import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

interface SetOnboardingWarrantiesCommandArgs {
  sellerId: string;
  isWithdrawalWarrantyActivated: boolean;
  isTransportWarrantyActivated: boolean;
  isUnpackingWarrantyActivated: boolean;
  diagnosticNeededForUnpackingWarranty: boolean;
  isLegalWarrantyOfConformityActivated: boolean;
  diagnosticNeededForLegalWarrantyOfConformity: boolean;
  isOutOfWarrantyActivated: boolean;
  withdrawalWarrantyDuration: {
    unit: string;
    value: string;
  };
  transportWarrantyDuration: {
    unit: string;
    value: string;
  };
  unpackingWarrantyDuration: {
    unit: string;
    value: string;
  };
  legalWarrantyOfConformityDuration: {
    unit: string;
    value: string;
  };
}

const ENDPOINT = `${APIConfiguration.import}onboardingWarranties`;

const setOnboardingWarrantiesCommand = async (
  payload: SetOnboardingWarrantiesCommandArgs,
): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default setOnboardingWarrantiesCommand;
