import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useShallowCompareEffect } from 'react-use';

import { sortLanguageByPrefered } from '@savgroup-front-common/core/src/helpers';
import { useChangeHTMLLanguage } from '@savgroup-front-common/core/src/hooks';
import {
  changeLocaleLanguage,
  setUserLanguagePreference,
} from '@savgroup-front-common/core/src/domains/i18n/actions';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { getAllAvailableLanguages } from '@savgroup-front-common/core/src/domains/languages/selectors';
import { getSellersConfiguration } from '@savgroup-front-common/core/src/domains/sellerConfiguration/actionCreators';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';

const useDefaultLanguage = () => {
  const dispatch = useDispatch();
  const languages = useSelector(getAllAvailableLanguages);
  const sellerIds = useSelector(sellerSelectors.selectSellerIds);

  const currentLanguage = useSelector(selectLocale);

  useChangeHTMLLanguage(currentLanguage);

  useShallowCompareEffect(() => {
    if (sellerIds.length > 0) {
      dispatch(getSellersConfiguration({ sellerIds }));
    }
  }, [dispatch, sellerIds]);

  useDeepCompareEffect(
    function selectDefaultLanguage() {
      const [nextCurrentLanguage] = sortLanguageByPrefered(languages);

      const onlyLangueAvailableIsntTheCurrentOne =
        languages.length === 1 && currentLanguage !== nextCurrentLanguage;
      const languageIsntAvailable = languages.indexOf(currentLanguage) < 0;

      const shouldUpdate =
        onlyLangueAvailableIsntTheCurrentOne || languageIsntAvailable;

      if (nextCurrentLanguage !== undefined && shouldUpdate) {
        dispatch(setUserLanguagePreference({ locale: nextCurrentLanguage }));
        dispatch(changeLocaleLanguage({ locale: nextCurrentLanguage }));
      }
    },
    [languages, currentLanguage, dispatch],
  );
};

export default useDefaultLanguage;
