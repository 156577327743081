import styled from 'styled-components';

export const $BpmnModeler = styled.div`
  width: 100%;
  height: 500px;

  & .djs-palette {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.paragraphTextColor};
    box-shadow: 0 0 0.07142857142857142rem 0 rgba(10, 31, 68, 0.08),
      0 0.21428571428571427rem 0.2857142857142857rem 0 rgba(10, 31, 68, 0.1);
    border-radius: 0.5rem;
    box-sizing: border-box;
    height: 48px;

    &.two-column.open {
      width: auto;
    }

    & .group {
      display: flex;

      &:last-child {
        border-right: none;
      }
    }

    & .djs-palette-entries {
      display: flex;
    }

    & .entry {
      float: none;
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    & .entry,
    & .djs-palette-toggle {
      font-size: 30px;
      text-align: center;
      width: 46px;
      height: 46px;
      line-height: 46px;
      cursor: pointer;
    }

    & .highlighted-entry {
      color: ${({ theme }) => theme.colors.primary} !important;
    }

    & .separator {
      display: none;
    }

    & div[data-group='gateway'],
    & div[data-group='data-object'],
    & div[data-group='data-store'],
    & div[data-group='collaboration'],
    & div[data-group='artifact'],
    & .entry.bpmn-icon-space-tool,
    & .entry.bpmn-icon-intermediate-event-none,
    & .entry.bpmn-icon-end-event-none,
    & .entry.bpmn-icon-subprocess-expanded {
      display: none;
    }
  }

  .djs-context-pad.open {
    border: 1px solid ${({ theme }) => theme.colors.paragraphTextColor};
    box-shadow: 0 0 0.07142857142857142rem 0 rgba(10, 31, 68, 0.08),
      0 0.21428571428571427rem 0.2857142857142857rem 0 rgba(10, 31, 68, 0.1);
    border-radius: 8px;
    background: white;
    display: flex;
    padding: 0 4px;

    & .group {
      display: flex;
      border-right: 1px solid ${({ theme }) => theme.colors.paragraphTextColor};
      padding: 0 4px;
      &:last-child {
        border-right: none;
      }
    }

    & .entry {
      flex: 1;
      margin: 4px;
      cursor: pointer;
    }
  }

  & .bjs-powered-by {
    display: none;
  }
`;
