import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}loans`;

export interface SetNewLoanArgs {
  fileId: string;
  loanReference: string;
  productName: string;
  sellerId: string;
}

export default async function setNewLoan(
  payload: SetNewLoanArgs,
): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    const response = await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
