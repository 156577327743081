import { upperFirst } from 'lodash';
import { useMemo } from 'react';

import { useKeyboardLayout } from '@savgroup-front-common/core/src/protons/KeyboardLayoutProvider';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import {
  AutocompleteOption,
  KEYBOARD_LAYOUT,
} from '@savgroup-front-common/types';

import messages from '../messages';

const getKeyboardDescription = (layout: KEYBOARD_LAYOUT) => {
  switch (layout) {
    case KEYBOARD_LAYOUT.AZERTY:
      return messages.azerty;
    case KEYBOARD_LAYOUT.QWERTZ:
      return messages.qwertz;
    case KEYBOARD_LAYOUT.QWERTY:
    default:
      return messages.qwerty;
  }
};

const adaptLayoutToOption = (layout: KEYBOARD_LAYOUT): AutocompleteOption => {
  return {
    label: safeFormattedIntlString(getKeyboardDescription(layout)),
    description: upperFirst(layout),
    value: layout,
  };
};

const useChooseKeyboardLayout = () => {
  const { keyboardLayout, changeKeyboardLayout } = useKeyboardLayout();

  const options = useMemo<AutocompleteOption[]>(
    () => [
      adaptLayoutToOption(KEYBOARD_LAYOUT.AZERTY),
      adaptLayoutToOption(KEYBOARD_LAYOUT.QWERTY),
      adaptLayoutToOption(KEYBOARD_LAYOUT.QWERTZ),
    ],
    [],
  );

  return {
    options,
    value: adaptLayoutToOption(keyboardLayout),
    onChange: changeKeyboardLayout,
  };
};

export default useChooseKeyboardLayout;
