import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/sales/imports`;

export enum SalesImportType {
  NOT_SET = 'NotSet',
  MANUAL = 'Manual',
  AUTOMATIC = 'Automatic ',
}

export interface SalesImportReportSummaryDto {
  importId: string;
  sellerId: string;
  type: SalesImportType;
  createdDate: string;
  imported: number;
  notImported: number;
  errors: number;
  hasRejectsReport: boolean;
  source: string;
}

interface GetSalesImportSummariesQueryPayload {
  sellerId: string;
  date?: string;
  failedOnly?: boolean;
}

type GetSalesImportSummariesQuery = (
  payload: GetSalesImportSummariesQueryPayload,
) => Promise<BackResponse<SalesImportReportSummaryDto[]> | BackResponseFailure>;

const getSalesImportSummariesQuery: GetSalesImportSummariesQuery = async ({
  sellerId,
  failedOnly,
  date,
}) => {
  try {
    return await apiCall<BackResponse<SalesImportReportSummaryDto[]>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId), {
        failedOnly,
        date,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getSalesImportSummariesQuery;
