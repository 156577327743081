import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { ICONS_TYPE } from '@savgroup-front-common/core/src/protons/icons/ConfigurationConsole/Icon.types';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/${COMMON_QUERY_PARAMS.ENTITY_TYPE}/keys`;

export interface ConsoleHierarchicalKeyDto {
  children?: ConsoleHierarchicalKeyDto[];
  displayName?: string;
  id?: string;
  sellerId?: string;
  uniqueName?: string;
  localizationKey?: string;
  parentUniqueName?: string;
  parentsUniqueNames?: string[];
  columnIcon?: ICONS_TYPE;
  value?: string;
  description?: string;
  displayBothDisplayAndUniqueNames?: boolean;
}

async function getEntityKeysBySellerQuery({
  sellerId,
  entityType,
  dataSourceUrl,
}: {
  sellerId: string;
  entityType: string;
  dataSourceUrl?: string;
}): Promise<BackResponse<ConsoleHierarchicalKeyDto[]> | BackResponseFailure> {
  try {
    const endpoint = dataSourceUrl
      ? `${APIConfiguration.import}${dataSourceUrl}`
      : ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
          COMMON_QUERY_PARAMS.ENTITY_TYPE,
          entityType,
        );

    const response = await apiCall<BackResponse<ConsoleHierarchicalKeyDto[]>>(
      endpoint,
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getEntityKeysBySellerQuery;
