import { defineMessages } from 'react-intl';

export default defineMessages({
  qrcodeUploadButton: {
    id: 'view.card.documents.qrcodeUploadButton',
    defaultMessage: 'Upload documents',
  },
  importMessage: {
    id: 'view.card.documents.qrcodeUpload.additionalInformation.importMessage',
    defaultMessage:
      'Import directly your additional information pictures for the folder',
  },
  importDocumentSuccess: {
    id: 'view.card.documents.qrcodeUpload.additionalInformation.importMessageSuccess',
    defaultMessage: 'Document imported successfully',
  },
  importDocumentError: {
    id: 'view.card.documents.qrcodeUpload.additionalInformation.importDocumentError',
    defaultMessage: 'Error on import',
  },
  dropZoneFileLabel: {
    id: 'view.card.documents.qrcodeUpload.additionalInformation.dropZoneFileLabel',
    defaultMessage: 'Drop files here',
  },
});
