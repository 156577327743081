import { all } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { sparePartCatalogSaga } from './catalog';

export default function* sparePartsSaga(): Generator {
  try {
    yield all([sparePartCatalogSaga()]);
  } catch (error) {
    logCritical(error as any);
  }
}
