import { CommonCarrierService } from '@savgroup-front-common/core/src/api';

import getFileLabelAddress from './getFileLabelAddress';
import getFileLabels from './getFileLabels';
import getFileShippingsQuery from './getFileShippingsQuery';
import getPickupPoints from './getPickupPoints';
import setFileLabelAddress from './setFileLabelAddress';
import verifyAddress from './verifyAddress';

export const CarrierService = {
  getPickupPoints,
  verifyAddress,
  getFileLabelAddress,
  getFileLabels,
  setFileLabelAddress,
  createHomePickupCommand: CommonCarrierService.createHomePickupCommand,
  getFileShippingsQuery,
};
