import React, { PropsWithChildren, ReactElement } from 'react';
import { HeaderGroup } from 'react-table';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../formatters';

import TableOrder from './Table.Order';
import { getOrder } from './TableContent.helpers';
import { $HeaderTr, $StyledTh } from './TableContent.styles';
import { TABLE_ALIGN } from './TableContent.types';

interface TableHeaderProps<D extends Record<string, unknown>> {
  groups: HeaderGroup<D>[];
  isNewUiEnabled: boolean;
}

const TableHeader = <D extends Record<string, unknown>>({
  groups,
  isNewUiEnabled,
}: PropsWithChildren<TableHeaderProps<D>>): ReactElement => {
  return (
    <>
      {groups.map((group) => {
        const { key, role } = group.getHeaderGroupProps();

        return (
          <$HeaderTr key={key} role={role}>
            {group.headers.map((column) => {
              if (column.Header) {
                const customProps = column.getProps ? column.getProps() : {};
                const { align } = customProps;

                const isAlignRight = align === TABLE_ALIGN.RIGHT;

                const { key, ...rest } = column.getHeaderProps([
                  column.getSortByToggleProps(),
                  customProps,
                ]);

                return (
                  <$StyledTh
                    key={key}
                    {...rest}
                    title={undefined}
                    disableSortBy={column.disableSortBy}
                    $isNewUiEnabled={isNewUiEnabled}
                  >
                    {isAlignRight && !column.disableSortBy && (
                      <>
                        <TableOrder order={getOrder(column.isSortedDesc)} />
                        {'\xa0'}
                      </>
                    )}
                    {SafeFormattedMessage(
                      column.render('Header') as
                        | ReactElement
                        | string
                        | MessageType,
                    )}
                    {!isAlignRight && !column.disableSortBy && (
                      <>
                        {'\xa0'}
                        <TableOrder order={getOrder(column.isSortedDesc)} />
                      </>
                    )}
                  </$StyledTh>
                );
              }

              return <></>;
            })}
          </$HeaderTr>
        );
      })}
    </>
  );
};

TableHeader.displayName = 'TableHeader';

export default TableHeader;
