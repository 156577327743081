import { getFromLocalStorage } from '@savgroup-front-common/core/src/helpers';
import { LOCAL_STORAGE_KEYS, RESULT_TYPE } from '@savgroup-front-common/types';

const getInitiallyEnabledResultType = ({
  defaultResultType,
}: {
  defaultResultType: RESULT_TYPE[];
}): RESULT_TYPE[] => {
  const resultTypeFromLocalStorage = getFromLocalStorage({
    key: LOCAL_STORAGE_KEYS.SEARCH_RESULT_TYPES,
    defaultValue: defaultResultType,
  });

  return resultTypeFromLocalStorage.filter((type) => {
    if (defaultResultType.length === 1 && defaultResultType.includes(type)) {
      return true;
    }

    return defaultResultType.includes(type);
  });
};

export default getInitiallyEnabledResultType;
