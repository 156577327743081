import { createAction } from '@reduxjs/toolkit';

import {
  STOCK_ITEM_STATUS,
  StockItemDetails,
  StockItemId,
} from '@savgroup-front-common/types';

import * as actionType from './actionTypes';

export interface GetStockItemDetailsPayload {
  stockItemId: string;
}

export const getStockItemDetails = createAction<GetStockItemDetailsPayload>(
  actionType.GET_STOCK_ITEM_DETAILS.BASE,
);

export interface GetStockItemsDetailsBySparePartRequestPayload {
  sparePartRequestLineId: string;
}
export const getStockItemsDetailsBySparePartRequestLineId =
  createAction<GetStockItemsDetailsBySparePartRequestPayload>(
    actionType.GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE.BASE,
  );

export interface GetStockItemDetailsSucceededPayload {
  value: StockItemDetails;
}
export const getStockItemDetailsSucceeded =
  createAction<GetStockItemDetailsSucceededPayload>(
    actionType.GET_STOCK_ITEM_DETAILS.SUCCEEDED,
  );

export interface UpdateStockItemDetailsStatusPayload {
  stockItemId: StockItemId;
  stockItemStatus: STOCK_ITEM_STATUS;
}
