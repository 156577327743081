import { adaptGetOwnerByIdQueryResponse } from './adaptGetOwnerByIdQueryResponse';
import { getOwnerById } from './getOwnerById';

/**
 * @deprecated use getOwnerById instead
 */
export async function getOwnerByIdLegacy({ ownerId }) {
  try {
    if (!ownerId) {
      return null;
    }

    const response = await getOwnerById({ ownerId });

    const value = response.value || {};

    return adaptGetOwnerByIdQueryResponse(value);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Got an internal error: ${e.message} `);

    return null;
  }
}
