import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  Menu,
  MenuItem,
  VERTICAL_MENU_ITEM_TYPES,
} from '@savgroup-front-common/core/src/atoms/Menu';
import { MENU_POSITIONS } from '@savgroup-front-common/core/src/atoms/Menu/Menu.types';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import {
  FilterIcon,
  RadioCheckedIcon,
  RadioUncheckedIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { SearchInput } from '../../../../../atoms';
import { SearchInputVariant } from '../../../../../atoms/SearchInput/SearchInput.types';
import { useSearchContext } from '../../hooks';
import useSearchPermissions from '../../hooks/useSearchPermissions';

import { getMenuMessageByResultType } from './HeaderSearchInput.helpers';
import { $ActionContainer } from './HeaderSearchInput.styles';
import messages from './messages';

interface HeaderSearchInputProps {
  forwardedRef:
    | React.MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null;
}

const HeaderSearchInput: FunctionComponent<
  React.PropsWithChildren<HeaderSearchInputProps>
> = ({ forwardedRef }) => {
  const {
    query,
    onSearch,
    onFocus,
    onClear,
    isLoading,
    onKeyDown,
    enabledResultTypes,
    onToggleSearchType,
    showNoFilter,
  } = useSearchContext();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClose = useCallback(() => setIsMenuOpen(false), []);
  const handleToggleMenu = useCallback(
    () => setIsMenuOpen((value) => !value),
    [],
  );

  const ref = useRef<HTMLDivElement>(null);

  const permissions = useSearchPermissions();

  const menuItem: MenuItem[] = useMemo(
    () =>
      permissions.map((value) => {
        return {
          dataTestId: `search-filter-${value}-${enabledResultTypes.includes(
            value,
          )}`,
          type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
          key: value,
          label: getMenuMessageByResultType(value),
          icon: enabledResultTypes.includes(value) ? (
            <RadioCheckedIcon />
          ) : (
            <RadioUncheckedIcon />
          ),
          onClick: () => {
            onToggleSearchType(value);
          },
        };
      }),
    [onToggleSearchType, permissions, enabledResultTypes],
  );

  const showFilters = menuItem.length > 1;
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <SearchInput
      value={query}
      onChange={onSearch}
      onFocus={onFocus}
      onClear={onClear}
      isLoading={isLoading}
      ref={forwardedRef}
      onKeyDown={onKeyDown}
      dataTestId="headerSearchInput"
      placeholder={
        isNewUiEnabled
          ? messages.searchNewBoPlaceholder
          : messages.searchPlaceholder
      }
      variant={
        isNewUiEnabled
          ? SearchInputVariant.GLOBAL_SEARCH_NEW_UI
          : SearchInputVariant.GLOBAL_SEARCH
      }
    >
      {showFilters && (
        <$ActionContainer
          ref={ref}
          $isDanger={enabledResultTypes.length !== menuItem.length}
        >
          <Button
            icon={<FilterIcon />}
            naked={!showNoFilter}
            primary={showNoFilter}
            type={BUTTON_TYPES.BUTTON}
            onClick={handleToggleMenu}
            dataTestId="headerSearchInput_filterButton"
          />
          <Menu
            dataTestId="headerSearchInput_filterMenu"
            menuItems={menuItem}
            isOpen={isMenuOpen}
            onClose={handleClose}
            wrapperRef={ref}
            position={MENU_POSITIONS.RIGHT}
          />
        </$ActionContainer>
      )}
    </SearchInput>
  );
};

HeaderSearchInput.displayName = 'HeaderSearchInput';

export default React.forwardRef<HTMLInputElement>((props, ref) => (
  <HeaderSearchInput forwardedRef={ref} {...props} />
));
