import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/storeActor`;

interface ChangeStoreActorPayload {
  fileId: string;
}

async function changeStoreActor({
  fileId,
}: ChangeStoreActorPayload): Promise<BaseBackResponse> {
  if (!fileId) {
    throw new RequiredPayloadFieldIsNotProvided(
      JSON.stringify({ fileId }),
      'changeStoreActor',
    );
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.PUT,
  });

  return response;
}

export default changeStoreActor;
