import { createAction } from '@reduxjs/toolkit';

import { RequestLineStatus } from '@savgroup-front-common/types';

import * as actionType from './actionTypes';

export interface GetSparePartRequestLineDetailsPayload {
  sparePartRequestLineId: string;
}

export const getSparePartRequestLineDetails =
  createAction<GetSparePartRequestLineDetailsPayload>(
    actionType.GET_SPARE_PART_REQUEST_LINE_DETAILS.BASE,
  );

export interface UpdateSparePartRequestLineDetailStatusPayload {
  sparePartRequestLineId: string;
  requestLineStatus: RequestLineStatus;
}
export const updateSparePartRequestLineDetailStatus =
  createAction<UpdateSparePartRequestLineDetailStatusPayload>(
    actionType.UPDATE_SPARE_PART_REQUEST_LINE_DETAILS_STATUS.BASE,
  );
