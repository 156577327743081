import styled from 'styled-components';

import { SimpleAnimationProps } from '@savgroup-front-common/core/src/animations/simpleAnimation/types';
import { rem } from '@savgroup-front-common/core/src/helpers';
import {
  fadeInToFadeOut,
  slideBottomToFadeOut,
} from '@savgroup-front-common/core/src/animations/stateAnimation';
import { simpleShakeAnimation } from '@savgroup-front-common/core/src/animations/simpleAnimation';

export const $NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100% - 1rem);
  position: absolute;
  width: 100%;
  flex-direction: column;
  ${fadeInToFadeOut};

  > div {
    > * {
      margin: 0;
    }
    margin: 0 auto;
    max-width: ${rem(450)};
  }
`;

export const $NoResultTitle = styled.h2`
  ${slideBottomToFadeOut};
  color: ${({ theme }) => theme.colors.primary};
`;

export const $NoResultDescription = styled.p`
  ${slideBottomToFadeOut};
  padding-bottom: 2rem;
`;

interface NoResultIconProps extends SimpleAnimationProps {
  $isLoading: boolean;
}

export const $NoResultIcon = styled.div<NoResultIconProps>`
  ${({ $isLoading }) => ($isLoading ? null : simpleShakeAnimation)}
`;
