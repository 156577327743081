import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { $EmptyTabStyles } from './EmptyTab.styles';
import { NightIcon } from '@savgroup-front-common/core/src/protons/icons';

const EmptyTab: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme();

  return (
    <>
      <Row center="xs">
        <Col>
          <NightIcon size="40px" color={theme.colors.default} />
        </Col>
      </Row>
      <Row center="xs">
        <Col>
          <$EmptyTabStyles>{children}</$EmptyTabStyles>
        </Col>
      </Row>
    </>
  );
};

EmptyTab.displayName = 'EmptyTab';

export default EmptyTab;
