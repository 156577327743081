import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { FullProductDto } from '@savgroup-front-common/core/src/api/Owner/getFullOwnerProductQuery';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

export const ENDPOINT = `${APIConfiguration.owner}owners/products/byId/${COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID}`;

interface GetOwnerProductByIdQueryArgs {
  ownerProductId: string;
}

async function getOwnerProductByIdQuery({
  ownerProductId,
}: GetOwnerProductByIdQueryArgs): Promise<
  BackResponse<FullProductDto> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID,
      ownerProductId,
    );

    const response = await apiCall<BackResponse<FullProductDto>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getOwnerProductByIdQuery;
