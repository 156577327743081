import styled, { keyframes } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { getAnimationDuration } from '@savgroup-front-common/core/src/animations/helper';
import { rem } from '@savgroup-front-common/core/src/helpers';
import {
  slideBottomToFadeOut,
  StateAnimationProps,
} from '@savgroup-front-common/core/src/animations/stateAnimation';

interface $AnimationContainerProps extends StateAnimationProps {
  $isNew: boolean;
  $height: number;
}

const animationBackground = keyframes`
  0% { 
    opacity: 0;
  }
  30% { 
    opacity: 1;
  }

  100% { 
    opacity: 0;
  }
`;

export const $AnimationContainer = styled.div<$AnimationContainerProps>`
  ${slideBottomToFadeOut};
  width: 100%;
  height: ${({ $height }) => rem($height)};
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    top: 0;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    animation-name: ${({ $isNew }) => ($isNew ? animationBackground : null)};
    animation-duration: ${getAnimationDuration({
      animationDuration: 1500,
    })};
    animation-delay: ${getAnimationDuration({
      animationDuration: 300,
    })};
  }

  > svg {
    @media ${media.maxWidth.md} {
      display: none;
    }
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: ${rem(20)};
    flex: 1;
  }
`;
