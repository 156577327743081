import { AnyAction } from 'redux';
import { Action, ActionMeta } from 'redux-actions';

import { BaseBackResponse, StockItem } from '@savgroup-front-common/types';

import {
  SetStockItemListFiltersPayload,
  SetStockItemListIsDescendingPayload,
  SetStockItemListQueryPayload,
  UpdateStockItemListItemPayload,
  UpdateStockItemListItemStatusPayload,
} from './actionCreators';
import * as actionTypes from './actionTypes';
import { STOCK_ITEM_LIST_DOMAIN } from './constants';
import { StockItemListListDomainState, StockItemListRubric } from './types';

export const initialStockItemListState: StockItemListListDomainState = {
  [STOCK_ITEM_LIST_DOMAIN.LIST]: {},
  [STOCK_ITEM_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: undefined,
  [STOCK_ITEM_LIST_DOMAIN.TOTAL_COUNT]: 0,
  [STOCK_ITEM_LIST_DOMAIN.BY_PAGE]: [],
  [STOCK_ITEM_LIST_DOMAIN.QUERY]: '',
  [STOCK_ITEM_LIST_DOMAIN.IS_DESCENDING]: true,
  [STOCK_ITEM_LIST_DOMAIN.RUBRICS]: [],
};

interface OnGetStockItemListSuccessPayload extends BaseBackResponse {
  value: {
    rubrics: StockItemListRubric[];
    hits: StockItem[];
    hitCount: number;
  };
}

function onSetStockItemListFilters(
  state: StockItemListListDomainState,
  {
    payload: { filterCategoryName, filterName, active: isActive },
  }: Action<SetStockItemListFiltersPayload>,
) {
  return {
    ...state,
    [STOCK_ITEM_LIST_DOMAIN.RUBRICS]: state[STOCK_ITEM_LIST_DOMAIN.RUBRICS].map(
      (rubric) => ({
        ...rubric,
        facets: rubric.facets.map((item) => {
          if (rubric.name === filterCategoryName && item.name === filterName) {
            return { ...item, isActive };
          }

          return item;
        }),
      }),
    ),
  };
}
function onResetStockItemListFilters(state: StockItemListListDomainState) {
  return {
    ...state,
    [STOCK_ITEM_LIST_DOMAIN.RUBRICS]: state[STOCK_ITEM_LIST_DOMAIN.RUBRICS].map(
      (rubric) => ({
        ...rubric,
        facets: rubric.facets.map((item) => ({
          ...item,
          isActive: false,
        })),
      }),
    ),
  };
}

function onGetStockItemListSuccess(
  state: StockItemListListDomainState,
  {
    payload,
    meta,
  }: ActionMeta<OnGetStockItemListSuccessPayload, { page: number }>,
): StockItemListListDomainState {
  const stockItemList = payload?.value?.hits;

  return {
    ...state,
    [STOCK_ITEM_LIST_DOMAIN.LIST]: stockItemList.reduce((acc, stockItem) => {
      return {
        ...acc,
        [stockItem.stockItemId]: stockItem,
      };
    }, state[STOCK_ITEM_LIST_DOMAIN.LIST]),
    [STOCK_ITEM_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: meta.page,
    [STOCK_ITEM_LIST_DOMAIN.TOTAL_COUNT]: payload.value.hitCount,
    [STOCK_ITEM_LIST_DOMAIN.RUBRICS]: payload.value.rubrics.map((rubric) => ({
      ...rubric,
      facets: rubric.facets.map((item) => ({
        isActive:
          state[STOCK_ITEM_LIST_DOMAIN.RUBRICS]
            .find((c) => c.name === rubric.name)
            ?.facets.find((c) => c.name === item.name)?.isActive || false,
        ...item,
      })),
    })),
    [STOCK_ITEM_LIST_DOMAIN.BY_PAGE]: [
      ...state[STOCK_ITEM_LIST_DOMAIN.BY_PAGE].slice(0, meta.page - 1),
      stockItemList.map(({ stockItemId }) => stockItemId),
    ],
  };
}

function onSetStockItemListQueryAction(
  state: StockItemListListDomainState,
  { payload }: Action<SetStockItemListQueryPayload>,
): StockItemListListDomainState {
  return {
    ...state,
    [STOCK_ITEM_LIST_DOMAIN.QUERY]: payload.query,
  };
}

function onSetStockItemListIsDescendingAction(
  state: StockItemListListDomainState,
  { payload }: Action<SetStockItemListIsDescendingPayload>,
): StockItemListListDomainState {
  return {
    ...state,
    [STOCK_ITEM_LIST_DOMAIN.IS_DESCENDING]: payload.isDescending,
  };
}

function onUpdateStockItemListStatusAction(
  state: StockItemListListDomainState,
  { payload }: Action<UpdateStockItemListItemStatusPayload>,
): StockItemListListDomainState {
  const stockItem = state[STOCK_ITEM_LIST_DOMAIN.LIST]?.[payload.stockItemId];

  if (!stockItem) {
    return state;
  }

  return {
    ...state,
    [STOCK_ITEM_LIST_DOMAIN.LIST]: {
      ...state[STOCK_ITEM_LIST_DOMAIN.LIST],
      [payload.stockItemId]: {
        ...stockItem,
        stockItemStatus: payload.stockItemStatus,
      },
    },
  };
}

function onUpdateStockItemListAction(
  state: StockItemListListDomainState,
  { payload }: Action<UpdateStockItemListItemPayload>,
): StockItemListListDomainState {
  const newStockItem = payload.stockItem;
  const stockItem =
    state[STOCK_ITEM_LIST_DOMAIN.LIST]?.[newStockItem.stockItemId];

  if (!stockItem) {
    return state;
  }

  return {
    ...state,
    [STOCK_ITEM_LIST_DOMAIN.LIST]: {
      ...state[STOCK_ITEM_LIST_DOMAIN.LIST],
      [newStockItem.stockItemId]: {
        ...stockItem,
        stockItemStatus: newStockItem.stockItemStatus,
        stockName: newStockItem.stockName,
        fileReference: newStockItem.fileReference,
        supplierName: newStockItem.supplierName,
      },
    },
  };
}

export default function stockItemListReducer(
  state: StockItemListListDomainState = initialStockItemListState,
  action: AnyAction,
): StockItemListListDomainState {
  switch (action.type) {
    case actionTypes.GET_STOCK_ITEM_LIST.SUCCEEDED:
      return onGetStockItemListSuccess(
        state,
        action as ActionMeta<
          OnGetStockItemListSuccessPayload,
          { page: number }
        >,
      );
    case actionTypes.SET_STOCK_ITEM_LIST_QUERY.BASE:
      return onSetStockItemListQueryAction(
        state,
        action as Action<SetStockItemListQueryPayload>,
      );
    case actionTypes.SET_STOCK_ITEM_LIST_IS_DESCENDING.BASE:
      return onSetStockItemListIsDescendingAction(
        state,
        action as Action<SetStockItemListIsDescendingPayload>,
      );
    case actionTypes.SET_STOCK_ITEM_LIST_FILTERS.BASE:
      return onSetStockItemListFilters(
        state,
        action as Action<SetStockItemListFiltersPayload>,
      );
    case actionTypes.UPDATE_STOCK_ITEM_LIST_ITEM_STATUS.BASE:
      return onUpdateStockItemListStatusAction(
        state,
        action as Action<UpdateStockItemListItemStatusPayload>,
      );

    case actionTypes.UPDATE_STOCK_ITEM_LIST_ITEM.BASE:
      return onUpdateStockItemListAction(
        state,
        action as Action<UpdateStockItemListItemPayload>,
      );

    case actionTypes.RESET_STOCK_ITEM_LIST_FILTERS.BASE:
      return onResetStockItemListFilters(state);

    default:
      return state;
  }
}
