import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/orders/${COMMON_QUERY_PARAMS.ORDER_ID}`;

interface AssignOrderToOwnerArgs {
  orderId: string;
  ownerId: string;
}

async function assignOrderToOwner({
  orderId,
  ownerId,
}: AssignOrderToOwnerArgs): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    return await apiCall(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId).replace(
        COMMON_QUERY_PARAMS.OWNER_ID,
        ownerId,
      ),
      { method: SUPPORTED_METHODS.PUT },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default assignOrderToOwner;
