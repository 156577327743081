import {
  CHANGE_DASHBOARD_SEARCH_QUERY,
  CHANGE_MESSAGES_FILTER,
  CHANGE_MESSAGES_SEARCH_QUERY,
  CHANGE_MESSAGES_SORT,
  CHANGE_TODOS_FILTER,
  RESET_FILTERS,
} from './actionTypes';

const initialState = {
  dashboard: {
    appliedFilters: {},
    searchQuery: '',
  },
  messages: {
    appliedFilters: {},
    searchQuery: '',
  },
  alerts: {
    appliedFilters: {},
    searchQuery: '',
  },
};

function onMessagesFilterChange(
  state,
  { payload: { filterCategoryName, filterName, active } },
) {
  return {
    ...state,
    messages: {
      ...state.messages,
      appliedFilters: {
        ...state.messages.appliedFilters,
        [filterCategoryName]: {
          ...(state.messages.appliedFilters?.[filterCategoryName] || {}),
          [filterName]: active,
        },
      },
    },
  };
}

function onMessagesSortChange(state, { payload }) {
  return {
    ...state,
    messages: {
      ...state.messages,
      sortOrder: payload,
    },
  };
}

function onChangeMessagesSearchQuery(state, { payload }) {
  return {
    ...state,
    messages: {
      ...state.messages,
      searchQuery: payload || '',
    },
  };
}

function onTodosFilterChange(
  state,
  { payload: { filterCategoryName, filterName, active } },
) {
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      appliedFilters: {
        ...state.dashboard.appliedFilters,
        [filterCategoryName]: {
          ...(state.messages.appliedFilters?.[filterCategoryName] || {}),
          [filterName]: active,
        },
      },
    },
  };
}

function onChangeDashboardSearchQuery(state, { payload }) {
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      searchQuery: payload || '',
    },
  };
}

function onFiltersReset(state) {
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      appliedFilters: {},
    },
    messages: {
      ...state.messages,
      appliedFilters: {},
    },
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FILTERS.BASE:
      return onFiltersReset(state, action);
    case CHANGE_MESSAGES_FILTER:
      return onMessagesFilterChange(state, action);
    case CHANGE_MESSAGES_SORT:
      return onMessagesSortChange(state, action);
    case CHANGE_MESSAGES_SEARCH_QUERY:
      return onChangeMessagesSearchQuery(state, action);
    case CHANGE_TODOS_FILTER:
      return onTodosFilterChange(state, action);
    case CHANGE_DASHBOARD_SEARCH_QUERY:
      return onChangeDashboardSearchQuery(state, action);
    default:
      return state;
  }
}
