import { createSelector } from 'reselect';

import { ControlRootState } from '../ControlRootState';

export const debugState = (state: ControlRootState) => state.debug;

export const selectCfDebug = createSelector(
  [debugState],
  (debug) => debug?.cfDebug,
);
