import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  User,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}users`;

interface GetUserInformationByIdsQueryPayload {
  userIds: string[];
}

const getUserInformationByIdsQuery = async ({
  userIds,
}: GetUserInformationByIdsQueryPayload): Promise<
  BackResponse<User[]> | BackResponseFailure
> => {
  try {
    const url = buildUrl(ENDPOINT, { userIds });

    const response = await apiCall<BackResponse<User[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getUserInformationByIdsQuery;
