import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.config}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}`;

interface SellerAddress {
  name: string;
  contact: string;
  address: string;
  additionalAddress?: string;
  additionalInformation?: string;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
}

interface SellerCountry {
  countryCode: string;
  address: SellerAddress;
  useDefaultAddress: boolean;
}

export type UpsertTenantCommandPayload = {
  sellerId: string;
  name: string;
  shortName: string;
  trigram: string;
  myAccountUrl?: string;
  defaultOwnerOrderUrl?: string;
  countries: SellerCountry[];
  signatureDate?: string;
  customerSupportCountryCodes?: string[];
  cumulIntegrationId?: string;
  theme: {
    logo?: string;
    color1?: string;
    color2?: string;
  };
  siretNum?: string;
  vatNum?: string;
  email?: string;
};
export type UpsertSellerCommandPayload = {
  sellerId: string;
  name: string;
  myAccountUrl?: string;
  defaultOwnerOrderUrl?: string;
  parentId: string;
  withRetractationCare?: boolean;
  countries?: SellerCountry[];
  salesChannels?: string[];
  signatureDate?: string;
  customerSupportCountryCodes?: string[];
  cumulIntegrationId?: string;
  siretNum?: string;
  vatNum?: string;
  email?: string;
};

export default async function upsertSellerCommand(
  payload: UpsertSellerCommandPayload | UpsertTenantCommandPayload,
): Promise<BackResponse<string> | BackResponseFailure> {
  try {
    const { sellerId, ...rest } = payload;
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    return await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: rest,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
