import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/storeActor`;

interface SetStoreActorsPayload {
  claimId: string;
  storeActorId: string;
}

async function setStoreActor({
  claimId,
  storeActorId,
}: SetStoreActorsPayload): Promise<void> {
  const functionName = 'setStoreActor';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }
  if (!storeActorId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);
  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.PUT,
    json: { storeActorId },
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${functionName}`);
  }
}

export default setStoreActor;
