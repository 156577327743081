import { ActionMeta } from 'redux-actions';

import { User } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { USERS_DOMAIN } from './constants';
import { UsersDomainState } from './types';

export const initialUsersState: UsersDomainState = {
  [USERS_DOMAIN.LIST]: {},
};

export interface OnGetUserSuccessPayload {
  value: User;
}

function onGetUserSuccess(
  state: UsersDomainState,
  { payload }: { payload: OnGetUserSuccessPayload },
): UsersDomainState {
  const { value: user } = payload;

  return {
    ...state,
    [USERS_DOMAIN.LIST]: {
      ...state[USERS_DOMAIN.LIST],
      [user.userId]: user,
    },
  };
}

export default function mainReducer(
  state: UsersDomainState = initialUsersState,
  action: ActionMeta<OnGetUserSuccessPayload, unknown>,
): UsersDomainState {
  switch (action.type) {
    case types.GET_USER_BY_ID.SUCCEEDED:
      return onGetUserSuccess(state, action);

    default:
      return state;
  }
}
