import {
  APP_COMPONENT_STATUSES,
  AppComponent,
} from '@savgroup-front-common/types';

import getCurrentComponent from './getCurrentComponent';

const isComponentUnderMaintenance = (
  components: AppComponent[],
  componentName: string,
): boolean => {
  const component = getCurrentComponent(components, componentName);

  if (!component) {
    return false;
  }

  return component.status === APP_COMPONENT_STATUSES.UNDER_MAINTENANCE;
};

export default isComponentUnderMaintenance;
