import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AppScheduledMaintenance } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters';

import messages from './messages';
import {
  $ToastBody,
  $ToastLink,
  $ToastTime,
} from './NewScheduledMaintenance.styles';

interface NewScheduledMaintenanceProps {
  scheduledMaintenance: AppScheduledMaintenance;
}

const NewScheduledMaintenance: FunctionComponent<
  NewScheduledMaintenanceProps
> = ({ scheduledMaintenance }) => {
  const { formatDate } = useIntl();

  const duration = useMemo(
    () =>
      moment
        .duration(
          moment(scheduledMaintenance.scheduledFor).diff(
            moment(scheduledMaintenance.scheduledUntil),
          ),
        )
        .humanize(),
    [scheduledMaintenance.scheduledFor, scheduledMaintenance.scheduledUntil],
  );

  return (
    <>
      <$ToastTime>
        <SafeFormattedMessageWithoutSpread
          message={messages.scheduleTime}
          values={{
            duration,
            time: formatDate(scheduledMaintenance.scheduledFor, {
              day: 'numeric',
              month: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
          }}
        />
      </$ToastTime>
      <$ToastBody>{scheduledMaintenance.incidentUpdates[0].body}</$ToastBody>

      <$ToastLink
        href={scheduledMaintenance.shortlink}
        isExternal
        target="_blank"
      >
        <SafeFormattedMessageWithoutSpread message={messages.seemore} />
      </$ToastLink>
    </>
  );
};

NewScheduledMaintenance.displayName = 'NewScheduledMaintenance';

export default NewScheduledMaintenance;
