import React, { FunctionComponent } from 'react';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Table } from '@savgroup-front-common/core/src/atoms/Table';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import SHORTCUTS_COLUMNS from '../../ShortcutsContent.config';
import { ShortcutKey } from '../../ShortcutsContent.types';

interface ShortcutTableProps {
  title: MessageType;
  withoutMarginBottom?: boolean;
  data: ShortcutKey[];
}

const ShortcutTable: FunctionComponent<
  React.PropsWithChildren<ShortcutTableProps>
> = ({ data, title, withoutMarginBottom = false }) => {
  return (
    <Card
      minimal
      withBorder
      withoutPaddingBottom
      withoutMarginBottom={withoutMarginBottom}
    >
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread message={title} />
      </Heading>
      <Table<ShortcutKey> data={data} columns={SHORTCUTS_COLUMNS} />
    </Card>
  );
};

ShortcutTable.displayName = 'ShortcutTable';

export default ShortcutTable;
