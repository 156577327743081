import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { ConsoleDataCell } from './getEntityBySellerQuery';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/${COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION}/filter`;

export type GetFilteredModelBySellerCommandReturnValue = Record<
  string,
  ConsoleDataCell
>[];

export interface ConsoleEntityFieldFilter {
  key: string;
  value: string[];
}

interface GetFilteredModelBySellerCommandArgs {
  sellerId: string;
  page?: number;
  pageSize?: number;
  filters?: ConsoleEntityFieldFilter[];
  entityDefinition: string;
}

export async function getFilteredEntityDefintionBySellerCommand({
  page,
  pageSize,
  sellerId,
  filters,
  entityDefinition,
}: GetFilteredModelBySellerCommandArgs): Promise<
  BackResponse<GetFilteredModelBySellerCommandReturnValue> | BackResponseFailure
> {
  try {
    return await apiCall<
      BackResponse<GetFilteredModelBySellerCommandReturnValue>
    >(
      buildUrl(
        ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
          COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION,
          entityDefinition,
        ),
      ),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          page,
          pageSize,
          filters,
        },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
