import React, { FunctionComponent } from 'react';

import { AppStatusesContext } from './AppStatuses.context';
import {
  useAppComponents,
  useAppIncidents,
  useAppScheduledMaintenances,
} from './hooks';

interface AppStatusesProviderProps {
  componentName: string;
}

const AppStatusesProvider: FunctionComponent<React.PropsWithChildren<AppStatusesProviderProps>> = ({
  children,
  componentName,
}) => {
  const {
    appComponents,
    updateAppComponents,
    isUnderMaintenance,
    removeMaintenanceScreen,
    isComponentStatusLoaded,
  } = useAppComponents({
    componentName,
  });

  const { nextScheduledMaintenance, appScheduledMaintences } =
    useAppScheduledMaintenances({
      appComponents,
      componentName,
      updateAppComponents,
    });

  const { appIncidents } = useAppIncidents({ componentName });

  return (
    <AppStatusesContext.Provider
      value={{
        nextScheduledMaintenance,
        appIncidents,
        appScheduledMaintences,
        appComponents,
        updateAppComponents,
        isUnderMaintenance,
        removeMaintenanceScreen,
        isComponentStatusLoaded,
      }}
    >
      {children}
    </AppStatusesContext.Provider>
  );
};

AppStatusesProvider.displayName = 'AppStatusesProvider';

export default AppStatusesProvider;
