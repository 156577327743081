import {
  FACET_INPUT_TYPE,
  FACET_TRIPLE_STATE_SWITCH_VALUES,
  OnFacetChangeArgs,
  PossibleFilter,
} from '@savgroup-front-common/types';

const isTripleStateSwitchType = (
  inputType: FACET_INPUT_TYPE,
): inputType is FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH => {
  return inputType === FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH;
};
const isSwitchType = (
  inputType: FACET_INPUT_TYPE,
): inputType is FACET_INPUT_TYPE.SWITCH => {
  return inputType === FACET_INPUT_TYPE.SWITCH;
};
const isTripleStateSwitchValue = (
  value: FACET_TRIPLE_STATE_SWITCH_VALUES | boolean,
): value is FACET_TRIPLE_STATE_SWITCH_VALUES => {
  return typeof value !== 'boolean';
};
const isSwitchValue = (
  value: FACET_TRIPLE_STATE_SWITCH_VALUES | boolean,
): value is boolean => {
  return typeof value === 'boolean';
};

const createNewFacet = (payload: OnFacetChangeArgs): PossibleFilter => {
  if (
    isTripleStateSwitchType(payload.inputType) &&
    isTripleStateSwitchValue(payload.value)
  ) {
    const returnValue = {
      facetLabel: payload.facetLabel,
      facetName: payload.facetName,
      inputType: payload.inputType,
      value: payload.value,
      resultsCount: 0,
      shouldGoTop: true,
      isLocked: false,
      isHidden: false,
    };

    return returnValue;
  }

  if (isSwitchType(payload.inputType) && isSwitchValue(payload.value)) {
    return {
      facetLabel: payload.facetLabel,
      facetName: payload.facetName,
      inputType: payload.inputType,
      value: payload.value,
      resultsCount: 0,
      shouldGoTop: true,
      isLocked: false,
      isHidden: false,
    };
  }

  throw new Error('not supported facet type');
};

export default createNewFacet;
