import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useGetSellerConfiguration } from '@savgroup-front-common/core/src/hooks';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';
import { Seller, SellerConfiguration } from '@savgroup-front-common/types';
import { useControlTypedSelector } from 'control/hooks';

export const GetFaqUrlQuery = 'getFaqUrlQuery';

interface UseFaqUrlReturns {
  sellerConfiguration?: SellerConfiguration;
  isFaqEnabled?: boolean;
  handleOpenUrl: (sellerConfiguration?: SellerConfiguration) => void;
  handleFaqButton: () => void;
  isOpenModal: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  selectedSeller?: Seller;
  handleSelectSeller: (seller: Seller) => void;
}

const useFaqUrl = (): UseFaqUrlReturns => {
  const sellers = useControlTypedSelector(sellerSelectors.selectSellers);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState<Seller>();

  const initSellerSelected = sellers.length === 1 ? sellers[0] : selectedSeller;

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId: initSellerSelected?.id,
  });

  const handleOpenUrl = useCallback(
    async (sellerConfiguration?: SellerConfiguration) => {
      if (!sellerConfiguration) {
        return;
      }

      const { faqUrl } = sellerConfiguration;

      if (faqUrl) {
        window.open(faqUrl, '_blank');
      }
    },
    [],
  );

  const handleSelectSeller = (seller: Seller) => {
    setSelectedSeller(seller);
  };

  const handleFaqButton = useCallback(async () => {
    if (sellers.length === 1) {
      handleOpenUrl(sellerConfiguration);
    } else {
      setIsOpenModal(true);
    }
  }, [handleOpenUrl, sellerConfiguration, sellers.length]);

  return {
    sellerConfiguration,
    isFaqEnabled:
      (sellers.length === 1 && !!sellerConfiguration?.faqUrl) ||
      sellers.length > 1,
    handleOpenUrl,
    handleFaqButton,
    isOpenModal,
    setIsOpenModal,
    selectedSeller,
    handleSelectSeller,
  };
};

export default useFaqUrl;
