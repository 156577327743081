import { useMutation } from 'react-query';

import {
  CommonClaimService,
  CommonWorkflowService,
} from '@savgroup-front-common/core/src/api';
import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';

import { logError } from '../../../../../configuration';
import messages from '../messages';

interface UseUploadFileArgs {
  fileId?: string;
  claimId: string;
  additionalInformationId: string;
  token: string;
}

export const useAdditionalInformationUploadFile = ({
  fileId,
  claimId,
  additionalInformationId,
  token,
}: UseUploadFileArgs) => {
  const {
    mutateAsync: handleUploadDocuments,
    isLoading: isUploadDocumentsLoading,
  } = useMutation(
    [
      'setFileAdditionalInformationWithFilesAndTokenCommand',
      { fileId, additionalInformationId, token },
    ],
    async (files: File[]) => {
      const fileToUpload = files[0];

      if (!fileToUpload) {
        return undefined;
      }

      if (!fileId) {
        const response =
          await CommonClaimService.setClaimAdditionalInformationWithFilesCommand(
            {
              additionalInformationId,
              claimId,
              file: fileToUpload,
              token,
            },
          );

        if (response.failure) {
          logError(response.errors);
          toast.error(safeFormattedIntlString(messages.importDocumentError));

          return undefined;
        }

        toast.success(safeFormattedIntlString(messages.importDocumentSuccess));

        return undefined;
      }

      const workflowResponse =
        await CommonWorkflowService.setFileAdditionalInformationWithFilesAndTokenCommand(
          {
            fileId,
            additionalInformationId,
            file: fileToUpload,
            claimId,
            token,
          },
        );

      if (workflowResponse.failure) {
        logError(workflowResponse.errors);
        toast.error(safeFormattedIntlString(messages.importDocumentError));

        return undefined;
      }

      toast.success(safeFormattedIntlString(messages.importDocumentSuccess));

      return undefined;
    },
  );

  return {
    handleUploadDocuments,
    isUploadDocumentsLoading,
  };
};
