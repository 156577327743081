import { useMemo } from 'react';

import { SHORTCUT_LIST_CONTROL } from '@savgroup-front-common/constants';

import messages from '../messages';
import { ShortcutKey } from '../ShortcutsContent.types';

const useListShortcuts = () => {
  return useMemo<ShortcutKey[]>(() => {
    return [
      {
        fragments: SHORTCUT_LIST_CONTROL.FILTERS,
        description: messages.filterOpenLabel,
      },
      {
        fragments: SHORTCUT_LIST_CONTROL.LIST_TOGGLE,
        description: messages.listOpenLabel,
      },
    ];
  }, []);
};

export default useListShortcuts;
