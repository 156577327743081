import { APIConfiguration } from '@savgroup-front-common/configuration';
import { CIVILITY, SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  ReverseMoney,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}creation/nonreference/orders`;

interface Payload {
  id: string;
  reference: string;
}

interface Order {
  civility: CIVILITY;
  customerLastName: string;
  customerFirstName: string;
  address: AddressInfoDto;
  phoneNumber: string;
  customerMail: string;
  purchaseDateUtc: string;
  products: {
    modelId: string;
    price: ReverseMoney;
    orderLineReference: string;
    supplierId?: string;
    orderProductAdditionalInfo?: {
      uniqueKey: string;
      value: string;
    }[];
    shippingPrice?: ReverseMoney;
    soldWarrantyId?: string;
    soldWarrantyPrice?: ReverseMoney;
  }[];
  shippingPrice: ReverseMoney;

  isProductShippingPrice?: boolean;
  salesChannel: string;
  sellerId: string;
  storePurchasedAt?: string;
  orderRegistrationType?: string;
  saleReceiptNumber?: string;
  orderSource?: string;
}

async function createOrderWithoutReference({
  order,
}: {
  order: Order;
}): Promise<Payload> {
  const response = await apiCall<BackResponse<Payload>>(ENDPOINT, {
    method: SUPPORTED_METHODS.POST,
    json: order,
  });

  if (response.failure) {
    throw response.errors;
  }

  return response.value;
}

export default createOrderWithoutReference;
