import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains';
import { getActionErrorsSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { SPARE_PART_REQUEST_DOMAIN_KEY } from '../constants';

import { GET_SPARE_PART_REQUEST_LINE_DETAILS } from './actionTypes';
import {
  SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN,
  SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN_KEY,
} from './constants';
import { SparePartRequestLineDetailsDomainState } from './types';

interface State extends CommonAppState {
  [SPARE_PART_REQUEST_DOMAIN_KEY]: {
    [SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN_KEY]: SparePartRequestLineDetailsDomainState;
  };
}

const selectSparePartRequestLineDetailsDomain = (
  state: State,
): SparePartRequestLineDetailsDomainState =>
  state[SPARE_PART_REQUEST_DOMAIN_KEY][
    SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN_KEY
  ];

export const selectSparePartRequestLines = createSelector(
  [selectSparePartRequestLineDetailsDomain],
  (state) => state[SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN.REQUEST_LINE],
);
export const selectSparePartRequestLinesHasErrors = (
  state: State,
  { sparePartRequestLineId }: { sparePartRequestLineId: string },
) =>
  getActionErrorsSelector(
    state,
    GET_SPARE_PART_REQUEST_LINE_DETAILS,
    sparePartRequestLineId,
  );

export const selectSparePartRequestLinesById = createSelector(
  [
    selectSparePartRequestLines,
    (
      _: State,
      { sparePartRequestLineId }: { sparePartRequestLineId: string },
    ) => sparePartRequestLineId,
  ],
  (state, sparePartRequestLineId) => state[sparePartRequestLineId],
);
