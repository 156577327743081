import React, { FC, PropsWithChildren } from 'react';

import { ExternalPortal } from '../../../protons/ExternalPortal/ExternalPortal';

import { useFlexibleModal } from './FlexibleModal.hooks';
import { $DragArea, $FlexibleModalMask } from './FlexibleModal.styles';
import { FlexibleModalContent } from './FlexibleModalContent/FlexibleModalContent';
import { Resizer } from './Resizer/Resizer';

interface PropTypes {
  top?: number;
  left?: number;
  initHeight?: number;
  initWidth?: number;
  minWidth?: number;
  minHeight?: number;
  isOpen: boolean;
  disableMove?: boolean;
  disableVerticalMove?: boolean;
  disableHorizontalMove?: boolean;
  isMinimised?: boolean;
  disableResize?: boolean;
  disableKeystroke?: boolean;
  disableVerticalResize?: boolean;
  disableHorizontalResize?: boolean;
  onRequestClose: () => void;
  onFocus?: () => void;
  shouldKeepRatio?: boolean;
}

export const FlexibleModal: FC<PropsWithChildren<PropTypes>> = ({
  disableHorizontalResize,
  disableHorizontalMove,
  disableVerticalMove,
  disableKeystroke,
  disableMove,
  disableResize,
  disableVerticalResize,
  initHeight = 400,
  initWidth = 800,
  top,
  left,
  minHeight = 200,
  minWidth = 200,
  isOpen,
  isMinimised,
  onRequestClose,
  onFocus,
  shouldKeepRatio = false,
  children,
}) => {
  const {
    onMouseDown,
    refModal,
    state,
    handleUpdateStateResizing,
    refDragArea,
  } = useFlexibleModal({
    disableHorizontalResize,
    disableHorizontalMove,
    disableVerticalMove,
    disableKeystroke,
    disableMove,
    disableResize,
    disableVerticalResize,
    initHeight,
    initWidth,
    isMinimised,
    left,
    minHeight,
    minWidth,
    onRequestClose,
    top,
    shouldKeepRatio,
  });

  return (
    <ExternalPortal>
      <div>
        {isOpen && <$FlexibleModalMask />}

        <FlexibleModalContent
          onFocus={onFocus}
          width={state.width}
          height={state.height}
          top={state.top}
          left={state.left}
          isDragging={state.isDragging}
          isOpen={isOpen}
          ref={refModal}
        >
          {children}

          <$DragArea
            onMouseDown={onMouseDown}
            style={{
              width: state.width,
            }}
            ref={refDragArea}
          />

          {!disableResize && !isMinimised && (
            <Resizer onUpdateStateResizing={handleUpdateStateResizing} />
          )}
        </FlexibleModalContent>
      </div>
    </ExternalPortal>
  );
};

FlexibleModal.displayName = 'FlexibleModal';
