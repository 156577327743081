import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/additionalInformation/${COMMON_QUERY_PARAMS.ID}/file`;

export async function setClaimAdditionalInformationWithFilesCommand({
  claimGroupId,
  issueId,
}: {
  claimGroupId: string;
  issueId: string;
}): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          issueId,
        },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
