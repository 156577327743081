import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { rem } from '@savgroup-front-common/core/src/helpers';

import { SEARCH_HEIGHT } from '../../../Search.contants';

export const $ResultControl = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 0 1rem;
  height: ${rem(SEARCH_HEIGHT.CONTROLS)};
  flex-direction: row;
  display: flex;
`;
export const $ShowOnAll = styled.div`
  height: ${rem(25)};
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-direction: row;

  + div {
    padding-left: ${rem(20)};
  }
`;
export const $ShowTabletAndMore = styled($ShowOnAll)`
  @media ${media.maxWidth.sm} {
    display: none;
  }
`;
export const $ShowDesktop = styled($ShowOnAll)`
  @media ${media.maxWidth.md} {
    display: none;
  }
`;

export const $ControlText = styled.div`
  display: inline-block;

  margin: 0 ${rem(4)};
`;
