import get from 'lodash/get';
import { createSelector } from 'reselect';

import { FileId } from '@savgroup-front-common/types';
import { ControlRootState } from 'control/domains/ControlRootState';

import { SPARE_PART_REQUEST_DOMAIN_KEY } from '../constants';

import {
  FILE_SPARE_PART_REQUEST_LINES_DOMAIN,
  FILE_SPARE_PART_REQUEST_LINES_DOMAIN_KEY,
} from './constants';
import { SparePartFileRequestLineDetailsDomainState } from './types';

const selectSparePartRequestLinesState = (
  state: ControlRootState,
): SparePartFileRequestLineDetailsDomainState =>
  state[SPARE_PART_REQUEST_DOMAIN_KEY][
    FILE_SPARE_PART_REQUEST_LINES_DOMAIN_KEY
  ];

export const selectSparePartRequestLines = createSelector(
  [selectSparePartRequestLinesState],
  (state) => state[FILE_SPARE_PART_REQUEST_LINES_DOMAIN.LIST],
);

export const selectFileSparePartRequestLineIds = createSelector(
  [
    selectSparePartRequestLinesState,
    (_: ControlRootState, { fileId }: { fileId: FileId }) => fileId,
  ],
  (state, fileId) =>
    get(state[FILE_SPARE_PART_REQUEST_LINES_DOMAIN.BY_FILE_ID], fileId),
);

export const selectSparePartRequestLinesByFileId = createSelector(
  [selectSparePartRequestLines, selectFileSparePartRequestLineIds],
  (sparePartRequestLines, sparePartRequestLineIds = []) => {
    return sparePartRequestLineIds.map((sparePartRequestLineId) => {
      return get(sparePartRequestLines, sparePartRequestLineId);
    });
  },
);
