import filter from 'lodash/filter';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import maxBy from 'lodash/maxBy';
import toPairs from 'lodash/toPairs';
import { buffers, channel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';
import { quoteStatus } from './constants';
import * as Selectors from './selectors';

export function* getCurrentDraftedQuoteWorker({ payload: file }) {
  const fileId = file.get('fileId');
  const sellerId = file.get('sellerId');

  const allQuotesResponse = yield callAndGetResponse(
    ActionTypes.LOAD_FILE_QUOTES,
    `${APIConfiguration.repairer}files/${fileId}/quotes`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
  const allQuotes = get(allQuotesResponse, 'payload.value');

  const currentDraft = head(
    filter(
      allQuotes,
      (quote) => ['Draft', 'Created'].indexOf(get(quote, 'state')) > -1,
    ),
  );

  if (currentDraft) {
    return yield put(
      ActionTypes.GET_CURRENT_DRAFTED_QUOTE.end({
        fileId,
        quoteId: get(currentDraft, 'id'),
      }),
    );
  }

  const newQuoteIdResponse = yield callAndGetResponse(
    ActionTypes.CREATE_FILE_QUOTE,
    `${APIConfiguration.repairer}files/${fileId}/quotes`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
      },
    },
  );
  const newQuoteId = get(newQuoteIdResponse, 'payload.value');

  return yield put(
    ActionTypes.GET_CURRENT_DRAFTED_QUOTE.end({ fileId, quoteId: newQuoteId }),
  );
}
function* getCurrentDraftedQuoteWatcher() {
  yield takeEvery(
    ActionTypes.GET_CURRENT_DRAFTED_QUOTE.BASE,
    getCurrentDraftedQuoteWorker,
  );
}

export function* getQuoteByIdWorker({ payload: quoteId }) {
  yield call(
    callAndGetResponse,
    ActionTypes.GET_QUOTE_BY_ID,
    `${APIConfiguration.repairer}quotes/${quoteId}`,
    { method: SUPPORTED_METHODS.GET },
  );

  yield put(ActionTypes.GET_QUOTE_BY_ID.end());
}
function* getQuoteByIdWatcher() {
  yield takeEvery(ActionTypes.GET_QUOTE_BY_ID.BASE, getQuoteByIdWorker);
}

/**
 * Remove item from quote
 * @param payload
 * @param {String} payload.quoteId - quote id, for what we want to remove item
 * @param {String} payload.name - name of the new task
 * @param {String} payload.partsPrice - price of the new task
 * @param {String} payload.laborPrice - labor price of the new task
 * @param {String} payload.bundlePrice - bundle price of the new task
 */
function* addQuoteTaskWorker({ payload }) {
  const { quoteId, position } = payload;

  yield call(
    callAndGetResponse,
    ActionTypes.ADD_EMPTY_QUOTE_TASK,
    `${APIConfiguration.repairer}quotes/${quoteId}/items`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {},
    },
    {
      quoteId,
      position,
    },
  );
}

/**
 * Remove item from quote
 * @param payload
 * @param {String} payload.quoteId - quote id, for what we want to update item
 * @param {String} payload.repairerQuoteItemId - editable item id
 * @param {String} payload.name - new name of the task
 * @param {String} payload.partsPrice - new price of the task
 * @param {String} payload.laborPrice - new labor price of the task
 * @param {String} payload.bundlePrice - new bundle price of the task
 */
function* editQuoteTaskWorker({ payload }) {
  const { quoteId, position, task: newTask } = payload;
  const allQuotes = yield select(Selectors.quotes);
  const oldTask = allQuotes.get(quoteId).quoteItemSummaries[position];
  const oldTaskId = get(oldTask, 'id');

  if (oldTaskId) {
    yield call(
      callAndGetResponse,
      ActionTypes.DELETE_BEFORE_EDIT_QUOTE_TASK,
      `${APIConfiguration.repairer}quotes/${quoteId}/items`,
      {
        method: SUPPORTED_METHODS.DELETE,
        json: {
          repairerQuoteItemId: oldTaskId,
        },
      },
      {
        quoteId,
        oldTaskId,
      },
    );
  }
  const bundlePrice = get(newTask, 'bundlePrice', 0);
  const prices =
    bundlePrice === 0
      ? {
          partsPrice: get(newTask, 'partsPrice', 0),
          laborPrice: get(newTask, 'laborPrice', 0),
        }
      : {
          bundlePrice,
        };

  yield call(
    callAndGetResponse,
    ActionTypes.EDIT_QUOTE_TASK,
    `${APIConfiguration.repairer}quotes/${quoteId}/items`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        name: get(newTask, 'name'),
        ...prices,
      },
    },
    {
      quoteId,
      position,
      task: newTask,
    },
  );
}

/**
 * Remove item from quote
 * @param payload
 * @param {String} payload.quoteId - quote id, to submit
 */
function* deleteQuoteTaskWorker({ payload }) {
  const { quoteId, taskId, position } = payload;

  if (taskId) {
    yield call(
      callAndGetResponse,
      ActionTypes.DELETE_QUOTE_TASK,
      `${APIConfiguration.repairer}quotes/${quoteId}/items`,
      {
        method: SUPPORTED_METHODS.DELETE,
        json: {
          repairerQuoteItemId: taskId,
        },
      },
      {
        quoteId,
        taskId,
        position,
      },
    );
  }
}

function* submitQuoteWorker({ payload }) {
  const { quoteId } = payload;
  const quotes = yield select(Selectors.quotes);
  const quote = quotes.get(quoteId);
  // prevent fail request for uneditable quotes.

  if (!quote || !quote.editable) {
    yield put(ActionTypes.SUBMIT_QUOTE.end());

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.SUBMIT_QUOTE,
    `${APIConfiguration.repairer}quotes/created`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        quoteId,
        comment: '',
      },
    },
    {
      quoteId,
    },
  );
  yield put(ActionTypes.SUBMIT_QUOTE.end());
}
function* submitQuoteWatcher() {
  yield takeEvery(ActionTypes.SUBMIT_QUOTE.BASE, submitQuoteWorker);
}

function* aswerQuoteWorker({ payload }) {
  const { quoteId, answer } = payload;

  yield call(
    callAndGetResponse,
    ActionTypes.VALIDATE_REJECT_QUOTE,
    `${APIConfiguration.repairer}quotes/answered`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        quoteId,
        clientAnswer: answer,
      },
    },
    {
      quoteId,
    },
  );
  yield put(ActionTypes.VALIDATE_REJECT_QUOTE.end());
}
function* answertQuoteWatcher() {
  yield takeEvery(ActionTypes.VALIDATE_REJECT_QUOTE.BASE, aswerQuoteWorker);
}

function* getFileQuotesWorker({ payload: fileId }) {
  const allQuotesResponse = yield callAndGetResponse(
    ActionTypes.GET_FILE_QUOTES,
    `${APIConfiguration.repairer}files/${fileId}/quotes`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  const quotes = get(allQuotesResponse, 'payload.value', []);

  yield put(
    ActionTypes.GET_FILE_QUOTES.end(
      maxBy(
        quotes.filter(({ state }) => state !== quoteStatus.DRAFT),
        ({ editedDate }) => new Date(editedDate).getTime(),
      ),
    ),
  );
}
function* getFileQuotesWatcher() {
  yield takeEvery(ActionTypes.GET_FILE_QUOTES.BASE, getFileQuotesWorker);
}

function* editQuoteTaskWatcher() {
  let pendingChanges = [];
  const chan = yield call(channel, buffers.sliding(1));

  yield takeEvery(
    [
      ActionTypes.EDIT_QUOTE_TASK.BASE,
      ActionTypes.DELETE_QUOTE_TASK.BASE,
      ActionTypes.ADD_EMPTY_QUOTE_TASK.BASE,
    ],
    function* enqueueAction(action) {
      const quoteId = get(action, ['payload', 'quoteId']);

      if (!pendingChanges[quoteId]) {
        pendingChanges[quoteId] = [];
      }
      pendingChanges[quoteId].push(action);
      yield put(chan, action);
    },
  );
  yield fork(function* launchWorker() {
    while (true) {
      yield take(chan);
      const pendingChangesToProcess = toPairs(
        mapValues(pendingChanges, (changes) =>
          map(
            groupBy(changes, (a) => a.payload.position),
            (a) => findLast(a),
          ),
        ),
      );

      pendingChanges = {};
       
      for (const [quoteId, changes] of pendingChangesToProcess) {
         
        for (const action of changes) {
          const saga = (() => {
            switch (action.type) {
              case ActionTypes.EDIT_QUOTE_TASK.BASE:
                return editQuoteTaskWorker;
              case ActionTypes.DELETE_QUOTE_TASK.BASE:
                return deleteQuoteTaskWorker;
              case ActionTypes.ADD_EMPTY_QUOTE_TASK.BASE:
                return addQuoteTaskWorker;
              default:
                return null;
            }
          })();

          yield call(saga, action);
        }
        if (get(pendingChanges, [quoteId, 'length'], 0) === 0) {
          yield put(ActionTypes.QUOTE_TASK_AUTO_SAVE.end(quoteId));
        }
      }
    }
  });
}

export default function* quoteSaga() {
  try {
    yield all([
      getQuoteByIdWatcher(),
      getCurrentDraftedQuoteWatcher(),
      getFileQuotesWatcher(),
      editQuoteTaskWatcher(),
      submitQuoteWatcher(),
      answertQuoteWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
