import { TABLE_ORDER } from './TableContent.types';

export const getOrder = (desc?: boolean) => {
  if (desc === true) {
    return TABLE_ORDER.DESC;
  }
  if (desc === false) {
    return TABLE_ORDER.ASC;
  }

  return TABLE_ORDER.NONE;
};

interface CheckIsMessageArgs {
  value:
    | {
        id: string;
        defaultMessage: string;
      }
    | any;
}
export const checkIsMessage = ({
  value = null,
}: CheckIsMessageArgs): boolean => {
  return Boolean(value && value.id && value.defaultMessage);
};
