import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponse, FileSummary } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/summary`;

export const getCustomerFileFullSummaryQuery = async ({
  fileId,
}: {
  fileId: string;
}) => {
  try {
    return await apiCall<BackResponse<FileSummary>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
