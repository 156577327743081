import { useSelector } from 'react-redux';

import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { getAllAvailableLanguages } from '@savgroup-front-common/core/src/domains/languages/selectors';
import { getSellersConfigWasLoaded } from '@savgroup-front-common/core/src/domains/sellerConfiguration/selectors';

export const useProfileData = () => {
  const languages = useSelector(getAllAvailableLanguages) as (
    | 'fr'
    | 'en'
    | 'de'
    | 'es'
  )[];

  const isLoaded = useSelector(getSellersConfigWasLoaded);

  const locale = useSelector(selectLocale);

  return {
    languages,
    isLoaded,
    locale,
  };
};
