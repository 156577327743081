import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/additionalInformation`;

export async function getFileAdditionalInformation({
  fileId,
  stateName,
  moduleDefinitionId,
  targetOnType,
  filterToStateName,
}) {
  if (!fileId || !stateName || !moduleDefinitionId) {
    return [];
  }

  const baseUrl = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

  const url = buildUrl(baseUrl, {
    filterStateName: stateName,
    filterModuleDefinitionId: moduleDefinitionId,
    targetOnType,
    filterToStateName,
  });

  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${this.name}`);
  }

  return response.value || [];
}
