import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/multiprocedures`;

export async function getMultiProcedure({ claimId, moduleId, stateId }) {
  const url = buildUrl(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId),
    { moduleId, stateId },
  );

  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to get procedures`);
  }

  return {
    payload: response.value,
    index: claimId,
  };
}
