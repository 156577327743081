import { createReducer } from '@savgroup-front-common/core/src/helpers';

import * as BusinessConst from '../businessConst';

export const initialState = {
  isLoaded: false,
  id: null,
  name: null,
  brandId: null,
  brandName: null,
  modelTypeId: null,
  modelTypeName: null,
};

function onGetProduct(state, payload) {
  return {
    ...initialState,
    id: payload,
  };
}

function onGetProductSucceeded(state, payload) {
  if (state.id !== payload.value.id) {return state;}

  return {
    ...state,
    isLoaded: true,
    ...payload.value,
  };
}

const productReducer = createReducer(initialState, {
  [BusinessConst.GET_PRODUCT.BASE]: onGetProduct,
  [BusinessConst.GET_PRODUCT.SUCCEEDED]: onGetProductSucceeded,
});

export default productReducer;
