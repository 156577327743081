import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}drafts`;

interface DeleteDraftCommandPayload {
  draftId: string;
}

type DeleteDraftCommand = (
  payload: DeleteDraftCommandPayload,
) => Promise<BaseBackResponse | BackResponseFailure>;

const deleteDraftCommand: DeleteDraftCommand = async ({ draftId }) => {
  try {
    return await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.DELETE,
      json: {
        draftId,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default deleteDraftCommand;
