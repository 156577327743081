import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FilterTree,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}modeltypes/tree/bySellerIds`;

interface GetModelTypesTreeBySellerIdsQuery {
  sellerIds: string[];
}

async function getModelTypesTreeBySellerIdsQuery({
  sellerIds,
}: GetModelTypesTreeBySellerIdsQuery): Promise<
  BackResponse<FilterTree[]> | BackResponseFailure
> {
  try {
    const url = buildUrl(ENDPOINT, { sellerIds });

    const response = await apiCall<BackResponse<FilterTree[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getModelTypesTreeBySellerIdsQuery;
