import {
  AppScheduledMaintenance,
  SCHEDULED_MAINTENANCE_STATUSES,
} from '@savgroup-front-common/types';

const getNextScheduledMaintenance = (
  appScheduledMaintenances: AppScheduledMaintenance[],
  componentName: string,
) => {
  return appScheduledMaintenances.reduce<AppScheduledMaintenance | undefined>(
    (acc, item) => {
      if (
        [
          SCHEDULED_MAINTENANCE_STATUSES.SCHEDULED,
          SCHEDULED_MAINTENANCE_STATUSES.IN_PROGRESS,
          SCHEDULED_MAINTENANCE_STATUSES.VERIFYING,
        ].includes(item.status)
      ) {
        if (
          acc?.scheduledFor &&
          new Date(acc?.scheduledFor).getTime() <
            new Date(item?.scheduledFor).getTime()
        ) {
          return acc;
        }

        if (
          item.components.some((component) => component.name === componentName)
        ) {
          return item;
        }

        return acc;
      }

      return acc;
    },
    undefined,
  );
};

export default getNextScheduledMaintenance;
