import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';
import { transitionRightOrLeft } from '@savgroup-front-common/core/src/animations/complexeStateAnimation';

export const $DocumentData = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  ${transitionRightOrLeft()};
`;
export const $DocumentName = styled.div`
  color: #fff;
  font-size: ${({ theme }) => theme.fonts.size.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const $DocumentInfo = styled.div`
  margin-top: 0.5rem;
  min-height: ${rem(18)};
  color: #fff;
  font-size: ${({ theme }) => theme.fonts.size.small};
  display: flex;
  align-items: center;
  > div:first-child:not(:last-child) {
    padding-top: ${rem(2)};
    padding-right: ${rem(4)};
  }
`;

export const $DocumentBarRow = styled(Row)`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.darkBackground};
`;

export const $DocumentBarCol = styled(Col)`
  position: relative;
`;
