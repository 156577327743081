import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';

import { useSearchContext } from '../../hooks';
import { SEARCH_ANIMATION_DURATION } from '../../Search.contants';

import NoFilters from './NoFilters';
import NoResults from './NoResults';
import ResultList from './ResultList';
import { $SearchBody } from './SearchBody.styles';

const SearchBody: FunctionComponent = () => {
  const { showNoResult, showNoFilter, listHeight } = useSearchContext();

  return (
    <$SearchBody $size={listHeight} data-testid="search-body">
      <ResultList />
      <Transition
        in={showNoResult}
        timeout={SEARCH_ANIMATION_DURATION.NO_RESULT + 200}
        unmountOnExit
      >
        {(animationState) => <NoResults animationState={animationState} />}
      </Transition>
      <Transition
        in={showNoFilter}
        timeout={SEARCH_ANIMATION_DURATION.NO_RESULT + 200}
        unmountOnExit
      >
        {(animationState) => <NoFilters animationState={animationState} />}
      </Transition>
    </$SearchBody>
  );
};

SearchBody.displayName = 'SearchBody';

export default SearchBody;
