import { SearchResult } from '../Search.types';

import getResultSize from './getResultSize';

function getResultListSize(
  results: SearchResult[],
  indexToStop?: number,
): number {
  return results.reduce<number>((acc, result, index) => {
    if (indexToStop !== undefined && index >= indexToStop) {
      return acc;
    }

    return acc + getResultSize(result);
  }, 0);
}

export default getResultListSize;
