export const GET_WORKFLOW_STATE = 'GET_WORKFLOW_STATE';
export const GET_WORKFLOW_STATE_CALLBACK = 'GET_WORKFLOW_STATE_CALLBACK';
export const GET_CARRIER_LABELS_FOR_FILE = 'GET_CARRIER_LABELS_FOR_FILE';

export const SET_CURRENT_STATE = 'SET_CURRENT_STATE';
export const UPDATE_PROGRESS_STATUS = 'UPDATE_PROGRESS_STATUS';
export const ERASE_TODO_ACTION = 'ERASE_TODO_ACTION';

export const UPDATE_WORKFLOW_STATE_STARTED = 'UPDATE_WORKFLOW_STATE_STARTED';
export const UPDATE_WORKFLOW_STATE_PENDING = 'UPDATE_WORKFLOW_STATE_PENDING';
export const UPDATE_WORKFLOW_STATE_SUCCESSFUL =
  'UPDATE_WORKFLOW_STATE_SUCCESSFUL';
export const UPDATE_WORKFLOW_STATE_ERRORED = 'UPDATE_WORKFLOW_STATE_ERRORED';
export const UPDATE_WORKFLOW_STATE_RESET = 'UPDATE_WORKFLOW_STATE_RESET';

export const TODO_PROGRESS_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  RESOLVED: 'RESOLVED',
  ERRORED: 'ERRORED',
};

export const GET_TODO_CALLBACK = 'GET_TODO_CALLBACK';
export const GET_TODO_FACETS_CALLBACK = 'GET_TODO_FACETS_CALLBACK';
export const ON_ERRORS = 'ON_ERRORS';
export const CLEAR = 'CLEAR';
