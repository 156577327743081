import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import {
  GetSparePartCatalogsBySupplierIdPayload,
  GetSparePartCatalogsPayload,
} from './actionCreators';
import * as types from './actionTypes';
import {
  selectGetSparePartCatalogBySupplierIdIsLoading,
  selectGetSparePartCatalogBySupplierIdWasLoaded,
  selectGetSparePartCatalogIsLoading,
  selectGetSparePartCatalogWasLoaded,
} from './selectors';

function* getSparePartCatalogWorker({
  payload,
}: {
  payload: GetSparePartCatalogsPayload;
}): Generator {
  const { sparePartCatalogId = null } = payload;

  if (!sparePartCatalogId) {
    return;
  }

  const isLoading = yield select(selectGetSparePartCatalogIsLoading, {
    sparePartCatalogId,
  });
  const wasLoaded = yield select(selectGetSparePartCatalogWasLoaded, {
    sparePartCatalogId,
  });

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_SPARE_PART_CATALOG,
    buildUrl(`${APIConfiguration.actor}sellers/sparePartCatalogs`, {
      sparePartCatalogIds: sparePartCatalogId,
    }),
    { method: SUPPORTED_METHODS.GET },
    { indexer: sparePartCatalogId },
  );

  yield put(types.GET_SPARE_PART_CATALOG.end({ sparePartCatalogId }));
}
function* getSparePartCatalogWatcher() {
  yield takeEvery<Action<GetSparePartCatalogsPayload>>(
    types.GET_SPARE_PART_CATALOG.BASE,
    getSparePartCatalogWorker,
  );
}

function* getSparePartCatalogsBySupplierIdWorker({
  payload,
}: {
  payload: GetSparePartCatalogsBySupplierIdPayload;
}): Generator {
  const { supplierId, sellerId, query } = payload;

  if (!supplierId || !sellerId) {
    return;
  }

  const isLoading = yield select(
    selectGetSparePartCatalogBySupplierIdIsLoading,
    { supplierId, query },
  );
  const wasLoaded = yield select(
    selectGetSparePartCatalogBySupplierIdWasLoaded,
    { supplierId, query },
  );

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID,
    buildUrl(
      `${APIConfiguration.sparePart}sparepart/withSellingPrice/fullTextSearch`,
      { supplierId, sellerId, searchTerm: query },
    ),
    { method: SUPPORTED_METHODS.GET },
    {
      supplierId,
      sellerId,
      indexer: `${supplierId}.${query}`,
    },
  );

  yield put(types.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID.end({ supplierId }));
}
function* getSparePartCatalogsBySupplierIdWatcher() {
  yield takeEvery<Action<GetSparePartCatalogsBySupplierIdPayload>>(
    types.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID.BASE,
    getSparePartCatalogsBySupplierIdWorker,
  );
}

export default function* sparePartCatalogSaga(): Generator {
  try {
    yield all([
      getSparePartCatalogWatcher(),
      getSparePartCatalogsBySupplierIdWatcher(),
    ]);
  } catch (error) {
    logCritical(error as any);
  }
}
