import difference from 'lodash/difference';
import find from 'lodash/find';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'redux-first-history';
import { all, cancel, fork, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ActionTypes } from '@savgroup-front-common/core/src/domains/i18n';

import { FILE_ROUTES, ROUTES } from '../constants/routing';

import folderClient from './fileInfo/fileTabs/client';
import folderProduct from './fileInfo/fileTabs/product';
import folderSummary from './fileInfo/fileTabs/summary';
import fileInfo from './fileInfo/saga';
import marketplaceAggregator from './marketplaceTickets/marketplaceAggregator/saga';
import orders from './orders/ordersSaga';

const viewSagas = {
  [ROUTES.TODOS_WITH_PARAMS]: fileInfo,
  [ROUTES.ALERTS_WITH_PARAMS]: fileInfo,
  [ROUTES.FILES]: fileInfo,
  [FILE_ROUTES.CLIENT]: folderClient,
  [FILE_ROUTES.SUMMARY]: folderSummary,
  [FILE_ROUTES.PRODUCT]: folderProduct,
  [ROUTES.MESSAGES]: marketplaceAggregator,
  [ROUTES.ORDERS]: orders,
};

const tasks = {};

function* viewSaga(viewSagaKey) {
  const saga = viewSagas[viewSagaKey];

  if (tasks[viewSagaKey]) {
    yield cancel(tasks[viewSagaKey]);
    tasks[viewSagaKey] = null;
  }

  if (saga) {
    tasks[viewSagaKey] = yield fork(saga, viewSagaKey);
  }
}

let currentPage = {};

function findPagename(pathname) {
  return find(
    Object.keys(viewSagas),
    (route) =>
      !!matchPath(pathname, {
        path: route,
        exact: true,
        strict: true,
      }),
  );
}

function trackPageAndChangeLocation({ payload = {} }) {
  const { location: { pathname = '' } = {} } = payload || {};

  if (currentPage.pathname !== pathname && pathname.length > 0) {
    currentPage = {
      pathname,
      pagename: findPagename(pathname) || ROUTES.PAGE_NOT_FOUND,
    };
  }

  return currentPage;
}

function* locationChangeWorker({ payload = {} } = {}) {
  const current = trackPageAndChangeLocation({ payload });

  const { pathname = '' } = current;

  if (pathname.length === 0) {
    return;
  }

  const viewSagaKeys = Object.keys(viewSagas).filter(
    (route) =>
      !!matchPath(pathname, {
        path: route,
        exact: false,
        strict: false,
      }),
  );

  yield all(
    difference(Object.keys(tasks), viewSagaKeys).map((viewSagaKey) => {
      const taskToCancel = tasks[viewSagaKey];

      if (taskToCancel) {
        tasks[viewSagaKey] = null;

        return cancel(taskToCancel);
      }

      return undefined;
    }),
  );
  yield all(viewSagaKeys.map((viewSagaKey) => viewSaga(viewSagaKey)));
}

function* locationChangeWatcher() {
  yield takeEvery(LOCATION_CHANGE, locationChangeWorker);
}
function* i18nChangeWatcher() {
  yield takeEvery(
    ActionTypes.CHANGE_LOCALE_LANGUAGE.BASE,
    locationChangeWorker,
  );
}

export default function* locationSaga() {
  try {
    yield all([locationChangeWatcher(), i18nChangeWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
