import { createAction } from '@reduxjs/toolkit';

import * as ActionTypes from './actionTypes';
import {
  EXPLODE_FILE_REQUEST,
  FOLDERS_FORCE_CLOSE_FILE,
  LOAD_FILE_INFO_PAGE,
  SPLIT_FILE_REQUEST,
  SUBMIT_ATTACHMENTS,
} from './actionTypes';

export const loadFileInfo = createAction<{ fileId: string }>(
  ActionTypes.LOAD_FILE_INFO.BASE,
);
export const forceCloseFile = createAction<{
  fileId: string;
  userId: string;
  closingType: string;
  comment: string;
  toCustomerComment?: string;
}>(FOLDERS_FORCE_CLOSE_FILE.BASE);
export const splitFileRequest = createAction(SPLIT_FILE_REQUEST.BASE);
export const explodeFileRequest = createAction(EXPLODE_FILE_REQUEST);

export const loadFileInfoPage = createAction(LOAD_FILE_INFO_PAGE.BASE);

export const hydrateFileSummary = createAction(
  ActionTypes.HYDRATE_FILE_SUMMARY.BASE,
);
export const submitAttachments = createAction(SUBMIT_ATTACHMENTS.BASE);
