import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}publicApi/createApiApplication`;

interface SetApiApplicationResponse extends BaseBackResponse {
  value: {
    id: string;
    name: string;
    primaryKey: string;
    primaryToken: string;
    secondaryKey: string;
    secondaryToken: string;
  };
  failure: false;
}
interface SetApiApplicationResponseFailure extends BaseBackResponse {
  failure: true;
}

async function setApiApplication({
  sellerId,
  name,
}: {
  sellerId: string;
  name: string;
}): Promise<SetApiApplicationResponse | SetApiApplicationResponseFailure> {
  try {
    const response = await apiCall<SetApiApplicationResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        sellerId,
        applicationName: name,
      },
    });

    return response;
  } catch (exception) {
    return { failure: true, errors: (exception as any).errors };
  }
}

export default setApiApplication;
