import { CommonWorkflowService } from '@savgroup-front-common/core/src/api';

import addUnexpectedProductsCommand from './addUnexpectedProductsCommand';
import createCaseIfNotExistCommand from './createCaseIfNotExistCommand';
import createFileAttachmentsFromControlCommand from './createFileAttachmentsFromControlCommand';
import { createWorkflowVersionCommand } from './createWorkflowVersionCommand';
import createWorkOrderCommand from './createWorkOrderCommand';
import { addExternalCarrierDepositLabelCommand } from './files/addExternalCarrierDepositLabelCommand';
import addExternalCarrierTrackingReferenceCommand from './files/addExternalCarrierTrackingReferenceCommand';
import assignFileToUserCommand from './files/assignFileToUserCommand';
import cancelTechnicalIntervention from './files/cancelTechnicalIntervention';
import changeStoreActor from './files/changeStoreActor';
import { createFileAttachmentFromControlCommand } from './files/createFileAttachmentFromControlCommand';
import deleteFileAttachmentCommand from './files/deleteFileAttachmentCommand';
import { getClosedFilesByOrderId } from './files/getClosedFilesByOrderId';
import { getCustomerFileFullSummaryQuery } from './files/getCustomerFileFullSummaryQuery';
import { getFileAdditionalInformation } from './files/getFileAdditionalInformation';
import getFileByReference from './files/getFileByReference';
import { getFileReactionsQuery } from './files/getFileReactionsQuery';
import getFileRiskCarrier from './files/getFileRiskCarrier';
import getFileStatusVisibilitiesQuery from './files/getFileStatusVisibilitiesQuery';
import { getLatestFilesByOrderId } from './files/getLatestFilesByOrderId';
import { getOpenedFilesByOrderId } from './files/getOpenedFilesByOrderId';
import renameFileAttachmentCommand from './files/renameFileAttachmentCommand';
import reScheduleTechnicalInterventionCommand from './files/reScheduleTechnicalInterventionCommand';
import setActorCommand from './files/setActorCommand';
import { setFileAdditionalInformation } from './files/setFileAdditionalInformation';
import { setFileAdditionalInformationWithFilesCommand } from './files/setFileAdditionalInformationWithFilesCommand';
import setFileAttachmentInfo from './files/setFileAttachmentInfo';
import setFileProductAdequacyCommand from './files/setFileProductAdequacyCommand';
import setNewWorkflowVersion from './files/setNewWorkflowVersion';
import setRefund from './files/setRefund';
import unassignFileCommand from './files/unassignFileCommand';
import { updateDepositStoreCommand } from './files/updateDepositStoreCommand';
import updateFileAddress from './files/updateFileAddress';
import { updateFileOwnerNotificationCommand } from './files/updateFileOwnerNotificationCommand';
import updateStoreActorCommand from './files/updateStoreActorCommand';
import { upsertModuleDefinitionLocalesCommand } from './files/upsertModuleDefinitionLocalesCommand';
import forceCloseFilesCommand from './forceCloseFilesCommand';
import { getFileSellerActorsQuery } from './getFileSellerActorsQuery';
import { getHistoricFileInfo } from './getHistoricFileInfo';
import { getHistoricFileList } from './getHistoricFileList';
import { getHistoryItemsQuery } from './getHistoryItemsQuery';
import getIsAnyFileCreatedByOwnerQuery from './getIsAnyFileCreatedByOwnerQuery';
import getLastWorkflowVersion from './getLastWorkflowVersion';
import getModelSafetyriskFile from './getModelSafetyriskFile';
import getOpenedFilesQuery from './getOpenedFilesQuery';
import getProductHistoryByOwnerProductId from './getProductHistoryByOwnerProductId';
import getProductOrientationsQuery from './getProductOrientationsQuery';
import getRefundPolicyQuery from './getRefundPolicyQuery';
import getWorkTypeBySellerQuery from './getWorkTypeBySellerQuery';
import instanciateCommercialGestureModuleCommand from './instanciateCommercialGestureModuleCommand';
import instanciatePaymentRefundModuleCommand from './instanciatePaymentRefundModuleCommand';
import removeUnexpectedProductCommand from './removeUnexpectedProductCommand';
import setAdditionalInformationFactoryCode from './setAdditionalInformationFactoryCode';
import setDetailedCommercialGestureCommand from './setDetailedCommercialGestureCommand';
import setDetailedCommercialSolutionCommand from './setDetailedCommercialSolutionCommand';
import setFileAdditionalInformationCommand from './setFileAdditionalInformationCommand';
import setModelSafetyriskFile from './setModelSafetyriskFile';
import updateFileSolutionCommand from './updateFileSolutionCommand';
import { updateRequestStatusCommand } from './updateRequestStatusCommand';
import updateWarrantyCommand from './updateWarrantyCommand';

export const WorkflowService = {
  getHistoryItemsQuery,
  getFileReactionsQuery,
  updateDepositStoreCommand,
  updateFileOwnerNotificationCommand,
  upsertModuleDefinitionLocalesCommand,
  createWorkflowVersionCommand,
  addExternalCarrierDepositLabelCommand,
  createFileAttachmentFromControlCommand,
  assignFileToUserCommand,
  unassignFileCommand,
  setFileProductAdequacyCommand,
  changeStoreActor,
  updateStoreActorCommand,
  getProductOrientationsQuery,
  getCustomerFileFullSummaryQuery,
  deleteFileAttachmentCommand,
  renameFileAttachmentCommand,
  createCaseIfNotExistCommand,
  getWorkTypeBySellerQuery,
  getProductHistoryByOwnerProductId,
  getOpenedFilesQuery,
  getLastWorkflowVersion,
  createWorkOrderCommand,
  getClosedFilesByOrderId,
  getFileAdditionalInformation,
  getFileByReference,
  getFileHandling: CommonWorkflowService.getFileHandling,
  getFileRiskCarrier,
  getLatestFilesByOrderId,
  getOpenedFilesByOrderId,
  setFileAdditionalInformation,
  updateFileAddress,
  setRefund,
  setNewWorkflowVersion,
  setFileAttachmentInfo,
  setActorCommand,
  reScheduleTechnicalInterventionCommand,
  cancelTechnicalIntervention,
  addExternalCarrierTrackingReferenceCommand,
  getFileStatusVisibilitiesQuery,
  getModelSafetyriskFile,
  setModelSafetyriskFile,
  createFileAttachmentsFromControlCommand,
  getHistoricFileInfo,
  getHistoricFileList,
  updateWarrantyCommand,
  addUnexpectedProductsCommand,
  removeUnexpectedProductCommand,
  updateRequestStatusCommand,
  getFileSellerActorsQuery,
  getRefundPolicyQuery,
  setDetailedCommercialSolutionCommand,
  updateFileSolutionCommand,
  instanciatePaymentRefundModuleCommand,
  forceCloseFilesCommand,
  instanciateCommercialGestureModuleCommand,
  setDetailedCommercialGestureCommand,
  setFileAdditionalInformationCommand,
  setAdditionalInformationFactoryCode,

  setFileAdditionalInformationWithFilesCommand,
  getIsAnyFileCreatedByOwnerQuery,
};
