import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';
import { SimpleAnimationProps } from '@savgroup-front-common/core/src/animations/simpleAnimation';

interface $ResultRelativeProps extends SimpleAnimationProps {
  $size: number;
}

export const $SearchBody = styled.div<$ResultRelativeProps>`
  position: relative;
  height: ${({ $size = 0 }) => rem($size)};
  overflow: hidden;
`;
