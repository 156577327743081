import { defineMessages } from 'react-intl';

export default defineMessages({
  file: {
    id: 'view.ExplodeFileModal.file',
    defaultMessage: 'File {order}',
  },
  initializing: {
    id: 'view.ExplodeFileModal.initializing',
    defaultMessage: 'Initializing the file...',
  },
  preparingToTransition: {
    id: 'view.ExplodeFileModal.preparingToTransition',
    defaultMessage: 'Preparing to apply the chosen transition',
  },
  goodToGo: {
    id: 'view.ExplodeFileModal.goodToGo',
    defaultMessage: 'Good to go',
  },
  pleaseWait: {
    id: 'view.ExplodeFileModal.pleaseWait',
    defaultMessage: 'Please wait and do not close the page',
  },
  explodedSuccessfully: {
    id: 'view.ExplodeFileModal.explodedSuccessfully',
    defaultMessage: 'File was successfully exploded!',
  },
  messageErrored: {
    id: 'view.messageBox.messageErrored',
    defaultMessage: 'Errored',
  },
  viewFile: {
    id: 'view.orders.viewFile',
    defaultMessage: 'View claim',
  },
  explodeFileTitle: {
    id: 'viw.ExplodeFileModal.title',
    defaultMessage: 'Explode file',
  },
});
