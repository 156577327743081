import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}console/fullconfiguration`;

export enum ConfigurationItemType {
  ENTITY = 'Entity',
  RULE = 'Rule',
  TABS = 'Tabs',
}

export interface ConsoleConfigurationItem {
  localizationKey?: string;
  iconKey?: string;
  routingKey?: string;
  type: ConfigurationItemType;
  importType: string;
  tabs?: ConsoleConfigurationItem[];
}

export interface ConsoleConfiguration {
  entities: ConsoleConfigurationItem[];
  rules: ConsoleConfigurationItem[];
}

export async function getConsoleFullConfigurationQuery(): Promise<
  BackResponse<ConsoleConfiguration> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<ConsoleConfiguration>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
