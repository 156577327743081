import React, { FunctionComponent, ReactElement } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import { useSearchContext } from '../../../hooks';
import { SEARCH_ANIMATION_DURATION } from '../../../Search.contants';

import {
  $NoResultContainer,
  $NoResultDescription,
  $NoResultIcon,
  $NoResultTitle,
} from './SearchDisplayError.styles';

interface SearchDisplayErrorProps {
  animationState: TransitionStatus;
  title: MessageType;
  body: MessageType;
  icon: ReactElement;
  dataTestId?: string;
}

const SearchDisplayError: FunctionComponent<
  React.PropsWithChildren<SearchDisplayErrorProps>
> = ({ animationState, title, body, icon, dataTestId }) => {
  const { isLoading } = useSearchContext();

  return (
    <$NoResultContainer
      data-testid={dataTestId}
      animationState={animationState}
      animationDuration={SEARCH_ANIMATION_DURATION.NO_RESULT}
    >
      <div>
        <$NoResultIcon
          animationDuration={500}
          animationDelay={100}
          $isLoading={isLoading}
        >
          {icon}
        </$NoResultIcon>
        <$NoResultTitle
          animationState={animationState}
          animationDelay={100}
          animationDuration={SEARCH_ANIMATION_DURATION.NO_RESULT}
        >
          <SafeFormattedMessageWithoutSpread message={title} />
        </$NoResultTitle>
        <$NoResultDescription
          animationState={animationState}
          animationDelay={200}
          animationDuration={SEARCH_ANIMATION_DURATION.NO_RESULT}
        >
          <SafeFormattedMessageWithoutSpread message={body} />
        </$NoResultDescription>
      </div>
    </$NoResultContainer>
  );
};

SearchDisplayError.displayName = 'NoResults';

export default SearchDisplayError;
