import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}console/dependentRules`;

const getDependentRulesCommand = async ({
  entityId,
  sellerId,
  entityImportType,
}: {
  entityId: string;
  sellerId: string;
  entityImportType: string;
}): Promise<BackResponse<string[]> | BackResponseFailure> => {
  try {
    const response = await apiCall<BackResponse<string[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: { entityId, sellerId, entityImportType },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getDependentRulesCommand;
