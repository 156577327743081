import { fromJS } from 'immutable';
import get from 'lodash/get';
import { all, put, take, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ActionCreators as CommonWorkflowActionCreators } from '@savgroup-front-common/core/src/domains/workflow';

import { submitQuote } from '../actionCreators';
import {
  ActionCreators as QuoteActionCreators,
  ActionTypes as QuoteActionTypes,
} from '../index';

import * as ActionTypes from './actionTypes';

function* startEditQuoteWorker({ payload }) {
  const [
    ,
    {
      meta: { quoteId },
    },
  ] = yield all([
    put(
      QuoteActionCreators.getCurrentDraftedQuote(
        fromJS({
          fileId: payload.fileId,
          sellerId: payload.sellerId,
        }),
      ),
    ),
    take(QuoteActionTypes.GET_CURRENT_DRAFTED_QUOTE.END),
  ]);

  yield put(QuoteActionCreators.getQuoteById(quoteId));
}
function* startEditQuoteWatcher() {
  yield takeEvery(ActionTypes.START_EDIT_QUOTE, startEditQuoteWorker);
}

function* customSubmitQuoteWorker({ payload }) {
  const { quoteId } = get(payload, 'quoteInfo', {});

  const { fromState, targetWorkflowState, module, fileId, comment } = get(
    payload,
    'transition',
    {},
  );

  yield all([
    put(submitQuote({ quoteId })),
    take(QuoteActionTypes.SUBMIT_QUOTE.END),
  ]);

  yield put(
    CommonWorkflowActionCreators.setUserActionAndHydrateFileSummary({
      fromState: fromState.name,
      toState: targetWorkflowState.name,
      module,
      fileId,
      comment,
    }),
  );
}
function* customSubmitQuoteWatcher() {
  yield takeEvery(ActionTypes.CUSTOM_SUBMIT_QUOTE, customSubmitQuoteWorker);
}

export default function* editQuoteSaga() {
  try {
    yield all([startEditQuoteWatcher(), customSubmitQuoteWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
