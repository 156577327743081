export const FILTER_TITLES = {
  ACTIONS: 'actions',
  PRIORITY_LEVEL: 'priorityLevel',
};

export const FILTER_NAMES = {
  LANGUAGES: 'Languages',
  STATUS_TICKET_FILTERS: 'StatusTicketFilters',
  SALES_CHANNELS: 'SalesChannels',
  ORDER_STATUS: 'OrderStatus',
  SELLERS: 'Sellers',
  STOCK_ITEM_STATUS: 'StockItemStatus',
  HANDLING_TYPE: 'HandlingType',
  REQUEST_LINE_STATUS: 'RequestLineStatus',
  PARTNER_SCENARIO_TYPE: 'PartnerScenarioType',
  PRIORITIES: 'Priorities',
  ASSIGNMENT: 'Assignment',
};

export const TICKETS_ORDER_VALUES = {
  BY_ALL_MESSAGES: 'BY_ALL_MESSAGES',
};
