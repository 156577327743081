import { SearchResult } from '../Search.types';

import getNextSearchFocus from './getNextSearchFocus';

interface SearchSuperiorIndexToFocusArgs {
  results: SearchResult[];
  indexFocus?: number;
}

const searchSuperiorIndexToFocus = ({
  results,
  indexFocus = 0,
}: SearchSuperiorIndexToFocusArgs): { indexFocus: number } => {
  for (let index = 1; index < results.length; index += 1) {
    const result = getNextSearchFocus({ results, index, indexFocus });

    if (result !== undefined) {return { indexFocus: result };}
  }

  return { indexFocus };
};

export default searchSuperiorIndexToFocus;
