import { defineMessages } from 'react-intl';

export default defineMessages({
  orders: {
    id: 'view.search.orders',
    defaultMessage:
      'Orders - {count, plural, one {{count} result} other {{count} results}}',
  },
  files: {
    id: 'view.search.folder',
    defaultMessage:
      'Files - {count, plural, one {{count} result} other {{count} results}}',
  },
  sparePartRequestLines: {
    id: 'view.search.sparePartRequestLines',
    defaultMessage:
      'Spare part request lines - {count, plural, one {{count} result} other {{count} results}}',
  },
  stockItems: {
    id: 'view.search.stockItems',
    defaultMessage:
      'Stock items - {count, plural, one {{count} result} other {{count} results}}',
  },
});
