import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}console/restrictedColumns`;

export enum ColumnRestriction {
  DISABLED = 'Disabled',
  READ_ONLY = 'ReadOnly',
  MODIFY_ONLY = 'ModifyOnly',
  CUSTOM = 'Custom',
}

export interface ConsoleRestrictedColumnsSummary {
  columnName?: string;
  restriction: ColumnRestriction;
}

export async function getConsoleRestrictedColumnsQuery({
  sellerId,
  importType,
}: {
  sellerId: string;
  importType: string;
}): Promise<
  BackResponse<ConsoleRestrictedColumnsSummary[]> | BackResponseFailure
> {
  try {
    const response = await apiCall<
      BackResponse<ConsoleRestrictedColumnsSummary[]>
    >(buildUrl(ENDPOINT, { sellerId, importType }), {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
