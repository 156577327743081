import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';

interface ImportDraftArgs {
  draftId: string;
}

const ENDPOINT = `${APIConfiguration.import}drafts/imported`;

export default async function importDraft(
  payload: ImportDraftArgs,
): Promise<void> {
  const response = await apiCall(ENDPOINT, {
    method: SUPPORTED_METHODS.POST,
    json: payload,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to import draft`);
  }
}
