import { Cart } from 'iconoir-react';
import React, { FC } from 'react';

import {
  SparePartIcon,
  StockIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import FolderIcon from '@savgroup-front-common/core/src/protons/IconsNewDesign/Folder.icon';

const ResultIcon: FC<{ iconName: string; color?: string }> = ({
  iconName,
  color = 'rgba(83, 105, 235, 1)',
}) => {
  switch (iconName) {
    case 'Order':
      return <Cart width="16px" height="16px" color={color} />;
    case 'Stock':
      return <StockIcon size="16px" color={color} />;
    case 'Sparepart':
      return <SparePartIcon size="16px" color={color} />;
    case 'Folder':
    default:
      return <FolderIcon size="16" color={color} />;
  }
};

ResultIcon.displayName = 'ResultIcon';
export default ResultIcon;
