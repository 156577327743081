import Immutable from 'immutable';
import concat from 'lodash/concat';
import { createSelector } from 'reselect';

import { Selectors as fileAttachmentsSelectors } from '@savgroup-front-common/core/src/domains/attachments';
import { attachmentsListOrigins } from '@savgroup-front-common/core/src/domains/attachments/constants';

import { ControlRootState } from '../ControlRootState';

export const selectAttachmentsByFileId = createSelector(
  [
    fileAttachmentsSelectors.uploadAttachments,
    (_: ControlRootState, { fileId }: { fileId: string }) => fileId,
  ],
  (attachments, fileId) =>
    attachments.get(fileId) as Immutable.Map<
      string,
      Immutable.Map<string, unknown>
    >,
);

export const attachmentsListSelector = createSelector(
  [
    fileAttachmentsSelectors.filesAttachmentsListSelector,
    (_: ControlRootState, { fileId }: { fileId: string }) => fileId,
  ],
  (attachments, fileId) => {
    const controlFilesValue = attachments.getIn([
      attachmentsListOrigins.CONTROL,
      fileId,
      'value',
    ]);
    const controlFilesisLoaded = attachments.getIn([
      attachmentsListOrigins.CONTROL,
      fileId,
      'isLoaded',
    ]);

    const myAccountFilesValue = attachments.getIn([
      attachmentsListOrigins.MYACCOUNT,
      fileId,
      'value',
    ]);
    const myAccountFilesIsLoaded = attachments.getIn([
      attachmentsListOrigins.MYACCOUNT,
      fileId,
      'isLoaded',
    ]);

    const concatenatedFiles = concat(
      controlFilesValue,
      myAccountFilesValue,
    ).filter((x) => x);

    return {
      value: concatenatedFiles,
      isLoaded: controlFilesisLoaded && myAccountFilesIsLoaded,
    };
  },
);

interface Attachment {
  progress: number;
  name: string;
  isDeleting?: boolean;
  hasErrors?: boolean;
  id?: string;
  cancellationSignal?: () => void;
  internalId?: string;
  fileAttachmentId?: string;
  fileButtonOrigin?: unknown;
}

export const selectUploadAttachmentsListByFileId = createSelector(
  [selectAttachmentsByFileId],
  (attachments) => {
    return attachments
      ? attachments.toArray().map((attachment) => {
          const value = attachment.get('value') as Attachment;

          return {
            name: value?.name,
            progress: value?.progress,
            cancellationSignal: value?.cancellationSignal,
            fileAttachmentId: value?.fileAttachmentId,
            id: value?.id,
            fileButtonOrigin: value?.fileButtonOrigin,
          };
        })
      : [];
  },
);
