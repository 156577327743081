import get from 'lodash/get';
import { matchPath } from 'react-router-dom';
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors/router';
import { storeActionCreators } from '@savgroup-front-common/core/src/domains/store';
import { ActionCreators as WorkflowActionCreators } from '@savgroup-front-common/core/src/domains/workflow';

import { ActionCreators, ActionTypes } from '../marketplaceTickets';
import { loadOwnerInfo } from '../owner/actionCreators';

import {
  loadMarketplaceDetailedOrder,
  loadMarketplaceOrders,
} from './actionCreators';
import { LOAD_MARKETPLACE_DETAILED_ORDER } from './actionTypes';
import { defaultOrderSearchQuery } from './reducer';
import * as OrdersSelectors from './selectors';
import {
  currentOrderOwnerIdSelector,
  getDetailedOrderByOrderId,
  selectOrderQuery,
  selectOrders,
} from './selectors';

function* refreshInfoOnClaims() {
  const orderId = yield select(OrdersSelectors.currentOrderIdSelector);
  const ownerId = yield select(currentOrderOwnerIdSelector);

  yield put(ActionCreators.loadClaimInfoByOrder({ orderId, ownerId }));
}
function* claimDeletionWatcher() {
  yield takeLatest(
    [ClaimActionTypes.DELETE_CLAIM_BY_ID.END],
    refreshInfoOnClaims,
  );
}

function* refreshOnClaimGroupDeletion() {
  const orderId = yield select(OrdersSelectors.currentOrderIdSelector);
  const ownerId = yield select(currentOrderOwnerIdSelector);

  yield all([
    put(ActionCreators.loadClaimInfoByOrder({ orderId, ownerId })),
    put(loadMarketplaceDetailedOrder(orderId)),
    take(LOAD_MARKETPLACE_DETAILED_ORDER.END),
  ]);
}
function* claimGroupDeletionWatcher() {
  yield takeLatest(
    [ClaimActionTypes.DELETE_CLAIM_GROUP_BY_ID.END],
    refreshOnClaimGroupDeletion,
  );
}

function* refreshOrder(orderId) {
  yield all([
    put(loadMarketplaceDetailedOrder(orderId)),
    take(LOAD_MARKETPLACE_DETAILED_ORDER.END),
  ]);
}

export function* orderDetailsAndClaimCreationModule(orderId) {
  yield all([
    put(loadMarketplaceDetailedOrder(orderId)),
    take(LOAD_MARKETPLACE_DETAILED_ORDER.END),
  ]);
  const details = yield select(getDetailedOrderByOrderId, {
    orderId,
  });
  const { products } = details;
  const storeId = details.purchaseStoreId;

  const ownerProductIds = products.map(({ ownerProductId }) => ownerProductId);

  yield put(WorkflowActionCreators.loadOwnerProductFiles({ ownerProductIds }));

  if (storeId) {
    yield put(storeActionCreators.getStore({ storeId }));
  }
  const ownerId = yield select(currentOrderOwnerIdSelector);

  yield put(ActionCreators.loadClaimInfoByOrder({ orderId, ownerId }));
  yield put(ActionCreators.loadMarketplaceTicketsByOrder(orderId));
  if (ownerId) {
    yield put(loadOwnerInfo(ownerId));
  }

  yield all([claimDeletionWatcher(), claimGroupDeletionWatcher()]);
}

export default function* mainSaga(route) {
  const path = yield select(pathname);

  const options = matchPath(path, {
    path: route,
    exact: false,
    strict: false,
  });
  const orderId = get(options, 'params.orderId');

  const dataIsUpToDate = (data) => {
    return data && data.isLoaded && !data.isDirty;
  };

  let orders = yield select(selectOrders);

  orders = orders[defaultOrderSearchQuery];
  if (!dataIsUpToDate(orders)) {
    const searchQuery = yield select(selectOrderQuery);

    yield put(loadMarketplaceOrders({ searchQuery, firstQuery: true }));
  }

  if (orderId) {
    yield takeEvery(
      ActionTypes.CREATE_OWNER_FOR_ORDER.END,
      function* refreshOrderInfoOnOwnerCreation() {
        yield call(refreshOrder, orderId);
      },
    );
    yield call(orderDetailsAndClaimCreationModule, orderId);
  }
}
