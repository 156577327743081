import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.search}search/filter/indexes`;

export interface FacetCategory {
  name: string;
  facets: {
    count: number;
    name: string;
    key: string;
    label: string;
  }[];
}

interface GetFiltersFacetsArgs {
  tenantId: string;
}

async function getFiltersFacets({
  tenantId,
}: GetFiltersFacetsArgs): Promise<
  BackResponse<FacetCategory[]> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<FacetCategory[]>>(
      buildUrl(ENDPOINT, { tenantId }),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFiltersFacets;
