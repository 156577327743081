import { defineMessages } from 'react-intl';

export default defineMessages({
  seemore: {
    id: 'view.appStatus.seeMore',
    defaultMessage: 'See more',
  },
  lastUpdate: {
    id: 'view.appStatus.lastUpdate',
    defaultMessage: 'Last update: {time}',
  },
  scheduleTime: {
    id: 'view.appStatus.scheduleTime',
    defaultMessage: 'Schedule time: {time}',
  },
  scheduledMaintenanceTitle: {
    id: 'view.appStatus.maintenanceMode.title',
    defaultMessage: 'Maintenance',
  },
  scheduledMaintenanceBody: {
    id: 'view.appStatus.maintenanceMode.body',
    defaultMessage: 'We are currently updating the app, please comeback later',
  },
  scheduledMaintenanceByPass: {
    id: 'view.appStatus.maintenanceMode.byPass',
    defaultMessage: "I'm admin, i don't care",
  },
});
