import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { AppIncident } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';

import messages from './messages';
import { $ToastLastUpdate, $ToastLink } from './ToastIncident.styles';

interface ToastIncidentProps {
  incident: AppIncident;
}

const ToastIncident: FunctionComponent<ToastIncidentProps> = ({ incident }) => {
  const intl = useIntl();

  return (
    <>
      {incident.incidentUpdates[0].body}
      <$ToastLink href={incident.shortlink} isExternal target="_blank">
        <SafeFormattedMessageWithoutSpread message={messages.seemore} />
      </$ToastLink>
      <$ToastLastUpdate>
        <SafeFormattedMessageWithoutSpread
          message={messages.lastUpdate}
          values={{
            time: intl.formatDate(incident.updatedAt, {
              day: 'numeric',
              month: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
          }}
        />
      </$ToastLastUpdate>
    </>
  );
};

ToastIncident.displayName = 'ToastIncident';

export default ToastIncident;
