import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/debugging/actorGeographic`;

interface GetRulesDebuggingActorGeographic {
  claimId?: string;
  onlyMatchingRules?: boolean;
}

interface Value {
  inputResults: Record<string, string>[];
}

interface GetRulesDebuggingActorGeographicResponse {
  value: Value;
}

async function getRulesDebuggingActorGeographic({
  claimId,
  onlyMatchingRules,
}: GetRulesDebuggingActorGeographic): Promise<Value> {
  const functionName = 'getRulesDebuggingActorGeographic';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  const response = await apiCall<GetRulesDebuggingActorGeographicResponse>(
    buildUrl(url, { onlyMatchingRules }),
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  return response.value;
}

export default getRulesDebuggingActorGeographic;
