import React from 'react';
import { useTimeout } from 'react-use';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { MultiBanners } from '@savgroup-front-common/core/src/atoms/Banners';
import { FullScreenLoader } from '@savgroup-front-common/core/src/molecules/FullScreenLoader';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';

import messages from './messages';
import { OAuthContainer } from './OAuthServerCallback.styles';

const defaultNotification = [
  buildNotification({
    message: messages.isTakingTooLong,
    notificationType: NOTIFICATION_TYPES.ALERT,
  }),
];

const TIME_TOO_LONG = 10000;

export default function LoadingScreen({ notifications }) {
  const [ready] = useTimeout(TIME_TOO_LONG);

  const isTooLong = ready();

  const innerNotifications =
    isTooLong && notifications.length === 0
      ? defaultNotification
      : notifications;

  return (
    <OAuthContainer>
      <MultiBanners notifications={innerNotifications} />

      <FullScreenLoader
        isOpen={notifications.length === 0}
        message={messages.isLoading}
      />
    </OAuthContainer>
  );
}
