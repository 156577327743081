import React, { FunctionComponent } from 'react';

import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { $Backlink } from './Backlink.styles';
import messages from './messages';

interface BacklinkProps {
  href: string;
}

const Backlink: FunctionComponent<React.PropsWithChildren<BacklinkProps>> = ({
  href,
}) => {
  return (
    <$Backlink href={href}>
      <ChevronLeftIcon />
      <SafeFormattedMessageWithoutSpread message={messages.backLabel} />
    </$Backlink>
  );
};

Backlink.displayName = 'Backlink';

export default Backlink;
