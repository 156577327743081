import { Calendar } from 'iconoir-react';
import React from 'react';

import AddCircleOutlineIcon from '../AddCircleOutline.icon';
import AddDatabaseScriptIcon from '../AddDatabaseScript.icon';
import BaselineEcoIcon from '../BaselineEco.icon';
import BoxIcon from '../Box.icon';
import BreakdownIcon from '../Breakdown.icon';
import CardWalletIcon from '../CardWallet.icon';
import CartIcon from '../Cart.icon';
import ChatLinesIcon from '../ChatLines.icon';
import CheckCircleOutlineIcon from '../CheckCircleOutline.icon';
import ConsumableIcon from '../Consumable.icon';
import CrackedEggIcon from '../CrackedEgg.icon';
import CreditCardIcon from '../CreditCard.icon';
import CrossLightIcon from '../Cross/CrossLight.icon';
import DeliveryIcon from '../Delivery.icon';
import DeliveryBoxIcon from '../DeliveryBox.icon';
import DeliveryTruckIcon from '../DeliveryTruck.icon';
import DepositIcon from '../Deposit.icon';
import ExchangeIcon from '../Exchange.icon';
import { FormatListNumberedIcon } from '../FormatListNumbered/FormatListNumbered.icon';
import GridRemoveIcon from '../GridRemove.icon';
import GroupIcon from '../Group.icon';
import HeadsetHelpIcon from '../HeadsetHelp.icon';
import HighPriorityIcon from '../HighPriority.icon';
import HomeLightIcon from '../Home/HomeLight.icon';
import InsertDriveFileIcon from '../InsertDriveFile.icon';
import LaptopIcon from '../Laptop.icon';
import LightBulbOnIcon from '../LightBulbOn.icon';
import MediaImageIcon from '../MediaImage.icon';
import MultiwindowIcon from '../Multiwindow.icon';
import NewspaperIcon from '../Newspaper.icon';
import OpenBookIcon from '../OpenBook.icon';
import PackagesIcon from '../Packages.icon';
import PhoneIcon from '../Phone.icon';
import PostAddIcon from '../PostAdd.icon';
import QuestionMarkIcon from '../QuestionMark.icon';
import ReceiveEurosIcon from '../ReceiveEuros.icon';
import RefreshDoubleIcon from '../RefreshDouble.icon';
import SadFaceIcon from '../SadFace.icon';
import ScanBarcodeIcon from '../ScanBarcode.icon';
import SendMailIcon from '../SendMail.icon';
import ShieldIcon from '../Shield.icon';
import SleeperChairIcon from '../SleeperChair.icon';
import SmallShopIcon from '../SmallShop.icon';
import SparePartIcon from '../SparePart.icon';
import StarsIcon from '../Stars.icon';
import UmbrellaIcon from '../Umbrella.icon';
import VeganCircleIcon from '../VeganCircle.icon';

import { ICONS_TYPE } from './Icon.types';

interface ConfigurationConsoleIconProps {
  icon?: ICONS_TYPE;
  color?: string;
  size?: string;
}

const ConfigurationConsoleIcon: React.FC<ConfigurationConsoleIconProps> = ({
  icon,
  color = 'black',
  size,
}) => {
  if (!icon) {
    return <></>;
  }

  switch (icon) {
    case ICONS_TYPE.ADD_CIRCLE_OUTLINE_ICON:
      return <AddCircleOutlineIcon color={color} size={size} />;
    case ICONS_TYPE.ADD_DATABASE_SCRIPT_ICON:
      return <AddDatabaseScriptIcon color={color} size={size} />;
    case ICONS_TYPE.BASELINE_ECO:
      return <BaselineEcoIcon color={color} size={size} />;
    case ICONS_TYPE.BOX_ICON:
      return <BoxIcon color={color} size={size} />;
    case ICONS_TYPE.TOOLS_ICON:
      return <SparePartIcon color={color} size={size} />;
    case ICONS_TYPE.BREAKDOWN_ICON:
      return <BreakdownIcon color={color} size={size} />;
    case ICONS_TYPE.CALENDAR_ICON:
      return <Calendar color={color} width={size} height={size} />;
    case ICONS_TYPE.CARD_WALLET_ICON:
      return <CardWalletIcon color={color} size={size} />;
    case ICONS_TYPE.CART_ICON:
      return <CartIcon color={color} size={size} />;
    case ICONS_TYPE.CHAT_LINES_ICON:
      return <ChatLinesIcon color={color} size={size} />;
    case ICONS_TYPE.CHECK_CIRCLE_OUTLINE_ICON:
      return <CheckCircleOutlineIcon color={color} size={size} />;
    case ICONS_TYPE.CONSUMABLE_ICON:
      return <ConsumableIcon color={color} size={size} />;
    case ICONS_TYPE.CRACKED_EGG_ICON:
      return <CrackedEggIcon color={color} size={size} />;
    case ICONS_TYPE.CREDIT_CARD_ICON:
      return <CreditCardIcon color={color} size={size} />;
    case ICONS_TYPE.CROSS_ICON:
      return <CrossLightIcon color={color} size={size} />;
    case ICONS_TYPE.DELIVERY_ICON:
      return <DeliveryIcon color={color} size={size} />;
    case ICONS_TYPE.DELIVERY_BOX_ICON:
      return <DeliveryBoxIcon color={color} size={size} />;
    case ICONS_TYPE.DELIVERY_TRUCK_ICON:
      return <DeliveryTruckIcon color={color} size={size} />;
    case ICONS_TYPE.GRID_REMOVE_ICON:
      return <GridRemoveIcon color={color} size={size} />;
    case ICONS_TYPE.HEADSET_HELP_ICON:
      return <HeadsetHelpIcon color={color} size={size} />;
    case ICONS_TYPE.HIGH_PRIORITY_ICON:
      return <HighPriorityIcon color={color} size={size} />;
    case ICONS_TYPE.HOME_ICON:
      return <HomeLightIcon color={color} size={size} />;
    case ICONS_TYPE.LAPTOP_ICON:
      return <LaptopIcon color={color} size={size} />;
    case ICONS_TYPE.LIGHT_BULB_ON_ICON:
      return <LightBulbOnIcon color={color} size={size} />;
    case ICONS_TYPE.MEDIA_IMAGE_ICON:
      return <MediaImageIcon color={color} size={size} />;
    case ICONS_TYPE.MULTIWINDOW_ICON:
      return <MultiwindowIcon color={color} size={size} />;
    case ICONS_TYPE.NEWSPAPER_ICON:
      return <NewspaperIcon color={color} size={size} />;
    case ICONS_TYPE.OPEN_BOOK_ICON:
      return <OpenBookIcon color={color} size={size} />;
    case ICONS_TYPE.PACKAGES_ICON:
      return <PackagesIcon color={color} size={size} />;
    case ICONS_TYPE.PEOPLE_GROUP_ICON:
      return <GroupIcon color={color} size={size} />;
    case ICONS_TYPE.PHONE_ICON:
      return <PhoneIcon color={color} size={size} />;
    case ICONS_TYPE.QUESTION_MARK_ICON:
      return <QuestionMarkIcon color={color} size={size} />;
    case ICONS_TYPE.RECEIVE_EUROS_ICON:
      return <ReceiveEurosIcon color={color} size={size} />;
    case ICONS_TYPE.REFRESH_DOUBLE_ICON:
      return <RefreshDoubleIcon color={color} size={size} />;
    case ICONS_TYPE.SAD_FACE_ICON:
      return <SadFaceIcon color={color} size={size} />;
    case ICONS_TYPE.SCAN_BARCODE_ICON:
      return <ScanBarcodeIcon color={color} size={size} />;
    case ICONS_TYPE.SEND_MAIL_ICON:
      return <SendMailIcon color={color} size={size} />;
    case ICONS_TYPE.SLEEPER_CHAIR_ICON:
      return <SleeperChairIcon color={color} size={size} />;
    case ICONS_TYPE.SHOPPING_CART_ICON:
      return <CartIcon color={color} size={size} />;
    case ICONS_TYPE.SMALL_SHOP_ICON:
      return <SmallShopIcon color={color} size={size} />;
    case ICONS_TYPE.STARS_ICON:
      return <StarsIcon color={color} size={size} />;
    case ICONS_TYPE.UMBRELLA_ICON:
      return <UmbrellaIcon color={color} size={size} />;
    case ICONS_TYPE.VEGAN_CIRCLE_ICON:
      return <VeganCircleIcon color={color} size={size} />;
    case ICONS_TYPE.DEPOSIT_ICON:
      return <DepositIcon color={color} size={size} />;
    case ICONS_TYPE.SHIELD_ICON:
      return <ShieldIcon color={color} size={size} />;
    case ICONS_TYPE.INSERT_DRIVE_FILE_ICON:
      return <InsertDriveFileIcon color={color} size={size} />;
    case ICONS_TYPE.FORMAT_LIST_NUMBERED_IDON:
      return <FormatListNumberedIcon color={color} size={size} />;
    case ICONS_TYPE.POST_ADD_ICON:
      return <PostAddIcon color={color} size={size} />;
    case ICONS_TYPE.CONSTRUCTION_ICON:
      return <SparePartIcon color={color} size={size} />;
    case ICONS_TYPE.GROUP_ICON:
      return <GroupIcon color={color} size={size} />;
    case ICONS_TYPE.EXCHANGE_ICON:
      return <ExchangeIcon color={color} size={size} />;
    default:
      return <QuestionMarkIcon color={color} size={size} />;
  }
};

ConfigurationConsoleIcon.displayName = 'ConfigurationConsoleIcon';

export default ConfigurationConsoleIcon;
