import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';
import {
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import * as types from './actionTypes';
import { USERS_DOMAIN, USERS_DOMAIN_KEY } from './constants';
import { UsersDomainState } from './types';

interface State extends CommonAppState {
  [USERS_DOMAIN_KEY]: UsersDomainState;
}

const selectUsersDomain = (state: State) => state[USERS_DOMAIN_KEY];

const selectUserList = createSelector(
  [selectUsersDomain],
  (state) => state[USERS_DOMAIN.LIST],
);

export const selectUserById = createSelector(
  [selectUserList, (_: State, { userId }: { userId?: string }) => userId],
  (users, userId?: string) => (userId ? users[userId] : null),
);

export const selectGetUserIsLoading = (
  state: State,
  { userId }: { userId?: string },
): boolean => isActionLoadingSelector(state, types.GET_USER_BY_ID, userId);

export const selectGetUserWasLoaded = (
  state: State,
  { userId }: { userId?: string },
): boolean => wasActionLoadedSelector(state, types.GET_USER_BY_ID, userId);
