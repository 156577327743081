import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}owners/products/updateowner`;

interface UpdateOwnerProductsOwnerCommandArgs {
  ownerProductIds: string[];
  ownerId: string;
}

async function updateOwnerProductsOwnerCommand({
  ownerProductIds,
  ownerId,
}: UpdateOwnerProductsOwnerCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.PUT,
      json: { ownerProductIds, ownerId },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default updateOwnerProductsOwnerCommand;
