import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

export enum UNIT {
  DAY = 'Day',
  MONTH = 'Month',
  YEAR = 'Year',
}

interface OnboardingWarranties {
  isWithdrawalWarrantyActivated: boolean;
  isTransportWarrantyActivated: boolean;
  isUnpackingWarrantyActivated: boolean;
  diagnosticNeededForUnpackingWarranty: boolean;
  isLegalWarrantyOfConformityActivated: boolean;
  diagnosticNeededForLegalWarrantyOfConformity: boolean;
  isOutOfWarrantyActivated: boolean;
  withdrawalWarrantyDuration: {
    unit: UNIT;
    value: string;
    isEmpty: boolean;
  };
  transportWarrantyDuration: {
    unit: UNIT;
    value: string;
    isEmpty: boolean;
  };
  unpackingWarrantyDuration: {
    unit: UNIT;
    value: string;
    isEmpty: boolean;
  };
  legalWarrantyOfConformityDuration: {
    unit: UNIT;
    value: string;
    isEmpty: boolean;
  };
}

const ENDPOINT = `${APIConfiguration.import}onboardingWarranties`;

const getOnboardingWarrantiesQuery = async ({
  sellerId,
}: {
  sellerId: string;
}): Promise<BackResponse<OnboardingWarranties> | BackResponseFailure> => {
  try {
    const url = buildUrl(ENDPOINT, { sellerId });
    const response = await apiCall<BackResponse<OnboardingWarranties>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getOnboardingWarrantiesQuery;
