import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  FileReactionHistorySummary,
  ReactionTypes,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/reactions`;

export interface FileReactionHistorySummaryDto
  extends Omit<FileReactionHistorySummary, 'executionDate'> {
  executionDate: string;
}

export const getFileReactionsQuery = async ({
  fileId,
  reactionType,
}: {
  fileId: string;
  reactionType: ReactionTypes;
}) => {
  try {
    return await apiCall<BackResponse<FileReactionHistorySummaryDto[]>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId), {
        reactionType,
      }),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
