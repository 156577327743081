import React, { FC } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { $TitleContainer } from '../AddressInformations.styles';
import {
  Autocomplete,
  FormGroup,
  PlacesAutocomplete,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import messages from '../messages';
import {
  InputHookForm,
  SwitchHookForm,
  TextareaHookForm,
} from '@savgroup-front-common/core/src/molecules/Form';
import {
  ADDRESS_INFORMATIONS_FIELDS as FIELDS,
  AddressFormName,
  AddressInformationsFormValues,
} from '../AddressInformations.types';
import { Controller, useFormContext } from 'react-hook-form';
import { filterOption } from '../AddressInformations.helpers';
import { FLEX_DIRECTION, MessageType } from '@savgroup-front-common/types';
import { countryList } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { useHandleSelectAddress } from './hooks/useHandleSelectAddress';
import { useHabitationOptions } from '../hooks/useHabitationOptions';
import { useControlTypedSelector } from 'control/hooks';

interface AddressInformationFormProps {
  formName?: AddressFormName;
  title?: MessageType;
}

export const AddressInformationForm: FC<AddressInformationFormProps> = ({
  formName = 'ownerAddress',
  title = messages.personalInformation,
}) => {
  const countryOptions = useControlTypedSelector(countryList);
  const { handleSelectAddress } = useHandleSelectAddress({
    formName,
  });
  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<AddressInformationsFormValues>();
  const watchedCountryCode = watch(`${formName}.country`);

  const { habitationOptions } = useHabitationOptions();

  return (
    <Row>
      <Col xs={12}>
        <$TitleContainer $isFirst>
          <Heading level={4}>
            <SafeFormattedMessageWithoutSpread message={title} />
          </Heading>
        </$TitleContainer>
      </Col>

      <Col xs={12} sm={6}>
        <FormGroup>
          <InputHookForm
            label={messages.firstNameLabel}
            dataTestId="editAddress-informations-firstName"
            disableBrowserAutocomplete
            name={`${formName}.firstName`}
            errors={errors}
            register={register}
            isRequired
          />
        </FormGroup>
      </Col>
      <Col xs={12} sm={6}>
        <FormGroup>
          <InputHookForm
            label={messages.lastNameLabel}
            dataTestId="editAddress-informations-lastName"
            disableBrowserAutocomplete
            name={`${formName}.lastName`}
            errors={errors}
            register={register}
            isRequired
          />
        </FormGroup>
      </Col>
      <Col xs={12} sm={6}>
        <FormGroup>
          <InputHookForm
            label={messages.companyNameLabel}
            dataTestId="editAddress-informations-compagnyName"
            name={`${formName}.companyName`}
            disableBrowserAutocomplete
            errors={errors}
            register={register}
          />
        </FormGroup>
      </Col>
      <Col xs={12} sm={6}>
        <FormGroup>
          <InputHookForm
            label={messages.phoneLabel}
            dataTestId="editAddress-informations-phoneNumber"
            name={`${formName}.phoneNumber`}
            disableBrowserAutocomplete
            errors={errors}
            register={register}
            isRequired
          />
        </FormGroup>
      </Col>

      <Col xs={12}>
        <$TitleContainer>
          <Heading level={4}>
            <SafeFormattedMessageWithoutSpread
              message={messages.contactDetails}
            />
          </Heading>
        </$TitleContainer>
      </Col>

      <Col xs={12} sm={3}>
        <FormGroup>
          <Controller
            control={control}
            name={`${formName}.country`}
            render={({ field }) => (
              <Autocomplete
                onChange={(option) => field.onChange(option)}
                label={messages.countryLabel}
                filterOption={filterOption}
                dataTestId="editAddress-informations-country"
                options={countryOptions}
                name={FIELDS.COUNTRY}
                ref={field.ref}
                errors={errors}
                value={field.value}
                isRequired
              />
            )}
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={9}>
        <FormGroup>
          <Controller
            name={`${formName}.address`}
            control={control}
            render={({ field }) => (
              <PlacesAutocomplete
                countryCode={watchedCountryCode?.value}
                label={messages.addressLabel}
                dataTestId="editAddress-informations-address"
                name={FIELDS.ADDRESS}
                errors={errors}
                ref={field.ref}
                value={field.value}
                isRequired
                onSelect={handleSelectAddress}
                onChange={field.onChange}
              />
            )}
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={6}>
        <FormGroup>
          <InputHookForm
            dataTestId="editAddress-informations-additionalAddress"
            name={`${formName}.additionalAddress`}
            errors={errors}
            label={messages.additionalAddressLabel}
            register={register}
            disableBrowserAutocomplete
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={2}>
        <FormGroup>
          <InputHookForm
            dataTestId="editAddress-informations-zipCode"
            name={`${formName}.postalCode`}
            errors={errors}
            label={messages.postalCodeLabel}
            isRequired
            register={register}
            disableBrowserAutocomplete
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={4}>
        <FormGroup>
          <InputHookForm
            dataTestId="editAddress-informations-city"
            name={`${formName}.city`}
            errors={errors}
            label={messages.cityLabel}
            isRequired
            register={register}
            disableBrowserAutocomplete
          />
        </FormGroup>
      </Col>

      <Col xs={12}>
        <$TitleContainer>
          <Heading level={4}>
            <SafeFormattedMessageWithoutSpread
              message={messages.complementaryInformations}
            />
          </Heading>
        </$TitleContainer>
      </Col>

      <Col xs={12} sm={4}>
        <FormGroup>
          <SwitchHookForm
            dataTestId="editAddress-informations-elevator"
            name={`${formName}.hasElevator`}
            label={messages.elevator}
            alignLabel={FLEX_DIRECTION.COLUMN}
            register={register}
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={4}>
        <FormGroup>
          <SwitchHookForm
            dataTestId="editAddress-informations-parkingSpace"
            name={`${formName}.hasParkingSpace`}
            label={messages.parkingSpace}
            alignLabel={FLEX_DIRECTION.COLUMN}
            register={register}
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={4}>
        <FormGroup>
          <Controller
            control={control}
            name={`${formName}.housingType`}
            render={({ field }) => (
              <Autocomplete
                onChange={(option) => {
                  field.onChange(option);
                }}
                label={messages.housingType}
                dataTestId="editAddress-informations-housingType"
                options={habitationOptions}
                name={FIELDS.HOUSING_TYPE}
                ref={field.ref}
                errors={errors}
                value={field.value}
              />
            )}
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={6}>
        <FormGroup>
          <InputHookForm
            dataTestId="editAddress-informations-floor"
            name={`${formName}.floor`}
            label={messages.floor}
            errors={errors}
            register={register}
          />
        </FormGroup>
      </Col>

      <Col xs={12} sm={6}>
        <FormGroup>
          <InputHookForm
            dataTestId="editAddress-informations-doorCode"
            name={`${formName}.doorCode`}
            label={messages.doorCode}
            errors={errors}
            register={register}
          />
        </FormGroup>
      </Col>

      <Col xs={12}>
        <FormGroup>
          <TextareaHookForm
            name={`${formName}.additionalInformation`}
            label={messages.additionalInformation}
            register={register}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

AddressInformationForm.displayName = 'AddressInformationForm';
