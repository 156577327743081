import { useMutation } from 'react-query';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';

import { WorkflowService } from '../../api';

export const useImportAttachmentOnFile = ({ fileId }: { fileId?: string }) => {
  const { mutateAsync: handleImportImageOnFile, isLoading } = useMutation(
    ['useImportAttachmentOnFile'],
    async (fileToImport: File) => {
      if (!fileId) {
        throw new Error('File id is required');
      }

      const importFileResponse =
        await CommonAttachmentService.uploadAttachements<string>({
          files: [fileToImport],
          endpoint: `${APIConfiguration.document}files/${fileId}/control-attachments`,
        });

      if (importFileResponse.failure) {
        return importFileResponse;
      }

      const response = await WorkflowService.setFileAttachmentInfo({
        fileId,
        extension: fileToImport.type,
        name: fileToImport.name,
        fileAttachmentId: importFileResponse.value,
      });

      return response;
    },
  );

  return {
    handleImportImageOnFile,
    isLoading,
  };
};
