import React, { ReactElement } from 'react';
import { Column, PluginHook, TableState, useSortBy } from 'react-table';

import { ComponentErrorBoundary } from '../../molecules/ErrorBoundary';

import TableContent from './TableContent';

interface TableProps<D extends Record<string, any>> {
  data: D[];
  columns: Column<D>[];
  plugins?: PluginHook<D>[];
  initialState?: Partial<TableState<D>>;
  dataTestId?: string;
}

const Table = <D extends Record<string, any>>({
  columns,
  data,
  plugins = [useSortBy],
  initialState,
  dataTestId,
}: TableProps<D>): ReactElement | null => {
  return (
    <ComponentErrorBoundary>
      <TableContent<D>
        columns={columns}
        data={data}
        plugins={plugins}
        initialState={initialState}
        dataTestId={dataTestId}
      />
    </ComponentErrorBoundary>
  );
};

Table.displayName = 'Table';

export default Table;
