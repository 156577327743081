import {
  APP_COMPONENT_STATUSES,
  APP_STATUS_IMPACT,
  AppIncident,
  AppStatusUpdate,
  INCIDENT_STATUSES,
} from '@savgroup-front-common/types';

import adaptRawAppComponentsToAppComponents from './adaptRawAppComponentsToAppComponents';

interface RawUnresolvedIncidents {
  incidents: {
    components: {
      created_at: string;
      description: string | null;
      group: boolean;
      group_id: string | null;
      id: string;
      name: string;
      only_show_if_degraded: boolean;
      page_id: string;
      position: number;
      showcase: boolean;
      start_date: string;
      status: APP_COMPONENT_STATUSES;
      updated_at: string;
    }[];
    created_at: string;
    id: string;
    impact: APP_STATUS_IMPACT;
    incident_updates: {
      body: string;
      created_at: string;
      display_at: string;
      id: string;
      incident_id: string;
      status: INCIDENT_STATUSES;
      updated_at: string;
    }[];
    monitoring_at: null;
    name: string;
    page_id: string;
    resolved_at: null;
    shortlink: string;
    status: INCIDENT_STATUSES;
    updated_at: string;
  }[];
}

const adaptRawAppIncidentToAppIncident = (
  payload: RawUnresolvedIncidents,
): AppIncident[] => {
  return payload.incidents.map((incident) => ({
    components: adaptRawAppComponentsToAppComponents(incident.components),
    createdAt: incident.created_at,
    id: incident.id,
    impact: incident.impact,
    incidentUpdates: incident.incident_updates.map<
      AppStatusUpdate<INCIDENT_STATUSES>
    >((update) => ({
      body: update.body,
      createdAt: update.created_at,
      displayAt: update.display_at,
      id: update.id,
      incidentId: update.incident_id,
      status: update.status,
      updatedAt: update.updated_at,
    })),
    monitoringAt: incident.monitoring_at,
    name: incident.name,
    pageId: incident.page_id,
    resolvedAt: incident.resolved_at,
    shortlink: incident.shortlink,
    status: incident.status,
    updatedAt: incident.updated_at,
  }));
};

export default adaptRawAppIncidentToAppIncident;
