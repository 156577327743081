import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  RequestLineStatus,
  SparePartStateUponReceipt,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requestLine/update`;

interface UpdateSparePartRequestLineArgs {
  requestLineId: string;
  requestLineStatus: Exclude<
    RequestLineStatus,
    RequestLineStatus.ORDER_SUBMITTED | RequestLineStatus.ORDER_COLLECTED
  >;
}

interface UpdateSparePartRequestLineSubmittedArgs {
  requestLineId: string;
  externalOrderId: string;
  purchasePrice: number;
  requestLineStatus: RequestLineStatus.ORDER_SUBMITTED;
}
interface UpdateSparePartRequestLineOrderCollectedArgs {
  requestLineId: string;
  sparePartStateUponReceipt: SparePartStateUponReceipt;
  requestLineStatus: RequestLineStatus.ORDER_COLLECTED;
  stockItemLocation: string;
  stockId: string;
}

export default async function updateSparePartRequestLine(
  payload:
    | UpdateSparePartRequestLineArgs
    | UpdateSparePartRequestLineSubmittedArgs
    | UpdateSparePartRequestLineOrderCollectedArgs,
): Promise<void> {
  const response = await apiCall<BaseBackResponse>(ENDPOINT, {
    method: SUPPORTED_METHODS.PUT,
    json: payload,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to updateSparePartRequestLine`);
  }
}
