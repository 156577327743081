import { all, debounce, put, takeEvery } from 'redux-saga/effects';
import 'regenerator-runtime';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { CONTROL_CONSTANTS } from '@savgroup-front-common/constants/src';

import * as TicketsActionCreators from '../marketplaceTickets/marketplaceAggregator/ticketsActions';

import * as ActionTypes from './actionTypes';

const { FILTERS } = CONTROL_CONSTANTS;

function* changeMessagesSearchQueryWorker() {
  yield put(TicketsActionCreators.loadTickets({ withSpinner: true }));
}
function* changeMessagesSearchQueryWatcher() {
  yield debounce(
    500,
    ActionTypes.CHANGE_MESSAGES_SEARCH_QUERY,
    changeMessagesSearchQueryWorker,
  );
}

function* changeMessagesFilterWorker() {
  yield put(TicketsActionCreators.loadTickets({ withSpinner: true }));
}
function* changeMessagesFilterWatcher() {
  yield takeEvery(
    ActionTypes.CHANGE_MESSAGES_FILTER,
    changeMessagesFilterWorker,
  );
}

function* changeMessagesSortWorker() {
  yield put(TicketsActionCreators.loadTickets({ withSpinner: true }));
}
function* changeMessagesSortWatcher() {
  yield takeEvery(ActionTypes.CHANGE_MESSAGES_SORT, changeMessagesSortWorker);
}

function* resetFiltersWorker({ payload = {} }) {
  const { concernedFilter } = payload;

  if (concernedFilter === FILTERS.MESSAGES) {
    yield put(TicketsActionCreators.loadTickets({ withSpinner: true }));
  }
  yield put(ActionTypes.RESET_FILTERS.success({}, { concernedFilter }));
}
function* resetFiltersWatcher() {
  yield takeEvery(ActionTypes.RESET_FILTERS.BASE, resetFiltersWorker);
}

export default function* filterSaga() {
  try {
    yield all([
      changeMessagesFilterWatcher(),
      changeMessagesSortWatcher(),
      changeMessagesSearchQueryWatcher(),
      resetFiltersWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
