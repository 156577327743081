import round from 'lodash/round';
import { ChangeEvent } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { ReverseMoney } from '@savgroup-front-common/types';

import {
  formatStringToIntlPrice,
  formatStringToNegativeIntlPrice,
} from './intl';

export function computeTotalPrice(task: {
  bundlePrice?: string | number;
  partsPrice?: number;
  laborPrice?: number;
}): number {
  const isPriceValid = (price?: string | number) =>
    price !== undefined && price !== '';
  const isBundle = isPriceValid(task.bundlePrice);
  const total = isBundle
    ? Number(task.bundlePrice || 0)
    : round(Number(task.partsPrice || 0) + Number(task.laborPrice || 0), 2);

  return total;
}

export const formatPriceToReversMoney = (
  amount: number | string,
  currency = CURRENCIES.EUR,
): ReverseMoney => ({
  currency,
  amount: String(amount),
});

export const transformStringPriceToRoundedStringPrice = (
  amount: string,
): string => {
  const amountWithoutComma = amount.replace(',', '.');
  const amountWithoutSpaceAndInvisibleSpace = amountWithoutComma
    .replace(' ', '')
    .replace(' ', '')
    .replaceAll(' ', '');

  const num = Number(amountWithoutSpaceAndInvisibleSpace);

  if (Number.isNaN(num)) {
    return '0.00';
  }

  return round(Number(amountWithoutSpaceAndInvisibleSpace), 2).toFixed(2);
};

export const transformStringPriceToRoundedFloatPrice = (
  amount: string,
): number => {
  return Number(transformStringPriceToRoundedStringPrice(amount));
};

export const transformNumberPriceToRoundedStringPrice = (
  amount: number,
): string => {
  return transformStringPriceToRoundedStringPrice(String(amount));
};
export const transformNumberPriceToRoundedFloatPrice = (
  amount: number,
): number => {
  return Number(transformStringPriceToRoundedStringPrice(String(amount)));
};

export const transformIntlPriceStringToInt = (amount: string): number => {
  const amountRounded = transformStringPriceToRoundedFloatPrice(amount);

  return parseInt(round(amountRounded * 100).toString(), 10);
};

export const formatPriceOnBlurHookForm = ({
  field,
  currency = CURRENCIES.EUR,
}: {
  currency?: CURRENCIES;
  field: { onChange: (price: string) => void };
}) => {
  return (event: ChangeEvent<HTMLInputElement>): void => {
    field.onChange(
      formatStringToIntlPrice({
        amount: event.target.value,
        currency,
      }),
    );
  };
};
export const formatNegativePriceOnBlurHookForm = ({
  field,
  currency = CURRENCIES.EUR,
}: {
  currency?: CURRENCIES;
  field: { onChange: (price: string) => void };
}) => {
  return (event: ChangeEvent<HTMLInputElement>): void => {
    field.onChange(
      formatStringToNegativeIntlPrice({
        amount: event.target.value,
        currency,
      }),
    );
  };
};
