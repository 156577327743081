import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SparePartSupplier,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}parts/validSparePartSuppliers`;

export interface GetValidSparePartSuppliersQueryArgs {
  sellerId: string;
  fileId?: string;
}

export async function getValidSparePartSuppliersQuery({
  sellerId,
  fileId,
}: GetValidSparePartSuppliersQueryArgs): Promise<
  BackResponse<SparePartSupplier[]> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SparePartSupplier[]>>(
      buildUrl(ENDPOINT, {
        sellerId,
        fileId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
