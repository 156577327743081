import styled, { css, keyframes } from 'styled-components';

import { SimpleAnimationProps } from '@savgroup-front-common/core/src/animations/simpleAnimation/types';
import { rem } from '@savgroup-front-common/core/src/helpers';
import {
  accelerateTimingFunctionAnimation,
  decelerateTimingFunctionAnimation,
  decelerateTimingFunctionTransition,
} from '@savgroup-front-common/core/src/animations/timingFunction';

const wiggle = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-${rem(5)});
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-${rem(2)});
  }
  100% {
    transform: translateY(0);
  }
`;

const activeMixin = css`
  &:hover {
    opacity: 1;
  }
  &:hover,
  &:focus {
    animation: ${wiggle} 0.5s;
    animation-iteration-count: 1;
  }
`;

export const $ScrollToTopButton = styled.button<{ $show: boolean }>`
  z-index: 2;
  text-align: center;
  border: none;
  background: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  line-height: ${rem(30)};
  border-radius: ${rem(4)};
  width: ${rem(38)};
  height: ${rem(38)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  opacity: ${({ $show }) => ($show ? 0.5 : 0)};
  pointer-events: ${({ $show }) => ($show ? null : 'none')};
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.2s;
  ${accelerateTimingFunctionAnimation};
  ${decelerateTimingFunctionAnimation};
  ${({ $show }) => ($show ? activeMixin : null)};
`;

interface $ScrollButtonContainerProps extends SimpleAnimationProps {
  $position: number;
}
export const $ScrollButtonContainer = styled.div<$ScrollButtonContainerProps>`
  position: fixed;
  bottom: ${rem(20)};
  right: 3.5rem;
  transform: translateY(-${({ $position }) => rem($position)});
  ${decelerateTimingFunctionTransition};
  transition-duration: ${({ animationDuration }) =>
    animationDuration ? animationDuration / 1000 : 0}s;
`;
