import { useCallback, useState } from 'react';

import {
  ADDRESS_VERIFY_FAILURE,
  CIVILITY,
  COUNTRY_CODES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants/src';
import { DataGouvService } from '@savgroup-front-common/core/src/api';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { countryMap } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { AddressInfoDto } from '@savgroup-front-common/types';
import { CarrierService } from 'control/api';
import { useControlTypedSelector } from 'control/hooks/useControlTypedSelector';

import messages from '../messages';

import useCarrierName from './useCarrierName';

const useHandleSuggestion = () => {
  const countries: Record<string, string> = useControlTypedSelector(countryMap);
  const [isVerifyStrict, setIsVerifyStrict] = useState(true);

  const carrierCompany = useCarrierName();
  const { pushErrors, pushError, pushNotification } = useBanners();

  const handleSuggestion = useCallback(
    async (formValues: AddressInfoDto) => {
      if (formValues.countryCode === COUNTRY_CODES.FR) {
        try {
          const response = await DataGouvService.geoCodeAddressFromDataGouv({
            query: [formValues.address, formValues.postalCode, formValues.city]
              .filter((v) => v)
              .join(' '),
          });

          if (response.features.length > 0) {
            const [feature] = response.features;

            const suggestedAddress: AddressInfoDto = {
              civility: CIVILITY.NOT_SET,
              additionalAddress: '',
              address: feature.properties.name,
              city: feature.properties.city,
              countryCode: COUNTRY_CODES.FR,
              postalCode: feature.properties.postcode,
              companyName: formValues.companyName || '',
              firstname: formValues.firstname || '',
              lastname: formValues.lastname || '',
              phone: formValues.phone || '',
              hasElevator: formValues.hasElevator,
              hasParkingSpace: formValues.hasElevator,
              housingType: formValues.housingType,
              floor: formValues.floor,
              doorCode: formValues.doorCode,
              additionalInformation: formValues.additionalInformation,
            };

            return suggestedAddress;
          }

          return undefined;
        } catch (error) {
          pushError(error as Error);

          return undefined;
        }
      }
      const response = await CarrierService.verifyAddress({
        carrierCompany,
        address: {
          civility: CIVILITY.NOT_SET,
          street2: formValues.additionalAddress || '',
          street1: formValues.address,
          city: formValues.city || '',
          companyName: formValues.companyName || '',
          country:
            countries?.[formValues.countryCode.toLocaleUpperCase()] || '',
          countryCode: formValues.countryCode,
          name1: formValues.firstname || '',
          name2: formValues.lastname || '',
          phone: formValues.phone || '',
          zipCode: formValues.postalCode || '',
          hasElevator: formValues.hasElevator,
          hasParkingSpace: formValues.hasElevator,
          housingType: formValues.housingType,
          floor: formValues.floor,
          doorCode: formValues.doorCode,
          additionalInformation: formValues.additionalInformation,
        },
        verifyStrict: isVerifyStrict,
      });

      if (response.failure) {
        if (response.errors.some((e) => e.code === ADDRESS_VERIFY_FAILURE)) {
          pushNotification(
            buildNotification({
              notificationType: NOTIFICATION_TYPES.ALERT,
              message: messages.addressConfirmation,
            }),
          );
          setIsVerifyStrict(false);

          return undefined;
        }
        pushErrors(response.errors);

        return undefined;
      }

      const suggestedAddress: AddressInfoDto = {
        civility: CIVILITY.NOT_SET,
        additionalAddress: response.value.street2,
        address: response.value.street1,
        city: response.value.city,
        countryCode: response.value.countryCode,
        postalCode: response.value.zipCode,
        companyName: formValues.companyName || '',
        firstname: formValues.firstname || '',
        lastname: formValues.lastname || '',
        phone: formValues.phone || '',
      };

      return suggestedAddress;
    },
    [
      carrierCompany,
      countries,
      pushError,
      pushErrors,
      isVerifyStrict,
      pushNotification,
    ],
  );

  return {
    handleSuggestion,
    isVerifyStrict,
  };
};

export default useHandleSuggestion;
