import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  BaseBackResponse,
  RISK_CARRIER_TYPE,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/warranty`;

interface UpdateWarrantyCommandPayload {
  fileId: string;
  warrantyTypeId: string;
  riskCarrier?: RISK_CARRIER_TYPE;
}

const updateWarrantyCommand = async ({
  fileId,
  warrantyTypeId,
  riskCarrier,
}: UpdateWarrantyCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: { warrantyTypeId, riskCarrier },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default updateWarrantyCommand;
