import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}publicApi/updateApiApplication`;

interface UpdateApiApplication {
  applicationId: string;
  sellerId: string;
  primaryToken: boolean;
  secondaryToken: boolean;
}

interface UpdateApiApplicationResponse extends BaseBackResponse {
  failure: false;
  value: {
    primaryToken: string;
    secondaryToken: string;
    id: string;
    sellerId: string;
    name: string;
    primaryKey: string;
    secondaryKey: string;
  };
}
interface UpdateApiApplicationResponseError extends BaseBackResponse {
  failure: true;
}

async function updateApiApplication({
  primaryToken,
  secondaryToken,
  sellerId,
  applicationId,
}: UpdateApiApplication): Promise<
  UpdateApiApplicationResponse | UpdateApiApplicationResponseError
> {
  try {
    const response = await apiCall<UpdateApiApplicationResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
        applicationId,
        primaryToken,
        secondaryToken,
      },
    });

    return response;
  } catch (exception) {
    return { failure: true, errors: (exception as any).errors };
  }
}

export default updateApiApplication;
