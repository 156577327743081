import { AnyAction } from 'redux';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { types } from './actions';
import { DEBUG_DOMAIN } from './constants';

export type DebugState = {
  cfDebug: { entries?: Record<string, unknown>[]; origin?: string };
};

const initialState: DebugState = {
  [DEBUG_DOMAIN.CF_DEBUG]: {},
};

interface OnPushCfDebugAction extends AnyAction {
  payload: { entry?: Record<string, unknown> };
}

function onPushCfDebug(
  state: DebugState,
  { payload = {} }: OnPushCfDebugAction,
): DebugState {
  const { entry, ...rest } = payload;

  const oldEntries = state?.cfDebug?.entries || [];
  const newEntries = entry ? [...oldEntries, entry] : oldEntries;

  return {
    ...state,
    cfDebug: {
      ...(state[DEBUG_DOMAIN.CF_DEBUG] || {}),
      ...rest,
      entries: newEntries,
    },
  };
}

interface OnResetCfDebugAction extends AnyAction {
  payload: { origin?: string };
}

function onResetCfDebug(
  state: DebugState,
  { payload }: OnResetCfDebugAction,
): DebugState {
  const { origin } = payload;

  return {
    ...state,
    cfDebug: {
      origin,
    },
  };
}

export default function mainReducer(
  state: DebugState = initialState,
  action: AnyAction,
) {
  try {
    switch (action.type) {
      case types.PUSH_CF_DEBUG.BASE:
        return onPushCfDebug(state, action as OnPushCfDebugAction);
      case types.RESET_CF_DEBUG.BASE:
        return onResetCfDebug(state, action as OnResetCfDebugAction);
      default:
        return state;
    }
  } catch (e) {
    logCritical(e);

    return state;
  }
}
