import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  MARKETING_OFFER_TYPE,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/refundMethods`;

export interface RefundMethods {
  sellerId: string;
  marketingOfferType: MARKETING_OFFER_TYPE;
  translationByCountryCode: {
    cultureCode: COUNTRY_CODES;
    key: string;
    label: string;
  }[];
}

export async function getRefundMethodsBySellerQuery({
  sellerId,
}: {
  sellerId: string;
}): Promise<BackResponse<RefundMethods[]> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<BackResponse<RefundMethods[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
