import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}billing/CreateB2B`;

interface BillingCommandQueryLine {
  description: string;
  type: string;
  preTaxAmount: ReverseMoneyNumberAmount;
  vatRate: number;
  amountIncludedTax: ReverseMoneyNumberAmount;
}

export interface CreateB2BBillingCommandQueryArgs {
  sellerId: string;
  clientId: string;
  title: string;
  orderFormReference: string;
  lines: BillingCommandQueryLine[];
  fileReferences: string[];
  footnotes: string;
}

const createB2BBillingCommandQuery = async (
  payload: CreateB2BBillingCommandQueryArgs,
): Promise<BackResponse<string> | BackResponseFailure> => {
  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default createB2BBillingCommandQuery;
