import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  INTERVENTION_REPORT_STATE,
  RecipientType,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/interventionreport`;

interface GetInterventionReportArgs {
  fileId: string;
}

interface BaseInterventionReportLine {
  description: string;
  preTaxUnitPrice: ReverseMoneyNumberAmount;
  quantity: number;
  vatRate: number;
  preTaxAmount: ReverseMoneyNumberAmount;
  amountIncludedTax: ReverseMoneyNumberAmount;
  recipientType: RecipientType;
}

interface AdministrativeFeesReportLine extends BaseInterventionReportLine {
  type: 'AdministrativeFee';
}

interface DiscountReportLine extends BaseInterventionReportLine {
  type: 'Discount';
}

interface ServiceReportLine extends BaseInterventionReportLine {
  type: 'Service';
  service: {
    serviceId: string;
  };
}

interface UnknownServiceReportLine extends BaseInterventionReportLine {
  type: 'UnknownService';
  recipientType: RecipientType;
}

interface SparePartReportLine extends BaseInterventionReportLine {
  type: 'SparePart';
  sparePart: {
    stockItemId: string;
    sparePartId: string;
    supplierId: string;
    reference: string;
    preTaxUnitBuyingPrice: ReverseMoneyNumberAmount;
    isInstalled?: boolean;
  };
}

interface UnknownSparePartReportLine extends BaseInterventionReportLine {
  type: 'UnknownSparePart';
  unknownSparePart: {
    supplierId: string;
    reference: string;
  };
}

export type GetInterventionReportLine =
  | AdministrativeFeesReportLine
  | DiscountReportLine
  | ServiceReportLine
  | UnknownServiceReportLine
  | SparePartReportLine
  | UnknownSparePartReportLine;

export interface InterventionReportSummary {
  id: string;
  fileId: string;
  model: string;
  closingType: string;
  closingCode: string;
  serialNumber: string;
  irisCode: {
    conditionCode: string;
    symptomCode: string;
    sectionCode: string;
    defectCode: string;
    repairCode: string;
    extendedConditionCode: string;
  };
  technicalDescription: string;
  comment: string;
  state: INTERVENTION_REPORT_STATE;
  reportLines: string[];
  lines: GetInterventionReportLine[];
}

export default async function getInterventionReport(
  payload: GetInterventionReportArgs,
): Promise<BackResponse<InterventionReportSummary> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<InterventionReportSummary>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
