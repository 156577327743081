import React, { FunctionComponent } from 'react';

import { MIME_TYPES } from '@savgroup-front-common/constants/src/shared';
import {
  ImageIcon,
  PdfIcon,
  VideoIcon,
} from '@savgroup-front-common/core/src/protons/icons';

interface DocumentTypeIconProps {
  type?: string;
  color: string;
  size: string;
}

const DocumentTypeIcon: FunctionComponent<
  React.PropsWithChildren<DocumentTypeIconProps>
> = ({ type, color, size }) => {
  switch (type) {
    case MIME_TYPES.PDF:
      return <PdfIcon color={color} size={size} />;
    case MIME_TYPES.JPEG:
    case MIME_TYPES.PNG:
      return <ImageIcon color={color} size={size} />;
    case MIME_TYPES.OCTET_STREAM:
      return <VideoIcon color={color} size={size} />;

    default:
      return null;
  }
};

DocumentTypeIcon.displayName = 'DocumentTypeIcon';

export default DocumentTypeIcon;
