import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.document}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/billingDocument`;

interface GetBillingDocumentLinkQueryArgs {
  sellerId: string;
  claimId: string;
  billingId: string;
  claimGroupId: string;
}

export const getBillingDocumentLinkQuery = async ({
  sellerId,
  claimId,
  billingId,
  claimGroupId,
}: GetBillingDocumentLinkQueryArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    return await apiCall<BackResponse<string>>(
      buildUrl(url, { billingId, claimGroupId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
