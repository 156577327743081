import { HOUSING_TYPES } from '@savgroup-front-common/constants';
import {
  AddressInfoDto,
  AutocompleteOptionForHookForm,
  StrictAutocompleteOptionForHookForm,
} from '@savgroup-front-common/types';

export enum ADDRESS_INFORMATIONS_FIELDS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY_NAME = 'companyName',
  PHONE_NUMBER = 'phoneNumber',
  ADDRESS = 'address',
  ADDITIONAL_ADDRESS = 'additionalAddress',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  COUNTRY = 'country',
  HAS_ELEVATOR = 'hasElevator',
  HAS_PARKING_SPACE = 'hasParkingSpace',
  HOUSING_TYPE = 'housingType',
  FLOOR = 'floor',
  DOOR_CODE = 'doorCode',
  ADDITIONAL_INFORMATION = 'additionalInformation',
}

export interface AddressForm {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  address: string;
  additionalAddress: string;
  postalCode: string;
  city: string;
  country: AutocompleteOptionForHookForm;
  hasElevator: boolean;
  hasParkingSpace: boolean;
  housingType: StrictAutocompleteOptionForHookForm<HOUSING_TYPES>;
  floor: string;
  doorCode: string;
  additionalInformation: string;
}

export type AddressInformationsFormValues = {
  ownerAddress: AddressForm;
  ownerAddressOnFile: AddressForm;
  isOwnerAddressOnFileSame: boolean;
};

export interface AddressInformationsStepValues {
  ownerAddress: {
    address: AddressInfoDto;
    suggestedAddress?: AddressInfoDto;
  };
  ownerAddressOnFile: {
    address: AddressInfoDto;
    suggestedAddress?: AddressInfoDto;
  };
  isOwnerAddressOnFileSame: boolean;
}
export interface AddressInformationsStepContext {
  hasSuggestedAddress: boolean;
  unmatchingFields: string[];
  couldEditAddressOnFile: boolean;
}

export type AddressFormName = 'ownerAddress' | 'ownerAddressOnFile';
