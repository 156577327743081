import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponse, OwnerSummary } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}owners/id/${COMMON_QUERY_PARAMS.OWNER_ID}`;

export async function getOwnerById({ ownerId }: { ownerId: string }) {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId);

    return await apiCall<BackResponse<OwnerSummary>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (e) {
    return prepareResponseFailure(e);
  }
}
