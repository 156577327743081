import get from 'lodash/get';
import moment from 'moment';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import {
  GET_FILE_FULL_SUMMARY,
  types,
  UPDATE_OWNER_NOTIFICATION_EMAIL,
} from './actions';
import { COMMENT_STATUS } from './fileSummary.constants';

const onFileFullSummaryLoading = (state, { meta }) => {
  const fileId = meta?.fileId;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {
        isLoaded: false,
        isDirty: false,
        isLoading: true,
        statusCode: 404,
      }),
      isDirty: true,
      isLoading: true,
      statusCode: 404,
      hasErrors: false,
    },
  };
};

const onFileFullSummaryLoadingErrored = (state, { meta, statusCode }) => {
  const fileId = meta?.fileId;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      isDirty: true,
      hasErrors: true,
      isLoading: false,
      statusCode,
    },
  };
};

const onFileFullSummaryLoaded = (state, { payload, meta }) => {
  const fileId = meta?.fileId;
  const fileSummary = payload?.value;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      isLoaded: true,
      isLoading: false,
      isDirty: false,
      value: {
        ...(state[fileId]?.value || {}),
        ...fileSummary,
      },
      statusCode: 200,
      hasErrors: false,
    },
  };
};

const onGetFileCommentsSucceeded = (state, { payload, meta }) => {
  const fileId = meta?.fileId;
  const comments = get(payload, ['value', 'hits'], []);

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {
        value: {
          fileProducts: [],
        },
      }),
      value: {
        ...(state[fileId]?.value || { fileProducts: [] }),
        comments: comments.map((comment) => ({
          ...comment,
          createdAt: moment(comment.createdAt),
          lastModifiedAt: comment.lastModifiedAt
            ? moment(comment.lastModifiedAt)
            : null,
          internalId: comment.id,
          status: COMMENT_STATUS.SENT,
        })),
      },
    },
  };
};

const onSendNewFileCommentStarting = (state, { meta }) => {
  const fileId = meta?.fileId;
  const newComment = meta?.newComment;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      value: {
        ...(state[fileId]?.value || {}),
        comments: [
          ...(state[fileId]?.value?.comments || []),
          { ...newComment, status: COMMENT_STATUS.PENDING },
        ],
      },
    },
  };
};

const onSendNewFileCommentSuccess = (state, { meta, payload }) => {
  const fileId = meta?.fileId;
  const indexer = meta?.indexer;
  const newComment = meta?.newComment;
  const value = payload?.value;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      value: {
        ...(state[fileId]?.value || {}),
        comments: state[fileId]?.value.comments.reduce((acc, curr) => {
          if (curr.internalId === indexer) {
            return [
              ...acc,
              {
                ...newComment,
                id: value.id,
                internalId: newComment.internalId,
                status: COMMENT_STATUS.SENT,
              },
            ];
          }

          return [...acc, curr];
        }, []),
      },
    },
  };
};

const onUpdateFileCommentStarting = (state, { meta }) => {
  const fileId = meta?.fileId;
  const indexer = meta?.indexer;
  const newComment = meta?.newComment;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      value: {
        ...(state[fileId]?.value || {}),
        comments: state[fileId]?.value.comments.reduce((acc, curr) => {
          if (curr.internalId === indexer) {
            return [
              ...acc,
              {
                ...newComment,
                status: COMMENT_STATUS.PENDING,
              },
            ];
          }

          return [...acc, curr];
        }, []),
      },
    },
  };
};

const onUpdateFileCommentSuccess = (state, { meta, payload }) => {
  const fileId = meta?.fileId;
  const indexer = meta?.indexer;
  const newComment = meta?.newComment;
  const value = payload?.value;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      value: {
        ...(state[fileId]?.value || {}),
        comments: state[fileId]?.value.comments.reduce((acc, curr) => {
          if (curr.internalId === indexer) {
            return [
              ...acc,
              {
                ...newComment,
                lastModifiedAt: moment(value.lastModifiedAt),
                status: COMMENT_STATUS.SENT,
              },
            ];
          }

          return [...acc, curr];
        }, []),
      },
    },
  };
};

const onDeleteFileCommentSuccess = (state, { meta }) => {
  const fileId = meta?.fileId;
  const indexer = meta?.indexer;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      value: {
        ...(state[fileId]?.value || {}),
        comments: state[fileId]?.value?.comments.filter((comment) => {
          return comment.internalId !== indexer;
        }),
      },
    },
  };
};

const onUpdateOwnerNotificationEmailSuccess = (state, { payload }) => {
  const { fileId, ownerNotificationEmail } = payload;

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      value: {
        ...(state[fileId]?.value || {}),
        ownerNotificationEmail,
      },
    },
  };
};

const reducer = (state = {}, action) => {
  try {
    switch (action.type) {
      case GET_FILE_FULL_SUMMARY.STARTED:
        return onFileFullSummaryLoading(state, action);
      case GET_FILE_FULL_SUMMARY.SUCCEEDED:
        return onFileFullSummaryLoaded(state, action);
      case GET_FILE_FULL_SUMMARY.ERRORED:
        return onFileFullSummaryLoadingErrored(state, action);

      case types.GET_FILE_COMMENTS.SUCCEEDED:
        return onGetFileCommentsSucceeded(state, action);

      case types.SEND_NEW_FILE_COMMENTS.STARTED:
        return onSendNewFileCommentStarting(state, action);
      case types.SEND_NEW_FILE_COMMENTS.SUCCEEDED:
        return onSendNewFileCommentSuccess(state, action);

      case types.UPDATE_FILE_COMMENTS.STARTED:
        return onUpdateFileCommentStarting(state, action);
      case types.UPDATE_FILE_COMMENTS.SUCCEEDED:
        return onUpdateFileCommentSuccess(state, action);

      case types.DELETE_FILE_COMMENTS.SUCCEEDED:
        return onDeleteFileCommentSuccess(state, action);

      case UPDATE_OWNER_NOTIFICATION_EMAIL.SUCCEEDED:
        return onUpdateOwnerNotificationEmailSuccess(state, action);

      default:
        return state;
    }
  } catch (e) {
    logCritical(e);

    return state;
  }
};

export default reducer;
