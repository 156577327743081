import {
  File,
  FileHistoric,
  isFileHistoric,
  SearchOtherHighlight,
} from '@savgroup-front-common/types';

import adaptFileToOwnerNameHighLights from './adaptFileToOwnerNameHighLights';
import adaptHighlights from './adaptHighlights';
import adaptToBoldHighlight from './adaptToBoldHighlight';
import messages from './messages';

export enum HighlightNames {
  RMA_NUMBER = 'RmaNumber',
  ORDER_REFERENCE = 'OrderReference',
  TRANSPORT_TRACKING_NUMBERS = 'TransportTrackingNumbers',
  FILE_REFERENCE = 'FileReference',
  SHORT_FILE_REFERENCE = 'ShortFileReference',
  OWNER_FIRST_NAME = 'OwnerFirstName',
  OWNER_LAST_NAME = 'OwnerLastName',
  OWNER_PHONE_NUMBERS = 'OwnerPhoneNumbers',
  OWNER_PHONE_NUMBER = 'OwnerPhoneNumber',
  OWNER_MAIL = 'OwnerMail',
  OWNER_ZIP_CODE = 'OwnerZipCode',
  SERIAL_NUMBER = 'SerialNumber',
  IMEI = 'Imei',
  SELLER = 'Seller',
  BRANDS = 'Brands',
}

const getHighLightLabel = (highlightKey: string) => {
  switch (highlightKey) {
    case HighlightNames.ORDER_REFERENCE:
      return messages.order;
    case HighlightNames.TRANSPORT_TRACKING_NUMBERS:
      return messages.transportTrackingNumbers;
    case HighlightNames.FILE_REFERENCE:
    case HighlightNames.SHORT_FILE_REFERENCE:
      return messages.fileRef;
    case HighlightNames.OWNER_FIRST_NAME:
    case HighlightNames.OWNER_LAST_NAME:
      return messages.ownerName;
    case HighlightNames.OWNER_PHONE_NUMBERS:
    case HighlightNames.OWNER_PHONE_NUMBER:
      return messages.ownerPhoneNumber;
    case HighlightNames.OWNER_MAIL:
      return messages.ownerMail;
    case HighlightNames.OWNER_ZIP_CODE:
      return messages.ownerZipCode;
    case HighlightNames.SERIAL_NUMBER:
      return messages.serialNumber;
    case HighlightNames.IMEI:
      return messages.imei;
    case HighlightNames.SELLER:
      return messages.seller;
    case HighlightNames.BRANDS:
      return messages.brand;
    case HighlightNames.RMA_NUMBER:
      return messages.rmaNumber;
    default:
      return messages.notSupported;
  }
};

const adaptFileToOwnerPhonesHighLights = (file: File) => {
  return [adaptToBoldHighlight(file.ownerPhoneNumber)];
};

export const adaptFileToOtherHighlights = (
  file: File | FileHistoric,
  propertyToExclude: string[] = [],
) => {
  if (isFileHistoric(file)) {
    return [];
  }

  const { highlights = {} } = file;

  const hasANonOtherFieldMatch = Object.keys(highlights).some((key) =>
    propertyToExclude.includes(key),
  );

  if (hasANonOtherFieldMatch) {
    return [];
  }

  const value = Object.entries(highlights).reduce<SearchOtherHighlight[]>(
    (acc, [key, highlight]) => {
      if (propertyToExclude.includes(key)) {
        return acc;
      }

      if (key === HighlightNames.OWNER_PHONE_NUMBERS) {
        return [
          ...acc,
          {
            id: key,
            label: getHighLightLabel(key),
            value: adaptFileToOwnerPhonesHighLights(file),
          },
        ];
      }
      if (
        [
          HighlightNames.OWNER_FIRST_NAME.toString(),
          HighlightNames.OWNER_LAST_NAME.toString(),
        ].includes(key)
      ) {
        return [
          ...acc,
          {
            id: key,
            label: getHighLightLabel(key),
            value: adaptFileToOwnerNameHighLights(file),
          },
        ];
      }

      return [
        ...acc,
        {
          id: key,
          label: getHighLightLabel(key),
          value: adaptHighlights(highlight),
        },
      ];
    },
    [],
  );

  return value;
};

export const adaptFileToFirstOtherHighlights = (
  file: File | FileHistoric,
  propertyToExclude: string[] = [],
) => {
  return adaptFileToOtherHighlights(file, propertyToExclude).filter(
    (_otherHighlight, index) => index === 0,
  );
};
