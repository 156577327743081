import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/matchingActors`;

interface GetMatchingActorsQueryPayload {
  fileId: string;
}

export interface GetMatchingActorsQueryResponseValue {
  identity: {
    id: string;
    type: ACTOR_TYPES;
  };
  label: string;
}

interface GetMatchingActorsQueryResponse extends BaseBackResponse {
  value: GetMatchingActorsQueryResponseValue[];
}

async function getMatchingActorsQuery({
  fileId,
}: GetMatchingActorsQueryPayload): Promise<
  GetMatchingActorsQueryResponseValue[]
> {
  const functionName = 'getMatchingActorsQuery';

  if (!fileId) {
    throw new RequiredPayloadFieldIsNotProvided(fileId, `${functionName}`);
  }

  const url = buildUrl(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
    {},
  );

  const response = await apiCall<GetMatchingActorsQueryResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${functionName}`);
  }

  return response.value;
}

export default getMatchingActorsQuery;
