import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '@savgroup-front-common/core/src/formatters';

import { $MenuCountBadge, $SubLink, $SubMenuList } from '../SidBarMenu.styles';
import { MenuItem } from '../SidBarMenu.types';

interface SubMenusLinkProps {
  menus: MenuItem[];
}
const SubMenusLink: FunctionComponent<
  React.PropsWithChildren<SubMenusLinkProps>
> = ({ menus }) => {
  const theme = useTheme();

  return (
    <$SubMenuList>
      {menus.map((menu) => {
        return (
          <li key={menu.to}>
            <$SubLink
              to={menu.to}
              $isActive={menu.isActive}
              title={safeFormattedIntlString(menu.message)}
              data-testid={`sidebar_submenu_${menu.dataTestId}`}
            >
              <menu.icon
                color={menu.isActive ? theme.colors.primary : '#ccc'}
                size="16px"
              />

              {SafeFormattedMessage(menu.message)}
              <$MenuCountBadge $count={menu.count}>
                {menu.count > 99 ? '99+' : menu.count}
              </$MenuCountBadge>
            </$SubLink>
            {menu.submenus.length > 0 && <SubMenusLink menus={menu.submenus} />}
          </li>
        );
      })}
    </$SubMenuList>
  );
};

SubMenusLink.displayName = 'SubMenusLink';

export default React.memo(SubMenusLink);
