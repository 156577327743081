import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const SELLER_ID = 'SELLER_ID';
const GENERIC_MODEL_TYPE_ID = 'GENERIC_MODEL_TYPE_ID';

const ENDPOINT = `${APIConfiguration.catalog}brands/generic?SellerId=${SELLER_ID}&GenericModelTypeId=${GENERIC_MODEL_TYPE_ID}`;

export interface BrandGenericType {
  id: string;
  name: string;
  label: string;
  sellerId: string;
  parentBrandId: string;
}

interface GetBrandGenericArgs {
  sellerId: string;
  modelTypesGenericId: string;
}

const getBrandGeneric = async ({
  sellerId,
  modelTypesGenericId,
}: GetBrandGenericArgs): Promise<
  BackResponse<BrandGenericType[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(SELLER_ID, sellerId).replace(
      GENERIC_MODEL_TYPE_ID,
      modelTypesGenericId,
    );

    const response = await apiCall<BackResponse<BrandGenericType[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getBrandGeneric;
