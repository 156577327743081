import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OwnerSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/owners/stockForUser`;

interface GetStockOwnerForCurrentUserQueryPayload {
  sellerId: string;
}

async function getStockOwnerForCurrentUserQuery({
  sellerId,
}: GetStockOwnerForCurrentUserQueryPayload): Promise<
  BackResponse<OwnerSummary> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<BackResponse<OwnerSummary>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getStockOwnerForCurrentUserQuery;
