import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}loans/${COMMON_QUERY_PARAMS.LOAN_ID}`;

export interface UpdateLoanCommandArgs {
  id: string;
  state: string;
  sellerId: string;
}

export default async function updateLoanCommand({
  id,
  state,
  sellerId,
}: UpdateLoanCommandArgs): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.LOAN_ID, id);

    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.PUT,
      json: { state, sellerId },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
