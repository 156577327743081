import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  ModelDimension,
  SparePartSupplierAssociationDto,
  SpareProductSize,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}parts/update`;

export interface SparePartNewModelDto {
  id: string;
  manufacturerReference: string;
  manufacturer?: string;
  manufacturerUniqueName: string;
  uniqueName: string;
  manufacturerName: string;
  ean: string;
  manufacturerLabel: string;
  isOEM?: boolean;
  sparePartType?: string;
  status: string;
  category?: string;
  subCategory?: string;
  size?: SpareProductSize;
  dimensions?: ModelDimension;
  weight?: number;
  isCustomerSaleAllowed?: boolean;
  outOfWarrantyReturnProcess: string;
  warrantyReturnProcess: string;
  manufacturerWarranty?: boolean;
  sparePartSupplierAssociations?: SparePartSupplierAssociationDto[];
  comment?: string;
}

interface UpdateMultipleSparePartsCommandPayload {
  spareParts: SparePartNewModelDto[];
  sellerId: string;
}

export async function updateMultipleSparePartsCommand(
  payload: UpdateMultipleSparePartsCommandPayload,
): Promise<BaseBackResponse> {
  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
