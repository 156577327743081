import { createSelector } from 'reselect';

import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { ControlRootState } from 'control/domains/ControlRootState';
import { isFacetActive } from 'control/helpers';

import { ALERTS_SEARCH } from './actionTypes';
import { ALERTS_LIST_DOMAIN_KEY } from './constants';
import { ALERTS_LIST_DOMAIN } from './types';

export const alertsListSelector = (state: ControlRootState) =>
  state[ALERTS_LIST_DOMAIN_KEY];

export const seletAlertsListPagination = createSelector(
  alertsListSelector,
  (alerts) => {
    return {
      query: alerts[ALERTS_LIST_DOMAIN.QUERY] || undefined,
      sort: alerts[ALERTS_LIST_DOMAIN.SORT],
      isDescending: alerts[ALERTS_LIST_DOMAIN.IS_DESCENDING],
      page: alerts[ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER],
      pageSize: alerts[ALERTS_LIST_DOMAIN.BY_PAGE],
    };
  },
);
export const seletAlertsListFilters = createSelector(
  alertsListSelector,
  (alerts) =>
    alerts[ALERTS_LIST_DOMAIN.RUBRICS].map((rubric) => ({
      ...rubric,
      values: rubric.values.filter((facet) => !facet.isHidden),
    })),
);

export const selectAlertsListIsLoading = (
  state: ControlRootState,
  { page }: { page: number },
) => isActionLoadingSelector(state, ALERTS_SEARCH, page);

export const selectAlertsListCount = createSelector(
  alertsListSelector,
  (state) => state[ALERTS_LIST_DOMAIN.TOTAL_COUNT],
);

export const selectAlertsListWasLoaded = (
  state: ControlRootState,
  { page }: { page: number },
) => wasActionLoadedSelector(state, ALERTS_SEARCH, page);

export const selectAlertsListAppliedFiltersCount = createSelector(
  [seletAlertsListFilters],
  (rubrics) => {
    return rubrics.reduce(
      (acc, rubric) => acc + rubric.values.filter(isFacetActive).length,
      0,
    );
  },
);

export const selectAlertsListErrors = (
  state: ControlRootState,
  { page }: { page: number },
): ErrorFromBack[] => getActionErrorsSelector(state, ALERTS_SEARCH, page);
