import { useCallback, useEffect, useRef, useState } from 'react';

const MINIMAL_SIZE = 350;

interface StateTypes {
  width: number;
  height: number;
  top: number;
  left: number;
  rel?: {
    x: number;
    y: number;
  };
  isDragging: boolean;
  isResizing: boolean;
}

interface UseFlexibleModalProps {
  top?: number;
  left?: number;
  initHeight: number;
  initWidth: number;
  minWidth: number;
  minHeight: number;
  disableMove?: boolean;
  disableVerticalMove?: boolean;
  disableHorizontalMove?: boolean;
  isMinimised?: boolean;
  disableResize?: boolean;
  disableKeystroke?: boolean;
  disableVerticalResize?: boolean;
  disableHorizontalResize?: boolean;
  onRequestClose: () => void;
  shouldKeepRatio: boolean;
}

export const useFlexibleModal = ({
  top,
  left,
  initWidth,
  initHeight,
  disableHorizontalResize,
  disableVerticalResize,
  minHeight,
  minWidth,
  disableHorizontalMove,
  disableMove,
  disableVerticalMove,
  disableResize,
  onRequestClose,
  disableKeystroke,
  isMinimised,
  shouldKeepRatio,
}: UseFlexibleModalProps) => {
  const [state, setState] = useState<StateTypes>({
    isDragging: false,
    isResizing: false,
    top: top !== undefined ? top : window.innerHeight / 2 - initHeight / 2 - 50,
    left:
      left !== undefined ? left : window.innerWidth / 2 - initWidth / 2 - 21,
    width: initWidth,
    height: initHeight,
  });

  const ratio = initHeight / initWidth;

  const refModal = useRef<HTMLDivElement>(null);
  const refDragArea = useRef<HTMLDivElement>(null);

  const resize = useCallback(
    (width?: number, height?: number) => {
      setState((prevState) => ({
        ...prevState,
        width: width || prevState.width,
        height: shouldKeepRatio
          ? (width || prevState.width) * ratio
          : height || prevState.height,
      }));
    },
    [ratio, shouldKeepRatio],
  );

  const handleResizing = useCallback(
    (clientX: number, clientY: number) => {
      const node = refModal.current;

      if (!node) {
        return;
      }

      const shouldUpdateHeight =
        !disableVerticalResize && node && clientY > node.offsetTop + minHeight;
      const shouldUpdateWidth =
        !disableHorizontalResize &&
        node &&
        clientX > node.offsetLeft + minWidth;

      resize(
        shouldUpdateWidth ? clientX - node.offsetLeft + 16 / 2 : undefined,
        shouldUpdateHeight ? clientY - node.offsetTop + 16 / 2 : undefined,
      );
    },
    [
      disableHorizontalResize,
      disableVerticalResize,
      minHeight,
      minWidth,
      resize,
    ],
  );

  const onMouseMove = useCallback(
    (e: {
      pageY: number;
      pageX: number;
      clientX: any;
      clientY: any;
      stopPropagation: () => void;
      preventDefault: () => void;
    }) => {
      if (state.isDragging && state.rel) {
        if (disableMove || (disableVerticalMove && disableHorizontalMove)) {
          return;
        }

        if (!disableVerticalMove && disableHorizontalMove) {
          setState({
            ...state,
            top: e.pageY - state.rel.y,
          });
        } else if (disableVerticalMove && !disableHorizontalMove) {
          setState({
            ...state,
            left: e.pageX - state.rel.x,
          });
        } else if (!disableVerticalMove && !disableHorizontalMove) {
          setState({
            ...state,
            left: e.pageX - state.rel.x,
            top: e.pageY - state.rel.y,
          });
        }

        e.stopPropagation();
        e.preventDefault();

        return;
      }

      if (state.isResizing) {
        handleResizing(e.clientX, e.clientY);
      }
    },
    [
      disableHorizontalMove,
      disableMove,
      disableVerticalMove,
      handleResizing,
      state,
    ],
  );

  const onMouseDown = useCallback(
    (e: {
      button: number;
      pageX: number;
      pageY: number;
      stopPropagation: () => void;
      preventDefault: () => void;
    }) => {
      if (e.button !== 0) {
        return;
      }

      const pos = refModal.current;

      if (pos) {
        setState({
          ...state,
          isDragging: true,
          rel: {
            x: e.pageX - pos.offsetLeft,
            y: e.pageY - pos.offsetTop,
          },
        });
      }
      e.stopPropagation();
      e.preventDefault();
    },
    [state],
  );

  const onMouseUp = useCallback(
    (e: { stopPropagation: () => void }) => {
      document.removeEventListener('mousemove', onMouseMove);
      setState({ ...state, isDragging: false, isResizing: false });

      e.stopPropagation();
    },
    [onMouseMove, state],
  );

  const handleUpdateStateResizing = useCallback(
    (isResizing: any) => {
      setState({ ...state, isResizing });
    },
    [state],
  );

  const pressKey = useCallback(
    (e: { ctrlKey: any; keyCode: any }) => {
      if (e.ctrlKey) {
        switch (e.keyCode) {
          case 37: {
            if (!disableResize) {
              setState((prevState) => ({
                ...prevState,
                width: prevState.width - 20,
              }));
            }
            break;
          }
          case 38: {
            if (!disableResize) {
              setState((prevState) => ({
                ...prevState,
                height: prevState.height - 20,
              }));
            }
            break;
          }
          case 39: {
            if (!disableResize) {
              setState((prevState) => ({
                ...prevState,
                width: prevState.width + 20,
              }));
            }
            break;
          }
          case 40: {
            if (!disableResize) {
              setState((prevState) => ({
                ...prevState,
                height: prevState.height + 20,
              }));
            }
            break;
          }
          default:
            break;
        }
      } else {
        switch (e.keyCode) {
          case 27: {
            onRequestClose();
            break;
          }
          case 37: {
            if (!disableMove && !disableHorizontalMove) {
              setState((prevState) => ({
                ...prevState,
                left: prevState.left - 20,
              }));
            }
            break;
          }
          case 38: {
            if (!disableMove && !disableVerticalMove) {
              setState((prevState) => ({
                ...prevState,
                top: prevState.top - 20,
              }));
            }
            break;
          }
          case 39: {
            if (!disableMove && !disableHorizontalMove) {
              setState((prevState) => ({
                ...prevState,
                left: prevState.left + 20,
              }));
            }
            break;
          }
          case 40: {
            if (!disableMove && !disableVerticalMove) {
              setState((prevState) => ({
                ...prevState,
                top: prevState.top + 20,
              }));
            }
            break;
          }
          default:
            break;
        }
      }
    },
    [
      disableHorizontalMove,
      disableMove,
      disableResize,
      disableVerticalMove,
      onRequestClose,
    ],
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    if (!disableKeystroke) {
      document.addEventListener('keydown', pressKey);
    }

    return () => {
      if (document.removeEventListener) {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('mousemove', onMouseMove);
        if (!disableKeystroke) {
          document.removeEventListener('keydown', pressKey);
        }
      }
    };
  }, [disableKeystroke, onMouseMove, onMouseUp, pressKey]);

  useEffect(() => {
    if (isMinimised) {
      setState((previousState) => ({
        ...previousState,
        width: MINIMAL_SIZE,
        height: MINIMAL_SIZE,
        top: window.innerHeight - MINIMAL_SIZE - 50,
        left: window.innerWidth - MINIMAL_SIZE - 50,
      }));
    } else {
      setState((previousState) => ({
        ...previousState,
        width: initWidth,
        height: initHeight,
        top:
          top !== undefined
            ? top
            : window.innerHeight / 2 - initHeight / 2 - 50,
        left:
          left !== undefined
            ? left
            : window.innerWidth / 2 - initWidth / 2 - 21,
      }));
    }
  }, [isMinimised, initHeight, initWidth, left, top]);

  return {
    refModal,
    refDragArea,
    state,
    handleUpdateStateResizing,
    onMouseDown,
  };
};
