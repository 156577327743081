import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { matchPath } from 'react-router-dom';
import { all, delay, put, select, take } from 'redux-saga/effects';

import { permissionsArdLoaded } from '@savgroup-front-common/core/src/domains/login/selectors';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';
import { fileSummarySelectors } from 'control/domains/workflow/fileSummary';
import { GET_FILE_FULL_SUMMARY } from 'control/domains/workflow/fileSummary/actions';

import { FILE_ROUTES } from '../../../constants/routing';
import {
  ActionCreators as MarketplaceActionCreators,
  ActionTypes as MarketplaceActionTypes,
} from '../../marketplaceTickets';
import * as MarketplaceSelectors from '../../marketplaceTickets/selectors';
import { loadModelsInfo } from '../../models/actionCreators';
import { LOAD_MODELS_INFO } from '../../models/actionTypes';

export default function* clientTabSaga() {
  let isLoaded = yield select(permissionsArdLoaded);

  while (!isLoaded) {
    yield delay(100);
    isLoaded = yield select(permissionsArdLoaded);
  }
  const path = yield select(pathname);
  const match = matchPath(path, {
    path: FILE_ROUTES.CLIENT,
    exact: true,
    strict: false,
  });
  const { fileId } = match.params;

  yield take(GET_FILE_FULL_SUMMARY.SUCCEEDED);
  const fileSummary = yield select(fileSummarySelectors.getFileSummaryValue, {
    fileId,
  });
  const ownerId = get(fileSummary, ['value', 'ownerId']);

  yield all([
    put(MarketplaceActionCreators.loadOwnerProducts(ownerId)),
    take(MarketplaceActionTypes.LOAD_OWNER_PRODUCTS.END),
  ]);
  const ownerProducts = yield select(
    MarketplaceSelectors.getOwnerProducts,
    ownerId,
  );
  const clientProducts = get(ownerProducts, 'value');
  const productIds = uniqBy(clientProducts, 'sellerProductId').map(
    (product) => product.sellerProductId,
  );

  yield all([
    put(MarketplaceActionCreators.loadSellerProductsInfo({ ids: productIds })),
    take(MarketplaceActionTypes.LOAD_SELLER_PRODUCTS_INFO.END),
  ]);

  const sellerProductsList = yield select(
    MarketplaceSelectors.getLoadSellerProductsList,
  );
  const sellerProductsListValue = get(sellerProductsList, 'value');
  const modelIds = uniqBy(sellerProductsListValue, 'modelId').map(
    (product) => product.modelId,
  );

  yield all([
    put(loadModelsInfo({ ids: modelIds })),
    take(LOAD_MODELS_INFO.END),
  ]);
}
