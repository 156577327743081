import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}createShippingBoToken`;

const createShippingBoTokenCommand = async (payload: {
  sellerId: string;
  code: string;
}): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    const response = await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default createShippingBoTokenCommand;
