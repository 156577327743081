import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponseFailure } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.document}balancingStockItemToRegulateExports/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}`;

export interface DownloadBalancingStockItemToRegulateExportQueryArgs {
  stockInventoryId: string;
}

export default async function downloadBalancingStockItemToRegulateExportQuery(
  payload: DownloadBalancingStockItemToRegulateExportQueryArgs,
): Promise<Blob | BackResponseFailure> {
  try {
    const file = await apiCall<Blob>(
      ENDPOINT.replace(
        COMMON_QUERY_PARAMS.STOCK_ITEM_ID,
        payload.stockInventoryId,
      ),
      {
        method: SUPPORTED_METHODS.GET,
        responseType: 'blob',
      },
    );

    return file;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
