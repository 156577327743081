import styled from 'styled-components';

interface $ActionContainerProps {
  $isDanger: boolean;
}

export const $ActionContainer = styled.div<$ActionContainerProps>`
  position: relative;
  &::after {
    background-color: ${({ theme, $isDanger }) =>
      $isDanger ? theme.colors.danger : 'transparent'};
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    pointer-events: none;
  }
`;
