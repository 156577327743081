import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  DiagnosticTreeStep,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/diagnostic/${COMMON_QUERY_PARAMS.TREE_UNIQUE_NAME}`;

interface StartOrContinueDiagnosticTreeQueryArgs {
  claimId: string;
  treeUniqueName: string;
}

interface StartOrContinueDiagnosticTreeQueryReturnValues {
  currentNode: DiagnosticTreeStep;
  history: DiagnosticTreeStep[];
}

const startOrContinueDiagnosticTreeQuery = async ({
  claimId,
  treeUniqueName,
}: StartOrContinueDiagnosticTreeQueryArgs): Promise<
  | BackResponse<StartOrContinueDiagnosticTreeQueryReturnValues>
  | BackResponseFailure
> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId).replace(
        COMMON_QUERY_PARAMS.TREE_UNIQUE_NAME,
        treeUniqueName,
      ),
      {
        ForceRestart: true,
      },
    );

    const response = await apiCall<
      BackResponse<StartOrContinueDiagnosticTreeQueryReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default startOrContinueDiagnosticTreeQuery;
