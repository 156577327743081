import { RESULT_DISPLAY_TYPE, SearchResult } from '../Search.types';

const isResultFocusable = (result: SearchResult) => {
  switch (result.displayType) {
    case RESULT_DISPLAY_TYPE.RESULT:
    case RESULT_DISPLAY_TYPE.SHOW_MORE:
      return true;

    default:
      return false;
  }
};

export default isResultFocusable;
