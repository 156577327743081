import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.document}SparePartItemStockLabel/:SparePartItemRequestLineId`;

interface GenerateAndDownloadSparePartItemStockLabelQueryArgs {
  labelInchWidth?: number;
  labelInchHeight?: number;
  labelDensityDPI?: number;
  sparePartItemRequestLineId: string;
}

export const generateAndDownloadSparePartItemStockLabelQuery = async ({
  labelInchWidth,
  labelInchHeight,
  labelDensityDPI,
  sparePartItemRequestLineId,
}: GenerateAndDownloadSparePartItemStockLabelQueryArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      ':SparePartItemRequestLineId',
      sparePartItemRequestLineId,
    );

    return await apiCall<BackResponse<string>>(
      buildUrl(url, {
        labelInchWidth,
        labelInchHeight,
        labelDensityDPI,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
