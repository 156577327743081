import { fromJS } from 'immutable';
import find from 'lodash/find';
import get from 'lodash/get';
import { all, call, put, race, take, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import {
  ANSWER_TO_QUOTE,
  GET_PENDING_CUSTOMER_VALIDAITON_QUOTATION,
} from './actionTypes';
import {
  ActionCreators as QuoteActionCreators,
  ActionTypes as QuoteActionTypes,
} from './index';

function* answerToQuoteWorker({ payload }) {
  const { file, answer, callback } = payload;

  const pendingCustomerValidationQuotation = yield callAndGetResponse(
    GET_PENDING_CUSTOMER_VALIDAITON_QUOTATION,
    `${APIConfiguration.repairer}files/${file.fileId}/quotation/pendingCustomerValidation`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  const quoteId = pendingCustomerValidationQuotation.payload?.value?.id;

  if (quoteId) {
    const [, validateQuoteResponse] = yield all([
      put(QuoteActionCreators.validateRejectQuote({ quoteId, answer })),
      race([
        take(QuoteActionTypes.VALIDATE_REJECT_QUOTE.SUCCEEDED),
        take(QuoteActionTypes.VALIDATE_REJECT_QUOTE.ERRORED),
      ]),
    ]);

    if (
      get(
        find(validateQuoteResponse, (r) => Boolean(r)),
        'type',
      ) === QuoteActionTypes.VALIDATE_REJECT_QUOTE.SUCCEEDED &&
      callback
    ) {
      yield call(callback);
    }
  } else {
    const [
      ,
      {
        meta: { quoteId: newQuoteId },
      },
    ] = yield all([
      put(
        QuoteActionCreators.getCurrentDraftedQuote(
          fromJS({
            fileId: file.fileId,
            sellerId: file.sellerId,
          }),
        ),
      ),
      take(QuoteActionTypes.GET_CURRENT_DRAFTED_QUOTE.END),
    ]);

    const [, validateQuoteResponse] = yield all([
      put(
        QuoteActionCreators.validateRejectQuote({
          quoteId: newQuoteId,
          answer,
        }),
      ),
      race([
        take(QuoteActionTypes.VALIDATE_REJECT_QUOTE.SUCCEEDED),
        take(QuoteActionTypes.VALIDATE_REJECT_QUOTE.ERRORED),
      ]),
    ]);

    if (
      get(
        find(validateQuoteResponse, (r) => Boolean(r)),
        'type',
      ) === QuoteActionTypes.VALIDATE_REJECT_QUOTE.SUCCEEDED &&
      callback
    ) {
      yield call(callback);
    }
  }
}
function* answerToQuoteWatcher() {
  yield takeEvery(ANSWER_TO_QUOTE, answerToQuoteWorker);
}

export default function* editQuoteSaga() {
  try {
    yield all([answerToQuoteWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
