import { SEARCH_HEIGHT } from '../Search.contants';
import { RESULT_DISPLAY_TYPE, SearchResult } from '../Search.types';

function getResultSize(result: SearchResult): number {
  switch (result?.displayType) {
    case RESULT_DISPLAY_TYPE.TITLE:
      return SEARCH_HEIGHT.TITLE;

    case RESULT_DISPLAY_TYPE.RESULT:
      return SEARCH_HEIGHT.RESULT;

    case RESULT_DISPLAY_TYPE.ERROR:
      return SEARCH_HEIGHT.ERROR;

    case RESULT_DISPLAY_TYPE.SHOW_MORE:
      return SEARCH_HEIGHT.SHOW_MORE_RESULT;

    case RESULT_DISPLAY_TYPE.CONTROLS:
      return SEARCH_HEIGHT.CONTROLS;

    default:
      return 0;
  }
}

export default getResultSize;
