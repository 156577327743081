import styled from 'styled-components';

export const $FlexibleModalMask = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

export const $DragArea = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
  width: 100%;
  height: 100%;
`;
