import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { ModalHeader } from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { ChooseKeyboardLayout, ShortcutTable } from './Components';
import {
  useListShortcuts,
  useModalShortcuts,
  useSearchShortcuts,
} from './hooks';
import messages from './messages';

const ShortcutsContent: FunctionComponent = () => {
  const searchShortcuts = useSearchShortcuts();
  const modalShortcuts = useModalShortcuts();
  const listShortcuts = useListShortcuts();

  const isLarge = useMedia(media.minWidth.lg);

  return (
    <>
      <ModalHeader>
        <Heading level={2}>
          <SafeFormattedMessageWithoutSpread message={messages.title} />
        </Heading>
      </ModalHeader>

      <ChooseKeyboardLayout />

      <Row>
        <Col lg={6} xs={12}>
          <ShortcutTable title={messages.searchTitle} data={searchShortcuts} />
          <ShortcutTable
            title={messages.listTitle}
            data={listShortcuts}
            withoutMarginBottom={isLarge}
          />
        </Col>
        <Col lg={6} xs={12}>
          <ShortcutTable
            title={messages.modalTitle}
            data={modalShortcuts}
            withoutMarginBottom={!isLarge}
          />
        </Col>
      </Row>
    </>
  );
};

ShortcutsContent.displayName = 'ShortcutsContent';

export default ShortcutsContent;
