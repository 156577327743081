import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}publicApi/apiApplication/${COMMON_QUERY_PARAMS.SELLER_ID}`;

async function deleteApiApplication({
  sellerId,
  applicationId,
}: {
  sellerId: string;
  applicationId: string;
}): Promise<BaseBackResponse> {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

  try {
    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.DELETE,
      json: { applicationId },
    });

    return response;
  } catch (exception) {
    return { failure: true, errors: (exception as any).errors } as any;
  }
}

export default deleteApiApplication;
