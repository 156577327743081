import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { PERMISSIONS } from '@savgroup-front-common/constants/src/control';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';
import {
  useGetSellerConfiguration,
  useHasPermission,
  useHasSomePermissions,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';
import { useControlTypedSelector } from 'control/hooks';
import useHasSellerSettingsPagePermission from 'control/view/components/Settings/SellerSettings/SellerSettings.hooks';
import useHasBusinessIntelligencePermission from 'control/view/pages/BusinessIntelligencePage/hooks/useHasBusinessIntelligencePermission';

import { transformToMenu } from '../SidBarMenu.helpers';
import { MENU_NAMES, MenuConf, MenuItem } from '../SidBarMenu.types';

import useFaqUrl from './useFaqUrl';
import useGetCount from './useGetCount';

const useGetMenuItems = (): { menus: MenuItem[]; index: number } => {
  const location: any = useLocation();

  const pathname = location.pathname || location?.location?.pathname;
  const { alertsCount, todosCount } = useGetCount();
  const sellers = useControlTypedSelector(sellerSelectors.selectSellers);
  const initSellerSelected = sellers[0];

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId: initSellerSelected?.id,
  });

  const hasTicketReadingPermission = useHasPermission(
    PERMISSIONS.TICKET_READING,
  );
  const hasGetOrdersPermission = useHasPermission(PERMISSIONS.GET_ORDER);
  const hasImportsPermission = useHasPermission(PERMISSIONS.IMPORT_MANAGEMENT);

  const hasSellerSettingsPermissions = useHasSellerSettingsPagePermission();

  const hasUserManagementPermission = useHasPermission(
    PERMISSIONS.ROLE_MANAGEMENT,
  );
  const haseSanityTestsSettingsPermission = useHasPermission(
    PERMISSIONS.SANITY_TESTS,
  );

  const hasSparePartPermission = useHasSomePermissions([
    PERMISSIONS.ACCESS_SPARE_PART_REQUEST,
    PERMISSIONS.ACCESS_SPARE_PART_STOCK_ITEMS,
    PERMISSIONS.MANAGE_SPARE_PART_CATALOG,
  ]);

  const hasSparePartRequestPermission = useHasPermission(
    PERMISSIONS.ACCESS_SPARE_PART_REQUEST,
  );
  const hasCreateSparePartRequestPermission = useHasPermission(
    PERMISSIONS.CREATE_SPARE_PART_REQUEST,
  );

  const hasSparePartStockPermission = useHasPermission(
    PERMISSIONS.ACCESS_SPARE_PART_STOCK_ITEMS,
  );
  const hasFilesPermission = useHasPermission(PERMISSIONS.SEARCH_FILE);
  const hasTodoAndAlertPermissions = useHasSomePermissions([
    PERMISSIONS.GET_WORKFLOW,
    PERMISSIONS.WORKFLOW_MANAGEMENT,
  ]);

  const hasSomeFilePermissions = useHasSomePermissions([
    PERMISSIONS.SEARCH_FILE,
    PERMISSIONS.GET_WORKFLOW,
    PERMISSIONS.WORKFLOW_MANAGEMENT,
  ]);

  const hasSomeFileHistoricPermissions = useHasSomePermissions([
    PERMISSIONS.VIEW_HISTORICAL_FILE,
  ]);

  const hasBulkChangeAccessPermissions = useHasSomePermissions([
    PERMISSIONS.BULK_CHANGE_ACCESS_RIGHTS,
  ]);

  const hasBusinessIntelligencePermission =
    useHasBusinessIntelligencePermission();

  const hasManageSparePartCatalogPermission = useHasPermission(
    PERMISSIONS.MANAGE_SPARE_PART_CATALOG,
  );

  const { isFaqEnabled } = useFaqUrl();

  const hasUpdateRequestLineValidityPermission = useHasPermission(
    PERMISSIONS.UPDATE_REQUEST_LINE_VALIDITY,
  );

  const hasInventoryPermission = useHasSomePermissions([
    PERMISSIONS.STOCK_INVENTORY_OPERATOR,
    PERMISSIONS.STOCK_INVENTORY_SUPERVISOR,
  ]);

  const hasConsoleAccessRightsPermission = useHasPermission(
    PERMISSIONS.CONSOLE_ACCESS_RIGHTS,
  );

  const hasBillingAccessRightsPermission = useHasPermission(
    PERMISSIONS.BILLING_ACCESS_RIGHTS,
  );

  const isSuperAdmin = useIsSuperAdmin();

  const menus = useMemo(() => {
    const items: MenuConf[] = [
      {
        nav: MENU_NAMES.FILES,
        expandIfAlone: true,
        permission: hasSomeFilePermissions,
        submenus: [
          {
            nav: MENU_NAMES.FILES,
            permission: hasFilesPermission,
          },
          {
            nav: MENU_NAMES.ALERTS,
            count: alertsCount,
            permission: hasTodoAndAlertPermissions,
          },
          {
            nav: MENU_NAMES.TODOS,
            count: todosCount,
            permission: hasTodoAndAlertPermissions,
          },
          {
            nav: MENU_NAMES.FILES_HISTORIC,
            permission: hasSomeFileHistoricPermissions,
          },
          {
            nav: MENU_NAMES.BULK_CHANGES,
            permission: hasBulkChangeAccessPermissions,
          },
        ],
      },
      {
        nav: MENU_NAMES.SPARE_PARTS,
        expandIfAlone: true,
        forceIconIfAlone: true,
        permission: hasSparePartPermission,
        submenus: [
          {
            nav: MENU_NAMES.SPARE_PART_REQUESTS,
            permission: hasSparePartRequestPermission,
          },
          {
            nav: MENU_NAMES.SPARE_PART_REQUESTS_TO_VALIDATE,
            permission: hasUpdateRequestLineValidityPermission,
          },
          {
            nav: MENU_NAMES.SPARE_PART_STOCK,
            permission: hasSparePartStockPermission,
          },
          {
            nav: MENU_NAMES.CREATE_SPARE_PART_REQUEST,
            permission:
              (hasCreateSparePartRequestPermission &&
                sellerConfiguration?.isSparePartNewModel) ||
              isSuperAdmin,
          },
          {
            nav: MENU_NAMES.SETTINGS_SPARE_PART_CATALOG,
            permission: hasManageSparePartCatalogPermission,
          },
          {
            nav: MENU_NAMES.SPARE_PART_INVENTORIES,
            permission: hasInventoryPermission || isSuperAdmin,
          },
        ],
      },
      { nav: MENU_NAMES.MESSAGES, permission: hasTicketReadingPermission },
      { nav: MENU_NAMES.ORDERS, permission: hasGetOrdersPermission },
      {
        nav: MENU_NAMES.BUSINESS_INTELLIGENCE,
        permission: hasBusinessIntelligencePermission,
      },
      {
        nav: MENU_NAMES.SETTINGS,
        permission:
          hasSellerSettingsPermissions ||
          hasUserManagementPermission ||
          haseSanityTestsSettingsPermission ||
          hasImportsPermission,
        submenus: [
          {
            nav: MENU_NAMES.SETTINGS_SELLERS,
            permission: hasSellerSettingsPermissions,
          },
          {
            nav: MENU_NAMES.SETTINGS_MASTER,
          },
          {
            nav: MENU_NAMES.SETTINGS_USERS,
            permission: hasUserManagementPermission,
          },
          {
            nav: MENU_NAMES.SETTINGS_TESTS,
            permission: haseSanityTestsSettingsPermission,
          },
          {
            nav: MENU_NAMES.SETTINGS_IMPORTS,
            permission: hasImportsPermission,
          },
          {
            nav: MENU_NAMES.SETTINGS_SPARE_PARTS,
            permission: hasManageSparePartCatalogPermission,
          },
        ],
      },
      {
        nav: MENU_NAMES.CONFIGURATION_CONSOLE,
        permission: hasConsoleAccessRightsPermission,
        submenus: [
          {
            nav: MENU_NAMES.CONFIGURATION_CONSOLE_RULES,
          },
          {
            nav: MENU_NAMES.CONFIGURATION_CONSOLE_IMPORT,
            permission: isSuperAdmin,
          },
          {
            nav: MENU_NAMES.CONFIGURATION_CONSOLE_OPTIONS,
            permission: isSuperAdmin,
          },
        ],
      },
      {
        nav: MENU_NAMES.BILLING,
        permission: hasBillingAccessRightsPermission,
        submenus: [
          {
            nav: MENU_NAMES.BILLING_TRACKING,
          },
          {
            nav: MENU_NAMES.BILLING_CUSTOMERS_LIST,
          },
          {
            nav: MENU_NAMES.BILLING_PARAMETERS,
          },
        ],
      },
      {
        nav: MENU_NAMES.HELP,
        permission: isSuperAdmin || isFaqEnabled,
        isExternal: true,
      },
    ];

    return transformToMenu(items, pathname);
  }, [
    hasSomeFilePermissions,
    hasFilesPermission,
    alertsCount,
    hasTodoAndAlertPermissions,
    todosCount,
    hasSomeFileHistoricPermissions,
    hasBulkChangeAccessPermissions,
    hasSparePartPermission,
    hasSparePartRequestPermission,
    hasUpdateRequestLineValidityPermission,
    hasSparePartStockPermission,
    hasCreateSparePartRequestPermission,
    sellerConfiguration?.isSparePartNewModel,
    isSuperAdmin,
    hasManageSparePartCatalogPermission,
    hasInventoryPermission,
    hasTicketReadingPermission,
    hasGetOrdersPermission,
    hasBusinessIntelligencePermission,
    hasSellerSettingsPermissions,
    hasUserManagementPermission,
    haseSanityTestsSettingsPermission,
    hasImportsPermission,
    hasConsoleAccessRightsPermission,
    hasBillingAccessRightsPermission,
    isFaqEnabled,
    pathname,
  ]);

  return {
    index: menus.findIndex((menu) => menu.isActive),
    menus,
  };
};

export default useGetMenuItems;
