import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { ImportVersionWithUserDetailsDto } from './getVersionsQuery';

const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/importversionsbyfiles`;

export interface ImportVersionByFileSummaryDto {
  fileName?: string;
  versions?: ImportVersionWithUserDetailsDto[];
}

interface GetVersionsByFileQueryArgs {
  sellerId: string;
}

const getVersionsByFileQuery = async (
  payload: GetVersionsByFileQueryArgs,
): Promise<
  BackResponseFailure | BackResponse<ImportVersionByFileSummaryDto[]>
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      payload.sellerId,
    );

    return await apiCall<BackResponse<ImportVersionByFileSummaryDto[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getVersionsByFileQuery;
