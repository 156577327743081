import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GetSparePartSupplierBySellerIdPayload } from './actionCreators';
import * as types from './actionTypes';
import {
  selectGetSparePartSupplierBySellerIdIsLoading,
  selectGetSparePartSupplierBySellerIdWasLoaded,
} from './selectors';

function* getSparePartSuppliersBySellerIdWorker({
  payload,
}: {
  payload: GetSparePartSupplierBySellerIdPayload;
}): Generator {
  const URL = `${APIConfiguration.sparePart}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/suppliers`;
  const { sellerId } = payload;

  if (!sellerId) {
    return;
  }

  const isLoading = yield select(
    selectGetSparePartSupplierBySellerIdIsLoading,
    {
      sellerId,
    },
  );
  const wasLoaded = yield select(
    selectGetSparePartSupplierBySellerIdWasLoaded,
    {
      sellerId,
    },
  );

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID,
    URL.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
    { method: SUPPORTED_METHODS.GET },
    { indexer: sellerId },
  );

  yield put(types.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID.end({ sellerId }));
}
function* getSparePartSuppliersBySellerIdWatcher() {
  yield takeEvery<Action<GetSparePartSupplierBySellerIdPayload>>(
    types.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID.BASE,
    getSparePartSuppliersBySellerIdWorker,
  );
}

export default function* sparePartSupplierSaga(): Generator {
  try {
    yield all([getSparePartSuppliersBySellerIdWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
