import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

/* eslint-disable max-len */
const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/products/${COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID}/safetyrisk`;

interface SetModelSafetyriskFilePayload {
  fileId: string;
  ownerProductId: string;
}

const setModelSafetyriskFile = async ({
  fileId,
  ownerProductId,
}: SetModelSafetyriskFilePayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId).replace(
      COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID,
      ownerProductId,
    );

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default setModelSafetyriskFile;
