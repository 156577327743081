import React, { FunctionComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  AnimationDirection,
  BUTTON_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import { getAnimationDirectionClassNames } from '@savgroup-front-common/core/src/animations/helper';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import {
  $ActionContainer,
  $AttachmentsAnimation,
  $AttachmentsContainer,
} from './DocumentPreviewNavigation.styles';
import messages from './messages';

interface AttachmentPreviewNavigationProps {
  count: number;
  activePreview: number;
  onChange: (index: number) => void;
  animationType: AnimationDirection;
  isZoomed: boolean;
  isNavigationEnabled: boolean;
}

const AttachmentPreviewNavigation: FunctionComponent<
  React.PropsWithChildren<AttachmentPreviewNavigationProps>
> = ({
  children,
  count,
  activePreview,
  onChange,
  animationType,
  isZoomed,
  isNavigationEnabled,
}) => {
  const classNames = getAnimationDirectionClassNames(animationType);

  return (
    <$AttachmentsContainer>
      <$ActionContainer>
        {activePreview > 0 && isNavigationEnabled && (
          <Button
            type={BUTTON_TYPES.BUTTON}
            onClick={(event) => {
              event.stopPropagation();
              onChange(activePreview - 1);
            }}
            icon={<ChevronLeftIcon />}
            primary
          >
            <SafeFormattedMessageWithoutSpread message={messages.previous} />
          </Button>
        )}
      </$ActionContainer>
      <TransitionGroup
        component={null}
        childFactory={(child) => React.cloneElement(child, { classNames })}
      >
        <CSSTransition
          key={activePreview}
          timeout={400}
          unmountOnExit
          classNames={classNames}
        >
          {(animationState) => (
            <$AttachmentsAnimation
              $isZoomed={isZoomed}
              animationState={animationState}
              animationDuration={400}
            >
              {children}
            </$AttachmentsAnimation>
          )}
        </CSSTransition>
      </TransitionGroup>
      <$ActionContainer>
        {activePreview !== count - 1 && isNavigationEnabled && (
          <Button
            icon={<ChevronRightIcon />}
            type={BUTTON_TYPES.BUTTON}
            onClick={(event) => {
              event.stopPropagation();
              onChange(activePreview + 1);
            }}
            primary
          >
            <SafeFormattedMessageWithoutSpread message={messages.next} />
          </Button>
        )}
      </$ActionContainer>
    </$AttachmentsContainer>
  );
};

AttachmentPreviewNavigation.displayName = 'AttachmentPreviewNavigation';

export default AttachmentPreviewNavigation;
