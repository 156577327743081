import { uniqBy } from 'lodash';

import {
  RESULT_DISPLAY_TYPE,
  SEARCH_ACTION_TYPES,
  SearchAction,
  SearchResult,
  SearchResultItem,
  SearchState,
} from '../Search.types';

import generateResultTitle from './generateResultTitle';
import generateShowMoreResult from './generateShowMoreResult';
import getContainerSizeForMoreResults from './getContainerSizeForMoreResults';
import getResultListSize from './getResultListSize';

const buildSetMoreResultsState = (
  state: SearchState,
  action: SearchAction,
): SearchState => {
  if (action.type !== SEARCH_ACTION_TYPES.SET_MORE_RESULTS) {
    return state;
  }
  const { focusedResultType: type } = state;

  if (!type) {
    return state;
  }
  const { totalCount, value: incomingResutls } = action.payload.response[type];

  const previousResult = state.storedResults
    .filter(
      (result) =>
        result.displayType === RESULT_DISPLAY_TYPE.RESULT &&
        result.resultType === type,
    )
    .map<SearchResult>((result) => ({
      ...result,
      isNew:
        state.results.length > 0 &&
        state.results.every(({ key }) => key !== result.key),
      animationIndex: 0,
    }));

  const results = uniqBy(
    [
      ...previousResult,
      ...incomingResutls.map(
        (result, index): SearchResultItem => ({
          value: {
            name: result.value.name,
            reference: result.value.reference,
            other: result.value.other,
            url: result.value.url,
            status: result.value.status,
          },
          isNew: true,
          key: result.key,
          displayType: RESULT_DISPLAY_TYPE.RESULT,
          resultType: result.resultType,
          animationIndex: index,
        }),
      ),
    ],
    'key',
  );

  const listOfEnhancedResults = [
    generateResultTitle({ type, count: totalCount, isNew: false }),
    ...results,
  ];

  const hasMoreResultToShow = results.length < totalCount;

  if (hasMoreResultToShow) {
    listOfEnhancedResults.push(
      generateShowMoreResult({
        type,
        isNew: true,
        count: totalCount - (previousResult.length + incomingResutls.length),
      }),
    );
  }

  const incommingResults = listOfEnhancedResults.filter(({ isNew }) => isNew);

  const containerHeight = getContainerSizeForMoreResults({
    previousContainerSize: state.containerHeight,
    results: listOfEnhancedResults,
    totalCount,
    incommingResults,
  });

  return {
    ...state,
    isLoading: false,
    results: listOfEnhancedResults,
    storedResults: listOfEnhancedResults,
    containerHeight,
    listHeight: getResultListSize(listOfEnhancedResults),
    indexFocus: listOfEnhancedResults.length - 1,
  };
};

export default buildSetMoreResultsState;
