import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BaseBackResponse,
  PickupPointAddress,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/depositstore`;

interface UpdateDepositStoreArgs {
  fileId: string;
  depositStore: {
    depositStoreId: string;
    depositPickupPointAddress: PickupPointAddress;
  };
}

export const updateDepositStoreCommand = async (
  payload: UpdateDepositStoreArgs,
): Promise<BaseBackResponse> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId);

    return await apiCall<BackResponse<BaseBackResponse>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: payload.depositStore,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
