import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import { Z_INDEX_HEADER_SEARCH } from '@savgroup-front-common/constants/src/control';
import { getAnimationDuration } from '@savgroup-front-common/core/src/animations/helper';
import {
  isAnimationEntering,
  rem,
} from '@savgroup-front-common/core/src/helpers';
import { StateAnimationProps } from '@savgroup-front-common/core/src/animations/stateAnimation';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';

export const $ResultList = styled(TransitionGroup)`
  width: 100%;
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
`;

interface ResultItemProps extends StateAnimationProps {
  $position?: number;
}

export const $ResultItem = styled.li<ResultItemProps>`
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: ${({ animationState }) =>
    isAnimationEntering(animationState) ? Z_INDEX_HEADER_SEARCH : 0};
  transform: translateY(${({ $position = 0 }) => rem($position)});
  transition-duration: ${getAnimationDuration};
  ${decelerateTimingFunctionTransition};
`;
