import { useMemo } from 'react';

import { PERMISSIONS } from '@savgroup-front-common/constants';
import { useHasPermission } from '@savgroup-front-common/core/src/hooks';
import { RESULT_TYPE } from '@savgroup-front-common/types';

const useSearchPermissions = (): RESULT_TYPE[] => {
  const orderPermission = useHasPermission(PERMISSIONS.GET_ORDER);
  const sparePartRequestLinePermission = useHasPermission(
    PERMISSIONS.ACCESS_SPARE_PART_REQUEST,
  );
  const stockItemPermission = useHasPermission(
    PERMISSIONS.ACCESS_SPARE_PART_STOCK_ITEMS,
  );
  const filesPermission = useHasPermission(PERMISSIONS.SEARCH_FILE);

  return useMemo(() => {
    const returnValue = [];

    if (filesPermission) {
      returnValue.push(RESULT_TYPE.FILE);
    }
    if (orderPermission) {
      returnValue.push(RESULT_TYPE.ORDER);
    }
    if (sparePartRequestLinePermission) {
      returnValue.push(RESULT_TYPE.SPARE_PART_REQUEST);
    }
    if (stockItemPermission) {
      returnValue.push(RESULT_TYPE.STOCK_ITEM);
    }

    return returnValue;
  }, [
    filesPermission,
    orderPermission,
    sparePartRequestLinePermission,
    stockItemPermission,
  ]);
};

export default useSearchPermissions;
