import { FileHighlights } from '@savgroup-front-common/types';

import messages from './messages';

export enum HighlightNames {
  RMA_NUMBER = 'RmaNumber',
  ORDER_REFERENCE = 'OrderReference',
  TRANSPORT_TRACKING_NUMBERS = 'TransportTrackingNumbers',
  FILE_REFERENCE = 'FileReference',
  SHORT_FILE_REFERENCE = 'ShortFileReference',
  OWNER_FIRST_NAME = 'OwnerFirstName',
  OWNER_LAST_NAME = 'OwnerLastName',
  OWNER_PHONE_NUMBERS = 'OwnerPhoneNumbers',
  OWNER_PHONE_NUMBER = 'OwnerPhoneNumber',
  OWNER_MAIL = 'OwnerMail',
  OWNER_ZIP_CODE = 'OwnerZipCode',
  SERIAL_NUMBER = 'SerialNumber',
  IMEI = 'Imei',
  SELLER = 'Seller',
  BRANDS = 'Brands',
}

const getHighLightLabel = (highlightKey?: string) => {
  switch (highlightKey) {
    case HighlightNames.ORDER_REFERENCE:
      return messages.order;
    case HighlightNames.TRANSPORT_TRACKING_NUMBERS:
      return messages.transportTrackingNumbers;
    case HighlightNames.FILE_REFERENCE:
    case HighlightNames.SHORT_FILE_REFERENCE:
      return messages.fileRef;
    case HighlightNames.OWNER_FIRST_NAME:
    case HighlightNames.OWNER_LAST_NAME:
      return messages.ownerName;
    case HighlightNames.OWNER_PHONE_NUMBERS:
    case HighlightNames.OWNER_PHONE_NUMBER:
      return messages.ownerPhoneNumber;
    case HighlightNames.OWNER_MAIL:
      return messages.ownerMail;
    case HighlightNames.OWNER_ZIP_CODE:
      return messages.ownerZipCode;
    case HighlightNames.SERIAL_NUMBER:
      return messages.serialNumber;
    case HighlightNames.IMEI:
      return messages.imei;
    case HighlightNames.SELLER:
      return messages.seller;
    case HighlightNames.BRANDS:
      return messages.brand;
    case HighlightNames.RMA_NUMBER:
      return messages.rmaNumber;
    default:
      return messages.notSupported;
  }
};

export const getOtherHighlights = (highlights?: FileHighlights) => {
  if (!highlights) {
    return undefined;
  }

  const highlightFiltered = Object.entries(highlights).filter(
    ([key]) =>
      ![
        HighlightNames.OWNER_FIRST_NAME.toString(),
        HighlightNames.OWNER_LAST_NAME.toString(),
        HighlightNames.FILE_REFERENCE.toString(),
      ].includes(key),
  );

  if (
    highlightFiltered.some(
      ([key]) => key === HighlightNames.OWNER_PHONE_NUMBERS,
    )
  ) {
    return {
      label: getHighLightLabel(HighlightNames.OWNER_PHONE_NUMBERS),
      value: `<span class='hightlighted'>${highlightFiltered
        .find(([key]) => key === HighlightNames.OWNER_PHONE_NUMBERS)?.[1][0]
        .replaceAll('<em>', '')
        .replaceAll('</em>', '')}</span>`,
    };
  }

  const highlightItem =
    highlightFiltered.length > 0 ? highlightFiltered[0] : undefined;

  return highlightItem
    ? {
        label: getHighLightLabel(highlightItem[0]),
        value: `<span class='hightlighted'>${highlightItem[1][0]
          .replaceAll('<em>', '')
          .replaceAll('</em>', '')}</span>`,
      }
    : undefined;
};
