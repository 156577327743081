import React, { ReactElement } from 'react';
import { Column, PluginHook, TableState } from 'react-table';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import { Pagination } from '../../molecules/Pagination';

import TableBody from './Table.Body';
import TableFooter from './Table.Footer';
import TableHeader from './Table.Header';
import useMemoizedTable from './TableContent.hooks';
import {
  $PaginationContainer,
  $StyledTable,
  $StyledTBody,
  $StyledTFoot,
  $StyledTHead,
  $TableContainer,
} from './TableContent.styles';

interface TableContentProps<D extends Record<string, unknown>> {
  data: D[];
  columns: Column<D>[];
  plugins?: PluginHook<D>[];
  initialState?: Partial<TableState<D>>;
  dataTestId?: string;
}

const TableContent = <D extends Record<string, unknown>>({
  columns,
  data,
  plugins = [],
  initialState,
  dataTestId,
}: TableContentProps<D>): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    hasFooter,
    hasPagination,
    gotoPage,
    pageCount,
    page,
    state: { pageIndex },
  } = useMemoizedTable({ columns, data, plugins, initialState });

  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <>
      <$TableContainer>
        <$StyledTable {...getTableProps()} data-testid={dataTestId}>
          <$StyledTHead>
            <TableHeader<D>
              groups={headerGroups}
              isNewUiEnabled={isNewUiEnabled}
            />
          </$StyledTHead>
          <$StyledTBody
            {...getTableBodyProps()}
            $isNewUiEnabled={isNewUiEnabled}
          >
            <TableBody<D>
              rows={hasPagination ? page : rows}
              prepareRow={prepareRow}
              dataTestId={dataTestId}
              isNewUiEnabled={isNewUiEnabled}
            />
          </$StyledTBody>
          {hasFooter && (
            <$StyledTFoot>
              <TableFooter<D> groups={footerGroups} dataTestId={dataTestId} />
            </$StyledTFoot>
          )}
        </$StyledTable>
      </$TableContainer>
      {hasPagination && pageCount > 1 && (
        <$PaginationContainer>
          <Pagination
            current={pageIndex + 1}
            onChange={(p) => gotoPage(p - 1)}
            total={pageCount}
            centered
          />
        </$PaginationContainer>
      )}
    </>
  );
};

TableContent.displayName = 'TableContainer';

export default TableContent;
