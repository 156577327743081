import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ServiceBusConfiguration,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}adapterServiceBusConfigurations`;

export interface GetServiceBusConfigurationArgs {
  sellerId: string;
}

export default async function getServiceBusConfiguration(
  payload: GetServiceBusConfigurationArgs,
): Promise<BackResponse<ServiceBusConfiguration[]> | BackResponseFailure> {
  try {
    const { sellerId } = payload;
    const url = buildUrl(ENDPOINT, {
      sellerId,
    });

    return await apiCall<BackResponse<ServiceBusConfiguration[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
