import { all, select, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { setToLocalStorage } from '@savgroup-front-common/core/src/helpers';
import {
  FilterCategory,
  LOCAL_STORAGE_KEYS,
} from '@savgroup-front-common/types';
import { isFacetActive } from 'control/helpers';

import {
  FILES_LIST_CHANGE_FILTER,
  FILES_LIST_CHANGE_MULTPLE_FILTER,
  FILES_LIST_LOCK_FILTER,
  FILES_LIST_RESET_FILTERS,
  FILES_LIST_RESET_FILTERS_AND_LOCK,
} from './actionTypes';
import { seletFilesListFilters } from './selectors';

function* onSaveFilterWorker() {
  const filters: FilterCategory[] = (yield select(
    seletFilesListFilters,
  )) as FilterCategory[];

  const filteredFilter = filters.reduce<FilterCategory[]>((acc, filter) => {
    const values = filter.values.filter(isFacetActive).map((value) => ({
      ...value,
      count: 0,
      shouldGoTop: true,
    }));

    if (values.length === 0) {
      return acc;
    }

    return [
      ...acc,
      {
        ...filter,
        values,
      },
    ];
  }, []);

  setToLocalStorage({
    key: LOCAL_STORAGE_KEYS.FILTERS_LIST_FILES,
    value: filteredFilter,
  });
}

function* onSaveFilterWatcher() {
  yield takeEvery(
    [
      FILES_LIST_CHANGE_FILTER.BASE,
      FILES_LIST_CHANGE_MULTPLE_FILTER.BASE,
      FILES_LIST_RESET_FILTERS.BASE,
      FILES_LIST_RESET_FILTERS_AND_LOCK.BASE,
      FILES_LIST_LOCK_FILTER.BASE,
    ],
    onSaveFilterWorker,
  );
}

export default function* mainFilesSaga(): Generator {
  try {
    yield all([onSaveFilterWatcher()]);
  } catch (error) {
    logCritical(error as any);
  }
}
