import { MessageType } from './MessageType';

export enum RESULT_TYPE {
  FILE = 'file',
  ORDER = 'order',
  SPARE_PART_REQUEST = 'sparePartRequest',
  STOCK_ITEM = 'stockItem',
}

export enum SEARCH_HIGHLIGHT_TYPE {
  BOLD = 'bold',
  REGULAR = 'regular',
}

export interface SearchHighlight {
  type: SEARCH_HIGHLIGHT_TYPE;
  value: string;
}

export interface SearchOtherHighlight {
  id: string;
  label: MessageType;
  value: SearchHighlight[];
}
