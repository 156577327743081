import React, { FC } from 'react';
import { GroupBase } from 'react-select';
import SelectType from 'react-select/base';

import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import Autocomplete from '../Autocomplete';
import { AutocompleteBaseProps } from '../Autocomplete.types';

import { getOptionsWithCountryLabelMessage } from './CountryAutocomplete.helpers';
import * as customCountryAutocompleteComponent from './CustomCountryAutocompleteComponent';

interface CountryAutocompleteProps
  extends AutocompleteBaseProps<
    BaseAutocompleteOption,
    boolean,
    GroupBase<BaseAutocompleteOption>
  > {
  options?: BaseAutocompleteOption[];
  ref: React.ForwardedRef<
    SelectType<
      BaseAutocompleteOption,
      boolean,
      GroupBase<BaseAutocompleteOption>
    >
  >;
  isLabelProvided?: boolean;
}

const CountryAutocomplete: FC<CountryAutocompleteProps> = ({
  options,
  isLabelProvided = false,
  ...rest
}) => {
  const optionsWithCountryLabelMessages = getOptionsWithCountryLabelMessage({
    options,
    isLabelProvided,
  });

  return (
    <Autocomplete
      {...rest}
      options={optionsWithCountryLabelMessages}
      customComponents={customCountryAutocompleteComponent}
    />
  );
};

CountryAutocomplete.displayName = 'CountryAutocomplete';

export default CountryAutocomplete;
