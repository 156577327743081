import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

import { APIConfiguration } from '@savgroup-front-common/configuration';

import { $DisplayValue, $FlagImageValue } from '../CountryAutocomplete.styles';

const SingleValue: FunctionComponent<React.PropsWithChildren<any>> = (
  props,
) => {
  const { data, innerProps, children } = props;
  const countryCode = data?.cultureCode || data?.value || undefined;

  return (
    <components.SingleValue
      {...props}
      innerProps={{ ...innerProps, 'data-value': countryCode }}
    >
      <$DisplayValue>
        <$FlagImageValue
          src={`${APIConfiguration.catalogCDN}miscellaneous/flags/${countryCode}.svg`}
          alt={countryCode}
        />
        {children}
      </$DisplayValue>
    </components.SingleValue>
  );
};

SingleValue.displayName = 'SingleValue';

export default SingleValue;
