import { createRequest } from '@savgroup-front-common/core/src/services/request';

export enum SPARE_PART_REQUEST_LINE_LIST_ACTION_TYPE {
  GET_SPARE_PART_REQUEST_LINE_LIST = 'SPARE_PART/REQUEST_LINE_LIST/GET_LIST',
  SET_SPARE_PART_REQUEST_LINE_LIST_QUERY = 'SPARE_PART/REQUEST_LINE_LIST/SET_LIST_QUERY',
  SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING = 'SPARE_PART/REQUEST_LINE_LIST/SET_LIST_IS_DESCENDING',
  SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS = 'SPARE_PART/REQUEST_LINE_LIST/SET_LIST_FILTERS',
  UPDATE_SPARE_PART_REQUEST_LINE_LIST_ITEM_STATUS = 'SPARE_PART/REQUEST_LINE_LIST/UPDATE_LIST_ITEM_STATUS',
  RESET_SPARE_PART_REQUEST_LINE_LIST_FILTERS = 'SPARE_PART/REQUEST_LINE_LIST/RESET_LIST_FILTERS',
}

export const GET_SPARE_PART_REQUEST_LINE_LIST = createRequest(
  SPARE_PART_REQUEST_LINE_LIST_ACTION_TYPE.GET_SPARE_PART_REQUEST_LINE_LIST,
);

export const SET_SPARE_PART_REQUEST_LINE_LIST_QUERY = createRequest(
  SPARE_PART_REQUEST_LINE_LIST_ACTION_TYPE.SET_SPARE_PART_REQUEST_LINE_LIST_QUERY,
);

export const SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING = createRequest(
  SPARE_PART_REQUEST_LINE_LIST_ACTION_TYPE.SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING,
);

export const SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS = createRequest(
  SPARE_PART_REQUEST_LINE_LIST_ACTION_TYPE.SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS,
);

export const UPDATE_SPARE_PART_REQUEST_LINE_LIST_ITEM_STATUS = createRequest(
  SPARE_PART_REQUEST_LINE_LIST_ACTION_TYPE.UPDATE_SPARE_PART_REQUEST_LINE_LIST_ITEM_STATUS,
);

export const RESET_SPARE_PART_REQUEST_LINE_LIST_FILTERS = createRequest(
  SPARE_PART_REQUEST_LINE_LIST_ACTION_TYPE.RESET_SPARE_PART_REQUEST_LINE_LIST_FILTERS,
);
