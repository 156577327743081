import get from 'lodash/get';

import * as BusinessConst from '../businessConst';

export const initialState = {};

function onTransportInfoLoaded(state, { payload, meta }) {
  const fileId = get(meta, 'fileId');
  const transportInfo = get(payload, 'value');

  if (fileId) {
    return {
      ...state,
      [fileId]: { isLoaded: !!transportInfo, ...transportInfo },
    };
  }

  return state;
}

const transportReducer = (state = initialState, action) => {
  switch (action.type) {
    case BusinessConst.GET_TRANSPORT_INFO.SUCCEEDED:
      return onTransportInfoLoaded(state, action);
    default:
      return state;
  }
};

export default transportReducer;
