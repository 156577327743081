import { REPAIRER_TYPE } from '@savgroup-front-common/types';

import { SellerId } from './types';

export const formatRepairerTypeAndSellerIdIndexer = ({
  sellerId,
  repairerType,
}: {
  sellerId: SellerId;
  repairerType: REPAIRER_TYPE;
}): string => `${sellerId}_${repairerType}`;
