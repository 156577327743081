import { createAction } from '@reduxjs/toolkit';

import {
  LOAD_FAILED_ORDERS,
  LOAD_MARKETPLACE_DETAILED_ORDER,
  LOAD_MARKETPLACE_ORDERS,
  UPDATE_ORDERS_SEARCH_QUERY,
} from './actionTypes';

export const loadMarketplaceOrders = createAction(LOAD_MARKETPLACE_ORDERS.BASE);
export const loadMarketplaceDetailedOrder = createAction<string>(
  LOAD_MARKETPLACE_DETAILED_ORDER.BASE,
);
export const loadFailedOrders = createAction(LOAD_FAILED_ORDERS.BASE);

export const updateOrdersSearchQuery = createAction(
  UPDATE_ORDERS_SEARCH_QUERY.BASE,
);
