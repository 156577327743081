export const useIsCypressRunning = () => {
  try {
    const isCypressRunning = !!(
      (window as any)?.Cypress || (window?.parent as any)?.Cypress
    );

    return isCypressRunning;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_error: any) {
    return false;
  }
};
