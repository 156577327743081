import { AnyAction } from 'redux';
import { Action } from 'redux-actions';

import { UpdateSparePartRequestLineDetailStatusPayload } from './actionCreators';
import {
  GET_SPARE_PART_REQUEST_LINE_DETAILS,
  UPDATE_SPARE_PART_REQUEST_LINE_DETAILS_STATUS,
} from './actionTypes';
import { SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN } from './constants';
import {
  GetSparePartRequestLineDetailsResponse,
  SparePartRequestLineDetailsDomainState,
} from './types';

export const initialSparePartRequestLineState: SparePartRequestLineDetailsDomainState =
  {
    [SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN.REQUEST_LINE]: {},
  };

interface OnGetSparePartRequestDetailsSucceededAction extends AnyAction {
  payload: GetSparePartRequestLineDetailsResponse;
}
function onGetSparePartRequestDetails(
  state: SparePartRequestLineDetailsDomainState,
  { payload }: OnGetSparePartRequestDetailsSucceededAction,
) {
  const { value } = payload;

  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN.REQUEST_LINE]: {
      ...state[SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN.REQUEST_LINE],
      [value.requestLineId]: value,
    },
  };
}
function updateSparePartRequestDetailStatus(
  state: SparePartRequestLineDetailsDomainState,
  { payload }: Action<UpdateSparePartRequestLineDetailStatusPayload>,
) {
  const { sparePartRequestLineId, requestLineStatus } = payload;

  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN.REQUEST_LINE]: {
      ...state[SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN.REQUEST_LINE],
      [sparePartRequestLineId]: {
        ...state[SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN.REQUEST_LINE]?.[
          sparePartRequestLineId
        ],
        requestLineStatus,
      },
    },
  };
}

export default function requestLineDetailsReducer(
  state: SparePartRequestLineDetailsDomainState = initialSparePartRequestLineState,
  action: AnyAction,
): SparePartRequestLineDetailsDomainState {
  switch (action.type) {
    case GET_SPARE_PART_REQUEST_LINE_DETAILS.SUCCEEDED: {
      return onGetSparePartRequestDetails(
        state,
        action as OnGetSparePartRequestDetailsSucceededAction,
      );
    }
    case UPDATE_SPARE_PART_REQUEST_LINE_DETAILS_STATUS.BASE: {
      return updateSparePartRequestDetailStatus(
        state,
        action as Action<UpdateSparePartRequestLineDetailStatusPayload>,
      );
    }

    default: {
      return state;
    }
  }
}
