import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const TOKEN = ':token';
const ENDPOINT = `${APIConfiguration.document}files/${COMMON_QUERY_PARAMS.FILE_ID}/control-attachments/fromUploadLink/${TOKEN}`;

interface UploadFileAttachmentsFromControlCommandArgs {
  fileId: string;
  token: string;
  files: File[];
}

const uploadFileAttachmentsFromControlCommand = async ({
  fileId,
  token,
  files,
}: UploadFileAttachmentsFromControlCommandArgs): Promise<
  BackResponse<string[]> | BackResponseFailure
> => {
  try {
    const body = new FormData();

    files.forEach((file) => {
      body.append('file', file);
    });

    return await apiCall<BackResponse<string[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId).replace(
        TOKEN,
        token,
      ),
      {
        method: SUPPORTED_METHODS.POST,
        body,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default uploadFileAttachmentsFromControlCommand;
