import { useCallback, useReducer } from 'react';

import { CREATE_MODAL_TYPES } from '@savgroup-front-common/constants/src/control';
import { MenuItem } from '@savgroup-front-common/core/src/atoms/Menu';
import { MENU_ITEM_TYPES } from '@savgroup-front-common/core/src/atoms/Menu/Menu.types';

import { createModalAnimationDuration } from '../CreateButton.constants';
import {
  createModalInitialState,
  createModalStateReducer,
} from '../CreateButton.reducers';
import { MODAL_STATE_ACTION_TYPES } from '../CreateButton.types';

import useAuthorizedCreateOptions from './useAuthorizedCreateOptions';
import useAuthorizedCreateShortcuts from './useAuthorizedCreateShortcuts';
import useResetDebug from './useResetDebug';

const useCreateButton = () => {
  const [state, dispatch] = useReducer(
    createModalStateReducer,
    null,
    createModalInitialState,
  );
  const resetDebug = useResetDebug();

  const { isMenuOpen, modalType } = state;

  const onCreateModalOpen = useCallback(
    ({ key }: MenuItem) => {
      resetDebug({ key });

      dispatch({
        type: MODAL_STATE_ACTION_TYPES.OPEN_CREATE_MODAL,
        payload: { key: key as CREATE_MODAL_TYPES },
      });
    },
    [resetDebug],
  );

  const onCreateModalClose = useCallback(async () => {
    dispatch({
      type: MODAL_STATE_ACTION_TYPES.CLOSE_CREATE_MODAL,
    });

    return new Promise((resolve) =>
      setTimeout(resolve, createModalAnimationDuration),
    );
  }, []);

  const authorizedOptions = useAuthorizedCreateOptions({ onCreateModalOpen });

  useAuthorizedCreateShortcuts({
    onCreateModalOpen,
    autorizedCreateOptions: authorizedOptions,
  });

  const onToggleMenu = useCallback(() => {
    dispatch({
      type: MODAL_STATE_ACTION_TYPES.TOGGLE_MENU,
    });
  }, []);
  const handleButtonClick = useCallback(() => {
    const menuItems = authorizedOptions.filter(
      (option) => option.type === MENU_ITEM_TYPES.BUTTON,
    );

    if (menuItems.length > 1) {
      onToggleMenu();
    } else {
      onCreateModalOpen(menuItems[0]);
    }
  }, [authorizedOptions, onCreateModalOpen, onToggleMenu]);

  return {
    onToggleMenu,
    isMenuOpen,
    onCreateModalClose,
    handleButtonClick,
    authorizedOptions,
    modalType,
  };
};

export default useCreateButton;
