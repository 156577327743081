import get from 'lodash/get';
import includes from 'lodash/includes';
import pickBy from 'lodash/pickBy';
import trim from 'lodash/trim';
import flow from 'lodash/flow';
import flatMapNodes from 'unist-util-flatmap';
import mapNodes from 'unist-util-map';
import unified from 'unified';
import parse from 'rehype-parse';
import toH from 'hast-to-hyperscript';
import React from 'react';

import { isUrlAllowed } from './allowedUrls';

function decodeMessage(encodedMessage) {
  return (
    encodedMessage &&
    encodedMessage.replace(/&#(\d+);/g, (_, codePoint) =>
      String.fromCodePoint(codePoint),
    )
  );
}

function sanitize(text, whitelist = ['a', 'br', 'b']) {
  const attributeName = '[a-zA-Z_:][a-zA-Z0-9:._-]*';
  const unquoted = '[^"\'=<>`\\u0000-\\u0020]+';
  const singleQuoted = "'[^']*'";
  const doubleQuoted = '"[^"]*"';
  const attributeValue = `(?:${unquoted}|${singleQuoted}|${doubleQuoted})`;
  const attribute = `(?:\\s+${attributeName}(?:\\s*=\\s*${attributeValue})?)`;
  const isWhiteListedRegex = new RegExp(`^${whitelist.join('|')}$`);

  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(
      new RegExp(`&lt;(\\w+)${attribute}*\\s*/?&gt;`, 'g'),
      (m, tagName) =>
        isWhiteListedRegex.test(tagName)
          ? `<${m.substring(4, m.length - 4)}>`
          : m,
    )
    .replace(/&lt;\/(\w+)&gt;/g, (m, tagName) =>
      isWhiteListedRegex.test(tagName)
        ? `<${m.substring(4, m.length - 4)}>`
        : m,
    );
}

function getMatches(n, matcher, createLinkNode) {
  let cursor = 0;
  const parts = [];

  for (
    let match = matcher.exec(n.value);
    match;
    match = matcher.exec(n.value)
  ) {
    if (isUrlAllowed(match[2])) {
      if (cursor < match.index) {
        parts.push({
          type: 'text',
          value: n.value.slice(cursor, match.index),
        });
      }
      cursor = match.index + match[0].length;
      parts.push(
        createLinkNode({
          type: 'element',
          tagName: 'a',
          properties: {
            href: match[2],
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          children: [
            {
              type: 'text',
              value: match[1] || match[2],
            },
          ],
        }),
      );
    }
  }
  if (parts.length > 0) {
    if (cursor < n.value.length) {
      parts.push({
        type: 'text',
        value: n.value.slice(cursor, n.value.length),
      });
    }

    return parts;
  }

  return undefined;
}

function matchFreeFloatingLinks(n, createLinkNode) {
  const urls = '(?:mailto:|https?://|www.)\\S+';
  const matchers = [
    new RegExp(`([^"(>\\r\\n]*) < (${urls}) >`, 'g'),
    new RegExp(`([^"(>\\r\\n]*) \\( (${urls}) \\)`, 'g'),
    new RegExp(`([^"(>\\r\\n]*) = (${urls})`, 'g'),
    new RegExp(`()(${urls})`, 'g'),
  ];

  let parts = [n];

  for (const matcher of matchers) {
    parts = [].concat(
      ...parts.map(
        node =>
          (node.type === 'text'
            ? getMatches(node, matcher, createLinkNode)
            : null) || node,
      ),
    );
  }

  return parts;
}

function parseToHast(text, whiteListProps = ['target', 'href']) {
  try {
    const parser = unified()
      .use(parse, { fragment: true, verbose: true })
      .use(function CustomPlugin() {
        const filterProps = n => ({
          ...n,
          ...(n.properties
            ? {
                properties: pickBy(n.properties, (_, p) =>
                  includes(whiteListProps, p),
                ),
              }
            : null),
        });
        const slice = location => ({
          type: 'text',
          value: text.slice(location.start.offset, location.end.offset),
        });
        const createLinkNode = n => {
          const transformedNode = filterProps(n);

          return {
            ...transformedNode,
            children: [
              {
                type: 'element',
                tagName: 'i',
                properties: { className: 'icon external link alt' },
              },
              ...transformedNode.children,
            ],
          };
        };
        const processLinks = node =>
          flatMapNodes(node, n => {
            const href = get(n, 'properties.href');

            if (n.type === 'element' && n.tagName === 'a') {
              if (includes(whiteListProps, 'href') && isUrlAllowed(href)) {
                return [createLinkNode(n)];
              }
              const { opening, closing } = n.data.position;

              return [slice(opening), ...n.children, slice(closing)];
            }
            if (n.type === 'text') {
              const parts = matchFreeFloatingLinks(n, createLinkNode);

              if (parts) {
                return parts;
              }
            }

            return [filterProps(n)];
          });

        this.Compiler = node =>
          processLinks(
            mapNodes(node, n => {
              if (n.type === 'text') {
                return {
                  ...n,
                  value: n.value
                    .replace(/&amp;/g, '&')
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>'),
                };
              }

              return n;
            }),
          );
      });
    const tree = parser.processSync(text).contents;

    return toH(React.createElement, tree);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("[parseToHast] Couldn't parse message into html", e);

    return React.createElement('div', null, text);
  }
}

export const processMessage = flow(decodeMessage, trim, sanitize, parseToHast);
