import React, { FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import { $MenuIconContainer, $MenuItemTitle } from '../SidBarMenu.styles';

import { $MainExternalLink } from './MenuExternalLink.styles';

interface MenuExternalLinkProps {
  message: MessageType;
  icon: FunctionComponent<
    React.PropsWithChildren<{
      color: string;
      size: string;
    }>
  >;
}

const MenuExternalLink: FunctionComponent<
  React.PropsWithChildren<MenuExternalLinkProps>
> = ({ message, icon: Icon }) => {
  return (
    <$MainExternalLink>
      <$MenuIconContainer>
        <Icon color="#ccc" size="24px" />
      </$MenuIconContainer>
      <$MenuItemTitle>
        <SafeFormattedMessageWithoutSpread message={message} />
      </$MenuItemTitle>
    </$MainExternalLink>
  );
};

MenuExternalLink.displayName = 'MenuExternalLink';
export default MenuExternalLink;
