import React, { FunctionComponent } from 'react';

import { EVENT_CODE } from '@savgroup-front-common/constants';

import { ShortcutSymbolIcon } from '../../atoms/ShortcutSymbolIcon';
import { getShortcutLabelByEventCode } from '../../formatters';

import { $KeyShortcut } from './ShortcutKeyboard.styles';
import { useKeyboardLayout } from '../../protons/KeyboardLayoutProvider/KeyboardLayoutProvider';

interface ShortcutKeyboardProps {
  height?: number;
  code: EVENT_CODE;
}

const ShortcutKeyboard: FunctionComponent<ShortcutKeyboardProps> = ({
  height = 25,
  code,
}) => {
  const { keyboardLayout } = useKeyboardLayout();
  const label = getShortcutLabelByEventCode(code, keyboardLayout);

  return (
    <$KeyShortcut $height={height}>
      <ShortcutSymbolIcon type={code} />
      {label && (
        <span>{getShortcutLabelByEventCode(code, keyboardLayout)}</span>
      )}
    </$KeyShortcut>
  );
};

ShortcutKeyboard.displayName = 'ShortcutKeyboard';

export default ShortcutKeyboard;
