import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  WorkflowSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/upgradeWorkflowVersion`;

interface SetNewWorkflowVersionPayload {
  fileId: string;
  workflowVersion: WorkflowSummary;
}

async function setNewWorkflowVersion({
  fileId,
  workflowVersion,
}: SetNewWorkflowVersionPayload): Promise<
  BaseBackResponse | BackResponseFailure
> {
  if (!fileId) {
    const exception = new RequiredPayloadFieldIsNotProvided(
      'fileId',
      'setNewWorkflowVersion',
    );

    return prepareResponseFailure(exception);
  }
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        workflowVersion,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default setNewWorkflowVersion;
