const mapAuthorityByClientId: Record<string, string> = {
  'app.revers.io': 'https://login.revers.io',
  'control.revers.io': 'https://login.revers.io',
  'app-carrefour.revers.io': 'https://login-revers-io.carrefour.fr',
  'app-auchan.revers.io': 'https://login.revers.io',
  'app-maisonsdumonde.revers.io': 'https://login.revers.io',
};

function setAuthority(authority: string | undefined = undefined) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clientId } = this;
  const defaultAuthority = mapAuthorityByClientId[clientId];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.authority = authority || defaultAuthority;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

const mapClientIdByHostname: Record<string, string> = {
  'app.revers.io': 'app.revers.io',
  'app2.revers.io': 'app.revers.io',
};

const AuthConfiguration = {
  authority: 'https://login.revers.io',
  setAuthority,
  clientId: 'app.revers.io',
  scope: 'openid email api',
};

const { hostname } = window.location;

if (hostname !== 'localhost') {
  AuthConfiguration.clientId =
    mapClientIdByHostname[hostname] || window.location.hostname;
}

AuthConfiguration.setAuthority();

export default AuthConfiguration;
