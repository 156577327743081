import { HOUSING_TYPES } from '@savgroup-front-common/constants';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import messages from '../messages';

export const useHabitationOptions = () => {
  const habitationOptions = [
    {
      value: HOUSING_TYPES.HOUSE,
      label: safeFormattedIntlString(messages.housingTypeHouse),
      data: HOUSING_TYPES.HOUSE,
    },
    {
      value: HOUSING_TYPES.APARTMENT,
      label: safeFormattedIntlString(messages.housingTypeFlat),
      data: HOUSING_TYPES.APARTMENT,
    },
    {
      value: HOUSING_TYPES.OTHER,
      label: safeFormattedIntlString(messages.housingTypeOther),
      data: HOUSING_TYPES.OTHER,
    },
  ];

  return { habitationOptions };
};
