import { useCallback, useEffect, useRef } from 'react';

import { getNextScrollPosition, scrollTo } from '../helpers';
import { SearchResult } from '../Search.types';

interface UseSearchSrollArgs {
  indexFocus?: number;
  results: SearchResult[];
}

const useSearchScroll = ({ indexFocus, results }: UseSearchSrollArgs) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((position: number) => {
    scrollTo(containerRef, position);
  }, []);

  useEffect(() => {
    if (results.length > 0) {
      const nextScrollPosition = getNextScrollPosition({
        scrollTop: containerRef.current?.scrollTop || 0,
        containerHeight: containerRef.current?.clientHeight || 0,
        results,
        indexFocus,
      });

      if (typeof nextScrollPosition === 'number') {
        handleScroll(nextScrollPosition);
      }
    }
  }, [handleScroll, indexFocus, results]);

  return { containerRef };
};

export default useSearchScroll;
