import { createAction } from '@reduxjs/toolkit';

import { REPAIRER_TYPE } from '@savgroup-front-common/types';

import * as repairerActionTypes from './actionTypes';

export interface GetRepairerByTypeAndSellerIdPayload {
  sellerId: string;
  repairerType: REPAIRER_TYPE;
}

export const getRepairersByTypeAndSellerId =
  createAction<GetRepairerByTypeAndSellerIdPayload>(
    repairerActionTypes.GET_REPAIRERS_BY_TYPE_AND_SELLER_ID.BASE,
  );
