import styled from 'styled-components';
import { Link } from '../../../../../atoms/link';

export const $ToastLink = styled(Link)`
  color: white;
  display: block;
  text-decoration: underline;
`;
export const $ToastBody = styled.p`
  color: white;
  display: block;
  font-size: 0.75rem;
`;

export const $ToastTime = styled.p`
  color: white;
  font-size: 0.5rem;
  margin: 0;
`;
