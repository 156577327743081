import { NAV } from '../../../constants/routing';

export enum TABS_CONFIGURATION_CONSOLE {
  FOLLOW_UP = 'follow-up',
  ENTITIES = 'entities',
  SETTINGS = 'settings',
  OPTIONS = 'options',
  IMPORT = 'import',
}

export const CONFIGURATION_CONSOLE_ROUTES = {
  FOLLOW_UP: `/${NAV.CONFIGURATION_CONSOLE}/:sellerId?/follow-up`,
  ENTITIES: `/${NAV.CONFIGURATION_CONSOLE}/:sellerId?/entities`,
  SETTINGS: `/${NAV.CONFIGURATION_CONSOLE}/:sellerId?/settings`,
  OPTIONS: `/${NAV.CONFIGURATION_CONSOLE}/:sellerId?/options`,
  IMPORT: `/${NAV.CONFIGURATION_CONSOLE}/:sellerId?/import`,
};

export const SETTINGS_PATH = `/${NAV.CONFIGURATION_CONSOLE}/:sellerId/${TABS_CONFIGURATION_CONSOLE.SETTINGS}/:settingsPageName/:importType/:ruleId?`;
