import styled from 'styled-components';

export const $NoPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
