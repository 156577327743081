import React, { FunctionComponent } from 'react';

import { EVENT_CODE } from '@savgroup-front-common/constants';

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  KeyboardReturnIcon,
  KeyboardSpaceIcon,
} from '../../protons/icons';

interface ShortcutSymbolIcon {
  type: EVENT_CODE;
}

const ShortcutSymbolIcon: FunctionComponent<ShortcutSymbolIcon> = ({
  type,
}) => {
  switch (type) {
    case EVENT_CODE.SPACE: {
      return <KeyboardSpaceIcon />;
    }
    case EVENT_CODE.ENTER: {
      return <KeyboardReturnIcon />;
    }
    case EVENT_CODE.ARROW_DOWN: {
      return <ChevronDownIcon />;
    }
    case EVENT_CODE.ARROW_LEFT: {
      return <ChevronLeftIcon />;
    }
    case EVENT_CODE.ARROW_UP: {
      return <ChevronUpIcon />;
    }
    case EVENT_CODE.ARROW_RIGHT: {
      return <ChevronRightIcon />;
    }

    default: {
      return null;
    }
  }
};

ShortcutSymbolIcon.displayName = 'ShortcutKeyboard';

export default ShortcutSymbolIcon;
