import { PhonePaused } from 'iconoir-react';
import React from 'react';

interface PhonePausedProps {
  color?: string;
  size?: string;
}

export const PhonePausedIcon: React.FC<PhonePausedProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return <PhonePaused height={size} width={size} color={color} />;
};

PhonePausedIcon.displayName = 'PhonePausedIcon';
