import React, { FunctionComponent } from 'react';

import {
  OrderIcon,
  SparePartIcon,
  StockIcon,
  FileIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { RESULT_TYPE } from '@savgroup-front-common/types';

interface SearchResultIconProps {
  type: RESULT_TYPE;
  color: string;
  size?: string;
}

const SearchResultIcon: FunctionComponent<
  React.PropsWithChildren<SearchResultIconProps>
> = ({ type, color, size = '20px' }) => {
  switch (type) {
    case RESULT_TYPE.FILE:
      return <FileIcon size={size} color={color} />;
    case RESULT_TYPE.ORDER:
      return <OrderIcon size={size} color={color} />;
    case RESULT_TYPE.SPARE_PART_REQUEST:
      return <SparePartIcon size={size} color={color} />;
    case RESULT_TYPE.STOCK_ITEM:
      return <StockIcon size={size} color={color} />;

    default:
      return null;
  }
};

SearchResultIcon.displayName = 'SearchResultIcon';

export default SearchResultIcon;
