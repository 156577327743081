import { defineMessages } from 'react-intl';

export default defineMessages({
  available: {
    id: 'sparePartRequest.stockItem.status.available',
    defaultMessage: 'Available in stock',
  },
  booked: {
    id: 'sparePartRequest.stockItem.status.booked',
    defaultMessage: 'Booked',
  },
  brokenUponReceipt: {
    id: 'sparePartRequest.stockItem.status.brokenUponReceipt',
    defaultMessage: 'Missing',
  },
  mismatch: {
    id: 'sparePartRequest.stockItem.status.mismatch',
    defaultMessage: 'mismatch',
  },
  mismatchWrongPart: {
    id: 'sparePartRequest.stockItem.status.mismatchWrongPart',
    defaultMessage: 'Mismatch wrong part',
  },
  installed: {
    id: 'sparePartRequest.stockItem.status.installed',
    defaultMessage: 'installed',
  },
  defective: {
    id: 'sparePartRequest.stockItem.status.defective',
    defaultMessage: 'defective',
  },
  useless: {
    id: 'sparePartRequest.stockItem.status.useless',
    defaultMessage: 'useless',
  },
  brokenDuringIntervention: {
    id: 'sparePartRequest.stockItem.status.brokenDuringIntervention',
    defaultMessage: 'broken During Intervention',
  },
  supplierLitigation: {
    id: 'sparePartRequest.stockItem.status.supplierLitigation',
    defaultMessage: 'supplierLitigation',
  },
  obselete: {
    id: 'sparePartRequest.stockItem.status.obselete',
    defaultMessage: 'obselete',
  },
  missing: {
    id: 'sparePartRequest.stockItem.status.missing',
    defaultMessage: 'missing',
  },
  inTransit: {
    id: 'sparePartRequest.stockItem.status.inTransit',
    defaultMessage: 'In transit',
  },
  commercialSolution: {
    id: 'sparePartRequest.stockItem.status.commercialSolution',
    defaultMessage: 'Commercial solution',
  },
  consumed: {
    id: 'sparePartRequest.stockItem.status.consumed',
    defaultMessage: 'Consumed',
  },
});
