import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import * as fileDetailsSelectors from '../../../domains/fileInfo/fileDetails';
import { closeModal } from '../../../domains/modals/actionCreators';

import ExplodeFileModal from './ExplodeFileModal';

const mapStateToProps = createSelector(
  [fileDetailsSelectors.currentExplodeFileState],
  (explodeFileState) => ({
    explodeFileState,
  }),
);

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => dispatch(closeModal()),
});

const Content = connect(mapStateToProps, mapDispatchToProps)(ExplodeFileModal);

export default { Content };
