import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { EditAddressFooter } from '../../components';

import useAddressInformationForm from './hooks/useAddressInformationForm';
import { AddressInformationForm } from './AddressInformationForm/AddressInformationForm';
import { SwitchHookForm } from '@savgroup-front-common/core/src/molecules/Form';
import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { AnimatedRenderer } from '@savgroup-front-common/core/src/atoms/AnimatedRenderer';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import useAddressInformationStepContext from './hooks/useAddressInformationStepContext';
import messages from './messages';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

const AddressInformationsContent: FC = () => {
  const {
    context: { couldEditAddressOnFile },
  } = useAddressInformationStepContext();
  const { formContext, handleAddressInformationsSubmit, isLoading } =
    useAddressInformationForm();

  const {
    register,
    formState: { errors },
    watch,
  } = formContext;

  const isOwnerAddressOnFileSame = watch('isOwnerAddressOnFileSame');

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleAddressInformationsSubmit}>
        <BannersConsumer />

        <AddressInformationForm />

        {couldEditAddressOnFile && (
          <>
            <FormGroup>
              <SwitchHookForm
                label={messages.isOwnerAddressOnFileSameLabel}
                dataTestId="switch-isOwnerAddressOnFileSame"
                name="isOwnerAddressOnFileSame"
                register={register}
              />
            </FormGroup>

            {!isOwnerAddressOnFileSame && (
              <AnimatedRenderer isOpen={!isOwnerAddressOnFileSame}>
                <Heading level={3}>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.ownerAddressOnFile}
                  />
                </Heading>
                <AddressInformationForm formName="ownerAddressOnFile" />
              </AnimatedRenderer>
            )}
          </>
        )}

        <EditAddressFooter
          isLoading={isLoading}
          dataTestId="editAddress-informations-submit"
          isError={Object.keys(errors).length > 0}
        />
      </form>
    </FormProvider>
  );
};

AddressInformationsContent.displayName = 'AddressInformationsContent';

export default AddressInformationsContent;
