import { createAction } from '@reduxjs/toolkit';

import { MODAL_TYPES } from '../../constants/modals';

import * as ActionTypes from './actionTypes';

export const openModal = createAction<any>(ActionTypes.OPEN_MODAL);
export const closeModal = createAction(ActionTypes.CLOSE_MODAL);

export const openExplodeFileModal = () =>
  openModal({ type: MODAL_TYPES.EXPLODE_FILE_MODAL });
