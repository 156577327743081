import upperFirst from 'lodash/upperFirst';
import { AnyAction } from 'redux';
import { Action } from 'redux-actions';

import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { getFromLocalStorage } from '@savgroup-front-common/core/src/helpers';
import {
  FileAlert,
  FilterCategory,
  LOCAL_STORAGE_KEYS,
  OnFacetChangeArgs,
  OnFacetLockArgs,
  OnMultipleFacetChangeArgs,
  PossibleFilter,
} from '@savgroup-front-common/types';
import {
  adaptRawRubricsToRubrics,
  isFacetActive,
  reOrderFacets,
  resetFacet,
  updateRubricsAndFacetFromAction,
} from 'control/helpers';

import {
  ChangeAlertsListPagePayload,
  ChangeAlertsListSearchQueryPayload,
} from './actionCreators';
import {
  ALERTS_LIST_CHANGE_FILTER,
  ALERTS_LIST_CHANGE_MULTPLE_FILTER,
  ALERTS_LIST_CHANGE_SEARCH_QUERY,
  ALERTS_LIST_LOCK_FILTER,
  ALERTS_LIST_RESET_FILTERS,
  ALERTS_LIST_RESET_FILTERS_AND_LOCK,
  ALERTS_LIST_SELECT_PAGE,
  ALERTS_SEARCH,
  REHYDRATE_ALERTS_SEARCH,
  REORDER_ALERTS_LIST_FILTERS,
  TOGGLE_ALERTS_LIST_RESULTS_ORDER,
} from './actionTypes';
import {
  ALERTS_LIST_DOMAIN,
  AlertsListState,
  RawAlertsListRubric,
} from './types';

const PAGE_SIZE = 15;
const START_PAGE = 1;

const initialState: AlertsListState = {
  [ALERTS_LIST_DOMAIN.VALUE]: [],
  [ALERTS_LIST_DOMAIN.IS_DESCENDING]: true,
  [ALERTS_LIST_DOMAIN.SORT]: SORT_TYPES.LAST_STATE_MODIFICATION,
  [ALERTS_LIST_DOMAIN.QUERY]: '',
  [ALERTS_LIST_DOMAIN.RUBRICS]: getFromLocalStorage({
    key: LOCAL_STORAGE_KEYS.FILTERS_LIST_ALERTS,
    defaultValue: [],
  }),
  [ALERTS_LIST_DOMAIN.TOTAL_COUNT]: 0,
  [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: START_PAGE,
  [ALERTS_LIST_DOMAIN.BY_PAGE]: PAGE_SIZE,
};

function onReorderRubrics(state: AlertsListState): AlertsListState {
  return {
    ...state,
    [ALERTS_LIST_DOMAIN.RUBRICS]: reOrderFacets(
      state[ALERTS_LIST_DOMAIN.RUBRICS],
    ),
  };
}

interface SearchSucceededPayload {
  value: {
    rubrics: RawAlertsListRubric[];
    hitCount: number;
    hits: FileAlert[];
  };
}

function onSearchSucceeded(
  state: AlertsListState,
  action: Action<SearchSucceededPayload>,
): AlertsListState {
  const { hits, hitCount, rubrics } = action.payload.value;

  const filtersWithState = adaptRawRubricsToRubrics(
    state[ALERTS_LIST_DOMAIN.RUBRICS],
    rubrics,
  );

  const previousPage =
    (state[ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER] - 1) *
    state[ALERTS_LIST_DOMAIN.BY_PAGE];
  const nextPage =
    state[ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER] *
    state[ALERTS_LIST_DOMAIN.BY_PAGE];

  return {
    ...state,
    [ALERTS_LIST_DOMAIN.VALUE]: [
      ...state.value.slice(0, previousPage),
      ...hits,
      ...state.value.slice(nextPage + 1, previousPage),
    ],
    [ALERTS_LIST_DOMAIN.TOTAL_COUNT]: hitCount,
    [ALERTS_LIST_DOMAIN.RUBRICS]: filtersWithState,
  };
}

function onSearchQueryChange(
  state: AlertsListState,
  action: Action<ChangeAlertsListSearchQueryPayload>,
): AlertsListState {
  return {
    ...state,
    [ALERTS_LIST_DOMAIN.QUERY]: action.payload.query,
    [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: START_PAGE,
  };
}

function onSelectPage(
  state: AlertsListState,
  action: Action<ChangeAlertsListPagePayload>,
): AlertsListState {
  return {
    ...state,
    [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: action.payload.page,
  };
}

function onFilterChange(
  state: AlertsListState,
  action: Action<OnFacetChangeArgs>,
): AlertsListState {
  return {
    ...state,
    [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: START_PAGE,
    [ALERTS_LIST_DOMAIN.RUBRICS]: updateRubricsAndFacetFromAction({
      rubrics: state[ALERTS_LIST_DOMAIN.RUBRICS],
      payload: action.payload,
    }),
  };
}

function onMultipleFilterChange(
  state: AlertsListState,
  action: Action<OnMultipleFacetChangeArgs>,
): AlertsListState {
  const reinitializedRubrics = state[
    ALERTS_LIST_DOMAIN.RUBRICS
  ].map<FilterCategory>((rubric) => {
    return {
      ...rubric,
      values: rubric.values
        .map<PossibleFilter>((filter) => {
          if (!isFacetActive(filter)) {
            return filter;
          }

          return resetFacet(filter);
        })
        .sort((a, b) => (b.resultsCount - a.resultsCount < 0 ? -1 : 1)),
    };
  });

  const rubrics = action.payload.reduce<FilterCategory[]>(
    (rubrics, payload) => {
      return updateRubricsAndFacetFromAction({
        rubrics,
        payload: {
          ...payload,
          rubricName: upperFirst(payload.rubricName),
          facetName: upperFirst(payload.facetName),
        },
      });
    },
    reinitializedRubrics,
  );

  return {
    ...state,
    [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: START_PAGE,
    [ALERTS_LIST_DOMAIN.RUBRICS]: reOrderFacets(rubrics),
  };
}

function onFilterLock(
  state: AlertsListState,
  action: Action<OnFacetLockArgs>,
): AlertsListState {
  const { rubricName, facetName, lockValue } = action.payload;

  return {
    ...state,
    [ALERTS_LIST_DOMAIN.RUBRICS]: state[
      ALERTS_LIST_DOMAIN.RUBRICS
    ].map<FilterCategory>((rubric) => {
      if (rubric.rubricName === rubricName) {
        return {
          ...rubric,
          values: rubric.values.map<PossibleFilter>((filter) => {
            if (filter.facetName !== facetName) {
              return filter;
            }

            return {
              ...filter,
              isLocked: lockValue,
            };
          }),
        };
      }

      return rubric;
    }),
  };
}

function onFiltersReset(state: AlertsListState): AlertsListState {
  return {
    ...state,
    [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: START_PAGE,
    [ALERTS_LIST_DOMAIN.RUBRICS]: state[
      ALERTS_LIST_DOMAIN.RUBRICS
    ].map<FilterCategory>((rubric) => {
      return {
        ...rubric,
        values: rubric.values
          .map<PossibleFilter>((filter) => {
            if (filter.isLocked) {
              return filter;
            }

            if (!isFacetActive(filter)) {
              return filter;
            }

            return resetFacet(filter);
          })
          .sort((a, b) => (b.resultsCount - a.resultsCount < 0 ? -1 : 1)),
      };
    }),
  };
}
function onLocksAndFiltersReset(state: AlertsListState): AlertsListState {
  return {
    ...state,
    [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: START_PAGE,
    [ALERTS_LIST_DOMAIN.RUBRICS]: state[
      ALERTS_LIST_DOMAIN.RUBRICS
    ].map<FilterCategory>((rubric) => {
      return {
        ...rubric,
        values: rubric.values
          .map<PossibleFilter>((filter) => {
            return { ...resetFacet(filter), isLocked: false };
          })
          .sort((a, b) => (b.resultsCount - a.resultsCount < 0 ? -1 : 1)),
      };
    }),
  };
}

interface RehydrateSearchSucceededPayload {
  value: {
    rubrics: RawAlertsListRubric[];
    hitCount: number;
    hits: FileAlert[];
  };
}

function onRehydrateListSucceeded(
  state: AlertsListState,
  action: Action<RehydrateSearchSucceededPayload>,
): AlertsListState {
  const { hits, hitCount, rubrics } = action.payload.value;

  const filtersWithState = adaptRawRubricsToRubrics(
    state[ALERTS_LIST_DOMAIN.RUBRICS],
    rubrics,
  );

  return {
    ...state,
    [ALERTS_LIST_DOMAIN.VALUE]: hits,
    [ALERTS_LIST_DOMAIN.TOTAL_COUNT]: hitCount,
    [ALERTS_LIST_DOMAIN.RUBRICS]: filtersWithState,
  };
}

function onToggleOrder(state: AlertsListState): AlertsListState {
  return {
    ...state,
    [ALERTS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: START_PAGE,
    [ALERTS_LIST_DOMAIN.IS_DESCENDING]:
      !state[ALERTS_LIST_DOMAIN.IS_DESCENDING],
  };
}

function alertsListReducer(
  state: AlertsListState = initialState,
  action: AnyAction,
): AlertsListState {
  switch (action.type) {
    case ALERTS_SEARCH.SUCCEEDED:
      return onSearchSucceeded(state, action as Action<SearchSucceededPayload>);

    case ALERTS_LIST_CHANGE_SEARCH_QUERY.BASE:
      return onSearchQueryChange(
        state,
        action as Action<ChangeAlertsListSearchQueryPayload>,
      );
    case ALERTS_LIST_CHANGE_MULTPLE_FILTER.BASE:
      return onMultipleFilterChange(
        state,
        action as Action<OnMultipleFacetChangeArgs>,
      );
    case ALERTS_LIST_SELECT_PAGE.BASE:
      return onSelectPage(state, action as Action<ChangeAlertsListPagePayload>);
    case TOGGLE_ALERTS_LIST_RESULTS_ORDER.BASE:
      return onToggleOrder(state);
    case ALERTS_LIST_CHANGE_FILTER.BASE:
      return onFilterChange(state, action as Action<OnFacetChangeArgs>);
    case ALERTS_LIST_RESET_FILTERS.BASE:
      return onFiltersReset(state);
    case ALERTS_LIST_RESET_FILTERS_AND_LOCK.BASE:
      return onLocksAndFiltersReset(state);
    case ALERTS_LIST_LOCK_FILTER.BASE:
      return onFilterLock(state, action as Action<OnFacetLockArgs>);
    case REHYDRATE_ALERTS_SEARCH.SUCCEEDED:
      return onRehydrateListSucceeded(
        state,
        action as Action<RehydrateSearchSucceededPayload>,
      );
    case REORDER_ALERTS_LIST_FILTERS.BASE:
      return onReorderRubrics(state);

    default:
      return state;
  }
}

export default alertsListReducer;
