import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';

import { ControlRootState } from '../../ControlRootState';

const selectWorkflowState = (state: ControlRootState) => state.workflow;

export const fileSummary = createSelector(
  [selectWorkflowState],
  (workflow) => workflow.fileSummary,
);

export const selectFileSummaryValue = createSelector(
  [
    selectWorkflowState,
    (_state: CommonAppState, { fileId }: { fileId: string }) => fileId,
  ],
  (state, fileId) => {
    return state.fileSummary?.[fileId]?.value;
  },
);

export const getFileSummaryValue = createSelector(
  [
    selectWorkflowState,
    (_state: CommonAppState, { fileId }: { fileId?: string }) => fileId,
  ],
  (state, fileId) => {
    if (!fileId) {
      return {};
    }

    return {
      isLoaded: state.fileSummary?.[fileId]?.isLoaded,
      hasErrors: state.fileSummary?.[fileId]?.hasErrors,
      isLoading: state.fileSummary?.[fileId]?.isLoading,
      value: state.fileSummary?.[fileId]?.value,
    };
  },
);

export const selectFileCommentsCount = createSelector(
  [getFileSummaryValue],
  ({ value: fileSummaryValue }): number => {
    if (fileSummaryValue && fileSummaryValue.comments) {
      return fileSummaryValue.comments.length;
    }

    return 0;
  },
);

export const selectTechnicalInterventions = createSelector(
  [getFileSummaryValue],
  ({ value: fileSummaryValue }) => {
    return fileSummaryValue?.technicalInterventions || [];
  },
);
