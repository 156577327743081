import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import {
  DownloadIcon,
  FileIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import messages from './messages';
import { $NoPreviewContainer } from './NoPreview.styles';

interface NoPreview {
  url: string;
  filename: string;
}

const NoPreview: FunctionComponent<React.PropsWithChildren<NoPreview>> = ({
  url,
  filename,
}) => {
  return (
    <$NoPreviewContainer>
      <Row center="xs">
        <Col>
          <FileIcon color="white" size="30px" />
        </Col>
      </Row>
      <Row center="xs">
        <Col>
          <SafeFormattedMessage {...messages.noPreviewAvailable} />
        </Col>
      </Row>
      <Row center="xs">
        <Col>
          <ButtonLink
            isExternal
            primary
            href={url}
            download={filename}
            icon={<DownloadIcon />}
          >
            <SafeFormattedMessage {...messages.download} />
          </ButtonLink>
        </Col>
      </Row>
    </$NoPreviewContainer>
  );
};

NoPreview.displayName = 'NoPreview';

export default NoPreview;
