import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  WorkflowSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}workflows/${COMMON_QUERY_PARAMS.WORKFLOW_NAME}/versions`;

interface GetLastWorkflowVersionPayload {
  workflowName: string;
  maxResult?: number;
}

interface GetLastWorkflowVersionResponse extends BaseBackResponse {
  value: WorkflowSummary[];
}

async function getLastWorkflowVersion({
  workflowName,
  maxResult = 10,
}: GetLastWorkflowVersionPayload): Promise<
  GetLastWorkflowVersionResponse | BackResponseFailure
> {
  if (!workflowName) {
    const exception = new RequiredPayloadFieldIsNotProvided(
      'workflowName',
      'getLastWorkflowVersion',
    );

    return prepareResponseFailure(exception);
  }
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.WORKFLOW_NAME, workflowName),
      { maxResult },
    );

    const response = await apiCall<GetLastWorkflowVersionResponse>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getLastWorkflowVersion;
