import styled from 'styled-components';

import { Link } from '../../../../atoms/link';

export const $ToastLink = styled(Link)`
  color: white;
  display: block;
  text-decoration: underline;
  font-weight: 700;
`;

export const $ToastLastUpdate = styled.p`
  color: white;
  font-size: 0.75rem;
  margin: 0;
`;
export const $ToastScheduleTime = styled.p`
  color: white;
  font-size: 0.75rem;
  margin: 0;
`;
