import { createSelector } from 'reselect';

import { ControlRootState } from '../ControlRootState';

import { CONTROL_REPAIRER_DOMAIN_KEY } from './constants';
import { REPAIRER_DOMAIN } from './types';

export const repairerSelector = (state: ControlRootState) =>
  state[CONTROL_REPAIRER_DOMAIN_KEY];

export const getLoans = createSelector(
  [
    repairerSelector,
    (_repairer: ControlRootState, { fileId }: { fileId: string }) => fileId,
  ],
  (repairer, fileId: string) => {
    return repairer[REPAIRER_DOMAIN.LOANS][fileId] || [];
  },
);
