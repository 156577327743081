import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FilterPreset,
} from '@savgroup-front-common/types';

const FILTER_PRESET_ID = ':filterPresetId';

const ENDPOINT = `${APIConfiguration.search}search/filter/${FILTER_PRESET_ID}`;

interface DeleteFiltersPresetArgs {
  filterPresetId: string;
}

async function deleteFiltersPreset({
  filterPresetId,
}: DeleteFiltersPresetArgs): Promise<
  BackResponse<FilterPreset[]> | BackResponseFailure
> {
  const url = ENDPOINT.replace(FILTER_PRESET_ID, filterPresetId);

  try {
    const response = await apiCall<BackResponse<FilterPreset[]>>(url, {
      method: SUPPORTED_METHODS.DELETE,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default deleteFiltersPreset;
