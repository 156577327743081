import camelCase from 'lodash/camelCase';
import { Action } from 'redux-actions';

import {
  FACET_TRIPLE_STATE_SWITCH_VALUES,
  OnMultipleFacetChangeArgs,
} from '@savgroup-front-common/types';

interface FacetToSend {
  name: string;
  included: boolean;
}
interface RubricToSend {
  name: string;
  facets: FacetToSend[];
}

const selectFilterFromMultiFilterChangeAction = (
  action: Action<OnMultipleFacetChangeArgs>,
): RubricToSend[] => {
  const rubrics = action.payload.reduce<RubricToSend[]>((acc, filter) => {
    const facet = {
      name: filter.facetName,
      included: filter.value === FACET_TRIPLE_STATE_SWITCH_VALUES.INCLUDE,
    };

    const currentName = camelCase(filter.rubricName);

    const previousRubricIndex = acc.findIndex(
      ({ name }) => name === currentName,
    );
    const previousRubric = acc[previousRubricIndex];

    if (previousRubric) {
      const updatedRubric = {
        ...previousRubric,
        facets: [...previousRubric.facets, facet],
      };

      return [
        ...acc.slice(0, previousRubricIndex),
        updatedRubric,
        ...acc.slice(previousRubricIndex + 1),
      ];
    }

    const newRubric = {
      name: currentName,
      facets: [facet],
    };

    return [...acc, newRubric];
  }, []);

  return rubrics;
};

export default selectFilterFromMultiFilterChangeAction;
