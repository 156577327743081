import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}sparepart/sellingPrice`;

export interface GetSparePartVATPriceArgs {
  sellerId: string;
  preTaxBuyingPrice: number;
}

interface GetSparePartVATPriceResponse extends BaseBackResponse {
  failure: false;
  value: {
    preTaxSellingPrice: number;
    vatRate: number;
    sellingPriceIncludedTax: number;
  };
}
interface GetSparePartVATPriceResponseError extends BaseBackResponse {
  failure: true;
}

export default async function getSparePartVATPrice(
  payload: GetSparePartVATPriceArgs,
): Promise<GetSparePartVATPriceResponse | GetSparePartVATPriceResponseError> {
  try {
    return await apiCall<GetSparePartVATPriceResponse>(
      buildUrl(ENDPOINT, {
        sellerId: payload.sellerId,
        preTaxBuyingPrice: payload.preTaxBuyingPrice,
      }),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (exception) {
    return { errors: (exception as any).errors, failure: true };
  }
}
