import { USER_EXPIRED, USER_FOUND, USER_LOADED } from 'redux-oidc';

import { createReducer } from '@savgroup-front-common/core/src/helpers';

const initialState = {
  isLoggedIn: false,
  failed: false,
};

function userFoundApply(state) {
  return { ...state, isLoggedIn: true, failed: false };
}

function userFailedApply(state) {
  return { ...state, isLoggedIn: false, failed: true };
}

const authReducer = createReducer(initialState, {
  [USER_FOUND]: userFoundApply,
  [USER_EXPIRED]: userFailedApply,
  [USER_LOADED]: userFoundApply,
});

export default authReducer;
