import styled from 'styled-components';

import { simpleRotateAnimation } from '../../animations/simpleAnimation';
import { linearTimingFunctionAnimation } from '../../animations/timingFunction';

export const $GearContainer = styled.div`
  height: 200px;
  width: 200px;
  position: relative;
  & > *:first-child {
    ${simpleRotateAnimation};
    animation-iteration-count: infinite;
    ${linearTimingFunctionAnimation};
    position: absolute;
    height: 200px;
    width: 200px;
    color: white;
    top: -18px;
    left: -18px;
  }
  & > *:last-child {
    ${simpleRotateAnimation};
    animation-iteration-count: infinite;
    ${linearTimingFunctionAnimation};
    animation-direction: reverse;
    bottom: -10px;
    right: -10px;
    position: absolute;
    height: 100px;
    width: 100px;
    color: white;
  }
`;
