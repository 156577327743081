import get from 'lodash/get';

import { AddressInfoDto } from '@savgroup-front-common/types';

export const formatAddress = (
  address: AddressInfoDto,
  countryList: { key: string }[],
) => ({
  civility: get(address, 'civility'),
  name1: get(address, 'label'),
  name2: '',
  countryCode: get(address, 'countryCode'),
  street1: get(address, 'address'),
  street2: get(address, 'additionalAddress'),
  zipCode: get(address, 'postalCode'),
  state: '',
  city: get(address, 'city'),
  country: get(
    countryList.find((country) => country.key === get(address, 'countryCode')),
    'text',
  ),
  phone: get(address, 'phone'),
  contactLastName: get(address, 'lastname'),
  contactFirstName: get(address, 'firstname'),
  email: '',
});
