import { AnyAction } from 'redux';

import { Document } from '@savgroup-front-common/types';

import { GET_DOCUMENTS_BY_CLAIM_ID } from './actionTypes';
import { CLAIMS_DOMAIN } from './constants';
import { ClaimDomainState } from './types';

export interface OnGetDocumentsByClaimIdAction extends AnyAction {
  payload: { value: Document[] };
  meta: { claimId: string };
}

export const initialState: ClaimDomainState = {
  [CLAIMS_DOMAIN.DOCUMENTS]: {},
};

export function onGetDocumentsByClaimIdSuccess(
  state: ClaimDomainState,
  { payload, meta }: OnGetDocumentsByClaimIdAction,
): ClaimDomainState {
  const { value } = payload;
  const { claimId } = meta;
  const oldDocuments = state?.[CLAIMS_DOMAIN.DOCUMENTS];

  return {
    ...state,
    [CLAIMS_DOMAIN.DOCUMENTS]: {
      ...oldDocuments,
      [claimId]: value,
    },
  };
}

export default function reducer(
  state: ClaimDomainState = initialState,
  action: AnyAction,
): ClaimDomainState {
  switch (action.type) {
    case GET_DOCUMENTS_BY_CLAIM_ID.SUCCEEDED:
      return onGetDocumentsByClaimIdSuccess(
        state,
        action as OnGetDocumentsByClaimIdAction,
      );
    default:
      return state;
  }
}
