import { useEffect } from 'react';

import {
  checkTargetIsInput,
  handleShortcutCallback,
  Shortcut,
} from './useShortCut';

const useMultipleShortCuts = (shortcuts: Shortcut[]): void => {
  useEffect(() => {
    function handleEscape(event: KeyboardEvent) {
      if (checkTargetIsInput({ event })) {
        return;
      }

      for (let index = 0; index < shortcuts.length; index += 1) {
        const shortcut = shortcuts[index];

        handleShortcutCallback({
          event,
          shortcut,
        });
      }
    }
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [shortcuts]);
};

export default useMultipleShortCuts;
