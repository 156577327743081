import { createAction } from '@reduxjs/toolkit';

import * as invoiceActionTypes from './actionTypes';
import { FileId } from './types';

export interface GetInvoicesByFileIdPayload {
  fileId: FileId;
}

export const getInvoicesByFileId = createAction<GetInvoicesByFileIdPayload>(
  invoiceActionTypes.GET_INVOICES_BY_FILE_ID.BASE,
);
