import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  BasePayload,
  QuotationEnrichedDto,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotation/lastCreated`;

export interface GetLastCreatedQuotationByFileIdPayload extends BasePayload {
  fileId: string;
}

export default async function getLastCreatedQuotationByFileIdQuery({
  fileId,
  signal,
}: GetLastCreatedQuotationByFileIdPayload): Promise<
  BackResponse<QuotationEnrichedDto | undefined> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<
      BackResponse<QuotationEnrichedDto | undefined>
    >(url, {
      method: SUPPORTED_METHODS.GET,
      signal,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
