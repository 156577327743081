import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';

const ENDPOINT = `${APIConfiguration.catalog}Recognize/SerialNumber`;

interface SetRecognizeSerialNumberCommandProps {
  imageBase64: string;
}

export const setRecognizeSerialNumberCommand = async ({
  imageBase64,
}: SetRecognizeSerialNumberCommandProps): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        imageBase64,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
