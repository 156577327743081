import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponse, BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}owners/anyFileCreated`;

const getIsAnyFileCreatedByOwnerQuery = async ({
  sellerId,
}: {
  sellerId: string;
}) => {
  try {
    return await apiCall<BackResponse<BaseBackResponse>>(
      buildUrl(ENDPOINT, {
        sellerId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getIsAnyFileCreatedByOwnerQuery;
