import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useRanger } from 'react-ranger';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../formatters';
import { $Container } from '../common';
import { Label } from '../common/Label/Label';

import { $SliderHandler, $SliderTrack } from './Slider.styles';

interface SliderProps {
  min?: number;
  max?: number;
  defaultValue?: number;
  stepSize?: number;
  onChange?: (values: number | number[]) => void;
  onDrag?: (values: number | number[]) => void;
  label?: string | MessageType | ReactElement;
  name?: string;
  hasRange?: boolean;
  defaultValues?: number[];
}

const Slider: FunctionComponent<React.PropsWithChildren<SliderProps>> = ({
  defaultValue = 0,
  min = 0,
  max = 100,
  stepSize = 1,
  onChange,
  onDrag,
  label,
  name,
  hasRange = false,
  defaultValues = [0, 100],
}) => {
  const [values, setValues] = useState(
    hasRange ? defaultValues : [defaultValue],
  );
  const { getTrackProps, handles } = useRanger({
    min,
    max,
    stepSize,
    values,
    onDrag: (values) => {
      if (onDrag) {
        const [firstValue] = values;

        setValues(values);
        onDrag(hasRange ? values : firstValue);
      }

      return false;
    },
    onChange: (values) => {
      if (onChange) {
        const [firstValue] = values;

        setValues(values);
        onChange(hasRange ? values : firstValue);
      }
    },
  });

  const handle = handles[0];
  const { getHandleProps } = handle;

  const handleForRange = hasRange ? handles[1] : undefined;
  const getHandlePropsForRange = handleForRange?.getHandleProps || undefined;

  return (
    <$Container>
      {label && <Label htmlFor={name}>{SafeFormattedMessage(label)}</Label>}
      <$SliderTrack {...getTrackProps()}>
        <$SliderHandler {...getHandleProps()} />
        {getHandlePropsForRange && (
          <$SliderHandler {...getHandlePropsForRange()} />
        )}
      </$SliderTrack>
    </$Container>
  );
};

Slider.displayName = 'Slider';

export default Slider;
