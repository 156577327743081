import styled from 'styled-components';

export const $SearchResultHeader = styled.div<{
  $color?: string;
  $bgColor?: string;
}>`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumBold};
  font-size: ${({ theme }) => theme.fonts.size.medium};

  em {
    display: flex;
    padding: 2px 4px 1px 4px;
    align-items: flex-start;
    gap: 10px;
    font-size: 8px;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ $color }) => $color || null};
    background-color: ${({ $bgColor }) => $bgColor || null};
  }
`;
