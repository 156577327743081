import { defineMessages } from 'react-intl';

export default defineMessages({
  exit: {
    id: 'components.videoCall.callExit',
    defaultMessage: 'Exit',
  },
  join: {
    id: 'components.videoCall.callJoin',
    defaultMessage: 'Join',
  },
  connectionFailure: {
    id: 'components.videoCall.notification.connectionFailure',
    defaultMessage:
      'There is a connection failure, trying to reconnect to the session.',
  },
  confirmationTakeSubscriberPhotoTitle: {
    id: 'components.videoCall.confirmationTakeSubscriberPhoto.title',
    defaultMessage: 'Confirm this photo',
  },
  confirmationTakeSubscriberPhotoDidactic: {
    id: 'components.videoCall.confirmationTakeSubscriberPhoto.didactic',
    defaultMessage: 'This picture will be imported directly to the file.',
  },
  takePhoto: {
    id: 'components.videoCall.takePhoto',
    defaultMessage: 'Take photo',
  },
  endCall: {
    id: 'components.videoCall.endCall',
    defaultMessage: 'End call',
  },
  quitCall: {
    id: 'components.videoCall.quitCall',
    defaultMessage: 'Quit call',
  },
  enterPiPMode: {
    id: 'components.videoCall.enterPiPMode',
    defaultMessage: 'Enter PiP mode',
  },
  exitPiPMode: {
    id: 'components.videoCall.exitPiPMode',
    defaultMessage: 'Exit PiP mode',
  },
  minimise: {
    id: 'components.videoCall.minimise',
    defaultMessage: 'Minimise',
  },
  restore: {
    id: 'components.videoCall.restore',
    defaultMessage: 'Restore',
  },
  takeSubscriberPhotoSuccess: {
    id: 'components.videoCall.takeSubscriberPhotoSuccess',
    defaultMessage: 'Photo taken successfully',
  },
  takeSubscriberPhotoError: {
    id: 'components.videoCall.takeSubscriberPhotoError',
    defaultMessage: 'Error while taking photo',
  },
  photoFileName: {
    id: 'components.videoCall.photoFileName',
    defaultMessage: 'Photo taken on video call',
  },
});
