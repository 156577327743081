import { createSelector } from 'reselect';

import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { ControlRootState } from 'control/domains/ControlRootState';

import * as types from './actionTypes';
import { SPARE_PART_CATALOG_DOMAIN } from './constants';
import { SparePartCatalogId, SupplierId } from './types';

const selectSparePartCatalogDomain = (state: ControlRootState) =>
  state.spareParts.sparePartCatalog;

const selectSparePartCatalogList = createSelector(
  [selectSparePartCatalogDomain],
  (state) => state[SPARE_PART_CATALOG_DOMAIN.LIST],
);

const selectBySupplierId = createSelector(
  [selectSparePartCatalogDomain],
  (state) => state[SPARE_PART_CATALOG_DOMAIN.BY_SUPPLIER_ID],
);

export const selectSparePartCatalogById = createSelector(
  [
    selectSparePartCatalogList,
    (
      _: unknown,
      { sparePartCatalogId }: { sparePartCatalogId: SparePartCatalogId },
    ) => sparePartCatalogId,
  ],
  (sparePartCatalogs, sparePartCatalogId: SparePartCatalogId) =>
    sparePartCatalogs[sparePartCatalogId] || null,
);

export const selectSparePartCatalogIdsBySupplierId = createSelector(
  [
    selectBySupplierId,
    (_: unknown, { supplierId }: { supplierId: SupplierId }) => supplierId,
  ],
  (sparePartCatalogIdsBySupplierId, supplierId: SupplierId) =>
    sparePartCatalogIdsBySupplierId[supplierId] || [],
);
export const selectSparePartCatalogsBySupplierId = createSelector(
  [selectSparePartCatalogList, selectSparePartCatalogIdsBySupplierId],
  (sparePartCatalogs, sparePartCatalogIds) =>
    sparePartCatalogIds
      .map((supplierId) => sparePartCatalogs[supplierId] || null)
      .filter((sparePartCatalog) => sparePartCatalog !== null),
);

export const selectGetSparePartCatalogIsLoading = (
  state: ControlRootState,
  { sparePartCatalogId }: { sparePartCatalogId: SparePartCatalogId },
): boolean =>
  isActionLoadingSelector(
    state,
    types.GET_SPARE_PART_CATALOG,
    sparePartCatalogId,
  );

export const selectGetSparePartCatalogWasLoaded = (
  state: ControlRootState,
  { sparePartCatalogId }: { sparePartCatalogId: SparePartCatalogId },
): boolean =>
  wasActionLoadedSelector(
    state,
    types.GET_SPARE_PART_CATALOG,
    sparePartCatalogId,
  );

export const selectGetSparePartCatalogBySupplierIdIsLoading = (
  state: ControlRootState,
  { supplierId, query }: { supplierId: SupplierId; query: string },
): boolean =>
  isActionLoadingSelector(
    state,
    types.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID,
    `${supplierId}.${query}`,
  );

export const selectGetSparePartCatalogBySupplierIdWasLoaded = (
  state: ControlRootState,
  { supplierId, query }: { supplierId: SupplierId; query: string },
): boolean =>
  wasActionLoadedSelector(
    state,
    types.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID,
    `${supplierId}.${query}`,
  );
export const selectGetSparePartCatalogBySupplierIdErrors = (
  state: ControlRootState,
  { supplierId, query }: { supplierId: SupplierId; query: string },
): ErrorFromBack[] =>
  getActionErrorsSelector(
    state,
    types.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID,
    `${supplierId}.${query}`,
  );
