import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SortOption,
  SparePartDto,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}parts/page`;

export interface RetrieveSparePartsArgs {
  page: number;
  pageSize: number;
  sellerId: string;
  sortOptions?: SortOption[];
}

export async function retrieveSpareParts({
  page,
  pageSize,
  sortOptions = [],
  sellerId,
}: RetrieveSparePartsArgs): Promise<
  BackResponse<SparePartDto[]> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SparePartDto[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: { sellerId, page, pageSize, sortOptions },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
