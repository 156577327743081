import { defineMessages } from 'react-intl';

export default defineMessages({
  seemore: {
    id: 'view.appStatus.seeMore',
    defaultMessage: 'See more',
  },

  completedAt: {
    id: 'view.appStatus.sheduledMaintenance.completedAt',
    defaultMessage: 'Completion time: {time}',
  },
});
