import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/actor`;

interface SetActorCommandPayload {
  fileId: string;
  actorId: string;
  actorType: ACTOR_TYPES;
}

async function setActorCommand({
  fileId,
  actorId,
  actorType,
}: SetActorCommandPayload): Promise<boolean> {
  const functionName = 'setActorCommand';

  if (!fileId || !actorType || !actorId) {
    throw new RequiredPayloadFieldIsNotProvided(
      JSON.stringify({ fileId, actorId, actorType }),
      `${functionName}`,
    );
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.POST,
    json: {
      actorId,
      actorType,
    },
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${functionName}`);
  }

  return true;
}

export default setActorCommand;
