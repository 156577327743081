import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SparePartSearchSummaryDto } from '@savgroup-front-common/core/src/api/SpareParts/searchSparePartsByNames';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}parts/searchFull`;

export interface SearchFullSparePartsQueryReturnValues {
  spareParts: SparePartSearchSummaryDto[];
  bomDocuments: {
    url: string;
    name: string;
  }[];
  categories: string[];
  sparePartSuppliers: {
    id: string;
    companyName: string;
  }[];
}

export interface SearchFullSparePartsQueryArgs {
  sellerId: string;
  manufacturerReference?: string;
  supplierReference?: string;
  sparePartSupplierIds?: string[];
  factoryCode?: string;
  fileSku?: string;
  fileId?: string;
}

export async function searchFullSparePartsQuery({
  sellerId,
  manufacturerReference,
  supplierReference,
  sparePartSupplierIds,
  factoryCode,
  fileSku,
  fileId,
}: SearchFullSparePartsQueryArgs): Promise<
  BackResponse<SearchFullSparePartsQueryReturnValues> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SearchFullSparePartsQueryReturnValues>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          sellerId,
          manufacturerReference,
          supplierReference,
          sparePartSupplierIds,
          factoryCode,
          fileSku,
          fileId,
        },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
