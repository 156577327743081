import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GetSparePartRequestLinesByFileId } from './actionCreators';
import * as types from './actionTypes';

function* getSparePartRequestLinesByFileIdWorker({
  payload,
}: {
  payload: GetSparePartRequestLinesByFileId;
}): Generator {
  const URL = `${APIConfiguration.sparePart}files/${COMMON_QUERY_PARAMS.FILE_ID}/requestLines`;

  const { fileId } = payload;

  yield call(
    callAndGetResponse,
    types.GET_SPARE_PART_REQUEST_LINES_BY_FILE_ID,
    URL.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
    { method: SUPPORTED_METHODS.GET },
    { indexer: fileId },
  );

  yield put(
    types.GET_SPARE_PART_REQUEST_LINES_BY_FILE_ID.end({
      fileId,
    }),
  );
}
function* getSparePartRequestLinesByFileIdWatcher() {
  yield takeEvery<Action<GetSparePartRequestLinesByFileId>>(
    types.GET_SPARE_PART_REQUEST_LINES_BY_FILE_ID.BASE,
    getSparePartRequestLinesByFileIdWorker,
  );
}

export default function* sparePartRequestLineSaga(): Generator {
  try {
    yield all([getSparePartRequestLinesByFileIdWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
