import { createAction } from '@reduxjs/toolkit';

import * as ActionTypes from './actionTypes';

export const loadOwnerProductFiles = createAction(
  ActionTypes.LOAD_OWNER_PRODUCT_FILES.BASE,
);
export const loadOwnerProductFilesEnd = createAction(
  ActionTypes.LOAD_OWNER_PRODUCT_FILES.END,
);

export const uploadFileAttachment = createAction(
  ActionTypes.UPLOAD_FILE_ATTACHMENT,
);
