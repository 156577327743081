import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  CURRENCIES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { ReverseMoneyNumberAmount } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/addProducts`;

export interface Product {
  modelId: string;
  orderLineReference: string;
  price?: ReverseMoneyNumberAmount;
}

export interface AddProductsPayload {
  orderId: string;
  products: Product[];
  shippingPrice?: ReverseMoneyNumberAmount;
}

async function addProductsToOrder({
  products,
  shippingPrice = {
    amount: 0,
    currency: CURRENCIES.EUR,
  },
  orderId,
}: AddProductsPayload): Promise<void> {
  const functionName = 'addProductsToOrder';

  if (products.length === 0) {
    throw new RequiredPayloadFieldIsNotProvided('products', `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId);
  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.POST,
    json: { shippingPrice, products },
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${functionName}`);
  }
}

export default addProductsToOrder;
