import { createAction } from '@reduxjs/toolkit';

import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const types = {
  PUSH_CF_DEBUG: createRequest('DEBUG/PUSH_CF_DEBUG'),
  RESET_CF_DEBUG: createRequest('DEBUG/RESET_CF_DEBUG'),
};

export const pushCfDebug = createAction<{
  payload: { entry?: Record<string, unknown> };
}>(types.PUSH_CF_DEBUG.BASE);
export const resetCfDebug = createAction<{ origin: string }>(
  types.RESET_CF_DEBUG.BASE,
);
