import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, FileId } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/trackingReferences`;

interface AddExternalCarrierTrackingReferenceCommandPayload {
  moduleDefinitionId: string;
  moduleInstanceId: string;
  carrier: string;
  unknownCarrierName: string;
  trackingNumber: string;
  trackingUrl: string;
  handlingDirection: string;
  fileId: FileId;
}

async function addExternalCarrierTrackingReferenceCommand({
  fileId,
  moduleDefinitionId,
  moduleInstanceId,
  carrier,
  unknownCarrierName,
  trackingNumber,
  trackingUrl,
  handlingDirection,
}: AddExternalCarrierTrackingReferenceCommandPayload): Promise<BaseBackResponse> {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          moduleDefinitionId,
          moduleInstanceId,
          carrier,
          unknownCarrierName,
          trackingNumber,
          trackingUrl,
          handlingDirection,
        },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default addExternalCarrierTrackingReferenceCommand;
