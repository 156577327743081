import { File, SearchHighlight } from '@savgroup-front-common/types';

import { HighlightNames } from './adaptFileToOtherHighlights';
import adaptHighlights from './adaptHighlights';
import adaptToRegularHighlight from './adaptToRegularHighlight';

const getHighlightFromProperty = (
  file: File,
  property: HighlightNames.OWNER_FIRST_NAME | HighlightNames.OWNER_LAST_NAME,
) => {
  const highlightProperty = file?.highlights?.[property] || [];

  if (highlightProperty.length > 0) {
    return adaptHighlights(highlightProperty);
  }

  if (property === HighlightNames.OWNER_FIRST_NAME) {
    return [adaptToRegularHighlight(file.ownerFirstName)];
  }

  if (property === HighlightNames.OWNER_LAST_NAME) {
    return [adaptToRegularHighlight(file.ownerLastName)];
  }

  return [adaptToRegularHighlight(file[property])];
};

const adaptFileToOwnerNameHighLights = (file: File): SearchHighlight[] => [
  ...getHighlightFromProperty(file, HighlightNames.OWNER_FIRST_NAME),
  adaptToRegularHighlight(' '),
  ...getHighlightFromProperty(file, HighlightNames.OWNER_LAST_NAME),
];

export default adaptFileToOwnerNameHighLights;
