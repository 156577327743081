import React, { FC, useState } from 'react';
import { v4 } from 'uuid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../formatters';
import { useDisplayConfirmMessageOnWindowClose } from '../../hooks/useDisplayConfirmMessageOnWindowClose';
import { MinusIcon } from '../../protons/icons';
import CameraIcon from '../../protons/icons/Camera.icon';
import { AddIcon } from '../../protons/IconsNewDesign/Add.icon';
import { CallEndIcon } from '../../protons/IconsNewDesign/CallEnd.icon';
import { PhonePausedIcon } from '../../protons/IconsNewDesign/PhonePaused';
import { ButtonGroup } from '../button';
import Button from '../button/Button';

import messages from './messages';
import { useVideoCall } from './VideoCall.hooks';
import {
  $Footer,
  $Publisher,
  $Subscriber,
  $VideoContainer,
} from './VideoCall.styles';
import { MainScreen } from './VideoCall.types';

interface VideoCallProps {
  applicationId: string;
  sessionId: string;
  token: string;
  publisherPreference?: {
    initials?: string;
    name?: string;
    publishAudio?: boolean;
    publishVideo?: boolean;
  };
  subscriberPreference?: {
    initials?: string;
    name?: string;
    publishAudio?: boolean;
    publishVideo?: boolean;
  };
  main: MainScreen;
  isMinimised?: boolean;
  onClose?: () => void;
  onSessionEnd?: () => void;
  onMinimiseClick?: () => void;
  onRestoreClick?: () => void;
  isLoading?: boolean;
  onTakeSubscriberPhoto?: (
    file: File,
  ) => Promise<BackResponse<any> | BackResponseFailure>;
  customCloseIcon?: React.ReactElement;
  onEndCallClick?: () => void;
}

export const VideoCall: FC<VideoCallProps> = React.memo(
  ({
    applicationId,
    token,
    sessionId,
    subscriberPreference = {},
    publisherPreference = {},
    main = MainScreen.SUBSCRIBER,
    onClose,
    onSessionEnd,
    isMinimised = false,
    onMinimiseClick,
    onRestoreClick,
    isLoading = false,
    onTakeSubscriberPhoto,
    customCloseIcon,
    onEndCallClick,
  }) => {
    const [uuid] = useState(v4());

    const { handleTakeSubscriberPhoto, isTakeSubscriberPhotoLoading } =
      useVideoCall({
        applicationId,
        token,
        sessionId,
        subscriberPreference,
        publisherPreference,
        main,
        uuid,
        onTakeSubscriberPhoto,
        onSessionEnd,
      });

    useDisplayConfirmMessageOnWindowClose();

    const hasTakeSubscriberPhoto = !!onTakeSubscriberPhoto;
    const hasMinimisedAndMinimiseClick = isMinimised && !!onMinimiseClick;
    const hasNotMinimisedAndRestoreClick = !isMinimised && !!onRestoreClick;
    const hasClose = !!onClose;
    const hasSessionEnd = !!onEndCallClick;

    const hasOnlyOnlyClose =
      hasClose &&
      !hasSessionEnd &&
      !hasNotMinimisedAndRestoreClick &&
      !hasMinimisedAndMinimiseClick &&
      !hasTakeSubscriberPhoto;

    return (
      <$VideoContainer id="videos">
        <$Subscriber
          id={`subscriber_${uuid}`}
          $isMain={main === MainScreen.SUBSCRIBER}
          $isMinimised={isMinimised}
        />
        <$Publisher
          id={`publisher_${uuid}`}
          $isMain={main === MainScreen.PUBLISHER}
          $isMinimised={isMinimised}
        />

        <$Footer>
          {hasOnlyOnlyClose && (
            <Button
              icon={customCloseIcon ?? <PhonePausedIcon />}
              type={BUTTON_TYPES.BUTTON}
              onClick={onClose}
              danger
              title={safeFormattedIntlString(messages.quitCall)}
            />
          )}

          {!hasOnlyOnlyClose && (
            <ButtonGroup>
              {hasTakeSubscriberPhoto && (
                <Button
                  type={BUTTON_TYPES.BUTTON}
                  secondary
                  icon={<CameraIcon />}
                  onClick={() => handleTakeSubscriberPhoto()}
                  title={safeFormattedIntlString(messages.takePhoto)}
                  isLoading={isTakeSubscriberPhotoLoading}
                  small
                />
              )}

              {hasMinimisedAndMinimiseClick && (
                <Button
                  type={BUTTON_TYPES.BUTTON}
                  secondary
                  onClick={onRestoreClick}
                  icon={<AddIcon />}
                  small
                  title={safeFormattedIntlString(messages.restore)}
                />
              )}

              {hasNotMinimisedAndRestoreClick && (
                <Button
                  type={BUTTON_TYPES.BUTTON}
                  secondary
                  onClick={onMinimiseClick}
                  icon={<MinusIcon />}
                  small
                  title={safeFormattedIntlString(messages.minimise)}
                />
              )}

              {hasClose && (
                <Button
                  icon={customCloseIcon ?? <PhonePausedIcon />}
                  type={BUTTON_TYPES.BUTTON}
                  onClick={onClose}
                  secondary
                  small
                  title={safeFormattedIntlString(messages.quitCall)}
                />
              )}
              {hasSessionEnd && (
                <Button
                  icon={<CallEndIcon />}
                  type={BUTTON_TYPES.BUTTON}
                  danger
                  onClick={onEndCallClick}
                  isLoading={isLoading}
                  small
                  title={safeFormattedIntlString(messages.endCall)}
                />
              )}
            </ButtonGroup>
          )}
        </$Footer>
      </$VideoContainer>
    );
  },
);
