import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { FILE_ROUTES } from '../../../constants/routing';
import { buildFilesUrl } from '../../../helpers/route';

import {
  $FileItemReference,
  $FileItemStyled,
  $SpaceBetweenItems,
} from './ExplodeFileModal.styles';
import messages from './messages';

interface FileItem {
  processed?: boolean;
  id?: string;
  fileReference?: string;
  failed?: boolean;
  order: number;
  closeModal: () => void;
}

const FileItem: FunctionComponent<FileItem> = ({
  processed,
  id = '',
  fileReference,
  order,
  failed,
  closeModal,
}) => {
  if (failed) {
    return (
      <$FileItemStyled>
        <$FileItemReference>{fileReference}</$FileItemReference>
        <$SpaceBetweenItems>
          <SafeFormattedMessageWithoutSpread
            message={messages.messageErrored}
          />
        </$SpaceBetweenItems>
      </$FileItemStyled>
    );
  }

  if (processed && fileReference) {
    return (
      <$FileItemStyled>
        <$FileItemReference>{fileReference}</$FileItemReference>
        <$SpaceBetweenItems>
          <SafeFormattedMessageWithoutSpread message={messages.goodToGo} />
        </$SpaceBetweenItems>
        <Link
          onClick={closeModal}
          to={buildFilesUrl({
            fileId: id,
            fileTabId: FILE_ROUTES.DEFAULT_TAB,
          })}
        >
          <SafeFormattedMessageWithoutSpread message={messages.viewFile} />
        </Link>
      </$FileItemStyled>
    );
  }
  if (fileReference) {
    return (
      <$FileItemStyled>
        <$FileItemReference>{fileReference}</$FileItemReference>
        <$SpaceBetweenItems>
          <SafeFormattedMessageWithoutSpread
            message={messages.preparingToTransition}
          />
        </$SpaceBetweenItems>
        <Loader />
      </$FileItemStyled>
    );
  }

  return (
    <$FileItemStyled>
      <$FileItemReference>
        <SafeFormattedMessageWithoutSpread
          message={messages.file}
          values={{ order }}
        />
      </$FileItemReference>
      <$SpaceBetweenItems>
        <SafeFormattedMessageWithoutSpread message={messages.initializing} />
      </$SpaceBetweenItems>
      <Loader />
    </$FileItemStyled>
  );
};

FileItem.displayName = 'FileItem';

export default FileItem;
