import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';

function* getBackOfficeLinkWorker({ payload: json }) {
  const { ownerId, redirectUrl, orderId } = json;

  if (window.location.hostname === 'localhost') {
    yield call(
      callAndGetResponse,
      ActionTypes.LOAD_BACK_OFFICE_LINK,
      `${APIConfiguration.notification}owners/${ownerId}/backOfficeLink`,
      {
        method: SUPPORTED_METHODS.POST,
        json: { ...json, forceLocalhost: true },
      },
      { orderId, redirectUrl, indexer: orderId },
    );
  } else {
    yield call(
      callAndGetResponse,
      ActionTypes.LOAD_BACK_OFFICE_LINK,
      `${APIConfiguration.notification}owners/${ownerId}/backOfficeLink`,
      { method: SUPPORTED_METHODS.POST, json },
      { orderId, redirectUrl, indexer: orderId },
    );
  }

  yield put(ActionTypes.LOAD_BACK_OFFICE_LINK.end({ indexer: orderId }));
}

function* loadBackOfficeLinkWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_BACK_OFFICE_LINK.BASE,
    getBackOfficeLinkWorker,
  );
}

export default function* mainSaga() {
  try {
    yield all([loadBackOfficeLinkWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
