import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

interface CreateDraftCommandArgs {
  name: string;
  sellerId: string;
}

const ENDPOINT = `${APIConfiguration.import}drafts`;

export default async function createDraftCommand(
  payload: CreateDraftCommandArgs,
): Promise<BackResponse<string> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
