import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';

import { $ResetPressingContainer } from './LongPressProgress.styles';
import LongPressProgressIcon from './LongPressProgressIcon';

interface LongPressProgressProps {
  duration?: number;
  isOpen: boolean;
  withBackground?: boolean;
  color?: string;
  sqSize?: number;
  strokeWidth?: number;
}

const LongPressProgress: FunctionComponent<React.PropsWithChildren<LongPressProgressProps>> = ({
  duration = 1000,
  isOpen,
  color,
  withBackground = false,
  sqSize,
  strokeWidth,
}) => {
  return (
    <Transition in={isOpen} timeout={400} unmountOnExit>
      {(animationState) => {
        return (
          <$ResetPressingContainer
            animationDelay={200}
            animationState={animationState}
            animationDuration={500}
          >
            <LongPressProgressIcon
              duration={duration}
              color={color}
              withBackground={withBackground}
              sqSize={sqSize}
              strokeWidth={strokeWidth}
            />
          </$ResetPressingContainer>
        );
      }}
    </Transition>
  );
};

LongPressProgress.displayName = 'LongPressProgress';

export default LongPressProgress;
