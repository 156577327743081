import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getAddress } from '@savgroup-front-common/core/src/helpers/address';
import { countryList } from '@savgroup-front-common/core/src/domains/i18n/selectors';

import { getCountryOption } from '../../AddressInformations.helpers';
import {
  AddressInformationsFormValues,
  AddressFormName,
} from '../../AddressInformations.types';

interface HandleSelectAddressArgs {
  countryCode?: string;
  streetNumber?: string;
  street?: string;
  city?: string;
  postalCode?: string;
}
interface UseHandleSelectAddressArgs {
  formName?: AddressFormName;
}

export const useHandleSelectAddress = ({
  formName = 'ownerAddress',
}: UseHandleSelectAddressArgs) => {
  const countryOptions = useSelector(countryList);
  const { setValue } = useFormContext<AddressInformationsFormValues>();

  return {
    handleSelectAddress: useCallback(
      ({
        countryCode,
        streetNumber,
        street,
        city,
        postalCode,
      }: HandleSelectAddressArgs = {}) => {
        setValue(`${formName}.address`, getAddress(street, streetNumber));
        setValue(
          `${formName}.country`,
          getCountryOption(countryOptions, countryCode),
        );
        setValue(`${formName}.city`, city || '');
        setValue(`${formName}.postalCode`, postalCode || '');
      },
      [countryOptions, formName, setValue],
    ),
  };
};
