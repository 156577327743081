import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  API_COMMON_ERROR,
  COMMON_QUERY_PARAMS,
} from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/resource`;

interface ImportResourceCommandPayload {
  sellerId: string;
  file: File;
}

type ImportResourceCommand = (
  payload: ImportResourceCommandPayload,
) => Promise<BackResponse<string> | BackResponseFailure>;

export const importResourceCommand: ImportResourceCommand = async ({
  sellerId,
  file,
}) => {
  const body = new FormData();

  if (file) {
    body.append('file', file);
  }

  if (file && (file.name === 'undefined' || file.name === undefined)) {
    return prepareResponseFailure({
      errors: [{ code: API_COMMON_ERROR.UNHANDLED }],
    });
  }

  try {
    const response = await apiCall<BackResponse<string>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
      {
        method: SUPPORTED_METHODS.POST,
        body,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
