import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import {
  AppComponent,
  AppScheduledMaintenance,
  LOCAL_STORAGE_KEYS,
  SCHEDULED_MAINTENANCE_STATUSES,
} from '@savgroup-front-common/types';

import { StatusPageService } from '../../../api';
import { getFromLocalStorage, setToLocalStorage } from '../../../helpers';
import getNextScheduledMaintenance from '../helpers/getNextScheduledMaintenance';
import isComponentUnderMaintenance from '../helpers/isComponentUnderMaintenance';
import processScheduleMaintenance from '../helpers/processScheduleMaintenance';

const SECONDES = 1000;
const MINUTES = 60 * SECONDES;

const LONG_SCHEDULED_MAINTENCE_INTERVAL = 30 * MINUTES;
const MEDIUM_SCHEDULED_MAINTENCE_INTERVAL = 3 * MINUTES;
const QUICK_SCHEDULED_MAINTENCE_INTERVAL = 10 * SECONDES;

interface GetRefreshTimerForMaintenance {
  isUnderMaintenance: boolean;
  nextScheduledMaintenance: AppScheduledMaintenance | undefined;
}

const getRefreshTimerForMaintence = ({
  nextScheduledMaintenance,
  isUnderMaintenance,
}: GetRefreshTimerForMaintenance) => {
  if (isUnderMaintenance) {
    return QUICK_SCHEDULED_MAINTENCE_INTERVAL;
  }
  if (!nextScheduledMaintenance) {
    return LONG_SCHEDULED_MAINTENCE_INTERVAL;
  }
  if (
    [
      SCHEDULED_MAINTENANCE_STATUSES.IN_PROGRESS,
      SCHEDULED_MAINTENANCE_STATUSES.VERIFYING,
    ].includes(nextScheduledMaintenance.status)
  ) {
    return MEDIUM_SCHEDULED_MAINTENCE_INTERVAL;
  }
  const now = moment();

  const maintenanceMomentDate = moment(nextScheduledMaintenance?.scheduledFor);

  const duration = moment.duration(maintenanceMomentDate.diff(now));
  const isScheduledMaintenanceNextHours = duration.asHours() < 1;
  const isScheduledMaintenanceNow = duration.asMinutes() <= 5;

  if (isScheduledMaintenanceNow) {
    return QUICK_SCHEDULED_MAINTENCE_INTERVAL;
  }

  if (isScheduledMaintenanceNextHours) {
    return MEDIUM_SCHEDULED_MAINTENCE_INTERVAL;
  }

  return LONG_SCHEDULED_MAINTENCE_INTERVAL;
};

interface UseAppScheduledMaintenancesArgs {
  componentName: string;
  updateAppComponents: () => Promise<void>;
  appComponents: AppComponent[];
}

const useAppScheduledMaintenances = ({
  componentName,
  updateAppComponents,
  appComponents,
}: UseAppScheduledMaintenancesArgs) => {
  const [appScheduledMaintences, setAppScheduledMaintences] = useState(() =>
    getFromLocalStorage({
      key: LOCAL_STORAGE_KEYS.APP_SCHEDULED_MAINTENANCES,
      defaultValue: [],
    }),
  );

  const getAppScheduledMaintenance = useCallback(async () => {
    const response = await StatusPageService.getAppScheduledMaintenances();

    updateAppComponents();

    if (response.failure) {
      return;
    }

    setAppScheduledMaintences((previousScheduledMaintenances) => {
      response.value.forEach((scheduledMaintenance) => {
        processScheduleMaintenance(
          previousScheduledMaintenances,
          scheduledMaintenance,
          componentName,
        );
      });

      return response.value;
    });

    setToLocalStorage({
      key: LOCAL_STORAGE_KEYS.APP_SCHEDULED_MAINTENANCES,
      value: response.value,
    });
  }, [componentName, updateAppComponents]);

  const nextScheduledMaintenance = getNextScheduledMaintenance(
    appScheduledMaintences,
    componentName,
  );

  useInterval(
    getAppScheduledMaintenance,
    getRefreshTimerForMaintence({
      isUnderMaintenance: isComponentUnderMaintenance(
        appComponents,
        componentName,
      ),
      nextScheduledMaintenance,
    }),
  );

  useEffect(() => {
    getAppScheduledMaintenance();
  }, [getAppScheduledMaintenance]);

  return { appScheduledMaintences, nextScheduledMaintenance };
};

export default useAppScheduledMaintenances;
