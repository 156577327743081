import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ContractActor,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.actor}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/actors`;

const getActorsBySellerIdQuery = async ({
  sellerId,
  includeDynamicActors,
}: {
  sellerId: string;
  includeDynamicActors: boolean;
}): Promise<BackResponse<ContractActor[]> | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    return await apiCall<BackResponse<ContractActor[]>>(
      buildUrl(url, { includeDynamicActors }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getActorsBySellerIdQuery;
