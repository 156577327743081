import { useCallback, useState } from 'react';

const useOpenProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleToggleIsOpen = useCallback(
    () => setIsOpen((value) => !value),
    [],
  );

  return { isOpen, handleToggleIsOpen, handleClose };
};

export default useOpenProfile;
