import { MessageDescriptor } from 'react-intl';

import { DropdownTreeSelectData } from '../../core/src/atoms/Form/TreeSelect/TreeSelect.types';

export enum FACET_INPUT_TYPE {
  SWITCH = 'switch',
  TRIPLE_STATE_SWITCH = 'tripleStateSwitch',
}

export enum FACET_TRIPLE_STATE_SWITCH_VALUES {
  IDDLE = 'iddle',
  EXCLUDE = 'exclude',
  INCLUDE = 'include',
}

export enum RUBRIC_TYPES {
  DEFAULT = 'default',
  DATE = 'date',
  RANGE_NUMERIC = 'rangeNumeric',
  NUMERIC = 'numeric',
  PERCENTAGE = 'percentage',
}

export type OnFacetChangeArgs =
  | {
      rubricName: string;
      facetName: string;
      facetLabel: string;
      inputType: FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH;
      value: FACET_TRIPLE_STATE_SWITCH_VALUES;
    }
  | {
      rubricName: string;
      facetName: string;
      facetLabel: string;
      inputType: FACET_INPUT_TYPE.SWITCH;
      value: boolean;
    };

export type OnMultipleFacetChangeArgs = OnFacetChangeArgs[];

export type OnFacetLockArgs = {
  rubricName: string;
  facetName: string;
  lockValue: boolean;
};

export type FacetLabelFormatter = (value: {
  filter: PossibleFilter;
  rubricName: string;
}) => string;

export type RubricLabelFormatter = (value: {
  category: FilterCategory;
}) => string;

export interface FacetBase {
  facetLabel: string;
  facetName: string;
  resultsCount: number;
  shouldGoTop: boolean;
  isLocked: boolean;
  isHidden: boolean;
}

export interface TripleStateSwitchFacetBase extends FacetBase {
  inputType: FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH;
  value: FACET_TRIPLE_STATE_SWITCH_VALUES;
}
export interface SwitchFacetBase extends FacetBase {
  inputType: FACET_INPUT_TYPE.SWITCH;
  value: boolean;
}

export type PossibleFilter = TripleStateSwitchFacetBase | SwitchFacetBase;

export interface ActiveFilterPreset extends FilterPreset {
  isActive: boolean;
}

export interface FilterPreset {
  filters: {
    name: string;
    facets: { name: string; included: boolean; label?: string }[];
  }[];
  id: string;
  name: string;
  profiles: string[];
  tenantId: string;
  creatorId?: string;
}

export interface FilterCategory {
  rubricLabel: string;
  rubricName: string;
  values: PossibleFilter[];
}

export interface FilterTree {
  id: string;
  name: string;
  children?: FilterTree[];
}

export interface FacetSAS {
  name?: string;
  label?: string;
  count?: number;
  key?: string;
  minValue?: number;
  maxValue?: number;
}

export type RubricDefaultDto = {
  name: string;
  facets: FacetSAS[];
  label: MessageDescriptor | string;
  type: RUBRIC_TYPES.DEFAULT | RUBRIC_TYPES.NUMERIC;
};
export type FiltersDefaultDto = RubricDefaultDto[];

export type RubricRangeDto = {
  name: string;
  minValue?: number;
  maxValue?: number;
  label: MessageDescriptor | string;
  type: RUBRIC_TYPES.RANGE_NUMERIC | RUBRIC_TYPES.PERCENTAGE;
  facets?: FacetSAS[];
};
export type FiltersRangeDto = RubricRangeDto[];

export type RubricDateDto = {
  name: string;
  minValue?: Date;
  maxValue?: Date;
  label: MessageDescriptor | string;
  type: RUBRIC_TYPES.DATE;
  facets?: FacetSAS[];
};
export type FiltersDateDto = RubricDateDto[];

export interface FilterSASDto {
  defaultFilters: {
    filters: FiltersDefaultDto;
    numericFilters: FiltersRangeDto;
    dateTimeFilters: FiltersDateDto;
  };
}

export interface FilterValue<T = unknown> {
  value: string;
  label: string;
  data?: T;
  children?: FilterValue<T>[];
  className?: string;
  checked?: boolean;
  expanded?: boolean;
  parentsUniqueNames?: string[];
}

export interface SelectedFilters {
  name: string;
  key: string;
  values?: FilterValue[] | null;
  treeOptions?: DropdownTreeSelectData[];
}
