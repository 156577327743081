import { defineMessages } from 'react-intl';

export default defineMessages({
  seemore: {
    id: 'view.appStatus.seeMore',
    defaultMessage: 'See more',
  },
  rescheduledTitle: {
    id: 'view.appStatus.sheduledMaintenance.rescheduledTitle',
    defaultMessage: 'The maintenance has been rescheduled',
  },
  scheduleTime: {
    id: 'view.appStatus.sheduledMaintenance.scheduleTime',
    defaultMessage: 'Time: {time} for {duration}',
  },
});
