import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const SendDiagonalIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} fill="none" color={color} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9713 2.60234C19.1156 2.80832 19.1223 3.08069 18.9885 3.29356L9.84346 17.8361C9.70694 18.0531 9.45166 18.1648 9.19957 18.1177C8.94748 18.0706 8.74975 17.8743 8.70085 17.6225L7.36226 10.7324L1.34421 7.11745C1.12438 6.98539 1.00752 6.73245 1.04946 6.47946C1.0914 6.22647 1.28363 6.02477 1.53431 5.97072L18.3276 2.34988C18.5735 2.29688 18.8271 2.39636 18.9713 2.60234ZM8.61357 10.6189L9.62653 15.8329L16.2827 5.24821L8.61357 10.6189ZM15.566 4.22405L3.34244 6.85959L7.89637 9.59509L15.566 4.22405Z"
      fill="currentColor"
    />
  </Icon>
);

SendDiagonalIcon.displayName = 'SendDiagonalIcon';

export default SendDiagonalIcon;
