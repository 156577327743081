import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileLabel,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}carriers/files/${COMMON_QUERY_PARAMS.FILE_ID}/labels`;

interface GetFileLabelsArgs {
  fileId: string;
}

async function getFileLabels({
  fileId,
}: GetFileLabelsArgs): Promise<
  BackResponse<FileLabel[]> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BackResponse<FileLabel[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFileLabels;
