import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/${COMMON_QUERY_PARAMS.ENTITY_TYPE}`;

interface DeleteDiagnosticTreeNodeConfigurationFromConsoleCommandPayload {
  sellerId: string;
  entityType: string;
  id: string;
  uniqueName: string;
}

const deleteEntityFromConsoleCommand = async ({
  sellerId,
  uniqueName,
  id,
  entityType,
}: DeleteDiagnosticTreeNodeConfigurationFromConsoleCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
        COMMON_QUERY_PARAMS.ENTITY_TYPE,
        entityType,
      ),
      {
        method: SUPPORTED_METHODS.DELETE,
        json: { uniqueName, id },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default deleteEntityFromConsoleCommand;
