import { AnyAction } from 'redux';

import { Loan } from '@savgroup-front-common/types';

import { GET_LOANS } from './actionTypes';
import { REPAIRER_DOMAIN, RepairerState } from './types';

const initialState: RepairerState = {
  loans: {},
};

interface OnLoadLoansSuccededPayload extends AnyAction {
  payload: { value: Loan[] };
  meta: { fileId: string };
}

function onLoadLoansSucceded(
  state: RepairerState,
  { payload, meta }: OnLoadLoansSuccededPayload,
) {
  const loans = payload.value;
  const { fileId } = meta;

  return {
    ...state,
    [REPAIRER_DOMAIN.LOANS]: {
      [fileId]: loans,
    },
  };
}

function repairerReducer(
  state: RepairerState = initialState,
  action: AnyAction,
): RepairerState {
  switch (action.type) {
    case GET_LOANS.SUCCEEDED:
      return onLoadLoansSucceded(state, action as OnLoadLoansSuccededPayload);

    default:
      return state;
  }
}
export default repairerReducer;
