import first from 'lodash/first';
import size from 'lodash/size';
import trim from 'lodash/trim';
import {
  all,
  call,
  debounce,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import 'regenerator-runtime';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';
import { storeActionCreators } from '@savgroup-front-common/core/src/domains/store';
import { selectGetStoreBySellerIdIsReadyToCall } from '@savgroup-front-common/core/src/domains/store/selectors';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import {
  LOAD_FAILED_ORDERS,
  LOAD_MARKETPLACE_DETAILED_ORDER,
  LOAD_MARKETPLACE_ORDERS,
} from './actionTypes';
import { ordersQuery } from './selectors';

function* loadMarketplaceOrdersWorker({ payload }) {
  const firstQuery = payload?.firstQuery;
  const searchQuery = payload?.searchQuery || ' ';
  const searchGUIDReg = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  );
  const orders = yield select(ordersQuery, searchQuery);
  const currentPage = orders && !firstQuery ? orders.pagination.page : 0;
  const hasNextPage = orders ? orders?.pagination?.hasNextPage : true;

  if (hasNextPage || currentPage === 0) {
    const page = currentPage + 1;

    if (searchGUIDReg.test(String(searchQuery))) {
      yield all([
        put(ClaimActionCreators.getOrderByClaimGroup(searchQuery)),
        take(ClaimActionTypes.GET_ORDER_BY_CLAIM_GROUP.END),
      ]);
    }
    const response = yield call(
      callAndGetResponse,
      LOAD_MARKETPLACE_ORDERS,
      buildUrl(
        `${APIConfiguration.owner}orders/search`,
        {
          term: trim(searchQuery),
          page,
          pageSize: 32,
        },
        { encode: true, arrayFormat: 'brackets' },
      ),
      { method: SUPPORTED_METHODS.GET },
      {
        searchQuery,
        loadMarketplaceOrdersWorker,
      },
    );
    const newOrders = response?.payload?.value || [];

    if (size(newOrders) === 0) {
      const sellerIds = yield select(sellerSelectors.selectSellerIds);
      const sellerId = first(sellerIds);

      const isGetStoresBySellerIdIsReadyToCall = yield select(
        selectGetStoreBySellerIdIsReadyToCall,
        { sellerId },
      );

      if (isGetStoresBySellerIdIsReadyToCall) {
        yield put(storeActionCreators.getStoresBySellerId({ sellerId }));
      }
    }
  }

  yield put(LOAD_MARKETPLACE_ORDERS.end());
}
function* loadMarketplaceOrdersWatcher() {
  yield debounce(
    500,
    LOAD_MARKETPLACE_ORDERS.BASE,
    loadMarketplaceOrdersWorker,
  );
}

function* loadMarketplaceDetailedOrderWorker({ payload: orderId }) {
  if (!orderId) {
    logCritical(new Error('Trying to load order details with a null orderId'));

    return;
  }

  yield call(
    callAndGetResponse,
    LOAD_MARKETPLACE_DETAILED_ORDER,
    `${APIConfiguration.owner}orders/${orderId}/details`,
    { method: SUPPORTED_METHODS.GET },
    {
      indexer: orderId,
      orderId,
    },
  );
  yield put(LOAD_MARKETPLACE_DETAILED_ORDER.end());
}
function* loadMarketplaceDetailedOrderWatcher() {
  yield takeEvery(
    LOAD_MARKETPLACE_DETAILED_ORDER.BASE,
    loadMarketplaceDetailedOrderWorker,
  );
}

function* loadFailedOrdersWorker({ payload: searchQuery }) {
  yield call(
    callAndGetResponse,
    LOAD_FAILED_ORDERS,
    `${APIConfiguration.owner}failedimportorders/search?term=${searchQuery}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      searchQuery,
    },
  );
  yield put(LOAD_FAILED_ORDERS.end());
}
function* loadFailedOrdersWatcher() {
  yield takeEvery(LOAD_FAILED_ORDERS.BASE, loadFailedOrdersWorker);
}

export default function* mainSaga() {
  try {
    yield all([
      loadMarketplaceOrdersWatcher(),
      loadMarketplaceDetailedOrderWatcher(),
      loadFailedOrdersWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
