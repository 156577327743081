import React from 'react';

import Icon from '../Icon';

interface CrossLightIconProps {
  color?: string;
  size?: string;
}

const CrossLightIcon: React.FC<CrossLightIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M11.2634 28.7383L20.0018 20M28.7401 11.2617L20.0001 20M20.0001 20L11.2634 11.2617M20.0018 20L28.7401 28.7383"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

CrossLightIcon.displayName = 'CrossLightIcon';

export default CrossLightIcon;
