import { createSelector } from 'reselect';

import { ControlRootState } from '../ControlRootState';

const additionalInformationState = (state: ControlRootState) =>
  state.additionalInformation;

export const getAdditionalInformationLabels = createSelector(
  [additionalInformationState],
  (state) => {
    return state;
  },
);
