import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { ChevronUpIcon } from '@savgroup-front-common/core/src/protons/icons';
import { Tooltip } from '@savgroup-front-common/core/src/atoms/Tooltip';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';

import messages from './messages';
import {
  $ScrollButtonContainer,
  $ScrollToTopButton,
} from './ScrollToTopButton.styles';

interface ScrollToTopButtonProps {
  position: number;
  animationDuration: number;
  show: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ScrollToTopButton: FunctionComponent<
  React.PropsWithChildren<ScrollToTopButtonProps>
> = ({ position, animationDuration, show, onClick }) => {
  const theme = useTheme();

  return (
    <$ScrollButtonContainer
      $position={position}
      animationDuration={animationDuration}
    >
      <Tooltip
        title={safeFormattedIntlString(messages.scrollToTop)}
        disabled={!show}
      >
        <$ScrollToTopButton
          $show={show}
          onClick={onClick}
          type={BUTTON_TYPES.BUTTON}
        >
          <ChevronUpIcon color={theme.colors.white} size="30px" />
        </$ScrollToTopButton>
      </Tooltip>
    </$ScrollButtonContainer>
  );
};

ScrollToTopButton.displayName = 'ScrollToTopButton';

export default ScrollToTopButton;
