import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SalesforceSellerConfiguration,
  SF_SERVICE,
} from '@savgroup-front-common/types';

/* eslint-disable max-len */
const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/configuration/Salesforce/${COMMON_QUERY_PARAMS.SERVICE}`;

export const getSellerConfigurationSalesforceQuery = async ({
  sellerId,
  service,
}: {
  sellerId: string;
  service: SF_SERVICE;
}): Promise<
  BackResponseFailure | BackResponse<SalesforceSellerConfiguration>
> => {
  try {
    return await apiCall<BackResponse<SalesforceSellerConfiguration>>(
      buildUrl(
        ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
          COMMON_QUERY_PARAMS.SERVICE,
          service,
        ),
      ),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
