import { defineMessages } from 'react-intl';

export default defineMessages({
  emailLabel: {
    id: 'view.card.client.email',
    defaultMessage: 'Your email',
  },
  postalCodeLabel: {
    id: 'view.createOrderFromScratch.postalCode',
    defaultMessage: 'Zipcode',
  },
  countryLabel: {
    id: 'view.component.block.client.country',
    defaultMessage: 'Country',
  },
  cityLabel: {
    id: 'view.DeliveryManagement.city',
    defaultMessage: 'City',
  },
  firstNameLabel: {
    id: 'view.card.client.firstName',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'view.card.client.lastName',
    defaultMessage: 'Last Name',
  },
  phoneLabel: {
    id: 'view.card.client.phone',
    defaultMessage: 'Phone Number',
  },
  companyNameLabel: {
    id: 'view.createOrderFromScratch.companyName',
    defaultMessage: 'Company name',
  },
  addressLabel: {
    id: 'view.card.transport.address',
    defaultMessage: 'Address',
  },
  additionalAddressLabel: {
    id: 'view.DeliveryManagement.additionalAddress',
    defaultMessage: 'Additional address',
  },
  elevator: {
    id: 'view.DeliveryManagement.elevator',
    defaultMessage: 'Elevator',
  },
  parkingSpace: {
    id: 'view.DeliveryManagement.parkingSpace',
    defaultMessage: 'Parking space',
  },
  housingType: {
    id: 'view.DeliveryManagement.housingType',
    defaultMessage: 'Housing type',
  },
  floor: {
    id: 'view.DeliveryManagement.floor',
    defaultMessage: 'Floor',
  },
  doorCode: {
    id: 'view.DeliveryManagement.doorCode',
    defaultMessage: 'Door code',
  },
  additionalInformation: {
    id: 'view.DeliveryManagement.additionalInformation',
    defaultMessage: 'Addictional information',
  },

  housingTypeHouse: {
    id: 'view.DeliveryManagement.housingTypeHouse',
    defaultMessage: 'House',
  },
  housingTypeFlat: {
    id: 'view.DeliveryManagement.housingTypeFlat',
    defaultMessage: 'Flat',
  },
  housingTypeOther: {
    id: 'view.DeliveryManagement.housingTypeOther',
    defaultMessage: 'Other',
  },

  personalInformation: {
    id: 'view.createOrderFromScratch.personalInformation',
    defaultMessage: 'Personal information',
  },
  contactDetails: {
    id: 'view.createOrderFromScratch.contactDetails',
    defaultMessage: 'Contact details',
  },
  complementaryInformations: {
    id: 'view.createOrderFromScratch.complementaryInformations',
    defaultMessage: 'Complementary Informations',
  },
  addressConfirmation: {
    id: 'view.createOrderFromScratch.addressConfirmation',
    defaultMessage: 'You should take confirm if your address is correct',
  },
  isOwnerAddressOnFileSameLabel: {
    id: 'view.addressInformations.isOwnerAddressOnFileSameLabel',
    defaultMessage: 'Is the address on file is same as client ?',
  },
  ownerAddressOnFile: {
    id: 'view.addressInformations.ownerAddressOnFile',
    defaultMessage: 'Owner address on file',
  },
});
