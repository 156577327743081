import PropTypes from 'prop-types';
import React from 'react';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import { getFromDataByKey } from '@savgroup-front-common/core/src/helpers';

import messages from './messages';
import { SpinnerStyled } from './WaitUntilLoaded.styles';

const WaitUntilLoaded = ({
  data,
  renderComponent,
  Loading,
  renderFailure,
  inverted,
}) => {
  if (!data) {
    return <Loading inverted={inverted} />;
  }

  const value = getFromDataByKey(data, 'value');
  const isLoaded = getFromDataByKey(data, 'isLoaded');
  const isLoading = getFromDataByKey(data, 'isLoading');
  const hasErrors = getFromDataByKey(data, 'hasErrors');

  if (hasErrors) {
    return renderFailure();
  }

  const hasValue = isLoading === false && value;

  const hasData =
    hasValue &&
    (!Array.isArray(value) || (Array.isArray(value) && value.length));

  if (isLoaded || hasData) {
    return renderComponent(value);
  }

  return <Loading inverted={inverted} />;
};

WaitUntilLoaded.propTypes = {
  renderFailure: PropTypes.func,
  data: PropTypes.shape({}),
  renderComponent: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
  Loading: PropTypes.func,
};

WaitUntilLoaded.defaultProps = {
  inverted: false,
  data: null,
  Loading: (inverted) => (
    <SpinnerStyled $inverted={inverted}>
      <Loader />
    </SpinnerStyled>
  ),
  renderFailure: () => (
    <div>
      <i className="icon cross" />
      <SafeFormattedMessage {...messages.loadingFailed} />
    </div>
  ),
};

export default WaitUntilLoaded;
