import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.config}debugging/sellers/${COMMON_QUERY_PARAMS.SELLER_ID}`;

export interface GetSanityTestArgs {
  sellerId: string;
  batchId: string;
}

type Html = string;

const getSanityTest = async ({
  sellerId,
  batchId,
}: GetSanityTestArgs): Promise<BackResponse<Html> | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<BackResponse<Html>>(
      buildUrl(url, { BatchId: batchId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSanityTest;
