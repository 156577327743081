 
import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { CIVILITY } from '@savgroup-front-common/constants/src/shared/civility';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}tenants/${COMMON_QUERY_PARAMS.TENANT_ID}/owners`;

interface CreateOwnerAddressInfo {
  addressId?: string;
  civility: CIVILITY;
  firstname: string;
  companyName: string;
  lastname: string;
  address: string;
  additionalAddress: string;
  additionalInformation?: string;
  doorCode?: string;
  floor?: string;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
  isDefault?: boolean;
  isValid?: boolean;
}

export interface CreateOwner {
  ownerId: string;
  externalId?: string;
  email: string;
  preferredLanguage: string;
  firstname: string;
  lastname: string;
  phone: string;
  companyName: string;
  customerType?: string;
  addressInfo: CreateOwnerAddressInfo;
}

interface CreateOwnerPayload {
  owner: CreateOwner;
  tenantId: string;
  sellerId?: string;
}

async function createOwner({
  owner,
  tenantId,
  sellerId,
}: CreateOwnerPayload): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.TENANT_ID, tenantId);

    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.POST,
      json: { ...owner, sellerId },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createOwner;
