 
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

import DEMO_CONFIG from './productFruits.config.DEMO';
import DEV_CONFIG from './productFruits.config.DEV';
import LOCAL_CONFIG from './productFruits.config.LOCAL';
import PREPROD_CONFIG from './productFruits.config.PREPROD';
import PROD_CONFIG from './productFruits.config.PROD';

export const PRODUCT_FRUITS_CONFIG = ((): { workspaceCode?: string } => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return DEMO_CONFIG;
    case APP_ENVS.DEV:
      return DEV_CONFIG;
    case APP_ENVS.LOCAL:
      return LOCAL_CONFIG;
    case APP_ENVS.PROD:
      return PROD_CONFIG;
    case APP_ENVS.PREPROD:
      return PREPROD_CONFIG;
    default:
      throw new Error(`${currentAppEnvironment} is not supported as APP_ENVS.`);
  }
})();
