import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';
import { LOAD_OWNER_INFO } from './actionTypes';

function* loadOwnerInfoWorker({ payload: ownerId }) {
  const meta = { ownerId, indexer: ownerId };

  yield call(
    callAndGetResponse,
    LOAD_OWNER_INFO,
    `${APIConfiguration.owner}owners/id/${ownerId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );
  yield put(LOAD_OWNER_INFO.end());
}
export function* loadOwnerInfoWatcher() {
  yield takeEvery(LOAD_OWNER_INFO.BASE, loadOwnerInfoWorker);
}

export function* loadOwnerProductsWorker({ payload }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_OWNER_PRODUCTS,
    `${APIConfiguration.owner}owners/${payload}/products`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
  yield put(ActionTypes.LOAD_OWNER_PRODUCTS.end());
}
function* loadOwnerProductsWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_OWNER_PRODUCTS.BASE,
    loadOwnerProductsWorker,
  );
}

function* loadSellerProductsInfoWorker({ payload }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_SELLER_PRODUCTS_INFO,
    `${APIConfiguration.config}sellers/products/list`,
    {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    },
  );
  yield put(ActionTypes.LOAD_SELLER_PRODUCTS_INFO.end());
}
function* loadSellerProductsInfoWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_SELLER_PRODUCTS_INFO.BASE,
    loadSellerProductsInfoWorker,
  );
}

export default function* ownerSaga() {
  try {
    yield all([
      loadOwnerProductsWatcher(),
      loadSellerProductsInfoWatcher(),
      loadOwnerInfoWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
