import React from 'react';

import { GlobalStyle } from '@savgroup-front-common/core/src/helpers';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';

const GlobalStyleManager = () => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return <GlobalStyle control isNewUiEnabled={isNewUiEnabled} />;
};

GlobalStyleManager.displayName = 'GlobalStyleManager';

export default GlobalStyleManager;
