import matches from 'lodash/matches';
import { all, call, put, take, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';
import * as ActionCreators from '@savgroup-front-common/core/src/domains/workflow/actionCreators';
import { getFileActions } from '@savgroup-front-common/core/src/domains/workflow/actionCreators';
import * as ActionTypes from '@savgroup-front-common/core/src/domains/workflow/actionTypes';

import { GET_FILE_FULL_SUMMARY, getFullFileSummary, types } from './actions';

function* getFileFullSummaryWorker({ payload: fileId }) {
  const meta = { fileId };

  if (!fileId) {
    yield put(GET_FILE_FULL_SUMMARY.end(meta));

    return;
  }

  yield call(
    callAndGetResponse,
    GET_FILE_FULL_SUMMARY,
    `${APIConfiguration.workflow}files/customerFile/${fileId}/summary`,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );
  yield put(GET_FILE_FULL_SUMMARY.end(meta));
}
export function* getFileFullSummaryWatcher() {
  yield takeEvery(GET_FILE_FULL_SUMMARY.BASE, getFileFullSummaryWorker);
}

function* getFileCommentsWorker({ payload: fileId }) {
  const meta = { fileId };

  yield call(
    callAndGetResponse,
    types.GET_FILE_COMMENTS,
    `${APIConfiguration.workflow}files/${fileId}/fileComments`,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );
  yield put(types.GET_FILE_COMMENTS.end(meta));
}
export function* getFileCommentsWatcher() {
  yield takeEvery(GET_FILE_FULL_SUMMARY.BASE, getFileCommentsWorker);
}

function* sendNewFileCommentWorker({ payload: { fileId, newComment } }) {
  const meta = { fileId, indexer: newComment.internalId, newComment };

  yield call(
    callAndGetResponse,
    types.SEND_NEW_FILE_COMMENTS,
    `${APIConfiguration.workflow}files/fileComment`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        fileId,
        content: newComment.content,
      },
    },
    meta,
  );

  yield put(types.SEND_NEW_FILE_COMMENTS.end(meta));
}
export function* sendNewFileCommentWatcher() {
  yield takeEvery(types.SEND_NEW_FILE_COMMENTS.BASE, sendNewFileCommentWorker);
}

function* updateFileCommentWorker({ payload: { fileId, newComment } }) {
  const meta = { fileId, indexer: newComment.internalId, newComment };

  yield call(
    callAndGetResponse,
    types.UPDATE_FILE_COMMENTS,
    `${APIConfiguration.workflow}files/fileComment`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        fileCommentId: newComment.id,
        NewContent: newComment.content,
      },
    },
    meta,
  );

  yield put(types.UPDATE_FILE_COMMENTS.end(meta));
}
export function* updateFileCommentWatcher() {
  yield takeEvery(types.UPDATE_FILE_COMMENTS.BASE, updateFileCommentWorker);
}

function* deleteFileCommentWorker({ payload: { fileId, comment } }) {
  const meta = { fileId, indexer: comment.internalId };

  yield call(
    callAndGetResponse,
    types.DELETE_FILE_COMMENTS,
    `${APIConfiguration.workflow}files/${comment.id}/fileComment`,
    { method: SUPPORTED_METHODS.DELETE },
    meta,
  );

  yield put(types.DELETE_FILE_COMMENTS.end(meta));
}
export function* deleteFileCommentWatcher() {
  yield takeEvery(types.DELETE_FILE_COMMENTS.BASE, deleteFileCommentWorker);
}

function* setUserActionAndHydrateFileSummaryWorker({
  payload: { fileId, module, fromState, toState, comment, toCustomerComment },
}) {
  yield all([
    put(
      ActionCreators.setSyncUserAction({
        fileId,
        module,
        fromState,
        toState,
        comment,
        toCustomerComment,
      }),
    ),
    take(
      matches({
        type: ActionTypes.SET_SYNC_USER_ACTION.END,
        meta: fileId,
      }),
    ),
  ]);

  yield all([
    put(getFileActions(fileId)),
    put(getFullFileSummary(fileId)),
    take(
      matches({
        type: GET_FILE_FULL_SUMMARY.END,
        meta: {
          fileId,
        },
      }),
    ),
  ]);
}

function* setUserActionAndHydrateFileSummaryWatcher() {
  yield takeEvery(
    ActionTypes.SET_USER_ACTION_AND_HYDRATE_FILE_SUMMARY.BASE,
    setUserActionAndHydrateFileSummaryWorker,
  );
}

export default function* workflowFileSummarySaga() {
  yield all([
    getFileFullSummaryWatcher(),
    getFileCommentsWatcher(),
    sendNewFileCommentWatcher(),
    updateFileCommentWatcher(),
    deleteFileCommentWatcher(),
    setUserActionAndHydrateFileSummaryWatcher(),
  ]);
}
