import { PERMISSIONS } from '@savgroup-front-common/constants';
import {
  useHasPermission,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';

const useHasTenantConnectedAppPermission = (): boolean => {
  const isSuperAdmin = useIsSuperAdmin();

  return (
    useHasPermission(PERMISSIONS.STRIPE_ACCOUNT_MANAGEMENT) && isSuperAdmin
  );
};

export default useHasTenantConnectedAppPermission;
