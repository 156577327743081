import React, {
  FunctionComponent,
  MutableRefObject,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { useScroll } from 'react-use';

import { $PageContainerStyled } from './PageContainer.styles';
import PageContainerContext from './PageContainerContext';
import ScrollToTopButton from './ScrollToTopButton';

interface PageContainerProps {
  forwardedRef?:
    | ((instance: HTMLDivElement | null) => void)
    | MutableRefObject<HTMLDivElement | null>
    | null;
  isScrollToTupEnabled?: boolean;
  componentThemeName?: string;
}

const PageContainer: FunctionComponent<
  React.PropsWithChildren<PageContainerProps>
> = ({
  children,
  forwardedRef,
  isScrollToTupEnabled = true,
  componentThemeName,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [{ position, animationDuration }, setPosition] = useState({
    position: 0,
    animationDuration: 300,
  });

  useScroll(containerRef);

  const scrollToTop = () => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  return (
    <PageContainerContext.Provider value={setPosition}>
      <$PageContainerStyled
        data-testid="pageContainer"
        ref={(ref) => {
          if (forwardedRef) {
            if (typeof forwardedRef === 'function') {
              forwardedRef(ref);
            } else {
               
              forwardedRef.current = ref;
            }
          }
          containerRef.current = ref;
        }}
        $componentThemeName={componentThemeName}
      >
        {children}
        {isScrollToTupEnabled && (
          <ScrollToTopButton
            onClick={scrollToTop}
            position={position}
            animationDuration={animationDuration}
            show={(containerRef.current?.scrollTop || 0) > 50}
          />
        )}
      </$PageContainerStyled>
    </PageContainerContext.Provider>
  );
};

PageContainer.displayName = 'PageContainer';

export default React.forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    isScrollToTupEnabled?: boolean;
    componentThemeName?: string;
  }
>((props, ref) => <PageContainer forwardedRef={ref} {...props} />);
