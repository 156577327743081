import { defineMessages } from 'react-intl';

export default defineMessages({
  noFiltersTitle: {
    id: 'view.search.noFiltersTitle',
    defaultMessage: 'Sad no filters!',
  },
  noFiltersText: {
    id: 'view.search.noFiltersText',
    defaultMessage: 'Activate some filters to see the results!',
  },
});
