import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const QUOTE_TASK_AUTO_SAVE = createRequest('QUOTE_TASK_AUTO_SAVE');
export const GET_CURRENT_DRAFTED_QUOTE = createRequest(
  'GET_CURRENT_DRAFTED_QUOTE',
);
export const GET_QUOTE_BY_ID = createRequest('GET_QUOTE_BY_ID');
export const GET_FILE_QUOTES = createRequest('GET_FILE_QUOTES');

export const EDIT_QUOTE_TASK = createRequest('EDIT_QUOTE_TASK');
export const DELETE_QUOTE_TASK = createRequest('DELETE_QUOTE_TASK');
export const DELETE_BEFORE_EDIT_QUOTE_TASK = createRequest(
  'DELETE_BEFORE_EDIT_QUOTE_TASK',
);

export const SUBMIT_QUOTE = createRequest('SUBMIT_QUOTE');

export const LOAD_FILE_QUOTES = createRequest('LOAD_FILE_QUOTES');
export const CREATE_FILE_QUOTE = createRequest('CREATE_FILE_QUOTE');

export const ADD_EMPTY_QUOTE_TASK = createRequest('ADD_EMPTY_QUOTE_TASK');

export const VALIDATE_REJECT_QUOTE = createRequest('VALIDATE_REJECT_QUOTE');
export const ANSWER_TO_QUOTE = 'ANSWER_TO_QUOTE';
export const GET_PENDING_CUSTOMER_VALIDAITON_QUOTATION = createRequest(
  'GET_PENDING_CUSTOMER_VALIDAITON_QUOTATION',
);
