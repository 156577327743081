import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useTheme } from 'styled-components';

import { HighlightsDisplayer } from '@savgroup-front-common/core/src/protons/HighlightsDisplayer/HighlightsDisplayer';
import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import {
  RESULT_TYPE,
  SearchHighlight,
  SearchOtherHighlight,
} from '@savgroup-front-common/types';

import { useSearchContext } from '../../../hooks';
import { SEARCH_HEIGHT } from '../../../Search.contants';
import SearchItemContainer from '../../common/SearchItemContainer';
import SearchResultIcon from '../../common/SearchResultIcon';

import messages from './messages';
import {
  $ResultInfo,
  $ResultInfoContainer,
  $ResultLink,
  $ResultOtherItem,
  $ResultOtherList,
  $ResultOtherTitle,
  $ResultReference,
  $ResultSelectIcon,
  $ResultShortener,
} from './SearchItemResult.styles';
import { KeyboardReturnIcon } from '@savgroup-front-common/core/src/protons/icons';

interface SearchItemResultProps {
  reference: SearchHighlight[];
  status: SearchHighlight[];
  name: SearchHighlight[];
  others: SearchOtherHighlight[];
  animationIndex: number;
  animationState: TransitionStatus;
  url: string;
  isFocus: boolean;
  isNew: boolean;
  type: RESULT_TYPE;
}

const SearchItemResult: FunctionComponent<
  React.PropsWithChildren<SearchItemResultProps>
> = ({
  reference = [],
  status = [],
  name = [],
  others = [],
  animationIndex,
  animationState,
  url,
  isFocus,
  isNew,
  type,
}) => {
  const { disableHover, onResultClick } = useSearchContext();
  const allNamesAreEmpty = name.every(({ value }) => !value);
  const theme = useTheme();

  const match = useRouteMatch(url);

  return (
    <SearchItemContainer
      animationState={animationState}
      animationIndex={animationIndex}
      isNew={isNew}
      height={SEARCH_HEIGHT.RESULT}
    >
      <$ResultLink
        href={url}
        $isActive={Boolean(match)}
        onClick={onResultClick}
        $isFocus={isFocus}
        $disableHover={disableHover}
        $animationState={animationState}
        dataTestId={`searchItemResultLink-${type}`}
      >
        <SearchResultIcon type={type} color={theme.colors.primary} />

        <$ResultInfoContainer>
          <$ResultInfo>
            <$ResultReference $withSeparation={status.length > 0}>
              <HighlightsDisplayer highlights={reference} />
            </$ResultReference>
            {status.length > 0 && (
              <$ResultShortener>
                <HighlightsDisplayer highlights={status} />
              </$ResultShortener>
            )}
          </$ResultInfo>

          <$ResultInfo>
            {!allNamesAreEmpty && (
              <span>
                <HighlightsDisplayer highlights={name} />
              </span>
            )}
            {others.length > 0 && (
              <$ResultOtherList>
                {others.map(({ id, value: other, label }) => (
                  <$ResultOtherItem key={id}>
                    <$ResultOtherTitle>
                      {SafeFormattedMessage(label)}
                    </$ResultOtherTitle>

                    <HighlightsDisplayer highlights={other} />
                  </$ResultOtherItem>
                ))}
              </$ResultOtherList>
            )}
          </$ResultInfo>
        </$ResultInfoContainer>
        <$ResultSelectIcon $isFocus={isFocus}>
          <KeyboardReturnIcon color={theme.colors.primary} />
          <SafeFormattedMessageWithoutSpread message={messages.select} />
        </$ResultSelectIcon>
      </$ResultLink>
    </SearchItemContainer>
  );
};

SearchItemResult.displayName = 'SearchItemResult';

export default SearchItemResult;
