import { defineMessages } from 'react-intl';

export default defineMessages({
  searchPlaceholder: {
    id: 'view.search.placeholder',
    defaultMessage: 'Search for files or orders',
  },
  searchNewBoPlaceholder: {
    id: 'view.search.newui.placeholder',
    defaultMessage: 'Search ...',
  },
  file: {
    id: 'view.search.filter.file',
    defaultMessage: 'Files',
  },
  order: {
    id: 'view.search.filter.order',
    defaultMessage: 'Orders',
  },
  sparePartRequest: {
    id: 'view.search.filter.sparePartRequest',
    defaultMessage: 'Spare part requests',
  },
  stockItem: {
    id: 'view.search.filter.stockItem',
    defaultMessage: 'Stock items',
  },
});
