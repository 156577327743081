import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AssignedSites,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { UserRoleOrUserPermission } from '../../../domains/userManagement';

const ENDPOINT = `${APIConfiguration.authorization}users/management`;

export interface SetUserArgs {
  email: string;
  lastname: string;
  firstname: string;
  concernedEntities?: string[];
  credentials: UserRoleOrUserPermission[];
  assignedSites?: AssignedSites;
  stocks?: { stockId: string; stockName: string }[];
}

export default async function upsertUserManagementCommand({
  email,
  lastname,
  firstname,
  concernedEntities,
  credentials,
  assignedSites,
  stocks,
}: SetUserArgs): Promise<BackResponse<string> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        email,
        lastname,
        firstname,
        concernedEntities,
        credentials,
        assignedSites,
        stocks,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
