import { createAction } from '@reduxjs/toolkit';

import {
  GET_MODEL_BY_ID,
  GET_MODEL_REPARABILITY,
  GET_MODELS_BY_SEARCH_MODEL_QUERY,
  LOAD_MODELS_INFO,
} from './actionTypes';

export const getModelsBySearchModelQuery = createAction<{
  maxResult?: number;
  sellerId?: string;
  searchTerms: string;
}>(GET_MODELS_BY_SEARCH_MODEL_QUERY.BASE);

export const getModelById = createAction(GET_MODEL_BY_ID.BASE);
export const loadModelsInfo = createAction(LOAD_MODELS_INFO.BASE);
export const getModelReparability = createAction<{
  sellerId?: string;
  modelId: string;
}>(GET_MODEL_REPARABILITY.BASE);
