import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, FileId } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}salesForce/case`;

interface CreateCaseIfNotExistCommandPayload {
  fileId: FileId;
}

async function createCaseIfNotExistCommand({
  fileId,
}: CreateCaseIfNotExistCommandPayload): Promise<BaseBackResponse> {
  try {
    return await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        fileId,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createCaseIfNotExistCommand;
