import { PERMISSIONS } from '@savgroup-front-common/constants';
import {
  useHasSomePermissions,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';

const useHasTenantAccountPermission = (): boolean => {
  const isSuperAdmin = useIsSuperAdmin();

  const accountPermission = useHasSomePermissions([
    PERMISSIONS.SELLER_CONFIGURATION,
    PERMISSIONS.SELLER_MANAGEMENT,
  ]);

  return isSuperAdmin && accountPermission;
};

export default useHasTenantAccountPermission;
