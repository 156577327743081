import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import * as ActionTypes from './actionTypes';

export const initialState = Object.freeze({
  items: [],
  model: {},
  modelInfo: {},
  bySeller: {},
});

export const onGetModelsBySearchModelQueryLoaded = (
  state,
  { payload, meta },
) => {
  const terms = get(meta, ['searchTerms'], null);
  const models = get(payload, ['value'], null);
  const sellerId = get(meta, ['sellerId'], null);

  if (!terms || !models || !sellerId) {
    return state;
  }

  const orderedModels = orderBy(models, ['name'], ['asc']);
  const modelIds = map(orderedModels, ({ id }) => id);

  return {
    ...state,
    bySeller: {
      ...state.bySeller,
      [sellerId]: uniqBy([...modelIds, ...(state.bySeller[sellerId] || [])]),
    },
    items: uniqBy([...orderedModels, ...state.items], 'id'),
  };
};

export function onModelByIdLoading(state, { meta }) {
  const { modelId } = meta;

  return {
    ...state,
    model: {
      ...state.model,
      [modelId]: {
        ...(state.model[modelId] || {
          isLoaded: false,
          value: undefined,
          isDirty: false,
        }),
        isLoaded: false,
        hasErrors: false,
      },
    },
  };
}
export function onModelByIdLoaded(state, { meta, payload }) {
  const modelId = get(meta, 'modelId');
  const model = get(payload, 'value');

  return {
    ...state,
    model: {
      ...state.model,
      [modelId]: {
        ...state.model[modelId],
        isLoaded: true,
        isDirty: true,
        value: model,
      },
    },
  };
}
export function onGetModelByIdErrored(state, { meta }) {
  const modelId = get(meta, 'modelId');

  return {
    ...state,
    models: {
      model: {
        ...state.model,
        [modelId]: {
          ...state.model[modelId],
          isDirty: true,
          hasErrors: true,
        },
      },
    },
  };
}

export function onLoadModelsInfoLoading(state) {
  return {
    ...state,
    modelInfo: { ...state.modelInfo, isLoaded: false, value: undefined },
  };
}
export function onLoadModelsInfoLoaded(state, { payload }) {
  const modelInfo = get(payload, ['value']);

  return {
    ...state,
    modelInfo: { ...state.modelInfo, isLoaded: true, value: modelInfo },
  };
}
export function onLoadModelsInfoErrored(state) {
  return {
    ...state,
    modelInfo: { ...state.modelInfo, isDirty: true, hasErrors: true },
  };
}

export function onGetModelReparabilityLoaded(state, { meta, payload }) {
  const modelId = get(meta, 'modelId');
  const isRepairable = get(payload, 'value');

  const oldModel = get(state, ['model', modelId], {});

  return {
    ...state,
    model: {
      ...state.model,
      [modelId]: {
        isLoaded: true,
        isDirty: true,
        value: {
          ...oldModel.value,
          isRepairable,
        },
      },
    },
  };
}

export default function modelsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_MODELS_BY_SEARCH_MODEL_QUERY.SUCCEEDED:
      return onGetModelsBySearchModelQueryLoaded(state, action);

    case ActionTypes.GET_MODEL_BY_ID.STARTED:
      return onModelByIdLoading(state, action);
    case ActionTypes.GET_MODEL_BY_ID.SUCCEEDED:
      return onModelByIdLoaded(state, action);
    case ActionTypes.GET_MODEL_BY_ID.ERRORED:
      return onGetModelByIdErrored(state, action);

    case ActionTypes.LOAD_MODELS_INFO.STARTED:
      return onLoadModelsInfoLoading(state, action);
    case ActionTypes.LOAD_MODELS_INFO.SUCCEEDED:
      return onLoadModelsInfoLoaded(state, action);
    case ActionTypes.LOAD_MODELS_INFO.ERRORED:
      return onLoadModelsInfoErrored(state, action);

    case ActionTypes.GET_MODEL_REPARABILITY.SUCCEEDED:
      return onGetModelReparabilityLoaded(state, action);

    default: {
      return state;
    }
  }
}
