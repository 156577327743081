import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileShipping,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}files/${COMMON_QUERY_PARAMS.FILE_ID}/shipping`;

interface GetFileShippingsQueryArgs {
  fileId: string;
}

async function getFileShippingsQuery({
  fileId,
}: GetFileShippingsQueryArgs): Promise<
  BackResponse<FileShipping> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BackResponse<FileShipping>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFileShippingsQuery;
