import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const CREATE_OWNER_FOR_ORDER = createRequest(
  '@@SAV/MARKETPLACE/CREATE_OWNER_FOR_ORDER',
);

export const LOAD_FILTERS = createRequest('@@SAV/MESSAGES/LOAD_FILTERS');

export const LOAD_MARKETPLACE_TICKETS = createRequest(
  '@@SAV/MESSAGES/LOAD_MARKETPLACE_TICKETS',
);
export const SHOW_MARKETPLACE_TICKETS_LOADER =
  '@@SAV/MESSAGES/SHOW_MARKETPLACE_TICKETS_LOADER';
export const LOAD_MARKETPLACE_DETAILED_TICKET = createRequest(
  '@@SAV/MESSAGES/LOAD_MARKETPLACE_DETAILED_TICKET',
);

export const CLOSE_MARKETPLACE_TICKET = createRequest(
  '@@SAV/MARKETPLACE/CLOSE_MARKETPLACE_TICKET',
);
export const LOAD_OWNER_PRODUCTS = createRequest(
  '@@SAV/MARKETPLACE/LOAD_OWNER_PRODUCTS',
);
export const LOAD_SELLER_PRODUCTS_INFO = createRequest(
  '@@SAV/MARKETPLACE/LOAD_SELLER_PRODUCTS_INFO',
);

export const LOAD_CLAIM_INFO_BY_ORDER = createRequest(
  '@@SAV/MARKETPLACE/LOAD_CLAIM_INFO_BY_ORDER',
);
export const LOAD_CLAIMS_BY_ORDER = createRequest(
  '@@SAV/MARKETPLACE/LOAD_CLAIMS_BY_ORDER',
);
export const LOAD_FILES_BY_ORDER = createRequest(
  '@@SAV/MARKETPLACE/LOAD_FILES_BY_ORDER',
);

export const SEND_MESSAGE = createRequest('@@SAV/MESSAGES/SEND_MESSAGE');

export const LOAD_MARKETPLACE_TICKETS_BY_ORDER = createRequest(
  '@@SAV/MARKETPLACE/LOAD_MARKETPLACE_TICKETS_BY_ORDER',
);
export const LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER = createRequest(
  '@@SAV/MARKETPLACE/LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER',
);

export const SNOOZE_TICKET = createRequest('@@SAV/MARKETPLACE/SNOOZE_TICKET');

export const UPLOAD_MARKETPLACE_ATTACHMENTS = createRequest(
  '@@SAV/MARKETPLACE/UPLOAD_MARKETPLACE_ATTACHMENTS',
);
export const DELETE_UPLOADED_MARKETPLACE_ATTACHMENT =
  '@@SAV/MARKETPLACE/DELETE_UPLOADED_MARKETPLACE_ATTACHMENT';

export const TRACK_USER_ACTIVITY_ON_TICKET = createRequest(
  '@@SAV/MARKETPLACE/TRACK_USER_ACTIVITY_ON_TICKET',
);
export const REFRESH_TICKET_DETAILS = createRequest(
  '@@SAV/MARKETPLACE/REFRESH_TICKET_DETAILS',
);
export const GENERATE_OWNER_AND_BACKOFFICE_LINK = createRequest(
  '@@SAV/MARKETPLACE/GENERATE_OWNER_AND_BACKOFFICE_LINK',
);

export const UPDATE_DRAFT_MESSAGE = '@@SAV/MARKETPLACE/UPDATE_DRAFT_MESSAGE';
export const DELETE_DRAFT_MESSAGE = '@@SAV/MARKETPLACE/DELETE_DRAFT_MESSAGE';
