import { defineMessages } from 'react-intl';

export default defineMessages({
  keyboardLayout: {
    id: 'view.shortcutsModal.selectKeyboard.label',
    defaultMessage: 'Keyboard layout',
  },
  azerty: {
    id: 'view.shortcutsModal.selectKeyboard.option.azerty.label',
    defaultMessage: 'French',
  },
  qwerty: {
    id: 'view.shortcutsModal.selectKeyboard.option.qwerty.label',
    defaultMessage: 'United state',
  },
  qwertz: {
    id: 'view.shortcutsModal.selectKeyboard.option.qwertz.label',
    defaultMessage: 'German',
  },
});
