import {
  APP_COMPONENT_STATUSES,
  AppComponent,
} from '@savgroup-front-common/types';

interface RawAppComponent {
  created_at: string;
  description: string | null;
  group: boolean;
  group_id: string | null;
  id: string;
  name: string;
  only_show_if_degraded: boolean;
  page_id: string;
  position: number;
  showcase: boolean;
  start_date: string;
  status: APP_COMPONENT_STATUSES;
  updated_at: string;
}

const adaptRawAppComponentsToAppComponents = (
  components: RawAppComponent[],
): AppComponent[] =>
  components.map((component) => ({
    createdAt: component.created_at,
    description: component.description,
    group: component.group,
    groupId: component.group_id,
    id: component.id,
    name: component.name,
    onlyShowIfDegraded: component.only_show_if_degraded,
    pageId: component.page_id,
    position: component.position,
    showcase: component.showcase,
    startDate: component.start_date,
    status: component.status,
    updatedAt: component.updated_at,
  }));

export default adaptRawAppComponentsToAppComponents;
