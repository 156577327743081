import { darken } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

import { rem } from '../../helpers';

import { TABLE_ALIGN, TABLE_ORDER } from './TableContent.types';

export const $StyledTable = styled.table`
  min-width: 100%;
  border-collapse: collapse;
`;

const goDown = keyframes`
  0% {
    transform: translateY(${rem(8)}) rotate(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0) rotate(180deg);
    opacity: 0;
  }
`;
const goUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(${rem(8)});
    opacity: 0;
  }
`;
const fade = keyframes`
  0% {
    transform: translateY(${rem(4)}) scale(1);
    opacity: 0;
  }
  50% {
    transform: translateY(${rem(4)}) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(${rem(4)}) scale(0);
    opacity: 0;
  }
`;

interface TableOrderContainerProps {
  $order: TABLE_ORDER;
}

export const $TableOrderContainer = styled.span<TableOrderContainerProps>`
  > svg {
    animation-name: ${({ $order }) => {
      if ($order === TABLE_ORDER.NONE) {
        return goDown;
      }
      if ($order === TABLE_ORDER.ASC) {
        return goUp;
      }

      return fade;
    }};
    opacity: ${({ $order }) => ($order === TABLE_ORDER.NONE ? 0 : 1)};
    transform: ${({ $order }) =>
      $order === TABLE_ORDER.ASC
        ? `translateY(${rem(4)}) rotate(180deg)`
        : `translateY(${rem(4)})`};
    animation-timing-function: linear;
    animation-iteration-count: 0;
    animation-duration: 1s;
  }
  display: inline-block;
  transform: translateY(${rem(4)});
`;
export const $TableContainer = styled.div`
  overflow-x: auto;
  flex: 1;
  margin-bottom: 1.5rem;
`;

interface CellMixinProps {
  disableSortBy?: boolean;
  align?: TABLE_ALIGN | string;
  $isNewUiEnabled?: boolean;
}

const cellMixin = css<CellMixinProps>`
  padding: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '12px 8px' : rem(10))};
  text-align: ${({ align }) => align || TABLE_ALIGN.LEFT};
  font-size: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? theme.fonts.size.megaSmall : theme.fonts.size.ultraSmall};
  line-height: 1.1;
  &:first-child {
    padding-left: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '8px' : 0)};
  }
  &:last-child {
    padding-right: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '8px' : 0)};
  }

  &:hover ${$TableOrderContainer} > svg {
    animation-iteration-count: ${({ disableSortBy }) =>
      disableSortBy ? null : 'infinite'};
  }
`;

export const $CellContent = styled.div`
  display: inline-block;
`;

export const $StyledTd = styled.td<CellMixinProps>`
  ${cellMixin};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? theme.newUI.defaultColors.text
      : theme.colors.mainTextColor};
  @media ${media.minWidth.lg} {
    font-size: ${({ theme, $isNewUiEnabled }) =>
      $isNewUiEnabled
        ? theme.fonts.size.megaSmall
        : theme.fonts.size.medium} !important;
  }
`;

const thHover = css`
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
  }
  &:active {
    background-color: ${({ theme }) => {
      return darken(0.05, theme.colors.secondaryBackground);
    }};
  }
`;

export const $StyledTh = styled.th<CellMixinProps>`
  ${cellMixin}
  ${({ disableSortBy }) => (disableSortBy ? null : thHover)}
  border-bottom: ${({ theme }) => theme.newUI.variationColors.light4} 1px solid;
  padding-bottom: ${rem(12)};
  user-select: none;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? theme.newUI.defaultColors.secondaryText
      : theme.colors.paragraphTextColor};
  white-space: nowrap;
  > span {
    white-space: break-spaces;
  }
`;

export const $PaginationContainer = styled.div`
  padding-top: 1rem;
`;

export const $StyledTr = styled.tr<{
  $isEven?: boolean;
  $isNewUiEnabled?: boolean;
}>`
  position: relative;
  border-collapse: collapse;

  background-color: ${({ theme, $isEven, $isNewUiEnabled }) =>
    $isEven && !$isNewUiEnabled ? theme.colors.neutralBackground : null};
`;
export const $HeaderTr = styled.tr`
  position: relative;
  border-collapse: collapse;
`;

export const $StyledTHead = styled.thead`
  ${$StyledTr} {
    vertical-align: bottom;
  }
`;

export const $StyledTFoot = styled.tfoot`
  ${$StyledTr} {
    vertical-align: bottom;
    border-top: ${({ theme }) => theme.newUI.variationColors.light4} 1px solid;
    ${$StyledTd} {
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
      color: ${({ theme }) => theme.colors.mainTextColor};
      font-size: ${({ theme }) => theme.fonts.size.small}!important;
    }
  }
`;

export const $StyledTBody = styled.tbody<{ $isNewUiEnabled?: boolean }>`
  ${$StyledTr} {
    vertical-align: top;
    :not(:first-child) ${$StyledTd} {
      border-top: ${({ theme }) => theme.newUI.variationColors.light4} 1px solid;
    }
    &:hover {
      background: ${({ theme }) =>
        theme.colors.datatableRowBackgroundHoverColor};
    }
  }
`;
