import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAssignedStoreIds } from '@savgroup-front-common/core/src/domains/login/selectors';
import {
  storeActionCreators,
  storeSelectors,
} from '@savgroup-front-common/core/src/domains/store';
import { useControlTypedSelector } from 'control/hooks';

const useStore = () => {
  const dispatch = useDispatch();
  const storeIds = useSelector(selectAssignedStoreIds);
  const stores = useControlTypedSelector((state) =>
    storeSelectors.selectStoreByIds(state, { storeIds }),
  );

  useEffect(() => {
    storeIds.forEach((storeId) =>
      dispatch(storeActionCreators.getStore({ storeId })),
    );
  }, [dispatch, storeIds]);

  return stores.map(({ name }) => name);
};

export default useStore;
