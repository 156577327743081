import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/confirmation`;

interface GetClaimConfirmationPayload {
  claimId: string;
}

export interface GetClaimConfirmationResponseValue {
  additionalElements: string[];
}

interface GetClaimConfirmationResponse extends BaseBackResponse {
  value: GetClaimConfirmationResponseValue;
}

async function getClaimConfirmation({
  claimId,
}: GetClaimConfirmationPayload): Promise<GetClaimConfirmationResponseValue> {
  const functionName = 'getClaimConfirmation';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);
  const response = await apiCall<GetClaimConfirmationResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${functionName}`);
  }

  return response.value;
}

export default getClaimConfirmation;
