import { all, put, select, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import {
  messagesFiltersAppliedSelector,
  messagesSearchQuerySelector,
  messagesSortValueSelector,
} from '../filter/selectors';
import { ActionCreators } from '../marketplaceTickets';
import * as TicketsActions from '../marketplaceTickets/marketplaceAggregator/ticketsConst';

function* loadTicketsWorker({ payload = {} }) {
  const { withSpinner = false } = payload;

  if (withSpinner) {
    yield put(ActionCreators.showMarketplaceTicketsLoader());
  }

  const appliedFilters = yield select(messagesFiltersAppliedSelector);
  const searchQuery = yield select(messagesSearchQuerySelector);
  const sortValue = yield select(messagesSortValueSelector);

  yield put(
    ActionCreators.loadMarketplaceTickets({
      filters: appliedFilters,
      searchQuery,
      searchOrder: { sortOrder: sortValue?.value },
    }),
  );
}

function* loadTicketsWatcher() {
  yield takeEvery(TicketsActions.LOAD_TICKETS, loadTicketsWorker);
}

export default function* ticketsSagas() {
  try {
    yield all([loadTicketsWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
