export enum SPARE_PART_REQUEST_LINE_DOMAIN {
  LIST = 'list',
  CURRENT_PAGE_NUMBER = 'currentPage',
  BY_PAGE = 'byPage',
  TOTAL_COUNT = 'totalCount',
  QUERY = 'query',
  IS_DESCENDING = 'isDescending',
  RUBRICS = 'rubrics',
}

export const SPARE_PART_REQUEST_LINE_LIST_DOMAIN_KEY = 'requestLineList';
