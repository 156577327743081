import { ReverseMoney } from './ReverseMoney';

export type StockItemId = string;

export enum STOCK_ITEM_STATUS {
  AVAILABLE = 'Available',
  BOOKED = 'Booked',
  CONSUMED = 'Consumed',
  BROKEN_UPON_RECEIPT = 'BrokenUponReceipt',
  MISMATCH = 'Mismatch',
  INSTALLED = 'Installed',
  DEFECTIVE = 'Defective',
  USELESS = 'Useless',
  BROKEN_DURING_INTERVENTION = 'BrokenDuringIntervention',
  SUPPLIER_LITIGATION = 'SupplierLitigation',
  OBSOLETE = 'Obsolete',
  MISSING = 'Missing',
  IN_TRANSIT = 'InTransit',
  COMMERCIAL_SOLUTION = 'CommercialSolution',
  MISMATCH_WRONG_PART = 'MismatchWrongPart',
}

export interface StockItem {
  stockItemId: StockItemId;
  stockItemStatus: STOCK_ITEM_STATUS;
  sparePartReference: string;
  stockName: string;
  fileReference?: string;
  supplierName: string;
  stockInputTimeUtc: string;
}

export interface StockItemDetails {
  stockItemId: StockItemId;
  condition: string;
  sparePartReference: string;
  supplierReference: string;
  supplierName: string;
  supplierId: string;
  stockId: string;
  stockName: string;
  fileReference?: string;
  fileId?: string;
  stockItemLocation: string;
  pretaxPurchasePrice: ReverseMoney;
  taxIncludedSellingPrice: ReverseMoney;
  stockItemStatus: STOCK_ITEM_STATUS;
}
