import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, ProductHistory } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}ownerProducts/${COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID}/files`;

interface GetProductHistoryByOwnerProductIdResponse extends BaseBackResponse {
  value: ProductHistory[];
}

async function getProductHistoryByOwnerProductId({
  ownerProductId,
}: {
  ownerProductId: string;
}): Promise<ProductHistory[]> {
  if (!ownerProductId) {
    throw new RequiredPayloadFieldIsNotProvided(
      'ownerProductId',
      'getProductHistoryByOwnerProductId',
    );
  }

  const url = ENDPOINT.replace(
    COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID,
    ownerProductId,
  );

  const response = await apiCall<GetProductHistoryByOwnerProductIdResponse>(
    url,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  if (response.failure) {
    throw new Error(
      `Got an exception trying to getProductHistoryByOwnerProductId`,
    );
  }

  return response.value;
}

export default getProductHistoryByOwnerProductId;
