import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SanityTest,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}sanity/sanityTestQueue`;

export interface GetSanityTestsListArgs {
  pageSize: number;
  page: number;
  queue: boolean;
}

interface SanityTestList {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: SanityTest[];
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

const getSanityTestsList = async ({
  pageSize,
  page,
  queue,
}: GetSanityTestsListArgs): Promise<
  BackResponse<SanityTestList> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<SanityTestList>>(
      buildUrl(ENDPOINT, { pageSize, page, queue }),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSanityTestsList;
