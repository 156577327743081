import { AddressInfoDto } from '@savgroup-front-common/types';

export enum ADDRESS_SUGGESTION_ORIGIN {
  USER_VALUES = 'userValues',
  SUGGESTION = 'suggestion',
}

export enum ADDRESS_SUGGESTION_FIELDS {
  ORIGIN = 'origin',
}
export interface AddressSuggestionFormValues {
  ownerAddressToKeep: ADDRESS_SUGGESTION_ORIGIN;
  ownerAddressOnFileToKeep: ADDRESS_SUGGESTION_ORIGIN;
}

export interface AddressSuggestionStepValues {
  ownerAddress: {
    address: AddressInfoDto;
    suggestedAddress?: AddressInfoDto;
  };
  ownerAddressOnFile: {
    address: AddressInfoDto;
    suggestedAddress?: AddressInfoDto;
  };
  isOwnerAddressOnFileSame: boolean;
}
export interface AddressSuggestionStepContext {
  hasSuggestedAddress: boolean;
  unmatchingFields: string[];
  couldEditAddressOnFile: boolean;
}
