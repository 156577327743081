import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions';
import { BackResponse, SearchOrderDetails } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/details`;

/**
 * @deprecated Need to use getOrderDetailsQuery instead
 * @param orderId
 */
async function getOrderDetails({ orderId }: { orderId: string }) {
  if (!orderId) {
    throw new RequiredPayloadFieldIsNotProvided(
      JSON.stringify({ orderId }),
      'getOrderDetails',
    );
  }

  const response = await apiCall<BackResponse<SearchOrderDetails>>(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId),
    { method: SUPPORTED_METHODS.GET },
  );

  return response.value;
}

export default getOrderDetails;
