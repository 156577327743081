import React, { FunctionComponent, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { useIsSuperAdmin } from '@savgroup-front-common/core/src/hooks';
import {
  MenuHamburgerCloseIcon,
  MenuHamburgerOpenIcon,
  NewReversLogoIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { ROUTES } from '../../../../constants/routing';

import { $MenuHamburger, $MenuLogo } from './HeaderLogo.styles';
import useDotColor from './hooks/useDotColor';
import useMaintenanceStatus from './hooks/useMaintenanceStatus';
import messages from './messages';

const LOGO_SIZE = '40px';

interface HeaderLogoProps {
  onClick: (event: MouseEvent) => void;
  isMenuOpen: boolean;
}

const HeaderLogo: FunctionComponent<
  React.PropsWithChildren<HeaderLogoProps>
> = ({ onClick, isMenuOpen }) => {
  const theme = useTheme();
  const iconColor = theme.colors.white;
  const isSuperAdmin = useIsSuperAdmin();

  const dotColor = useDotColor();

  useMaintenanceStatus();

  return (
    <>
      <$MenuHamburger
        onClick={(event) => onClick(event)}
        type={BUTTON_TYPES.BUTTON}
      >
        {isMenuOpen ? (
          <MenuHamburgerOpenIcon color={iconColor} size={LOGO_SIZE} />
        ) : (
          <MenuHamburgerCloseIcon color={iconColor} size={LOGO_SIZE} />
        )}
      </$MenuHamburger>
      <$MenuLogo
        as={Link}
        to={ROUTES.HOME}
        title={safeFormattedIntlString(messages.logoTitle)}
        $cornerColor={isSuperAdmin ? dotColor : undefined}
      >
        <NewReversLogoIcon size={LOGO_SIZE} />
      </$MenuLogo>
    </>
  );
};

HeaderLogo.displayName = 'HeaderLogo';

export default React.memo(HeaderLogo);
