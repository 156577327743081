import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { CIVILITY } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  SPARE_PART_HANDLING_TYPES,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}request`;

export interface SetSparePartRequestArgs {
  supplierId: string;
  fileId?: string;
  requestLines: {
    sparePartReference: string;
    quantity: number;
  }[];
  handlingType: SPARE_PART_HANDLING_TYPES;
  deliveryAddress: {
    addressId?: string;
    civility: CIVILITY;
    firstname?: string;
    companyName?: string;
    lastname?: string;
    address?: string;
    additionalAddress?: string;
    additionalInformation?: string;
    doorCode?: string;
    floor?: string;
    postalCode?: string;
    city?: string;
    countryCode?: string;
    phone?: string;
    isDefault?: boolean;
    isValid?: boolean;
  };
}

type RequestId = string;

interface SetSparePartRequestResponse extends BaseBackResponse {
  value: RequestId;
}

export default async function setSparePartRequest(
  payload: SetSparePartRequestArgs,
): Promise<RequestId> {
  const response = await apiCall<SetSparePartRequestResponse>(ENDPOINT, {
    method: SUPPORTED_METHODS.POST,
    json: payload,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to setSparePartRequest`);
  }

  return response.value;
}
