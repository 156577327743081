import {
  APP_COMPONENT_STATUSES,
  APP_STATUS_IMPACT,
  AppScheduledMaintenance,
  BackResponse,
  BackResponseFailure,
  SCHEDULED_MAINTENANCE_STATUSES,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';

import { adaptRawScheduledMaintenancesToScheduledMaintenances } from './adapters';
import STATUS_PAGE_URL from './config';

export interface RawScheduledMaintenanceResponse {
  page: {
    id: string;
    name: string;
    url: string;
    updated_at: string;
  };
  scheduled_maintenances: {
    components: {
      created_at: string;
      description: string | null;
      group: boolean;
      group_id: string | null;
      id: string;
      name: string;
      only_show_if_degraded: boolean;
      page_id: string;
      position: number;
      showcase: boolean;
      start_date: string;
      status: APP_COMPONENT_STATUSES;
      updated_at: string;
    }[];
    created_at: string;
    id: string;
    impact: APP_STATUS_IMPACT;
    incident_updates: {
      body: string;
      created_at: string;
      display_at: string;
      id: string;
      incident_id: string;
      status: SCHEDULED_MAINTENANCE_STATUSES;
      updated_at: string;
    }[];
    scheduled_for: string;
    scheduled_until: string;
    monitoring_at: null;
    name: string;
    page_id: string;
    resolved_at: null;
    shortlink: string;
    status: SCHEDULED_MAINTENANCE_STATUSES;
    updated_at: string;
  }[];
}

const getAppScheduledMaintenances = async (): Promise<
  BackResponse<AppScheduledMaintenance[]> | BackResponseFailure
> => {
  try {
    const ENDPOINT = `${STATUS_PAGE_URL}/api/v2/scheduled-maintenances.json`;

    const response = await fetch(ENDPOINT);

    const rawScheduledMaintenanceResponse =
      (await response.json()) as RawScheduledMaintenanceResponse;

    const returnValue: BackResponse<AppScheduledMaintenance[]> = {
      failure: false,
      isHandledError: false,
      hasWarnings: false,
      isNotFound: false,
      errors: [],
      value: adaptRawScheduledMaintenancesToScheduledMaintenances(
        rawScheduledMaintenanceResponse,
      ),
    };

    return returnValue;
  } catch (e) {
    return prepareResponseFailure(e);
  }
};

export default getAppScheduledMaintenances;
