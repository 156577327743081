import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useTheme } from 'styled-components';

import { FilterIcon } from '@savgroup-front-common/core/src/protons/icons';

import SearchDisplayError from '../../common/SearchDisplayError';

import messages from './messages';

interface NoResultsProps {
  animationState: TransitionStatus;
}

const NoFilters: FunctionComponent<React.PropsWithChildren<NoResultsProps>> = ({
  animationState,
}) => {
  const theme = useTheme();

  return (
    <SearchDisplayError
      dataTestId="search-error-no-filters"
      animationState={animationState}
      title={messages.noFiltersTitle}
      body={messages.noFiltersText}
      icon={<FilterIcon color={theme.colors.primary} size="64px" />}
    />
  );
};

NoFilters.displayName = 'NoFilters';

export default NoFilters;
