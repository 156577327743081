import { RESULT_TYPE } from '@savgroup-front-common/types';

import { RESULT_DISPLAY_TYPE, SearchResultTitle } from '../Search.types';

const generateResultTitle = ({
  type,
  count,
  animationIndex = 0,
  isNew = true,
}: {
  type: RESULT_TYPE;
  count: number;
  animationIndex?: number;
  isNew?: boolean;
}): SearchResultTitle => ({
  displayType: RESULT_DISPLAY_TYPE.TITLE,
  animationIndex,
  key: `title-${type}`,
  resultType: type,
  isNew,
  value: { count },
});

export default generateResultTitle;
