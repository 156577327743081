import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  CURRENCIES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { SparePartSearchSummaryDto } from '@savgroup-front-common/core/src/api/SpareParts/searchSparePartsByNames';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SparePartType,
  SparePartStatus,
  SpareProductSize,
  SparePartCondition,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}parts/referenceNew`;

export interface SetSparePartRequestNewReferenceArgs {
  sellerId: string;
  manufacturerReference: string;
  manufacturerLabel: string;
  manufacturerId: string;
  supplierReference: string;
  supplierLabel: string;
  supplierId: string;
  sparePartType: SparePartType;
  sparePartStatus: SparePartStatus;
  spareProductSize: SpareProductSize;
  sparePartCondition: SparePartCondition;
  isOriginalEquipmentManufacturer: boolean;
  isCustomerSaleAllowed: boolean;
  preTaxPurchasePriceCurrency: CURRENCIES;
  preTaxPurchasePrice: number;
  stockSite?: string;
}

export const setSparePartRequestNewReference = async ({
  sellerId,
  manufacturerReference,
  manufacturerLabel,
  manufacturerId,
  supplierReference,
  supplierLabel,
  supplierId,
  sparePartType,
  sparePartStatus,
  spareProductSize,
  sparePartCondition,
  isOriginalEquipmentManufacturer,
  isCustomerSaleAllowed,
  preTaxPurchasePriceCurrency,
  preTaxPurchasePrice,
  stockSite,
}: SetSparePartRequestNewReferenceArgs): Promise<
  BackResponse<SparePartSearchSummaryDto> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<SparePartSearchSummaryDto>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
        manufacturerReference,
        manufacturerLabel,
        manufacturerId,
        supplierReference,
        supplierLabel,
        supplierId,
        sparePartType,
        sparePartStatus,
        spareProductSize,
        sparePartCondition,
        isOriginalEquipmentManufacturer,
        isCustomerSaleAllowed,
        preTaxPurchasePriceCurrency,
        preTaxPurchasePrice,
        stockSite,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
