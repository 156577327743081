import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const FilterIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <path
      d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"
      fill="currentColor"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </Icon>
);

FilterIcon.displayName = 'FilterIcon';

export default FilterIcon;
