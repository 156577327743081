import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}stripe/deleteStripeSellerAccount`;

export interface DeleteStripeSellerAccountArgs {
  sellerId: string;
}

export default async function deleteStripeSellerAccount(
  payload: DeleteStripeSellerAccountArgs,
): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    return await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId: payload.sellerId,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
