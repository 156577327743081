import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { ClaimContextSummary } from '@savgroup-front-common/types/src/Claim';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/issue`;

export async function setClaimGroupIssueCommand({
  claimGroupId,
  issueId,
}: {
  claimGroupId: string;
  issueId: string;
}): Promise<BackResponse<ClaimContextSummary[]> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<ClaimContextSummary[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          issueId,
        },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
