import { defineMessages } from 'react-intl';

export default defineMessages({
  qrcodeUploadButton: {
    id: 'view.card.documents.qrcodeUploadButton',
    defaultMessage: 'Upload documents',
  },
  importMessage: {
    id: 'view.card.documents.qrcodeUpload.importMessage',
    defaultMessage: 'Import directly your pictures for the folder',
  },
  importDocumentSuccess: {
    id: 'view.card.documents.qrcodeUpload.importMessageSuccess',
    defaultMessage:
      '{count} {count, plural, one {Document} other {Documents}} imported successfully',
  },
  importDocumentError: {
    id: 'view.card.documents.qrcodeUpload.importDocumentError',
    defaultMessage:
      'Error on {count, plural, one {document} other {documents}} import',
  },
});
