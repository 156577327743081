import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const LOAD_FILE_INFO = createRequest('FILE_INFO/LOAD_FILE_INFO');
export const FOLDERS_FORCE_CLOSE_FILE = createRequest(
  'FOLDERS_FORCE_CLOSE_FILE',
);
export const EXPLODE_FILE_REQUEST = 'EXPLODE_FILE_REQUEST';
export const SPLIT_FILE_REQUEST = createRequest('SPLIT_FILE_REQUEST');

export const LOAD_FILE_INFO_PAGE = createRequest('LOAD_FILE_INFO_PAGE');

export const HYDRATE_FILE_SUMMARY = createRequest(
  'FILE_INFO/HYDRATE_FILE_SUMMARY',
);
export const SUBMIT_ATTACHMENTS = createRequest('SUBMIT_ATTACHMENTS');
