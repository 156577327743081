import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { getControlTheme } from '@savgroup-front-common/core/src/theme/Theme';

export const ThemeProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <StyledThemeProvider theme={getControlTheme({}, isNewUiEnabled)}>
      {children}
    </StyledThemeProvider>
  );
};

ThemeProvider.displayName = 'ThemeProvider';
