import React, { FunctionComponent, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  media,
} from '@savgroup-front-common/constants/src/shared';
import { Menu } from '@savgroup-front-common/core/src/atoms/Menu';
import { MENU_POSITIONS } from '@savgroup-front-common/core/src/atoms/Menu/Menu.types';
import { selectSellers } from '@savgroup-front-common/core/src/domains/sellers/selectors';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import {
  ChevronDownIcon,
  PlusIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import {
  $Button,
  $CreateButtonContainer,
  $MenuVerticalPosition,
} from './CreateButton.styles';
import CreateModal from './CreateModal';
import useCreateButton from './hooks/useCreateButton';
import messages from './messages';

const CreateButton: FunctionComponent = () => {
  const ref = useRef(null);

  const sellers = useSelector(selectSellers);

  const {
    onCreateModalClose,
    onToggleMenu,
    isMenuOpen,
    modalType,
    handleButtonClick,
    authorizedOptions,
  } = useCreateButton();

  const isMobileView = useMedia(media.maxWidth.md);

  const isNewUiEnabled = useIsNewUiEnabled();

  if (authorizedOptions.length === 0) {
    return null;
  }

  const icon = isNewUiEnabled ? <ChevronDownIcon /> : <PlusIcon />;

  return (
    <$CreateButtonContainer $isNewUiEnabled={isNewUiEnabled}>
      <div ref={ref}>
        <$Button
          dataTestId="createButton"
          componentThemeName={SUPPORTED_COMPONENTS.CREATE_FILE_BUTTON}
          icon={isMobileView ? undefined : icon}
          primary
          disabled={sellers.length === 0}
          type={BUTTON_TYPES.BUTTON}
          onClick={handleButtonClick}
          position={
            isNewUiEnabled
              ? SUPPORTED_ICON_POSITIONS.RIGHT
              : SUPPORTED_ICON_POSITIONS.LEFT
          }
          $isNewUiEnabled={isNewUiEnabled}
        >
          <SafeFormattedMessageWithoutSpread message={messages.createLabel} />
        </$Button>

        {authorizedOptions.length > 1 && (
          <$MenuVerticalPosition $isNewUiEnabled={isNewUiEnabled}>
            <Menu
              dataTestId="createButtonMenu"
              menuItems={authorizedOptions}
              isOpen={isMenuOpen}
              onClose={onToggleMenu}
              wrapperRef={ref}
              position={
                isNewUiEnabled ? MENU_POSITIONS.RIGHT : MENU_POSITIONS.LEFT
              }
            />
          </$MenuVerticalPosition>
        )}
      </div>
      <CreateModal onClose={onCreateModalClose} modalType={modalType} />
    </$CreateButtonContainer>
  );
};

CreateButton.displayName = 'CreateButton';

export default React.memo(CreateButton);
