import {
  APP_COMPONENT_STATUSES,
  APP_STATUS_IMPACT,
  AppScheduledMaintenance,
  AppStatusUpdate,
  SCHEDULED_MAINTENANCE_STATUSES,
} from '@savgroup-front-common/types';

import adaptRawAppComponentsToAppComponents from './adaptRawAppComponentsToAppComponents';

interface RawScheduledMaintenance {
  page: {
    id: string;
    name: string;
    url: string;
    updated_at: string;
  };
  scheduled_maintenances: {
    components: {
      created_at: string;
      description: string | null;
      group: boolean;
      group_id: string | null;
      id: string;
      name: string;
      only_show_if_degraded: boolean;
      page_id: string;
      position: number;
      showcase: boolean;
      start_date: string;
      status: APP_COMPONENT_STATUSES;
      updated_at: string;
    }[];
    created_at: string;
    id: string;
    impact: APP_STATUS_IMPACT;
    incident_updates: {
      body: string;
      created_at: string;
      display_at: string;
      id: string;
      incident_id: string;
      status: SCHEDULED_MAINTENANCE_STATUSES;
      updated_at: string;
    }[];
    scheduled_for: string;
    scheduled_until: string;
    monitoring_at: null;
    name: string;
    page_id: string;
    resolved_at: null;
    shortlink: string;
    status: SCHEDULED_MAINTENANCE_STATUSES;
    updated_at: string;
  }[];
}

const adaptRawScheduledMaintenancesToScheduledMaintenances = (
  payload: RawScheduledMaintenance,
): AppScheduledMaintenance[] => {
  return payload.scheduled_maintenances.map((maintenance) => ({
    components: adaptRawAppComponentsToAppComponents(maintenance.components),
    createdAt: maintenance.created_at,
    id: maintenance.id,
    impact: maintenance.impact,
    incidentUpdates: maintenance.incident_updates.map<
      AppStatusUpdate<SCHEDULED_MAINTENANCE_STATUSES>
    >((update) => ({
      body: update.body,
      createdAt: update.created_at,
      displayAt: update.display_at,
      id: update.id,
      incidentId: update.incident_id,
      status: update.status,
      updatedAt: update.updated_at,
    })),
    scheduledFor: maintenance.scheduled_for,
    scheduledUntil: maintenance.scheduled_until,
    monitoringAt: maintenance.monitoring_at,
    name: maintenance.name,
    pageId: maintenance.page_id,
    resolvedAt: maintenance.resolved_at,
    shortlink: maintenance.shortlink,
    status: maintenance.status,
    updatedAt: maintenance.updated_at,
  }));
};

export default adaptRawScheduledMaintenancesToScheduledMaintenances;
