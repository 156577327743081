import { Action } from 'redux-actions';
import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import 'regenerator-runtime';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GET_DRAFT_HISTORY } from './actionTypes';
import { GetDraftHistoryPayload } from './types';

function* getDraftHistoryWorker({
  payload,
}: {
  payload: GetDraftHistoryPayload;
}): Generator<
  | CallEffect
  | PutEffect<{
      type: string;
    }>
> {
  const { sellerId } = payload;
  const meta = { sellerId, indexer: sellerId };

  yield call(
    callAndGetResponse,
    GET_DRAFT_HISTORY,
    `${APIConfiguration.import}sellers/${sellerId}/drafts/byStatuses?statuses=Applied`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );

  yield put(GET_DRAFT_HISTORY.end(meta));
}

function* getDraftHistoryWatcher() {
  yield takeLatest<Action<GetDraftHistoryPayload>>(
    GET_DRAFT_HISTORY.BASE,
    getDraftHistoryWorker,
  );
}

export default function* mainSaga() {
  try {
    yield all([getDraftHistoryWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
