import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { File, FilterCategory } from '@savgroup-front-common/types';

interface RawTodosListFacet {
  count: number;
  name: string;
  key: string;
  label: string;
}

export interface RawTodosListRubric {
  name: string;
  facets: RawTodosListFacet[];
}

export enum TODOS_LIST_DOMAIN {
  VALUE = 'value',
  CURRENT_PAGE_NUMBER = 'currentPageNumber',
  BY_PAGE = 'byPage',
  TOTAL_COUNT = 'totalCount',
  QUERY = 'query',
  IS_DESCENDING = 'isDescending',
  RUBRICS = 'rubrics',
  SORT = 'sort',
}

export interface TodosListState {
  [TODOS_LIST_DOMAIN.VALUE]: File[];
  [TODOS_LIST_DOMAIN.IS_DESCENDING]: boolean;
  [TODOS_LIST_DOMAIN.SORT]: SORT_TYPES;
  [TODOS_LIST_DOMAIN.QUERY]: string;
  [TODOS_LIST_DOMAIN.RUBRICS]: FilterCategory[];
  [TODOS_LIST_DOMAIN.TOTAL_COUNT]: number;
  [TODOS_LIST_DOMAIN.CURRENT_PAGE_NUMBER]: number;
  [TODOS_LIST_DOMAIN.BY_PAGE]: number;
}
