import { RequestLineStatus } from '@savgroup-front-common/types';

import messages from './messages/sparePartRequestLineStatusMessages';

export const getSparePartRequestLineStatusMessage = (
  requestLineStatus: RequestLineStatus,
) => {
  switch (requestLineStatus) {
    case RequestLineStatus.AVAILABLE_IN_STOCK:
      return messages.AvailableInStock;
    case RequestLineStatus.SUBMITTED_TO_SUPPLIER:
      return messages.SubmittedToSupplier;
    case RequestLineStatus.ORDER_COLLECTED:
      return messages.OrderCollected;
    case RequestLineStatus.REMAINDER:
      return messages.Remainder;
    case RequestLineStatus.SOLD_OUT:
      return messages.SoldOut;
    case RequestLineStatus.TO_PROCESS:
      return messages.ToProcess;
    case RequestLineStatus.TO_VALIDATE:
      return messages.ToValidate;
    case RequestLineStatus.VALID:
      return messages.Valid;
    case RequestLineStatus.CANCELLED:
      return messages.Cancelled;
    case RequestLineStatus.CANCELLED_ABANDONED:
      return messages.CancelledAbandoned;
    case RequestLineStatus.CANCELLED_COMMERCIAL_SOLUTION_EXPIRED:
      return messages.CancelledCommercialSolutionExpired;
    case RequestLineStatus.CANCELLED_COMMERCIAL_SOLUTION_NOT_REPAIRABLE:
      return messages.CancelledCommercialSolutionNotRepairable;
    case RequestLineStatus.CANCELLED_COMMERCIAL_SOLUTION_REMAINDER:
      return messages.CancelledCommercialSolutionRemainder;
    case RequestLineStatus.CANCELLED_COMMERCIAL_SOLUTION_SOLD_OUT:
      return messages.CancelledCommercialSolutionSoldOut;
    case RequestLineStatus.CLOSED:
      return messages.Closed;
    case RequestLineStatus.MISSING:
      return messages.Missing;
    case RequestLineStatus.ORDER_SUBMITTED:
      return messages.OrderSubmitted;
    case RequestLineStatus.ORDER_DELIVERED:
      return messages.OrderDelivered;
    case RequestLineStatus.ORDER_SHIPPED:
      return messages.OrderShipped;
    case RequestLineStatus.REJECTED:
      return messages.Rejected;
    default:
      return undefined;
  }
};
