import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  CURRENCIES,
} from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions';
import {
  AddressInfoDto,
  BackResponse,
  ModelDimension,
  ProductStates,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/details`;

interface OrderDetailsFromBack {
  products: {
    sellerProductId: string;
    orderLineReference: string;
    modelTypeId: string;
    modelTypeName: string;
    brandName: string;
    modelName: string;
    price: number;
    shippingPrice: number;
    soldWarrantyPrice: number;
    priceCurrencyCode: CURRENCIES;
    state: ProductStates;
    notOpenForClaims: boolean;
    modelId: string;
    brandId: string;
    supplierId: string;
    externalIdentifier: string;
    soldWarrantyId: string;
    ownerProductId: string;
    modelDimensions: ModelDimension;
    ean: string;
    weight: number;
    sku: string;
    orderProductAdditionalInfo: [
      {
        uniqueKey: string;
        value: string;
      },
    ];
    claimId: string;
    claimGroupId: string;
    isAvailableForClaims: boolean;
  }[];
  soldWarrantyPurchaseDate: string;
  externalOrderLinkUrl: string;
  noSolution: boolean;
  purchaseStoreId: string;
  orderRegistrationType: string;
  saleReceiptNumber: string;
  tenantId: string;
  orderId: string;
  sellerId: string;
  orderReference: string;
  marketplace: string;
  storePurchasedAt: string;
  sellerName: string;
  purchaseDate: string;
  totalPrice: number;
  totalPriceCurrencyCode: string;
  productsCount: number;
  ownerId: string;
  lastName: string;
  firstName: string;
  email: string;
  deliveryAddress: AddressInfoDto;
}

/**
 * @deprecated Need to use getOrderDetailsQuery instead
 * @param orderId
 */
async function getOrderDetailsLegacy({ orderId }: { orderId: string }) {
  if (!orderId) {
    throw new RequiredPayloadFieldIsNotProvided(
      JSON.stringify({ orderId }),
      'getOrderDetails',
    );
  }

  const response = await apiCall<BackResponse<OrderDetailsFromBack>>(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId),
    { method: SUPPORTED_METHODS.GET },
  );

  const { deliveryAddress } = response.value;

  return {
    ...response.value,
    deliveryAddress: {
      ...deliveryAddress,
      firstName: deliveryAddress.firstname,
      lastName: deliveryAddress.lastname,
      addressLine2: deliveryAddress.additionalAddress,
      country: deliveryAddress.countryCode,
      company: deliveryAddress.companyName,
    },
  };
}

export default getOrderDetailsLegacy;
