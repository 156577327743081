import get from 'lodash/get';
import { combineReducers } from 'redux';

import {
  appointments as appointmentsReducer,
  commercialSolution as commercialSolutionReducer,
  explodeFileState as explodeFileStateReducer,
  handling as handlingReducer,
  invoicePaymentStatus as invoicePaymentStatusReducer,
  loadOwnerProductFiles as loadOwnerProductFilesReducer,
  splitFileState as splitFileStateReducer,
  userActions as userActionsReducer,
} from '@savgroup-front-common/core/src/domains/workflow/reducers';
import fileSummaryReducer from 'control/domains/workflow/fileSummary/reducers';

import { GET_FILE_STATES } from './actionTypes';

function getFileStatesLoading(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {
        isLoaded: false,
        isDirty: false,
        value: undefined,
      }),
      isLoaded: false,
      isDirty: true,
    },
  };
}

function getFileStatesLoadingErrored(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      isDirty: true,
      hasErrors: true,
    },
  };
}

function getFileStatesLoaded(state, { payload, meta }) {
  const fileId = get(meta, ['fileId']);
  const fileStates = get(payload, ['value']);

  return {
    ...state,
    [fileId]: {
      ...(state[fileId] || {}),
      isLoaded: true,
      isDirty: false,
      value: fileStates,
    },
  };
}

function fileStatesReducer(state = {}, action) {
  switch (action.type) {
    case GET_FILE_STATES.STARTED: {
      return getFileStatesLoading(state, action);
    }
    case GET_FILE_STATES.SUCCEEDED: {
      return getFileStatesLoaded(state, action);
    }
    case GET_FILE_STATES.ERRORED: {
      return getFileStatesLoadingErrored(state, action);
    }

    default: {
      return state;
    }
  }
}

const reducer = combineReducers({
  fileStates: fileStatesReducer,

  explodeFileState: explodeFileStateReducer,
  splitFileState: splitFileStateReducer,
  appointments: appointmentsReducer,
  commercialSolution: commercialSolutionReducer,
  userActions: userActionsReducer,
  fileSummary: fileSummaryReducer,
  invoicePaymentStatus: invoicePaymentStatusReducer,
  handling: handlingReducer,
  loadOwnerProductFiles: loadOwnerProductFilesReducer,
});

export default reducer;
