import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';
import { useIsAdmin } from '@savgroup-front-common/core/src/hooks';

const useThemedEnv = (): APP_ENVS => {
  const hasPermission = useIsAdmin();

  if (!hasPermission) {
    return APP_ENVS.PROD;
  }

  return currentAppEnvironment;
};

export default useThemedEnv;
