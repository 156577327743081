import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { PaymentProvider } from '@savgroup-front-common/types/src/Payment/PaymentProvider';

const ENDPOINT = `${APIConfiguration.payment}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/payments/accounts`;

export interface PaymentAccountSummary {
  id: string;
  name: string;
  description: string;
  paymentProvider: PaymentProvider;
  token?: {
    publicToken?: string;
  };
  isEnabled: boolean;
  metadata?: Record<string, string | undefined>;
}

interface GetPaymentAccountsBySellerQueryArgs {
  sellerId: string;
}

export const getPaymentAccountsBySellerQuery = async ({
  sellerId,
}: GetPaymentAccountsBySellerQueryArgs): Promise<
  BackResponse<PaymentAccountSummary[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    return await apiCall<BackResponse<PaymentAccountSummary[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
