import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Billing,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}seller/${COMMON_QUERY_PARAMS.SELLER_ID}/billings`;

interface GetBillingsQueryArgs {
  sellerId: string;
  search?: string;
  orderColumn?:
    | 'None'
    | 'ClientName'
    | 'ClientType'
    | 'SellerName'
    | 'BillingType'
    | 'BillNumber'
    | 'BillDate'
    | 'DueDate'
    | 'PreTaxAmount'
    | 'FileNumber'
    | 'BillingStatus';
  page?: number;
  pageSize?: number;
}

const getBillingsQuery = async ({
  sellerId,
  search = undefined,
  orderColumn = 'None',
  page = 1,
  pageSize = 10,
}: GetBillingsQueryArgs): Promise<
  BackResponse<Billing[]> | BackResponseFailure
> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
      {
        search,
        orderColumn,
        page,
        pageSize,
      },
    );

    return await apiCall<BackResponse<Billing[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getBillingsQuery;
