import { Cloud } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

export const CloudIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Cloud width={size} height={size} color={color} />;

CloudIcon.displayName = 'CloudIcon';
