import { createAction } from '@reduxjs/toolkit';

import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import {
  STOCK_ITEM_STATUS,
  StockItemDetails,
} from '@savgroup-front-common/types';

import * as actionType from './actionTypes';

export interface GetStockItemListPayload {
  page: number;
  pageSize?: number;
  isDescending?: number;
  sort?: SORT_TYPES;
  query?: string;
}

export const getStockItemList = createAction<GetStockItemListPayload>(
  actionType.GET_STOCK_ITEM_LIST.BASE,
);

export interface SetStockItemListQueryPayload {
  query: string;
}

export const setStockItemListQuery = createAction<SetStockItemListQueryPayload>(
  actionType.SET_STOCK_ITEM_LIST_QUERY.BASE,
);

export interface SetStockItemListIsDescendingPayload {
  isDescending: boolean;
}

export const setStockItemListIsDescending =
  createAction<SetStockItemListIsDescendingPayload>(
    actionType.SET_STOCK_ITEM_LIST_IS_DESCENDING.BASE,
  );

export interface SetStockItemListFiltersPayload {
  filterCategoryName: string;
  filterName: string;
  active: boolean;
}
export const setStockItemListFilters =
  createAction<SetStockItemListFiltersPayload>(
    actionType.SET_STOCK_ITEM_LIST_FILTERS.BASE,
  );

export interface UpdateStockItemListItemStatusPayload {
  stockItemId: string;
  stockItemStatus: STOCK_ITEM_STATUS;
}
export const updateStockItemListItemStatus =
  createAction<UpdateStockItemListItemStatusPayload>(
    actionType.UPDATE_STOCK_ITEM_LIST_ITEM_STATUS.BASE,
  );

export const resetStockItemListFilters = createAction(
  actionType.RESET_STOCK_ITEM_LIST_FILTERS.BASE,
);

export interface UpdateStockItemListItemPayload {
  stockItem: StockItemDetails;
}
export const updateStockItemListItem =
  createAction<UpdateStockItemListItemPayload>(
    actionType.UPDATE_STOCK_ITEM_LIST_ITEM.BASE,
  );
