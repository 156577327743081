import { createContext } from 'react';

const PageContainerContext = createContext<
  React.Dispatch<
    React.SetStateAction<{
      position: number;
      animationDuration: number;
    }>
  >
>(() => () => ({ position: 0, animationDuration: 0 }));

export default PageContainerContext;
