import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const LOAD_OWNER_PRODUCT_FILES = createRequest(
  'LOAD_OWNER_PRODUCT_FILES',
);
export const LOAD_SELLER_INFO = createRequest('LOAD_SELLER_INFO');
export const LOAD_CUSTOMER_FILES_SUMMARY = createRequest(
  'LOAD_CUSTOMER_FILES_SUMMARY',
);

export const EDIT_FILE_ADDRESS = createRequest('EDIT_FILE_ADDRESS');

export const UPLOAD_FILE_ATTACHMENT = 'UPLOAD_FILE_ATTACHMENT';
