import { Action } from 'redux-actions';
import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GetRepairerByTypeAndSellerIdPayload } from './actionCreators';
import * as types from './actionTypes';
import { formatRepairerTypeAndSellerIdIndexer } from './helpers';
import {
  selectGetRepairerByTypeAndSellerIdIsLoading,
  selectGetRepairerTypeAndBySellerIdWasLoaded,
} from './selectors';

function* getRepairersBySellerIdWorker({
  payload,
}: {
  payload: GetRepairerByTypeAndSellerIdPayload;
}): Generator<
  | SelectEffect
  | CallEffect
  | PutEffect<{
      type: string;
      meta: unknown;
    }>,
  void,
  boolean | undefined
> {
  const { sellerId, repairerType } = payload;

  if (!sellerId) {
    return;
  }

  const isLoading = yield select(selectGetRepairerByTypeAndSellerIdIsLoading, {
    sellerId,
    repairerType,
  });
  const wasLoaded = yield select(selectGetRepairerTypeAndBySellerIdWasLoaded, {
    sellerId,
    repairerType,
  });

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_REPAIRERS_BY_TYPE_AND_SELLER_ID,
    buildUrl(`${APIConfiguration.repairer}repairertype/repairers`, {
      repairerType,
      sellerId,
    }),
    { method: SUPPORTED_METHODS.GET },
    {
      sellerId,
      indexer: formatRepairerTypeAndSellerIdIndexer({ sellerId, repairerType }),
    },
  );

  yield put(
    types.GET_REPAIRERS_BY_TYPE_AND_SELLER_ID.end({ sellerId, repairerType }),
  );
}
function* getRepairersBySellerIdWatcher() {
  yield takeEvery<Action<GetRepairerByTypeAndSellerIdPayload>>(
    types.GET_REPAIRERS_BY_TYPE_AND_SELLER_ID.BASE,
    getRepairersBySellerIdWorker,
  );
}

export default function* repairerSaga(): Generator {
  try {
    yield all([getRepairersBySellerIdWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
