import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  CIVILITY,
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

export const URL = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/changeAddress`;

interface UpdateOwnerAddressArgs {
  ownerId: string;
  ownerAddress: AddressInfoDto;
  ownerAddressOnFile: AddressInfoDto;
  fileId: string;
}

interface Owner {
  id: string;
  civility: CIVILITY;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  preference: {
    language: string;
    communicationChannel: string;
    lastSelectedStore: string;
  };
  addresses: AddressInfoDto[];
  ownerAddress: AddressInfoDto;
  countryCode: string;
  tenantId: string;
}

export async function updateOwnerAddressCommand({
  ownerId,
  ownerAddress,
  ownerAddressOnFile,
  fileId,
}: UpdateOwnerAddressArgs): Promise<BackResponse<Owner> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<Owner>>(
      URL.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: { ownerAddress, ownerAddressOnFile, fileId },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
