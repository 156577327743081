import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GetProductSupplierPayload } from './actionCreators';
import * as types from './actionTypes';
import {
  selectGetProductSupplierIsLoading,
  selectGetProductSupplierWasLoaded,
} from './selectors';

function* getProductSuppliersWorker({
  payload,
}: {
  payload: GetProductSupplierPayload;
}): Generator {
  const URL = `${APIConfiguration.claim}rma/supplier/${COMMON_QUERY_PARAMS.PRODUCT_SUPPLIER_ID}`;
  const { productSupplierId } = payload;

  if (!productSupplierId) {
    return;
  }

  const isLoading = yield select(selectGetProductSupplierIsLoading, {
    productSupplierId,
  });
  const wasLoaded = yield select(selectGetProductSupplierWasLoaded, {
    productSupplierId,
  });

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_PRODUCT_SUPPLIER,
    URL.replace(COMMON_QUERY_PARAMS.PRODUCT_SUPPLIER_ID, productSupplierId),
    { method: SUPPORTED_METHODS.GET },
    { indexer: productSupplierId },
  );

  yield put(types.GET_PRODUCT_SUPPLIER.end({ productSupplierId }));
}
function* getProductSuppliersWatcher() {
  yield takeEvery<Action<GetProductSupplierPayload>>(
    types.GET_PRODUCT_SUPPLIER.BASE,
    getProductSuppliersWorker,
  );
}

export default function* productSupplierSaga(): Generator {
  try {
    yield all([getProductSuppliersWatcher()]);
  } catch (error) {
    logCritical(error as any);
  }
}
