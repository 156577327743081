import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';

import { Input } from '../../atoms/Form';
import { isRoundNumber } from '../../helpers';
import {
  ChevronFirstIcon,
  ChevronLastIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../../protons/icons';

import { PaginationButton, PaginationContainer } from './Pagination.styles';

interface PaginationProps {
  onChange: (page: number) => void;
  total?: number;
  current?: number;
  centered?: boolean;
  dataTestId?: string;
}

/**
 * @deprecated Use Pagination from @savgroup-front-common/atoms instead
 */
const Pagination: FunctionComponent<PaginationProps> = ({
  current = 1,
  onChange,
  total = 1,
  centered = true,
  dataTestId = 'pagination',
}): ReactElement => {
  const [value, setValue] = useState<number>(Number(current));
  const roundedTotal = Math.ceil(total);

  const handlePageChange = (page: number) => {
    setValue(page);
    onChange(page);
  };

  useEffect(() => {
    if (value !== current && value.toString().length !== 0) {
      setValue(current);
    }
  }, [current, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nbr = Number(event.target.value);

    if (nbr === 0 || !isRoundNumber(event.target.value)) {
      return setValue(0);
    }

    if (value && (nbr < 1 || nbr > roundedTotal)) {
      return event.preventDefault();
    }

    setValue(nbr);

    return onChange(nbr);
  };

  const disableNext = roundedTotal === value;
  const disablePrev = Number(value) <= 1;

  return (
    <PaginationContainer $centered={centered} data-testid={dataTestId}>
      <PaginationButton
        type={BUTTON_TYPES.BUTTON}
        onClick={() => handlePageChange(1)}
        disabled={disablePrev}
      >
        <ChevronFirstIcon />
      </PaginationButton>
      <PaginationButton
        type={BUTTON_TYPES.BUTTON}
        onClick={() => handlePageChange((value || 1) - 1)}
        disabled={disablePrev}
      >
        <ChevronLeftIcon />
      </PaginationButton>
      <Input
        min={1}
        max={roundedTotal}
        onChange={handleChange}
        value={value}
        suffix={`/${roundedTotal}`}
        selectAllOnFocus
      />
      <PaginationButton
        data-testid={`${dataTestId}-nextPageButton`}
        type={BUTTON_TYPES.BUTTON}
        onClick={() => handlePageChange((value || 0) + 1)}
        disabled={disableNext}
      >
        <ChevronRightIcon />
      </PaginationButton>
      <PaginationButton
        type={BUTTON_TYPES.BUTTON}
        onClick={() => handlePageChange(roundedTotal)}
        disabled={disableNext}
      >
        <ChevronLastIcon />
      </PaginationButton>
    </PaginationContainer>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
