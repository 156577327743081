import { createSelector } from 'reselect';

import { ControlRootState } from '../ControlRootState';

const selectOwnerState = (state: ControlRootState) => state.owner;

export const selectOwners = createSelector(
  [selectOwnerState],
  (state) => state?.owners,
);
export const selectOwnerById = createSelector(
  [selectOwners, (_state: ControlRootState, ownerId: string) => ownerId],
  (state, ownerId) => state?.[ownerId]?.value,
);
