import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.shortcutsModal.title',
    defaultMessage: 'Shortcuts',
  },
  searchTitle: {
    id: 'view.shortcutsModal.searchTitle',
    defaultMessage: 'Main search',
  },
  listTitle: {
    id: 'view.shortcutsModal.listTitle',
    defaultMessage: 'List',
  },
  modalTitle: {
    id: 'view.shortcutsModal.modalTitle',
    defaultMessage: 'Modals',
  },
  tableHeaderShortcut: {
    id: 'view.shortcutsModal.tableHeader.shortcut',
    defaultMessage: 'Shortcuts',
  },
  tableHeaderLabel: {
    id: 'view.shortcutsModal.tableHeader.label',
    defaultMessage: 'Labels',
  },
  tableHeaderCondition: {
    id: 'view.shortcutsModal.tableHeader.condition',
    defaultMessage: 'Conditions',
  },
  searchUpLabel: {
    id: 'view.shortcutsModal.labels.search.up',
    defaultMessage: 'Go up',
  },
  searchDowmLabel: {
    id: 'view.shortcutsModal.labels.search.down',
    defaultMessage: 'Go down',
  },
  searchSelectLabel: {
    id: 'view.shortcutsModal.labels.search.select',
    defaultMessage: 'Select',
  },
  searchCloseLabel: {
    id: 'view.shortcutsModal.labels.search.close',
    defaultMessage: 'Close',
  },
  searchOpenLabel: {
    id: 'view.shortcutsModal.labels.search.openUp',
    defaultMessage: 'Open search',
  },
  listOpenLabel: {
    id: 'view.shortcutsModal.labels.list.openUp',
    defaultMessage: 'Open/close list',
  },
  filterOpenLabel: {
    id: 'view.shortcutsModal.labels.filter.openUp',
    defaultMessage: 'Open/close filter',
  },

  createFileByOrderReference: {
    id: 'view.shortcutsModal.labels.modals.createFileByOrderReference',
    defaultMessage: 'Open create file by order reference modal',
  },
  createFileByProductReference: {
    id: 'view.shortcutsModal.labels.modals.createFileByProductReference',
    defaultMessage: 'Open create file by product reference modal',
  },
  createFileByTicketReference: {
    id: 'view.shortcutsModal.labels.modals.createFileByTicketReference',
    defaultMessage: 'Open create file by ticket reference modal',
  },
  createFileByCustomerReference: {
    id: 'view.shortcutsModal.labels.modals.createFileByCustomerReference',
    defaultMessage: 'Open create file by customer reference modal',
  },
  createSparePartRequest: {
    id: 'view.shortcutsModal.labels.modals.createSparePartRequest',
    defaultMessage: 'Open create spare part request modal',
  },
  openShortcutModal: {
    id: 'view.shortcutsModal.labels.modals.openShortcutModal',
    defaultMessage: 'Open shortcuts modal',
  },

  conditionSearchIsOpened: {
    id: 'view.shortcutsModal.condition.searchIsOpened',
    defaultMessage: 'Search is opened',
  },

  conditionsearchIsClosed: {
    id: 'view.shortcutsModal.condition.searchIsClosed',
    defaultMessage: 'Search is closed',
  },
});
