import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ImportReport,
} from '@savgroup-front-common/types';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';

export interface ImportReportValue {
  categories: ImportReport[];
  hasErrors: boolean;
}

interface ImportReportArgs {
  draftId: string;
}

const ENDPOINT = `${APIConfiguration.import}drafts/${COMMON_QUERY_PARAMS.DRAFT_ID}/importreport`;

export async function getImportReport(
  payload: ImportReportArgs,
): Promise<BackResponse<ImportReportValue> | BackResponseFailure> {
  const { draftId } = payload;

  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.DRAFT_ID, draftId);
    const response = await apiCall<BackResponse<ImportReportValue>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
