import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.marketplace}connectorStatus`;

interface GetConnectorStatusResponse extends BaseBackResponse {
  failure: false;
  value: {
    apiConnector: string;
    error:
      | 'None'
      | 'ConnectivityProblem'
      | 'InvalidCredentials'
      | 'Unknown'
      | 'InvalidTokenFormat'
      | 'OperationNotAllowed'
      | 'NoTokenConfigured'
      | 'QuotaExceeded'
      | 'HolidayMode';
    estimatedNextAttemptTimeUtc: string;
    seller: string;
  }[];
}

interface GetConnectorStatusResponseError extends BaseBackResponse {
  failure: true;
}

async function getConnectorStatus(): Promise<
  GetConnectorStatusResponse | GetConnectorStatusResponseError
> {
  try {
    const response = await apiCall<GetConnectorStatusResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return { failure: true, errors: (exception as any).errors };
  }
}

export default getConnectorStatus;
