import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  SellerBillingConfiguration,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/configuration/billing`;

interface SetSellerBillingConfigurationQueryPayload {
  sellerId: string;
  payload: SellerBillingConfiguration;
}

const setSellerBillingConfigurationQuery = async ({
  sellerId,
  payload,
}: SetSellerBillingConfigurationQueryPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: payload,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default setSellerBillingConfigurationQuery;
