/* eslint-disable max-len */
import { createRequest } from '@savgroup-front-common/core/src/services/request';

enum TYPE {
  GET_STOCK_ITEM_DETAILS = 'stocks/stockItemDetails/GET_STOCK_ITEM_DETAILS',
  GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE = 'stocks/stockItemDetails/GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE',
  UPDATE_STOCK_ITEM_STATUS_DETAILS = 'stocks/stockItemDetails/UPDATE_STOCK_ITEM_STATUS_DETAILS',
}

export const GET_STOCK_ITEM_DETAILS = createRequest(
  TYPE.GET_STOCK_ITEM_DETAILS,
);

export const UPDATE_STOCK_ITEM_STATUS_DETAILS = createRequest(
  TYPE.UPDATE_STOCK_ITEM_STATUS_DETAILS,
);

export const GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE = createRequest(
  TYPE.GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE,
);
