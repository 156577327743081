import { useMemo } from 'react';

import { MenuItem } from '@savgroup-front-common/core/src/atoms/Menu';
import { useAuthorizedCreateModalTypes } from '@savgroup-front-common/core/src/hooks';

import { getAuthorizedOptions } from '../CreateButton.helpers';

const useAuthorizedCreateOptions = ({
  onCreateModalOpen,
}: {
  onCreateModalOpen: (menuItem: MenuItem) => void;
}): MenuItem[] => {
  const authorizations = useAuthorizedCreateModalTypes();

  return useMemo(() => {
    return getAuthorizedOptions(authorizations, onCreateModalOpen);
  }, [authorizations, onCreateModalOpen]);
};

export default useAuthorizedCreateOptions;
