import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { Client } from '@savgroup-front-common/types/src/Billing/Client';

export const ENDPOINT = `${APIConfiguration.owner}clients/${COMMON_QUERY_PARAMS.CLIENT_ID}`;

interface GetClientByIdQueryArgs {
  clientId: string;
}

const getClientByIdQuery = async ({
  clientId,
}: GetClientByIdQueryArgs): Promise<
  BackResponse<Client> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLIENT_ID, clientId);

    const response = await apiCall<BackResponse<Client>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClientByIdQuery;
