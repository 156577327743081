import {
  AppScheduledMaintenance,
  SCHEDULED_MAINTENANCE_STATUSES,
} from '@savgroup-front-common/types';

import {
  displayCompletedScheduledMaintenance,
  displayInProgressScheduledMaintenance,
  displayNewScheduledMaintenance,
  displayRescheduledMaintenance,
} from './toastList';

const processScheduleMaintenance = (
  previousScheduledMaintenances: AppScheduledMaintenance[],
  newScheduledMaintenance: AppScheduledMaintenance,
  componentName: string,
): void => {
  const isCurrentComponent = newScheduledMaintenance.components.some(
    (component) => component.name === componentName,
  );

  if (!isCurrentComponent) {
    return;
  }

  const previousScheduledMaintenance = previousScheduledMaintenances.find(
    (appStatus) => newScheduledMaintenance.id === appStatus.id,
  );

  if (previousScheduledMaintenance) {
    const lastUpdate = previousScheduledMaintenance.incidentUpdates[0];
    const lastScheduledFor = previousScheduledMaintenance.scheduledFor;
    const lastScheduledUntil = previousScheduledMaintenance.scheduledUntil;
    const newScheduledFor = newScheduledMaintenance.scheduledFor;
    const newScheduledUntil = newScheduledMaintenance.scheduledUntil;

    const newUpdate = newScheduledMaintenance.incidentUpdates[0];

    if (
      lastScheduledFor !== newScheduledFor ||
      lastScheduledUntil !== newScheduledUntil
    ) {
      displayRescheduledMaintenance(newScheduledMaintenance);

      return;
    }

    if (lastUpdate.id === newUpdate.id) {
      return;
    }

    if (
      newScheduledMaintenance.status ===
      SCHEDULED_MAINTENANCE_STATUSES.COMPLETED
    ) {
      displayCompletedScheduledMaintenance(newScheduledMaintenance);

      return;
    }
  }

  if (
    newScheduledMaintenance.status ===
    SCHEDULED_MAINTENANCE_STATUSES.IN_PROGRESS
  ) {
    displayInProgressScheduledMaintenance(newScheduledMaintenance);

    return;
  }

  if (
    newScheduledMaintenance.status === SCHEDULED_MAINTENANCE_STATUSES.SCHEDULED
  ) {
    displayNewScheduledMaintenance(newScheduledMaintenance);
  }
};

export default processScheduleMaintenance;
