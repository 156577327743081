import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import AddressSuggestionSchema from '../AddressSuggestion.schema';
import {
  ADDRESS_SUGGESTION_ORIGIN,
  AddressSuggestionFormValues,
} from '../AddressSuggestion.types';
import useAddressSuggestionStepContext from './useAddressSuggestionStepContext';
import { useState } from 'react';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

const useAddressSuggestionForm = ({
  haveOwnerAddressSuggestion,
  haveOwnerAddressOnFileSuggestion,
}: {
  haveOwnerAddressSuggestion: boolean;
  haveOwnerAddressOnFileSuggestion: boolean;
}) => {
  const formContext = useForm<AddressSuggestionFormValues>({
    defaultValues: {
      ownerAddressToKeep: ADDRESS_SUGGESTION_ORIGIN.SUGGESTION,
      ownerAddressOnFileToKeep: ADDRESS_SUGGESTION_ORIGIN.SUGGESTION,
    },
    resolver: yupResolver(
      AddressSuggestionSchema({
        haveOwnerAddressSuggestion,
        haveOwnerAddressOnFileSuggestion,
      }),
    ),
    shouldUnregister: true,
  });
  const { handleSubmit } = formContext;

  const {
    values,
    onSubmit,
    context: { couldEditAddressOnFile },
  } = useAddressSuggestionStepContext();
  const [isLoading, setIsLoading] = useState(false);
  const { pushErrors } = useBanners();

  const handleAddressSuggestionSubmit = handleSubmit(
    async ({ ownerAddressToKeep, ownerAddressOnFileToKeep }) => {
      setIsLoading(true);

      try {
        if (!couldEditAddressOnFile) {
          if (
            ownerAddressToKeep === ADDRESS_SUGGESTION_ORIGIN.SUGGESTION &&
            values.ownerAddress.suggestedAddress
          ) {
            onSubmit({
              ownerAddress: { address: values.ownerAddress.suggestedAddress },
            });

            return undefined;
          }

          if (ownerAddressToKeep === ADDRESS_SUGGESTION_ORIGIN.USER_VALUES) {
            onSubmit();

            return undefined;
          }
        }

        if (
          ownerAddressToKeep === ADDRESS_SUGGESTION_ORIGIN.USER_VALUES &&
          ownerAddressOnFileToKeep === ADDRESS_SUGGESTION_ORIGIN.USER_VALUES
        ) {
          onSubmit();

          return undefined;
        }

        let ownerAddress = values.ownerAddress.address;
        let ownerAddressOnFile = values.ownerAddressOnFile.address;

        if (
          ownerAddressToKeep === ADDRESS_SUGGESTION_ORIGIN.SUGGESTION &&
          values.ownerAddress.suggestedAddress
        ) {
          ownerAddress = values.ownerAddress.suggestedAddress;
        }
        if (
          ownerAddressOnFileToKeep === ADDRESS_SUGGESTION_ORIGIN.SUGGESTION &&
          values.ownerAddressOnFile.suggestedAddress
        ) {
          ownerAddressOnFile = values.ownerAddressOnFile.suggestedAddress;
        }

        onSubmit({
          ownerAddress: { address: ownerAddress },
          ownerAddressOnFile: { address: ownerAddressOnFile },
        });

        return undefined;
      } catch (errors) {
        pushErrors(errors as Error[]);
        setIsLoading(false);

        return undefined;
      }
    },
  );

  return { formContext, handleAddressSuggestionSubmit, isLoading };
};

export default useAddressSuggestionForm;
