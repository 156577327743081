import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const GENERATE_DEMO_ORDER_ENDPOINT = `${APIConfiguration.owner}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/demoOrder`;

interface GenerateDemoOrderCommandArgs {
  ownerId?: string;
  modelTypeId?: string;
  sellerId: string;
}

export const generateDemoOrderCommand = async ({
  modelTypeId,
  ownerId,
  sellerId,
}: GenerateDemoOrderCommandArgs): Promise<
  BackResponse<void> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<void>>(
      GENERATE_DEMO_ORDER_ENDPOINT.replace(
        COMMON_QUERY_PARAMS.SELLER_ID,
        sellerId,
      ),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          modelTypeId,
          ownerId,
        },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
