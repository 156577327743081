import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { createGenericContext } from '@savgroup-front-common/core/src/helpers';
import { FileSummary } from '@savgroup-front-common/types';
import { TABS_FILE } from 'control/constants/routing';

import * as fileDetailsSelectors from '../../../domains/fileInfo/fileDetails';

import { GraphQlFileSummary } from './FileInfoNewUi/FileInfoNewUi.types';

export interface FileInfoContextArgs {
  buildUrl: (arg?: { fileId: string; fileTabId?: TABS_FILE }) => string;
  fileId: string;
  file: FileSummary;
  fileSummary?: GraphQlFileSummary;
  handleRefetchFullSummary: () => Promise<void>;
}

const [useFileInfoContext, FileInfoProvider] =
  createGenericContext<FileInfoContextArgs>();

interface FileInfoContextProviderProps {
  buildUrl: (arg?: { fileId: string; fileTabId?: TABS_FILE }) => string;
  fileSummary?: GraphQlFileSummary;
  onRefetchFullSummary?: () => Promise<void>;
}

const FileInfoContextProvider: FunctionComponent<
  React.PropsWithChildren<FileInfoContextProviderProps>
> = ({ children, buildUrl, onRefetchFullSummary, fileSummary }) => {
  const { fileId } = useParams<{ fileId: string }>();
  const file = useSelector(fileDetailsSelectors.currentFileSelector);

  if (!file) {
    return <></>;
  }

  return (
    <FileInfoProvider
      value={{
        buildUrl,
        fileId,
        file,
        fileSummary,
        handleRefetchFullSummary:
          onRefetchFullSummary ?? (() => Promise.resolve()),
      }}
    >
      {children}
    </FileInfoProvider>
  );
};

FileInfoContextProvider.displayName = 'FileInfoContextProvider';

export { FileInfoContextProvider, useFileInfoContext };
