import map from 'lodash/map';
import matches from 'lodash/matches';
import uniqBy from 'lodash/uniqBy';
import { matchPath } from 'react-router-dom';
import { all, put, race, select, take } from 'redux-saga/effects';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ActionCreators as ClaimsActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import { loadTranslation } from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import * as ClaimsActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';
import { storeActionCreators } from '@savgroup-front-common/core/src/domains/store';
import { selectGetStoreBySellerIdIsReadyToCall } from '@savgroup-front-common/core/src/domains/store/selectors';
import { GET_FILE_FULL_SUMMARY } from 'control/domains/workflow/fileSummary/actions';
import { getFileSummaryValue } from 'control/domains/workflow/fileSummary/selectors';

import { FILE_ROUTES } from '../../../constants/routing';

export default function* summaryTabSaga() {
  const path = yield select(pathname);
  const match = matchPath(path, {
    path: FILE_ROUTES.SUMMARY,
    exact: true,
    strict: false,
  });
  const { fileId } = match.params;

  const { isLoaded } = yield select(getFileSummaryValue, { fileId });

  if (!isLoaded) {
    const [, errors] = yield race([
      take(
        matches({
          type: GET_FILE_FULL_SUMMARY.SUCCEEDED,
          meta: { fileId },
        }),
      ),
      take(
        matches({
          type: GET_FILE_FULL_SUMMARY.ERRORED,
          meta: { fileId },
        }),
      ),
    ]);

    if (errors) {
      logError(`GET_FILE_FULL_SUMMARY errored.`, errors);
    }
  }

  const { value: fileSummary } = yield select(getFileSummaryValue, { fileId });

  const fileProducts = fileSummary?.fileProducts || [];
  const sellerId = fileSummary?.sellerId;

  const isGetStoresBySellerIdIsReadyToCall = yield select(
    selectGetStoreBySellerIdIsReadyToCall,
    { sellerId },
  );

  if (isGetStoresBySellerIdIsReadyToCall) {
    yield put(storeActionCreators.getStoresBySellerId({ sellerId }));
  }

  yield all([
    put(
      ClaimsActionCreators.loadClaimsByGroupId(
        fileSummary.claimInfoSummary.claimGroupId,
      ),
    ),
    take(ClaimsActionTypes.LOAD_CLAIMS_BY_GROUP_ID.END),
  ]);

  const irshIds = uniqBy(
    map(
      fileProducts,
      ({
        issueId,
        reasonId,
        solutionTypeId,
        warrantyTypeId,
        ownerProductId,
      }) => ({
        issueId,
        reasonId,
        solutionId: solutionTypeId,
        warrantyTypeId,
        ownerProductId,
      }),
    ),
    JSON.stringify,
  );

  if (fileSummary) {
    yield put(
      loadTranslation({
        warrantyTypeId: fileSummary.warrantyTypeId,
      }),
    );
  }

  for (const irsh of irshIds) {
    yield put(loadTranslation(irsh));
  }
}
