import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}`;

export async function deleteClaimContext({ claimId }) {
  if (!claimId) {
    throw new Error('Could not call deleteClaimContext without a claimId.');
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  return apiCall(url, {
    method: SUPPORTED_METHODS.DELETE,
  });
}
