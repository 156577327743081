import { defineMessages } from 'react-intl';

export default defineMessages({
  noResultsTitle: {
    id: 'view.search.noResultsTitle',
    defaultMessage: 'Sad no result!',
  },
  noResultsText: {
    id: 'view.search.noResultsText',
    defaultMessage:
      'We cannot find the item you are searching. Easily search for a file or an order by using file reference, order reference, tracking number or customer informations (name / phone / email)',
  },
});
