import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { useToggle } from 'react-use';

import { useFunctionOnEscape } from '@savgroup-front-common/core/src/hooks';

import { useDocumentData, useDocumentIndex } from './DocumentPreview.hooks';
import { DocumentPreviewAttachment } from './DocumentPreview.types';
import Header from './Header';
import DocumentPreviewNavigation from './Navigation';
import DocumentPreviewObject from './Preview';

interface DocumentPreviewContentProps {
  onClose: () => void;
  attachments: DocumentPreviewAttachment[];
  currentActivePreview?: number;
  isNavigationEnabled: boolean;
}

const DocumentPreviewContent: FunctionComponent<
  React.PropsWithChildren<DocumentPreviewContentProps>
> = ({ onClose, attachments, currentActivePreview, isNavigationEnabled }) => {
  const { activePreview, animationType, handleChange } = useDocumentIndex({
    currentActivePreview,
  });
  const attachment = get(attachments, activePreview);
  const { documentName, blobDocumentUrl, blobDocumentType } = useDocumentData({
    attachment,
  });

  const [isZoomed, toggleZoomed] = useToggle(false);

  useFunctionOnEscape(onClose);

  return (
    <>
      <Header
        name={documentName}
        url={blobDocumentUrl}
        type={blobDocumentType}
        onClose={onClose}
        isZoomed={isZoomed}
        onZoom={toggleZoomed}
        animationType={animationType}
      />
      <DocumentPreviewNavigation
        isZoomed={isZoomed}
        count={attachments.length}
        activePreview={activePreview}
        onChange={handleChange}
        animationType={animationType}
        isNavigationEnabled={isNavigationEnabled}
      >
        <DocumentPreviewObject
          name={documentName}
          type={blobDocumentType}
          url={blobDocumentUrl}
        />
      </DocumentPreviewNavigation>
    </>
  );
};

DocumentPreviewContent.displayName = 'DocumentPreviewContent';

export default DocumentPreviewContent;
