import get from 'lodash/get';

import * as ActionTypes from './actionTypes';

const initialState = {};

function getAdditionalInformationEntitiesSummariesSuccess(state, { payload }) {
  const value = get(payload, ['value'], []);

  return value.reduce((acc, value) => {
    const { id } = value;

    return {
      ...acc,
      [id]: value,
    };
  }, state);
}

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ADDITIONAL_INFORMATION_ENTITIES_SUMMARIES.SUCCEEDED:
      return getAdditionalInformationEntitiesSummariesSuccess(state, action);

    default:
      return state;
  }
}
