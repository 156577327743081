import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}models/${COMMON_QUERY_PARAMS.MODEL_ID}/repairable`;

interface GetRepairableByModelIdPayload {
  id: string;
}

interface GetRepairableByModelIdResponse extends BaseBackResponse {
  value: boolean;
}

async function getRepairableByModelId({
  id,
}: GetRepairableByModelIdPayload): Promise<boolean> {
  const functionName = 'getRepairableByModelId';

  if (!id) {
    throw new RequiredPayloadFieldIsNotProvided(id, `${functionName}`);
  }
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.MODEL_ID, id);

  const response = await apiCall<GetRepairableByModelIdResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  return response.value;
}

export default getRepairableByModelId;
