export const QUERY_PARAMS = Object.freeze({
  TAB: 'tab',
  FILTER_BY: 'filterBy',
  PRIORITY: 'priority',
  SEARCH: 'search',
});

export const FILTERS = {
  SPARE_PART_REQUEST_LINES: 'SPARE_PART_REQUEST_LINES_FILTER',
  FILES: 'FILES_FILTER',
  MESSAGES: 'MESSAGES_FILTER',
  TODOS: 'TODOS_FILTER',
};
