import styled from 'styled-components';

export const $GoToSelfcareButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  > a {
    height: 32px;
    min-height: 32px;
  }
`;
