import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  ADDITIONAL_INFORMATION_TYPES,
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
  DocumentType,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/${COMMON_QUERY_PARAMS.ENTITY_TYPE}/minimal`;

enum AdditionalInformationRelatedTo {
  PRODUCT = 'Product',
  CLAIM = 'Claim',
}

interface CreateEntityMinimalCommandArg {
  sellerId: string;
  entityType: string;
  payload:
    | {
        uniqueName?: string;
        treeUniqueName?: string;
      }
    | {
        uniqueName?: string;
        isRequired: boolean;
        relatedTo: AdditionalInformationRelatedTo;
        type: ADDITIONAL_INFORMATION_TYPES;
        subType: DocumentType;
        enumValues?: string[];
        didactics?: string;
      }
    | {
        uniqueName?: string;
        actorType: ACTOR_TYPES;
        address: Partial<AddressInfoDto>;
        mail?: string;
        phone?: string;
      };
}

interface CreationEntityMinimalResponse {
  sellerId: string;
  tenantId: string;
  treeUniqueName: string;
  type: string;
  uniqueName: string;
}

async function createEntityMinimalCommand({
  sellerId,
  entityType,
  payload,
}: CreateEntityMinimalCommandArg): Promise<
  BackResponse<CreationEntityMinimalResponse> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<CreationEntityMinimalResponse>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
        COMMON_QUERY_PARAMS.ENTITY_TYPE,
        entityType,
      ),
      { method: SUPPORTED_METHODS.POST, json: payload },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createEntityMinimalCommand;
