import { createAction } from '@reduxjs/toolkit';

import { FORM_MODE } from '@savgroup-front-common/constants';

import {
  CREATE_USER_MANAGEMENT,
  DELETE_USER_PROFIL,
  GET_ASSIGNABLE_PERMISSIONS,
  GET_CONFIGURABLE_ROLES,
  GET_MANAGEMENT_USERS,
  GET_USER_PROFILES,
  SET_USER_PROFIL,
} from './actionTypes';
import { UserRoleOrUserPermission } from './types';

export const getManagementUsers = createAction(GET_MANAGEMENT_USERS.BASE);
export const getConfigurableRoles = createAction(GET_CONFIGURABLE_ROLES.BASE);

export interface SetUserPayload {
  userId: string;
  lastname: string;
  firstname: string;
  email: string;
  credentials: UserRoleOrUserPermission[];
  type: FORM_MODE;
}
export const setUserActionCreator = createAction<SetUserPayload>(
  CREATE_USER_MANAGEMENT.SUCCEEDED,
);

export interface SetUserProfilePayload {
  userProfileId: string;
  name: string;
  tenantId: string;
  roleIds: string[];
  cumulDashboardIds: string[];
  permissions: string[];
}
export const setUserProfile = createAction<SetUserProfilePayload>(
  SET_USER_PROFIL.BASE,
);

export interface GetUserProfilesPayload {
  tenantId: string;
}
export const getUserProfiles = createAction<GetUserProfilesPayload>(
  GET_USER_PROFILES.BASE,
);
export const getAssignablePermissions = createAction(
  GET_ASSIGNABLE_PERMISSIONS.BASE,
);
export const deleteUserProfile = createAction<{ userProfileId: string }>(
  DELETE_USER_PROFIL.BASE,
);
