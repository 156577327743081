import { createRequest } from '@savgroup-front-common/core/src/services/request';

enum TYPE {
  GET_REQUEST_LINE_DETAILS = 'SPARE_PART/REQUEST_LINES/GET_REQUEST_LINE_DETAILS',
  UPDATE_REQUEST_LINE_DETAILS_STATUS = 'SPARE_PART/REQUEST_LINES/UPDATE_REQUEST_LINE_DETAILS_STATUS',
}

export const GET_SPARE_PART_REQUEST_LINE_DETAILS = createRequest(
  TYPE.GET_REQUEST_LINE_DETAILS,
);
export const UPDATE_SPARE_PART_REQUEST_LINE_DETAILS_STATUS = createRequest(
  TYPE.UPDATE_REQUEST_LINE_DETAILS_STATUS,
);
