import { AnyAction } from 'redux';

import { GET_SPARE_PART_REQUEST_LINES_BY_FILE_ID } from './actionTypes';
import { FILE_SPARE_PART_REQUEST_LINES_DOMAIN } from './constants';
import {
  GetSparePartRequestLinesByFileIdResponse,
  SparePartFileRequestLineDetailsDomainState,
} from './types';

export const initialFileSparePartRequestLinesState: SparePartFileRequestLineDetailsDomainState =
  {
    [FILE_SPARE_PART_REQUEST_LINES_DOMAIN.LIST]: {},
    [FILE_SPARE_PART_REQUEST_LINES_DOMAIN.BY_FILE_ID]: {},
  };

interface OnGetSparePartRequestLinesByFileIdSucceededAction extends AnyAction {
  payload: GetSparePartRequestLinesByFileIdResponse;
}
function onGetSparePartRequestLinesByFileId(
  state: SparePartFileRequestLineDetailsDomainState,
  { payload, meta }: OnGetSparePartRequestLinesByFileIdSucceededAction,
) {
  const { value = [] } = payload;
  const { indexer: fileId } = meta;

  return {
    ...state,
    [FILE_SPARE_PART_REQUEST_LINES_DOMAIN.LIST]: value.reduce(
      (acc, fileSparePartRequestLine) => {
        return {
          ...acc,
          [fileSparePartRequestLine.requestLineId]: fileSparePartRequestLine,
        };
      },
      state[FILE_SPARE_PART_REQUEST_LINES_DOMAIN.LIST],
    ),
    [FILE_SPARE_PART_REQUEST_LINES_DOMAIN.BY_FILE_ID]: {
      ...state[FILE_SPARE_PART_REQUEST_LINES_DOMAIN.BY_FILE_ID],
      [fileId]: value.map(
        (fileSparePartRequestLine) => fileSparePartRequestLine.requestLineId,
      ),
    },
  };
}

export default function fileRequestLinesReducer(
  state: SparePartFileRequestLineDetailsDomainState = initialFileSparePartRequestLinesState,
  action: AnyAction,
): SparePartFileRequestLineDetailsDomainState {
  switch (action.type) {
    case GET_SPARE_PART_REQUEST_LINES_BY_FILE_ID.SUCCEEDED: {
      return onGetSparePartRequestLinesByFileId(
        state,
        action as OnGetSparePartRequestLinesByFileIdSucceededAction,
      );
    }
    default: {
      return state;
    }
  }
}
