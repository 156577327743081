import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { LOAD_SELLERS_INFO } from './actionTypes';

function* loadSellersWorker(): Generator {
  yield call(
    callAndGetResponse,
    LOAD_SELLERS_INFO,
    `${APIConfiguration.config}sellers`,
    { method: SUPPORTED_METHODS.GET },
  );

  yield put(LOAD_SELLERS_INFO.end());
}

function* loadSellersWatcher() {
  yield takeEvery(LOAD_SELLERS_INFO.BASE, loadSellersWorker);
}

export default function* mainSaga(): Generator {
  try {
    yield all([loadSellersWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
