import flatMap from 'lodash/flatMap';
import some from 'lodash/some';

import rawAllowedUrls from './allowedUrls.json';

export const allowedUrls = flatMap(rawAllowedUrls, rawRegExps =>
  rawRegExps.map(exp => new RegExp(`^(https?://)?(?:${exp})(?:(/|#).*)?$`)),
).concat(/^mailto:/);

export function isUrlAllowed(url) {
  return some(allowedUrls, s => s.test(url));
}
