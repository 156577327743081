import { all } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { stockItemDetailsSaga } from './stockItemDetails';
import { stockItemListSaga } from './stockItemList';

export default function* main(): Generator {
  try {
    yield all([stockItemListSaga(), stockItemDetailsSaga()]);
  } catch (error) {
    logCritical(error);
  }
}
