import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  DraftHistoryStatus,
  IncludedFile,
  SellerId,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/drafts/active`;

export interface DraftSummaryDto {
  id: string;
  name: string;
  status: DraftHistoryStatus;
  creationDate: string;
  includedFiles: IncludedFile[];
  authorId: string;
}

interface GetActiveDraftQueryPayload {
  sellerId: SellerId;
}

type GetActiveDraftQuery = (
  payload: GetActiveDraftQueryPayload,
) => Promise<BackResponse<DraftSummaryDto> | BackResponseFailure>;

const getActiveDraftQuery: GetActiveDraftQuery = async ({ sellerId }) => {
  try {
    return await apiCall<BackResponse<DraftSummaryDto>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getActiveDraftQuery;
