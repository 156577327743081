import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Link } from '@savgroup-front-common/core/src/atoms/link';

export const $Backlink = styled(Link)`
  display: flex;
  align-items: center;
  @media ${media.minWidth.lg} {
    display: none;
  }
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
