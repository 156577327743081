import { createSelector } from 'reselect';

import { CURRENCIES } from '@savgroup-front-common/constants';
import {
  AdditionalInformation,
  FileSummary,
  OrderDetails,
} from '@savgroup-front-common/types';

import { ControlRootState } from '../ControlRootState';
import { getFileByFileId } from '../fileInfo/fileDetails';
import { selectOrderById } from '../orders/selectors';

export const productsInfoSelectorCombiner = (
  file?: FileSummary,
  order?: Omit<OrderDetails, 'deliveryAddress'>,
): {
  totalPrice: number;
  totalShippingPrice: number;
  currency: CURRENCIES;
  totalQuantity: number;
  products: {
    price: number;
    shippingPrice: number;
    currency: CURRENCIES;
    sellerProductId: string;
    ownerProductId: string;
    quantity: number;
    modelTypeId: string;
    brandName: string;
    modelName: string;
    modelTypeName: string;
    modelId: string;
    sku: string;
    ean: string;
    orderLineReference: string;
    fileAdditionalInformation: AdditionalInformation[];
    additionalInformation: AdditionalInformation[];
    claimId?: string;
    modelDisplayName?: string;
    brandDisplayName: string;
  }[];
} => {
  const orderProducts = order?.products || [];
  const fileProducts = file?.fileProducts || [];

  const mergedOrderAndFileProducts = orderProducts
    .filter(({ orderLineReference }) =>
      fileProducts.find(
        (fileProduct) => fileProduct.orderLineReference === orderLineReference,
      ),
    )
    .map((product) => {
      const { orderLineReference } = product;
      const fileProduct = fileProducts.find(
        (fileProduct) => fileProduct.orderLineReference === orderLineReference,
      );

      return {
        ...(fileProduct || { modelDisplayName: '', brandDisplayName: '' }),
        fileAdditionalInformation: fileProduct?.fileAdditionalInformation || [],
        additionalInformation: fileProduct?.additionalInformation || [],
        ...product,
      };
    });

  if (mergedOrderAndFileProducts.length === 0) {
    return {
      products: [],
      totalPrice: 0,
      totalQuantity: 0,
      totalShippingPrice: 0,
      currency: CURRENCIES.EUR,
    };
  }

  const products = mergedOrderAndFileProducts.map(
    ({
      price,
      shippingPrice,
      priceCurrencyCode,
      sellerProductId,
      ownerProductId,
      modelTypeId,
      modelTypeName,
      brandName,
      modelName,
      modelId,
      sku,
      ean,
      orderLineReference = '',
      fileAdditionalInformation = [],
      additionalInformation = [],
      claimId,
      modelDisplayName,
      brandDisplayName,
    }) => {
      return {
        price,
        shippingPrice,
        currency: priceCurrencyCode as CURRENCIES,
        sellerProductId,
        ownerProductId,
        quantity: 1,
        modelTypeId,
        brandName,
        modelName,
        modelTypeName,
        modelId,
        sku,
        ean,
        orderLineReference: orderLineReference.toUpperCase(),
        fileAdditionalInformation,
        additionalInformation,
        claimId,
        modelDisplayName,
        brandDisplayName,
      };
    },
  );

  const totalPrice = products.reduce((acc, product) => {
    return acc + product.price;
  }, 0);
  const totalShippingPrice = products.reduce((acc, product) => {
    return acc + product.shippingPrice;
  }, 0);
  const currency = order?.totalPriceCurrencyCode || CURRENCIES.EUR;
  const totalQuantity = products.reduce((acc, product) => {
    return acc + product.quantity;
  }, 0);

  return {
    totalPrice,
    totalShippingPrice,
    currency,
    totalQuantity,
    products,
  };
};

export const selectProductsInfoByFileIdAndOrderId = createSelector(
  [
    ((
      state: ControlRootState,
      { fileId }: { fileId?: string; orderId?: string },
    ) => getFileByFileId(state, { fileId })) as any,
    (
      state: ControlRootState,
      { orderId }: { fileId?: string; orderId?: string },
    ) => selectOrderById(state, { orderId }),
  ],
  productsInfoSelectorCombiner,
);
