import { useCallback } from 'react';
import { useMedia, useToggle } from 'react-use';

import { media } from '@savgroup-front-common/constants/src/shared';

const useMobileMenu = () => {
  const activate = useMedia(media.maxWidth.xs);

  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
  const onMenuToggle = useCallback(() => {
    if (activate) {toggleIsMenuOpen();}
  }, [activate, toggleIsMenuOpen]);

  return {
    isMenuOpen,
    onMenuToggle,
  };
};

export default useMobileMenu;
