import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';

const ENDPOINT = `${APIConfiguration.payment}payment/billing/${COMMON_QUERY_PARAMS.BILLING_ID}/regenerate`;

export interface RegenerateBillingDocumentCommandArgs {
  billingId: string;
}

export default async function regenerateBillingDocumentCommand({
  billingId,
}: RegenerateBillingDocumentCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.BILLING_ID, billingId),
      {
        method: SUPPORTED_METHODS.POST,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
