import { TransitionSummary } from '@savgroup-front-common/types';

export const mapTransitions = (transitions: TransitionSummary[]) =>
  transitions.map((transition) => ({
    label: transition.label,
    targetState: {
      name: transition.targetState.name,
      shortLabel: transition.targetState.shortLabel,
      label: transition.targetState.label,
    },
    required: transition.required,
    isCommentMandatory: transition.isCommentMandatory,
    expects: {
      mandatories: transition.expects ? transition.expects.mandatories : [],
      optionals: transition.expects ? transition.expects.optionals : [],
    },
  }));
