import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import {
  GetUserProfilesPayload,
  SetUserProfilePayload,
} from './actionCreators';
import * as ActionTypes from './actionTypes';
import { DEFAULT_ENTITY_TYPE } from './constants';
import {
  getAssignablePermissionsIsLoadingSelector,
  getConfigurableRolesIsLoading,
  getUserManagementIsLoadingSelector,
  selectGetUserProfilByTenantIsLoading,
} from './selectors';

function* getManagementUsersWorker() {
  const isLoading: ReturnType<typeof getUserManagementIsLoadingSelector> =
    yield select((state) =>
      getUserManagementIsLoadingSelector(state, {
        entityType: DEFAULT_ENTITY_TYPE,
      }),
    );

  if (!isLoading) {
    yield call(
      callAndGetResponse,
      ActionTypes.GET_MANAGEMENT_USERS,
      `${APIConfiguration.authorization}users/management/${DEFAULT_ENTITY_TYPE}`,
      { method: SUPPORTED_METHODS.GET },
      { indexer: DEFAULT_ENTITY_TYPE },
    );
    yield put(ActionTypes.GET_MANAGEMENT_USERS.end());
  }
}
function* getManagementUsersWatcher() {
  yield takeEvery<Action<void>>(
    ActionTypes.GET_MANAGEMENT_USERS.BASE,
    getManagementUsersWorker,
  );
}

function* getConfigurableRolesWorker() {
  const isLoading: ReturnType<typeof getConfigurableRolesIsLoading> =
    yield select((state) =>
      getConfigurableRolesIsLoading(state, { entityType: DEFAULT_ENTITY_TYPE }),
    );

  if (!isLoading) {
    yield call(
      callAndGetResponse,
      ActionTypes.GET_CONFIGURABLE_ROLES,
      `${APIConfiguration.authorization}roles/configurable/${DEFAULT_ENTITY_TYPE}`,
      { method: SUPPORTED_METHODS.GET },
      { indexer: DEFAULT_ENTITY_TYPE },
    );
    yield put(ActionTypes.GET_CONFIGURABLE_ROLES.end());
  }
}
function* getConfigurableRolesWatcher() {
  yield takeEvery(
    ActionTypes.GET_CONFIGURABLE_ROLES.BASE,
    getConfigurableRolesWorker,
  );
}

function* setUserProfilWorker({
  payload: {
    userProfileId,
    name,
    tenantId,
    roleIds,
    cumulDashboardIds,
    permissions,
  },
}: Action<SetUserProfilePayload>) {
  const json = {
    userProfileId,
    name,
    tenantId,
    roleIds,
    cumulDashboardIds,
    permissions,
  };

  yield call(
    callAndGetResponse,
    ActionTypes.SET_USER_PROFIL,
    `${APIConfiguration.authorization}users/upsertUserProfile`,
    {
      method: SUPPORTED_METHODS.POST,
      json,
    },
    { indexer: userProfileId },
  );

  yield put(ActionTypes.SET_USER_PROFIL.end());
}
function* setUserProfilWatcher() {
  yield takeEvery<Action<SetUserProfilePayload>>(
    ActionTypes.SET_USER_PROFIL.BASE,
    setUserProfilWorker,
  );
}

function* getAllUserProfilesWorker({
  payload,
}: Action<GetUserProfilesPayload>) {
  const { tenantId } = payload;
  const isLoading: ReturnType<typeof selectGetUserProfilByTenantIsLoading> =
    yield select((state) =>
      selectGetUserProfilByTenantIsLoading(state, { tenantId }),
    );
  const url = buildUrl(
    `${APIConfiguration.authorization}users/listUserProfile`,
    {
      tenantId,
    },
    { skipNulls: true },
  );

  const meta = { indexer: tenantId };

  if (!isLoading) {
    yield call(
      callAndGetResponse,
      ActionTypes.GET_USER_PROFILES,
      url,
      { method: SUPPORTED_METHODS.GET },
      meta,
    );

    yield put(ActionTypes.GET_USER_PROFILES.end());
  }
}
function* getAllUserProfilesWatcher() {
  yield takeEvery<Action<GetUserProfilesPayload>>(
    ActionTypes.GET_USER_PROFILES.BASE,
    getAllUserProfilesWorker,
  );
}

function* deleteUserProfilesWorker({
  payload,
}: Action<{ userProfileId: string }>) {
  const { userProfileId } = payload;

  yield call(
    callAndGetResponse,
    ActionTypes.DELETE_USER_PROFIL,
    `${APIConfiguration.authorization}users/userProfile`,
    {
      method: SUPPORTED_METHODS.DELETE,
      json: { userProfileId },
    },
    { userProfileId, indexer: userProfileId },
  );

  yield put(ActionTypes.DELETE_USER_PROFIL.end({ userProfileId }));
}
function* deleteUserProfilesWatcher() {
  yield takeEvery<Action<{ userProfileId: string }>>(
    ActionTypes.DELETE_USER_PROFIL.BASE,
    deleteUserProfilesWorker,
  );
}

interface UpsertUserProfileToUserPayload {
  sellerId: string;
  userProfileIds: string[];
  userId: string;
}

function* upsertUserProfileToUserWorker({
  payload,
}: Action<UpsertUserProfileToUserPayload>) {
  const { sellerId, userId, userProfileIds = [] } = payload;

  yield call(
    callAndGetResponse,
    ActionTypes.SET_USER_PROFILE_TO_USER,
    `${APIConfiguration.authorization}users/setUserProfilesToUser/${userId}`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: { sellerId, userProfileIds, entityType: DEFAULT_ENTITY_TYPE },
    },
    { indexer: userId },
  );

  yield put(ActionTypes.SET_USER_PROFILE_TO_USER.end({ indexer: userId }));
}
function* upsertUserProfileToUserWatcher() {
  yield takeEvery<Action<UpsertUserProfileToUserPayload>>(
    ActionTypes.SET_USER_PROFILE_TO_USER.BASE,
    upsertUserProfileToUserWorker,
  );
}
function* getAssignablePermissionsWorker() {
  const isLoading: ReturnType<
    typeof getAssignablePermissionsIsLoadingSelector
  > = yield select(getAssignablePermissionsIsLoadingSelector);

  if (isLoading) {
    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.GET_ASSIGNABLE_PERMISSIONS,
    `${APIConfiguration.authorization}permissions/assignablePermissions`,
    { method: SUPPORTED_METHODS.GET },
  );

  yield put(ActionTypes.GET_ASSIGNABLE_PERMISSIONS.end());
}
function* getAssignablePermissionsWatcher() {
  yield takeEvery(
    ActionTypes.GET_ASSIGNABLE_PERMISSIONS.BASE,
    getAssignablePermissionsWorker,
  );
}

export default function* userImportSaga() {
  try {
    yield all([
      // setUserAndProfileWatcher(),
      // createUserManagementWatcher(),
      getManagementUsersWatcher(),
      getConfigurableRolesWatcher(),
      setUserProfilWatcher(),
      getAllUserProfilesWatcher(),
      deleteUserProfilesWatcher(),
      upsertUserProfileToUserWatcher(),
      getAssignablePermissionsWatcher(),
    ]);
  } catch (error) {
    logCritical(error as any);
  }
}
