import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const URL = `${APIConfiguration.claim}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/claims`;

interface Claim {
  groupId: string;
  claimId: string;
  orderLineReference: string;
  state: string;
  ownerProductId: string;
  claimCreationDateUtc: string;
}

interface Response extends BaseBackResponse {
  value: Claim[];
}

async function getClaimsByOrderId({
  orderId,
}: {
  orderId: string;
}): Promise<Claim[]> {
  if (!orderId) {
    throw new RequiredPayloadFieldIsNotProvided(
      'products',
      `getClaimsByOrderId`,
    );
  }

  const url = URL.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId);
  const response = await apiCall<Response>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  return response.value;
}

export default getClaimsByOrderId;
