import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OwnerSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}owners`;

interface GetOwnersQueryPayload {
  tenantId: string;
  sellerId: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  zipCode?: string;
}

async function getOwnersQuery({
  tenantId,
  email,
  phoneNumber,
  zipCode,
  sellerId,
  name,
}: GetOwnersQueryPayload): Promise<
  BackResponse<OwnerSummary[]> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<OwnerSummary[]>>(
      buildUrl(ENDPOINT, {
        tenantId,
        mail: email,
        phoneNumber,
        zipCode,
        sellerId,
        name,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getOwnersQuery;
