import { createAction } from '@reduxjs/toolkit';

import {
  OnFacetChangeArgs,
  OnFacetLockArgs,
  OnMultipleFacetChangeArgs,
} from '@savgroup-front-common/types';

import {
  FILES_FILTER_REFRESH,
  FILES_LIST_CHANGE_FILTER,
  FILES_LIST_CHANGE_MULTPLE_FILTER,
  FILES_LIST_CHANGE_SEARCH_QUERY,
  FILES_LIST_LOCK_FILTER,
  FILES_LIST_RESET_FILTERS,
  FILES_LIST_RESET_FILTERS_AND_LOCK,
  FILES_LIST_SELECT_PAGE,
  FILES_SEARCH,
  REORDER_FILES_LIST_FILTERS,
  TOGGLE_FILES_LIST_RESULTS_ORDER,
} from './actionTypes';

export const searchFilesList = createAction<void>(FILES_SEARCH.BASE);

export const changeFilesListFilter = createAction<OnFacetChangeArgs>(
  FILES_LIST_CHANGE_FILTER.BASE,
);

export const changeMultipleFilesListFilter =
  createAction<OnMultipleFacetChangeArgs>(
    FILES_LIST_CHANGE_MULTPLE_FILTER.BASE,
  );

export const lockFilesListFilter = createAction<OnFacetLockArgs>(
  FILES_LIST_LOCK_FILTER.BASE,
);

export interface ChangeFilesListSearchQueryPayload {
  query: string;
}
export const changeFilesListSearchQuery =
  createAction<ChangeFilesListSearchQueryPayload>(
    FILES_LIST_CHANGE_SEARCH_QUERY.BASE,
  );
export const toggleFilesListSearchOrder = createAction<void>(
  TOGGLE_FILES_LIST_RESULTS_ORDER.BASE,
);

export interface ChangeFilesListPagePayload {
  page: number;
}
export const changeFilesListPage = createAction<ChangeFilesListPagePayload>(
  FILES_LIST_SELECT_PAGE.BASE,
);
export const reorderFilesListFilters = createAction<void>(
  REORDER_FILES_LIST_FILTERS.BASE,
);
export const resetFilesListFilters = createAction<void>(
  FILES_LIST_RESET_FILTERS.BASE,
);
export const resetFilesListLocksAndFilters = createAction<void>(
  FILES_LIST_RESET_FILTERS_AND_LOCK.BASE,
);
export const refreshFilesFilters = createAction<void>(
  FILES_FILTER_REFRESH.BASE,
);
