import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { useConfirmationContext } from '@savgroup-front-common/core/src/components/Confirmation/ConfirmationContextProvider';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { OwnerService } from '../../../../api';

import messages from './messages';

export const useVideoCallLauncher = () => {
  const { getConfirmation } = useConfirmationContext();
  const [shouldDisplayVideoCall, setShouldDisplayVideoCall] = useState(false);

  const { pushErrors } = useToasts({ extendedMessages: messages });

  const [state, setState] = useState<{ fileId?: string; ownerId?: string }>({
    fileId: undefined,
    ownerId: undefined,
  });

  const {
    data,
    isLoading,
    mutateAsync: handleCreateSession,
  } = useMutation(
    ['joinVideoCallSession'],
    async ({
      phone,
      fileId,
      ownerId,
    }: {
      phone: string;
      fileId: string;
      ownerId: string;
    }) => {
      setState({ fileId, ownerId });

      const response = await OwnerService.createVideoSessionCommand({
        fileId,
        phone,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      const resultToken = await OwnerService.getVideoSessionModeratorTokenQuery(
        {
          sessionId: response.value.sessionId,
        },
      );

      if (resultToken.failure) {
        pushErrors(resultToken.errors);

        return undefined;
      }

      const onlineParticipants = resultToken.value.participants.filter(
        (participant) => participant.isStreaming && participant.isModerator,
      );

      if (onlineParticipants.length > 0) {
        const isConfirmed = await getConfirmation({
          title: messages.confirmationConnectSessionTitle,
          didactic: messages.confirmationConnectSessionDidactic,
        });

        if (!isConfirmed) {
          return undefined;
        }
      }

      setShouldDisplayVideoCall(true);

      return {
        ...response.value,
        token: resultToken.value.token,
        applicationId: resultToken.value.applicationId,
      };
    },
  );

  const sessionId = data?.sessionId;
  const clientShareLink = data?.url;
  const applicationId = data?.applicationId;
  const token = data?.token;

  const handleExitCall = useCallback(() => {
    setShouldDisplayVideoCall(false);
  }, []);
  const { mutateAsync: handleSessionEnd, isLoading: isSessionEndLoading } =
    useMutation(['forceDisconnectClientsCommand'], async () => {
      if (!state.fileId) {
        return;
      }

      const response = await OwnerService.forceDisconnectClientsCommand({
        fileId: state.fileId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return;
      }

      setShouldDisplayVideoCall(false);
    });

  return {
    isLoading,
    applicationId,
    token,
    sessionId,
    clientShareLink,
    handleCreateSession,
    handleExitCall,
    shouldDisplayVideoCall,
    handleSessionEnd,
    isSessionEndLoading,
    ownerId: state.ownerId,
    fileId: state.fileId,
  };
};
