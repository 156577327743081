import get from 'lodash/get';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

import { availableNamedLanguages } from '@savgroup-front-common/constants';
import { changeLocaleLanguage } from '@savgroup-front-common/core/src/domains/i18n/actions';
import { types } from '@savgroup-front-common/core/src/domains/login/actions';
import { getSellers } from '@savgroup-front-common/core/src/domains/sellers/actionCreators';
import { defaultLanguage } from '@savgroup-front-common/core/src/helpers';
import { AuthorizationService } from 'control/api';

const {
  LOGIN_SIGNING_IN,
  LOGIN_SIGNED_IN,
  LOGIN_SIGNOUT,
  LOGIN_SIGNEDOUT,
  LOGIN_SIGNED_ENHANCED,
} = types;

export { LOGIN_SIGNING_IN, LOGIN_SIGNED_IN, LOGIN_SIGNOUT, LOGIN_SIGNEDOUT };

export function loginSigningIn() {
  return { type: LOGIN_SIGNING_IN };
}

export const loginSignedIn = (payload) => (dispatch) => {
  const preferedLanguage =
    toLower(get(payload, 'preferences.language')) ||
    toLower(navigator.language).slice(0, 2);
  const chosenLanguage = includes(availableNamedLanguages, preferedLanguage)
    ? preferedLanguage
    : defaultLanguage;

  if (chosenLanguage) {
    dispatch(changeLocaleLanguage({ locale: chosenLanguage }));
  }
  dispatch({ type: LOGIN_SIGNED_IN, payload });
};

export function loginSigningOut() {
  return { type: LOGIN_SIGNOUT };
}

export function loginSignedOut() {
  return { type: LOGIN_SIGNEDOUT };
}

export const signIn = (onLogin) => async (dispatch) => {
  dispatch(loginSigningIn());

  try {
    const result = await AuthorizationService.login();

    if (onLogin) {
      onLogin();
    }

    dispatch(getSellers());
    dispatch(
      loginSignedIn({
        isLoaded: true,
        ...result.value,
        failure: result.failure,
        errors: result.errors,
      }),
    );
    dispatch(LOGIN_SIGNED_ENHANCED.error([]));
  } catch (errors) {
    dispatch(LOGIN_SIGNED_ENHANCED.error(errors));
    dispatch(
      loginSignedIn({
        isLoaded: true,
        failure: true,
        errors,
      }),
    );
  }
};

export const signOut = (dispatch) => {
  dispatch(loginSigningOut());

  return dispatch(loginSignedOut());
};
