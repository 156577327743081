import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const CREATE_USER_MANAGEMENT = createRequest(
  'REVERS.IO/AUTHORIZATION/CREATE_USER_MANAGEMENT',
);
export const SET_USER_AND_PROFILE = createRequest(
  'REVERS.IO/AUTHORIZATION/SET_USER_AND_PROFILE',
);
export const GET_MANAGEMENT_USERS = createRequest(
  'REVERS.IO/AUTHORIZATION/GET_MANAGEMENT_USERS',
);
export const GET_CONFIGURABLE_ROLES = createRequest(
  'REVERS.IO/AUTHORIZATION/GET_CONFIGURABLE_ROLES',
);
export const SET_USER_PROFIL = createRequest(
  'REVERS.IO/AUTHORIZATION/SET_USER_PROFIL',
);
export const DELETE_USER_PROFIL = createRequest(
  'REVERS.IO/AUTHORIZATION/DELETE_USER_PROFIL',
);
export const GET_USER_PROFILES = createRequest(
  'REVERS.IO/AUTHORIZATION/GET_USER_PROFILE',
);
export const GET_ASSIGNABLE_PERMISSIONS = createRequest(
  'REVERS.IO/AUTHORIZATION/GET_ASSIGNABLE_PERMISSIONS',
);
export const SET_USER_PROFILE_TO_USER = createRequest(
  'REVERS.IO/AUTHORIZATION/SET_USER_PROFILE_TO_USER',
);
