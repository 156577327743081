import { uniq } from 'lodash';
import get from 'lodash/get';
import { ActionMeta } from 'redux-actions';

import { SparePartSupplier } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { SPARE_PART_SUPPLIER_DOMAIN } from './constants';
import { SparePartSupplierDomainState } from './types';

export const initialSparePartSupplierState: SparePartSupplierDomainState = {
  [SPARE_PART_SUPPLIER_DOMAIN.LIST]: {},
  [SPARE_PART_SUPPLIER_DOMAIN.BY_SELLER_ID]: {},
};

export interface OnGetSparePartSupplierSuccessPayload {
  value: SparePartSupplier[];
}

function onGetSparePartSupplierSuccess(
  state: SparePartSupplierDomainState,
  { payload }: { payload: OnGetSparePartSupplierSuccessPayload },
): SparePartSupplierDomainState {
  const { value: sparePartSuppliers } = payload;

  return {
    ...state,
    [SPARE_PART_SUPPLIER_DOMAIN.LIST]: sparePartSuppliers.reduce(
      (acc, sparePartSupplier) => {
        return {
          ...acc,
          [sparePartSupplier.id]: sparePartSupplier,
        };
      },
      state[SPARE_PART_SUPPLIER_DOMAIN.LIST],
    ),
    [SPARE_PART_SUPPLIER_DOMAIN.BY_SELLER_ID]: sparePartSuppliers.reduce(
      (acc, sparePartSupplier) => {
        return {
          ...acc,
          [sparePartSupplier.sellerId]: uniq([
            ...get(acc, sparePartSupplier.sellerId, []),
            sparePartSupplier.id,
          ]),
        };
      },
      state[SPARE_PART_SUPPLIER_DOMAIN.BY_SELLER_ID],
    ),
  };
}

export default function mainReducer(
  state: SparePartSupplierDomainState = initialSparePartSupplierState,
  action: ActionMeta<OnGetSparePartSupplierSuccessPayload, unknown>,
): SparePartSupplierDomainState {
  switch (action.type) {
    case types.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID.SUCCEEDED:
      return onGetSparePartSupplierSuccess(state, action);

    default:
      return state;
  }
}
