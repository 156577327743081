import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { AddressInfoDto, BaseBackResponse } from '@savgroup-front-common/types';

const HANDLING_MODE = ':handlingMode';
const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/addresses/${HANDLING_MODE}`;

interface GetFilesByQueryArgs {
  fileId: string;
  handlingMode: string;
  address: AddressInfoDto;
}

async function updateFileAddress({
  handlingMode,
  fileId,
  address,
}: GetFilesByQueryArgs): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId).replace(
      HANDLING_MODE,
      handlingMode,
    );

    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        addressInfo: address,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default updateFileAddress;
