import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import { logWarning } from '../../configuration';

import {
  LOAD_FAILED_ORDERS,
  LOAD_MARKETPLACE_DETAILED_ORDER,
  LOAD_MARKETPLACE_ORDERS,
  UPDATE_ORDERS_SEARCH_QUERY,
} from './actionTypes';

export const defaultOrderSearchQuery = ' ';

const emptyOrders = {
  isLoaded: false,
  isDirty: false,
  pagination: {
    total: 0,
    page: 0,
    hasNextPage: true,
  },
  value: undefined,
};

const initialState = {
  detailedOrders: {},
  failedOrders: {},
  orders: { [defaultOrderSearchQuery]: emptyOrders },
  query: '',
};

function onMarketplaceOrdersLoading(state, { meta }) {
  const searchQuery = meta?.searchQuery || defaultOrderSearchQuery;

  return {
    ...state,
    orders: {
      ...state.orders,
      [searchQuery]: {
        ...(state.orders[searchQuery] || emptyOrders),
        isDirty: true,
      },
    },
  };
}
function onMarketplaceOrdersLoadingErrored(state, { meta }) {
  const searchQuery = get(meta, 'searchQuery', defaultOrderSearchQuery);

  return {
    ...state,
    orders: {
      ...state.orders,
      [searchQuery]: {
        ...state.orders[searchQuery],
        isDirty: true,
        hasErrors: true,
        value: [],
      },
    },
  };
}
function onMarketplaceOrdersLoaded(state, { payload, meta }) {
  const searchQuery = meta?.searchQuery || defaultOrderSearchQuery;
  const orders = (payload?.value || []).map((order) => ({
    ...order,
    id: order.orderId,
  }));

  const oldPage = state.orders[searchQuery]?.pagination.page;
  const { page, hasNextPage, total } = payload?.pagination || {};

  return {
    ...state,
    orders: {
      ...state.orders,
      [searchQuery]: {
        ...state.orders[searchQuery],
        isLoaded: true,
        isDirty: false,
        pagination: { page, hasNextPage, total },
        value:
          oldPage < page
            ? uniqBy(
                (state.orders[searchQuery].value || []).concat(orders),
                'orderId',
              )
            : orders,
      },
    },
  };
}

function onFailedOrdersLoading(state, { meta }) {
  const searchQuery = meta?.searchQuery;

  return {
    ...state,
    failedOrders: {
      ...state.failedOrders,
      [searchQuery]: {
        ...(state.failedOrders[searchQuery] || {}),
        isLoaded: false,
        isDirty: false,
        value: undefined,
      },
    },
  };
}
function onFailedOrdersErrored(state, { meta }) {
  const searchQuery = meta?.searchQuery;

  return {
    ...state,
    failedOrders: {
      ...state.failedOrders,
      [searchQuery]: {
        ...state.failedOrders[searchQuery],
        isDirty: true,
        hasErrors: true,
      },
    },
  };
}
function onFailedOrdersLoaded(state, { payload, meta }) {
  const searchQuery = meta?.searchQuery;
  const orders = payload?.value;

  return {
    ...state,
    failedOrders: {
      ...state.failedOrders,
      [searchQuery]: {
        ...state.failedOrders[searchQuery],
        isLoaded: true,
        isDirty: false,
        value: orders,
      },
    },
  };
}

export const onDetailedMarketplaceOrdersLoading = (state, { meta }) => {
  const orderId = meta?.orderId;

  if (!orderId) {
    logWarning(`[onDetailedMarketplaceOrdersLoading]  OrderId is undefined`);

    return state;
  }

  return {
    ...state,
    detailedOrders: {
      ...state.detailedOrders,
      [orderId]: {
        ...(state.detailedOrders[orderId] || {
          isLoaded: false,
          isDirty: true,
        }),
        isDirty: true,
      },
    },
  };
};

export const onDetailedMarketplaceOrdersLoadingErrored = (state, { meta }) => {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    logWarning(
      `[onDetailedMarketplaceOrdersLoadingErrored]  OrderId is undefined`,
    );

    return state;
  }

  return {
    ...state,
    detailedOrders: {
      ...state.detailedOrders,
      [orderId]: {
        ...state.detailedOrders[orderId],
        isDirty: true,
        hasErrors: true,
      },
    },
  };
};

export const onDetailedMarketplaceOrdersLoaded = (state, { payload, meta }) => {
  const orderId = meta?.orderId;
  const detailedOrder = payload?.value;

  const isDetailedOrderIdIsNotEqualToOrderId =
    detailedOrder.orderId !== orderId;

  if (!orderId || !detailedOrder || isDetailedOrderIdIsNotEqualToOrderId) {
    return state;
  }

  const products = orderBy(
    detailedOrder?.products,
    ['notOpenForClaims'],
    ['asc'],
  );

  return {
    ...state,
    detailedOrders: {
      ...state.detailedOrders,
      [orderId]: {
        ...state.detailedOrders[orderId],
        isLoaded: true,
        isDirty: false,
        value: {
          ...detailedOrder,
          products,
          id: detailedOrder.orderId,
        },
      },
    },
  };
};

function onOrdersSearchQueryUpdate(state, { payload }) {
  return {
    ...state,
    query: payload,
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // marketplace orders
    case LOAD_MARKETPLACE_ORDERS.STARTED:
      return onMarketplaceOrdersLoading(state, action);
    case LOAD_MARKETPLACE_ORDERS.SUCCEEDED:
      return onMarketplaceOrdersLoaded(state, action);
    case LOAD_MARKETPLACE_ORDERS.ERRORED:
      return onMarketplaceOrdersLoadingErrored(state, action);

    // marketplace detailed orders
    case LOAD_MARKETPLACE_DETAILED_ORDER.STARTED:
      return onDetailedMarketplaceOrdersLoading(state, action);
    case LOAD_MARKETPLACE_DETAILED_ORDER.SUCCEEDED:
      return onDetailedMarketplaceOrdersLoaded(state, action);
    case LOAD_MARKETPLACE_DETAILED_ORDER.ERRORED:
      return onDetailedMarketplaceOrdersLoadingErrored(state, action);

    // failed orders
    case LOAD_FAILED_ORDERS.STARTED:
      return onFailedOrdersLoading(state, action);
    case LOAD_FAILED_ORDERS.SUCCEEDED:
      return onFailedOrdersLoaded(state, action);
    case LOAD_FAILED_ORDERS.ERRORED:
      return onFailedOrdersErrored(state, action);

    case UPDATE_ORDERS_SEARCH_QUERY.BASE:
      return onOrdersSearchQueryUpdate(state, action);

    default:
      return state;
  }
}
