import React, { FunctionComponent } from 'react';

import {
  AccountTeeIcon,
  BadgeIcon,
  BallotIcon,
  BookmarkIcon,
  HowToRegIcon,
  LanguageIcon,
  OtherHousesIcon,
  PushPinIcon,
  RecyclingIcon,
  SellIcon,
  ShopIcon,
  TrackChangesIcon,
  TruckIcon,
  UserIcon,
} from '@savgroup-front-common/core/src/protons/icons';

interface FileRubricHandlerProps {
  rubricName: string;
}

const FileRubricHandler: FunctionComponent<
  React.PropsWithChildren<FileRubricHandlerProps>
> = ({ rubricName }) => {
  switch (rubricName) {
    case 'StatusId': {
      return <PushPinIcon />;
    }
    case 'Assignment': {
      return <BookmarkIcon />;
    }
    case 'SolutionGroupType': {
      return <BallotIcon />;
    }
    case 'Store': {
      return <ShopIcon />;
    }
    case 'SiteActorId': {
      return <HowToRegIcon />;
    }
    case 'Brands': {
      return <SellIcon />;
    }
    case 'PartnerScenarioType': {
      return <TruckIcon />;
    }
    case 'WarrantyType': {
      return <RecyclingIcon />;
    }
    case 'ActorType': {
      return <UserIcon />;
    }

    case 'ActorId': {
      return <BadgeIcon />;
    }
    case 'Seller': {
      return <OtherHousesIcon />;
    }
    case 'FileLanguage': {
      return <LanguageIcon />;
    }
    case 'BuyerType': {
      return <TrackChangesIcon />;
    }
    case 'WorkflowVersion': {
      return <AccountTeeIcon />;
    }

    default: {
      return null;
    }
  }
};

FileRubricHandler.displayName = 'FileRubricHandler';

export default FileRubricHandler;
