import { getInitialLocale } from '@savgroup-front-common/core/src/helpers';
import {
  UserManagerConfig,
  userManagerHelper,
} from '@savgroup-front-common/core/src/services';

import { AuthConfiguration } from '../configuration/auth';

export class ControlUserManagerConfig extends UserManagerConfig {
  public static get cache() {
    const userManagerConfigCache = this._cache;

    if (userManagerConfigCache?.userManagerConfig) {
      return userManagerConfigCache;
    }

    const userManagerHelperOutput = userManagerHelper(
      AuthConfiguration,
      getInitialLocale,
    );

    UserManagerConfig.cache = userManagerHelperOutput;

    return userManagerHelperOutput;
  }
}
