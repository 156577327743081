import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claim/file/${COMMON_QUERY_PARAMS.FILE_ID}/reopen`;

interface ReopenFileArgs {
  fileId: string;
}

const reopenFile = async ({
  fileId,
}: ReopenFileArgs): Promise<BackResponse<string> | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);
    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.POST,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default reopenFile;
