import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  SALESFORCE_INTEGRATION_TYPE,
  SalesforceSellerConfiguration,
  SF_SERVICE,
} from '@savgroup-front-common/types';

/* eslint-disable max-len */
const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/configuration/Salesforce/${COMMON_QUERY_PARAMS.SERVICE}`;

type SetSellerConfigurationSalesforceCommandPayload =
  SalesforceSellerConfiguration & { sellerId: string; service: SF_SERVICE };

export const setSellerConfigurationSalesforceCommand = async (
  payload: SetSellerConfigurationSalesforceCommandPayload,
): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    const { sellerId, service, integrationType } = payload;

    const body =
      integrationType === SALESFORCE_INTEGRATION_TYPE.Standard
        ? {
            ...payload,
            sellerId,
            salesforceFieldServiceLightningIntegrationType: integrationType,
          }
        : {
            sellerId,
            salesforceFieldServiceLightningIntegrationType: integrationType,
          };

    return await apiCall<BaseBackResponse>(
      buildUrl(
        ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
          COMMON_QUERY_PARAMS.SERVICE,
          service,
        ),
      ),
      {
        method: SUPPORTED_METHODS.POST,
        json: body,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
