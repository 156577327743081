import styled from 'styled-components';

import { DefaultStyledProps } from '@savgroup-front-common/types';

interface PaginationContainerProps extends DefaultStyledProps {
  $centered?: boolean;
}

export const PaginationContainer = styled.div<PaginationContainerProps>`
  display: flex;
  flex-direction: row;
  width: 256px;
  margin: ${({ $centered }) => ($centered ? '0 auto' : 'unset')};
`;

export const PaginationButton = styled.button<PaginationContainerProps>`
  background: none;
  border: 0;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  &:hover:not(:disabled) {
    background: ${({ theme }: PaginationContainerProps) =>
      theme.colors.primary};
    svg {
      color: white;
    }
  }
  &:disabled {
    svg {
      color: ${({ theme }: PaginationContainerProps) => theme.colors.default};
    }
  }
`;
