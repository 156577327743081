import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponse, BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/externalCarrierDepositLabels`;

interface AddExternalCarrierDepositLabelCommandArgs {
  fileId: string;
  moduleDefinitionId: string;
  moduleInstanceId: string;
  wave: number;
  attachmentId: string;
}

export const addExternalCarrierDepositLabelCommand = async ({
  moduleDefinitionId,
  moduleInstanceId,
  attachmentId,
  wave,
  fileId,
}: AddExternalCarrierDepositLabelCommandArgs): Promise<BaseBackResponse> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BackResponse<BaseBackResponse>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        moduleDefinitionId,
        moduleInstanceId,
        wave,
        attachmentId,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
