import { AppComponent } from '@savgroup-front-common/types';

const getCurrentComponent = (
  appComponents: AppComponent[],
  componentName: string,
) => {
  return appComponents.find(({ name }) => name === componentName);
};

export default getCurrentComponent;
