import { FILTER_FILES_FACETS_TO_EXCLUDE } from '@savgroup-front-common/constants';
import { RawFilesListRubric } from 'control/domains/filesList/types';

const filterHiddenFileFacets = (
  rubric: RawFilesListRubric,
): RawFilesListRubric => {
  return {
    ...rubric,
    facets: rubric.facets.filter((facet) => {
      return !FILTER_FILES_FACETS_TO_EXCLUDE.some(
        ({ rubricName, values }) =>
          rubricName === rubric.name && values.includes(facet.name),
      );
    }),
  };
};

export default filterHiddenFileFacets;
