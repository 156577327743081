import { CloudUpload } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const CloudUploadIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <CloudUpload width={size} height={size} color={color} />;

CloudUploadIcon.displayName = 'CloudUploadIcon';

export default CloudUploadIcon;
