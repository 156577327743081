import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  FileShortSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/reference/${COMMON_QUERY_PARAMS.REFERENCE}`;

interface GetFileByQueryResponse extends BaseBackResponse {
  value: FileShortSummary;
}

async function getFileByReference({
  reference,
}: {
  reference: string;
}): Promise<FileShortSummary> {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.REFERENCE, reference);
  const response = await apiCall<GetFileByQueryResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to getFileByReference`);
  }

  return response.value;
}

export default getFileByReference;
