import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.import}rule/duplicate`;

interface DuplicateRuleCommandArg {
  importType: string;
  ruleId: string;
}

async function duplicateRuleCommand({
  importType,
  ruleId,
}: DuplicateRuleCommandArg): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    const response = await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: { importType, ruleId },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default duplicateRuleCommand;
