import {
  FACET_INPUT_TYPE,
  FilterCategory,
  OnFacetChangeArgs,
  PossibleFilter,
} from '@savgroup-front-common/types';

const updateFacetInExistingRubric = (
  oldRubrics: FilterCategory[],
  payload: OnFacetChangeArgs,
): FilterCategory[] => {
  const newRubrics = oldRubrics.map<FilterCategory>((rubric) => {
    if (rubric.rubricName !== payload.rubricName) {
      return rubric;
    }

    return {
      ...rubric,
      values: rubric.values.map<PossibleFilter>((filter) => {
        if (filter.facetName !== payload.facetName) {
          return filter;
        }

        if (
          payload.inputType === FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH &&
          filter.inputType === FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH
        ) {
          return {
            ...filter,
            isLocked: false,
            isHidden: false,
            value: payload.value,
          };
        }
        if (
          payload.inputType === FACET_INPUT_TYPE.SWITCH &&
          filter.inputType === FACET_INPUT_TYPE.SWITCH
        ) {
          return {
            ...filter,
            isLocked: false,
            isHidden: false,
            value: payload.value,
          };
        }

        return filter;
      }),
    };
  });

  return newRubrics;
};

export default updateFacetInExistingRubric;
