import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, Store } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/storeActor`;

interface GetStoreActorsPayload {
  claimId: string;
}

export interface GetStoreActorsResponseValue {
  storesActor: Store[];
  selectedStoreActorId: string;
  selectedStoreActorName: string;
}

interface GetStoreActorsResponse extends BaseBackResponse {
  value: GetStoreActorsResponseValue;
}

async function getStoreActors({
  claimId,
}: GetStoreActorsPayload): Promise<GetStoreActorsResponseValue> {
  const functionName = 'getStoreActors';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);
  const response = await apiCall<GetStoreActorsResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${functionName}`);
  }

  return response.value;
}

export default getStoreActors;
