import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ACTOR_TYPES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  UserManagementInformation,
} from '@savgroup-front-common/types';

const ENTITY_TYPE = ':entityType';

const ENDPOINT = `${APIConfiguration.authorization}users/management/${ENTITY_TYPE}`;

interface ListManagementUsersQueryPayload {
  entityType: ACTOR_TYPES;
}

const listManagementUsersQuery = async ({
  entityType,
}: ListManagementUsersQueryPayload): Promise<
  BackResponse<UserManagementInformation[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(ENTITY_TYPE, entityType);
    const response = await apiCall<BackResponse<UserManagementInformation[]>>(
      url,
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default listManagementUsersQuery;
