import { TaskList } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const TaskIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <TaskList width={size} height={size} color={color} />;

TaskIcon.displayName = 'TaskIcon';

export default TaskIcon;
