import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';

export enum DraftStatus {
  CREATED = 'Created',
  FILES_IMPORTING = 'FilesImporting',
  FILES_IMPORTED = 'FilesImported',
  TESTING = 'Testing',
  TESTED = 'Tested',
  APPLIED = 'Applied',
  CANCELLED = 'Cancelled',
}

export interface DraftSummaryDto {
  id: string;
  name: string;
  status: DraftStatus;
  creationDate: string;
  includedFiles: {
    name: string;
    url: string;
    utcImportDate: string;
  }[];
  authorId: string;
  exception: string;
}

interface ImportReportArgs {
  draftId: string;
}

const ENDPOINT = `${APIConfiguration.import}drafts/${COMMON_QUERY_PARAMS.DRAFT_ID}`;

export default async function getDraftByIdQuery(
  payload: ImportReportArgs,
): Promise<BackResponse<DraftSummaryDto> | BackResponseFailure> {
  const { draftId } = payload;

  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.DRAFT_ID, draftId);

    return await apiCall<BackResponse<DraftSummaryDto>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
