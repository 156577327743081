import { combineReducers } from 'redux';

import {
  STOCK_ITEM_DETAILS_DOMAIN_KEY,
  stockItemDetailsReducer,
} from './stockItemDetails';
import {
  STOCK_ITEM_LIST_DOMAIN_KEY,
  stockItemListReducer,
} from './stockItemList';

export default combineReducers({
  [STOCK_ITEM_LIST_DOMAIN_KEY]: stockItemListReducer,
  [STOCK_ITEM_DETAILS_DOMAIN_KEY]: stockItemDetailsReducer,
});
