export enum FEATURE_FLAGS_FOR_SELLER {
  BILLING = 'Billing',
  DOCUMENT_VISIBLE_ON_SELFCARE = 'DocumentSelfcare',
  PRODUCT_WITHOUT_CATALOG = 'ProductWithoutCatalog',
  HOLDER_VISIBILITY = 'HolderVisibility',
  QRCODE_UPLOAD = 'UploadByQrCode',
  MULTI_PRODUCT_CARRIER_LABELS = 'MultiProductCarrierLabels',
  COMMERCIAL_GESTURE_MANUAL_MODULE_INSTANCIATION = 'CommercialGestureManualModuleInstaciation',
  DISPLAY_REPAIRED_TAG = 'DisplayRepairedTag',
  OPTIONAL_EMAIL = 'OptionalEmail',
  DESACTIVATE_ACTOR_COVERAGE_RULE = 'DeactivateActorCoverageRule',
  QUALIREPAR = 'Qualirepar',
  QUOTE_INPUTATION = 'QuoteInputation',
  VIDEO_CALL = 'VideoCall',
  USER_ASSIGNMENT = 'UserAssignment',
  TAG_FILE_STATUS = 'TagFileStatus',
  SEARCH_PRODUCT_SPAREPART = 'SearchProductSparePart',
  FILE_FILTER_PRESET = 'FileFilterPreset',
  SWITCH_NEW_BO = 'SwitchNewBo',
  EXTENDED_INTERVENTION_REPORT = 'ExtendedInterventionReport',
  DECLARE_SAFETY_RISK_STATUS = 'DeclareSafetyRiskStatus',
  DECLARE_BENEFICIARY = 'DeclareBeneficiary',
  EDIT_CLIENT_ADDRESS = 'EditClientAddress',
  DISPLAY_ACCOUNTING_TAB = 'DisplayAccountingTab',
  EXTENDED_FILE_CLOSING_MODAL = 'ExtendedFileClosingModal',
  HISTORICAL_FILE_VISIBLE = 'HistoricalFileVisible',
}
