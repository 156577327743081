import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getActionErrorsSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { CREATE_OWNER_FOR_ORDER } from './actionTypes';

export const marketplaceTicketsState = (state) => state.marketplaceTickets;

export const filters = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('filters'),
);

export const tickets = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('tickets'),
);

export const detailedTickets = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => {
    return marketplaceTickets
      ? marketplaceTickets.get('detailedTickets')
      : undefined;
  },
);

export const detailedTicketsTracking = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('detailedTicketsTracking'),
);

export const attachments = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('attachments'),
);

export const claimsInfo = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('claimsInfo'),
);

export const openFilesByOrderIdSelector = createSelector(
  [marketplaceTicketsState, (state, { orderId }) => orderId],
  (state, orderId) => {
    const value = state.getIn(['claimsInfo', orderId, 'value'], null);

    return get(value, 'openFiles', []);
  },
);

export const closedFilesByOrderIdSelector = createSelector(
  [marketplaceTicketsState, (state, { orderId }) => orderId],
  (state, orderId) => {
    const value = state.getIn(['claimsInfo', orderId, 'value'], null);

    return get(value, 'closedFiles', []);
  },
);

export const createOwnerForOrderErrors = (state, { orderId }) =>
  getActionErrorsSelector(state, CREATE_OWNER_FOR_ORDER, orderId);

export const ticketsByOrder = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('ticketsByOrder'),
);

export const marketplaceCapabilities = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('marketplaceCapabilities'),
);

export const ticketUploads = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('ticketUploads'),
);

export const detailedTicketOrderInfo = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('detailedTicketOrderInfo'),
);

export const draftMessages = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('draftMessages'),
);

export const snoozeStatus = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('snoozeStatus'),
);

export const createdOwnerIds = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('createdOwnerIds'),
);

export const loadOwnerProducts = createSelector(
  [marketplaceTicketsState],
  (marketplaceTickets) => marketplaceTickets.get('ownerProducts'),
);

export const getOwnerProducts = createSelector(
  [marketplaceTicketsState, (state, ownerId) => ownerId],
  (state, ownerId) => {
    return {
      isLoaded: state.getIn(['ownerProducts', ownerId, 'isLoaded'], null),
      hasErrors: state.getIn(['ownerProducts', ownerId, 'hasErrors'], null),
      value: state.getIn(['ownerProducts', ownerId, 'value'], null),
    };
  },
);

const loadSellerProductsList = createSelector(
  [marketplaceTicketsState],
  (state) => state.get('sellerProductsList'),
);

export const selectSellerProductsList = createSelector(
  [loadSellerProductsList],
  (state) => state.get('value'),
);
export const getLoadSellerProductsList = createSelector(
  [marketplaceTicketsState],
  (state) => {
    return {
      isLoaded: state.getIn(['sellerProductsList', 'isLoaded'], null),
      hasErrors: state.getIn(['sellerProductsList', 'hasErrors'], null),
      value: state.getIn(['sellerProductsList', 'value'], null),
    };
  },
);
