import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../../../formatters';

import messages from './messages';

const getCountryLabelMessage = (label: string) => {
  switch (label) {
    case 'be':
      return safeFormattedIntlString(messages.be);
    case 'bg':
      return safeFormattedIntlString(messages.bg);
    case 'cz':
      return safeFormattedIntlString(messages.cz);
    case 'dk':
      return safeFormattedIntlString(messages.dk);
    case 'de':
      return safeFormattedIntlString(messages.de);
    case 'ee':
      return safeFormattedIntlString(messages.ee);
    case 'ie':
      return safeFormattedIntlString(messages.ie);
    case 'el':
      return safeFormattedIntlString(messages.el);
    case 'es':
      return safeFormattedIntlString(messages.es);
    case 'fr':
      return safeFormattedIntlString(messages.fr);
    case 'hr':
      return safeFormattedIntlString(messages.hr);
    case 'it':
      return safeFormattedIntlString(messages.it);
    case 'cy':
      return safeFormattedIntlString(messages.cy);
    case 'lv':
      return safeFormattedIntlString(messages.lv);
    case 'lt':
      return safeFormattedIntlString(messages.lt);
    case 'lu':
      return safeFormattedIntlString(messages.lu);
    case 'hu':
      return safeFormattedIntlString(messages.hu);
    case 'mt':
      return safeFormattedIntlString(messages.mt);
    case 'nl':
      return safeFormattedIntlString(messages.nl);
    case 'at':
      return safeFormattedIntlString(messages.at);
    case 'pl':
      return safeFormattedIntlString(messages.pl);
    case 'pt':
      return safeFormattedIntlString(messages.pt);
    case 'ro':
      return safeFormattedIntlString(messages.ro);
    case 'si':
      return safeFormattedIntlString(messages.si);
    case 'sk':
      return safeFormattedIntlString(messages.sk);
    case 'fi':
      return safeFormattedIntlString(messages.fi);
    case 'se':
      return safeFormattedIntlString(messages.se);
    case 'uk':
      return safeFormattedIntlString(messages.uk);
    case 'ch':
      return safeFormattedIntlString(messages.ch);
    case 'no':
      return safeFormattedIntlString(messages.no);
    case 'is':
      return safeFormattedIntlString(messages.is);
    case 'li':
      return safeFormattedIntlString(messages.li);

    default:
      return label;
  }
};

export const getOptionsWithCountryLabelMessage = ({
  options,
  isLabelProvided,
}: {
  options?: BaseAutocompleteOption[];
  isLabelProvided: boolean;
}) => {
  if (!options) {
    return [];
  }

  return options.map((option) => {
    return {
      ...option,
      label: isLabelProvided
        ? option.label
        : getCountryLabelMessage(option.value),
    };
  });
};
