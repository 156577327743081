import {
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import 'regenerator-runtime/runtime';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { wasEveryActionLoadedSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import {
  GET_MODEL_BY_ID,
  GET_MODEL_REPARABILITY,
  GET_MODELS_BY_SEARCH_MODEL_QUERY,
  LOAD_MODELS_INFO,
} from './actionTypes';

function* getModelByIdWorker({ payload: modelId }) {
  const meta = { modelId, indexer: modelId };

  const wasModelLoaded = yield select(wasEveryActionLoadedSelector, [
    { action: GET_MODEL_BY_ID, indexer: modelId },
  ]);

  if (wasModelLoaded || !modelId) {
    yield put(GET_MODEL_BY_ID.end(meta));

    return;
  }

  yield call(
    callAndGetResponse,
    GET_MODEL_BY_ID,
    `${APIConfiguration.catalog}models/${modelId}`,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );
  yield put(GET_MODEL_BY_ID.end(meta));
}
export function* getModelByIdWatcher() {
  yield takeEvery(GET_MODEL_BY_ID.BASE, getModelByIdWorker);
}

function* getModelsBySearchModelQueryWorker({
  payload: { maxResult, sellerId = undefined, searchTerms },
}) {
  const url = buildUrl(`${APIConfiguration.catalog}models/bySearchModel`, {
    maxResult,
    sellerId,
    searchTerms,
  });

  yield call(
    callAndGetResponse,
    GET_MODELS_BY_SEARCH_MODEL_QUERY,
    url,
    { method: SUPPORTED_METHODS.GET },
    {
      sellerId,
      searchTerms,
      indexer: searchTerms,
    },
  );
  yield put(GET_MODELS_BY_SEARCH_MODEL_QUERY.end());
}
export function* getModelsBySearchModelQueryWatcher() {
  yield debounce(
    500,
    GET_MODELS_BY_SEARCH_MODEL_QUERY.BASE,
    getModelsBySearchModelQueryWorker,
  );
}

function* loadModelInfoWorker({ payload }) {
  yield call(
    callAndGetResponse,
    LOAD_MODELS_INFO,
    `${APIConfiguration.catalog}models`,
    {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    },
  );
  yield put(LOAD_MODELS_INFO.end());
}
export function* loadModelInfoWatcher() {
  yield takeEvery(LOAD_MODELS_INFO.BASE, loadModelInfoWorker);
}

function* getModelReparabilityWorker({ payload }) {
  const { modelId, sellerId } = payload;
  const meta = { modelId, indexer: modelId };

  yield call(
    callAndGetResponse,
    GET_MODEL_REPARABILITY,
    buildUrl(`${APIConfiguration.catalog}models/${modelId}/repairable`, {
      sellerId,
    }),
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );
  yield put(GET_MODEL_REPARABILITY.end());
}
export function* getModelReparabilityWatcher() {
  yield takeEvery(GET_MODEL_REPARABILITY.BASE, getModelReparabilityWorker);
}

export default function* modelsSaga() {
  try {
    yield all([
      getModelsBySearchModelQueryWatcher(),

      getModelByIdWatcher(),
      loadModelInfoWatcher(),
      getModelReparabilityWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
