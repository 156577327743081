import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/debugging/actorSolutionsAndPrice`;

interface GetRulesDebuggingActorSolutionsAndPrice {
  claimId?: string;
  onlyMatchingRules?: boolean;
}

interface Value {
  actorSolutions: Record<string, unknown>;
  actorPrice: Record<string, unknown>;
}
interface GetRulesDebuggingActorSolutionsAndPriceResponse
  extends BaseBackResponse {
  value: Value;
}

async function getRulesDebuggingActorSolutionsAndPrice({
  claimId,
  onlyMatchingRules,
}: GetRulesDebuggingActorSolutionsAndPrice): Promise<Value> {
  const functionName = 'getRulesDebuggingActorSolutionsAndPrice';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  const response =
    await apiCall<GetRulesDebuggingActorSolutionsAndPriceResponse>(
      buildUrl(url, {
        onlyMatchingRules,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

  return response.value;
}

export default getRulesDebuggingActorSolutionsAndPrice;
