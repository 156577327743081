import { AnyAction } from 'redux';

import { DraftHistory } from '@savgroup-front-common/types';

import { GET_DRAFT_HISTORY } from './actionTypes';
import { IMPORT_DOMAIN } from './constants';
import { ImportDomainState } from './types';

interface OnGetDraftHistoryBySellerIdAction extends AnyAction {
  meta: { sellerId: string };
  payload: { value: DraftHistory[] };
}

export const initialState: ImportDomainState = {
  [IMPORT_DOMAIN.DRAFT_HISTORY]: {},
};

function onGetDraftHistoryBySellerIdSuccess(
  state: ImportDomainState,
  { payload, meta }: OnGetDraftHistoryBySellerIdAction,
): ImportDomainState {
  const { sellerId } = meta;
  const { value } = payload;
  const oldDraftHistories = state?.[IMPORT_DOMAIN.DRAFT_HISTORY];

  return {
    ...state,
    [IMPORT_DOMAIN.DRAFT_HISTORY]: {
      ...oldDraftHistories,
      [sellerId]: value,
    },
  };
}

export default function reducer(
  state: ImportDomainState = initialState,
  action: AnyAction,
): ImportDomainState {
  switch (action.type) {
    case GET_DRAFT_HISTORY.SUCCEEDED:
      return onGetDraftHistoryBySellerIdSuccess(
        state,
        action as OnGetDraftHistoryBySellerIdAction,
      );
    default:
      return state;
  }
}
