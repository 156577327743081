import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}users/setUserProfilesToUser/${COMMON_QUERY_PARAMS.USER_ID}`;

export interface SetUserArgs {
  userId: string;
  sellerId: string;
  userProfileIds: string[];
}

export default async function setUserProfilesToUser({
  sellerId,
  userProfileIds,
  userId,
}: SetUserArgs): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.USER_ID, userId);
    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.PUT,
      json: { sellerId, userProfileIds, entityType: 'Seller' },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
