import React, { PropsWithChildren, ReactElement } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '../../../../formatters';

import {
  $Dot,
  $DotContainer,
  $Label,
  $LabelContainer,
  $StyledSwitch,
  $SwitchContainer,
  $SwitchRadio,
} from './TripleStateSwitch.styles';
import { SwitchOption } from './TripleStateSwitch.types';

export interface TripleStateSwitchProps<Value extends string | number> {
  name: string;
  value?: SwitchOption<Value>;
  label?: MessageType | string;
  id?: string;
  isDisabled?: boolean;
  onChange?: (value: SwitchOption<Value>) => void;
  dataTestId?: string;
  shouldStopClickPropagation?: boolean;
  options: [SwitchOption<Value>, SwitchOption<Value>, SwitchOption<Value>];
}

function TripleStateSwitch<Value extends string | number>({
  label,
  isDisabled = false,
  dataTestId,
  onChange,
  name,
  value,
  options,
  shouldStopClickPropagation = false,
}: PropsWithChildren<TripleStateSwitchProps<Value>>): ReactElement {
  const activeIndex = options.findIndex(
    (option) => option.value === value?.value,
  );

  const isActive = [0, 2].includes(activeIndex);

  const input = (
    <>
      {options.map((option, index) => {
        return (
          <$SwitchRadio
            title={safeFormattedIntlString(option.label)}
            key={option.value}
            value={option.value}
            checked={value?.value === option.value}
            onChange={() => undefined}
            onClick={(event) => {
              const isSame = value === option;

              event.currentTarget.blur();

              if (!onChange) {
                return;
              }

              if (isSame && (index === 0 || index === 1)) {
                onChange(options[2]);

                return;
              }

              if (isSame && index === 2) {
                onChange(options[0]);

                return;
              }

              onChange(option);
            }}
            disabled={isDisabled}
            data-testid={`${dataTestId}_${option.value}`}
            name={name}
          />
        );
      })}
      <$StyledSwitch disabled={isDisabled}>
        <$DotContainer>
          <$Dot $isActive={isActive} />
          <$Dot $isActive={isActive} />
          <$Dot $isActive={isActive} />
        </$DotContainer>
      </$StyledSwitch>
    </>
  );

  if (label) {
    return (
      <$Label disabled={isDisabled}>
        {input}
        <$LabelContainer>{SafeFormattedMessage(label)}</$LabelContainer>
      </$Label>
    );
  }

  return (
    <$SwitchContainer
      onClick={
        shouldStopClickPropagation
          ? (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
          : undefined
      }
    >
      {input}
    </$SwitchContainer>
  );
}

TripleStateSwitch.displayName = 'TripleStateSwitch';

export default TripleStateSwitch;
