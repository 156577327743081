import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/DiagnosticTreeNodeResponseConfiguration`;

interface DeleteDiagnosticTreeNodeResponseConfigurationFromConsoleCommandPayload {
  sellerId: string;
  uniqueName: string;
  id?: string;
}

const deleteDiagnosticTreeNodeResponseConfigurationFromConsoleCommand = async ({
  sellerId,
  uniqueName,
  id,
}: DeleteDiagnosticTreeNodeResponseConfigurationFromConsoleCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
      {
        method: SUPPORTED_METHODS.DELETE,
        json: { uniqueName, id },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default deleteDiagnosticTreeNodeResponseConfigurationFromConsoleCommand;
