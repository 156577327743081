import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/ownernotification`;

interface UpdateFileOwnerNotificationCommandPayload {
  fileId: string;
  ownerNotificationEmail: string;
}

export const updateFileOwnerNotificationCommand = async ({
  fileId,
  ownerNotificationEmail,
}: UpdateFileOwnerNotificationCommandPayload): Promise<BaseBackResponse> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        ownerNotificationEmail,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
