import { Packages } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const StockIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Packages width={size} height={size} color={color} />;

StockIcon.displayName = 'StockIcon';

export default StockIcon;
