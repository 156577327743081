import React from 'react';

import Icon from './Icon';

interface HighPriorityIconProps {
  color?: string;
  size?: string;
}

const HighPriorityIcon: React.FC<HighPriorityIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M19.9999 13.3333V20M19.9999 26.6833L20.0166 26.665M19.2933 2.37333C19.4808 2.18607 19.7349 2.08088 19.9999 2.08088C20.2649 2.08088 20.5191 2.18607 20.7066 2.37333L37.6266 19.2933C37.8139 19.4808 37.9191 19.735 37.9191 20C37.9191 20.265 37.8139 20.5192 37.6266 20.7067L20.7066 37.6267C20.5191 37.8139 20.2649 37.9191 19.9999 37.9191C19.7349 37.9191 19.4808 37.8139 19.2933 37.6267L2.37326 20.7067C2.186 20.5192 2.08081 20.265 2.08081 20C2.08081 19.735 2.186 19.4808 2.37326 19.2933L19.2933 2.37333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

HighPriorityIcon.displayName = 'HighPriorityIcon';

export default HighPriorityIcon;
