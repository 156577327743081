import { RefObject } from 'react';

const scrollTo = (container: RefObject<HTMLDivElement>, target: number) => {
  setTimeout(() => {
    if (container?.current && container.current.scroll) {
      container.current.scroll({
        left: 0,
        top: target,
        behavior: 'smooth',
      });
    }
  }, 0);
};

export default scrollTo;
