import get from 'lodash/get';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { pathname } from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../../constants/routing';
import * as Selectors from '../selectors';

export const currentTicketIdSelector = createSelector([pathname], (path) => {
  const options = matchPath(path, {
    path: ROUTES.MESSAGES,
    exact: false,
    strict: false,
  });
  const ticketId = get(options, 'params.ticketId');

  return ticketId;
});

export const currentTicketSelector = createSelector(
  [Selectors.detailedTickets, currentTicketIdSelector],
  (detailedTickets, currentTicketId) =>
    detailedTickets ? detailedTickets.get(currentTicketId) : undefined,
);

export const currentDetailedTicketsTracking = createSelector(
  [Selectors.detailedTicketsTracking, currentTicketIdSelector],
  (detailedTicketsTracking, currentTicketId) =>
    detailedTicketsTracking.get(currentTicketId),
);

export const currentTicketUploadsSelector = createSelector(
  [Selectors.ticketUploads, currentTicketIdSelector],
  (ticketUploads, currentTicketId) => ticketUploads.get(currentTicketId),
);

export const currentTicketOrderInfo = createSelector(
  [Selectors.detailedTicketOrderInfo, currentTicketIdSelector],
  (detailedTicketOrderInfo, currentTicketId) =>
    detailedTicketOrderInfo.get(currentTicketId),
);

export const currentTicketDraftMessage = createSelector(
  [Selectors.draftMessages, currentTicketIdSelector],
  (draftMessages, currentTicketId) =>
    draftMessages.getIn([currentTicketId, 'value']) || '',
);

export const currentMarketplaceCapabilitiesSelector = createSelector(
  [currentTicketSelector, Selectors.marketplaceCapabilities],
  (detailedTicket, marketplaceCapabilities) => {
    const ticket = detailedTicket && detailedTicket.get('value');
    const marketplace = get(ticket, 'apiConnector');
    const sellerId = get(ticket, 'sellerId');

    if (marketplace && sellerId) {
      return (
        marketplaceCapabilities &&
        marketplaceCapabilities.getIn([marketplace, sellerId])
      );
    }

    return null;
  },
);

export const currentTicketOrderIdSelector = createSelector(
  [currentTicketSelector],
  (currentTicket) =>
    currentTicket ? get(currentTicket.get('value'), 'orderId') : null,
);
