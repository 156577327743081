import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}iriscode/locales`;

interface IrisCodeReturnValuesItem {
  cultureCode: string;
  key: string;
  label: string;
}

interface IrisCodeReturnValues {
  IrisConditionCode: IrisCodeReturnValuesItem[];
  IrisSymptomCode: IrisCodeReturnValuesItem[];
  IrisSectionCode: IrisCodeReturnValuesItem[];
  IrisDefectCode: IrisCodeReturnValuesItem[];
  IrisRepairCode: IrisCodeReturnValuesItem[];
  IrisExtendedConditionCode: IrisCodeReturnValuesItem[];
}

const getIrisCode = async (): Promise<
  BackResponse<IrisCodeReturnValues> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<IrisCodeReturnValues>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getIrisCode;
