import React from 'react';

import {
  AppIncident,
  AppScheduledMaintenance,
} from '@savgroup-front-common/types';

import { toast } from '../../../atoms/Toast';
import {
  CompletedScheduledMaintenance,
  InProgressScheduledMaintenance,
  NewScheduledMaintenance,
  RescheduledMaintenance,
  ToastIncident,
} from '../ToastStatus';

export const displayUnresolvedIncident = (incident: AppIncident) => {
  toast.warn(<ToastIncident incident={incident} />, {
    autoClose: 15000,
    title: incident.name,
  });
};

export const displayResolvedIncident = (incident: AppIncident) => {
  toast.success(<ToastIncident incident={incident} />, {
    autoClose: 15000,
    title: incident.name,
  });
};

export const displayInProgressScheduledMaintenance = (
  scheduledMaintenance: AppScheduledMaintenance,
) => {
  toast.warn(
    <InProgressScheduledMaintenance
      scheduledMaintenance={scheduledMaintenance}
    />,
    { autoClose: 15000, title: scheduledMaintenance.name },
  );
};

export const displayCompletedScheduledMaintenance = (
  scheduledMaintenance: AppScheduledMaintenance,
) => {
  toast.success(
    <CompletedScheduledMaintenance
      scheduledMaintenance={scheduledMaintenance}
    />,
    { autoClose: 15000, title: scheduledMaintenance.name },
  );
};

export const displayRescheduledMaintenance = (
  scheduledMaintenance: AppScheduledMaintenance,
) => {
  toast.info(
    <RescheduledMaintenance scheduledMaintenance={scheduledMaintenance} />,
    { autoClose: 15000, title: scheduledMaintenance.name },
  );
};

export const displayNewScheduledMaintenance = (
  scheduledMaintenance: AppScheduledMaintenance,
) => {
  toast.info(
    <NewScheduledMaintenance scheduledMaintenance={scheduledMaintenance} />,
    { autoClose: 15000, title: scheduledMaintenance.name },
  );
};
