import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.payment}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/payments/${COMMON_QUERY_PARAMS.ID}`;

interface DeletePaymentAccountCommandArgs {
  sellerId: string;
  accountId: string;
}

export const deletePaymentAccountCommand = async ({
  sellerId,
  accountId,
}: DeletePaymentAccountCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace(COMMON_QUERY_PARAMS.ID, accountId);

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.DELETE,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
