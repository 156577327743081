import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}sellers`;

interface SellerAddress {
  name: string;
  contact: string;
  address: string;
  additionalAddress?: string;
  additionalInformation?: string;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
}

interface SellerCountry {
  countryCode: string;
  address: SellerAddress;
  useDefaultAddress: boolean;
}

export type UpsertTenantCommandPayload = {
  name: string;
  shortName: string;
  trigram: string;
  myAccountUrl?: string;
  defaultOwnerOrderUrl?: string;
  countries: SellerCountry[];
  signatureDate: string;
  customerSupportCountryCodes?: string[];
  cumulIntegrationId?: string;
  theme: {
    logo?: string;
    color1?: string;
    color2?: string;
  };
};

export type UpsertSellerCommandPayload = {
  name: string;
  myAccountUrl?: string;
  defaultOwnerOrderUrl?: string;
  parentId: string;
  withRetractationCare: boolean;
  countries: SellerCountry[];
  salesChannels: string[];
  signatureDate: string;
  customerSupportCountryCodes?: string[];
  cumulIntegrationId?: string;
};

export const createSellerCommand = async (
  payload: UpsertSellerCommandPayload | UpsertTenantCommandPayload,
): Promise<BackResponseFailure | BackResponse<string>> => {
  try {
    return await apiCall<BackResponse<string>>(buildUrl(ENDPOINT), {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
