import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.workflow}files/orderid/${COMMON_QUERY_PARAMS.ORDER_ID}/closed`;

export async function getClosedFilesByOrderId({ orderId }) {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId);

  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to get closed files by orderId`);
  }

  return response.value;
}
