import { useTheme } from 'styled-components';

import { APP_ENVS } from '@savgroup-front-common/constants/src/shared';

import useThemedEnv from '../../../common/hooks/useThemedEnv';

const useDotColor = (): string => {
  const env = useThemedEnv();
  const theme = useTheme();

  if (window.location.href.includes('localhost')) {
    return theme.colors.purple;
  }

  switch (env) {
    case APP_ENVS.DEMO:
      return theme.colors.success;
    case APP_ENVS.DEV:
      return theme.colors.alert;
    case APP_ENVS.PROD:
    default:
      return theme.newUI.defaultColors.menu;
  }
};

export default useDotColor;
