import { rgba } from 'polished';
import styled from 'styled-components';

import { Z_INDEX_MAX } from '@savgroup-front-common/constants/src/shared/zIndex';

export const $FlexibleModalContent = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_MAX};

  border: 1px solid ${({ theme }) => theme.colors.secondaryColor2};
  background: ${({ theme }) => theme.colors.white};

  border-radius: ${({ theme }) => theme.borders.radius};

  transition: box-shadow ease-in-out 100ms;
  &:hover {
    box-shadow: ${({ theme }) =>
      `0px 0px 4px ${rgba(theme.colors.primary, 0.4)}`};
  }
`;
