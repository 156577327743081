import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { useAppStatuses } from '@savgroup-front-common/core/src/molecules/AppStatuses';
import getNextScheduledMaintenance from '@savgroup-front-common/core/src/molecules/AppStatuses/helpers/getNextScheduledMaintenance';
import {
  APP_COMPONENT_STATUSES,
  AppComponent,
} from '@savgroup-front-common/types';

import {
  $MenuTitle,
  $StatusContainer,
  $StatusIndicator,
  $StatusLabel,
} from './HeaderProfileMenu.styles';
import messages from './messages';

const HeaderProfileAppStatus: FunctionComponent = () => {
  const { appComponents, updateAppComponents, appScheduledMaintences } =
    useAppStatuses();

  useEffect(() => {
    updateAppComponents();
  }, [updateAppComponents]);

  const { formatDate } = useIntl();

  const getComponentStatusLabel = useCallback(
    (appComponent: AppComponent) => {
      switch (appComponent.status) {
        case APP_COMPONENT_STATUSES.DEGRADED_PERFORMANCE: {
          return {
            statusLabel: safeFormattedIntlString(messages.degradedPerformance),
            hasScheduledMaintenance: false,
          };
        }
        case APP_COMPONENT_STATUSES.MAJOR_OUTAGE: {
          return {
            statusLabel: safeFormattedIntlString(messages.majorOutage),
            hasScheduledMaintenance: false,
          };
        }
        case APP_COMPONENT_STATUSES.OPERATIONAL: {
          const nextScheduledMaintenance = getNextScheduledMaintenance(
            appScheduledMaintences,
            appComponent.name,
          );

          if (nextScheduledMaintenance) {
            return {
              statusLabel: safeFormattedIntlString(
                messages.nextScheduledMaintenance,
                {
                  time: formatDate(nextScheduledMaintenance.scheduledFor, {
                    day: 'numeric',
                    month: 'long',
                    hour: 'numeric',
                    minute: 'numeric',
                  }),
                },
              ),
              hasScheduledMaintenance: true,
            };
          }

          return {
            statusLabel: safeFormattedIntlString(messages.operational),
            hasScheduledMaintenance: false,
          };
        }
        case APP_COMPONENT_STATUSES.PARTIAL_OUTAGE: {
          return {
            statusLabel: safeFormattedIntlString(messages.partialOutage),
            hasScheduledMaintenance: false,
          };
        }
        case APP_COMPONENT_STATUSES.UNDER_MAINTENANCE: {
          return {
            statusLabel: safeFormattedIntlString(messages.underMaintenance),
            hasScheduledMaintenance: false,
          };
        }

        default:
          return {
            statusLabel: safeFormattedIntlString(messages.loading),
            hasScheduledMaintenance: false,
          };
      }
    },
    [appScheduledMaintences, formatDate],
  );

  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <>
      <$MenuTitle $isNewUiEnabled={isNewUiEnabled}>
        <SafeFormattedMessageWithoutSpread message={messages.appStatus} />
      </$MenuTitle>
      {appComponents.length > 0 ? (
        appComponents.map((appComponent) => {
          const { hasScheduledMaintenance, statusLabel } =
            getComponentStatusLabel(appComponent);

          return (
            <$StatusContainer
              key={appComponent.id}
              $isNewUiEnabled={isNewUiEnabled}
            >
              <$StatusIndicator
                $status={appComponent.status}
                $hasScheduledMaintenance={hasScheduledMaintenance}
              />
              <$StatusLabel $isNewUiEnabled={isNewUiEnabled}>
                {appComponent.name} -{' '}
              </$StatusLabel>
              <$StatusLabel $isNewUiEnabled={isNewUiEnabled}>
                <strong>{statusLabel}</strong>
              </$StatusLabel>
            </$StatusContainer>
          );
        })
      ) : (
        <$StatusContainer $isNewUiEnabled={isNewUiEnabled}>
          <Loader color="white" size="10px" strokeWidth="10" />
          <$StatusLabel $isNewUiEnabled={isNewUiEnabled}>
            {safeFormattedIntlString(messages.loading)}
          </$StatusLabel>
        </$StatusContainer>
      )}
    </>
  );
};

HeaderProfileAppStatus.displayName = 'HeaderProfileAppStatus';

export default HeaderProfileAppStatus;
