import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SearchOrderDetails,
} from '@savgroup-front-common/types';

export const ENDPOINT = `${APIConfiguration.owner}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/details`;

async function getOrderDetailsQuery({
  orderId,
}: {
  orderId: string;
}): Promise<BackResponse<SearchOrderDetails> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<SearchOrderDetails>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getOrderDetailsQuery;
