import {
  File,
  FileHistoric,
  isFileHistoric,
  SearchHighlight,
} from '@savgroup-front-common/types';

import adaptHighlights from './adaptHighlights';
import adaptToRegularHighlight from './adaptToRegularHighlight';

export const getShortFileReference = (file: File): SearchHighlight[] => {
  const { highlights = {} } = file;
  const { ShortFileReference = [] } = highlights;

  return adaptHighlights(ShortFileReference).reduce<SearchHighlight[]>(
    (acc, { value: shortFileReferenceValue, type: shortFileReferenceType }) => {
      const regex = new RegExp(shortFileReferenceValue);

      return acc.reduce<SearchHighlight[]>((acc, { value, type }) => {
        const position = value.search(regex);

        const start = 0;
        const startSlice = position;
        const endSlice = position + shortFileReferenceValue.length;
        const end = value.length;

        const reduced: SearchHighlight[] = [...acc];

        if (startSlice > start) {
          reduced.push({
            type,
            value: value.slice(start, startSlice),
          });
        }

        reduced.push({
          type: shortFileReferenceType,
          value: value.slice(startSlice, endSlice),
        });

        if (endSlice < end) {
          reduced.push({
            type,
            value: value.slice(endSlice, end),
          });
        }

        return reduced;
      }, []);
    },
    [adaptToRegularHighlight(file.fileReference)],
  );
};

const adaptFileToReferenceHighLights = (
  file: File | FileHistoric,
): SearchHighlight[] => {
  if (isFileHistoric(file)) {
    return [];
  }

  const fileReference = file?.highlights?.FileReference || [];
  const shortFileReference = file?.highlights?.ShortFileReference || [];

  if (fileReference.length) {
    return adaptHighlights(fileReference);
  }
  if (shortFileReference.length) {
    return getShortFileReference(file);
  }

  return [adaptToRegularHighlight(file.fileReference)];
};

export default adaptFileToReferenceHighLights;
