import React, { FC } from 'react';

import Banner from '@savgroup-front-common/core/src/atoms/Banners/Banner';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import messages from './messages';

export const WeRecommendUsingDesktop: FC = () => {
  return (
    <Modal isOpen size={MODAL_SIZES.SMALL}>
      <Banner>
        <SafeFormattedMessageWithoutSpread
          message={messages.recommendedDesktop}
        />
      </Banner>
    </Modal>
  );
};

WeRecommendUsingDesktop.displayName = 'WeRecommendUsingDesktop';
