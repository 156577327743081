 
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';

import {
  CardContainer,
  FieldContainer,
  SkeletonField,
} from './DataCard.styles';

const RenderColumn = ({ rowData, isEllipsisEnabled }) => {
  const customCell = rowData?.customCell;
  const label = rowData?.label;
  const value = rowData?.value;

  if (!customCell && !value) {
    return null;
  }

  if (customCell) {
    return <div>{customCell}</div>;
  }

  return (
    <Field
      label={label}
      copy={value}
      copyOnlyOnIconClick
      isEllipsisEnabled={isEllipsisEnabled}
    >
      {value}
    </Field>
  );
};

RenderColumn.propTypes = {
  isEllipsisEnabled: PropTypes.bool.isRequired,
  rowData: PropTypes.shape({}).isRequired,
};

RenderColumn.displayName = 'RenderColumn';

const Grid = ({ data, col, isEllipsisEnabled }) => {
  return (
    <Row>
      {map(data, (rowData) => {
        const label = rowData?.label;

        if (label) {
          return (
            <FieldContainer
              key={safeFormattedIntlString(label)}
              lg={12 / col}
              md={6}
              xs={12}
            >
              <RenderColumn
                rowData={rowData}
                isEllipsisEnabled={isEllipsisEnabled}
              />
            </FieldContainer>
          );
        }

        return null;
      })}
    </Row>
  );
};

Grid.propTypes = {
  isEllipsisEnabled: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  col: PropTypes.number.isRequired,
};

Grid.displayName = 'Grid';

function DataCard(props) {
  const { isLoaded, col, data, isEllipsisEnabled } = props;

  return (
    <CardContainer>
      {!isLoaded ? (
        <Row>
          {Array(col)
            .fill(null)
            .map((_, index) => (
              <Col key={String(index)} xs={12} md={6} lg={12 / col}>
                {Array(Math.ceil(5 / col))
                  .fill(null)
                  .map((_, index) => (
                    <Skeleton key={String(index)} Component={SkeletonField} />
                  ))}
              </Col>
            ))}
        </Row>
      ) : (
        <Grid data={data} col={col} isEllipsisEnabled={isEllipsisEnabled} />
      )}
    </CardContainer>
  );
}

DataCard.propTypes = {
  isLoaded: PropTypes.bool,
  col: PropTypes.number,
  isEllipsisEnabled: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DataCard.defaultProps = {
  isLoaded: true,
  isEllipsisEnabled: true,
  col: 1,
};
DataCard.displayName = 'DataCard';

export default DataCard;
