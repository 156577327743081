import { CIVILITY } from '@savgroup-front-common/constants';
import { AddressInfoDto } from '@savgroup-front-common/types';
import { AddressForm } from '../../AddressInformations.types';

export const prepareAddressInfo = (values: AddressForm): AddressInfoDto => {
  const {
    additionalAddress,
    address,
    city,
    companyName,
    country,
    firstName,
    lastName,
    phoneNumber,
    postalCode,
    hasElevator,
    hasParkingSpace,
    housingType,
    floor,
    doorCode,
    additionalInformation,
  } = values;

  return {
    civility: CIVILITY.NOT_SET,
    additionalAddress,
    address,
    city,
    companyName,
    postalCode,
    countryCode: country.value,
    firstname: firstName,
    lastname: lastName,
    phone: phoneNumber,
    hasElevator,
    hasParkingSpace,
    housingType: housingType?.data,
    floor,
    doorCode,
    additionalInformation,
  };
};
