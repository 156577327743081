import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const ALERTS_SEARCH = createRequest('ALERTS_SEARCH');
export const ALERTS_LIST_CHANGE_FILTER = createRequest(
  'ALERTS_LIST_CHANGE_FILTER',
);
export const ALERTS_LIST_CHANGE_MULTPLE_FILTER = createRequest(
  'ALERTS_LIST_CHANGE_MULTPLE_FILTER',
);
export const ALERTS_LIST_LOCK_FILTER = createRequest('ALERTS_LIST_LOCK_FILTER');
export const ALERTS_LIST_CHANGE_SEARCH_QUERY = createRequest(
  'ALERTS_LIST_CHANGE_SEARCH_QUERY',
);
export const TOGGLE_ALERTS_LIST_RESULTS_ORDER = createRequest(
  'TOGGLE_ALERTS_LIST_SEARCH_ORDER',
);
export const ALERTS_LIST_SELECT_PAGE = createRequest('ALERTS_LIST_SELECT_PAGE');
export const ALERTS_LIST_RESET_FILTERS = createRequest(
  'ALERTS_LIST_RESET_FILTERS',
);
export const ALERTS_LIST_RESET_FILTERS_AND_LOCK = createRequest(
  'ALERTS_LIST_RESET_FILTERS_AND_LOCK',
);
export const REORDER_ALERTS_LIST_FILTERS = createRequest(
  'REORDER_ALERTS_LIST_FILTERS',
);
export const REHYDRATE_ALERTS_SEARCH = createRequest('REHYDRATE_ALERTS_SEARCH');
