import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  UserSite,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}users/listUserSite`;

interface ListUserSiteQueryPayload {
  sellerId: string;
  searchQuery?: string;
}

const listUserSiteQuery = async ({
  sellerId,
  searchQuery,
}: ListUserSiteQueryPayload): Promise<
  BackResponse<UserSite[]> | BackResponseFailure
> => {
  try {
    const url = buildUrl(ENDPOINT, { sellerId, searchQuery });
    const response = await apiCall<BackResponse<UserSite[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default listUserSiteQuery;
