import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Claim,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/reason`;

interface SetClaimGroupReasonCommandPayload {
  claimGroupId: string;
  claimIds: string[];
  reasonId: string;
  diagnosticComment: string;
  reasonComment: string;
  isSafetyRiskDeclared: boolean;
}

export const setClaimGroupReasonCommand = async ({
  claimIds,
  claimGroupId,
  reasonId,
  diagnosticComment = '',
  reasonComment = '',
  isSafetyRiskDeclared,
}: SetClaimGroupReasonCommandPayload): Promise<Claim | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<BackResponse<Claim>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        claimIds,
        reasonId,
        diagnosticComment,
        reasonComment,
        declareSafetyRisk: isSafetyRiskDeclared,
      },
    });

    return response.value;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
