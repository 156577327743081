import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import {
  SEARCH_ANIMATION_DELAY,
  SEARCH_ANIMATION_DURATION,
} from '../../../Search.contants';

import { $AnimationContainer } from './SearchItemContainer.styles';

interface ResultTitleProps {
  animationIndex: number;
  animationState: TransitionStatus;
  height: number;
  isNew?: boolean;
}

const SearchItemContainer: FunctionComponent<React.PropsWithChildren<ResultTitleProps>> = ({
  animationIndex,
  animationState,
  children,
  height,
  isNew = false,
}) => {
  return (
    <$AnimationContainer
      $isNew={isNew}
      $height={height}
      animationState={animationState}
      animationDuration={
        SEARCH_ANIMATION_DURATION.ITEM_FADE +
        animationIndex * SEARCH_ANIMATION_DURATION.INDEX
      }
      animationDelay={SEARCH_ANIMATION_DELAY.ITEM_FADE}
    >
      {children}
    </$AnimationContainer>
  );
};

SearchItemContainer.displayName = 'SearchItemContainer';

export default SearchItemContainer;
