import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/control-attachments`;

interface SetFileAttachmentInfoArgs {
  fileId: string;
  fileAttachmentId: string;
  name: string;
  extension: string;
}

export default async function setFileAttachmentInfo(
  payload: SetFileAttachmentInfoArgs,
): Promise<BackResponse<string> | BackResponseFailure> {
  try {
    const { fileAttachmentId, fileId, extension, name } = payload;
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        name,
        extension,
        fileAttachmentId,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
