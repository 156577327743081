import { useCallback, useState } from 'react';

import { AppComponent } from '@savgroup-front-common/types';

import { StatusPageService } from '../../../api';
import isComponentUnderMaintenance from '../helpers/isComponentUnderMaintenance';

const useAppComponents = ({ componentName }: { componentName: string }) => {
  const [appComponents, setComponents] = useState<AppComponent[]>([]);
  const [isComponentStatusLoaded, setIsComponentStatusLoaded] = useState(false);
  const [forceRemoveUnderMaintenance, setForceRemoveUnderMaintenance] =
    useState(false);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  const updateAppComponents = useCallback(async () => {
    const response = await StatusPageService.getAppComponents();

    if (response.failure) {
      return;
    }

    setIsComponentStatusLoaded(true);

    setIsUnderMaintenance(
      isComponentUnderMaintenance(response.value, componentName),
    );

    setComponents(response.value);
  }, [componentName]);
  const removeMaintenanceScreen = useCallback(async () => {
    setForceRemoveUnderMaintenance(true);
  }, []);

  return {
    appComponents,
    updateAppComponents,
    isUnderMaintenance: !forceRemoveUnderMaintenance && isUnderMaintenance,
    removeMaintenanceScreen,
    isComponentStatusLoaded,
  };
};

export default useAppComponents;
