import React, { FunctionComponent } from 'react';

import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { AddressInfoDto } from '@savgroup-front-common/types';

import {
  EDIT_ADDRESS_STEP_NAMES,
  EDIT_ADDRESS_STEPS_CONFIG,
} from './EditAddress.steps';
import { EditAddressContext, EditAddressValues } from './EditAddress.types';

interface EditAddressContentProps {
  onSubmit: (value: EditAddressValues) => void;
  initialOwnerAddress: AddressInfoDto;
  initialOwnerAddressOnFile?: AddressInfoDto;
  couldEditAddressOnFile?: boolean;
}

const EditAddressContent: FunctionComponent<
  React.PropsWithChildren<EditAddressContentProps>
> = ({
  onSubmit,
  initialOwnerAddress,
  initialOwnerAddressOnFile,
  couldEditAddressOnFile = false,
}) => {
  return (
    <StepsOrchestrator<EditAddressValues, EditAddressContext>
      config={EDIT_ADDRESS_STEPS_CONFIG}
      initialStep={EDIT_ADDRESS_STEP_NAMES.ADDRESS_INFORMATIONS}
      onSubmit={onSubmit}
      initialValues={{
        ownerAddress: {
          address: initialOwnerAddress,
        },
        ownerAddressOnFile: initialOwnerAddressOnFile
          ? {
              address: initialOwnerAddressOnFile,
            }
          : undefined,
      }}
      initialContext={{
        couldEditAddressOnFile,
      }}
    />
  );
};

EditAddressContent.displayName = 'EditAddressContent';

export default EditAddressContent;
