import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  API_COMMON_ERROR,
  COMMON_QUERY_PARAMS,
  DOCUMENT_TYPES,
} from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/theme/files/${COMMON_QUERY_PARAMS.DOCUMENT_TYPE}`;

export interface UploadThemeAssetsArgs {
  file: File;
  documentType: DOCUMENT_TYPES;
  sellerId: string;
}

async function uploadThemeAssets({
  file,
  sellerId,
  documentType,
}: UploadThemeAssetsArgs): Promise<BaseBackResponse | BackResponseFailure> {
  const body = new FormData();

  if (file) {
    body.append('fileBinary', file);
  }

  try {
    const response = await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.DOCUMENT_TYPE, documentType).replace(
        COMMON_QUERY_PARAMS.SELLER_ID,
        sellerId,
      ),
      {
        method: SUPPORTED_METHODS.POST,
        body,
      },
    );

    if (response.statusCode === 401) {
      return prepareResponseFailure({
        errors: [{ code: API_COMMON_ERROR.UNHANDLED }],
      });
    }

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default uploadThemeAssets;
