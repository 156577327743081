import { useSelector } from 'react-redux';

import { selectAlertsListCount } from 'control/domains/alertsList/selectors';
import { selectTodosListCount } from 'control/domains/todosList/selectors';

const useGetCount = () => {
  const todosCount = useSelector(selectTodosListCount);
  const alertsCount = useSelector(selectAlertsListCount);

  return {
    todosCount,
    alertsCount,
  };
};

export default useGetCount;
