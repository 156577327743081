import * as WorkflowConst from './actionTypes';
import { CLEAR, GET_TODO_FACETS_CALLBACK, ON_ERRORS } from './actionTypes';

export function onGetWorkflowState({ id }: { id: string }) {
  return { type: WorkflowConst.GET_WORKFLOW_STATE, payload: { id } };
}

export function onGetWorkflowStatetReply(
  workflowStates: any,
  carrierLabels: any,
) {
  return {
    type: WorkflowConst.GET_WORKFLOW_STATE_CALLBACK,
    payload: { workflowStates, carrierLabels },
  };
}

export function onGetCarrierLabelsSucceeded(carrierLabels: any) {
  return {
    type: WorkflowConst.GET_CARRIER_LABELS_FOR_FILE,
    payload: {
      carrierLabels,
    },
  };
}

export function onWorkflowStateUpdateStarted({ fileId }: { fileId: string }) {
  return {
    type: WorkflowConst.UPDATE_WORKFLOW_STATE_STARTED,
    payload: { fileId },
  };
}

export function onWorkflowStateUpdateReset({ fileId }: { fileId: string }) {
  return {
    type: WorkflowConst.UPDATE_WORKFLOW_STATE_RESET,
    payload: { fileId },
  };
}

export function onWorkflowStateUpdateErrored({ fileId }: { fileId: string }) {
  return {
    type: WorkflowConst.UPDATE_WORKFLOW_STATE_ERRORED,
    payload: { fileId },
  };
}

export function onWorkflowStatePending({ fileId }: { fileId: string }) {
  return {
    type: WorkflowConst.UPDATE_WORKFLOW_STATE_PENDING,
    payload: { fileId },
  };
}

export function onWorkflowStateSuccessful({ fileId }: { fileId: string }) {
  return {
    type: WorkflowConst.UPDATE_WORKFLOW_STATE_SUCCESSFUL,
    payload: { fileId },
  };
}

export function onSetCurrentState({
  name,
  shortLabel,
  label,
}: {
  name: string;
  shortLabel: string;
  label: string;
}) {
  return {
    type: WorkflowConst.SET_CURRENT_STATE,
    payload: {
      name,
      shortLabel,
      label,
    },
  };
}

export function UpdateTodoProgressStatus({
  itemIndex,
  status,
}: {
  itemIndex: number;
  status: string;
}) {
  return {
    type: WorkflowConst.UPDATE_PROGRESS_STATUS,
    payload: { itemIndex, status },
  };
}

export function eraseTodoAction(itemIndex: number) {
  return {
    type: WorkflowConst.ERASE_TODO_ACTION,
    payload: { itemIndex },
  };
}

export function onGetToDoReply(payload: any) {
  return { type: WorkflowConst.GET_TODO_CALLBACK, payload };
}

export function onClear() {
  return { type: CLEAR };
}

export function onGetTodoFacetsReply(facets: any) {
  return { type: GET_TODO_FACETS_CALLBACK, payload: { facets } };
}

export function onError(dataType: string) {
  return {
    type: ON_ERRORS,
    payload: { dataType },
  };
}
