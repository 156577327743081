import { CREATE_MODAL_TYPES } from '@savgroup-front-common/constants';

export enum MODAL_STATE_ACTION_TYPES {
  OPEN_CREATE_MODAL = 'OPEN_CREATE_MODAL',
  CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL',
  TOGGLE_MENU = 'TOGGLE_MENU',
  CLOSE_MENU = 'CLOSE_MENU',
}

export interface CreateButtonState {
  isMenuOpen: boolean;
  modalType: CREATE_MODAL_TYPES | null;
}

export type CreateButtonAction =
  | {
      type: MODAL_STATE_ACTION_TYPES.CLOSE_CREATE_MODAL;
    }
  | {
      type: MODAL_STATE_ACTION_TYPES.CLOSE_MENU;
    }
  | {
      type: MODAL_STATE_ACTION_TYPES.OPEN_CREATE_MODAL;
      payload: { key: CREATE_MODAL_TYPES };
    }
  | {
      type: MODAL_STATE_ACTION_TYPES.TOGGLE_MENU;
    };
