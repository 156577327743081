import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { AutocompleteOption } from '@savgroup-front-common/types';

const transformField = (field: string) =>
  field.replace(/[^a-zA-Z]/g, '').toLowerCase();

export const getCountryOption = (
  options: AutocompleteOption[] = [],
  value?: string,
): AutocompleteOption => {
  const firstOption = options.filter((option) => option?.value === value)?.[0];

  return firstOption || ({ value } as AutocompleteOption);
};

export const filterOption = (
  { value = '', label = '' }: FilterOptionOption<AutocompleteOption>,
  inputValue = '',
): boolean => {
  const transformedValue = transformField(inputValue);

  return [label, value].some((val) =>
    transformField(val).includes(transformedValue),
  );
};
