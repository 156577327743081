import { FileSummary } from '@savgroup-front-common/types';

export const extractFileAdditionalInformation = ({
  file,
}: {
  file?: FileSummary;
}): any => {
  if (!file || !file.fileProducts) {
    return [];
  }

  return file.fileProducts.reduce((acc: any, fileProduct) => {
    const { fileAdditionalInformation = [] } = fileProduct;
    const additionalInformationOfTypeFile: any =
      fileAdditionalInformation.filter(
        ({ additionalInformationFileValue }) => additionalInformationFileValue,
      );

    return [...acc, ...additionalInformationOfTypeFile];
  }, []);
};
