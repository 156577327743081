import { all, put, take, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  ActionCreators as CommonWorkflowActionCreators,
  ActionTypes as CommonWorkflowActionTypes,
} from '@savgroup-front-common/core/src/domains/workflow';

import * as ActionTypes from './actionTypes';

function* customInterventionReportWorker({ payload }) {
  const { fileId, comment, fromState, targetWorkflowState, module } = payload;

  yield all([
    put(
      CommonWorkflowActionCreators.setUserActionAndHydrateFileSummary({
        fromState: fromState.name,
        toState: targetWorkflowState.name,
        module,
        fileId,
        comment,
      }),
    ),
    take(CommonWorkflowActionTypes.SET_SYNC_USER_ACTION.END),
  ]);
}

function* customInterventionReportWatcher() {
  yield takeEvery(
    ActionTypes.CUSTOM_INTERVENTION_REPORT.BASE,
    customInterventionReportWorker,
  );
}

export default function* interventionReportSaga() {
  try {
    yield all([customInterventionReportWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
