import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/issues`;

export interface IssueSummaryDto {
  id: string;
  name: string;
  uniqueName: string;
  key: string;
  displayOrder: number;
  didactic: string;
  fromProductWarranty: boolean;
  winningWarrantyTypeId: string;
  iconKey: string;
}

interface ClaimGroupIssueSummary {
  claimIds: string[];
  issues: IssueSummaryDto[];
}

export async function getClaimGroupIssuesQuery({
  claimGroupId,
}: {
  claimGroupId: string;
}): Promise<BackResponse<ClaimGroupIssueSummary[]> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<ClaimGroupIssueSummary[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
