import * as ActionTypes from './actionTypes';

const initialState = {
  backOfficeLink: {},
};

function onBackOfficeLinkLoading(state) {
  return {
    ...state,
    backOfficeLink: {
      ...state.backOfficeLink,
      isLoaded: false,
      isDirty: true,
    },
  };
}

function onBackOfficeLinkLoaded(state, { payload, meta }) {
  return {
    ...state,
    backOfficeLink: {
      ...state.backOfficeLink,
      isLoaded: true,
      isDirty: true,
      value: {
        ...(state.backOfficeLink || {}),
        [meta.orderId]: {
          ...(state.backOfficeLink?.[meta.orderId] || {}),
          [meta.redirectUrl]: payload.value,
        },
      },
    },
  };
}

function onBackOfficeLinkErrored(state) {
  return {
    ...state,
    backOfficeLink: {
      ...state.backOfficeLink,
      isLoaded: true,
      isDirty: true,
    },
  };
}

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_BACK_OFFICE_LINK.STARTED: {
      return onBackOfficeLinkLoading(state, action);
    }
    case ActionTypes.LOAD_BACK_OFFICE_LINK.SUCCEEDED: {
      return onBackOfficeLinkLoaded(state, action);
    }
    case ActionTypes.LOAD_BACK_OFFICE_LINK.ERRORED: {
      return onBackOfficeLinkErrored(state, action);
    }

    default:
      return state;
  }
}
