import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GetSparePartRequestLineDetailsPayload } from './actionCreators';
import * as types from './actionTypes';

function* getSparePartRequestLinesWorker(action: {
  payload: GetSparePartRequestLineDetailsPayload;
}): Generator {
  const URL = `${APIConfiguration.sparePart}requestLine/${COMMON_QUERY_PARAMS.REQUEST_LINE_ID}`;
  const { sparePartRequestLineId } = action.payload;

  yield call(
    callAndGetResponse,
    types.GET_SPARE_PART_REQUEST_LINE_DETAILS,
    URL.replace(COMMON_QUERY_PARAMS.REQUEST_LINE_ID, sparePartRequestLineId),
    { method: SUPPORTED_METHODS.GET },
    { indexer: sparePartRequestLineId },
  );

  yield put(
    types.GET_SPARE_PART_REQUEST_LINE_DETAILS.end({ sparePartRequestLineId }),
  );
}
function* getSparePartRequestLinesWatcher() {
  yield takeEvery<Action<GetSparePartRequestLineDetailsPayload>>(
    types.GET_SPARE_PART_REQUEST_LINE_DETAILS.BASE,
    getSparePartRequestLinesWorker,
  );
}

export default function* sparePartRequestLineDetailsSaga(): Generator {
  try {
    yield all([getSparePartRequestLinesWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
