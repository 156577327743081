import React, { FunctionComponent } from 'react';

import { INPUT_TYPES } from '@savgroup-front-common/constants/src/shared';

import { $HiddenInput } from './SubmitInput.styles';

interface SubmitInputArgs {
  isDisabled?: boolean;
}

const SubmitInput: FunctionComponent<React.PropsWithChildren<SubmitInputArgs>> = ({
  isDisabled = false,
}) => {
  return <$HiddenInput type={INPUT_TYPES.SUBMIT} disabled={isDisabled} />;
};

SubmitInput.displayName = 'SubmitInput';

export default SubmitInput;
