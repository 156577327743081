import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useInterval } from '@savgroup-front-common/core/src/hooks';
import { CircularProgress } from '@savgroup-front-common/core/src/protons/CircularProgress/CircularProgress';

interface LongPressProgressIconProps {
  duration: number;
  color?: string;
  withBackground: boolean;
  sqSize?: number;
  strokeWidth?: number;
}

const LongPressProgressIcon: FunctionComponent<
  React.PropsWithChildren<LongPressProgressIconProps>
> = ({ duration, color, withBackground, sqSize, strokeWidth }) => {
  const [percentage, setPercentage] = useState(0);

  const start = useMemo(() => Date.now(), []);

  const updateProgress = useCallback(() => {
    const current = Date.now() - start;

    if (current < duration) {
      const percentage = (current * 100) / duration;

      setPercentage(percentage);
    }
  }, [start, duration]);

  useInterval(updateProgress, 10);

  return (
    <CircularProgress
      percentage={percentage}
      color={color}
      withBackground={withBackground}
      sqSize={sqSize}
      strokeWidth={strokeWidth}
    />
  );
};

LongPressProgressIcon.displayName = 'LongPressProgress';

export default LongPressProgressIcon;
