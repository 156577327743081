import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  INTERVENTION_REPORT_STATE,
  ReverseMoney,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/interventionreport`;

interface AdministrativeFeesReportLine {
  type: 'administrativeFee';
  description: string;
  administrativeFee: {
    priceIncludedTax: ReverseMoney;
  };
}

interface DiscountReportLine {
  type: 'discount';
  description: string;
  discount: {
    priceIncludedTax: ReverseMoney;
  };
}

interface ServiceReportLine {
  type: 'service';
  service: {
    serviceId: string;
  };
}

interface UnknownServiceReportLine {
  type: 'unknownService';
  unknownService: {
    priceIncludedTax: ReverseMoney;
  };
}

interface StockItemReportLine {
  type: 'sparePart';
  description: string;
  sparePart: {
    stockItemId: string;
  };
}

interface UnknownSparePartReportLine {
  type: 'unknownSparePart';
  description: string;
  unknownSparePart: {
    supplierId?: string;
    reference: string;
    preTaxBuyingPrice: ReverseMoney;
    preTaxSellingPrice: ReverseMoney;
  };
}

export type SetInterventionReportLine =
  | AdministrativeFeesReportLine
  | DiscountReportLine
  | ServiceReportLine
  | UnknownServiceReportLine
  | StockItemReportLine
  | UnknownSparePartReportLine;

interface SetInterventionReportArgs {
  fileId: string;
  reportLines: SetInterventionReportLine[];
  state: INTERVENTION_REPORT_STATE.DRAFT | INTERVENTION_REPORT_STATE.VALIDATED;
  comment: string;
}

export default async function setInterventionReport(
  payload: SetInterventionReportArgs,
): Promise<BaseBackResponse> {
  try {
    const response = await apiCall(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          comment: payload.comment,
          state: payload.state,
          reportLines: payload.reportLines,
        },
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
