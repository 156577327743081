import React, { FunctionComponent, Suspense } from 'react';
import { useSelector } from 'react-redux';

import { CREATE_MODAL_TYPES } from '@savgroup-front-common/constants';
import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';
import { SwipeableDrawer } from '@savgroup-front-common/core/src/atoms/SwipeableDrawer';
import { selectSellers } from '@savgroup-front-common/core/src/domains/sellers/selectors';
import {
  useGetSellerConfiguration,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

const CreateSeller = React.lazy(
  () => import('../../SellerManagement/CreateSeller'),
);
const CreateTenant = React.lazy(
  () => import('../../SellerManagement/CreateTenant'),
);
const CreateSparePartRequestLegacy = React.lazy(
  () => import('../../SparePart/CreateSparePartRequestLegacy'),
);
const CreateFileByProductReference = React.lazy(
  () => import('../../CreateFile/CreateFileByProductReference'),
);
const CreateFileByCustomerInformation = React.lazy(
  () => import('../../CreateFile/CreateFileByCustomerInformation'),
);
const CreateFileByOrderReference = React.lazy(
  () => import('../../CreateFile/CreateFileByOrderReference'),
);
const CreateFileByReceiptInformation = React.lazy(
  () => import('../../CreateFile/CreateFileByReceiptInformation'),
);
const CreateFileByOwnerStock = React.lazy(
  () => import('../../CreateFile/CreateFileByOwnerStock'),
);

interface Props {
  modalType: string | null;
  onClose: () => void;
}

const CreateModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  modalType,
  onClose,
}) => {
  const isSuperAdmin = useIsSuperAdmin();
  const sellers = useSelector(selectSellers);
  const initSellerSelected = sellers[0];

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId: initSellerSelected?.id,
  });
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <Suspense fallback={<BaseLoader />}>
      <SwipeableDrawer
        isOpen={modalType === CREATE_MODAL_TYPES.FILE_ORDER_REFERENCE}
        dataTestId={CREATE_MODAL_TYPES.FILE_ORDER_REFERENCE}
        onClose={onClose}
        hasBackdrop
        hasCloseOnBackdrop={!isNewUiEnabled}
      >
        <Suspense fallback={<BaseLoader />}>
          <CreateFileByOrderReference onClose={onClose} />
        </Suspense>
      </SwipeableDrawer>
      <SwipeableDrawer
        isOpen={modalType === CREATE_MODAL_TYPES.FILE_PRODUCT_REFERENCE}
        dataTestId={CREATE_MODAL_TYPES.FILE_PRODUCT_REFERENCE}
        onClose={onClose}
        hasBackdrop
        hasCloseOnBackdrop={!isNewUiEnabled}
      >
        <Suspense fallback={<BaseLoader />}>
          <CreateFileByProductReference onClose={onClose} />
        </Suspense>
      </SwipeableDrawer>
      <SwipeableDrawer
        isOpen={modalType === CREATE_MODAL_TYPES.FILE_BY_CUSTOMER_INFORMATION}
        dataTestId={CREATE_MODAL_TYPES.FILE_BY_CUSTOMER_INFORMATION}
        onClose={onClose}
        hasBackdrop
        hasCloseOnBackdrop={!isNewUiEnabled}
      >
        <Suspense fallback={<BaseLoader />}>
          <CreateFileByCustomerInformation onClose={onClose} />
        </Suspense>
      </SwipeableDrawer>
      <SwipeableDrawer
        isOpen={modalType === CREATE_MODAL_TYPES.FILE_OWNER_STOCK}
        dataTestId={CREATE_MODAL_TYPES.FILE_OWNER_STOCK}
        onClose={onClose}
        hasBackdrop
        hasCloseOnBackdrop={!isNewUiEnabled}
      >
        <Suspense fallback={<BaseLoader />}>
          <CreateFileByOwnerStock onClose={onClose} />
        </Suspense>
      </SwipeableDrawer>
      <SwipeableDrawer
        isOpen={modalType === CREATE_MODAL_TYPES.FILE_RECEIPT_INFORMATION}
        dataTestId={CREATE_MODAL_TYPES.FILE_RECEIPT_INFORMATION}
        onClose={onClose}
        hasBackdrop
        hasCloseOnBackdrop={!isNewUiEnabled}
      >
        <Suspense fallback={<BaseLoader />}>
          <CreateFileByReceiptInformation onClose={onClose} />
        </Suspense>
      </SwipeableDrawer>
      {isSuperAdmin ||
        (!sellerConfiguration?.isSparePartNewModel && (
          <SwipeableDrawer
            isOpen={modalType === CREATE_MODAL_TYPES.SPARE_PART_REQUEST}
            dataTestId={CREATE_MODAL_TYPES.SPARE_PART_REQUEST}
            onClose={onClose}
            hasBackdrop
            hasCloseOnBackdrop={!isNewUiEnabled}
          >
            <Suspense fallback={<BaseLoader />}>
              <CreateSparePartRequestLegacy onSubmitSuccessful={onClose} />
            </Suspense>
          </SwipeableDrawer>
        ))}
      <SwipeableDrawer
        isOpen={modalType === CREATE_MODAL_TYPES.SELLER}
        dataTestId={CREATE_MODAL_TYPES.SELLER}
        onClose={onClose}
        hasBackdrop
        hasCloseOnBackdrop={!isNewUiEnabled}
      >
        <Suspense
          fallback={
            <>
              <Skeleton height="3.3rem" marginBottom="1.5rem" />
              <Skeleton height="10rem" />
            </>
          }
        >
          <CreateSeller onClose={onClose} />
        </Suspense>
      </SwipeableDrawer>
      <SwipeableDrawer
        isOpen={modalType === CREATE_MODAL_TYPES.TENANT}
        dataTestId={CREATE_MODAL_TYPES.TENANT}
        onClose={onClose}
        hasBackdrop
        hasCloseOnBackdrop={!isNewUiEnabled}
      >
        <Suspense
          fallback={
            <>
              <Skeleton height="3.3rem" marginBottom="1.5rem" />
              <Skeleton height="10rem" />
            </>
          }
        >
          <CreateTenant onClose={onClose} />
        </Suspense>
      </SwipeableDrawer>
    </Suspense>
  );
};

CreateModal.displayName = 'CreateModal';

export default CreateModal;
