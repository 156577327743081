import { createSelector } from 'reselect';

import { PERMISSIONS } from '@savgroup-front-common/constants/src/control';
import { selectPermissions } from '@savgroup-front-common/core/src/domains/login/selectors';

export const ownerAccountPermissionSelector = createSelector(
  [selectPermissions],
  (permissions) => permissions.includes(PERMISSIONS.OWNER_ACCOUNT),
);

export const ticketReadingPermissionSelector = createSelector(
  [selectPermissions],
  (permissions) => permissions.includes(PERMISSIONS.TICKET_READING),
);

export const canManageRefundsPermissionSelector = createSelector(
  [selectPermissions],
  (permissions) => permissions.includes(PERMISSIONS.REFUND),
);

export const canManageQuotesPermissionSelector = createSelector(
  [selectPermissions],
  (permissions) => permissions.includes(PERMISSIONS.DISPLAY_QUOTE),
);

export const canReadOrdersPermissionSelector = createSelector(
  [selectPermissions],
  (permissions) => {
    return permissions.includes(PERMISSIONS.GET_ORDER);
  },
);
