import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import map from 'lodash/map';
import size from 'lodash/size';
import uniqBy from 'lodash/uniqBy';

import { FileProduct, Product } from '@savgroup-front-common/types';

import { groupProductsBy } from './groupProductsBy';

export const regroupFileProducts = (products: FileProduct[]) => {
  const groupedProductsBySellerProductId = groupBy(products, 'sellerProductId');

  return map(groupedProductsBySellerProductId, (groupedProducts = []) => {
    return {
      ...head(groupedProducts),
      quantity: size(groupedProducts),
    };
  });
};

export const regroupProducts = (products: Product[]) => {
  const groupedProductsBySellerProductId = groupBy(products, 'sellerProductId');

  return map(groupedProductsBySellerProductId, (groupedProducts = []) => {
    const productHistory = groupedProducts.map(
      (groupedProduct) => groupedProduct?.productHistory || [],
    );
    const flattenProductHistory = productHistory.flat();

    return {
      ...head(groupedProducts),
      orderLineReferences: groupedProducts.map((groupedProduct) => {
        return groupedProduct.orderLineReference;
      }),
      ownerProductId: groupedProducts.map((groupedProduct) => {
        return groupedProduct.ownerProductId;
      }),
      quantity: size(groupedProducts),
      productHistory: uniqBy(flattenProductHistory, 'fileId'),
    };
  });
};

export const regroupOtherProducts = (otherProducts: Product[]) => {
  const groupedProductsByOrderId = groupBy(otherProducts, 'orderId');
  const mappedGroupedProductsByOrderId = map(
    groupedProductsByOrderId,
    (productsByOrderId) => regroupProducts(productsByOrderId),
  );

  return flatten(mappedGroupedProductsByOrderId);
};

export const groupProductsByLatestFileId = groupProductsBy({
  fieldName: 'modelId',
});
