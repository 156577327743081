import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  BasePayload,
  RecipientType,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotation/recipientTypes`;

export interface GetPossibleRecipientTypesByFileIdQueryPayload
  extends BasePayload {
  fileId: string;
}

export default async function getPossibleRecipientTypesByFileIdQuery({
  fileId,
  signal,
}: GetPossibleRecipientTypesByFileIdQueryPayload): Promise<
  BackResponse<RecipientType[] | undefined> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BackResponse<RecipientType[] | undefined>>(url, {
      method: SUPPORTED_METHODS.GET,
      signal,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
