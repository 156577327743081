import { rgba, setLightness } from 'polished';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { rem } from '@savgroup-front-common/core/src/helpers';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';

const size = 70;
const smallSize = 50;

const activeStyle = css`
  ${decelerateTimingFunctionTransition};
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};

    background-color: ${({ theme }) =>
      rgba(setLightness(0.7, theme.colors.darkBackground), 0.3)};
  }
`;

export const $MainExternalLink = styled.div`
  display: flex;
  cursor: pointer;
  outline: none;
  flex-direction: column;
  text-decoration: inherit;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  @media ${media.maxWidth.xs} {
    position: relative;
  }

  svg {
    ${decelerateTimingFunctionTransition};
    transition-duration: 300ms;
    transform: translate(0, 0);
  }

  border-left: ${rem(3)} solid transparent;
  border-right: ${rem(3)} solid transparent;
  height: ${rem(size)};
  min-height: ${rem(size)};

  @media ${media.maxWidth.xs} {
    flex-direction: row;
    align-items: left;
    justify-content: left;
    border-left-width: ${rem(18)};
    border-right-width: ${rem(18)};
    height: ${rem(smallSize)};
    min-height: ${rem(smallSize)};
  }

  ${activeStyle};
`;
