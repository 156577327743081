import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  BILLING_STATUS,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}billing/${COMMON_QUERY_PARAMS.BILLING_ID}/status`;

interface UpdateBillingStatusCommandQueryArgs {
  billingId: string;
  status: BILLING_STATUS;
}

const updateBillingStatusCommandQuery = async ({
  billingId,
  status,
}: UpdateBillingStatusCommandQueryArgs): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.BILLING_ID, billingId);

  try {
    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        status,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default updateBillingStatusCommandQuery;
