import React, { FunctionComponent } from 'react';

import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectProfiles,
  selectRoles,
} from '@savgroup-front-common/core/src/domains/login/selectors';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { useControlTypedSelector } from 'control/hooks';
import { useAppContext } from 'control/view/app/App.context';

import { $MenuTitle } from './HeaderProfileMenu.styles';
import HeaderProfileRoles from './HeaderProfileRoles';
import useStore from './hooks/useStore';
import messages from './messages';

const HeaderProfileUserName: FunctionComponent = () => {
  const firstName = useControlTypedSelector(selectFirstName);
  const lastName = useControlTypedSelector(selectLastName);
  const email = useControlTypedSelector(selectEmail);
  const roleNames = useControlTypedSelector(selectRoles);
  const profileNames = useControlTypedSelector(selectProfiles);

  const storeNames = useStore();
  const { selectedSeller } = useAppContext();

  const fullName =
    firstName.length || lastName.length ? `${firstName} ${lastName}` : email;
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <>
      <$MenuTitle $isNewUiEnabled={isNewUiEnabled}>{fullName}</$MenuTitle>
      {profileNames.length === 0 && roleNames.length > 0 && (
        <HeaderProfileRoles message={messages.roles} list={roleNames} />
      )}
      {profileNames.length > 0 && (
        <HeaderProfileRoles message={messages.profiles} list={profileNames} />
      )}
      {storeNames.length > 0 && (
        <HeaderProfileRoles message={messages.stores} list={storeNames} />
      )}
      {selectedSeller && (
        <HeaderProfileRoles
          message={messages.seller}
          list={[selectedSeller.name]}
        />
      )}
    </>
  );
};

HeaderProfileUserName.displayName = 'HeaderProfileUserName';

export default HeaderProfileUserName;
