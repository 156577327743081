import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.ORDER_ID}/opened`;

export async function getOpenedFilesByOrderId({
  orderId,
  isWithoutWorkflowAndModuleInformation = false,
  isWithoutSolutionName = false,
}) {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId);

  const response = await apiCall(
    buildUrl(url, {
      isWithoutSolutionName,
      isWithoutWorkflowAndModuleInformation,
    }),
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  if (response.failure) {
    throw new Error(`Got an exception trying to get opened files by orderId`);
  }

  return response.value;
}
