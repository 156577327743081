import { defineMessages } from 'react-intl';

export default defineMessages({
  notSupported: {
    id: 'view.search.matchingFieldLabel.notSupported',
    defaultMessage: 'Other field',
  },
  order: {
    id: 'view.search.matchingFieldLabel.order',
    defaultMessage: 'Order',
  },
  seller: {
    id: 'view.search.matchingFieldLabel.seller',
    defaultMessage: 'Seller',
  },
  transportTrackingNumbers: {
    id: 'view.search.matchingFieldLabel.transportTrackingNumbers',
    defaultMessage: 'Transport tracking number',
  },
  brand: {
    id: 'view.search.matchingFieldLabel.brand',
    defaultMessage: 'Brand',
  },
  ownerZipCode: {
    id: 'view.search.matchingFieldLabel.ownerZipCode',
    defaultMessage: 'Zip code',
  },
  ownerPhoneNumber: {
    id: 'view.search.matchingFieldLabel.ownerPhoneNumber',
    defaultMessage: 'Phone number',
  },
  ownerName: {
    id: 'view.search.matchingFieldLabel.ownerName',
    defaultMessage: 'Owner name',
  },
  ownerMail: {
    id: 'view.search.matchingFieldLabel.ownerMail',
    defaultMessage: 'E-mail',
  },
  fileRef: {
    id: 'view.search.matchingFieldLabel.fileRef',
    defaultMessage: 'File reference',
  },
  serialNumber: {
    id: 'view.search.matchingFieldLabel.serialNumber',
    defaultMessage: 'Serial number',
  },
  imei: {
    id: 'view.search.matchingFieldLabel.imei',
    defaultMessage: 'IMEI',
  },
  rmaNumber: {
    id: 'view.search.matchingFieldLabel.rmaNumber',
    defaultMessage: 'Rma number',
  },
});
