import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  ImportReportByType,
} from '@savgroup-front-common/types';

interface GetImportReportByTypeArgs {
  draftId: string;
  type: string;
  category: string;
}

interface GetImportReportByTypeResponse extends BaseBackResponse {
  value: ImportReportByType[];
}

const CATEGORY = ':category';
const TYPE = ':type';

const ENDPOINT = `${APIConfiguration.import}drafts/${COMMON_QUERY_PARAMS.DRAFT_ID}/importReport/${CATEGORY}/${TYPE}`;

export default async function getImportReportByType(
  payload: GetImportReportByTypeArgs,
): Promise<ImportReportByType[]> {
  const { draftId, category, type } = payload;

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.DRAFT_ID, draftId)
    .replace(CATEGORY, category)
    .replace(TYPE, type);

  const response = await apiCall<GetImportReportByTypeResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to get import report by type`);
  }

  return response.value;
}
