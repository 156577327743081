import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { adaptFileToSearchResult } from 'control/adapters';
import { SearchService } from 'control/api';

import { SearchApiResult } from '../Search.types';

const searchFiles = async ({
  query,
  sort,
  isDescending,
  shouldLoad,
  page,
  pageSize,
}: {
  page: number;
  pageSize?: number;
  query: string;
  sort?: SORT_TYPES;
  shouldLoad: boolean;
  isDescending: boolean;
}): Promise<SearchApiResult> => {
  if (!shouldLoad) {
    return {
      isFailure: false,
      value: [],
      totalCount: 0,
      errors: [],
    };
  }

  const response = await SearchService.getFilesByQuery({
    query,
    pageSize: pageSize || 7,
    sort,
    isDescending,
    page,
  });

  if (response.failure) {
    return {
      isFailure: true,
      value: [],
      totalCount: 0,
      errors: response.errors,
    };
  }

  return {
    isFailure: false,
    value: response?.value?.hits.map(adaptFileToSearchResult),
    totalCount: response?.value?.hitCount,
    errors: [],
    hit: response.value.hits,
    hitCount: response?.value?.hitCount,
  };
};

export default searchFiles;
