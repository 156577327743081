import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { rem } from '@savgroup-front-common/core/src/helpers';

interface BlockProps {
  $cornerColor?: string;
}

const blockButton = css<BlockProps>`
  outline: 0;
  border: none;
  min-width: ${rem(50)};
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  transition-property: background-color;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    background-color: ${({ $cornerColor }) => $cornerColor};
    position: absolute;
    top: -15px;
    left: -15px;
    transform: rotate(45deg);
  }
  @media ${media.minWidth.sm} {
    display: flex;
    min-width: ${rem(70)};
  }
`;

export const $MenuLogo = styled(Link)<BlockProps>`
  @media ${media.maxWidth.xs} {
    display: none;
  }
  ${blockButton}
`;

export const $MenuHamburger = styled.button`
  @media ${media.minWidth.sm} {
    display: none !important;
  }
  ${blockButton}
`;
