import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/ignoreDateRules`;

export async function claimIgnoreDateRules({ claimId, value }) {
  if (!claimId) {
    return null;
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.PUT,
    json: {
      ignoreDateRulesChecking: value,
    },
  });

  return response.value;
}
