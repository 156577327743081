import { rgba } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { rem } from '@savgroup-front-common/core/src/helpers';

import { SECTION_STYLE } from './hooks/useGetSectionStyle';

export const $LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const $LayoutMain = styled.main<{ $isNewUiEnabled?: boolean }>`
  height: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled ? `calc(100vh - ${rem(50)})` : `calc(100vh - ${rem(70)})`};
  display: flex;
  flex-direction: row;

  @media ${media.maxWidth.md} {
    height: calc(100vh - ${rem(50)});
  }
`;

export const $Section = styled.section<{ $sectionStyleMode?: SECTION_STYLE }>`
  background-color: ${({ theme, $sectionStyleMode }) => {
    switch ($sectionStyleMode) {
      case SECTION_STYLE.NEW_BO_MODE:
        return '#FBFBFF';

      case SECTION_STYLE.CONSOLE_EDIT_MODE:
        return rgba(theme.colors.primary, 0.05);

      case SECTION_STYLE.CONSOLE_VIEW_MODE:
        return theme.colors.white;

      case SECTION_STYLE.CLASSIC_MODE:
      default:
        return '#f7f7f7';
    }
  }};
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  height: 100%;
`;
