import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';

function* loadRefundsWorker({ payload: fileId }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_REFUNDS,
    `${APIConfiguration.marketplace}files/${fileId}/refunds`,
    { method: SUPPORTED_METHODS.GET },
    { indexer: fileId, fileId },
  );

  yield put(ActionTypes.LOAD_REFUNDS.end({ fileId }));
}

function* loadRefundsWatcher() {
  yield takeEvery(ActionTypes.LOAD_REFUNDS.BASE, loadRefundsWorker);
}

export default function* refundsSaga() {
  try {
    yield all([loadRefundsWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
