import { Book } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

const MenuBook: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Book width={size} height={size} color={color} />;

MenuBook.displayName = 'MenuBook';

export default MenuBook;
