import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import {
  BackResponse,
  BackResponseFailure,
  ExtendedFile,
} from '@savgroup-front-common/types';

export async function requestReferencingAttachements(
  files: ExtendedFile[],
): Promise<BackResponseFailure | BackResponse<string[]>> {
  try {
    return await CommonAttachmentService.uploadMultiAttachments({
      files,
      endpoint: `${APIConfiguration.sparePart}request/referencingrequest`,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
