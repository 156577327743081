import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/setStoreActor`;

interface UpdateStoreActorCommandPayload {
  fileId: string;
  storeId?: string;
}

async function updateStoreActorCommand({
  fileId,
  storeId,
}: UpdateStoreActorCommandPayload): Promise<BaseBackResponse> {
  if (!fileId) {
    throw new RequiredPayloadFieldIsNotProvided(
      JSON.stringify({ fileId }),
      'updateStoreActorCommand',
    );
  }

  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        storeId,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default updateStoreActorCommand;
