import { all } from 'redux-saga/effects';

import { repairerSaga as controlRepairerSaga } from './controlRepairer';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { saga as actorsSaga } from '@savgroup-front-common/core/src/domains/actors';
import { saga as attachmentsSaga } from '@savgroup-front-common/core/src/domains/attachments';
import { saga as carriersSaga } from '@savgroup-front-common/core/src/domains/carriers';
import { saga as claimsSaga } from '@savgroup-front-common/core/src/domains/claims';
import { saga as fileUploadSaga } from '@savgroup-front-common/core/src/domains/fileUpload';
import { saga as i18nSaga } from '@savgroup-front-common/core/src/domains/i18n';
import { saga as sellerConfigurationSaga } from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import { sellerSaga } from '@savgroup-front-common/core/src/domains/sellers';
import { storeSaga } from '@savgroup-front-common/core/src/domains/store';
import { technicalInterventionsSaga } from '@savgroup-front-common/core/src/domains/technicalInterventions';
import { saga as workflowSaga } from '@savgroup-front-common/core/src/domains/workflow';
import { saga as additionalInformationSaga } from 'control/domains/additionalInformation';
import fileSummaryWatchers from 'control/domains/workflow/fileSummary/sagas';

import { saga as accessSaga } from './access';
import { alertsListSaga } from './alertsList';
import transportSaga from './business/transport/transportSagas';
import { saga as controlClaimsSaga } from './claims';
import { saga as filesSaga } from './files';
import { filesListSaga } from './filesList';
import { saga as filterSaga } from './filter';
import interventionReport from './interventionReport/saga';
import { invoiceSaga } from './invoice';
import { saga as marketplaceTicketsSaga } from './marketplaceTickets';
import modelsSaga from './models/saga';
import { saga as orderSaga } from './orders';
import { saga as ownerSaga } from './owner';
import { productSupplierSaga } from './productSupplier';
import { saga as quoteSaga } from './quote';
import editQuote from './quote/editQuote/saga';
import validateQuote from './quote/validateQuote';
import { saga as refundsSaga } from './refunds';
import { repairerSaga } from './repairer';
import routerSaga from './router';
import { saga as rulesImportSaga } from './ruleImport';
import { saga as sellersSummarySaga } from './sellersInfo';
import { sparePartRequestSaga } from './sparePartRequests';
import { sparePartsSaga } from './spareParts';
import { sparePartSupplierSaga } from './sparePartSupplier';
import { stocksSaga } from './stocks';
import ticketsSagas from './tickets/ticketsSagas';
import { todosListSaga } from './todosList';
import { userManagementSaga } from './userManagement';
import { usersSaga } from './users';
import { saga as controlWorkflowSaga } from './workflow';

export default function* rootSaga() {
  try {
    yield all([
      accessSaga(),
      actorsSaga(),
      additionalInformationSaga(),
      attachmentsSaga(),
      carriersSaga(),
      claimsSaga(),
      controlWorkflowSaga(),
      controlRepairerSaga(),
      editQuote(),
      filesListSaga(),
      alertsListSaga(),
      todosListSaga(),
      transportSaga(),
      fileUploadSaga(),
      filesSaga(),
      filterSaga(),
      i18nSaga(),
      interventionReport(),
      invoiceSaga(),
      marketplaceTicketsSaga(),
      modelsSaga(),
      orderSaga(),
      ownerSaga(),
      quoteSaga(),
      sellersSummarySaga(),
      refundsSaga(),
      repairerSaga(),
      routerSaga(),
      rulesImportSaga(),
      sellerConfigurationSaga(),
      sellerSaga(),
      sparePartsSaga(),
      sparePartRequestSaga(),
      sparePartSupplierSaga(),
      productSupplierSaga(),
      stocksSaga(),
      storeSaga(),
      technicalInterventionsSaga(),
      ticketsSagas(),
      userManagementSaga(),
      usersSaga(),
      validateQuote(),
      workflowSaga(),
      controlClaimsSaga(),

      fileSummaryWatchers(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
