import { useCallback, useState } from 'react';

import { SHORTCUT_LIST_CONTROL } from '@savgroup-front-common/constants';
import { useShortCut } from '@savgroup-front-common/core/src/hooks';

const useShortcutsModal = () => {
  const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState(false);
  const handleCloseShortcutsModal = useCallback(() => {
    setIsShortcutsModalOpen(false);
  }, []);
  const onOpenShortcutModal = useCallback(() => {
    setIsShortcutsModalOpen(true);
  }, []);
  const toggle = useCallback(() => {
    setIsShortcutsModalOpen((v) => !v);
  }, []);

  useShortCut({
    callback: toggle,
    codes: SHORTCUT_LIST_CONTROL.SHORTCUT_MODAL,
  });

  return {
    isShortcutsModalOpen,
    handleCloseShortcutsModal,
    onOpenShortcutModal,
  };
};

export default useShortcutsModal;
