import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileHistoric,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}historicalFiles/list`;

interface GetHistoricFileListArgs {
  page?: number;
  pageSize?: number;
  IsDescending?: boolean;
}

export const getHistoricFileList = async ({
  page = 1,
  pageSize = 15,
  IsDescending = true,
}: GetHistoricFileListArgs): Promise<
  BackResponse<FileHistoric[]> | BackResponseFailure
> => {
  const url = buildUrl(ENDPOINT, {
    Page: page,
    PageSize: pageSize,
    IsDescending,
  });

  try {
    const response = await apiCall<BackResponse<FileHistoric[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
