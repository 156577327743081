import { createSelector } from 'reselect';

import {
  FILTERS,
  TICKETS_ORDER_VALUES,
} from '@savgroup-front-common/constants/src/control';
import { getFromDataByKey } from '@savgroup-front-common/core/src/helpers';

import { filters } from '../marketplaceTickets/selectors';

const filtersState = (state) => state.filters;

export const messagesFiltersAppliedSelector = createSelector(
  [filtersState],
  (filters) => filters.messages.appliedFilters,
);
export const numberOfMessagesFiltersAppliedSelector = createSelector(
  [messagesFiltersAppliedSelector],
  (appliedFilters) => {
    return (appliedFilters ? Object.values(appliedFilters) : []).reduce(
      (sum, filter) => sum + Object.values(filter).filter((v) => v).length,
      0,
    );
  },
);
export const messagesSortValueSelector = createSelector(
  [filtersState],
  (filters) =>
    filters.messages.sortOrder || {
      name: TICKETS_ORDER_VALUES.BY_ALL_MESSAGES,
      value: 'Asc',
    },
);
export const messagesSearchQuerySelector = createSelector(
  [filtersState],
  (filters) => filters.messages.searchQuery,
);

export const messagesFilterSelector = createSelector(
  [
    filters,
    messagesSortValueSelector,
    messagesFiltersAppliedSelector,
    numberOfMessagesFiltersAppliedSelector,
  ],
  (filters, sortValue, appliedFilters, numberOfFiltersApplied) => {
    return {
      sortValue,
      value: getFromDataByKey(filters, 'value'),
      filterName: FILTERS.MESSAGES,
      appliedFilters,
      numberOfFiltersApplied,
    };
  },
);
