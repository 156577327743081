import get from 'lodash/get';
import head from 'lodash/head';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { Selectors as requestSelectors } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors/router';
import { OrderDetails } from '@savgroup-front-common/types';

import { ROUTES } from '../../constants/routing';
import { Selectors as AccessSelectors } from '../access';
import { ControlRootState } from '../ControlRootState';
import { currentFileSelector } from '../fileInfo/fileDetails';
import { LOAD_CLAIM_INFO_BY_ORDER } from '../marketplaceTickets/actionTypes';
import { currentTicketOrderIdSelector } from '../marketplaceTickets/marketplaceAggregator/selectors';
import { claimsInfo, ticketsByOrder } from '../marketplaceTickets/selectors';
import { selectOwners } from '../owner/selectors';

export const defaultOrderSearchQuery = ' ';

export const ordersState = (state: ControlRootState) => state.orders;

export const selectOrders = createSelector(
  [ordersState],
  (orders) => orders.orders,
);
export const ordersQuery = createSelector(
  [selectOrders, (_state, query = defaultOrderSearchQuery) => query],
  (ordersList, query) => ordersList[query],
);

export const selectDetailedOrders = createSelector(
  [ordersState],
  (orders) => orders.detailedOrders,
);
export const getDetailedOrderByOrderId = createSelector(
  [
    selectDetailedOrders,
    (_state: ControlRootState, { orderId }: { orderId?: string }) => orderId,
  ],
  (detailedOrdersState, orderId) => {
    if (!orderId) {
      return undefined;
    }

    return (
      detailedOrdersState[orderId]?.value || {
        products: [],
      }
    );
  },
);

export const failedOrders = createSelector(
  [ordersState],
  (orders) => orders.failedOrders,
);
export const failedOrdersQuery = createSelector(
  [failedOrders, (_state, query = defaultOrderSearchQuery) => query],
  (failedOrdersList, query) => failedOrdersList[query] || {},
);

export const selectOrderQuery = createSelector(
  [ordersState],
  (state) => state.query,
);

export const selectOrderById = createSelector(
  [
    selectDetailedOrders,
    (_: ControlRootState, { orderId }: { orderId?: string }) => orderId,
  ],
  (detailedOrders, orderId): OrderDetails | undefined =>
    detailedOrders && orderId ? detailedOrders[orderId]?.value : undefined,
);
export const currentOrderIdSelector = createSelector(
  [pathname, currentTicketOrderIdSelector, currentFileSelector],
  (path, currentTicketOrderId, currentFile) => {
    const options = matchPath(path, {
      path: ROUTES.ORDERS,
      exact: false,
      strict: false,
    });
    const orderId = get(options, 'params.orderId');

    if (!orderId && currentFile) {
      return currentFile.orderId;
    }

    return orderId || currentTicketOrderId;
  },
);
export const currentOrderBackOfficeLinkSelector = createSelector(
  [AccessSelectors.backOfficeLinkValue, currentOrderIdSelector],
  (backOfficeLink, orderId) => {
    if (backOfficeLink) {
      return backOfficeLink[orderId] || undefined;
    }

    return undefined;
  },
);
export const currentOrderBackOfficeLinkWithSubrouteSelector = createSelector(
  currentOrderBackOfficeLinkSelector,
  (_state, subroute = '') => subroute,
  (backOfficeLinksOffer, subroute) => {
    if (backOfficeLinksOffer) {
      return backOfficeLinksOffer?.[subroute];
    }

    return undefined;
  },
);
export const getBackOfficeLinkWithSubrouteByOrderId = createSelector(
  [
    AccessSelectors.backOfficeLinkValue,
    (_state: any, orderId: string) => orderId,
    (_state, _orderId: string, subroute = '') => subroute,
  ],
  (backOfficeLink, orderId, subroute) => {
    if (backOfficeLink) {
      return backOfficeLink[orderId]?.[subroute];
    }

    return undefined;
  },
);

export const currentOrderSelector = createSelector(
  [selectDetailedOrders, currentOrderIdSelector],
  (detailedOrders, currentOrderId) => detailedOrders[currentOrderId],
);
export const currentOrderSelectorValue = createSelector(
  [currentOrderSelector],
  (order) => order?.value || {},
);
export const currentFailedOrderSelector = createSelector(
  [failedOrders, currentOrderIdSelector, selectOrderQuery],
  (failedOrders, _currentOrderId, ordersSearch) => {
    const currentOrder = head((failedOrders[ordersSearch] as any)?.value || []);

    return currentOrder;
  },
);

export const currentTicketsByOrderSelector = createSelector(
  [ticketsByOrder, currentOrderIdSelector],
  (tickets, currentOrderId) => tickets.get(currentOrderId),
);
export const selectCurrentTicketsByOrderValue = createSelector(
  [currentTicketsByOrderSelector],
  (currentTicketsByOrder) =>
    currentTicketsByOrder ? currentTicketsByOrder.get('value') : null,
);
export const currentOrderOwnerIdSelector = createSelector(
  [currentOrderSelector],
  (currentOrder) => (currentOrder ? currentOrder.value?.ownerId : undefined),
);
export const currentOrderModelIdSelector = createSelector(
  [currentOrderSelector],
  (currentOrder) =>
    currentOrder
      ? (currentOrder.value?.products || []).map((product) => product.modelId)
      : [],
);
export const currentOrderOwnerSelector = createSelector(
  [selectOwners, currentOrderOwnerIdSelector as any],
  (owners: Record<string, { value: unknown }>, ownerId: string) =>
    ownerId ? get(owners, [ownerId, 'value']) : null,
);
export const currentClaimsInfoSelector = createSelector(
  [claimsInfo, currentOrderIdSelector],
  (claims, currentOrderId) => claims.get(currentOrderId),
);
export const wasClaimsInfosByOrderIdLoaded = (
  state: any,
  { orderId }: { orderId: string },
) =>
  requestSelectors.wasActionLoadedSelector(
    state,
    LOAD_CLAIM_INFO_BY_ORDER,
    orderId,
  );
