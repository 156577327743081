import * as ActionTypes from './actionTypes';

export const initialState = Object.freeze({
  ownerProductFiles: {
    isLoaded: false,
    items: [],
  },
  sellerInfo: {
    isLoaded: false,
  },
  fileStatuses: {
    isLoaded: false,
    items: {}, // map with key = fileId, and value = statuses
  },
  filesMap: {},
  ownerProductsMap: {},
});

function onLoadOwnerProductFiles(state) {
  return {
    ...state,
    ownerProductFiles: initialState.ownerProductFiles,
  };
}

function onLoadOwnerProductFilesSuccess(state, payload, meta) {
  return {
    ...state,
    ownerProductFiles: {
      isLoaded: true,
      items: payload.value || [],
    },
    ownerProductsMap: {
      ...state.ownerProductsMap,
      [meta.productId]: payload.value,
    },
  };
}

function onLoadSellerInfo(state) {
  return {
    ...state,
    sellerInfo: initialState.sellerInfo,
  };
}

function onLoadSellerInfoSuccess(state, payload) {
  return {
    ...state,
    sellerInfo: {
      isLoaded: true,
      ...payload.value,
    },
  };
}

function onLoadCustomerFilesStarted(state) {
  return {
    ...state,
    filesMap: initialState.filesMap,
  };
}

function onLoadCustomerFilesSuccess(state, { value }) {
  return {
    ...state,
    filesMap: value.reduce(
      (map, file) => ({
        ...map,
        [file.fileId]: file,
      }),
      {},
    ),
  };
}

export default function filesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.STARTED: {
      return onLoadOwnerProductFiles(state, action.payload);
    }

    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.SUCCEEDED: {
      return onLoadOwnerProductFilesSuccess(state, action.payload, action.meta);
    }

    case ActionTypes.LOAD_SELLER_INFO.STARTED: {
      return onLoadSellerInfo(state, action.payload);
    }

    case ActionTypes.LOAD_SELLER_INFO.SUCCEEDED: {
      return onLoadSellerInfoSuccess(state, action.payload);
    }

    case ActionTypes.LOAD_CUSTOMER_FILES_SUMMARY.STARTED: {
      return onLoadCustomerFilesStarted(state);
    }

    case ActionTypes.LOAD_CUSTOMER_FILES_SUMMARY.SUCCEEDED: {
      return onLoadCustomerFilesSuccess(state, action.payload);
    }

    default: {
      return state;
    }
  }
}
