import React, { PropsWithChildren, ReactElement } from 'react';
import { Row } from 'react-table';

import { SafeFormattedMessageWithoutSpread } from '../../formatters';

import { checkIsMessage } from './TableContent.helpers';
import { $CellContent, $StyledTd, $StyledTr } from './TableContent.styles';

interface TableBodyProps<D extends Record<string, unknown>> {
  rows: Row<D>[];
  prepareRow: (row: Row<D>) => void;
  dataTestId?: string;
  isNewUiEnabled: boolean;
}
const TableBody = <D extends Record<string, unknown>>({
  rows,
  prepareRow,
  dataTestId,
  isNewUiEnabled,
}: PropsWithChildren<TableBodyProps<D>>): ReactElement => {
  return (
    <>
      {rows.map((row, index) => {
        prepareRow(row);

        const { key, ...restRow } = row.getRowProps();

        return (
          <$StyledTr
            key={key}
            {...restRow}
            $isEven={(index + 1) % 2 === 0}
            $isNewUiEnabled={isNewUiEnabled}
          >
            {row.cells.map((cell, cellIndex) => {
              const isMessage = checkIsMessage(cell);

              const customProps = cell.column.getProps
                ? cell.column.getProps(cell)
                : {};

              const { key, ...restCell } = cell.getCellProps(customProps);

              return (
                <$StyledTd
                  key={key}
                  $isNewUiEnabled={isNewUiEnabled}
                  {...restCell}
                  data-testid={
                    dataTestId
                      ? `cell_${dataTestId}_${index}_${cellIndex}`
                      : undefined
                  }
                >
                  <$CellContent>
                    {isMessage ? (
                      <SafeFormattedMessageWithoutSpread message={cell.value} />
                    ) : (
                      cell.render('Cell')
                    )}
                  </$CellContent>
                </$StyledTd>
              );
            })}
          </$StyledTr>
        );
      })}
    </>
  );
};

TableBody.displayName = 'TableBody';

export default TableBody;
