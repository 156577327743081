import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { StockInventorySummaryDto } from './type/StockInventorySummaryDto';

const ENDPOINT = `${APIConfiguration.sparePart}stockInventory/create`;

export interface CreateStockInventoryCommandArgs {
  uniqueName: string;
  sellerId: string;
  stockIds: string[];
}

export default async function createStockInventoryCommand({
  uniqueName,
  sellerId,
  stockIds,
}: CreateStockInventoryCommandArgs): Promise<
  BackResponse<StockInventorySummaryDto> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<StockInventorySummaryDto>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.POST,
        json: { uniqueName, sellerId, stockIds },
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
