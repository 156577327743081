import { RESULT_TYPE } from '@savgroup-front-common/types';

import messages from './messages';

export const getMenuMessageByResultType = (type: RESULT_TYPE) => {
  switch (type) {
    case RESULT_TYPE.FILE:
      return messages.file;
    case RESULT_TYPE.ORDER:
      return messages.order;
    case RESULT_TYPE.SPARE_PART_REQUEST:
      return messages.sparePartRequest;
    case RESULT_TYPE.STOCK_ITEM:
      return messages.stockItem;

    default:
      return '';
  }
};
