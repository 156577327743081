import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}models/${COMMON_QUERY_PARAMS.MODEL_ID}/cumbersome`;

interface IsModelCumbersomeQueryArgs {
  sellerId: string;
  modelId: string;
}

export async function isModelCumbersomeQuery({
  sellerId,
  modelId,
}: IsModelCumbersomeQueryArgs): Promise<
  BackResponse<boolean> | BackResponseFailure
> {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.MODEL_ID, modelId),
      { sellerId },
    );

    const response = await apiCall<BackResponse<boolean>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
