import { getSparePartStockItemStatus } from '@savgroup-front-common/core/src/formatters';
import { RESULT_TYPE, StockItem } from '@savgroup-front-common/types';
import { SparePartService } from 'control/api';

import { buildStockItemUrl } from '../../../../../helpers';
import { SearchApiResult } from '../Search.types';

import smartFindItemHighlight from './smartFindItemHighlight';

const adaptOrderToSearchResult = (query: string) => (stockItem: StockItem) => {
  const { stockItemId, stockItemStatus, supplierName, sparePartReference } =
    stockItem;

  return {
    key: stockItemId,
    resultType: RESULT_TYPE.STOCK_ITEM,
    value: {
      reference: smartFindItemHighlight(query, sparePartReference),
      name: smartFindItemHighlight(query, supplierName),
      other: [],
      status: smartFindItemHighlight(
        query,
        getSparePartStockItemStatus(stockItemStatus),
      ),
      url: buildStockItemUrl({ stockItemId }),
    },
  };
};

const searchStockItems = async ({
  shouldLoad,
  query,
  isDescending,
  page,
  pageSize,
}: {
  page: number;
  pageSize?: number;
  query: string;
  shouldLoad: boolean;
  isDescending?: boolean;
}): Promise<SearchApiResult> => {
  if (!shouldLoad) {
    return {
      value: [],
      totalCount: 0,
      isFailure: false,
      errors: [],
    };
  }

  const response = await SparePartService.getStockItems({
    query,
    page,
    pageSize: pageSize || 7,
    isDescending,
  });

  if (response.failure) {
    return {
      value: [],
      totalCount: 0,
      isFailure: true,
      errors: response.errors,
    };
  }

  return {
    value: response.value.hits.map(adaptOrderToSearchResult(query)),
    totalCount: response.value.hitCount,
    isFailure: false,
    errors: [],
    hit: response.value.hits,
    hitCount: response.value.hitCount,
  };
};

export default searchStockItems;
