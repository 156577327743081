import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  REFUND_POLICY_TYPE,
  RefundPolicy,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}refundpolicy/${COMMON_QUERY_PARAMS.FILE_ID}`;

interface GetRefundPolicyQueryPayload {
  fileId: string;
  refundType?: REFUND_POLICY_TYPE;
}

const getRefundPolicyQuery = async ({
  fileId,
  refundType = REFUND_POLICY_TYPE.NOT_SET,
}: GetRefundPolicyQueryPayload): Promise<
  BackResponse<RefundPolicy> | BackResponseFailure
> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      { refundType },
    );

    return await apiCall<BackResponse<RefundPolicy>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getRefundPolicyQuery;
