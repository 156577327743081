import { AnyAction } from 'redux';
import { Action, ActionMeta } from 'redux-actions';

import { StockItemDetails } from '@savgroup-front-common/types';

import { UpdateStockItemDetailsStatusPayload } from './actionCreators';
import {
  GET_STOCK_ITEM_DETAILS,
  GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE,
  UPDATE_STOCK_ITEM_STATUS_DETAILS,
} from './actionTypes';
import { STOCK_ITEM_DETAILS_DOMAIN } from './constants';
import {
  GetStockItemDetailsBySparePartRequestLineResponse,
  GetStockItemDetailsResponse,
  StockItemDetailsDomainState,
} from './types';

export const initialStockItemDetailsState: StockItemDetailsDomainState = {
  [STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM]: {},
  [STOCK_ITEM_DETAILS_DOMAIN.BY_SPARE_PART_REQUEST_LINE_ID]: {},
};

interface OnGetStockItemDetailsSucceededAction extends AnyAction {
  payload: GetStockItemDetailsResponse;
}
function onGetStockItemDetailsSucceeded(
  state: StockItemDetailsDomainState,
  { payload }: OnGetStockItemDetailsSucceededAction,
) {
  const { value } = payload;

  return {
    ...state,
    [STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM]: {
      ...state[STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM],
      [value.stockItemId]: value,
    },
  };
}

type GetStockItemDetailsBySparePartRequestSucceededAction = ActionMeta<
  GetStockItemDetailsBySparePartRequestLineResponse,
  { sparePartRequestLineId: string }
>;

function onGetStockItemDetailsBySparePartRequestSucceeded(
  state: StockItemDetailsDomainState,
  { payload, meta }: GetStockItemDetailsBySparePartRequestSucceededAction,
) {
  const { value } = payload;
  const { sparePartRequestLineId } = meta;

  return {
    ...state,
    [STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM]: {
      ...state[STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM],
      ...value.reduce<Record<string, StockItemDetails>>((acc, stockItem) => {
        acc[stockItem.stockItemId] = stockItem;

        return acc;
      }, {}),
    },
    [STOCK_ITEM_DETAILS_DOMAIN.BY_SPARE_PART_REQUEST_LINE_ID]: {
      ...state[STOCK_ITEM_DETAILS_DOMAIN.BY_SPARE_PART_REQUEST_LINE_ID],
      [sparePartRequestLineId]: value.map((stockItem) => {
        return stockItem.stockItemId;
      }),
    },
  };
}

function onUpdateStockItemStatus(
  state: StockItemDetailsDomainState,
  { payload }: Action<UpdateStockItemDetailsStatusPayload>,
) {
  const { stockItemStatus, stockItemId } = payload;

  return {
    ...state,
    [STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM]: {
      ...state[STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM],
      [stockItemId]: {
        ...state[STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM]?.[stockItemId],
        stockItemStatus,
      },
    },
  };
}

export default function stockItemDetailsReducer(
  state: StockItemDetailsDomainState = initialStockItemDetailsState,
  action: AnyAction,
): StockItemDetailsDomainState {
  switch (action.type) {
    case GET_STOCK_ITEM_DETAILS.SUCCEEDED:
      return onGetStockItemDetailsSucceeded(
        state,
        action as OnGetStockItemDetailsSucceededAction,
      );
    case GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE.SUCCEEDED:
      return onGetStockItemDetailsBySparePartRequestSucceeded(
        state,
        action as GetStockItemDetailsBySparePartRequestSucceededAction,
      );

    case UPDATE_STOCK_ITEM_STATUS_DETAILS.BASE:
      return onUpdateStockItemStatus(
        state,
        action as Action<UpdateStockItemDetailsStatusPayload>,
      );

    default:
      return state;
  }
}
