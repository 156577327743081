import { defineMessages } from 'react-intl';

export default defineMessages({
  showMoreResult: {
    id: 'view.search.showMoreResultCount',
    defaultMessage: 'Show me more! - {count} results left',
  },
  select: {
    id: 'view.search.select',
    defaultMessage: 'Select',
  },
});
