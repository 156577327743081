import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { StockInventorySummaryDto } from './type/StockInventorySummaryDto';

const ENDPOINT = `${APIConfiguration.sparePart}stockInventory/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}`;

export interface GetStockInventoryArgs {
  stockInventoryId: string;
}

export default async function getStockInventory(
  payload: GetStockInventoryArgs,
): Promise<BackResponse<StockInventorySummaryDto> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<StockInventorySummaryDto>>(
      ENDPOINT.replace(
        COMMON_QUERY_PARAMS.STOCK_ITEM_ID,
        payload.stockInventoryId,
      ),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
