import get from 'lodash/get';
import head from 'lodash/head';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { onFileStateChanged } from './actionCreators';
import { GET_FILE_STATES } from './actionTypes';
import { selectLatestFileStateByFileId } from './selectors';

function isFileStateChanged({ oldLatestFileState, newLatestFileState }) {
  return (
    get(oldLatestFileState, ['state', 'name'], undefined) !== undefined &&
    get(oldLatestFileState, ['state', 'name']) !==
      get(newLatestFileState, ['state', 'name'])
  );
}

export function* loadFileStatesWorker({ payload: fileId }) {
  const oldLatestFileState = yield select(
    selectLatestFileStateByFileId,
    fileId,
  );

  if (!fileId) {
    yield put(GET_FILE_STATES.end(fileId));

    return;
  }

  const response = yield callAndGetResponse(
    GET_FILE_STATES,
    `${APIConfiguration.workflow}files/${fileId}/states`,
    { method: SUPPORTED_METHODS.GET },
    {
      fileId,
      indexer: fileId,
    },
  );

  const newLatestFileState = head(
    get(
      response,
      ['payload', 'value'],
      [
        {
          state: {},
          module: {},
        },
      ],
    ),
  ) || {
    state: {},
    module: {},
  };

  if (isFileStateChanged({ oldLatestFileState, newLatestFileState })) {
    yield put(
      onFileStateChanged({
        fileId,
      }),
    );
  }

  yield put(GET_FILE_STATES.end(fileId));
}
function* loadFileStatesWatcher() {
  yield takeEvery(GET_FILE_STATES.BASE, loadFileStatesWorker);
}

export default function* workflowSaga() {
  try {
    yield all([loadFileStatesWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
