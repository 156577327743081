import styled from 'styled-components';

import { Link } from '../../../../../atoms/link';

export const $ToastLink = styled(Link)`
  color: white;
  display: block;
  text-decoration: underline;
  font-weight: 700;
  margin-top: 0.15rem;
`;

export const $ToastTime = styled.p`
  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
`;

export const $ToastBody = styled.p`
  color: white;
  display: block;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 0.75rem;
  margin: 0;
`;
