import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}users/invite`;

const inviteUserCommand = async ({
  email,
  lastName,
  firstName,
  language,
}: {
  email: string;
  lastName: string;
  firstName: string;
  language: COUNTRY_CODES;
}): Promise<BackResponseFailure | BaseBackResponse> => {
  try {
    return await apiCall<BackResponse<BaseBackResponse>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        email,
        lastName,
        firstName,
        language,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default inviteUserCommand;
