import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  CUSTOMER_TYPE,
  PAYMENT_TYPE,
} from '@savgroup-front-common/types';
import { BILLING_MODE } from '@savgroup-front-common/types/src/Billing';

export const ENDPOINT = `${APIConfiguration.owner}clients`;

export interface SetUpsertClientCommandArgs {
  clientId?: string;
  tenantId: string;
  sellerId: string;
  customerType: CUSTOMER_TYPE;
  externalId?: string;
  companyname: string;
  email: string;
  deliveryAddressInfo: {
    address: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
  billingAddressInfo: {
    address: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
  taxIdentificationNumber: string;
  vatIdentificationNumber: string;
  paymentType: PAYMENT_TYPE;
  billingMode: BILLING_MODE;
  paymentTermDays: number;
  preferedLanguage: string;
}

const setUpsertClientCommandQuery = async (
  payload: SetUpsertClientCommandArgs,
): Promise<BackResponse<string> | BackResponseFailure> => {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        ...payload,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default setUpsertClientCommandQuery;
