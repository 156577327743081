import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { resetCfDebug } from 'control/domains/debug/actions';

const useResetDebug = () => {
  const storeDispatch = useDispatch();

  const resetDebug = useCallback(
    ({ key }: { key: string }) => {
      storeDispatch(resetCfDebug({ origin: key }));
    },
    [storeDispatch],
  );

  return resetDebug;
};

export default useResetDebug;
