import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  ActorTypes,
  COMMON_QUERY_PARAMS,
} from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}stocks/seller/${COMMON_QUERY_PARAMS.SELLER_ID}`;

export interface GetStocksBySitesCommandArgs {
  userSiteSummaries: {
    id: string;
    name: string;
    type: ActorTypes;
  }[];
}

interface StockLabel {
  stockId: string;
  stockName: string;
}

export default async function getStocksBySitesCommand(
  payload: GetStocksBySitesCommandArgs,
): Promise<BackResponse<StockLabel[]> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<StockLabel[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
