import { RESULTS_COUNT_DISPLAYED } from '../Search.contants';
import { SearchResult } from '../Search.types';

import getResultListSize from './getResultListSize';

const getContainerSizeForMoreResults = ({
  previousContainerSize,
  results,
  totalCount,
  incommingResults,
}: {
  previousContainerSize: number;
  totalCount: number;
  results: SearchResult[];
  incommingResults: SearchResult[];
}): number => {
  const getGreaterContainerSize = (result: SearchResult[], cut?: number) => {
    const size = getResultListSize(result, cut);

    return previousContainerSize < size ? size : previousContainerSize;
  };

  if (
    RESULTS_COUNT_DISPLAYED > totalCount + 1 &&
    results.length === totalCount + 1
  ) {
    return getResultListSize(results);
  }

  if (incommingResults.length < RESULTS_COUNT_DISPLAYED) {
    const lastIndex = results.length - RESULTS_COUNT_DISPLAYED - 1;

    return getGreaterContainerSize(
      results.filter((_result, index) => lastIndex <= index),
      RESULTS_COUNT_DISPLAYED,
    );
  }

  return getGreaterContainerSize(incommingResults);
};

export default getContainerSizeForMoreResults;
