import moment from 'moment';
import { useCallback, useState } from 'react';

import { useInterval } from '@savgroup-front-common/core/src/hooks';
import { useAppStatuses } from '@savgroup-front-common/core/src/molecules/AppStatuses';
import { SCHEDULED_MAINTENANCE_STATUSES } from '@savgroup-front-common/types';

import { LOGO_MAINTENANCE_DISPLAY_TYPES } from '../HeaderLogo.types';

const useMaintenanceStatus = (): LOGO_MAINTENANCE_DISPLAY_TYPES => {
  const { nextScheduledMaintenance } = useAppStatuses();

  const [countBetween, setMaintenanceCount] = useState(0);
  const [maintenanceMode, setMaintenanceDisplay] = useState(
    LOGO_MAINTENANCE_DISPLAY_TYPES.IDDLE,
  );

  const getDisplayMode = useCallback(() => {
    if (!nextScheduledMaintenance) {
      return LOGO_MAINTENANCE_DISPLAY_TYPES.IDDLE;
    }
    if (
      nextScheduledMaintenance.status ===
      SCHEDULED_MAINTENANCE_STATUSES.IN_PROGRESS
    ) {
      return LOGO_MAINTENANCE_DISPLAY_TYPES.IN_PROGRESS;
    }
    if (
      nextScheduledMaintenance.status ===
      SCHEDULED_MAINTENANCE_STATUSES.VERIFYING
    ) {
      return LOGO_MAINTENANCE_DISPLAY_TYPES.VERIFYING;
    }

    const now = moment();

    const maintenanceMomentDate = moment(
      nextScheduledMaintenance?.scheduledFor,
    );

    const isScheduledMaintenanceToday = maintenanceMomentDate.isSame(
      now,
      'day',
    );

    const duration = moment.duration(maintenanceMomentDate.diff(now));
    const isScheduledMaintenanceNextHours = duration.asHours() < 1;
    const isScheduledMaintenanceNow = duration.asHours() <= 0;

    if (isScheduledMaintenanceToday && isScheduledMaintenanceNow) {
      return LOGO_MAINTENANCE_DISPLAY_TYPES.IMMINENT;
    }

    if (isScheduledMaintenanceToday && isScheduledMaintenanceNextHours) {
      return LOGO_MAINTENANCE_DISPLAY_TYPES.TIMER;
    }
    if (isScheduledMaintenanceToday) {
      setMaintenanceCount((countBetween) => countBetween + 1);
      if (countBetween % 20 === 0) {
        return LOGO_MAINTENANCE_DISPLAY_TYPES.SCHEDULED_TODAY;
      }
    }

    return LOGO_MAINTENANCE_DISPLAY_TYPES.IDDLE;
  }, [countBetween, nextScheduledMaintenance]);

  const updateDisplayMode = useCallback(() => {
    setMaintenanceDisplay(getDisplayMode());
  }, [getDisplayMode]);

  useInterval(updateDisplayMode, 10000);

  return maintenanceMode;
};

export default useMaintenanceStatus;
