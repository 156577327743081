import get from 'lodash/get';
import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains';

import { CLAIMS_DOMAIN, CLAIMS_DOMAIN_KEY } from './constants';
import { ClaimDomainState } from './types';

interface State extends CommonAppState {
  [CLAIMS_DOMAIN_KEY]: ClaimDomainState;
}

const claimState = (state: State) => state[CLAIMS_DOMAIN_KEY];

export const selectDocumentByClaimId = createSelector(
  [claimState, (_: State, { claimId }: { claimId?: string }) => claimId],
  (state, claimId) => {
    return claimId ? get(state[CLAIMS_DOMAIN.DOCUMENTS], claimId) || [] : [];
  },
);
