import { defineMessages } from 'react-intl';

export default defineMessages({
  showMore: {
    id: 'view.messagesFilters.showMore',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'view.messagesFilters.showLess',
    defaultMessage: 'Show less',
  },
});
