import React from 'react';
import { useSelector } from 'react-redux';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { Selectors as SellerConfigurationSelectors } from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useControlTypedSelector } from 'control/hooks';

import { $GoToSelfcareButton } from './GoToSelfcareButton.styles';
import messages from './messages';

const GoToSelfcareButton = () => {
  const sellers = useSelector(sellerSelectors.selectSellers);
  const firstSeller = sellers?.[0];
  const sellerConfig = useControlTypedSelector((stateStore) =>
    SellerConfigurationSelectors.selectSellerConfigBySellerId(stateStore, {
      sellerId: firstSeller?.id || '',
    }),
  );

  if (!sellerConfig) {
    return null;
  }

  return (
    <$GoToSelfcareButton>
      <ButtonLink
        href={`${sellerConfig.myAccountUrl.replace('.', '.auth.')}new_design`}
        isExternal
        target="_blank"
        primary
        small
      >
        <SafeFormattedMessageWithoutSpread
          message={messages.goToSelfcareButton}
        />
      </ButtonLink>
    </$GoToSelfcareButton>
  );
};

GoToSelfcareButton.displayName = 'GoToSelfcareButton';

export default GoToSelfcareButton;
