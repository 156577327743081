import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfo,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { ConsoleDataCellType } from './getMatchingRulesInfoCommand';

import { EnrichedValue } from './getEntityDefinitionQuery';

export interface ConsoleEntityKey {
  id: string;
  sellerId: string;
  uniqueName: string;
  displayName: string;
}

export interface DebugInfo {
  isMatch: boolean;
  isWildcard: boolean;
  name: string;
  score: number;
}

export type ConsoleDataCell = {
  value: string;
  enrichedValue?: EnrichedValue;
  values: string[];
  enrichedValues?: EnrichedValue[];
  entityKey?: ConsoleEntityKey;
  entityKeys?: ConsoleEntityKey[];
  type: ConsoleDataCellType;
  address?: AddressInfo;
  contact?: {
    mail: string;
    tel: string;
  };
  children: Record<string, ConsoleDataCell>[];
  debugInfo?: DebugInfo;
};

export type GetEntityBySellerQueryReturnValue = Record<
  string,
  ConsoleDataCell
>[];

interface GetEntityBySellerQueryPayload {
  sellerId: string;
  entityDefinition: string;
  page: number;
  pageSize: number;
}

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entity/${COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION}`;

const getEntityBySellerQuery = async ({
  sellerId,
  entityDefinition,
  page,
  pageSize,
}: GetEntityBySellerQueryPayload): Promise<
  BackResponse<GetEntityBySellerQueryReturnValue> | BackResponseFailure
> => {
  const url = buildUrl(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
      COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION,
      entityDefinition,
    ),
    { page, pageSize },
  );

  try {
    return await apiCall<BackResponse<GetEntityBySellerQueryReturnValue>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getEntityBySellerQuery;
