import React, { FunctionComponent } from 'react';

import { Tooltip } from '@savgroup-front-common/core/src/atoms/Tooltip';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { MessageType } from '@savgroup-front-common/types';

import { $OtherRoles, $ProfileRole } from './HeaderProfileMenu.styles';

interface HeaderProfileRolesProps {
  list: string[];
  message: MessageType;
}

const HeaderProfileRoles: FunctionComponent<
  React.PropsWithChildren<HeaderProfileRolesProps>
> = ({ list, message }) => {
  const [name, ...rest] = list;
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$ProfileRole $isNewUiEnabled={isNewUiEnabled}>
      <SafeFormattedMessageWithoutSpread
        message={message}
        values={{
          bold: (chunks: string) => <strong>{chunks}</strong>,
          tooltip: (chunks: string) => (
            <$OtherRoles>
              <Tooltip title={rest.join('<br />')} theme="light">
                {chunks}
              </Tooltip>
            </$OtherRoles>
          ),
          count: rest.length,
          name,
        }}
      />
    </$ProfileRole>
  );
};

HeaderProfileRoles.displayName = 'HeaderProfileRoles';

export default HeaderProfileRoles;
