import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const TODOS_SEARCH = createRequest('TODOS_SEARCH');
export const TODOS_LIST_CHANGE_FILTER = createRequest(
  'TODOS_LIST_CHANGE_FILTER',
);
export const TODOS_LIST_CHANGE_MULTPLE_FILTER = createRequest(
  'TODOS_LIST_CHANGE_MULTPLE_FILTER',
);
export const TODOS_LIST_LOCK_FILTER = createRequest('TODOS_LIST_LOCK_FILTER');
export const TODOS_LIST_CHANGE_SEARCH_QUERY = createRequest(
  'TODOS_LIST_CHANGE_SEARCH_QUERY',
);
export const TOGGLE_TODOS_LIST_RESULTS_ORDER = createRequest(
  'TOGGLE_TODOS_LIST_SEARCH_ORDER',
);
export const TODOS_LIST_SELECT_PAGE = createRequest('TODOS_LIST_SELECT_PAGE');
export const TODOS_LIST_RESET_FILTERS = createRequest(
  'TODOS_LIST_RESET_FILTERS',
);
export const TODOS_LIST_RESET_FILTERS_AND_LOCK = createRequest(
  'TODOS_LIST_RESET_FILTERS_AND_LOCK',
);
export const REORDER_TODOS_LIST_FILTERS = createRequest(
  'REORDER_TODOS_LIST_FILTERS',
);
export const REHYDRATE_TODOS_SEARCH = createRequest('REHYDRATE_TODOS_SEARCH');
