import styled from 'styled-components';

export const $Resizer = styled.div`
  position: absolute;
  right: -12px;
  bottom: -12px;
  cursor: se-resize;
  margin: 5px;

  transition: all ease-in-out 100ms;
  border-bottom: dashed 6px ${({ theme }) => theme.colors.primary};
  border-right: dashed 6px ${({ theme }) => theme.colors.primary};

  &:hover {
    border-bottom: solid 7px ${({ theme }) => theme.colors.primary};
    border-right: solid 7px ${({ theme }) => theme.colors.primary};
  }
`;
