import styled from 'styled-components';

export const ShowMoreContainer = styled.div`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;

export const $LinkActionContainer = styled.div`
  text-align: center;
`;
