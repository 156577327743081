import { createAction } from '@reduxjs/toolkit';

import {
  OnFacetChangeArgs,
  OnFacetLockArgs,
  OnMultipleFacetChangeArgs,
} from '@savgroup-front-common/types';

import {
  REHYDRATE_TODOS_SEARCH,
  REORDER_TODOS_LIST_FILTERS,
  TODOS_LIST_CHANGE_FILTER,
  TODOS_LIST_CHANGE_MULTPLE_FILTER,
  TODOS_LIST_CHANGE_SEARCH_QUERY,
  TODOS_LIST_LOCK_FILTER,
  TODOS_LIST_RESET_FILTERS,
  TODOS_LIST_RESET_FILTERS_AND_LOCK,
  TODOS_LIST_SELECT_PAGE,
  TODOS_SEARCH,
  TOGGLE_TODOS_LIST_RESULTS_ORDER,
} from './actionTypes';

export const searchTodosList = createAction(TODOS_SEARCH.BASE);

export const changeTodosListFilter = createAction<OnFacetChangeArgs>(
  TODOS_LIST_CHANGE_FILTER.BASE,
);

export const changeMultipleTodosListFilter =
  createAction<OnMultipleFacetChangeArgs>(
    TODOS_LIST_CHANGE_MULTPLE_FILTER.BASE,
  );

export const lockTodosListFilter = createAction<OnFacetLockArgs>(
  TODOS_LIST_LOCK_FILTER.BASE,
);

export interface ChangeTodosListSearchQueryPayload {
  query: string;
}
export const changeTodosListSearchQuery =
  createAction<ChangeTodosListSearchQueryPayload>(
    TODOS_LIST_CHANGE_SEARCH_QUERY.BASE,
  );
export const toggleTodosListSearchOrder = createAction(
  TOGGLE_TODOS_LIST_RESULTS_ORDER.BASE,
);

export interface ChangeTodosListPagePayload {
  page: number;
}
export const changeTodosListPage = createAction<ChangeTodosListPagePayload>(
  TODOS_LIST_SELECT_PAGE.BASE,
);
export const reorderTodosListFilters = createAction(
  REORDER_TODOS_LIST_FILTERS.BASE,
);
export const resetTodosListFilters = createAction(
  TODOS_LIST_RESET_FILTERS.BASE,
);
export const resetTodosListLocksAndFilters = createAction(
  TODOS_LIST_RESET_FILTERS_AND_LOCK.BASE,
);
export const rehydrateTodosListSearch = createAction(
  REHYDRATE_TODOS_SEARCH.BASE,
);
