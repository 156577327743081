import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

const AddressInformationsSchema = Yup.object().shape({
  ownerAddress: Yup.object().shape({
    firstName: Yup.string().required(globalMessages.form.required),
    lastName: Yup.string().required(globalMessages.form.required),
    companyName: Yup.string(),
    phoneNumber: Yup.string().required(globalMessages.form.required),
    country: Yup.object().required(globalMessages.form.required),
    address: Yup.string().required(globalMessages.form.required),
    additionalAddress: Yup.string(),
    postalCode: Yup.string().required(globalMessages.form.required),
    city: Yup.string().required(globalMessages.form.required),
  }),
  ownerAddressOnFile: Yup.object().when('isOwnerAddressOnFileSame', {
    is: false,
    then(schema) {
      return schema.shape({
        firstName: Yup.string().required(globalMessages.form.required),
        lastName: Yup.string().required(globalMessages.form.required),
        companyName: Yup.string(),
        phoneNumber: Yup.string().required(globalMessages.form.required),
        country: Yup.object().required(globalMessages.form.required),
        address: Yup.string().required(globalMessages.form.required),
        additionalAddress: Yup.string(),
        postalCode: Yup.string().required(globalMessages.form.required),
        city: Yup.string().required(globalMessages.form.required),
      });
    },
  }),
});

export default AddressInformationsSchema;
