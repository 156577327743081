import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { LANGUAGES } from '@savgroup-front-common/constants';
import {
  changeLocaleLanguage,
  setUserLanguagePreference,
} from '@savgroup-front-common/core/src/domains/i18n/actions';
import { signOut } from 'control/domains/login/actions';
import { useOidcContext } from 'control/view/app/login/OidcProvider';

const useProfileActions = () => {
  const { userManager } = useOidcContext();
  const dispatch = useDispatch();

  const updateIntl = useCallback(
    (locale: LANGUAGES) => {
      dispatch(setUserLanguagePreference({ locale }));
      dispatch(changeLocaleLanguage({ locale }));
    },
    [dispatch],
  );
  const logout = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(signOut);

    await userManager.current.signoutRedirect();
  }, [dispatch, userManager]);

  return {
    logout,
    updateIntl,
  };
};

export default useProfileActions;
