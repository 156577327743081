import { COMMON_STEPS } from '@savgroup-front-common/constants';

import messages from './messages';
import { AddressInformations, AddressSuggestion } from './steps';

export enum EDIT_ADDRESS_STEP_NAMES {
  ADDRESS_INFORMATIONS = 'ADDRESS_INFORMATIONS',
  ADDRESS_SUGGESTION = 'ADDRESS_SUGGESTION',
}

export const EDIT_ADDRESS_STEPS_CONFIG = [
  {
    name: EDIT_ADDRESS_STEP_NAMES.ADDRESS_INFORMATIONS,
    Component: AddressInformations,
    nextStep: EDIT_ADDRESS_STEP_NAMES.ADDRESS_SUGGESTION,
    message: messages.addressInformations,
  },
  {
    name: EDIT_ADDRESS_STEP_NAMES.ADDRESS_SUGGESTION,
    Component: AddressSuggestion,
    nextStep: COMMON_STEPS.SUBMIT,
    message: messages.addressSuggestion,
  },
];
