import React from 'react';

import Icon from './Icon';

interface QuestionMarkIconProps {
  color?: string;
  size?: string;
}

const QuestionMarkIcon: React.FC<QuestionMarkIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M13.1667 13.4667C13.1667 5.51167 25.6667 5.51167 25.6667 13.4667C25.6667 19.1483 19.9851 18.0117 19.9851 24.83M20.0001 31.6833L20.0167 31.665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

QuestionMarkIcon.displayName = 'QuestionMarkIcon';

export default QuestionMarkIcon;
