export enum APP_STATUS_EVENT_TYPES {
  SCHEDULED_MAINTENANCE = 'scheduledMaintenance',
  INCIDENT = 'incident',
}

export enum INCIDENT_STATUSES {
  INVESTIGATING = 'investigating',
  IDENTIFIED = 'identified',
  MONITORING = 'monitoring',
  RESOLVED = 'resolved',
  POSTMORTEM = 'postmortem',
}

export enum SCHEDULED_MAINTENANCE_STATUSES {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  VERIFYING = 'verifying',
  COMPLETED = 'completed',
}

export enum APP_STATUS_IMPACT {
  NONE = 'none',
  MINOR = 'minor',
  MAJOR = 'major',
  CRITICAL = 'critical',
}

export enum APP_COMPONENT_STATUSES {
  OPERATIONAL = 'operational',
  DEGRADED_PERFORMANCE = 'degraded_performance',
  PARTIAL_OUTAGE = 'partial_outage',
  MAJOR_OUTAGE = 'major_outage',
  UNDER_MAINTENANCE = 'under_maintenance',
}

export interface AppStatusUpdate<Status> {
  body: string;
  createdAt: string;
  displayAt: string;
  id: string;
  incidentId: string;
  status: Status;
  updatedAt: string;
}

export interface AppComponent {
  createdAt: string;
  description: string | null;
  group: boolean;
  groupId: string | null;
  id: string;
  name: string;
  onlyShowIfDegraded: boolean;
  pageId: string;
  position: number;
  showcase: boolean;
  startDate: string;
  status: APP_COMPONENT_STATUSES;
  updatedAt: string;
}

export interface AppIncident {
  components: AppComponent[];
  createdAt: string;
  id: string;
  impact: APP_STATUS_IMPACT;
  incidentUpdates: AppStatusUpdate<INCIDENT_STATUSES>[];
  monitoringAt: null;
  name: string;
  pageId: string;
  resolvedAt: null;
  shortlink: string;
  status: INCIDENT_STATUSES;
  updatedAt: string;
}
export interface AppScheduledMaintenance {
  components: AppComponent[];
  createdAt: string;
  id: string;
  impact: APP_STATUS_IMPACT;
  scheduledUntil: string;
  scheduledFor: string;
  incidentUpdates: AppStatusUpdate<SCHEDULED_MAINTENANCE_STATUSES>[];
  monitoringAt: null;
  name: string;
  pageId: string;
  resolvedAt: null;
  shortlink: string;
  status: SCHEDULED_MAINTENANCE_STATUSES;
  updatedAt: string;
}
