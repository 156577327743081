import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  ActorTypes,
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/sellerActors`;

interface GetFileSellerActorsQueryPayload {
  fileId: string;
}

export interface GetMatchingActorsQueryResponseValue {
  identity: {
    id: string;
    type: ActorTypes;
  };
  label: string;
}

export async function getFileSellerActorsQuery({
  fileId,
}: GetFileSellerActorsQueryPayload): Promise<
  BackResponse<GetMatchingActorsQueryResponseValue[]> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<GetMatchingActorsQueryResponseValue[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
