import { darken } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $ProfilButtonContainer = styled.div`
  position: relative;
  > div {
    margin-right: ${rem(2)};
  }
`;

export const $ProfilButton = styled.button<{ $isNewUiEnabled: boolean }>`
  outline: 0;
  border: none;
  min-width: ${rem(50)};
  height: ${rem(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  transition-property: background-color;
  &:focus,
  &:hover {
    background-color: ${({ theme, $isNewUiEnabled }) =>
      $isNewUiEnabled ? 'unset' : darken(0.15, theme.colors.primary)};
  }
  @media ${media.minWidth.lg} {
    min-width: ${({ $isNewUiEnabled }) =>
      $isNewUiEnabled ? 'initiale' : rem(70)};
    height: ${({ $isNewUiEnabled }) =>
      $isNewUiEnabled ? 'initiale' : rem(70)};
  }
  position: relative;
  background-color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? 'unset' : theme.colors.darkBackground};
`;

export const $MenuVerticalPosition = styled.div<{ $isNewUiEnabled: boolean }>`
  ul {
    margin-top: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '0' : null)};
  }
`;
