import { ActionMeta } from 'redux-actions';

import { ProductSupplier } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { PRODUCT_SUPPLIER_DOMAIN } from './constants';
import { ProductSupplierDomainState } from './types';

export const initialProductSupplierState: ProductSupplierDomainState = {
  [PRODUCT_SUPPLIER_DOMAIN.VALUE]: {},
};

export interface OnGetProductSupplierSuccessPayload {
  value: ProductSupplier;
}

function onGetProductSupplierSuccess(
  state: ProductSupplierDomainState,
  { payload }: { payload: OnGetProductSupplierSuccessPayload },
): ProductSupplierDomainState {
  const { value: productSupplier } = payload;

  return {
    ...state,
    [PRODUCT_SUPPLIER_DOMAIN.VALUE]: {
      ...state[PRODUCT_SUPPLIER_DOMAIN.VALUE],
      [productSupplier.id]: productSupplier,
    },
  };
}

export default function mainReducer(
  state: ProductSupplierDomainState = initialProductSupplierState,
  action: ActionMeta<OnGetProductSupplierSuccessPayload, unknown>,
): ProductSupplierDomainState {
  switch (action.type) {
    case types.GET_PRODUCT_SUPPLIER.SUCCEEDED:
      return onGetProductSupplierSuccess(state, action);

    default:
      return state;
  }
}
