import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  ADDITIONAL_INFORMATION_TYPES,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/reasons`;

interface AdditionalInformation {
  id: string;
  key: string;
  name: string;
  isRequired: boolean;
  uniqueName: string;
  type: ADDITIONAL_INFORMATION_TYPES;
  uniquenessByProduct: boolean;
  description: string;
  displayOrder: number;
  possibleValues: AdditionalInformationPossibleValue[];
}
interface AdditionalInformationPossibleValue {
  id: string;
  name: string;
  key: string;
}

interface ReasonSummary {
  id: string;
  name: string;
  key: string;
  mandatoryComment: boolean;
  mandatoryDocument: boolean;
  kind: 'Normal' | 'OtherProblem';
  displayOrder: number;
  didactic: string;
  neededInformation: AdditionalInformation[];
}

async function getClaimReasons({
  claimId,
}: {
  claimId: string;
}): Promise<BackResponse<ReasonSummary[]> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<ReasonSummary[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getClaimReasons;
