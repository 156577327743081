import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const RESET_FILTERS = createRequest('RESET_FILTERS');

export const CHANGE_TODOS_FILTER = 'CHANGE_TODOS_FILTER';
export const CHANGE_DASHBOARD_SEARCH_QUERY = 'CHANGE_DASHBOARD_SEARCH_QUERY';

export const CHANGE_MESSAGES_FILTER = 'CHANGE_MESSAGES_FILTER';
export const CHANGE_MESSAGES_SORT = 'CHANGE_MESSAGES_SORT';
export const CHANGE_MESSAGES_SEARCH_QUERY = 'CHANGE_MESSAGES_SEARCH_QUERY';

export const CHANGE_ALERTS_FILTER = createRequest('CHANGE_ALERTS_FILTER');
