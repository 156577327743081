import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useTheme } from 'styled-components';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { RESULT_TYPE } from '@savgroup-front-common/types';

import { useSearchContext } from '../../../hooks';
import { SEARCH_HEIGHT } from '../../../Search.contants';
import SearchItemContainer from '../../common/SearchItemContainer';

import messages from './messages';
import {
  $ResultSelectIcon,
  $ShowMore,
  $ShowMoreContainer,
  $ShowMoreTextContainer,
} from './SearchItemShowMoreResult.styles';
import {
  ChevronDownIcon,
  KeyboardReturnIcon,
} from '@savgroup-front-common/core/src/protons/icons';

interface SearchItemShowMoreResultProps {
  type: RESULT_TYPE;
  count: number;
  animationIndex: number;
  animationState: TransitionStatus;
  isFocus: boolean;
}

const SearchItemShowMoreResult: FunctionComponent<
  React.PropsWithChildren<SearchItemShowMoreResultProps>
> = ({ animationIndex, animationState, type, isFocus, count }) => {
  const theme = useTheme();

  const { disableHover, onShowMore, isLoading, focusedResultType } =
    useSearchContext();

  return (
    <SearchItemContainer
      animationState={animationState}
      animationIndex={animationIndex}
      height={SEARCH_HEIGHT.SHOW_MORE_RESULT}
    >
      <$ShowMore
        type="button"
        disabled={isLoading}
        $isFocus={isFocus}
        $disableHover={disableHover}
        onClick={() => onShowMore(type)}
        animationState={animationState}
        data-testid={`show-more-result-${type}`}
      >
        <$ShowMoreContainer>
          <$ShowMoreTextContainer>
            <SafeFormattedMessageWithoutSpread
              message={messages.showMoreResult}
              values={{ count }}
            />

            <ChevronDownIcon />
          </$ShowMoreTextContainer>
          {focusedResultType === type && isLoading && (
            <Loader strokeWidth="10" />
          )}
        </$ShowMoreContainer>

        <$ResultSelectIcon $isFocus={isFocus}>
          <KeyboardReturnIcon color={theme.colors.primary} />
          <SafeFormattedMessageWithoutSpread message={messages.select} />
        </$ResultSelectIcon>
      </$ShowMore>
    </SearchItemContainer>
  );
};

SearchItemShowMoreResult.displayName = 'SearchItemShowMoreResult';

export default SearchItemShowMoreResult;
