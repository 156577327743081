import get from 'lodash/get';
import { all, call, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';
import { logCritical } from 'control/configuration';

import * as businessConst from '../businessConst';

export function* getTransportInformationWorker({ payload }) {
  const fileId = get(payload, 'fileId');

  if (!fileId) {
    return;
  }

  yield call(
    callAndGetResponse,
    businessConst.GET_TRANSPORT_INFO,
    `${APIConfiguration.workflow}files/customerfile/${fileId}/handling`,
    { method: SUPPORTED_METHODS.GET },
    { fileId },
  );
}

function* getTransportInformationWatcher() {
  yield takeEvery(
    businessConst.GET_TRANSPORT_INFO.BASE,
    getTransportInformationWorker,
  );
}

export default function* transportSaga() {
  try {
    yield all([getTransportInformationWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
