import { useMemo } from 'react';

import { SHORTCUT_LIST_CONTROL } from '@savgroup-front-common/constants';
import { MenuItem } from '@savgroup-front-common/core/src/atoms/Menu';
import { MENU_ITEM_TYPES } from '@savgroup-front-common/core/src/atoms/Menu/Menu.types';
import { useMultipleShortCuts } from '@savgroup-front-common/core/src/hooks';
import { Shortcut } from '@savgroup-front-common/core/src/hooks/useShortCut';

const bind = [
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_1,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_2,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_3,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_4,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_5,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_6,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_7,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_8,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_9,
];

const useAuthorizedCreateShortcuts = ({
  autorizedCreateOptions,
  onCreateModalOpen,
}: {
  autorizedCreateOptions: MenuItem[];
  onCreateModalOpen: (menuItem: MenuItem) => void;
}): void => {
  const shortcuts = useMemo(() => {
    return autorizedCreateOptions
      .filter((option) => option.type === MENU_ITEM_TYPES.BUTTON)
      .reduce<Shortcut[]>((acc, option, index) => {
        return [
          ...acc,
          { callback: () => onCreateModalOpen(option), codes: bind[index] },
        ];
      }, []);
  }, [autorizedCreateOptions, onCreateModalOpen]);

  useMultipleShortCuts(shortcuts);
};

export default useAuthorizedCreateShortcuts;
