import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { ANIMATION_DIRECTION } from '@savgroup-front-common/constants/src/shared/animation';
import { useAttachmentPreview } from '@savgroup-front-common/core/src/hooks';
import {
  ActionCreators as AttachmentsActionCreators,
  Selectors as AttachmentsSelectors,
} from '@savgroup-front-common/core/src/domains/attachments';

import { useControlTypedSelector } from '../../../hooks';

import { DocumentPreviewAttachment } from './DocumentPreview.types';

interface UseDocumentDataReturnValue {
  documentName?: string;
  blobDocumentUrl?: string;
  blobDocumentType?: string;
}

interface UseDocumentIndexReturnValue {
  animationType: ANIMATION_DIRECTION;
  handleChange: (index: number) => void;
  activePreview: number;
}

export const useDocumentData = ({
  attachment,
}: {
  attachment: DocumentPreviewAttachment;
}): UseDocumentDataReturnValue => {
  const dispatch = useDispatch();

  const documentName = get(attachment, 'documentName');
  const url = get(attachment, 'url');
  const type = get(attachment, 'type');
  const forceUrl = get(attachment, 'forceUrl');

  const isUrlReady =
    startsWith(url, 'https://') || startsWith(url, '//localhost/');
  const formattedUrl =
    isUrlReady && url ? url : `${APIConfiguration.base}${url}`;

  useEffect(() => {
    if (url && documentName) {
      dispatch(
        AttachmentsActionCreators.loadDocumentByUrl({
          url: formattedUrl,
          fileName: documentName,
        }),
      );
    }
  }, [dispatch, documentName, formattedUrl, url]);

  const attachmentValue = useControlTypedSelector((state) =>
    AttachmentsSelectors.getAttachmentsFilesValue(state, formattedUrl),
  );

  const blobDocumentUrl = useAttachmentPreview(attachmentValue);

  if (forceUrl) {
    return {
      blobDocumentUrl: formattedUrl,
      blobDocumentType: type,
      documentName,
    };
  }

  return {
    documentName,
    blobDocumentUrl,
    blobDocumentType: get(attachmentValue, ['data', 'type']),
  };
};

export const useDocumentIndex = ({
  currentActivePreview = 0,
}: {
  currentActivePreview?: number;
}): UseDocumentIndexReturnValue => {
  const [activePreview, setActivePreview] = useState(currentActivePreview);
  const [animationType, setAnimationType] = useState(ANIMATION_DIRECTION.NEXT);

  const handleChange = useCallback(
    (index: number) => {
      setActivePreview(index);
      setAnimationType(
        activePreview < index
          ? ANIMATION_DIRECTION.NEXT
          : ANIMATION_DIRECTION.PREVIOUS,
      );
    },
    [activePreview],
  );

  useEffect(() => {
    setActivePreview(currentActivePreview);
  }, [currentActivePreview]);

  return {
    animationType,
    handleChange,
    activePreview,
  };
};
