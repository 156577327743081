import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { EVENT_CODE, EVENT_KEY } from '@savgroup-front-common/constants';
import {
  KEYBOARD_LAYOUT,
  LOCAL_STORAGE_KEYS,
} from '@savgroup-front-common/types';

import { getFromLocalStorage, setToLocalStorage } from '../../helpers';

import { isAzertyLayout, isQwertyLayout, isQwertzLayout } from './helpers';

interface KeyboardLayoutContextReturnValue {
  changeKeyboardLayout: (layout: KEYBOARD_LAYOUT) => void;
  keyboardLayout: KEYBOARD_LAYOUT;
}

export const KeyboardLayoutContext =
  React.createContext<KeyboardLayoutContextReturnValue>({
    changeKeyboardLayout: () => undefined,
    keyboardLayout: KEYBOARD_LAYOUT.AZERTY,
  });

export const useKeyboardLayout = (): KeyboardLayoutContextReturnValue => {
  return useContext(KeyboardLayoutContext);
};

export const KeyboardLayoutProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [keyboardLayout, setKeyboardLayout] = useState<KEYBOARD_LAYOUT>(
    getFromLocalStorage({
      key: LOCAL_STORAGE_KEYS.KEYBOARD_LAYOUT,
      defaultValue: KEYBOARD_LAYOUT.AZERTY,
    }),
  );

  const changeKeyboardLayout = useCallback((keyboarLayout: KEYBOARD_LAYOUT) => {
    setKeyboardLayout(keyboarLayout);
    setToLocalStorage({
      key: LOCAL_STORAGE_KEYS.KEYBOARD_LAYOUT,
      value: keyboarLayout,
    });
  }, []);

  useEffect(() => {
    const isCypress = 'Cypress' in window;

    // only work with webkit
    if (!isCypress && navigator.keyboard) {
      navigator.keyboard.getLayoutMap().then((layout) => {
        if (isAzertyLayout(layout)) {
          changeKeyboardLayout(KEYBOARD_LAYOUT.AZERTY);

          return;
        }
        if (isQwertyLayout(layout)) {
          changeKeyboardLayout(KEYBOARD_LAYOUT.QWERTY);

          return;
        }
        if (isQwertzLayout(layout)) {
          changeKeyboardLayout(KEYBOARD_LAYOUT.QWERTZ);

          return;
        }

        changeKeyboardLayout(KEYBOARD_LAYOUT.AZERTY);
      });
    }
  }, [changeKeyboardLayout]);

  return (
    <KeyboardLayoutContext.Provider
      value={{ keyboardLayout, changeKeyboardLayout }}
    >
      {children}
    </KeyboardLayoutContext.Provider>
  );
};

declare global {
   
  interface Navigator {
    keyboard: {
      getLayoutMap: () => Promise<Map<EVENT_CODE, EVENT_KEY>>;
    };
  }
}

KeyboardLayoutProvider.displayName = 'KeyboardLayoutProvider';
