import { createSelector } from 'reselect';

import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers/errorsMessage';
import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';
import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { STOCKS_DOMAIN_KEY } from '../constants';

import * as types from './actionTypes';
import {
  STOCK_ITEM_LIST_DOMAIN,
  STOCK_ITEM_LIST_DOMAIN_KEY,
} from './constants';
import {
  RubricItemWithActive,
  SelectStockItemListAppliedFiltersToSend,
  StockItemListListDomainState,
} from './types';

interface State extends CommonAppState {
  [STOCKS_DOMAIN_KEY]: {
    [STOCK_ITEM_LIST_DOMAIN_KEY]: StockItemListListDomainState;
  };
}

const selectStockItemListDomain = (state: State) =>
  state[STOCKS_DOMAIN_KEY][STOCK_ITEM_LIST_DOMAIN_KEY];

export const selectStockItemList = createSelector(
  [selectStockItemListDomain],
  (state) => state[STOCK_ITEM_LIST_DOMAIN.LIST],
);
export const selectStockItemIdsByPage = createSelector(
  [selectStockItemListDomain],
  (state) => state[STOCK_ITEM_LIST_DOMAIN.BY_PAGE],
);
export const selectStockItemListByPage = createSelector(
  [selectStockItemList, selectStockItemIdsByPage],
  (list, pagesOfIds) => {
    return pagesOfIds.flat().map((id) => list[id]);
  },
);

export const selectStockItemListCurrentPage = createSelector(
  [selectStockItemListDomain],
  (state) => state[STOCK_ITEM_LIST_DOMAIN.CURRENT_PAGE_NUMBER],
);
export const selectStockItemListRubrics = createSelector(
  [selectStockItemListDomain],
  (state) => state[STOCK_ITEM_LIST_DOMAIN.RUBRICS],
);

const countFilters = (filters: RubricItemWithActive[]): number =>
  filters.reduce((acc, { isActive }) => acc + Number(isActive), 0);

const mapActiveFilter = (
  filters: RubricItemWithActive[],
): Record<string, boolean> =>
  filters.reduce(
    (acc, { name, isActive }) => ({
      ...acc,
      [name]: isActive,
    }),
    {},
  );

export const selectStockItemListAppliedFilters = createSelector(
  [selectStockItemListRubrics],
  (rubrics) =>
    rubrics.reduce(
      (acc, { name, facets }) => ({
        ...acc,
        [name]: mapActiveFilter(facets),
      }),
      {},
    ),
);
export const selectStockItemListAppliedFiltersToSend = createSelector(
  [selectStockItemListRubrics],
  (rubrics) => {
    const filters = rubrics.map(({ name, facets }) => {
      const filters = facets
        .filter(({ isActive }) => isActive)
        .map(({ name }) => name);

      if (filters.length === 0) {
        return undefined;
      }

      return {
        name,
        facets: filters.map((filter) => {
          return {
            included: true,
            name: filter,
          };
        }),
      };
    }) as SelectStockItemListAppliedFiltersToSend[];

    return filters.filter((filter) => filter);
  },
);

export const selectStockItemListFilters = createSelector(
  [selectStockItemListRubrics],
  (rubrics) => {
    return rubrics.map(({ name, facets }) => ({
      name,
      parameterName: name,
      values: Object.values(facets).map(({ label, count, name }) => ({
        value: name,
        name: label,
        resultsCount: count,
      })),
    }));
  },
);

export const selectStockItemListFiltersCount = createSelector(
  [selectStockItemListRubrics],
  (rubrics) => {
    return rubrics.reduce((acc, { facets }) => acc + countFilters(facets), 0);
  },
);

export const selectStockItemListTotalCount = createSelector(
  [selectStockItemListDomain],
  (state) => state[STOCK_ITEM_LIST_DOMAIN.TOTAL_COUNT],
);
export const selectStockItemListQuery = createSelector(
  [selectStockItemListDomain],
  (state) => state[STOCK_ITEM_LIST_DOMAIN.QUERY],
);
export const selectStockItemListIsDescending = createSelector(
  [selectStockItemListDomain],
  (state) => state[STOCK_ITEM_LIST_DOMAIN.IS_DESCENDING],
);

export const selectGetStockItemListWasLoaded = (
  state: State,
  { page }: { page: number },
): boolean | undefined =>
  wasActionLoadedSelector(state, types.GET_STOCK_ITEM_LIST, page);
export const selectGetStockItemListErrors = (
  state: State,
  { page }: { page: number },
): (Error | ErrorFromBack)[] =>
  getActionErrorsSelector(state, types.GET_STOCK_ITEM_LIST, page);

export const selectGetStockItemListIsLoading = (
  state: State,
  { page }: { page: number },
): boolean | undefined =>
  isActionLoadingSelector(state, types.GET_STOCK_ITEM_LIST, page);
