import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { RadioButtonGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  BulbIcon,
  QuestionIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { EditAddressFooter } from '../../components';

import { ADDRESS_SUGGESTION_ORIGIN } from './AddressSuggestion.types';
import { SelectAddressRadio } from './components';
import {
  useAddressSuggestionForm,
  useAddressSuggestionStepContext,
} from './hooks';
import messages from './messages';

const AddressSuggestionContent: FC = () => {
  const { values, previousStep } = useAddressSuggestionStepContext();
  const { formContext, handleAddressSuggestionSubmit, isLoading } =
    useAddressSuggestionForm({
      haveOwnerAddressSuggestion: !!values?.ownerAddress?.suggestedAddress,
      haveOwnerAddressOnFileSuggestion:
        !!values?.ownerAddressOnFile?.suggestedAddress,
    });
  const theme = useTheme();

  const { ownerAddress, ownerAddressOnFile } = values;
  const {
    formState: { errors },
  } = formContext;

  return (
    <FormProvider {...formContext}>
      <BannersConsumer />
      <form onSubmit={handleAddressSuggestionSubmit}>
        {ownerAddress?.suggestedAddress && (
          <RadioButtonGroup
            errors={errors}
            label={messages.addressGroupLabel}
            name="ownerAddressToKeep"
          >
            <SelectAddressRadio
              name="ownerAddressToKeep"
              value={ADDRESS_SUGGESTION_ORIGIN.SUGGESTION}
              address={ownerAddress?.suggestedAddress}
              icon={<BulbIcon color={theme.colors.primary} />}
              label={messages.suggestion}
            />
            <SelectAddressRadio
              name="ownerAddressToKeep"
              value={ADDRESS_SUGGESTION_ORIGIN.USER_VALUES}
              address={ownerAddress?.address}
              icon={<QuestionIcon color={theme.colors.primary} />}
              label={messages.userValues}
            />
          </RadioButtonGroup>
        )}

        {ownerAddressOnFile?.suggestedAddress && (
          <RadioButtonGroup
            errors={errors}
            label={messages.addressGroupLabel}
            name="ownerAddressOnFileToKeep"
          >
            <SelectAddressRadio
              name="ownerAddressOnFileToKeep"
              value={ADDRESS_SUGGESTION_ORIGIN.SUGGESTION}
              address={ownerAddressOnFile?.suggestedAddress}
              icon={<BulbIcon color={theme.colors.primary} />}
              label={messages.suggestion}
            />
            <SelectAddressRadio
              name="ownerAddressOnFileToKeep"
              value={ADDRESS_SUGGESTION_ORIGIN.USER_VALUES}
              address={ownerAddressOnFile?.address}
              icon={<QuestionIcon color={theme.colors.primary} />}
              label={messages.userValues}
            />
          </RadioButtonGroup>
        )}

        <EditAddressFooter
          dataTestId="editAddress-suggestion-submit"
          isLoading={isLoading}
          isError={Object.keys(errors).length > 0}
          onPreviousStepClick={() =>
            previousStep(
              {},
              { hasSuggestedAddress: false, unmatchingFields: [] },
            )
          }
        />
      </form>
    </FormProvider>
  );
};

AddressSuggestionContent.displayName = 'AddressSuggestionContent';

export default AddressSuggestionContent;
