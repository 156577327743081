import { combineReducers } from 'redux';

import {
  SPARE_PART_CATALOG_DOMAIN_KEY,
  sparePartCatalogReducer,
  SparePartCatalogsDomainState,
} from './catalog';

export default combineReducers<{
  [SPARE_PART_CATALOG_DOMAIN_KEY]: SparePartCatalogsDomainState;
}>({
  [SPARE_PART_CATALOG_DOMAIN_KEY]: sparePartCatalogReducer,
});
