import { createAction } from '@reduxjs/toolkit';

import * as FilterConst from './actionTypes';

export const resetFilters = createAction(FilterConst.RESET_FILTERS.BASE);

export const changeMessagesFilter = createAction(
  FilterConst.CHANGE_MESSAGES_FILTER,
);
export const changeMessagesSort = createAction(
  FilterConst.CHANGE_MESSAGES_SORT,
);
export const changeMessagesSearchQuery = createAction(
  FilterConst.CHANGE_MESSAGES_SEARCH_QUERY,
);
