import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $CreateButtonContainer = styled.div<{ $isNewUiEnabled: boolean }>`
  justify-content: center;
  flex-direction: row;
  padding: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '0' : '0 1rem')};
  background: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? 'unset' : theme.colors.white};
  align-items: center;
  align-content: space-around;
  display: none;
  height: 100%;
  @media ${media.minWidth.sm} {
    display: flex;
  }
  @media ${media.minWidth.lg} {
    padding: ${({ $isNewUiEnabled }) =>
      $isNewUiEnabled ? '0' : '0 0 0 2.1rem'};
  }
`;

const CreateButtonNewDesign = css`
  height: 32px;
  min-height: 32px;
  padding: 8px 10px 8px 14px;
`;

export const $Button = styled(Button)<{ $isNewUiEnabled: boolean }>`
  ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? CreateButtonNewDesign : null)}
`;

export const $MenuVerticalPosition = styled.div<{ $isNewUiEnabled: boolean }>`
  ul {
    margin-top: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '10px' : null)};
  }
`;
