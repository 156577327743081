import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/debugging/solutionTypes`;

interface GetRulesDebuggingSolutionTypes {
  claimId?: string;
  onlyMatchingRules?: boolean;
}
interface InputResult {
  name: string;
  isWildcard: boolean;
  isMatch: boolean;
}

interface Value {
  inputResults: InputResult[];
  depth: number;
  score: number;
  priority: number;
  name: string;
  overridable: boolean;
  additionalValues: Record<string, unknown>;
}
interface GetRulesDebuggingSolutionTypesResponse extends BaseBackResponse {
  value: Value[];
}

async function getRulesDebuggingSolutionTypes({
  claimId,
  onlyMatchingRules,
}: GetRulesDebuggingSolutionTypes): Promise<Value[]> {
  const functionName = 'getRulesDebuggingSolutionTypes';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  const response = await apiCall<GetRulesDebuggingSolutionTypesResponse>(
    buildUrl(url, { onlyMatchingRules }),
    { method: SUPPORTED_METHODS.GET },
  );

  return response.value;
}

export default getRulesDebuggingSolutionTypes;
