import { createAction } from '@reduxjs/toolkit';

import { createRequest } from '@savgroup-front-common/core/src/services/request';
import { FileComment } from '@savgroup-front-common/types';

export const GET_FILE_FULL_SUMMARY = createRequest(
  'WORKFLOW/GET_FILE_FULL_SUMMARY',
);

export const UPDATE_OWNER_NOTIFICATION_EMAIL = createRequest(
  'WORKFLOW/UPDATE_OWNER_NOTIFICATION_EMAIL',
);

export const types = {
  GET_FILE_COMMENTS: createRequest('WORKFLOW/FILE_SUMMARY/GET_FILE_COMMENTS'),
  SEND_NEW_FILE_COMMENTS: createRequest(
    'WORKFLOW/FILE_SUMMARY/SEND_NEW_FILE_COMMENTS',
  ),
  UPDATE_FILE_COMMENTS: createRequest(
    'WORKFLOW/FILE_SUMMARY/UPDATE_FILE_COMMENTS',
  ),
  DELETE_FILE_COMMENTS: createRequest(
    'WORKFLOW/FILE_SUMMARY/DELETE_FILE_COMMENTS',
  ),
};

interface SendNewFileCommentPayload {
  fileId: string;
  newComment: {
    internalId: string;
    content: string;
    createdAt: Date;
    userId: string;
  };
}

export const creators = {
  getFileComments: createAction(types.GET_FILE_COMMENTS.BASE),
  sendNewFileComment: createAction<SendNewFileCommentPayload>(
    types.SEND_NEW_FILE_COMMENTS.BASE,
  ),
  updateFileComment: createAction<{
    fileId: string;
    newComment: FileComment;
  }>(types.UPDATE_FILE_COMMENTS.BASE),
  deleteFileComment: createAction<{
    fileId: string;
    comment: FileComment;
  }>(types.DELETE_FILE_COMMENTS.BASE),
};

export const getFullFileSummary = createAction<string>(
  GET_FILE_FULL_SUMMARY.BASE,
);

export const updateOwnerNotificationEmail = createAction<{
  fileId: string;
  ownerNotificationEmail: string;
}>(UPDATE_OWNER_NOTIFICATION_EMAIL.SUCCEEDED);
