import {
  getSparePartRequestLineStatusMessage,
  safeFormattedIntlString,
} from '@savgroup-front-common/core/src/formatters';
import {
  RESULT_TYPE,
  SparePartRequestLine,
} from '@savgroup-front-common/types';
import { SparePartService } from 'control/api';

import { buildSparePartRequestLinesUrl } from '../../../../../helpers';
import { BaseSearchResultItem, SearchApiResult } from '../Search.types';

import smartFindItemHighlight from './smartFindItemHighlight';

const adaptRequestLineToSearchResult =
  (query: string) => (requestLine: SparePartRequestLine) => {
    const {
      requestLineId,
      requestLineStatus,
      requesterName,
      sparePartReference,
    } = requestLine;

    return {
      key: requestLineId,
      resultType: RESULT_TYPE.SPARE_PART_REQUEST,
      value: {
        name: smartFindItemHighlight(query, requesterName),
        reference: sparePartReference
          ? smartFindItemHighlight(query, sparePartReference)
          : undefined,
        other: [],
        status: smartFindItemHighlight(
          query,
          safeFormattedIntlString(
            getSparePartRequestLineStatusMessage(requestLineStatus),
          ),
        ),
        url: buildSparePartRequestLinesUrl({ requestLineId }),
      },
    };
  };

const searchSparePartRequestLines = async ({
  query,
  isDescending,
  shouldLoad,
  page,
  pageSize,
}: {
  page: number;
  pageSize?: number;
  query: string;
  shouldLoad: boolean;
  isDescending?: boolean;
}): Promise<SearchApiResult> => {
  if (!shouldLoad) {
    return {
      value: [],
      totalCount: 0,
      isFailure: false,
      errors: [],
    };
  }

  const response = await SparePartService.getSparePartRequestLines({
    query,
    page,
    pageSize: pageSize || 7,
    isDescending,
  });

  if (response.failure) {
    return {
      value: [],
      totalCount: 0,
      isFailure: true,
      errors: response.errors,
    };
  }

  return {
    isFailure: false,
    value: response.value.hits.map(
      adaptRequestLineToSearchResult(query),
    ) as unknown as BaseSearchResultItem[],
    totalCount: response.value.hitCount,
    errors: [],
    hit: response.value.hits,
    hitCount: response.value.hitCount,
  };
};

export default searchSparePartRequestLines;
