import {
  FilterCategory,
  OnFacetChangeArgs,
} from '@savgroup-front-common/types';

import injectNewFacetInExistingRubric from './injectNewFacetInExistingRubric';
import injectNewFacetInNewRubric from './injectNewFacetInNewRubric';
import updateFacetInExistingRubric from './updateFacetInExistingRubric';

const updateRubricsAndFacetFromAction = ({
  rubrics,
  payload,
}: {
  rubrics: FilterCategory[];
  payload: OnFacetChangeArgs;
}): FilterCategory[] => {
  const rubricIndex = rubrics.findIndex(
    (rubric) => rubric.rubricName === payload.rubricName,
  );

  if (rubricIndex === -1) {
    return injectNewFacetInNewRubric(rubrics, payload);
  }

  const hasFacet = rubrics[rubricIndex].values.some(({ facetName }) => {
    return facetName === payload.facetName;
  });

  if (!hasFacet) {
    return injectNewFacetInExistingRubric(rubrics, payload);
  }

  return updateFacetInExistingRubric(rubrics, payload);
};

export default updateRubricsAndFacetFromAction;
