import React, { PropsWithChildren, ReactElement } from 'react';
import { HeaderGroup } from 'react-table';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../formatters';
import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { $StyledTd, $StyledTr } from './TableContent.styles';

interface TableFooterProps<D extends Record<string, unknown>> {
  groups: HeaderGroup<D>[];
  dataTestId?: string;
}

const TableFooter = <D extends Record<string, unknown>>({
  groups,
  dataTestId,
}: PropsWithChildren<TableFooterProps<D>>): ReactElement => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <>
      {groups.map((group, index) => {
        const { key, ...rest } = group.getFooterGroupProps();

        return (
          <$StyledTr key={key} {...rest} $isNewUiEnabled={isNewUiEnabled}>
            {group.headers.map((column, headerIndex) => {
              const customProps = column.getProps ? column.getProps() : {};

              const { key, ...rest } = column.getFooterProps([
                column.getSortByToggleProps(),
                customProps,
              ]);

              return (
                <$StyledTd
                  key={key}
                  {...rest}
                  title={undefined}
                  disableSortBy={column.disableSortBy}
                  data-testid={
                    dataTestId
                      ? `footer_${dataTestId}_${index}_${headerIndex}`
                      : undefined
                  }
                  $isNewUiEnabled={isNewUiEnabled}
                >
                  {SafeFormattedMessage(
                    column.render('Footer') as
                      | ReactElement
                      | string
                      | MessageType,
                  )}
                </$StyledTd>
              );
            })}
          </$StyledTr>
        );
      })}
    </>
  );
};

TableFooter.displayName = 'TableFooter';

export default TableFooter;
