import get from 'lodash/get';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';

import { MODAL_TYPES } from '../../../constants/modals';
import { closeModal } from '../../../domains/modals/actionCreators';
import { selectModals } from '../../../domains/modals/selectors';
import { BaseHeader } from '../../components/BaseHeader';
import ExplodeFileModal from '../../components/ExplodeFileModal';

const modalsMap = {
  [MODAL_TYPES.EXPLODE_FILE_MODAL]: {
    Header: ExplodeFileModal.Header,
    Content: ExplodeFileModal.Content,
    size: MODAL_SIZES.MEDIUM,
  },
};

const ModalsPortal = () => {
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal);
  }, [dispatch]);

  const modals = useSelector(selectModals);

  return (
    <div>
      {modals.map((modal) => {
        const { Header, Content, size } = modalsMap[modal.type];
        const ModalHeader = Header || BaseHeader;

        return (
          <Modal isOpen key={modal.type} size={size} onClose={handleCloseModal}>
            <ModalHeader
              data={get(modal, 'data.header')}
              onClose={handleCloseModal}
            />
            <Content data={modal.data} closeModal={handleCloseModal} />
          </Modal>
        );
      })}
    </div>
  );
};

export default ModalsPortal;
