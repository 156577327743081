import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.warranty}soldwarranties/${COMMON_QUERY_PARAMS.WARRANTY_ID}`;

export interface GetSoldWarrantyByIdQueryResponseValue {
  soldWarrantyId: string;
  modelTypeId: string;
  modelTypeUniqueName: string;
  brandId: string;
  modelId: string;
  supplierId: string;
  validityReferenceDate: string;
  validityDuration: {
    unit: string;
    value: number;
    isEmpty: boolean;
  };
  waitingPeriodReferenceDate: string;
  waitingPeriodDuration: {
    unit: string;
    value: number;
    isEmpty: boolean;
  };
  productStatus: string;
  warrantyTypeId: string;
  issueIds: string[];
  excludedReasonIds: string[];
  mandatoryReasonIds: string[];
  riskCarrier: string;
  name: string;
  marketplace: string;
  externalIdentifier: string;
  countries: string[];
  transportCostCarrier: string;
  selectable: true;
  rmaType: string;
  eans: string[];
}

interface GetSoldWarrantyByIdQuery extends BaseBackResponse {
  value: GetSoldWarrantyByIdQueryResponseValue;
}

async function getSoldWarrantyById({
  warrantyId,
}: {
  warrantyId: string;
}): Promise<GetSoldWarrantyByIdQueryResponseValue> {
  if (!warrantyId) {
    throw new RequiredPayloadFieldIsNotProvided(
      'warrantyId',
      'getSoldWarrantyById',
    );
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.WARRANTY_ID, warrantyId);

  const response = await apiCall<GetSoldWarrantyByIdQuery>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to getSoldWarrantyById`);
  }

  return response.value;
}

export default getSoldWarrantyById;
