import { createReducer } from '@savgroup-front-common/core/src/helpers';

import * as BusinessConst from '../businessConst';

export const initialState = {
  isLoaded: false,
  id: null,
  isSelected: false,
  isMatching: false,
  name: null,
  warrantyType: null,
  riskCarrier: null,
  startDate: null,
  endDate: null,
  coveredIssues: [''],
  excludedReasons: [''],
};

function onGetWarrantie(state, payload) {
  return {
    ...initialState,
    id: payload,
  };
}

function onGetWarrantieSucceeded(state, payload) {
  return {
    ...state,
    isLoaded: true,
    ...payload,
  };
}

const warrantiesReducer = createReducer(initialState, {
  [BusinessConst.GET_WARRANTIES.BASE]: onGetWarrantie,
  [BusinessConst.GET_WARRANTIES.SUCCEEDED]: onGetWarrantieSucceeded,
});

export default warrantiesReducer;
