import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useHotjar } from '@savgroup-front-common/core/src/components/HotjarProvider';
import { useIsAdmin } from '@savgroup-front-common/core/src/hooks';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectUserId,
} from '@savgroup-front-common/core/src/domains/login/selectors';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';

const useUserTracking = () => {
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const email = useSelector(selectEmail);
  const userId = useSelector(selectUserId);
  const locale = useSelector(selectLocale);

  const tenants = useSelector(sellerSelectors.selectTenants);

  const { isHotjarEnabled, hotjar } = useHotjar();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (isHotjarEnabled && !isAdmin) {
      hotjar.identify(userId, {
        email,
        locale,
        firstName,
        lastName,
        tenants: tenants.reduce((acc, { name }) => `${acc}, ${name}`, ''),
      });
    }
  }, [
    email,
    firstName,
    hotjar,
    isAdmin,
    isHotjarEnabled,
    lastName,
    locale,
    tenants,
    userId,
  ]);
};

export default useUserTracking;
