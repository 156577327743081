import { RefreshDouble } from 'iconoir-react';
import React from 'react';

interface RefreshDoubleIconProps {
  color?: string;
  size?: string;
}

const RefreshDoubleIcon: React.FC<RefreshDoubleIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return <RefreshDouble width={size} height={size} color={color} />;
};

RefreshDoubleIcon.displayName = 'RefreshDoubleIcon';

export default RefreshDoubleIcon;
