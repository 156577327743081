import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants/src/shared';

export const CLAIM_GROUP_URL = {
  CREATE_CLAIM_GROUP_FROM_ORDER: `${APIConfiguration.claim}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/claimGroups`,
  SET_CLAIM_GROUP_CONFIRMATION_COMMAND: `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/confirmation`,
  GET_CLAIM_GROUP_CARRIER: `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/carriers`,
  GET_CLAIM_GROUP_HANDLING: `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/handling`,
  SET_CLAIM_GROUP_HANDLING: `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/handling`,

  GET_CLAIM_GROUP_CLAIM_IDS: `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}`,
  GET_CLAIM_GROUP_CLAIMS_SUMMARY: `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/claimsSummary`,
};
