import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  ROLES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  UserSite,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.authorization}users/management/${COMMON_QUERY_PARAMS.ENTITY_TYPE}/${COMMON_QUERY_PARAMS.ENTITY_ID}/withPagination`;

export enum ENTITY_TYPES {
  SELLER = 'Seller',
}

export interface AllowAllForSellers {
  Store?: string[];
  Repairer?: string[];
  Supplier?: string[];
  Warehouse?: string[];
}

export interface AssignedSites {
  sites: UserSite[];
  allowAllForSellers?: AllowAllForSellers;
}

export interface Credential {
  roleId: string;
  builtInRole: ROLES;
  entityType?: string;
  entityId: string;
}

export interface UserManagementInformationDto {
  userId: string;
  lastname: string;
  firstname: string;
  credentials: Credential[];
  mail: string;
  assignedSites: AssignedSites;
  concernedEntities: string[];
  stocks?: { stockId: string; stockName: string }[];
}

interface PagedListManagementUsersQueryPayload {
  entityId: string;
  entityType?: string;
  page: number;
  pageSize: number;
}

export const pagedListManagementUsersQuery = async ({
  entityId,
  entityType = ACTOR_TYPES.SELLER,
  page,
  pageSize,
}: PagedListManagementUsersQueryPayload): Promise<
  BackResponse<UserManagementInformationDto[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.ENTITY_TYPE,
      entityType,
    ).replace(COMMON_QUERY_PARAMS.ENTITY_ID, entityId);

    const response = await apiCall<
      BackResponse<UserManagementInformationDto[]>
    >(buildUrl(url, { Page: page, PageSize: pageSize }), {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
