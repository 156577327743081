import { all } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { sparePartFileRequestLinesSaga } from './fileRequestLines';
import { sparePartRequestDetailsSaga } from './requestLineDetails';
import { sparePartRequestLinesSaga } from './requestLineList';

export default function* sparePartRequestSaga(): Generator {
  try {
    yield all([
      sparePartRequestLinesSaga(),
      sparePartRequestDetailsSaga(),
      sparePartFileRequestLinesSaga(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
