import useHasSellerAccountPermission from './useHasSellerAccountPermission';
import useHasSellerApplicationPermission from './useHasSellerApplicationPermission';
import useHasSellerConnectedAppPermission from './useHasSellerConnectedAppPermission';
import useHasSellerThemePermission from './useHasSellerThemePermission';

const useHasSellerSettingsPermissions = () => {
  const hasSellerAccountPermission = useHasSellerAccountPermission();
  const hasSellerApplicationPermission = useHasSellerApplicationPermission();
  const hasSellerConnectedAppPermission = useHasSellerConnectedAppPermission();
  const hasSellerThemePermission = useHasSellerThemePermission();

  return {
    hasSellerAccountPermission,
    hasSellerApplicationPermission,
    hasSellerConnectedAppPermission,
    hasSellerThemePermission,
  };
};

export default useHasSellerSettingsPermissions;
