import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CURRENCIES, INPUT_TYPES } from '@savgroup-front-common/constants';

import { formatCurrencyCode } from '../../../formatters';
import Input, { InputProps } from '../Input';

interface PriceInputProps extends InputProps {
  currency?: CURRENCIES;
}

const MAX = 2147483647;

const LegacyPriceInput: FC<PriceInputProps> = ({
  name,
  label,
  currency = CURRENCIES.EUR,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name as string}
      control={control}
      render={({ field }) => (
        <Input
          {...rest}
          step="0.01"
          label={label}
          type={INPUT_TYPES.NUMBER}
          ref={field.ref}
          value={field.value}
          name={field.name}
          errors={errors}
          suffix={formatCurrencyCode(currency)}
          disableBrowserAutocomplete
          onChange={(event) => {
            const newValue = parseFloat(event.target.value);

            if (!Number.isNaN(newValue) && newValue <= MAX) {
              field.onChange(newValue);
            } else if (Number.isNaN(newValue) && newValue <= MAX) {
              field.onChange(undefined);
            }
          }}
          onBlur={(event) => {
            const newValue = parseFloat(event.target.value);

            if (!Number.isNaN(newValue) && newValue <= MAX) {
              field.onChange(newValue);
            } else if (Number.isNaN(newValue) && newValue <= MAX) {
              field.onChange(undefined);
            }
          }}
        />
      )}
    />
  );
};

LegacyPriceInput.displayName = 'PriceInput';

export default LegacyPriceInput;
