import { RESULT_TYPE } from '@savgroup-front-common/types';

import { RESULT_DISPLAY_TYPE, SearchResultShowmore } from '../Search.types';

const generateShowMoreResult = ({
  type,
  count,
  animationIndex = 0,
  isNew = true,
}: {
  type: RESULT_TYPE;
  count: number;
  animationIndex?: number;
  isNew?: boolean;
}): SearchResultShowmore => ({
  displayType: RESULT_DISPLAY_TYPE.SHOW_MORE,
  animationIndex,
  key: `showmore-${type}`,
  resultType: type,
  isNew,
  value: { count },
});

export default generateShowMoreResult;
