import React, { FunctionComponent } from 'react';

import {
  SEARCH_HIGHLIGHT_TYPE,
  SearchHighlight,
} from '@savgroup-front-common/types';

import { $ResultInfoSpotlight } from './HighlightsDisplayer.styles';

interface HighlightsDisplayerProps {
  highlights: SearchHighlight[];
}

export const HighlightsDisplayer: FunctionComponent<
  HighlightsDisplayerProps
> = ({ highlights }) => {
  return (
    <>
      {highlights.map(({ type, value }, index) => {
        const key = `${value}_${String(index)}`;

        if (type === SEARCH_HIGHLIGHT_TYPE.BOLD) {
          return <$ResultInfoSpotlight key={key}>{value}</$ResultInfoSpotlight>;
        }

        return <React.Fragment key={key}>{value}</React.Fragment>;
      })}
    </>
  );
};

HighlightsDisplayer.displayName = 'HighlightsDisplayer';
