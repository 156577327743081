import getAllAppStatuses from './getAllAppStatuses';
import getAppComponents from './getAppComponents';
import getAppIncidents from './getAppIncidents';
import getAppScheduledMaintenances from './getAppScheduledMaintenances';

export const StatusPageService = {
  getAllAppStatuses,
  getAppComponents,
  getAppIncidents,
  getAppScheduledMaintenances,
};
