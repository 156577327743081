import { defineMessages } from 'react-intl';

export default defineMessages({
  createFileMenuTitle: {
    id: 'view.button.create.menuTitle.file',
    defaultMessage: 'File',
  },

  createSparePartMenuTitle: {
    id: 'view.button.create.menuTitle.request',
    defaultMessage: 'Request',
  },

  orderReference: {
    id: 'view.button.createFile.orderReference',
    defaultMessage: 'Order Reference',
  },
  productReference: {
    id: 'view.button.createFile.productReference',
    defaultMessage: 'Product Reference',
  },
  receiptInformation: {
    id: 'view.button.createFile.receiptInformation',
    defaultMessage: 'Receipt information',
  },
  customerInformation: {
    id: 'view.button.createFile.customerInformation',
    defaultMessage: 'Customer information',
  },
  fileOwnerStock: {
    id: 'view.button.createFile.fileOwnerStock',
    defaultMessage: 'File owner stock',
  },

  createLabel: {
    id: 'view.button.create',
    defaultMessage: 'Create',
  },

  sparePartRequest: {
    id: 'view.button.create.sparePartRequest',
    defaultMessage: 'Spare parts',
  },
  seller: {
    id: 'view.button.create.seller',
    defaultMessage: 'Seller',
  },
  tenant: {
    id: 'view.button.create.tenant',
    defaultMessage: 'Tenant',
  },
  settings: {
    id: 'view.button.create.menuTitle.settings',
    defaultMessage: 'Settings',
  },
});
