import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.marketplace}files/${COMMON_QUERY_PARAMS.FILE_ID}/generateRefundCodes`;

interface RefundLine {
  orderLineReference: string;
  refundId: string;
  currencyCode: string;
  refundPrice: number;
  ean: string;
  modelName: string;
  type: string;
  parentOrderLineReference: string;
}

export interface GenerateRefundCodesCommandResponseValue {
  globalRefundId?: string;
  currencyCode?: string;
  totalRefundPrice?: number;
  refundLines: RefundLine[];
}

interface GenerateRefundCodesCommand extends BaseBackResponse {
  value: GenerateRefundCodesCommandResponseValue;
}

async function generateRefundCodes({
  fileId,
}: {
  fileId: string;
}): Promise<GenerateRefundCodesCommandResponseValue> {
  if (!fileId) {
    throw new Error(`cannot generateRefund codes without a fileId !`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

  const response = await apiCall<GenerateRefundCodesCommand>(url, {
    method: SUPPORTED_METHODS.POST,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to generateRefundCodes`);
  }

  return response.value;
}

export default generateRefundCodes;
