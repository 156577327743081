import React, { FC } from 'react';

import Skeleton from './Skeleton';

const SkeletonAutocomplete: FC<{ highContrast?: boolean }> = ({
  highContrast = false,
}) => {
  return (
    <>
      <Skeleton
        height="18px"
        marginBottom="0.5rem"
        width="25%"
        highContrast={highContrast}
      />
      <Skeleton
        height="40px"
        marginBottom="0.5rem"
        highContrast={highContrast}
      />
    </>
  );
};

SkeletonAutocomplete.displayName = 'SkeletonAutocomplete';

export default SkeletonAutocomplete;
