import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { SkeletonItem } from '@savgroup-front-common/core/src/atoms/Skeleton';

export const FieldLabel = styled('dt')`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: ${({ theme }) => theme.fonts.size.ultraSmall};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
`;

export const FieldValue = styled('dd')`
  color: ${({ theme }) => theme.colors.mainTextColor};
  font-size: ${({ theme }) => theme.fonts.size.medium};
  line-height: ${({ theme }) => theme.fonts.size.medium};
  margin-top: 0.5rem;
  margin-left: 0;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  white-space: pre-line;
  word-break: break-word;
`;

export const CardContainer = styled('dl')`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  min-height: auto;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 1.5rem;
`;

export const FieldContainer = styled(Col)`
  margin-bottom: 1.5rem;
`;

export const SkeletonLabel = styled(SkeletonItem)`
  height: ${({ theme }) => theme.fonts.size.ultraSmall};
  width: 50%;
  && {
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
`;

export const SkeletonValue = styled(SkeletonItem)`
  height: ${({ theme }) => theme.fonts.size.medium};
  && {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const SkeletonField = () => (
  <>
    <SkeletonLabel />
    <SkeletonValue />
  </>
);
