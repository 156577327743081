import React, { FunctionComponent } from 'react';

import { Loader } from '../../../../loader';
import { SelectFieldLoaderStyled } from '../../../common/FieldLoader/FieldLoader.styles';

export const LoadingIndicator: FunctionComponent<any> = ({ selectProps }) => {
  const dataTestId = ['autocomplete', 'loader', selectProps?.dataTestId]
    .filter((v) => v)
    .join('-');

  return (
    <SelectFieldLoaderStyled>
      <Loader dataTestId={dataTestId} />
    </SelectFieldLoaderStyled>
  );
};

LoadingIndicator.displayName = 'LoadingIndicator';

export default LoadingIndicator;
