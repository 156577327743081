const getSplittedText = (text: string): [string, string | null] => {
  const trimedText = text.trim();

  const textCount = trimedText.length;
  const half = Math.ceil(textCount / 2) + 3;

  const wordCount = trimedText.split(' ').length;

  if (wordCount <= 2) {
    return [trimedText, null];
  }

  let split = 0;

  for (let index = half; index < textCount; index += 1) {
    const letter = trimedText[index];

    if (letter === ' ') {
      split = index;
      break;
    }
  }

  return [trimedText.substr(0, split), trimedText.substr(split + 1)];
};

export default getSplittedText;
