import { ReactNode } from 'react';
import { Cell, HeaderProps, Renderer } from 'react-table';

import { MessageType } from '@savgroup-front-common/types';

export enum TABLE_ALIGN {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
}

export enum TABLE_ORDER {
  DESC = 'desc',
  ASC = 'asc',
  NONE = 'none',
}

export interface CustomColumn<D extends Record<string, unknown>> {
  Footer?: Renderer<HeaderProps<D>> | ReactNode | MessageType;
  getProps?: (cell?: Cell<D, any>) => Record<string, unknown>;
}
