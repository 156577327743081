import React, { FunctionComponent } from 'react';

import { $AutocompleteAnimatedMenu } from '../../Autocomplete.styles';

const Menu: FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  return (
    <$AutocompleteAnimatedMenu
      animationDuration={300}
      {...props}
      className="autocomplete-menu"
    />
  );
};

Menu.displayName = 'Menu';

export default Menu;
