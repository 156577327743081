import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ReverseMoney,
  SalesChannel,
} from '@savgroup-front-common/types';

export const ENDPOINT = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/orderSummaries`;

interface GetOwnerOrderSummariesQueryArgs {
  ownerId: string;
  sellerId: string;
  page: number;
  pageSize: number;
  modelSearch?: string;
}

export interface RawOwnerOrderProductSummary {
  orderLineReference: string;
  sku: string;
  label: string;
  brandLabel: string;
  quantity?: number;
  price: ReverseMoney;
  modelId: string;
  sellerProductId: string;
}

export interface RawOwnerOrderSummary {
  orderId: string;
  orderReference: string;
  purchaseDateUtc: string;
  salesChannel: SalesChannel;
  storeExternalId: string;
  store: string;
  products: RawOwnerOrderProductSummary[];
}

async function getOwnerOrderSummariesQuery({
  ownerId,
  sellerId,
  page,
  pageSize = 10,
  modelSearch = undefined,
}: GetOwnerOrderSummariesQueryArgs): Promise<
  BackResponse<RawOwnerOrderSummary[]> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId);

    const response = await apiCall<BackResponse<RawOwnerOrderSummary[]>>(
      buildUrl(url, {
        sellerId,
        page,
        pageSize,
        modelSearch,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getOwnerOrderSummariesQuery;
