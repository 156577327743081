import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { SALES_SATUS } from '../../../constants/salesStatus';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/sales/import/details/${COMMON_QUERY_PARAMS.STATE}/${COMMON_QUERY_PARAMS.IMPORT_ID}`;

export interface SalesImportDetailSummaryDto {
  rowIndex: number;
  orderReference: string;
  email: string;
  lastname: string;
  internalReference: string;
}
interface GetSalesImportDetailsQueryPayload {
  sellerId: string;
  importId: string;
  state: SALES_SATUS;
}

type GetSalesImportDetailsQuery = (
  payload: GetSalesImportDetailsQueryPayload,
) => Promise<BackResponse<SalesImportDetailSummaryDto[]> | BackResponseFailure>;

const getSalesImportDetailsQuery: GetSalesImportDetailsQuery = async ({
  sellerId,
  importId,
  state,
}) => {
  try {
    return await apiCall<BackResponse<SalesImportDetailSummaryDto[]>>(
      buildUrl(
        ENDPOINT.replace(COMMON_QUERY_PARAMS.STATE, state)
          .replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId)
          .replace(COMMON_QUERY_PARAMS.IMPORT_ID, importId),
      ),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getSalesImportDetailsQuery;
