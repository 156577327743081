import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  INTERVENTION_REPORT_STATE,
  ReverseMoney,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/interventionreport/extended`;

interface AdministrativeFeesReportLine {
  type: 'administrativeFee';
  description: string;
  administrativeFee: {
    priceIncludedTax: ReverseMoney;
  };
}

interface DiscountReportLine {
  type: 'discount';
  description: string;
  discount: {
    priceIncludedTax: ReverseMoney;
  };
}

interface ServiceReportLine {
  type: 'service';
  service: {
    serviceId: string;
  };
}

interface UnknownServiceReportLine {
  type: 'unknownService';
  unknownService: {
    priceIncludedTax: ReverseMoney;
  };
}

interface StockItemReportLine {
  type: 'sparePart';
  description: string;
  sparePart: {
    stockItemId: string;
    isInstalled?: boolean;
  };
}

interface UnknownSparePartReportLine {
  type: 'unknownSparePart';
  description: string;
  unknownSparePart: {
    supplierId?: string;
    reference: string;
    preTaxBuyingPrice: ReverseMoney;
    preTaxSellingPrice: ReverseMoney;
  };
}

export type SetInterventionReportLine =
  | AdministrativeFeesReportLine
  | DiscountReportLine
  | ServiceReportLine
  | UnknownServiceReportLine
  | StockItemReportLine
  | UnknownSparePartReportLine;

interface SetInterventionReportExtendedArgs {
  fileId: string;
  model: string;
  closingType?: string;
  closingCode?: string;
  serialNumber: string;
  irisCode: {
    conditionCode?: string;
    symptomCode?: string;
    sectionCode?: string;
    defectCode?: string;
    repairCode?: string;
    extendedConditionCode?: string;
  };
  technicalDescription?: string;
  comment: string;
  reportLines: SetInterventionReportLine[];
  state: INTERVENTION_REPORT_STATE.DRAFT | INTERVENTION_REPORT_STATE.VALIDATED;
}

export default async function setInterventionReportExtended(
  payload: SetInterventionReportExtendedArgs,
): Promise<BaseBackResponse> {
  try {
    const response = await apiCall(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          fileId: payload.fileId,
          model: payload.model,
          closingType: payload.closingType,
          closingCode: payload.closingCode,
          serialNumber: payload.serialNumber,
          irisCode: {
            conditionCode: payload.irisCode.conditionCode,
            symptomCode: payload.irisCode.symptomCode,
            sectionCode: payload.irisCode.sectionCode,
            defectCode: payload.irisCode.defectCode,
            repairCode: payload.irisCode.repairCode,
            extendedConditionCode: payload.irisCode.extendedConditionCode,
          },
          technicalDescription: payload.technicalDescription,
          comment: payload.comment,
          state: payload.state,
          reportLines: payload.reportLines,
        },
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
