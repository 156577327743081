import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import { RESULT_TYPE } from '@savgroup-front-common/types';

import { adaptSearchResults } from './Header.adapters';
import { searchResults } from './Search/helpers';

export const SEARCH_RESULTS = 'searchResults';

export const useHeaderNewBo = () => {
  const theme = useTheme();
  const [query, setQuery] = useState<string | undefined>();
  const [showMoreNumber, setShowMoreNumber] = useState(0);

  const [searchHistoryItems, setSearchHistoryItems] = useState<string[]>([]);

  const { isLoading, data: searchResult } = useQuery(
    [SEARCH_RESULTS, { query, showMoreNumber }],
    async () => {
      if (!query) {
        return [];
      }

      if (!searchHistoryItems.includes(query)) {
        setSearchHistoryItems([query, ...searchHistoryItems]);
      }

      const resultTypes = [
        RESULT_TYPE.FILE,
        RESULT_TYPE.ORDER,
        RESULT_TYPE.SPARE_PART_REQUEST,
        RESULT_TYPE.STOCK_ITEM,
      ];

      const response = await searchResults({
        query,
        page: 1,
        pageSize: 3 + showMoreNumber * 15,
        enabledResultTypes: resultTypes,
        searchPermissions: resultTypes,
      });

      return adaptSearchResults({
        response,
        primaryColor: theme.newUI?.iconColors.primary,
        cautionColor: theme.newUI?.iconColors.caution,
        successColor: theme.newUI?.iconColors.success,
      });
    },
    {
      staleTime: 10_000,
      keepPreviousData: true,
    },
  );

  const handleShowMore = () => {
    setShowMoreNumber(showMoreNumber + 1);
  };

  const handleChangeSearchQuery = useCallback((value: string) => {
    setShowMoreNumber(0);
    setQuery(value);
  }, []);

  return {
    searchResult,
    isLoading,
    query,
    handleChangeSearchQuery,
    handleShowMore,
    searchHistoryItems,
  };
};
