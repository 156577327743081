import { FilterCategory } from '@savgroup-front-common/types';

import isFacetActive from './isFacetActive';

const reOrderFacets = (rubrics: FilterCategory[]): FilterCategory[] => {
  return rubrics.map((rubric) => {
    return {
      ...rubric,
      values: rubric.values
        .map((filter) => ({
          ...filter,
          shouldGoTop: isFacetActive(filter),
        }))
        .sort((a, b) => {
          const AIsActive = isFacetActive(a);
          const BIsActive = isFacetActive(b);

          if (AIsActive && !BIsActive) {
            return -1;
          }
          if (BIsActive && !AIsActive) {
            return 1;
          }

          return b.resultsCount - a.resultsCount < 0 ? -1 : 1;
        }),
    };
  });
};

export default reOrderFacets;
