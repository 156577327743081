import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';
import { RESULT_TYPE } from '@savgroup-front-common/types';

import {
  buildSetMoreResultsState,
  buildSetResultsState,
  getInitiallyEnabledResultType,
  getResultListSize,
  searchInferiorIndexToFocus,
  searchSuperiorIndexToFocus,
} from './helpers';
import { RESULTS_COUNT_DISPLAYED, SEARCH_HEIGHT } from './Search.contants';
import { SEARCH_ACTION_TYPES, SearchAction, SearchState } from './Search.types';

export const searchReducerInit = ({
  defaultResultType,
}: {
  defaultResultType: RESULT_TYPE[];
}): SearchState => {
  const enabledResultTypes = getInitiallyEnabledResultType({
    defaultResultType,
  });

  return {
    query: '',
    results: [],
    storedResults: [],
    indexFocus: 0,
    containerHeight: 0,
    listHeight: 0,
    resultsAreOpened: false,
    isLoading: false,
    showNoResult: false,
    showNoFilter: false,
    page: 1,
    focusedResultType: undefined,
    enabledResultTypes,
  };
};

export const searchReducer = (
  state: SearchState,
  action: SearchAction,
): SearchState => {
  switch (action.type) {
    case SEARCH_ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    case SEARCH_ACTION_TYPES.UPDATE_QUERY: {
      const queryIsEmpty = action.payload.query.length === 0;

      return {
        ...state,
        query: action.payload.query,
        resultsAreOpened: queryIsEmpty ? false : state.resultsAreOpened,
        indexFocus: queryIsEmpty ? 0 : state.indexFocus,
        isLoading: !queryIsEmpty,
        results: queryIsEmpty ? [] : state.results,
        focusedResultType: undefined,
        listHeight: queryIsEmpty ? 0 : state.listHeight,
        containerHeight: queryIsEmpty ? 0 : state.containerHeight,
        page: 1,
      };
    }
    case SEARCH_ACTION_TYPES.SET_RESULTS: {
      return buildSetResultsState(state, action);
    }
    case SEARCH_ACTION_TYPES.SET_MORE_RESULTS: {
      return buildSetMoreResultsState(state, action);
    }
    case SEARCH_ACTION_TYPES.REQUEST_MORE_RESULTS: {
      return {
        ...state,
        page: state.page + 1,
        isLoading: true,
        focusedResultType: action.payload.type,
      };
    }
    case SEARCH_ACTION_TYPES.CLOSE_RESULTS: {
      return {
        ...state,
        resultsAreOpened: false,
        results: state.results.map((result) => ({ ...result, isNew: false })),
        storedResults: state.storedResults.map((result) => ({
          ...result,
          isNew: false,
        })),
        containerHeight: 0,
      };
    }
    case SEARCH_ACTION_TYPES.TOGGLE_SEARCH_TYPE: {
      const hasTypeEnabled = state.enabledResultTypes.includes(
        action.payload.type,
      );

      const enabledResultTypes = hasTypeEnabled
        ? state.enabledResultTypes.filter(
            (value) => value !== action.payload.type,
          )
        : [...state.enabledResultTypes, action.payload.type];

      return {
        ...state,
        focusedResultType: undefined,
        isLoading: state.query.length > 0,
        page: 1,
        enabledResultTypes,
      };
    }
    case SEARCH_ACTION_TYPES.ON_FOCUS: {
      const resultsAreOpened = state.query.length > 0;

      const { indexFocus } = searchSuperiorIndexToFocus({
        results: state.results,
        indexFocus: 0,
      });

      const getContainerHeightOnFocus = () => {
        if (!resultsAreOpened) {
          return 0;
        }
        if (state.showNoFilter || state.showNoResult) {
          return SEARCH_HEIGHT.NO_RESULT;
        }

        return (
          state.containerHeight ||
          getResultListSize(state.results, RESULTS_COUNT_DISPLAYED)
        );
      };

      const getListHeightOnFocus = () => {
        if (!resultsAreOpened) {
          return 0;
        }

        if (state.showNoFilter || state.showNoResult) {
          return SEARCH_HEIGHT.NO_RESULT;
        }

        return getResultListSize(state.results);
      };

      return {
        ...state,
        resultsAreOpened,
        indexFocus:
          state.indexFocus === undefined ? indexFocus : state.indexFocus,
        listHeight: getListHeightOnFocus(),
        containerHeight: getContainerHeightOnFocus(),
      };
    }
    case SEARCH_ACTION_TYPES.ON_BLUR: {
      return {
        ...state,
        indexFocus: undefined,
      };
    }
    case SEARCH_ACTION_TYPES.GO_UP: {
      if (state.query.length === 0) {
        return state;
      }

      const { indexFocus } = searchInferiorIndexToFocus(state);

      return {
        ...state,
        indexFocus,
      };
    }
    case SEARCH_ACTION_TYPES.GO_DOWN: {
      if (state.query.length === 0) {
        return state;
      }
      const { indexFocus } = searchSuperiorIndexToFocus(state);

      return {
        ...state,

        indexFocus,
      };
    }
    default:
      throw new ActionTypeIsNotSupportedError();
  }
};
