import useHasSellerSettingsPermissions from './SellerSettingsTabs/hooks/useHasSellerSettingsPermissions';
import useHasTenantPermission from './TenantSettingsTabs/hooks/useHasTenantPermission';

const useHasSellerSettingsPagePermission = () => {
  const tenantsPermissions = useHasTenantPermission();
  const sellersPermissions = useHasSellerSettingsPermissions();

  return [
    ...Object.values(tenantsPermissions),
    ...Object.values(sellersPermissions),
  ].includes(true);
};

export default useHasSellerSettingsPagePermission;
