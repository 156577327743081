export const REACT_AVATAR_DEFAULT_COLORS = [
  '#03120E',
  '#042A2B',
  '#1A1D1A',
  '#26413C',
  '#28AFB0',
  '#33658A',
  '#351431',
  '#387780',
  '#3F826D',
  '#4285f4',
  '#5E2BFF',
  '#63A375',
  '#67ae3f',
  '#6B2B06',
  '#757780',
  '#775253',
  '#7e3794',
  '#91785D',
  '#B80C09',
  '#C04CFD',
  '#DD4B1A',
  '#E28413',
  '#E83151',
  '#F26419',
  '#FC6DAB',
  '#d61a7f',
  '#d73d32',
  '#ff4080',
];
