import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/statusVisibilities`;

interface GetFileStatusVisibilitiesQueryPayload {
  fileId: string;
  stateName?: string;
  moduleId?: string;
  shouldThrow?: boolean;
}

export interface ActionVisibility {
  profiles: string[];
  transitionName: string;
}

interface FileStatusVisibilitySummary {
  statusVisibility: string[];
  actionOwners: ActionVisibility[];
  actionActors: ActionVisibility[];
}

type GetFileStatusVisibilitiesQueryResponse = FileStatusVisibilitySummary;

const getFileStatusVisibilitiesQuery = async ({
  fileId,
  stateName,
  moduleId,
  shouldThrow = false,
}: GetFileStatusVisibilitiesQueryPayload): Promise<
  BackResponse<GetFileStatusVisibilitiesQueryResponse> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<GetFileStatusVisibilitiesQueryResponse>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId), {
        stateName,
        moduleId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    if (shouldThrow) {
      throw exception;
    }

    return prepareResponseFailure(exception);
  }
};

export default getFileStatusVisibilitiesQuery;
