import React, { FC } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Autocomplete, FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';
import { SellerConfiguration } from '@savgroup-front-common/types';
import { useControlTypedSelector } from 'control/hooks';

import useFaqUrl from '../hooks/useFaqUrl';

import messages from './messages';
import { $DescriptionSellerConfiguration } from './SellerFaqModal.styles';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';

interface SellerFaqModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (sellerConfiguration?: SellerConfiguration) => void;
}

const SellerFaqModal: FC<SellerFaqModalProps> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const sellers = useControlTypedSelector(sellerSelectors.selectSellers);
  const isGetSellersWasLoaded = useControlTypedSelector(
    sellerSelectors.selectGetSellerWasLoaded,
  );
  const { sellerConfiguration, selectedSeller, handleSelectSeller } =
    useFaqUrl();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Heading level={1}>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </Heading>
      <Row>
        <$DescriptionSellerConfiguration xs={12}>
          <SafeFormattedMessageWithoutSpread message={messages.description} />
        </$DescriptionSellerConfiguration>
        <Col xs={12}>
          <FormGroup>
            <Autocomplete
              label={messages.sellerLabel}
              options={sellers.map((seller) => ({
                value: seller.id,
                label: seller.name,
                data: seller,
              }))}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                handleSelectSeller(newValue.data);
              }}
              isLoading={!isGetSellersWasLoaded}
              autoFocus
              dataTestId="selectSeller"
              value={
                selectedSeller
                  ? {
                      value: selectedSeller.id,
                      label: selectedSeller.name,
                      data: selectedSeller,
                    }
                  : undefined
              }
            />
          </FormGroup>
        </Col>
        {sellerConfiguration && !sellerConfiguration?.faqUrl && (
          <Col xs={12}>
            <SafeFormattedMessageWithoutSpread
              message={messages.noFaqUrlConfigured}
            />
          </Col>
        )}
        <Col xs={12} sm={2} smOffset={10}>
          <Button
            primary
            type={BUTTON_TYPES.BUTTON}
            onClick={() => onSelect(sellerConfiguration)}
            disabled={!sellerConfiguration?.faqUrl}
            fluid
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.validateSeller}
            />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

SellerFaqModal.displayName = 'SellerFaqModal';

export default SellerFaqModal;
