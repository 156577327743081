import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';

import { ControlRootState } from '../ControlRootState';

export const quotesState = (state: ControlRootState) => state.quote;

export const quotes = createSelector([quotesState], (currentQuotes) => {
  return currentQuotes.get('quotesMap') || Map();
});
export const draftIds = createSelector([quotesState], (currentQuotes) =>
  currentQuotes.get('draftIds'),
);
export const currentQuoteIds = createSelector([quotesState], (currentQuotes) =>
  currentQuotes.get('currentQuoteIds'),
);
export const selectQuoteIdByFileId = createSelector(
  [
    currentQuoteIds,
    (_: CommonAppState, { fileId }: { fileId: string }) => fileId,
  ],
  (quoteIds, fileId) => quoteIds.get(fileId) || undefined,
);
