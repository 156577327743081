import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import 'regenerator-runtime';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GET_LOANS } from './actionTypes';

interface GetLoansPayload {
  fileId: string;
  sellerId: string;
}

function* getLoansWorker({ payload }: { payload: GetLoansPayload }): Generator {
  const { fileId, sellerId } = payload;
  const meta = { fileId, indexer: fileId };

  yield call(
    callAndGetResponse,
    GET_LOANS,
    buildUrl(`${APIConfiguration.repairer}loans`, { fileId, sellerId }),
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );

  yield put(GET_LOANS.end(meta));
}
function* getLoansWatcher() {
  yield takeLatest<Action<GetLoansPayload>>(GET_LOANS.BASE, getLoansWorker);
}

export default function* mainSaga() {
  try {
    yield all([getLoansWatcher()]);
  } catch (error: any) {
    logCritical(error);
  }
}
