import { createAction } from '@reduxjs/toolkit';

import * as sparePartSupplierActionTypes from './actionTypes';

export interface GetSparePartSupplierBySellerIdPayload {
  sellerId?: string;
}

export const getSparePartSupplierBySellerId =
  createAction<GetSparePartSupplierBySellerIdPayload>(
    sparePartSupplierActionTypes.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID.BASE,
  );
