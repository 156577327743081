import { EmptyPage } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const FileIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <EmptyPage width={size} height={size} color={color} />;

FileIcon.displayName = 'FileIcon';

export default FileIcon;
