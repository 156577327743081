import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, SellerSummary } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.config}sellers/byid/${COMMON_QUERY_PARAMS.SELLER_ID}`;

export interface GetSellerByIdArgs {
  sellerId: string;
}

interface GetSellerByIdResponse extends BaseBackResponse {
  failure: false;
  value: SellerSummary;
}
interface GetSellerByIdResponseError extends BaseBackResponse {
  failure: true;
}

export default async function getSellerSummaryById(
  payload: GetSellerByIdArgs,
): Promise<GetSellerByIdResponse | GetSellerByIdResponseError> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      payload.sellerId,
    );

    return await apiCall<GetSellerByIdResponse>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
