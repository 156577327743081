import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.page.help.title',
    defaultMessage: 'Help',
  },
  description: {
    id: 'view.page.help.description',
    defaultMessage: 'Select a seller',
  },
  sellerLabel: {
    id: 'view.components.help.sellerLabel',
    defaultMessage: "Seller's Name",
  },
  validateSeller: {
    id: 'view.components.help.validateSeller',
    defaultMessage: 'Confirm',
  },
  noFaqUrlConfigured: {
    id: 'view.components.help.noFaqUrlConfigured',
    defaultMessage: "No help's url configured",
  },
});
