import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { CONSTRAINT_MATCHING_RULES } from './getMatchingRulesInfoCommand';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/ruleDefinition/${COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE}/predefinedFilters`;

export interface PredefinedFiltersDto {
  name: string;
  localizationKey: string;
  activeByDefault: boolean;
  filters: {
    constraint: CONSTRAINT_MATCHING_RULES;
    isInputFilter: boolean;
    values: {
      [x: string]: { values: string[] };
    };
  }[];
}

async function getPredefinedFiltersQuery({
  sellerId,
  ruleType,
}: {
  sellerId: string;
  ruleType: string;
}): Promise<BackResponse<PredefinedFiltersDto[]> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<PredefinedFiltersDto[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
        COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE,
        ruleType,
      ),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getPredefinedFiltersQuery;
