import styled from 'styled-components';

export const $FileItemReference = styled('span')`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const $FileItemStyled = styled('div')`
  display: flex;
  align-items: center;
`;

export const $SpaceBetweenItems = styled('div')`
  margin: 5px;
`;

export const $ExplodeFileModalBody = styled('div')`
  margin-top: 0;
`;
