import { createAction } from '@reduxjs/toolkit';

import * as sparePartCatalogActionTypes from './actionTypes';

export interface GetSparePartCatalogsPayload {
  sparePartCatalogId: string;
}

export interface GetSparePartCatalogsBySupplierIdPayload {
  supplierId: string;
  sellerId: string;
  query: string;
}

export const getSparePartCatalogsBySupplierId =
  createAction<GetSparePartCatalogsBySupplierIdPayload>(
    sparePartCatalogActionTypes.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID.BASE,
  );
