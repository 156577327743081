import camelCase from 'lodash/camelCase';
import { AnyAction } from 'redux';
import { Action } from 'redux-actions';
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { setToLocalStorage } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';
import {
  FACET_INPUT_TYPE,
  FACET_TRIPLE_STATE_SWITCH_VALUES,
  FilterCategory,
  LOCAL_STORAGE_KEYS,
  OnMultipleFacetChangeArgs,
  PossibleFilter,
} from '@savgroup-front-common/types';
import {
  isFacetActive,
  selectFilterFromMultiFilterChangeAction,
} from 'control/helpers';

import { onFileStateChanged } from '../workflow/actionCreators';

import { rehydrateTodosListSearch } from './actionCreators';
import {
  REHYDRATE_TODOS_SEARCH,
  TODOS_LIST_CHANGE_FILTER,
  TODOS_LIST_CHANGE_MULTPLE_FILTER,
  TODOS_LIST_CHANGE_SEARCH_QUERY,
  TODOS_LIST_LOCK_FILTER,
  TODOS_LIST_RESET_FILTERS,
  TODOS_LIST_RESET_FILTERS_AND_LOCK,
  TODOS_LIST_SELECT_PAGE,
  TODOS_SEARCH,
  TOGGLE_TODOS_LIST_RESULTS_ORDER,
} from './actionTypes';
import { selectTodosListFilters, selectTodosListPagination } from './selectors';

const SEARCH_REQUEST_TIMEOUT = 600;

const adaptTripleStateSwitchToFacets = (filters: PossibleFilter[]) => {
  return filters
    .filter((filter) => {
      return (
        filter.inputType === FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH &&
        filter.value !== FACET_TRIPLE_STATE_SWITCH_VALUES.IDDLE
      );
    })
    .map(({ facetName, value }) => ({
      name: facetName,
      included: value === FACET_TRIPLE_STATE_SWITCH_VALUES.INCLUDE,
    }));
};

const mapFacetsToParams = ({
  filters,
  searchOptions,
}: {
  filters: FilterCategory[];
  searchOptions: {
    query?: string;
    sort: SORT_TYPES;
    isDescending: boolean;
    page: number;
    pageSize: number;
  };
}) => {
  return {
    ...searchOptions,
    filters: filters
      .map((filter) => {
        return {
          name: camelCase(filter.rubricName),
          facets: adaptTripleStateSwitchToFacets(filter.values),
        };
      })
      .filter((category) => category.facets.length > 0),
  };
};

function* rehydrateSearchTodosWorker() {
  const searchOptions: ReturnType<typeof selectTodosListPagination> =
    yield select(selectTodosListPagination);
  const filters: ReturnType<typeof selectTodosListFilters> = yield select(
    selectTodosListFilters,
  );

  const params = mapFacetsToParams({
    filters,
    searchOptions,
  });

  yield call(
    callAndGetResponse,
    REHYDRATE_TODOS_SEARCH,
    `${APIConfiguration.search}filteredtodos`,

    {
      method: SUPPORTED_METHODS.POST,
      json: { ...params, page: 1, pageSize: params.page * params.pageSize },
    },
    { indexer: searchOptions.page },
  );

  yield put(REHYDRATE_TODOS_SEARCH.end());
}
export function* rehydrateSearchTodosWatcher(): Generator {
  yield takeEvery(REHYDRATE_TODOS_SEARCH.BASE, rehydrateSearchTodosWorker);
}

function* searchTodosListWorker(action: AnyAction) {
  if (action.type === TODOS_LIST_CHANGE_SEARCH_QUERY.BASE) {
    yield delay(SEARCH_REQUEST_TIMEOUT);
  }
  const searchOptions: ReturnType<typeof selectTodosListPagination> =
    yield select(selectTodosListPagination);
  const filters: ReturnType<typeof selectTodosListFilters> = yield select(
    selectTodosListFilters,
  );
  const params = mapFacetsToParams({
    filters,
    searchOptions,
  });

  yield call(
    callAndGetResponse,
    TODOS_SEARCH,
    `${APIConfiguration.search}filteredtodos`,
    { method: SUPPORTED_METHODS.POST, json: params },
    { indexer: searchOptions.page },
  );

  yield put(TODOS_SEARCH.end());
}

function* searchTodosListWatcher() {
  yield takeLatest(
    [
      TODOS_LIST_CHANGE_SEARCH_QUERY.BASE,
      TODOS_LIST_SELECT_PAGE.BASE,
      TODOS_SEARCH.BASE,
      TODOS_LIST_CHANGE_FILTER.BASE,
      TODOS_LIST_RESET_FILTERS_AND_LOCK.BASE,
      TODOS_LIST_RESET_FILTERS.BASE,
      TOGGLE_TODOS_LIST_RESULTS_ORDER.BASE,
    ],
    searchTodosListWorker,
  );
}

function* onSaveFilterWorker() {
  const filters: ReturnType<typeof selectTodosListFilters> = yield select(
    selectTodosListFilters,
  );

  const filteredFilter = filters.reduce<FilterCategory[]>((acc, filter) => {
    const values = filter.values.filter(isFacetActive).map((value) => ({
      ...value,
      count: 0,
      shouldGoTop: true,
    }));

    if (values.length === 0) {
      return acc;
    }

    return [
      ...acc,
      {
        ...filter,
        values,
      },
    ];
  }, []);

  setToLocalStorage({
    key: LOCAL_STORAGE_KEYS.FILTERS_LIST_TODOS,
    value: filteredFilter,
  });
}
function* onSaveFilterWatcher() {
  yield takeEvery(
    [
      TODOS_LIST_CHANGE_FILTER.BASE,
      TODOS_LIST_CHANGE_MULTPLE_FILTER.BASE,
      TODOS_LIST_RESET_FILTERS.BASE,
      TODOS_LIST_RESET_FILTERS_AND_LOCK.BASE,
      TODOS_LIST_LOCK_FILTER.BASE,
    ],
    onSaveFilterWorker,
  );
}

function* hardChangeWorder(action: Action<OnMultipleFacetChangeArgs>) {
  const searchOptions: ReturnType<typeof selectTodosListPagination> =
    yield select(selectTodosListPagination);

  const filters = selectFilterFromMultiFilterChangeAction(action);

  const params = {
    ...searchOptions,
    filters,
  };

  yield call(
    callAndGetResponse,
    TODOS_SEARCH,
    `${APIConfiguration.search}filteredtodos`,
    { method: SUPPORTED_METHODS.POST, json: params },
    { indexer: searchOptions.page },
  );

  yield put(TODOS_SEARCH.end());
}

function* hardChangeWatcher() {
  yield takeLatest([TODOS_LIST_CHANGE_MULTPLE_FILTER.BASE], hardChangeWorder);
}

function* onFileStateChangedWorker() {
  yield all([put(rehydrateTodosListSearch())]);
}
function* onFileStateChangedWatcher() {
  yield takeEvery([onFileStateChanged().type], onFileStateChangedWorker);
}

export default function* mainTodosSaga(): Generator {
  try {
    yield all([
      rehydrateSearchTodosWatcher(),
      searchTodosListWatcher(),
      onSaveFilterWatcher(),
      onFileStateChangedWatcher(),
      hardChangeWatcher(),
    ]);
  } catch (error) {
    logCritical(error as any);
  }
}
