import { useState } from 'react';
import {
  Column,
  HeaderGroup,
  PluginHook,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
  TableState,
  usePagination,
  useTable,
} from 'react-table';
import { useDeepCompareEffect } from 'react-use';

interface UseMemoizedTableReturnValue<D extends Record<string, unknown>> {
  getTableProps: (propGetter?: TablePropGetter<D> | undefined) => TableProps;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<D> | undefined,
  ) => TableBodyProps;
  headerGroups: HeaderGroup<D>[];
  footerGroups: HeaderGroup<D>[];
  rows: Row<D>[];
  prepareRow: (row: Row<D>) => void;
  state: TableState<D>;
  hasFooter: boolean;
  hasPagination: boolean;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  pageCount: number;
  page: Row<D>[];
}

interface UseMemoizedTableArgs<D extends Record<string, unknown>> {
  plugins: PluginHook<D>[];
  columns: Column<D>[];
  data: D[];
  initialState?: Partial<TableState<D>>;
}

const useMemoizedTable = <D extends Record<string, unknown>>({
  columns,
  data,
  plugins,
  initialState,
}: UseMemoizedTableArgs<D>): UseMemoizedTableReturnValue<D> => {
  const hasPagination = plugins.includes(usePagination);

  const [dataMemoized, setDataMemoized] = useState(data);
  const [columnsMemoized, setColumnsMemoized] = useState(columns);

  useDeepCompareEffect(() => {
    if (data !== dataMemoized) {
      setDataMemoized(data);
    }
  }, [data, dataMemoized]);

  useDeepCompareEffect(() => {
    if (columns !== columnsMemoized) {
      setColumnsMemoized(columnsMemoized);
    }
  }, [columns, columnsMemoized]);

  const hasFooter = columns.some(({ Footer }) => Footer);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    gotoPage,
    pageCount,
    state,
    page,
  } = useTable<D>(
    {
      columns: columnsMemoized,
      data: dataMemoized,
      initialState,
    },
    ...plugins,
  );

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    gotoPage,
    pageCount,
    state,
    page,

    hasFooter,
    hasPagination,
  };
};

export default useMemoizedTable;
