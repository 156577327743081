import { Loan } from '@savgroup-front-common/types';

export enum REPAIRER_DOMAIN {
  LOANS = 'loans',
}

export interface RepairerState {
  [REPAIRER_DOMAIN.LOANS]: {
    [fileId: string]: Loan[];
  };
}
