import { createSelector } from 'reselect';

import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { ControlRootState } from 'control/domains/ControlRootState';
import { isFacetActive } from 'control/helpers';

import { TODOS_SEARCH } from './actionTypes';
import { TODOS_LIST_DOMAIN_KEY } from './constants';
import { TODOS_LIST_DOMAIN } from './types';

export const todosListSelector = (state: ControlRootState) =>
  state[TODOS_LIST_DOMAIN_KEY];

export const selectTodosListPagination = createSelector(
  todosListSelector,
  (state) => {
    return {
      query: state[TODOS_LIST_DOMAIN.QUERY] || undefined,
      sort: state[TODOS_LIST_DOMAIN.SORT],
      isDescending: state[TODOS_LIST_DOMAIN.IS_DESCENDING],
      page: state[TODOS_LIST_DOMAIN.CURRENT_PAGE_NUMBER],
      pageSize: state[TODOS_LIST_DOMAIN.BY_PAGE],
    };
  },
);
export const selectTodosListFilters = createSelector(
  todosListSelector,
  (state) =>
    state[TODOS_LIST_DOMAIN.RUBRICS].map((rubric) => ({
      ...rubric,
      values: rubric.values.filter((facet) => !facet.isHidden),
    })),
);
export const selectTodosListCount = createSelector(
  todosListSelector,
  (state) => state[TODOS_LIST_DOMAIN.TOTAL_COUNT],
);

export const selectTodosListIsLoading = (
  state: ControlRootState,
  { page }: { page: number },
) => isActionLoadingSelector(state, TODOS_SEARCH, page);

export const selectTodosListWasLoaded = (
  state: ControlRootState,
  { page }: { page: number },
) => wasActionLoadedSelector(state, TODOS_SEARCH, page);

export const selectTodosListAppliedFiltersCount = createSelector(
  [selectTodosListFilters],
  (categories) => {
    return categories.reduce(
      (acc, category) => acc + category.values.filter(isFacetActive).length,
      0,
    );
  },
);

export const selectTodosListErrors = (
  state: ControlRootState,
  { page }: { page: number },
): ErrorFromBack[] => getActionErrorsSelector(state, TODOS_SEARCH, page);
