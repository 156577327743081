import { SearchResult } from '../Search.types';

import getResultListSize from './getResultListSize';
import getResultSize from './getResultSize';
import searchInferiorIndexToFocus from './searchInferiorIndexToFocus';

interface GetNextScrollPositionArgs {
  results: SearchResult[];
  indexFocus?: number;
  scrollTop: number;
  containerHeight: number;
}

const getNextScrollPosition = ({
  results,
  indexFocus,
  scrollTop,
  containerHeight,
}: GetNextScrollPositionArgs): number | null => {
  const { indexFocus: inferiorIndexFocus } = searchInferiorIndexToFocus({
    results,
    indexFocus,
  });

  const shouldGoToFirst = inferiorIndexFocus >= Number(indexFocus);

  if (shouldGoToFirst) {
    return 0;
  }

  const isBigStep = Number(indexFocus) - inferiorIndexFocus > 1;
  const nextFocusIndex = indexFocus || 0;
  const nextFocusPosition = getResultListSize(results, nextFocusIndex);
  const shouldGoTop = scrollTop > nextFocusPosition;

  if (shouldGoTop && isBigStep) {
    return getResultListSize(results, inferiorIndexFocus + 1);
  }

  if (shouldGoTop) {
    return nextFocusPosition;
  }

  const scrollBottom = scrollTop + containerHeight;
  const nextFocusSize = getResultSize(results[nextFocusIndex]);
  const shouldGoBottom = scrollBottom < nextFocusPosition + nextFocusSize;

  if (shouldGoBottom) {
    return nextFocusPosition + nextFocusSize - containerHeight;
  }

  return null;
};

export default getNextScrollPosition;
