import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BaseBackResponse,
  LABEL_ADDRESS_TYPE,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}carrier/files/${COMMON_QUERY_PARAMS.FILE_ID}/labels/current/address`;

interface SetFileLabelAddressArgs {
  fileId: string;
  addressType: LABEL_ADDRESS_TYPE;
  address: AddressInfoDto;
}

async function setFileLabelAddress({
  fileId,
  address,
  addressType,
}: SetFileLabelAddressArgs): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall(url, {
      method: SUPPORTED_METHODS.PUT,
      json: { addressType, address },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default setFileLabelAddress;
