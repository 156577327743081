import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.card.videoCallLauncher.title',
    defaultMessage: 'Make video call',
  },
  joinTheCall: {
    id: 'view.videoCallLauncher.joinTheCall.button',
    defaultMessage: 'Join the call',
  },
  joinAlreadyStartedCall: {
    id: 'view.videoCallLauncher.joinAlreadyStartedCall.button',
    defaultMessage: 'Join the call',
  },

  customerJoinCallLink: {
    id: 'view.videoCallLauncher.customerJoinCallLink',
    defaultMessage: 'Customer call link',
  },
  customerFullName: {
    id: 'view.videoCallLauncher.customerFullName',
    defaultMessage: 'Customer full name',
  },
  phoneLabel: {
    id: 'view.card.client.phone',
    defaultMessage: 'Phone Number',
  },
  videoCallHistory: {
    id: 'view.card.videoCallLauncher.videoCallHistory.title',
    defaultMessage: 'History',
  },
  'Reverse.Owner.Errors.Owner.ModeratorAlreadyConnected': {
    id: 'view.clientVisioPage.ModeratorAlreadyConnected',
    defaultMessage:
      'It seems that an operator is already connected to the video call.',
  },

  confirmationConnectSessionTitle: {
    id: 'view.videoCallLauncher.confirmationConnectSessionTitle',
    defaultMessage: 'A moderator is already connected to the video call',
  },
  confirmationConnectSessionDidactic: {
    id: 'view.videoCallLauncher.confirmationConnectSessionDidactic',
    defaultMessage:
      'If you confirm, you will be connected to the video call and the curent moderator will be disconnected.',
  },
});
