import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

import {
  CreateButtonAction,
  CreateButtonState,
  MODAL_STATE_ACTION_TYPES,
} from './CreateButton.types';

export const createModalInitialState = (): CreateButtonState => {
  return {
    isMenuOpen: false,
    modalType: null,
  };
};

export const createModalStateReducer = (
  state: CreateButtonState,
  action: CreateButtonAction,
): CreateButtonState => {
  switch (action.type) {
    case MODAL_STATE_ACTION_TYPES.OPEN_CREATE_MODAL: {
      return {
        ...state,
        isMenuOpen: false,
        modalType:
          state.modalType !== null ? state.modalType : action.payload.key,
      };
    }
    case MODAL_STATE_ACTION_TYPES.CLOSE_CREATE_MODAL:
      return {
        ...state,
        modalType: null,
      };
    case MODAL_STATE_ACTION_TYPES.TOGGLE_MENU: {
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    }
    case MODAL_STATE_ACTION_TYPES.CLOSE_MENU: {
      return {
        ...state,
        isMenuOpen: false,
      };
    }
    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
