import { CONFIGURATION_CONSOLE_ROUTES } from '../ConfigurationConsoleContent.constants';

export enum SETTINGS_PAGE_NAMES {
  MODIFY_RULE = 'modify-rule',
  SHOW_SETTINGS = 'show-settings',
  GLOBAL_REPORT = 'global-report',
  HISTORY = 'history',
  NEW_RULE = 'new-rule',
  SUBMIT_RULE = 'submit-rule',
}

export const RULE_SETTINGS_ROUTES = {
  SHOW_SETTINGS: `${CONFIGURATION_CONSOLE_ROUTES.SETTINGS}/${SETTINGS_PAGE_NAMES.SHOW_SETTINGS}/:importType?`,
  MODIFY_RULE: `${CONFIGURATION_CONSOLE_ROUTES.SETTINGS}/${SETTINGS_PAGE_NAMES.MODIFY_RULE}/:importType/:ruleId`,
  GLOBAL_REPORT: `${CONFIGURATION_CONSOLE_ROUTES.SETTINGS}/${SETTINGS_PAGE_NAMES.GLOBAL_REPORT}/:importType?`,
  HISTORY: `${CONFIGURATION_CONSOLE_ROUTES.SETTINGS}/${SETTINGS_PAGE_NAMES.HISTORY}/:importType?`,
  NEW_RULE: `${CONFIGURATION_CONSOLE_ROUTES.SETTINGS}/${SETTINGS_PAGE_NAMES.NEW_RULE}/:importType?`,
  SUBMIT_RULE: `${CONFIGURATION_CONSOLE_ROUTES.SETTINGS}/${SETTINGS_PAGE_NAMES.SUBMIT_RULE}`,
};
