import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import {
  Button,
  ButtonLink,
} from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  CrossIcon,
  DownloadIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import {
  $DocumentBarCol,
  $DocumentBarRow,
  $DocumentData,
  $DocumentInfo,
  $DocumentName,
} from './DocumentPreviewHeader.styles';
import DocumentTypeIcon from './DocumentPreviewIconType';
import messages from './messages';

interface DocumentPreviewHeaderProps {
  onClose: () => void;
  name?: string;
  url?: string;
  animationType: string;
  type?: string;
  onZoom: () => void;
  isZoomed: boolean;
}

const DocumentPreviewHeader: FunctionComponent<
  React.PropsWithChildren<DocumentPreviewHeaderProps>
> = ({
  onClose,
  name = '',
  url = '',
  animationType,
  type,
  onZoom,
  isZoomed,
}) => {
  const classNames = `${animationType} `;

  return (
    <$DocumentBarRow onClick={(event) => event.stopPropagation()}>
      <$DocumentBarCol xs={12} md={7}>
        <TransitionGroup
          component={null}
          childFactory={(child) => React.cloneElement(child, { classNames })}
        >
          <CSSTransition
            key={name}
            timeout={400}
            unmountOnExit
            classNames={classNames}
          >
            {(animationState) => (
              <$DocumentData
                animationState={animationState}
                animationDuration={400}
              >
                <$DocumentName>{name}</$DocumentName>
                <$DocumentInfo>
                  <div>
                    <DocumentTypeIcon type={type} color="white" size="14px" />
                  </div>
                  <div>{type}</div>
                </$DocumentInfo>
              </$DocumentData>
            )}
          </CSSTransition>
        </TransitionGroup>
      </$DocumentBarCol>

      <Col xs={12} md={5}>
        <Row end="xs">
          <Col>
            <Button
              secondary
              onClick={onZoom}
              type={BUTTON_TYPES.BUTTON}
              dataTestId="zoom-document-preview"
              icon={isZoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
              naked
            />
          </Col>
          <Col>
            <ButtonLink
              secondary
              download={name}
              isExternal
              href={url}
              icon={<DownloadIcon />}
            >
              <SafeFormattedMessageWithoutSpread message={messages.download} />
            </ButtonLink>
          </Col>
          <Col>
            <Button
              secondary
              onClick={onClose}
              type={BUTTON_TYPES.BUTTON}
              dataTestId="close-document-preview"
              icon={<CrossIcon />}
            />
          </Col>
        </Row>
      </Col>
    </$DocumentBarRow>
  );
};

DocumentPreviewHeader.displayName = 'DocumentPreviewHeader';

export default React.memo(DocumentPreviewHeader);
