import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  CURRENCIES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  MARKETING_OFFER_TARGET,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}refunds`;

export interface MakeRefundCommandPayloadLines {
  target: MARKETING_OFFER_TARGET;
  targetId?: string;
  targetLineId: string;
  amount: {
    amount: number;
    currency: CURRENCIES | string;
  };
}

export interface MakeRefundCommandPayload {
  fileId: string;
  lines: MakeRefundCommandPayloadLines[];
  method: string;
  refundReference?: string;
  creditNoteReference?: string;
  comment?: string;
}

const makeRefundCommand = async ({
  payload,
}: {
  payload: MakeRefundCommandPayload;
}): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    const response = await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default makeRefundCommand;
