import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GetInvoicesByFileIdPayload } from './actionCreators';
import * as types from './actionTypes';

function* getInvoicesByFileIdWorker({
  payload,
}: {
  payload: GetInvoicesByFileIdPayload;
}): Generator {
  const { fileId } = payload;

  if (!fileId) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_INVOICES_BY_FILE_ID,
    `${APIConfiguration.payment}invoices/File/${fileId}`,
    { method: SUPPORTED_METHODS.GET },
    {
      fileId,
      indexer: fileId,
    },
  );

  yield put(types.GET_INVOICES_BY_FILE_ID.end({ fileId }));
}
function* getInvoicesByFileIdWatcher() {
  yield takeEvery<Action<GetInvoicesByFileIdPayload>>(
    types.GET_INVOICES_BY_FILE_ID.BASE,
    getInvoicesByFileIdWorker,
  );
}

export default function* invoiceSaga(): Generator {
  try {
    yield all([getInvoicesByFileIdWatcher()]);
  } catch (error) {
    logCritical(error as any);
  }
}
