import { EVENT_CODE, EVENT_KEY } from '@savgroup-front-common/constants';

const isAzertyLayout = (layout: Map<EVENT_CODE, string>) => {
  const KEY_Q = layout.get(EVENT_CODE.KEY_Q);
  const KEY_W = layout.get(EVENT_CODE.KEY_W);
  const KEY_E = layout.get(EVENT_CODE.KEY_E);
  const KEY_R = layout.get(EVENT_CODE.KEY_R);
  const KEY_T = layout.get(EVENT_CODE.KEY_T);
  const KEY_Y = layout.get(EVENT_CODE.KEY_Y);

  if (EVENT_KEY.A !== KEY_Q) {
    return false;
  }
  if (EVENT_KEY.Z !== KEY_W) {
    return false;
  }
  if (EVENT_KEY.E !== KEY_E) {
    return false;
  }
  if (EVENT_KEY.R !== KEY_R) {
    return false;
  }
  if (EVENT_KEY.T !== KEY_T) {
    return false;
  }
  if (EVENT_KEY.Y !== KEY_Y) {
    return false;
  }

  return true;
};

export default isAzertyLayout;
