import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/assignment`;

interface AssignFileToUserCommandPayload {
  fileId: string;
  isAutomatic?: boolean;
  assignToUserId: string;
}

const assignFileToUserCommand = async ({
  fileId,
  isAutomatic = false,
  assignToUserId,
}: AssignFileToUserCommandPayload): Promise<BaseBackResponse> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        isAutomatic,
        assignToUserId,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default assignFileToUserCommand;
