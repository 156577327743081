import React, { FC } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const FiberManualRecordIcon: FC<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 24 24">
    <path d="M24 24H0V0h24v24z" fill="none" />
    <circle cx="12" cy="12" r="8" fill="currentColor" />
  </Icon>
);

FiberManualRecordIcon.displayName = 'FiberManualRecord';

export default FiberManualRecordIcon;
