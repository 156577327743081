import * as ActionTypes from './actionTypes';

export const initialState = {
  refund: {},
};

function onLoadRefundsErrored(state, { meta }) {
  const { fileId } = meta;

  return {
    ...state,
    refund: {
      [fileId]: {
        ...(state.refund || {}),
        amountToDeduct: 0,
      },
    },
  };
}
function onLoadRefundsSucceeded(state, { payload, meta }) {
  const { fileId } = meta;

  return {
    ...state,
    refund: {
      [fileId]: {
        ...(state.refund || {}),
        ...payload.value,
      },
    },
  };
}

function onRefundStatusReset(state, { payload: fileId }) {
  return state.setIn([fileId, 'refundFailed'], false);
}

export default function refundReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_REFUNDS.SUCCEEDED:
      return onLoadRefundsSucceeded(state, action);
    case ActionTypes.LOAD_REFUNDS.ERRORED:
      return onLoadRefundsErrored(state, action);
    case ActionTypes.RESET_REFUND_STATUS:
      return onRefundStatusReset(state, action);
    default:
      return state;
  }
}
