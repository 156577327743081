import { AddressInfoDto } from './AddressInfo';
import { TopicType } from './TopicType';

export type SellerId = string;
export type TenantId = string;

export enum REQUIRED_ONBOARDING_CONFIG_STEP {
  NONE = 'None',
  FROM_SCRATCH_INTEGRATION = 'FromScratchIntegration',
  FROM_SCRATCH_WARRANTIES = 'FromScratchWarranties',
  FROM_SCRATCH_ACTOR = 'FromScratchActor',
  SELECT_PRICING_PLAN = 'SelectPricingPlan',
}

export enum SELLER_PRODUCT_USAGE_CONTEXT {
  PRODUCTION = 'Production',
  PRODUCT_TESTING = 'ProductTesting',
}

export enum SELLER_TYPE {
  RETAILER = 'Retailer',
  SUPPLIER = 'Supplier',
  REPAIRER = 'Repairer',
}

export enum PRICING_PLAN {
  ENTERPRISE = 'Enterprise',
  NONE = 'None',
  BASIC = 'Basic',
}

export interface Seller {
  acceptedLanguages: string[];
  addressInfos: AddressInfoDto[];
  creationDate: string;
  id: SellerId;
  name: string;
  signatureDate: string;
  selfOnboardingDemoContextExpirationDate?: string;
  tenantId?: string;
  isTenant?: boolean;
  sellerType: SELLER_TYPE;
  requiredOnboardingConfigStep?: REQUIRED_ONBOARDING_CONFIG_STEP;
  sellerProductUsageContext?: SELLER_PRODUCT_USAGE_CONTEXT;
  pricingPlan?: PRICING_PLAN;
}

export interface SellerExport {
  date: string;
  id: string;
  marketplace: string;
  sellerId: string;
}

export interface ServiceBusConfiguration {
  id?: string;
  topicType: TopicType;
  topicName?: string;
  subscriptionName?: string;
  isActive?: boolean;
}

export interface NewServiceBus {
  id: string;
  sellerId: string;
  topicType: string;
  topicName: string;
  subscriptionName: string;
  isActive: boolean;
  topicUrl: string;
  primaryKey: string;
  secondaryKey: string;
  primaryConnectionString: string;
  secondaryConnectionString: string;
}

export interface AdapterConfiguration {
  id?: string;
  sellerId: string;
  adapterRequest: string;
  adapterAuthentification: string;
  verb: string;
  url: string;
}
