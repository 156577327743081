import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AssemblingModel,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}assemblingmodels/bySkus`;

interface GetAssemblingModelsBySkusCommandPayload {
  skus: string[];
  sellerId: string;
}

async function getAssemblingModelsBySkusCommand({
  skus,
  sellerId,
}: GetAssemblingModelsBySkusCommandPayload): Promise<
  BackResponse<AssemblingModel[]> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<AssemblingModel[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        skus,
        sellerId,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getAssemblingModelsBySkusCommand;
