import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { PaymentProvider } from '@savgroup-front-common/types/src/Payment/PaymentProvider';

import { PaymentAccountSummary } from './getPaymentAccountsBySellerQuery';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.payment}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/payments/${COMMON_QUERY_PARAMS.PAYMENT_PROVIDER}`;

export interface CreateOrUpdatePaymentAccountCommandArgs {
  sellerId: string;
  paymentProvider: PaymentProvider;
  name: string;
  description: string;
  token: { publicToken?: string; privateToken: string };
  metadata?: Record<string, string | undefined>;
}

export const createOrUpdatePaymentAccountCommand = async ({
  sellerId,
  paymentProvider,
  name,
  description,
  token,
  metadata,
}: CreateOrUpdatePaymentAccountCommandArgs): Promise<
  BackResponse<PaymentAccountSummary> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace(COMMON_QUERY_PARAMS.PAYMENT_PROVIDER, paymentProvider);

    return await apiCall<BackResponse<PaymentAccountSummary>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        name,
        description,
        token,
        metadata,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
