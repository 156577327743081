import { createAction } from '@reduxjs/toolkit';

import * as ActionTypes from './actionTypes';

export const getCurrentDraftedQuote = createAction(
  ActionTypes.GET_CURRENT_DRAFTED_QUOTE.BASE,
);
export const getQuoteById = createAction(ActionTypes.GET_QUOTE_BY_ID.BASE);

export const deleteQuoteTask = createAction(ActionTypes.DELETE_QUOTE_TASK.BASE);
export const editQuoteTask = createAction(ActionTypes.EDIT_QUOTE_TASK.BASE);
export const submitQuote = createAction(ActionTypes.SUBMIT_QUOTE.BASE);

export const addEmptyQuoteTask = createAction(
  ActionTypes.ADD_EMPTY_QUOTE_TASK.BASE,
);

export const getFileQuotes = createAction(ActionTypes.GET_FILE_QUOTES.BASE);
export const validateRejectQuote = createAction(
  ActionTypes.VALIDATE_REJECT_QUOTE.BASE,
);
