import { defineMessages } from 'react-intl';

export default defineMessages({
  commentTitle: {
    id: 'view.modals.header.commentTitle',
    defaultMessage: 'Comment claim',
  },
  addressUpdate: {
    id: 'view.modals.header.addressUpdate',
    defaultMessage: 'Address update',
  },
  sellerSettingsUpdateHeader: {
    id: 'view.modals.header.sellerSettingsUpdateHeader',
    defaultMessage: 'Seller settings update',
  },
});
