import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useTheme } from 'styled-components';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  ErrorFromBack,
  getUniqErrorMessages,
} from '@savgroup-front-common/core/src/helpers';
import { RESULT_TYPE } from '@savgroup-front-common/types';

import { SEARCH_HEIGHT } from '../../../Search.contants';
import SearchItemContainer from '../../common/SearchItemContainer';
import SearchResultIcon from '../../common/SearchResultIcon';

import { $ResultError } from './SearchItemError.styles';

interface SearchItemErrorProps {
  type: RESULT_TYPE;
  animationIndex: number;
  animationState: TransitionStatus;
  errors: ErrorFromBack[];
}

const SearchItemError: FunctionComponent<
  React.PropsWithChildren<SearchItemErrorProps>
> = ({ animationIndex, animationState, type, errors }) => {
  const theme = useTheme();

  const message = getUniqErrorMessages(errors);

  return (
    <SearchItemContainer
      animationState={animationState}
      animationIndex={animationIndex}
      height={SEARCH_HEIGHT.ERROR}
    >
      <$ResultError data-testid={`search-result-error-${type}`}>
        <SearchResultIcon type={type} color={theme.colors.danger} />
        <SafeFormattedMessageWithoutSpread message={message[0]} />
      </$ResultError>
    </SearchItemContainer>
  );
};

SearchItemError.displayName = 'SearchItemError';

export default SearchItemError;
