import { Action } from 'redux-actions';
import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import 'regenerator-runtime';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GET_DOCUMENTS_BY_CLAIM_ID } from './actionTypes';

interface GetDocumentsByClaimIdPayload {
  claimId: string;
}

function* getDocumentsByClaimIdWorker({
  payload,
}: {
  payload: GetDocumentsByClaimIdPayload;
}): Generator<
  | CallEffect
  | PutEffect<{
      type: string;
    }>
> {
  const { claimId } = payload;
  const meta = { claimId, indexer: claimId };

  yield call(
    callAndGetResponse,
    GET_DOCUMENTS_BY_CLAIM_ID,
    `${APIConfiguration.claim}claims/${claimId}/documents`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    meta,
  );

  yield put(GET_DOCUMENTS_BY_CLAIM_ID.end(meta));
}
function* getDocumentsByClaimIdWatcher() {
  yield takeLatest<Action<GetDocumentsByClaimIdPayload>>(
    GET_DOCUMENTS_BY_CLAIM_ID.BASE,
    getDocumentsByClaimIdWorker,
  );
}

export default function* mainSaga() {
  try {
    yield all([getDocumentsByClaimIdWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
