import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Warranty,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claim/${COMMON_QUERY_PARAMS.CLAIM_ID}/warranties`;

interface GetWarrantiesForClaimQueryArgs {
  claimId: string;
}

const getWarrantiesForClaimQuery = async ({
  claimId,
}: GetWarrantiesForClaimQueryArgs): Promise<
  BackResponse<Warranty[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    return await apiCall<BackResponse<Warranty[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getWarrantiesForClaimQuery;
