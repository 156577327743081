import { RESULT_TYPE } from '@savgroup-front-common/types';

import messages from './messages';

export const getSearchTitleMessage = (type: RESULT_TYPE) => {
  if (type === RESULT_TYPE.ORDER) {
    return messages.orders;
  }

  if (type === RESULT_TYPE.SPARE_PART_REQUEST) {
    return messages.sparePartRequestLines;
  }

  if (type === RESULT_TYPE.STOCK_ITEM) {
    return messages.stockItems;
  }

  return messages.files;
};
