import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SparePartRequestLine,
} from '@savgroup-front-common/types';

import { SparePartRequestLineRubric } from '../../domains/sparePartRequests/requestLineList';

const ENDPOINT = `${APIConfiguration.sparePart}search/requestline`;

export interface GetSparePartRequestLinesArgs {
  page: number;
  pageSize: number;
  isDescending?: boolean;
  sort?: SORT_TYPES;
  query: string;
}

interface GetSparePartRequestLinesPayload {
  hitCount: number;
  hits: SparePartRequestLine[];
  rubrics: SparePartRequestLineRubric[];
}

export default async function getSparePartRequestLines({
  page,
  pageSize,
  isDescending,
  sort = SORT_TYPES.REQUEST_LINE_LAST_MODIFICATION_TIME_UTC,
  query,
}: GetSparePartRequestLinesArgs): Promise<
  BackResponse<GetSparePartRequestLinesPayload> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<GetSparePartRequestLinesPayload>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          page,
          pageSize,
          isDescending,
          sort,
          query,
        },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
