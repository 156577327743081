import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  RequestLineId,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}stocks`;

export interface GetSparePartStocksArgs {
  requestLineId: RequestLineId;
}

export interface Stock {
  stockId: string;
  stockName: string;
}

export default async function getSparePartStocks(
  payload: GetSparePartStocksArgs,
): Promise<BackResponse<Stock[]> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<Stock[]>>(
      buildUrl(ENDPOINT, {
        requestLineId: payload.requestLineId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
