import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  RequestLineStatus,
  ReverseMoney,
  SparePartStateUponReceipt,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requests/handling/validate`;

export interface RequestLineStatusUpdateDto {
  requestLineId: string;
  statusUpdateData?: {
    sparePartReference?: string;
    orderReference?: string;
    comment?: string;
    excludingTaxPurchasePrice?: ReverseMoney;
    rejectionReason?: string;
    supplierSubmissionDate?: Date;
    sparePartStateUponReceipt?: SparePartStateUponReceipt;
    stockId?: string;
    additionalFiles?: string[];
    stockItemLocation?: string;
  };
}

export interface UpdateRequestLineStatusCommandPayload {
  fileId?: string;
  lineStatusUpdates: RequestLineStatusUpdateDto[];
  status: RequestLineStatus;
}

export async function updateRequestLineStatusCommand({
  lineStatusUpdates,
  fileId,
  status,
}: UpdateRequestLineStatusCommandPayload): Promise<
  | BackResponse<{ validationOK: string[]; validationKO: string[] }>
  | BackResponseFailure
> {
  try {
    return await apiCall<
      BackResponse<{ validationOK: string[]; validationKO: string[] }>
    >(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        lineStatusUpdates,
        fileId,
        status,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
