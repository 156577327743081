import { Telegram } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const SendIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Telegram width={size} height={size} color={color} />;

SendIcon.displayName = 'SendIcon';

export default SendIcon;
