import { Archive } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const InventoryIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Archive width={size} height={size} color={color} />;

InventoryIcon.displayName = 'InventoryIcon';

export default InventoryIcon;
