import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}publicApi/apiApplication/${COMMON_QUERY_PARAMS.SELLER_ID}`;

interface GetApiApplication {
  sellerId: string;
}

interface GetApiApplicationResponse extends BaseBackResponse {
  failure: false;
  value: {
    id: string;
    name: string;
    primaryKey: string;
    secondaryKey: string;
    sellerId: string;
  }[];
}
interface GetApiApplicationResponseError extends BaseBackResponse {
  failure: true;
}

async function getApiApplication({
  sellerId,
}: GetApiApplication): Promise<
  GetApiApplicationResponse | GetApiApplicationResponseError
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<GetApiApplicationResponse>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return { failure: true, errors: (exception as any).errors };
  }
}

export default getApiApplication;
