import useHasTenantAccountPermission from './useHasTenantAccountPermission';
import useHasTenantConnectedAppPermission from './useHasTenantConnectedAppPermission';
import useHasTenantProfilePermission from './useHasTenantProfilePermission';
import useHasTenantThemePermission from './useHasTenantThemePermission';

const useHasTenantPermission = () => {
  const hasTenantProfilesPermission = useHasTenantProfilePermission();
  const hasTenantAccountPermission = useHasTenantAccountPermission();
  const hasTenantConnectedAppPermission = useHasTenantConnectedAppPermission();
  const hasTenantThemePermission = useHasTenantThemePermission();

  const permissions = {
    hasTenantProfilesPermission,
    hasTenantAccountPermission,
    hasTenantConnectedAppPermission,
    hasTenantThemePermission,
  };

  return permissions;
};

export default useHasTenantPermission;
