import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}console/entryVisibility`;

export async function UpsertConsoleEntryVisibilityCommand({
  sellerId,
  importType,
  visible,
}: {
  sellerId: string;
  importType: string;
  visible: boolean;
}): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    const response = await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: { sellerId, importType, visible },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
