import claimReducer, {
  initialState as claimInitialState,
} from './claim/claimReducer';
import clientReducer, {
  initialState as clientInitialState,
} from './client/clientReducer';
import productReducer, {
  initialState as productInitialState,
} from './product/productReducer';
import transportReducer, {
  initialState as transportInitialState,
} from './transport/transportReducer';
import warrantiesReducer, {
  initialState as warrantiesInitialState,
} from './warrantie/warrantieReducer';
import reducer, {
  initialState as workflowInitialState,
} from './workflow/reducer';

export const initialState = {
  client: { ...clientInitialState },
  claim: { ...claimInitialState },
  product: { ...productInitialState },
  workflow: { ...workflowInitialState },
  warranties: { ...warrantiesInitialState },
  transport: { ...transportInitialState },
};

export default function businessReducer(state = initialState, action) {
  return {
    client: clientReducer(state.client, action),
    claim: claimReducer(state.claim, action),
    product: productReducer(state.product, action),
    workflow: reducer(state.workflow, action),
    warranties: warrantiesReducer(state.warranties, action),
    transport: transportReducer(state.transport, action),
  };
}
