import { darken, rgba, setLightness } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  media,
  Z_INDEX_MENU,
} from '@savgroup-front-common/constants/src/shared';

import { rem } from '@savgroup-front-common/core/src/helpers';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';
import { simpleSlideFromLeftAnimation } from '@savgroup-front-common/core/src/animations/simpleAnimation';

const size = 70;
const smallSize = 50;

interface $MenuContainerProps {
  $isOpen: boolean;
}

export const $MenuContainer = styled.nav<$MenuContainerProps>`
  display: flex;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  position: relative;
  z-index: ${Z_INDEX_MENU};
  min-width: ${rem(size)};
  flex-basis: ${rem(size)};
  flex-direction: column;
  justify-content: flex-start;

  @media ${media.maxWidth.xs} {
    transform: translateX(${({ $isOpen }) => ($isOpen ? `100%` : 0)});
    max-width: ${rem(300)};
    position: absolute;
    right: 100%;
    width: 100%;
    height: calc(100% - ${rem(50)});
    transition-duration: 0.5s;
    transition-property: transform;
    ${decelerateTimingFunctionTransition};
  }
`;

export const $MenuCountBadge = styled.span.attrs({
  animationDuration: 400,
})<{ $count: number }>`
  position: absolute;
  top: ${rem(5)};
  pointer-events: none;
  user-select: none;
  right: -${rem(3)};
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: ${rem(4)};
  background: ${({ theme }) => theme.colors.alert};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${rem(20)};
  font-size: ${rem(11)};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  text-align: center;

  opacity: ${({ $count }) => ($count > 0 ? 1 : 0)}!important;
  transition-duration: 400ms;
  ${decelerateTimingFunctionTransition};

  @media ${media.minWidth.sm} {
    transform: translate(0.25rem, 0.25rem);
    z-index: 1;
    + svg {
      transform: translate(
        ${({ $count }) => ($count > 0 ? '-0.25rem' : '0')},
        ${({ $count }) => ($count > 0 ? '-0.25rem' : '0')}
      ) !important;
    }
  }

  @media ${media.maxWidth.xs} {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;

export const $MenuIconContainer = styled.div`
  text-align: center;
  @media ${media.minWidth.sm} {
    position: relative;
  }
`;

export const $MenuItemTitle = styled.span`
  font-size: ${rem(10)};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.25rem;
  max-width: 100%;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  line-height: ${rem(12)};
  max-height: ${rem(12 * 2)};
  text-align: center;

  @media ${media.maxWidth.xs} {
    font-size: ${rem(14)};
    padding-left: 1rem;
  }
`;

const activeStyle = css`
  ${decelerateTimingFunctionTransition};
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};

    background-color: ${({ theme }) =>
      rgba(setLightness(0.7, theme.colors.darkBackground), 0.3)};
  }
`;

interface $SubLinkProps {
  $isActive: boolean;
}

export const $SubLink = styled(NavLink)<$SubLinkProps>`
  padding: 0.5rem 1rem;
  @media ${media.maxWidth.xs} {
    padding-left: 3.7rem;
    background: ${({ theme }) =>
      setLightness(0.125, theme.colors.darkBackground)};
  }
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${activeStyle};
  border-left-width: ${rem(3)};
  border-left-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary : 'transparent'};
  border-left-style: solid;

  svg {
    margin-right: 1rem;
  }
  span {
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
    line-height: ${rem(16)};
  }
`;

export const $SubMenuList = styled.ul`
  border-top-right-radius: ${({ theme }) => theme.borders.radius};
  border-bottom-right-radius: ${({ theme }) => theme.borders.radius};
  margin: 0;

  @media ${media.minWidth.sm} {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: ${({ theme }) => theme.colors.darkBackground};
  }

  ${$MenuCountBadge} {
    right: ${rem(18)};
  }

  @media ${media.maxWidth.xs} {
    background-color: ${({ theme }) =>
      darken(0.02, theme.colors.darkBackground)};
  }
  list-style: none;
  padding: 0.5rem 0;
  min-width: 10rem;
  li {
    position: relative;
    padding: 0;
    margin: 0;
  }
`;

const badgeHover = css`
  &:hover ${$MenuIconContainer} {
    svg {
      transform: translate(0, 0) !important;
    }
    ${$MenuCountBadge} {
      opacity: 0 !important;
    }
  }
`;

export const $MainLink = styled(NavLink)`
  display: flex;
  cursor: pointer;
  outline: none;
  flex-direction: column;
  text-decoration: inherit;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  @media ${media.maxWidth.xs} {
    position: relative;
  }

  svg {
    ${decelerateTimingFunctionTransition};
    transition-duration: 300ms;
    transform: translate(0, 0);
  }

  border-left: ${rem(3)} solid transparent;
  border-right: ${rem(3)} solid transparent;
  height: ${rem(size)};
  min-height: ${rem(size)};

  @media ${media.maxWidth.xs} {
    flex-direction: row;
    align-items: start;
    justify-content: left;
    border-left-width: ${rem(18)};
    border-right-width: ${rem(18)};
    height: ${rem(smallSize)};
    min-height: ${rem(smallSize)};
  }

  ${activeStyle};
`;

export const $MainExternalLink = styled.div`
  display: flex;
  cursor: pointer;
  outline: none;
  flex-direction: column;
  text-decoration: inherit;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  @media ${media.maxWidth.xs} {
    position: relative;
  }

  svg {
    ${decelerateTimingFunctionTransition};
    transition-duration: 300ms;
    transform: translate(0, 0);
  }

  border-left: ${rem(3)} solid transparent;
  border-right: ${rem(3)} solid transparent;
  height: ${rem(size)};
  min-height: ${rem(size)};

  @media ${media.maxWidth.xs} {
    flex-direction: row;
    align-items: left;
    justify-content: left;
    border-left-width: ${rem(18)};
    border-right-width: ${rem(18)};
    height: ${rem(smallSize)};
    min-height: ${rem(smallSize)};
  }

  ${activeStyle};
`;

interface $MenuListProps {
  $index: number;
}

export const $MenuList = styled.ul<$MenuListProps>`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    height: ${rem(size)};
    background-color: ${({ theme }) => theme.colors.primary};
    width: ${rem(3)};
    left: 0;
    top: 0;
    transition-duration: 0.3s;
    transition-property: transform;
    transform: translateY(${({ $index }) => rem($index * size)});
    @media ${media.maxWidth.xs} {
      transform: translateY(${({ $index }) => rem($index * smallSize)});
      height: ${rem(smallSize)};
    }
    ${decelerateTimingFunctionTransition};
  }
  > li {
  }
`;

export const $MenuItem = styled.li<{ $hideCountOnHover: boolean }>`
  width: 100%;
  position: relative;
  ${({ $hideCountOnHover }) => ($hideCountOnHover ? badgeHover : null)};

  &:focus,
  &:hover {
    ${$SubMenuList} {
      @media ${media.minWidth.sm} {
        display: block;
        ${simpleSlideFromLeftAnimation};
      }
    }
  }
`;
