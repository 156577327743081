import { Client, createClient } from 'graphql-sse';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';

import { createGenericContext } from '../../helpers';

export interface GraphQlSseContextArgs {
  graphqlSseClient: Client;
}

const [useGraphQlSseContext, CreateGraphQlSseProvider] =
  createGenericContext<GraphQlSseContextArgs>();

const client = createClient({
  url: `${APIConfiguration.graphQlGateway}`,
});

const GraphQlSseContextProvider: FunctionComponent<
  PropsWithChildren<Record<never, never>>
> = ({ children }) => {
  return (
    <CreateGraphQlSseProvider
      value={{
        graphqlSseClient: client,
      }}
    >
      {children}
    </CreateGraphQlSseProvider>
  );
};

GraphQlSseContextProvider.displayName = 'GraphQlSseContextProvider';

export { GraphQlSseContextProvider, useGraphQlSseContext };
