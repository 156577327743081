import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';

import { Z_INDEX_HEADER_SEARCH_OPEN } from '@savgroup-front-common/constants/src/control';
import { BackgroundDimmer } from '@savgroup-front-common/core/src/atoms/BackgroundDimmer';
import { isAnimationMounted } from '@savgroup-front-common/core/src/helpers';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';

import { HeaderSearchInput } from './components';
import ResultDisplay from './components/SearchBody';
import useSearch from './hooks/useSearch';
import { SEARCH_ANIMATION_DURATION } from './Search.contants';
import SearchContext from './Search.context';
import {
  $ResultContainer,
  $SearchCenter,
  $SearchContainer,
} from './Search.styles';

export const Search: FunctionComponent = () => {
  const {
    isLoading,
    containerRef,
    inputRef,
    isSteady,
    indexFocus,
    query,
    resultsAreOpened,
    handleClose,
    onSearch,
    onFocus,
    onKeyDown,
    showNoResult,
    onClear,
    onShowMore,
    results,
    enabledResultTypes,
    onToggleSearchType,
    focusedResultType,
    showNoFilter,
    containerHeight,
    listHeight,
  } = useSearch();

  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <>
      <$SearchContainer $isNewUiEnabled={isNewUiEnabled}>
        <Transition
          in={resultsAreOpened}
          timeout={SEARCH_ANIMATION_DURATION.CONTAINER}
        >
          {(animationState) => (
            <$SearchCenter
              animationState={animationState}
              $isNewUiEnabled={isNewUiEnabled}
            >
              <SearchContext.Provider
                value={{
                  resultsAreOpened,
                  enabledResultTypes,
                  focusedIndex: indexFocus,
                  disableHover: isSteady,
                  query,
                  onShowMore,
                  onResultClick: handleClose,
                  onToggleSearchType,
                  focusedResultType,
                  results,
                  showNoResult,
                  showNoFilter,
                  isLoading,
                  onSearch,
                  onFocus,
                  onClear,
                  onKeyDown,
                  listHeight,
                  containerHeight,
                }}
              >
                <HeaderSearchInput ref={inputRef} />
                <$ResultContainer
                  $size={containerHeight}
                  animationDuration={SEARCH_ANIMATION_DURATION.CONTAINER}
                  ref={containerRef}
                >
                  {isAnimationMounted(animationState) && <ResultDisplay />}
                </$ResultContainer>
              </SearchContext.Provider>
            </$SearchCenter>
          )}
        </Transition>
      </$SearchContainer>
      <BackgroundDimmer
        onClick={handleClose}
        isOpen={resultsAreOpened}
        animationDuration={SEARCH_ANIMATION_DURATION.CONTAINER}
        zIndex={Z_INDEX_HEADER_SEARCH_OPEN}
      />
    </>
  );
};

Search.displayName = 'Search';

export default React.memo(Search);
