import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import {
  GetStockItemDetailsPayload,
  GetStockItemsDetailsBySparePartRequestPayload,
} from './actionCreators';
import * as types from './actionTypes';

function* getStockItemDetailsWorker(action: {
  payload: GetStockItemDetailsPayload;
}): Generator {
  const URL = `${APIConfiguration.sparePart}stockItem/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}`;
  const { stockItemId } = action.payload;

  yield call(
    callAndGetResponse,
    types.GET_STOCK_ITEM_DETAILS,
    URL.replace(COMMON_QUERY_PARAMS.STOCK_ITEM_ID, stockItemId),
    { method: SUPPORTED_METHODS.GET },
    { indexer: stockItemId },
  );

  yield put(types.GET_STOCK_ITEM_DETAILS.end({ stockItemId }));
}
function* getStockItemDetailsWatcher() {
  yield takeEvery<Action<GetStockItemDetailsPayload>>(
    types.GET_STOCK_ITEM_DETAILS.BASE,
    getStockItemDetailsWorker,
  );
}

function* getStockItemsDetailsBySparePartRequestLineWorker(action: {
  payload: GetStockItemsDetailsBySparePartRequestPayload;
}): Generator {
  const URL = `${APIConfiguration.sparePart}stockItems`;
  const { sparePartRequestLineId } = action.payload;

  yield call(
    callAndGetResponse,
    types.GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE,
    buildUrl(URL, {
      requestLineId: sparePartRequestLineId,
    }),
    { method: SUPPORTED_METHODS.GET },
    { indexer: sparePartRequestLineId, sparePartRequestLineId },
  );

  yield put(
    types.GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE.end({
      sparePartRequestLineId,
    }),
  );
}
function* getStockItemsDetailsBySparePartRequestLineWatcher() {
  yield takeEvery<Action<GetStockItemsDetailsBySparePartRequestPayload>>(
    types.GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE.BASE,
    getStockItemsDetailsBySparePartRequestLineWorker,
  );
}

export default function* stockItemDetailsSaga(): Generator {
  try {
    yield all([
      getStockItemDetailsWatcher(),
      getStockItemsDetailsBySparePartRequestLineWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
