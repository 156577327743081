import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { ConsoleEntityKey } from './getEntityBySellerQuery';
import { EnrichedValue } from './getEntityDefinitionQuery';

const ENDPOINT = `${APIConfiguration.import}console/matchingRules`;

export enum MATCHING_RULE_CONTEXT {
  MATCHING = 'Matching',
  LAST_APPENDED = 'LastAppended',
  UNPUBLISHED = 'Unpublished',
}

export enum CONSTRAINT_MATCHING_RULES {
  NOT_SET = 'NotSet',
  ACTOR_TYPE = 'ActorType',
  ADDITIONAL_INFORMATION = 'AdditionalInformation',
  SOLUTION_TYPE = 'SolutionType',
  TRANSPORT = 'Transport',
  WARRANTY_TYPE_GROUP = 'WarrantyTypeGroup',
  REASON = 'Reason',
  MODEL_TYPE = 'ModelType',
  BRAND = 'Brand',
  EAN = 'Ean',
  WARRANTY_TYPE = 'WarrantyType',
  ISSUE = 'Issue',
  RETURNABLE = 'Returnable',
  REPAIRABLE = 'Repairable',
  ON_SITE_INTERVENTION = 'OnSiteIntervention',
  CUMBERSOME = 'Cumbersome',
  IS_LOW_VALUE = 'IsLowValue',
  SUPPLIER = 'Supplier',
  ADDITIONAL_INFORMATION_VALUE_RULE = 'AdditionalInformationValueRule',
  INCLUDED_ORDER_COUNTRY = 'IncludedOrderCountry',
  EXCLUDED_ORDER_COUNTRY = 'ExcludedOrderCountry',
  PURCHASE_STORE_COUNTRY = 'PurchaseStoreCountry',
  OWNER_ZIPCODE = 'OwnerZipCode',
  PURCHASE_STORE = 'PurchaseStore',
  PAYMENT_METHOD = 'PaymentMethod',
  PRODUCT_PRICE_INFERIOR_OR_EQUAL = 'ProductPriceInferiorOrEqual',
  COUNT_REPAIRS_LOWER_OR_EQUAL = 'CountRepairsLowerOrEqual',
  COUNT_REPAIRS_GREATER_OR_EQUAL = 'CountRepairsGreaterOrEqual',
  ORDER_DELIVERY_METHOD = 'OrderDeliveryMethod',
  SALES_CHANNEL = 'SalesChannel',
  INCLUDED_USER_PROFILE = 'IncludedUserProfile',
  EXCLUDED_USER_PROFILE = 'ExcludedUserProfile',
  PUBLICATION_STATUS = 'PublicationStatus',
  ID = 'Id',
}

export enum ConsoleDataCellType {
  TEXT = 'Text',
  BOOLEAN = 'Boolean',
  NUMBER = 'Number',
  DATE = 'Date',
  ARRAY = 'Array',
  GUID = 'Guid',
  TAG = 'Tag',
  TITLE = 'Title',
  CONTACT_INFO = 'ContactInfo',
  ADDRESS_INFO = 'AddressInfo',
  TEXT_AREA = 'Textarea',
  LOCALES = 'Locales',
  EXTENDED_LOCALES = 'ExtendedLocales',
  OPENING_HOURS = 'OpeningHours',
  LOCALIZED_URL = 'LocalizedUrl',
  COST_PER_KILOGRAM_INTERVAL = 'CostPerKilogramInterval',
  DURATION = 'Duration',
  ENTITY = 'Entity',
  CHILD_ROW = 'ChildRow',
}

export type DataCellWithModificationTrackingDto = {
  modified?: boolean;
  value: string;
  enrichedValue: EnrichedValue;
  values: string[];
  enrichedValues: EnrichedValue[];
  address?: AddressInfoDto;
  contact?: {
    mail: string;
    tel: string;
  };
  type: ConsoleDataCellType;
  entityKey?: ConsoleEntityKey;
  entityKeys?: ConsoleEntityKey[];
  openingHours: {
    hours: { start: string; end: string }[];
    underCondition: boolean;
  };
  locales: {
    cultureCode: string;
    label: string;
  }[];

  extendedLocales: {
    imagePath: string;
    description: string;
    keywords: string[];
    cultureCode: string;
    label: string;
  }[];
  localizedUrls: {
    url: string;
    cultureCodes: string[];
  }[];
  costPerKgIntervals: {
    minWeight: number;
    maxWeight: number;
    cost: number;
  }[];
  duration: {
    value: number;
    unit: string;
  };
  edit?: {
    type: string;
  };
  children: Record<string, DataCellWithModificationTrackingDto>[];
  debugInfo?: {
    isMatch: boolean;
    isWildcard: boolean;
    name: string;
    score: number;
  };
};

type GetMatchingRulesInfoCommandReturnValue = Record<
  string,
  DataCellWithModificationTrackingDto
>[];

export interface MatchingRulesFilters {
  constraint: CONSTRAINT_MATCHING_RULES;
  values?: {
    [key: string]:
      | { id: string; value: string }
      | { value: string }
      | { values: string[] }
      | undefined;
  };
  isInputFilter?: boolean;
}

interface GetMatchingRulesInfoCommandArgs {
  sellerId: string;
  ruleSetId?: string;
  type: string;
  strict: boolean;
  filters?: MatchingRulesFilters[];
  page: number;
  pageSize: number;
  context?: MATCHING_RULE_CONTEXT;
}

export async function getMatchingRulesInfoCommand({
  sellerId,
  ruleSetId,
  type,
  strict,
  filters,
  page,
  pageSize,
  context = MATCHING_RULE_CONTEXT.MATCHING,
}: GetMatchingRulesInfoCommandArgs): Promise<
  BackResponse<GetMatchingRulesInfoCommandReturnValue> | BackResponseFailure
> {
  try {
    const response = await apiCall<
      BackResponse<GetMatchingRulesInfoCommandReturnValue>
    >(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
        ruleSetId,
        type,
        strict,
        filters,
        page,
        pageSize,
        context,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
