import { createContext, FocusEvent } from 'react';

import { RESULT_TYPE } from '@savgroup-front-common/types';

import { defaultSearchResultTypePermissions } from './Search.contants';
import { SearchResult } from './Search.types';

export interface SearchContextReturnValue {
  focusedIndex?: number;
  disableHover: boolean;
  query: string;
  onShowMore: (type: RESULT_TYPE) => void;
  onResultClick: () => void;
  results: SearchResult[];
  showNoResult: boolean;
  isLoading: boolean;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (event: FocusEvent<HTMLInputElement>) => void;
  onClear: () => void;
  enabledResultTypes: RESULT_TYPE[];
  focusedResultType?: RESULT_TYPE;
  onToggleSearchType: (type: RESULT_TYPE) => void;
  showNoFilter: boolean;
  containerHeight: number;
  listHeight: number;
  resultsAreOpened: boolean;
}

const SearchContext = createContext<SearchContextReturnValue>({
  focusedIndex: undefined,
  focusedResultType: undefined,
  disableHover: false,
  enabledResultTypes: defaultSearchResultTypePermissions,
  query: '',
  onShowMore: () => undefined,
  onResultClick: () => undefined,
  onKeyDown: () => undefined,
  onSearch: () => undefined,
  onClear: () => undefined,
  onFocus: () => undefined,
  onToggleSearchType: () => undefined,
  results: [],
  showNoResult: false,
  showNoFilter: false,
  isLoading: false,
  containerHeight: 0,
  listHeight: 0,
  resultsAreOpened: false,
});

export default SearchContext;
