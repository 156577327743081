import { fromJS } from 'immutable';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import md5 from 'md5';
import moment from 'moment';

import { processMessage } from './html-to-react';

import {
  CLOSE_MARKETPLACE_TICKET,
  CREATE_OWNER_FOR_ORDER,
  DELETE_DRAFT_MESSAGE,
  DELETE_UPLOADED_MARKETPLACE_ATTACHMENT,
  LOAD_CLAIM_INFO_BY_ORDER,
  LOAD_FILTERS,
  LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER,
  LOAD_MARKETPLACE_DETAILED_TICKET,
  LOAD_MARKETPLACE_TICKETS,
  LOAD_MARKETPLACE_TICKETS_BY_ORDER,
  LOAD_OWNER_PRODUCTS,
  LOAD_SELLER_PRODUCTS_INFO,
  REFRESH_TICKET_DETAILS,
  SHOW_MARKETPLACE_TICKETS_LOADER,
  SNOOZE_TICKET,
  UPDATE_DRAFT_MESSAGE,
  UPLOAD_MARKETPLACE_ATTACHMENTS,
} from './actionTypes';

const initialState = fromJS({
  accessTokens: fromJS({}),
  attachments: fromJS({}),
  claimsInfo: fromJS({}),
  createdOwnerIds: fromJS({}),
  detailedTicketOrderInfo: fromJS({}),
  detailedTickets: fromJS({}),
  detailedTicketsTracking: fromJS({}),
  draftMessages: fromJS({}),
  filters: fromJS({}),
  marketplaceCapabilities: fromJS({}),
  order: fromJS({ models: {}, update: {} }),
  snoozeStatus: fromJS({}),
  tickets: fromJS({}),
  ticketsByOrder: fromJS({}),
  ticketUploads: fromJS({}),
  sellerProductsList: fromJS({}),
});

// Filters
function onFiltersLoading(state) {
  return state.updateIn(
    ['filters'],
    fromJS({ isLoaded: false, isDirty: false }),
    (oldValue) => oldValue.setIn(['isDirty'], true),
  );
}
function onFiltersLoadingErrored(state) {
  return state.updateIn(['filters'], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onFiltersLoaded(state, { payload }) {
  const allFiltersExceptLanguages = get(payload, ['value']);
  const filters = map(allFiltersExceptLanguages, (f) => ({
    ...f,
    values: sortBy(uniqBy(f.values, 'value'), (v) => v.name.toLowerCase()),
  }));

  return state.setIn(
    ['filters'],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], filters),
  );
}

// Marketplace tickets
function onMarketplaceTicketsLoading(state) {
  return state.updateIn(
    ['tickets'],
    fromJS({ isLoaded: false, isDirty: false }),
    (oldValue) => oldValue.setIn(['isDirty'], true),
  );
}
function onMarketplaceTicketsLoadingErrored(state) {
  return state.updateIn(['tickets'], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onMarketplaceTicketsLoaded(state, { payload }) {
  const tickets = get(payload, ['value']);

  return state.setIn(
    ['tickets'],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], tickets),
  );
}
function onMarketplaceTicketsLoaderShowing(state) {
  return state.updateIn(
    ['tickets'],
    fromJS({ isLoaded: false, isDirty: false }),
    (oldValue) => oldValue.setIn(['isLoaded'], false),
  );
}

// Detailed marketplace tickets
function onDetailedMarketplaceTicketsLoading(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);

  if (!ticketId) {
    return state;
  }

  return state.updateIn(
    ['detailedTickets', ticketId],
    fromJS({ isLoaded: false, isDirty: false }),
    (oldValue) => oldValue.setIn(['isDirty'], true),
  );
}
function onDetailedMarketplaceTicketsLoadingErrored(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);

  if (!ticketId) {
    return state;
  }

  return state.updateIn(['detailedTickets', ticketId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onDetailedMarketplaceTicketsLoaded(state, { payload, meta }) {
  const ticketId = get(meta, ['ticketId']);
  const detailedTicket = get(payload, ['value']);

  if (!ticketId) {
    return state;
  }
  if (!detailedTicket) {
    return state;
  }
  if (detailedTicket.id !== ticketId) {
    return state;
  }

  const newHash = md5(JSON.stringify(omit(detailedTicket, 'trackingActivity')));
  const oldHash = get(state.getIn(['detailedTickets', ticketId, 'value']), [
    'hash',
  ]);

  if (newHash === oldHash) {
    return state.setIn(
      ['detailedTicketsTracking', ticketId],
      get(detailedTicket, ['trackingActivity']),
    );
  }

  const groupMessages = (messages) =>
    map(
      groupBy(messages, (m) => {
        const dateInLocalTimezone = moment(m.creationDateUtc, moment.ISO_8601)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0);

        return dateInLocalTimezone.format();
      }),
      (messagesGroup, day) => ({
        day,
        messagesGroup,
      }),
    );

  const formattedTicket = {
    ...detailedTicket,
    messages: groupMessages(
      map(
        sortBy(
          get(detailedTicket, 'messages'),
          (message) => message.creationDateUtc,
        ),
        (message) => ({
          ...message,
          textBody: processMessage(message.textBody),
          attachments: map(message.attachments, (attachment) => ({
            ...attachment,
            filename: get(attachment, 'name'),
            creationDateUtc: message.creationDateUtc,
          })),
        }),
      ),
    ),
    attachments: flatMap(get(detailedTicket, 'messages'), (message) =>
      map(message.attachments, (attachment) => ({
        ...attachment,
        filename: get(attachment, 'name'),
        creationDateUtc: message.creationDateUtc,
      })),
    ),
    hash: newHash,
  };

  const ticketOrderInfo = pick(detailedTicket, [
    'orderReference',
    'orderLineReference',
    'productTypeName',
    'productModelName',
    'productBrandName',
    'productPrice',
    'customerName',
    'customerEmail',
    'customerPhone',
    'productCondition',
    'productConditionDescription',
    'orderStatus',
    'endOfWarrantDateUtc',
    'carrierCompany',
    'carrierTrackingNumber',
    'carrierTrackingLink',
    'purchaseDateUtc',
    'expectedDeliveryDateUtc',
    'shippingDateUtc',
  ]);

  return state
    .updateIn(['detailedTickets', ticketId], (old) =>
      (old || fromJS({}))
        .setIn(['isLoaded'], true)
        .setIn(['isDirty'], false)
        .updateIn(['value'], (oldFormattedTicket) =>
          get(oldFormattedTicket, 'hash') === get(formattedTicket, 'hash')
            ? oldFormattedTicket
            : formattedTicket,
        ),
    )
    .setIn(
      ['detailedTicketsTracking', ticketId],
      formattedTicket.trackingActivity,
    )
    .setIn(
      ['detailedTicketOrderInfo', ticketId],
      fromJS({ isLoaded: true, isDirty: false }).setIn(
        ['value'],
        ticketOrderInfo,
      ),
    );
}

// Closing of tickets
function onClosingTicketStarted(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);

  if (!ticketId) {
    return state;
  }

  return state;
}
function onClosingTicketErrored(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);

  if (!ticketId) {
    return state;
  }

  return state;
}
function onClosingTicketSucceeded(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);

  if (!ticketId) {
    return state;
  }

  return state.updateIn(['detailedTickets', ticketId, 'value'], (oldValue) => ({
    ...oldValue,
    status: 'Closed',
  }));
}

// Snooze of tickets SNOOZE_TICKET
function onSnoozingTicketStarted(state, { meta }) {
  const ticketId = get(meta, ['id']);

  if (!ticketId) {
    return state;
  }

  return state.setIn(
    ['snoozeStatus', ticketId],
    fromJS({ isLoaded: false, isDirty: false }),
  );
}
function onSnoozingTicketSucceeded(state, { meta }) {
  const ticketId = get(meta, ['id']);

  if (!ticketId) {
    return state;
  }

  return state
    .setIn(
      ['snoozeStatus', ticketId],
      fromJS({ isLoaded: true, isDirty: false, hasErrors: false }),
    )
    .updateIn(['detailedTickets', ticketId, 'value'], (oldValue) => ({
      ...oldValue,
      status: 'Snoozed',
    }));
}
function onSnoozingTicketErrored(state, { meta, errors }) {
  const ticketId = get(meta, ['id']);

  if (!ticketId) {
    return state;
  }

  return state.setIn(
    ['snoozeStatus', ticketId],
    fromJS({ isLoaded: true, isDirty: true, hasErrors: true }).set(
      'errors',
      errors,
    ),
  );
}

// Claims info
function onClaimsInfoLoading(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    return state;
  }

  return state.updateIn(
    ['claimsInfo', orderId],
    fromJS({ isLoaded: false, isDirty: false }),
    (oldValue) => oldValue.setIn(['isDirty'], true),
  );
}
function onClaimsInfoLoadingErrored(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    return state;
  }

  return state.updateIn(['claimsInfo', orderId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onClaimsInfoLoaded(state, { payload, meta }) {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    return state;
  }
  const openFilesInfoByClaim = groupBy(
    flatMap(
      filter(get(payload, ['filesInfo']), (file) => !get(file, 'isClosed')),
      (file) =>
        map(get(file, ['fileProducts']), (product) => ({
          ...file,
          ...product,
        })),
    ),
    'claimId',
  );

  const claimsInfoByOrderLineReference = groupBy(
    get(payload, ['claimsInfo']),
    'orderLineReference',
  );
  const claimsInfoByOwnerProductId = groupBy(
    get(payload, ['claimsInfo']),
    'ownerProductId',
  );

  const claimsInfo = mapValues(
    {
      ...claimsInfoByOrderLineReference,
      ...claimsInfoByOwnerProductId,
    },
    (claims) => {
      const openClaims = filter(
        claims,
        (claim) => get(claim, 'state') !== 'Closed',
      );
      const openFiles = map(
        orderBy(
          flatMap(claims, (claim) =>
            get(openFilesInfoByClaim, get(claim, 'claimId'), []),
          ),
          ['createdDate'],
          ['asc'],
        ),
        (file) => ({
          fileId: get(file, 'fileId'),
          fileReference: get(file, 'fileReference'),
        }),
      );

      return concat(
        map(openFiles, ({ fileId, fileReference }) => ({
          openFile: fileId,
          openFileReference: fileReference,
        })),
        map(openClaims, (openClaim) => ({ openClaim })),
      );
    },
  );

  return state.setIn(
    ['claimsInfo', orderId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], {
      ...claimsInfo,
      openFiles: get(payload, ['filesInfo'], []).filter(
        (file) => !get(file, 'isClosed') && orderId === get(file, 'orderId'),
      ),
      closedFiles: get(payload, ['filesInfo'], []).filter(
        (file) => get(file, 'isClosed') && orderId === get(file, 'orderId'),
      ),
    }),
  );
}

// tickets by order
function onTicketsByOrderLoading(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    return state;
  }

  return state.setIn(
    ['ticketsByOrder', orderId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}
function onTicketsByOrderLoadingErrored(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    return state;
  }

  return state.updateIn(['ticketsByOrder', orderId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onTicketsByOrderLoaded(state, { payload, meta }) {
  const orderId = get(meta, ['orderId']);
  const statusToValue = (status) => {
    switch (status) {
      case 'Closed':
        return 0;
      case 'Snoozed':
        return 1;
      case 'Open':
        return 2;
      default:
        return -1;
    }
  };
  const tickets = mapValues(
    groupBy(get(payload, ['value']), 'orderLineReference'),
    (group) =>
      head(
        orderBy(
          group,
          [({ status }) => statusToValue(status), 'creationDateUtc'],
          ['desc', 'desc'],
        ),
      ),
  );

  if (!orderId) {
    return state;
  }

  return state.setIn(
    ['ticketsByOrder', orderId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], tickets),
  );
}

// marketplace capabilities
function onMarketplaceCapabilitiesBySellerLoading(state, { meta }) {
  const marketplace = get(meta, ['marketplace']);
  const sellerId = get(meta, ['sellerId']);

  if (!marketplace) {
    return state;
  }
  if (!sellerId) {
    return state;
  }

  return state.setIn(
    ['marketplaceCapabilities', marketplace, sellerId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}
function onMarketplaceCapabilitiesBySellerLoadingErrored(state, { meta }) {
  const marketplace = get(meta, ['marketplace']);
  const sellerId = get(meta, ['sellerId']);

  if (!marketplace) {
    return state;
  }
  if (!sellerId) {
    return state;
  }

  return state.updateIn(
    ['marketplaceCapabilities', marketplace, sellerId],
    (oldValue) => oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onMarketplaceCapabilitiesBySellerLoaded(state, { payload, meta }) {
  const marketplace = get(meta, ['marketplace']);
  const sellerId = get(meta, ['sellerId']);
  const capabilities = get(payload, ['value']);

  if (!marketplace) {
    return state;
  }
  if (!sellerId) {
    return state;
  }

  return state.setIn(
    ['marketplaceCapabilities', marketplace, sellerId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], capabilities),
  );
}

// upload attachments
function onTicketAttachmentUploadPending(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);
  const cancellationSignal = get(meta, ['cancellationSignal']);
  const hash = get(meta, ['hash']);
  const fileName = get(meta, ['fileName']);
  const fileSize = get(meta, ['fileSize']);
  const mimeType = get(meta, ['mimeType']);

  if (!ticketId) {
    return state;
  }

  return state.setIn(
    ['ticketUploads', ticketId, hash],
    fromJS({
      isLoaded: false,
      isDirty: false,
      value: {
        fileName,
        mimeType,
        fileSize,
        progress: 0,
      },
      cancellationSignal,
    }),
  );
}
function onTicketAttachmentUploadErrored(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);
  const hash = get(meta, ['hash']);

  if (!ticketId) {
    return state;
  }

  return state.updateIn(['ticketUploads', ticketId, hash], (oldValue) =>
    oldValue
      .setIn(['isDirty'], true)
      .setIn(['hasErrors'], true)
      .removeIn(['cancellationSignal']),
  );
}
function onTicketAttachmentUploadProgress(state, { payload, meta }) {
  const ticketId = get(meta, ['ticketId']);
  const hash = get(meta, ['hash']);

  if (!ticketId) {
    return state;
  }

  return state.setIn(
    ['ticketUploads', ticketId, hash, 'value', 'progress'],
    payload,
  );
}
function onTicketAttachmentUploadSucceeded(state, { meta, payload }) {
  const ticketId = get(meta, ['ticketId']);
  const hash = get(meta, ['hash']);
  const attachmentId = get(payload, 'value');

  if (!ticketId) {
    return state;
  }

  return state.updateIn(['ticketUploads', ticketId, hash], (oldValue) =>
    oldValue
      .setIn(['isLoaded'], true)
      .setIn(['isDirty'], false)
      .setIn(['hasErrors'], false)
      .removeIn(['cancellationSignal'])
      .setIn(['value', 'attachmentId'], attachmentId)
      .setIn(['value', 'progress'], 100),
  );
}
function onTicketAttachmentUploadDeleted(state, { payload, meta }) {
  const ticketId = get(payload, ['ticketId'], get(meta, ['ticketId']));
  const hash = get(payload, ['hash'], get(meta, ['hash']));

  if (!ticketId) {
    return state;
  }
  if (hash) {
    return state.removeIn(['ticketUploads', ticketId, hash]);
  }

  return state.removeIn(['ticketUploads', ticketId]);
}

// Ticket order info
function onTicketOrderInfoLoading(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);

  if (!ticketId) {
    return state;
  }

  return state.updateIn(
    ['detailedTicketOrderInfo', ticketId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
    (oldValue) =>
      oldValue
        .setIn(['isLoaded'], false)
        .setIn(['hasErrors'], false)
        .setIn(['isDirty'], true),
  );
}
function onTicketOrderInfoLoadingErrored(state, { meta }) {
  const ticketId = get(meta, ['ticketId']);

  if (!ticketId) {
    return state;
  }

  return state.updateIn(['detailedTicketOrderInfo', ticketId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function onTicketOrderInfoLoaded(state, { payload, meta }) {
  const ticketId = get(meta, ['ticketId']);
  const ticketOrderInfo = get(payload, ['value']);

  if (!ticketId) {
    return state;
  }

  return state.setIn(
    ['detailedTicketOrderInfo', ticketId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], {
      ...ticketOrderInfo,
      expectedDeliveryDateUtc:
        get(ticketOrderInfo, 'expectedDeliveryDate') ||
        get(ticketOrderInfo, 'expectedDeliveryDateUtc'),
    }),
  );
}

// draft messages
function deleteDraftMessage(state, { payload }) {
  const ticketId = get(payload, ['ticketId']);

  return state.removeIn(['draftMessages', ticketId]);
}
function updateDraftMessage(state, { payload }) {
  const ticketId = get(payload, ['ticketId']);
  const textBody = get(payload, ['textBody']);

  if (textBody) {
    return state.setIn(['draftMessages', ticketId, 'value'], textBody);
  }

  return deleteDraftMessage(state, { payload });
}

// create owner for order
function createOwnerForOrderLoading(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    return state;
  }

  return state.setIn(
    ['createdOwnerIds', orderId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}
function createOwnerForOrderLoadingErrored(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  if (!orderId) {
    return state;
  }

  return state.updateIn(['createdOwnerIds', orderId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}
function createOwnerForOrderLoaded(state, { payload, meta }) {
  const orderId = get(meta, ['orderId']);
  const ownerId = get(payload, ['value']);

  if (!orderId) {
    return state;
  }

  return state.setIn(
    ['createdOwnerIds', orderId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], ownerId),
  );
}

// owner info
const onLoadOwnerProductsLoading = (state, { meta }) => {
  return state.setIn(
    ['ownerProducts', meta],
    fromJS({ isLoaded: false, hasErrors: false, value: undefined }),
  );
};
const onLoadOwnerProductsErrored = (state, { meta }) => {
  return state.updateIn(['ownerProducts', meta], (oldValue) =>
    oldValue.setIn(['hasErrors'], true).setIn(['isLoaded'], true),
  );
};
const onLoadOwnerProductsLoaded = (state, { payload, meta }) => {
  const products = get(payload, 'value');

  return state.setIn(
    ['ownerProducts', meta],
    fromJS({ isLoaded: true, hasErrors: false }).setIn(['value'], products),
  );
};
const onLoadSellerProductsListLoading = (state) => {
  return state.setIn(
    ['sellerProductsList'],
    fromJS({ isLoaded: false, hasErrors: false, value: undefined }),
  );
};
const onLoadSellerProductsListErrored = (state) => {
  return state.updateIn(['sellerProductsList'], (oldValue) =>
    oldValue.setIn(['hasErrors'], true).setIn(['isLoaded'], true),
  );
};
const onLoadSellerProductsListLoaded = (state, { payload }) => {
  const products = get(payload, 'value');

  return state.setIn(
    ['sellerProductsList'],
    fromJS({ isLoaded: true, hasErrors: false }).setIn(['value'], products),
  );
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // filters
    case LOAD_FILTERS.STARTED:
      return onFiltersLoading(state, action);
    case LOAD_FILTERS.SUCCEEDED:
      return onFiltersLoaded(state, action);
    case LOAD_FILTERS.ERRORED:
      return onFiltersLoadingErrored(state, action);

    // marketplace tickets
    case LOAD_MARKETPLACE_TICKETS.STARTED:
      return onMarketplaceTicketsLoading(state, action);
    case LOAD_MARKETPLACE_TICKETS.SUCCEEDED:
      return onMarketplaceTicketsLoaded(state, action);
    case LOAD_MARKETPLACE_TICKETS.ERRORED:
      return onMarketplaceTicketsLoadingErrored(state, action);
    case SHOW_MARKETPLACE_TICKETS_LOADER:
      return onMarketplaceTicketsLoaderShowing(state, action);

    // marketplace detailed tickets
    case LOAD_MARKETPLACE_DETAILED_TICKET.STARTED:
      return onDetailedMarketplaceTicketsLoading(state, action);
    case LOAD_MARKETPLACE_DETAILED_TICKET.SUCCEEDED:
      return onDetailedMarketplaceTicketsLoaded(state, action);
    case LOAD_MARKETPLACE_DETAILED_TICKET.ERRORED:
      return onDetailedMarketplaceTicketsLoadingErrored(state, action);

    // closing ticket
    case CLOSE_MARKETPLACE_TICKET.STARTED:
      return onClosingTicketStarted(state, action);
    case CLOSE_MARKETPLACE_TICKET.SUCCEEDED:
      return onClosingTicketSucceeded(state, action);
    case CLOSE_MARKETPLACE_TICKET.ERRORED:
      return onClosingTicketErrored(state, action);

    // snooze ticket
    case SNOOZE_TICKET.STARTED:
      return onSnoozingTicketStarted(state, action);
    case SNOOZE_TICKET.SUCCEEDED:
      return onSnoozingTicketSucceeded(state, action);
    case SNOOZE_TICKET.ERRORED:
      return onSnoozingTicketErrored(state, action);

    // claims info
    case LOAD_CLAIM_INFO_BY_ORDER.STARTED:
      return onClaimsInfoLoading(state, action);
    case LOAD_CLAIM_INFO_BY_ORDER.SUCCEEDED:
      return onClaimsInfoLoaded(state, action);
    case LOAD_CLAIM_INFO_BY_ORDER.ERRORED:
      return onClaimsInfoLoadingErrored(state, action);

    case LOAD_OWNER_PRODUCTS.STARTED:
      return onLoadOwnerProductsLoading(state, action);
    case LOAD_OWNER_PRODUCTS.SUCCEEDED:
      return onLoadOwnerProductsLoaded(state, action);
    case LOAD_OWNER_PRODUCTS.ERRORED:
      return onLoadOwnerProductsErrored(state, action);
    case LOAD_SELLER_PRODUCTS_INFO.STARTED:
      return onLoadSellerProductsListLoading(state, action);
    case LOAD_SELLER_PRODUCTS_INFO.SUCCEEDED:
      return onLoadSellerProductsListLoaded(state, action);
    case LOAD_SELLER_PRODUCTS_INFO.ERRORED:
      return onLoadSellerProductsListErrored(state, action);

    // tickets by order
    case LOAD_MARKETPLACE_TICKETS_BY_ORDER.STARTED:
      return onTicketsByOrderLoading(state, action);
    case LOAD_MARKETPLACE_TICKETS_BY_ORDER.SUCCEEDED:
      return onTicketsByOrderLoaded(state, action);
    case LOAD_MARKETPLACE_TICKETS_BY_ORDER.ERRORED:
      return onTicketsByOrderLoadingErrored(state, action);

    // marketplace capabilities
    case LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER.STARTED:
      return onMarketplaceCapabilitiesBySellerLoading(state, action);
    case LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER.SUCCEEDED:
      return onMarketplaceCapabilitiesBySellerLoaded(state, action);
    case LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER.ERRORED:
      return onMarketplaceCapabilitiesBySellerLoadingErrored(state, action);

    // tickets uploads
    case UPLOAD_MARKETPLACE_ATTACHMENTS.STARTED:
      return onTicketAttachmentUploadPending(state, action);
    case UPLOAD_MARKETPLACE_ATTACHMENTS.ERRORED:
      return onTicketAttachmentUploadErrored(state, action);
    case UPLOAD_MARKETPLACE_ATTACHMENTS.SUCCEEDED:
      return onTicketAttachmentUploadSucceeded(state, action);
    case UPLOAD_MARKETPLACE_ATTACHMENTS.PROGRESS:
      return onTicketAttachmentUploadProgress(state, action);
    case UPLOAD_MARKETPLACE_ATTACHMENTS.CANCELLED:
      return onTicketAttachmentUploadDeleted(state, action);

    // delete tickets upload
    case DELETE_UPLOADED_MARKETPLACE_ATTACHMENT:
      return onTicketAttachmentUploadDeleted(state, action);

    // Ticket order info
    case REFRESH_TICKET_DETAILS.STARTED:
      return onTicketOrderInfoLoading(state, action);
    case REFRESH_TICKET_DETAILS.SUCCEEDED:
      return onTicketOrderInfoLoaded(state, action);
    case REFRESH_TICKET_DETAILS.ERRORED:
      return onTicketOrderInfoLoadingErrored(state, action);

    // create owner for order
    case CREATE_OWNER_FOR_ORDER.STARTED:
      return createOwnerForOrderLoading(state, action);
    case CREATE_OWNER_FOR_ORDER.SUCCEEDED:
      return createOwnerForOrderLoaded(state, action);
    case CREATE_OWNER_FOR_ORDER.ERRORED:
      return createOwnerForOrderLoadingErrored(state, action);

    // draft messages
    case UPDATE_DRAFT_MESSAGE:
      return updateDraftMessage(state, action);
    case DELETE_DRAFT_MESSAGE:
      return deleteDraftMessage(state, action);

    default:
      return state;
  }
}
