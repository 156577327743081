export const isFileHistoric = (object: any): object is FileHistoric => {
  return !('fileProducts' in object);
};

export interface FileHistoric {
  fileId: string;
  sellerId: string;
  sellerName: string;
  fileReference: string;
  orderId: string;
  orderReference: string;
  lastModificationDate: string;
}

export interface FileHistoricSummary {
  orderLineReference: string[];
  ownerProductId: string;
  modelId: string;
  modelTypeId: string;
  serialNumber: string;
  imei: string;
  rma: string;
  solutionUniqueName: string;
  issueUniqueName: string;
  reasonUniqueName: string;
  warrantyUniqueName: string;
  ownerId: string;
  fileId: string;
  fileReference: string;
  orderReference: string;
  orderId: string;
  tenantId: string;
  sellerId: string;
  sellerName: string;
  createdDate: Date;
  endDate: Date;
  storeActorUniqueName: string;
  actorUniqueName: string;
  fileOutcome: string;
  fileComment: string[];
  customerComment: string;
  documents: {
    documentName: string;
    documentLink: string;
  }[];
  interventionReport: {
    closingCode: string;
    closingType: string;
    serviceUniqueName: string;
    description: string;
    irisCode: {
      conditionCode: string;
      symptomCode: string;
      sectionCode: string;
      defectCode: string;
      repairCode: string;
      extendedConditionCode: string;
    };
  };
  spareParts: {
    reference: string;
    supplier: string;
    name: string;
  }[];
  fileLink: string;
  isClosed: boolean;
  isRefunded: boolean;
}
