import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import { GetUserPayload } from './actionCreators';
import * as types from './actionTypes';
import { selectGetUserIsLoading, selectGetUserWasLoaded } from './selectors';

function* getUserByIdWorker({
  payload,
}: {
  payload: GetUserPayload;
}): Generator {
  const { userId } = payload;

  const isLoading = yield select(selectGetUserIsLoading, {
    userId,
  });
  const wasLoaded = yield select(selectGetUserWasLoaded, {
    userId,
  });

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_USER_BY_ID,
    `${APIConfiguration.authorization}users/${userId}`,
    { method: SUPPORTED_METHODS.GET },
    { indexer: userId },
  );

  yield put(types.GET_USER_BY_ID.end({ userId }));
}
function* getUserByIdWatcher(): Generator {
  yield takeEvery<Action<GetUserPayload>>(
    types.GET_USER_BY_ID.BASE,
    getUserByIdWorker,
  );
}

export default function* userSaga(): Generator {
  try {
    yield all([getUserByIdWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
