import React from 'react';

import Icon from './Icon';

interface SadFaceIconProps {
  color?: string;
  size?: string;
}

const SadFaceIcon: React.FC<SadFaceIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M14.1666 15C13.9456 15 13.7336 14.9122 13.5773 14.7559C13.421 14.5996 13.3333 14.3877 13.3333 14.1667C13.3333 13.9457 13.421 13.7337 13.5773 13.5774C13.7336 13.4211 13.9456 13.3333 14.1666 13.3333C14.3876 13.3333 14.5996 13.4211 14.7558 13.5774C14.9121 13.7337 14.9999 13.9457 14.9999 14.1667C14.9999 14.3877 14.9121 14.5996 14.7558 14.7559C14.5996 14.9122 14.3876 15 14.1666 15ZM25.8333 15C25.6122 15 25.4003 14.9122 25.244 14.7559C25.0877 14.5996 24.9999 14.3877 24.9999 14.1667C24.9999 13.9457 25.0877 13.7337 25.244 13.5774C25.4003 13.4211 25.6122 13.3333 25.8333 13.3333C26.0543 13.3333 26.2662 13.4211 26.4225 13.5774C26.5788 13.7337 26.6666 13.9457 26.6666 14.1667C26.6666 14.3877 26.5788 14.5996 26.4225 14.7559C26.2662 14.9122 26.0543 15 25.8333 15Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 36.6667C29.2049 36.6667 36.6666 29.205 36.6666 20C36.6666 10.795 29.2049 3.33334 19.9999 3.33334C10.7949 3.33334 3.33325 10.795 3.33325 20C3.33325 29.205 10.7949 36.6667 19.9999 36.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 25.8333C12.5 25.8333 15 22.5 20 22.5C25 22.5 27.5 25.8333 27.5 25.8333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

SadFaceIcon.displayName = 'SadFaceIcon';

export default SadFaceIcon;
