import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Invoice,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}invoices/${COMMON_QUERY_PARAMS.KIND}/${COMMON_QUERY_PARAMS.ID}`;

export enum KINK_TYPES {
  FILE = 'File',
}

interface GetOpenedInvoiceQueryArgs {
  kind: KINK_TYPES;
  id: string;
}

export const getInvoicesQuery = async ({
  kind,
  id,
}: GetOpenedInvoiceQueryArgs): Promise<
  BackResponse<Invoice[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.KIND, kind).replace(
      COMMON_QUERY_PARAMS.ID,
      id,
    );

    return await apiCall<BackResponse<Invoice[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
