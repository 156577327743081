import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.actor}actors/summary`;

export interface GetActorSummaryQueryResponseValue {
  id: string;
  actorType: string;
  fullName: string;
  address: {
    addressId: string;
    civility: string;
    firstname: string;
    companyName: string;
    lastname: string;
    address: string;
    additionalAddress: string;
    additionalInformation: string;
    doorCode: string;
    floor: string;
    postalCode: string;
    city: string;
    countryCode: string;
    phone: string;
    isDefault: boolean;
  };
}

interface GetActorSummaryQueryResponse extends BaseBackResponse {
  value: GetActorSummaryQueryResponseValue;
}

interface GetActorSummaryPayload {
  actorId: string;
  actorType?: string;
  sellerId?: string;
  ownerId?: string;
}

async function getActorSummary({
  actorId,
  actorType,
  sellerId,
  ownerId,
}: GetActorSummaryPayload): Promise<GetActorSummaryQueryResponseValue> {
  const functionName = 'getActorSummary';

  if (!actorId) {
    throw new RequiredPayloadFieldIsNotProvided(actorId, `${functionName}`);
  }

  const url = buildUrl(ENDPOINT, { actorId, actorType, sellerId, ownerId });

  const response = await apiCall<GetActorSummaryQueryResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to ${functionName}`);
  }

  return response.value;
}

export default getActorSummary;
