import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.authorization}users/unlink`;

export interface UnlinkUserFromSellerCommandPayload {
  userId: string;
  sellerId: string;
}

export async function unlinkUserFromSellerCommand({
  sellerId,
  userId,
}: UnlinkUserFromSellerCommandPayload): Promise<
  BackResponse<string> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
        userId,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
