import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/additionalInformation`;

export async function setClaimAdditionalInformation({
  claimId,
  additionalClaimInformationValues,
}) {
  if (!claimId) {
    throw new Error(
      'Could not call setClaimAdditionalInformation without a claimId.',
    );
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  const response = await apiCall(url, {
    method: SUPPORTED_METHODS.PUT,
    json: {
      additionalClaimInformation: additionalClaimInformationValues,
    },
  });

  return response.value || [];
}
