import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AdapterConfiguration,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}adapterEndpointConfigurations`;

export default async function setAdapterConfiguration(
  payload: AdapterConfiguration,
): Promise<BackResponse<AdapterConfiguration> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<AdapterConfiguration>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.PUT,
        json: payload,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
