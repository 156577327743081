import { RESULT_TYPE } from '@savgroup-front-common/types';

export const SEARCH_HEIGHT = {
  RESULT: 52,
  TITLE: 32,
  CONTROLS: 32,
  SHOW_MORE_RESULT: 52,
  ERROR: 52,
  NO_RESULT: 260,
};

export const SEARCH_ANIMATION_DURATION = {
  CONTAINER: 200,
  TITLE: 150,
  SELECTION: 100,
  ITEM_POSITION: 250,
  ITEM_FADE: 150,
  INDEX: 40,
  NO_RESULT: 150,
};

export const SEARCH_ANIMATION_DELAY = {
  ITEM_FADE: 100,
  ITEM_MOVING: 100,
};

export const SEARCH_RESULT_ORDER = [
  RESULT_TYPE.ORDER,
  RESULT_TYPE.FILE,
  RESULT_TYPE.SPARE_PART_REQUEST,
  RESULT_TYPE.STOCK_ITEM,
];

export const defaultSearchResultTypePermissions = [
  RESULT_TYPE.ORDER,
  RESULT_TYPE.FILE,
  RESULT_TYPE.SPARE_PART_REQUEST,
  RESULT_TYPE.STOCK_ITEM,
];

export const RESULTS_COUNT_DISPLAYED = 12;
