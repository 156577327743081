import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.businessIntelligence}cumul/dashboards`;

interface DashboardValue {
  id: string;
  name: string;
}

export const getCumulDashboard = async (): Promise<
  BackResponse<DashboardValue[]> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<DashboardValue[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
