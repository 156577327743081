import { Action } from 'redux-actions';
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';
import { StockItemDetails } from '@savgroup-front-common/types';

import {
  getStockItemDetailsSucceeded,
  GetStockItemDetailsSucceededPayload,
} from '../stockItemDetails/actionCreators';
import { selectStockItemDetailsById } from '../stockItemDetails/selectors';

import {
  GetStockItemListPayload,
  SetStockItemListIsDescendingPayload,
  SetStockItemListQueryPayload,
  updateStockItemListItem,
} from './actionCreators';
import * as types from './actionTypes';
import {
  selectStockItemListAppliedFiltersToSend,
  selectStockItemListIsDescending,
  selectStockItemListQuery,
} from './selectors';

const SEARCH_REQUEST_TIMEOUT = 600;

function* getStockItemListWorker({
  payload,
}: {
  payload: GetStockItemListPayload;
}): Generator {
  const {
    page,
    pageSize = 15,
    sort = SORT_TYPES.STOCK_INPUT_TIME_UTC,
  } = payload;

  const query = (yield select(selectStockItemListQuery)) as ReturnType<
    typeof selectStockItemListQuery
  >;

  const isDescending = (yield select(
    selectStockItemListIsDescending,
  )) as ReturnType<typeof selectStockItemListIsDescending>;

  const filters = (yield select(
    selectStockItemListAppliedFiltersToSend,
  )) as ReturnType<typeof selectStockItemListAppliedFiltersToSend>;

  yield call(
    callAndGetResponse,
    types.GET_STOCK_ITEM_LIST,
    `${APIConfiguration.sparePart}search/stockItem`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        sort,
        isDescending,
        page,
        pageSize,
        filters,
        query,
      },
    },
    { indexer: page, page },
  );

  yield put(types.GET_STOCK_ITEM_LIST.end());
}
function* getStockItemListWatcher() {
  yield takeEvery<Action<GetStockItemListPayload>>(
    types.GET_STOCK_ITEM_LIST.BASE,
    getStockItemListWorker,
  );
}

function* setStockItemListQueryWorker({
  payload,
}: {
  payload: SetStockItemListQueryPayload;
}): Generator {
  const { query } = payload;

  yield delay(SEARCH_REQUEST_TIMEOUT);

  yield put(types.SET_STOCK_ITEM_LIST_QUERY.start({ query }));
  yield put(types.GET_STOCK_ITEM_LIST.base({ page: 1 }));

  yield put(types.SET_STOCK_ITEM_LIST_QUERY.end({ query }));
}
function* setStockItemListQueryWatcher() {
  yield takeLatest<Action<SetStockItemListQueryPayload>>(
    types.SET_STOCK_ITEM_LIST_QUERY.BASE,
    setStockItemListQueryWorker,
  );
}

function* setStockItemListIsDescendingWorker({
  payload,
}: {
  payload: SetStockItemListIsDescendingPayload;
}): Generator {
  const { isDescending } = payload;

  yield put(
    types.SET_STOCK_ITEM_LIST_IS_DESCENDING.start({
      isDescending,
    }),
  );
  yield put(types.GET_STOCK_ITEM_LIST.base({ page: 1 }));
  yield put(types.SET_STOCK_ITEM_LIST_IS_DESCENDING.end({ isDescending }));
}
function* setStockItemListIsDescendingWatcher() {
  yield takeLatest<Action<SetStockItemListIsDescendingPayload>>(
    types.SET_STOCK_ITEM_LIST_IS_DESCENDING.BASE,
    setStockItemListIsDescendingWorker,
  );
}
function* setStockItemListFiltersWorker(): Generator {
  yield put(types.SET_STOCK_ITEM_LIST_FILTERS.start());
  yield put(types.GET_STOCK_ITEM_LIST.base({ page: 1 }));
  yield put(types.SET_STOCK_ITEM_LIST_FILTERS.end());
}
function* setStockItemListFiltersWatcher() {
  yield takeLatest<Action<SetStockItemListIsDescendingPayload>>(
    types.SET_STOCK_ITEM_LIST_FILTERS.BASE,
    setStockItemListFiltersWorker,
  );
}
function* resetStockItemListFiltersWatcher() {
  yield takeLatest<Action<SetStockItemListIsDescendingPayload>>(
    types.RESET_STOCK_ITEM_LIST_FILTERS.BASE,
    setStockItemListFiltersWorker,
  );
}

function* refreshStockItemWorker(
  action: Action<GetStockItemDetailsSucceededPayload>,
) {
  const { payload } = action;

  const stockItemDetails = (yield select(selectStockItemDetailsById, {
    stockItemId: payload.value.stockItemId,
  })) as StockItemDetails;

  yield put(
    updateStockItemListItem({
      stockItem: stockItemDetails,
    }),
  );
}
function* refreshStockItemWatcher() {
  yield takeEvery<Action<GetStockItemDetailsSucceededPayload>>(
    getStockItemDetailsSucceeded,
    refreshStockItemWorker,
  );
}

export default function* main(): Generator {
  try {
    yield all([
      getStockItemListWatcher(),
      setStockItemListQueryWatcher(),
      setStockItemListIsDescendingWatcher(),
      setStockItemListFiltersWatcher(),
      resetStockItemListFiltersWatcher(),
      refreshStockItemWatcher(),
    ]);
  } catch (error) {
    logCritical(error as any);
  }
}
