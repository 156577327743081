import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { ICONS_TYPE } from '@savgroup-front-common/core/src/protons/icons/ConfigurationConsole/Icon.types';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { FlexSize } from './getRuleDefinitionQuery';

export enum CONSOLE_ENTITY_DEFINITION {
  CLAIM_ADDITIONAL_INFORMATION = 'ClaimAdditionalInformation',
  BRAND = 'Brand',
  CARRIER = 'Carrier',
  CARRIER_COST = 'CarrierCost',
  CARRIER_PRODUCT = 'CarrierProduct',
  REFERENCE_STATUS = 'ReferenceStatus',
  HUB = 'Hub',
  INSURER = 'Insurer',
  ISSUE = 'Issue',
  MODEL = 'Model',
  MODEL_TYPE = 'ModelType',
  REASON = 'Reason',
  REPAIRER = 'Repairer',
  RMA_SUPPLIERS = 'RmaSuppliers',
  SOLD_WARRANTY = 'SoldWarranty',
  SOLUTION_GROUP = 'SolutionGroup',
  SOLUTION_TYPE = 'SolutionType',
  STORE = 'Store',
  TRANSPORT_METHOD = 'TransportMethod',
  WARRANTY_TYPE = 'WarrantyType',
  ACTOR_TYPE = 'ActorType',
}

export enum ConsoleEntityColumnType {
  TEXTAREA = 'Textarea',
  GUID = 'Guid',
  DATE = 'Date',
  ADRESS_INFO = 'AddressInfo',
  CONTACT_INFO = 'ContactInfo',
  OPENING_HOURS = 'OpeningHours',
  LOCALIZED_URL = 'LocalizedUrl',
  COST_PER_KILO_INTERVAL = 'CostPerKilogramInterval',
  LOCALE = 'Locale',
  EXTENDED_LOCALE = 'ExtendedLocale',
  ENTITY = 'Entity',
  TEXT = 'Text',
  BOOLEAN = 'Boolean',
  DOUBLE = 'Double',
  INTEGER = 'Integer',
  ICON_URL = 'IconUrl',
  LOCALIZED_ENTITY = 'LocalizedEntity',
  LOCALIZED_DIDACTIC = 'LocalizedDidactic',
  BUTTON = 'Button',
  DURATION = 'Duration',
  PROCEDURE_DOCUMENT = 'ProcedureDocument',
  RICH_TEXT = 'EnrichedText',
}

export enum ConsoleUpsertDisplayForm {
  BOTH = 'Both',
  CREATE = 'Create',
  UPDATE = 'Update',
  NONE = 'None',
}

export interface EnrichedValue {
  value: string;
  entityKey: string;
  label?: string;
  localizationKey?: string;
  localizationValues?: Record<string, string>;
  parentsUniqueNames?: string[];
  entityUniqueName?: string;
  tooltip?: string;
  additionalValues: {
    localizationKey?: string;
    value: string;
  }[];
}

export interface EntityColumnDefinitionDto {
  columnName: string;
  columnIcon?: ICONS_TYPE;
  columnType: ConsoleEntityColumnType;
  relatedEntity?: string;
  isMandatory: boolean;
  IsFilterActiveByDefault: boolean;
  /** @deprecated: use isColumnVisible * */
  rowDisplayOrder?: number;
  /** @deprecated: use isColumnMultiValue * */
  isMultiValue: boolean;
  isInput?: boolean;
  localizationKey?: string;
  placeHolder?: string;
  legend?: string;
  /** @deprecated: values is deprecated use enrichedValues instead. */
  values?: string[];
  enrichedValues?: EnrichedValue[];
  dataSource?: string;
  dataSourceName?: string;
  minValue?: number;
  maxValue?: number;
  displayWhen?: {
    column?: string;
    isOneOf?: string[];
  }[];
  minimalCreationStep?: number;
  minimalCreationDisplayOrder?: number;
  upsertDisplayOrder?: number;
  upsertDisplayForms: ConsoleUpsertDisplayForm[];
  canAddOne?: boolean;
  canAddOneInline?: boolean;
  canAddMulti?: boolean;
  isColumnMultiValue?: boolean;
  isFilterActiveByDefault?: boolean;
  isFilterMultiValue?: boolean;
  isTag?: boolean;
  isTitle?: boolean;
  isBold?: boolean;
  isExpandable?: boolean;
  flexSize?: FlexSize;
  isColumnVisible?: boolean;
  isFilterable?: boolean;
  isHierarchical?: boolean;
  isHiddenCard?: boolean;
  defaultValue?: string;
}

export enum CollapseInnerEditor {
  ARRAY = 'Array',
  BPMN = 'Bpmn',
}

export type EntityColumnsDefinitionsDto = {
  hasSearchColumn: boolean;
  isFilterEnabled: boolean;
  isCollapsable?: boolean;
  collapseInnerEditor?: CollapseInnerEditor;
  collapseInnerTypes?: string[];
  columns: Record<string, EntityColumnDefinitionDto>;
  smartGenerationColumns?: Record<string, EntityColumnDefinitionDto>;
};

interface GetEntityDefinitionQueryPayload {
  sellerId: string;
  entityDefinition: string;
}

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/entitydefinition/${COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION}`;

export const getEntityDefinitionQuery = async ({
  sellerId,
  entityDefinition,
}: GetEntityDefinitionQueryPayload): Promise<
  BackResponse<EntityColumnsDefinitionsDto> | BackResponseFailure
> => {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
    COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION,
    entityDefinition,
  );

  try {
    return await apiCall<BackResponse<EntityColumnsDefinitionsDto>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
