import { Action } from 'redux-actions';
import {
  all,
  call,
  CallEffect,
  delay,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import {
  GetSparePartRequestLineListPayload,
  SetSparePartRequestLineListIsDescendingPayload,
  SetSparePartRequestLineListQueryPayload,
} from './actionCreators';
import * as types from './actionTypes';
import {
  selectSparePartRequestLinesAppliedFiltersToSend,
  selectSparePartRequestLinesIsDescending,
  selectSparePartRequestLinesQuery,
} from './selectors';

const SEARCH_REQUEST_TIMEOUT = 600;

function* getSparePartRequestLinesWorker({
  payload,
}: {
  payload: GetSparePartRequestLineListPayload;
}): Generator<
  | CallEffect
  | SelectEffect
  | PutEffect<{
      type: string;
    }>
> {
  const {
    page,
    pageSize = 15,
    sort = SORT_TYPES.REQUEST_LINE_LAST_MODIFICATION_TIME_UTC,
  } = payload;

  const isDescending = (yield select(
    selectSparePartRequestLinesIsDescending,
  )) as ReturnType<typeof selectSparePartRequestLinesIsDescending>;

  const query = (yield select(selectSparePartRequestLinesQuery)) as ReturnType<
    typeof selectSparePartRequestLinesQuery
  >;
  const filters = (yield select(
    selectSparePartRequestLinesAppliedFiltersToSend,
  )) as ReturnType<typeof selectSparePartRequestLinesAppliedFiltersToSend>;

  yield call(
    callAndGetResponse,
    types.GET_SPARE_PART_REQUEST_LINE_LIST,
    `${APIConfiguration.sparePart}search/requestline`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        sort,
        isDescending,
        page,
        pageSize,
        filters,
        query,
      },
    },

    { indexer: page, page },
  );

  yield put(types.GET_SPARE_PART_REQUEST_LINE_LIST.end());
}
function* getSparePartRequestLinesWatcher() {
  yield takeEvery<Action<GetSparePartRequestLineListPayload>>(
    types.GET_SPARE_PART_REQUEST_LINE_LIST.BASE,
    getSparePartRequestLinesWorker,
  );
}

function* setSparePartRequestLinesQueryWorker({
  payload,
}: {
  payload: SetSparePartRequestLineListQueryPayload;
}): Generator<
  | CallEffect
  | PutEffect<{
      type: string;
    }>
> {
  const { query } = payload;

  yield delay(SEARCH_REQUEST_TIMEOUT);

  yield put(types.SET_SPARE_PART_REQUEST_LINE_LIST_QUERY.start({ query }));
  yield put(types.GET_SPARE_PART_REQUEST_LINE_LIST.base({ page: 1 }));

  yield put(types.SET_SPARE_PART_REQUEST_LINE_LIST_QUERY.end({ query }));
}
function* setSparePartRequestLinesQueryWatcher() {
  yield takeLatest<Action<SetSparePartRequestLineListQueryPayload>>(
    types.SET_SPARE_PART_REQUEST_LINE_LIST_QUERY.BASE,
    setSparePartRequestLinesQueryWorker,
  );
}

function* setSparePartRequestLinesIsDescendingWorker({
  payload,
}: {
  payload: SetSparePartRequestLineListIsDescendingPayload;
}): Generator<
  | CallEffect
  | PutEffect<{
      type: string;
    }>
> {
  const { isDescending } = payload;

  yield put(
    types.SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING.start({
      isDescending,
    }),
  );
  yield put(types.GET_SPARE_PART_REQUEST_LINE_LIST.base({ page: 1 }));
  yield put(
    types.SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING.end({ isDescending }),
  );
}
function* setSparePartRequestLinesIsDescendingWatcher() {
  yield takeLatest<Action<SetSparePartRequestLineListIsDescendingPayload>>(
    types.SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING.BASE,
    setSparePartRequestLinesIsDescendingWorker,
  );
}
function* setSparePartRequestLinesFiltersWorker(): Generator<
  | CallEffect
  | PutEffect<{
      type: string;
    }>
> {
  yield put(types.SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.start());
  yield put(types.GET_SPARE_PART_REQUEST_LINE_LIST.base({ page: 1 }));
  yield put(types.SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.end());
}
function* setSparePartRequestLinesFiltersWatcher() {
  yield takeLatest<Action<SetSparePartRequestLineListIsDescendingPayload>>(
    types.SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.BASE,
    setSparePartRequestLinesFiltersWorker,
  );
}
function* resetSparePartRequestLinesFiltersWatcher() {
  yield takeLatest<Action<SetSparePartRequestLineListIsDescendingPayload>>(
    types.RESET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.BASE,
    setSparePartRequestLinesFiltersWorker,
  );
}

export default function* sparePartRequestLineSaga(): Generator {
  try {
    yield all([
      getSparePartRequestLinesWatcher(),
      setSparePartRequestLinesQueryWatcher(),
      setSparePartRequestLinesIsDescendingWatcher(),
      setSparePartRequestLinesFiltersWatcher(),
      resetSparePartRequestLinesFiltersWatcher(),
    ]);
  } catch (error) {
    logCritical(error as any);
  }
}
