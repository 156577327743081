import React, { FunctionComponent } from 'react';

import { $SkeletonButtonStyled } from './Skeleton.styles';

const SkeletonButton: FunctionComponent<{ highContrast: boolean }> = ({
  highContrast = false,
}) => {
  return (
    <$SkeletonButtonStyled
      $highContrast={highContrast}
      $isInline={false}
      $width="100%"
      $height="1rem"
      $marginBottom="0"
      $isCircle={false}
    />
  );
};

SkeletonButton.displayName = 'SkeletonButton';

export default SkeletonButton;
