import {
  APP_COMPONENT_STATUSES,
  APP_STATUS_IMPACT,
  AppIncident,
  AppScheduledMaintenance,
  BackResponse,
  BackResponseFailure,
  INCIDENT_STATUSES,
  SCHEDULED_MAINTENANCE_STATUSES,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';

import {
  adaptRawAppIncidentToAppIncident,
  adaptRawScheduledMaintenancesToScheduledMaintenances,
} from './adapters';
import STATUS_PAGE_URL from './config';

const ENDPOINT = `${STATUS_PAGE_URL}/api/v2/summary.json`;

interface RawAppSummaryResponse {
  page: {
    id: string;
    name: string;
    url: string;
    updated_at: string;
  };
  incidents: {
    components: {
      created_at: string;
      description: string | null;
      group: boolean;
      group_id: string | null;
      id: string;
      name: string;
      only_show_if_degraded: boolean;
      page_id: string;
      position: number;
      showcase: boolean;
      start_date: string;
      status: APP_COMPONENT_STATUSES;
      updated_at: string;
    }[];
    created_at: string;
    id: string;
    impact: APP_STATUS_IMPACT;
    incident_updates: {
      body: string;
      created_at: string;
      display_at: string;
      id: string;
      incident_id: string;
      status: INCIDENT_STATUSES;
      updated_at: string;
    }[];
    monitoring_at: null;
    name: string;
    page_id: string;
    resolved_at: null;
    shortlink: string;
    status: INCIDENT_STATUSES;
    updated_at: string;
  }[];
  scheduled_maintenances: {
    components: {
      created_at: string;
      description: string | null;
      group: boolean;
      group_id: string | null;
      id: string;
      name: string;
      only_show_if_degraded: boolean;
      page_id: string;
      position: number;
      showcase: boolean;
      start_date: string;
      status: APP_COMPONENT_STATUSES;
      updated_at: string;
    }[];
    created_at: string;
    id: string;
    impact: APP_STATUS_IMPACT;
    incident_updates: {
      body: string;
      created_at: string;
      display_at: string;
      id: string;
      incident_id: string;
      status: SCHEDULED_MAINTENANCE_STATUSES;
      updated_at: string;
    }[];
    scheduled_for: string;
    scheduled_until: string;
    monitoring_at: null;
    name: string;
    page_id: string;
    resolved_at: null;
    shortlink: string;
    status: SCHEDULED_MAINTENANCE_STATUSES;
    updated_at: string;
  }[];
}

const getAllAppStatuses = async (): Promise<
  | BackResponse<{
      incidents: AppIncident[];
      scheduledMaintenances: AppScheduledMaintenance[];
    }>
  | BackResponseFailure
> => {
  try {
    const response = await fetch(ENDPOINT);

    const appSummaryResponse = (await response.json()) as RawAppSummaryResponse;

    const returnValue: BackResponse<{
      incidents: AppIncident[];
      scheduledMaintenances: AppScheduledMaintenance[];
    }> = {
      failure: false,
      isHandledError: false,
      hasWarnings: false,
      isNotFound: false,
      errors: [],
      value: {
        incidents: adaptRawAppIncidentToAppIncident(appSummaryResponse),
        scheduledMaintenances:
          adaptRawScheduledMaintenancesToScheduledMaintenances(
            appSummaryResponse,
          ),
      },
    };

    return returnValue;
  } catch (e) {
    return prepareResponseFailure(e);
  }
};

export default getAllAppStatuses;
