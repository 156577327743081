import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { StockInventorySummaryDto } from './type/StockInventorySummaryDto';

const ENDPOINT = `${APIConfiguration.sparePart}stockInventory/bySeller/${COMMON_QUERY_PARAMS.SELLER_ID}`;

export interface GetStockInventoryBySellerIdArgs {
  sellerId: string;
}

export default async function getStockInventoryBySellerId(
  payload: GetStockInventoryBySellerIdArgs,
): Promise<BackResponse<StockInventorySummaryDto[]> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<StockInventorySummaryDto[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, payload.sellerId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
