import { LANGUAGES } from '@savgroup-front-common/constants';
import { OwnerSummary } from '@savgroup-front-common/types';

export const adaptGetOwnerByIdQueryResponse = (response: OwnerSummary) => {
  if (!response) {
    return response;
  }

  const {
    civility,
    firstname,
    companyName,
    lastname,
    address,
    additionalAddress,
    postalCode,
    city,
    countryCode,
    phone,
  } = response.ownerAddress;

  return {
    ...response,
    notificationLanguage: response.preference.language as LANGUAGES,
    ownerAddress: {
      civility,
      firstName: firstname,
      companyName,
      lastName: lastname,
      address,
      addressLine2: additionalAddress,
      postalCode,
      city,
      country: countryCode,
      countryCode,
      phone,
    },
  };
};
