import { SearchHighlight } from '@savgroup-front-common/types';

import adaptToBoldHighlight from './adaptToBoldHighlight';
import adaptToRegularHighlight from './adaptToRegularHighlight';

export const splitHighlight = (highlight: string): SearchHighlight[] => {
  const regex = new RegExp(`<em\\b[^>]*>(.*?)</em>`, 'gi');

  const matched = highlight.match(regex) || [];

  const { returnValue } = highlight
    .split(regex)
    .filter((value) => value.length > 0)
    .reduce<{
      returnValue: SearchHighlight[];
      matches: string[];
    }>(
      (acc, value) => {
        const index = acc.matches.findIndex((match) => match.includes(value));

        const hasMatched = index >= 0;

        return {
          matches: hasMatched ? acc.matches.slice(index + 1) : acc.matches,
          returnValue: [
            ...acc.returnValue,
            hasMatched
              ? adaptToBoldHighlight(
                  value.replace('<em>', '').replace('</em>', ''),
                )
              : adaptToRegularHighlight(
                  value.replace('<em>', '').replace('</em>', ''),
                ),
          ],
        };
      },
      {
        returnValue: [],
        matches: matched,
      },
    );

  return returnValue.filter(({ value }) => value && value.length);
};

const adaptHighlights = (highlights: string[] | undefined = []) =>
  highlights.reduce<SearchHighlight[]>(
    (acc, highlight) => [...acc, ...splitHighlight(highlight)],
    [],
  );

export default adaptHighlights;
