import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $ShortcutKeyGroup = styled.div`
  height: ${rem(25)};
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
