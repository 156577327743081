import get from 'lodash/get';

import * as ActionTypes from './actionTypes';
import { LOAD_OWNER_INFO } from './actionTypes';

export const initialState = Object.freeze({
  files: {
    isLoaded: false,
    items: [],
  },
  products: {
    isLoaded: false,
    items: [],
  },
  owners: {},
});

function onOwnerLoading(state, { meta }) {
  const ownerId = get(meta, ['ownerId']);

  if (!ownerId) {
    return state;
  }

  return {
    ...state,
    owners: {
      ...state.owners,
      [ownerId]: {
        ...(state.owners[ownerId] || {
          isLoaded: false,
          isDirty: false,
          value: undefined,
        }),
        hasErrors: false,
      },
    },
  };
}
function onOwnerLoadingErrored(state, { meta }) {
  const ownerId = get(meta, ['ownerId']);

  if (!ownerId) {
    return state;
  }

  return {
    ...state,
    owners: {
      ...state.owners,
      [ownerId]: {
        ...state.owners[ownerId],
        hasErrors: true,
        isDirty: true,
      },
    },
  };
}
function onOwnerLoaded(state, { payload, meta }) {
  const ownerId = get(meta, ['ownerId']);
  const owner = get(payload, ['value']);

  if (!ownerId) {
    return state;
  }

  return {
    ...state,
    owners: {
      ...state.owners,
      [ownerId]: {
        ...state.owners[ownerId],
        isLoaded: true,
        isDirty: true,
        value: owner,
      },
    },
  };
}

function onOwnerProductsLoadStart(state) {
  return {
    ...state,
    products: initialState.products,
  };
}

function onOwnerProductsLoadSuccess(state, payload) {
  return {
    ...state,
    products: {
      isLoaded: true,
      items: payload.value,
    },
  };
}

function onSellerProductInfoSuccess(state, payload) {
  const products = payload.value;

  const newProducts = state.products.items.map((product) => {
    const productInfo = products.find(
      (info) => info.id === product.sellerProductId,
    );

    return {
      ...product,
      ...productInfo,
    };
  });

  return {
    ...state,
    products: {
      isLoaded: true,
      items: newProducts,
    },
  };
}

export default function ownerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_OWNER_INFO.STARTED:
      return onOwnerLoading(state, action);
    case LOAD_OWNER_INFO.SUCCEEDED:
      return onOwnerLoaded(state, action);
    case LOAD_OWNER_INFO.ERRORED:
      return onOwnerLoadingErrored(state, action);

    case ActionTypes.LOAD_OWNER_PRODUCTS.STARTED:
      return onOwnerProductsLoadStart(state, action.payload);

    case ActionTypes.LOAD_OWNER_PRODUCTS.SUCCEEDED:
      return onOwnerProductsLoadSuccess(state, action.payload);

    case ActionTypes.LOAD_SELLER_PRODUCTS_INFO.SUCCEEDED:
      return onSellerProductInfoSuccess(state, action.payload);

    default:
      return state;
  }
}
