import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OrderSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/orders`;

interface ListOrdersQueryPayload {
  ownerId: string;
}

export interface RawOrderSummary extends Omit<OrderSummary, 'purchaseDate'> {
  purchaseDate: string;
}

type GetOpenedFilesQueryResponse = RawOrderSummary[];

type ListOrdersQuery = (
  payload: ListOrdersQueryPayload,
) => Promise<BackResponse<GetOpenedFilesQueryResponse> | BackResponseFailure>;

const listOrdersQuery: ListOrdersQuery = async ({ ownerId }) => {
  try {
    return await apiCall<BackResponse<GetOpenedFilesQueryResponse>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default listOrdersQuery;
