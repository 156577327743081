import { createAction } from '@reduxjs/toolkit';

import {
  CLOSE_MARKETPLACE_TICKET,
  DELETE_DRAFT_MESSAGE,
  DELETE_UPLOADED_MARKETPLACE_ATTACHMENT,
  GENERATE_OWNER_AND_BACKOFFICE_LINK,
  LOAD_CLAIM_INFO_BY_ORDER,
  LOAD_FILTERS,
  LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER,
  LOAD_MARKETPLACE_DETAILED_TICKET,
  LOAD_MARKETPLACE_TICKETS,
  LOAD_MARKETPLACE_TICKETS_BY_ORDER,
  LOAD_OWNER_PRODUCTS,
  LOAD_SELLER_PRODUCTS_INFO,
  REFRESH_TICKET_DETAILS,
  SEND_MESSAGE,
  SHOW_MARKETPLACE_TICKETS_LOADER,
  SNOOZE_TICKET,
  TRACK_USER_ACTIVITY_ON_TICKET,
  UPDATE_DRAFT_MESSAGE,
  UPLOAD_MARKETPLACE_ATTACHMENTS,
} from './actionTypes';

export const loadFilters = createAction(LOAD_FILTERS.BASE);
export const loadMarketplaceTickets = createAction(
  LOAD_MARKETPLACE_TICKETS.BASE,
);
export const showMarketplaceTicketsLoader = createAction(
  SHOW_MARKETPLACE_TICKETS_LOADER,
);
export const loadMarketplaceDetailedTicket = createAction(
  LOAD_MARKETPLACE_DETAILED_TICKET.BASE,
);

export const closeMarketplaceTicket = createAction(
  CLOSE_MARKETPLACE_TICKET.BASE,
);
export const loadClaimInfoByOrder = createAction<{
  orderId: string;
  ownerId: string;
}>(LOAD_CLAIM_INFO_BY_ORDER.BASE);

export const loadOwnerProducts = createAction(LOAD_OWNER_PRODUCTS.BASE);
export const loadSellerProductsInfo = createAction(
  LOAD_SELLER_PRODUCTS_INFO.BASE,
);

export const sendMessage = createAction(SEND_MESSAGE.BASE);

export const loadMarketplaceTicketsByOrder = createAction(
  LOAD_MARKETPLACE_TICKETS_BY_ORDER.BASE,
);
export const loadMarketplaceCapabilitiesBySeller = createAction(
  LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER.BASE,
);

export const snoozeTicket = createAction(SNOOZE_TICKET.BASE);

export const uploadMarketplaceAttachments = createAction(
  UPLOAD_MARKETPLACE_ATTACHMENTS.BASE,
);
export const deleteUploadedMarketplaceAttachment = createAction(
  DELETE_UPLOADED_MARKETPLACE_ATTACHMENT,
);
export const trackUserActivityOnTicket = createAction(
  TRACK_USER_ACTIVITY_ON_TICKET.BASE,
);

export const refreshTicketDetails = createAction(REFRESH_TICKET_DETAILS.BASE);
export const generateOwnerAndBackofficeLink = createAction<{
  orderId: string;
  ownerId: string;
  sellerId: string;
  tokenOrigin: string;
  redirectUrl: string;
}>(GENERATE_OWNER_AND_BACKOFFICE_LINK.BASE);

export const updateDraftMessage = createAction(UPDATE_DRAFT_MESSAGE);
export const deleteDraftMessage = createAction(DELETE_DRAFT_MESSAGE);
