import { createSelector } from 'reselect';

import { Selectors as requestSelectors } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata';

import { ControlRootState } from '../ControlRootState';

import * as actionTypes from './actionTypes';

export const accessState = (state: ControlRootState) => state.access;

export const selectLoadBackOfficeLinkErrors = (
  state: ControlRootState,
  { orderId }: { orderId: string },
) =>
  requestSelectors.getActionErrorsSelector(
    state,
    actionTypes.LOAD_BACK_OFFICE_LINK,
    orderId,
  );

const backOfficeLink = createSelector(
  [accessState],
  (access) => access.backOfficeLink,
);

export const backOfficeLinkValue = createSelector(
  [backOfficeLink],
  (backOfficeLinkState) => backOfficeLinkState?.value || undefined,
);

export const currentOrderBackOfficeLinkIsLoadingSelector = createSelector(
  [backOfficeLink],
  (backOfficeLinkState) => {
    if (backOfficeLinkState) {
      return (
        !(backOfficeLinkState.isLoaded || false) &&
        (backOfficeLinkState.isDirty || false)
      );
    }

    return false;
  },
);
