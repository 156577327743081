import React from 'react';

import Icon from './Icon';

interface ConsumableIconProps {
  color?: string;
  size?: string;
}

const ConsumableIcon: React.FC<ConsumableIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M36.6716 5V12.495C36.6716 12.7173 36.5833 12.9306 36.4261 13.0878C36.2689 13.245 36.0556 13.3333 35.8333 13.3333C35.6699 13.3317 35.5102 13.2839 35.3728 13.1954C35.2354 13.107 35.1258 12.9814 35.0566 12.8333C33.702 9.98922 31.5691 7.58725 28.9051 5.90585C26.2412 4.22445 23.1552 3.33248 20.005 3.33333C11.3633 3.33333 4.25663 9.91167 3.42163 18.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0001 18.3333V13.3333M28.3334 16.6667V25C28.3334 25.8841 27.9822 26.7319 27.3571 27.357C26.732 27.9821 25.8841 28.3333 25.0001 28.3333H15.0001C14.116 28.3333 13.2682 27.9821 12.6431 27.357C12.0179 26.7319 11.6667 25.8841 11.6667 25V16.6667C11.6667 15.7826 12.0179 14.9348 12.6431 14.3096C13.2682 13.6845 14.116 13.3333 15.0001 13.3333H25.0001C25.8841 13.3333 26.732 13.6845 27.3571 14.3096C27.9822 14.9348 28.3334 15.7826 28.3334 16.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.41675 35V27.505C3.41675 27.0417 3.79341 26.6667 4.25675 26.6667C4.59008 26.6667 4.89008 26.865 5.03341 27.1667C6.38805 30.0104 8.52074 32.4121 11.1844 34.0934C13.848 35.7748 16.9335 36.667 20.0834 36.6667C28.7267 36.6667 35.8334 30.0883 36.6684 21.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

ConsumableIcon.displayName = 'ConsumableIcon';

export default ConsumableIcon;
