import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';

import { getResultListSize } from '../../../helpers';
import { useSearchContext } from '../../../hooks';
import { SEARCH_ANIMATION_DURATION } from '../../../Search.contants';
import { RESULT_DISPLAY_TYPE } from '../../../Search.types';
import {
  SearchItemControls,
  SearchItemError,
  SearchItemResult,
  SearchItemShowMoreResult,
  SearchItemTitle,
} from '../../SearchItems';

import { $ResultItem, $ResultList } from './ResultList.styles';

const ResultList: FunctionComponent = () => {
  const { focusedIndex, results } = useSearchContext();

  return (
    <$ResultList component="ul">
      {results.map((result, index) => (
        <Transition key={result.key} timeout={500}>
          {(animationState) => (
            <$ResultItem
              data-testid={`header-search-result-item-${result.key}-isfocused:${
                index === focusedIndex
              }`}
              animationState={animationState}
              animationDuration={SEARCH_ANIMATION_DURATION.ITEM_POSITION}
              $position={getResultListSize(results, index)}
            >
              {result.displayType === RESULT_DISPLAY_TYPE.RESULT && (
                <SearchItemResult
                  isFocus={index === focusedIndex}
                  animationState={animationState}
                  animationIndex={result.animationIndex}
                  url={result.value.url}
                  reference={result.value.reference}
                  name={result.value.name}
                  status={result.value.status}
                  others={result.value.other}
                  isNew={result.isNew}
                  type={result.resultType}
                />
              )}
              {result.displayType === RESULT_DISPLAY_TYPE.CONTROLS && (
                <SearchItemControls
                  animationState={animationState}
                  animationIndex={result.animationIndex}
                />
              )}
              {result.displayType === RESULT_DISPLAY_TYPE.ERROR && (
                <SearchItemError
                  animationState={animationState}
                  animationIndex={result.animationIndex}
                  type={result.resultType}
                  errors={result.value.errors}
                />
              )}
              {result.displayType === RESULT_DISPLAY_TYPE.TITLE && (
                <SearchItemTitle
                  animationState={animationState}
                  animationIndex={result.animationIndex}
                  count={result.value.count}
                  type={result.resultType}
                />
              )}
              {result.displayType === RESULT_DISPLAY_TYPE.SHOW_MORE && (
                <SearchItemShowMoreResult
                  isFocus={index === focusedIndex}
                  animationState={animationState}
                  animationIndex={result.animationIndex}
                  count={result.value.count}
                  type={result.resultType}
                />
              )}
            </$ResultItem>
          )}
        </Transition>
      ))}
    </$ResultList>
  );
};

ResultList.displayName = 'ResultList';

export default ResultList;
