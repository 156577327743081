import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { CIVILITY } from '@savgroup-front-common/constants/src/shared/civility';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  CUSTOMER_TYPE,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}tenants/${COMMON_QUERY_PARAMS.TENANT_ID}/owners`;

interface OwnerAddressInfo {
  addressId?: string;
  civility: CIVILITY;
  firstname: string;
  companyName: string;
  lastname: string;
  customerType?: CUSTOMER_TYPE;
  address: string;
  additionalAddress: string;
  additionalInformation?: string;
  doorCode?: string;
  floor?: string;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
  isDefault?: boolean;
  isValid?: boolean;
}

interface OwnerDetails {
  ownerId: string;
  externalId?: string;
  email: string;
  preferredLanguage: string;
  firstname: string;
  lastname: string;
  phone: string;
  addressInfo: OwnerAddressInfo;
}

interface UpsertOwnerPayload {
  owner: OwnerDetails;
  tenantId: string;
  sellerId?: string;
}

async function upsertOwner({
  owner,
  tenantId,
  sellerId,
}: UpsertOwnerPayload): Promise<BackResponse<string> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.TENANT_ID, tenantId);

    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: { ...owner, sellerId },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default upsertOwner;
