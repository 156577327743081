import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import { EVENT_CODE } from '@savgroup-front-common/constants';
import { ShortcutKeyboard } from '@savgroup-front-common/core/src/molecules/ShortcutKeyboard';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { SEARCH_HEIGHT } from '../../../Search.contants';
import SearchItemContainer from '../../common/SearchItemContainer';

import messages from './messages';
import {
  $ControlText,
  $ResultControl,
  $ShowDesktop,
  $ShowOnAll,
  $ShowTabletAndMore,
} from './SearchItemControls.styles';

interface SearchItemControlsProps {
  animationIndex: number;
  animationState: TransitionStatus;
}

const SearchItemControls: FunctionComponent<
  React.PropsWithChildren<SearchItemControlsProps>
> = ({ animationIndex, animationState }) => {
  return (
    <SearchItemContainer
      animationState={animationState}
      animationIndex={animationIndex}
      height={SEARCH_HEIGHT.CONTROLS}
    >
      <$ResultControl data-testid="search-result-control">
        <$ShowOnAll>
          <ShortcutKeyboard code={EVENT_CODE.ARROW_DOWN} />
          <ShortcutKeyboard code={EVENT_CODE.ARROW_UP} />
          <ShortcutKeyboard code={EVENT_CODE.ENTER} />

          <$ControlText>
            <SafeFormattedMessageWithoutSpread
              message={messages.navigationLabel}
            />
          </$ControlText>
        </$ShowOnAll>
        <$ShowTabletAndMore>
          <ShortcutKeyboard code={EVENT_CODE.ESCAPE} />
          <$ControlText>
            <SafeFormattedMessageWithoutSpread message={messages.escapLabel} />
          </$ControlText>
        </$ShowTabletAndMore>
        <$ShowDesktop>
          <ShortcutKeyboard code={EVENT_CODE.CONTROL_LEFT} /> +
          <ShortcutKeyboard code={EVENT_CODE.SPACE} />
          <$ControlText>
            <SafeFormattedMessageWithoutSpread
              message={messages.shortcutLabel}
            />
          </$ControlText>
        </$ShowDesktop>
      </$ResultControl>
    </SearchItemContainer>
  );
};

SearchItemControls.displayName = 'SearchItemControls';

export default SearchItemControls;
