import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { StockItemGroupInInventorySummaryDto } from './type/StockItemGroupInInventorySummaryDto';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.sparePart}stockInventory/StockInInventory/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}/StockItemGroupsInInventory`;

export interface GetStockItemGroupInInventoryByStockInInventoryQueryArgs {
  stockInInventoryId: string;
}

export default async function getStockItemGroupInInventoryByStockInInventoryQuery(
  payload: GetStockItemGroupInInventoryByStockInInventoryQueryArgs,
): Promise<
  BackResponse<StockItemGroupInInventorySummaryDto[]> | BackResponseFailure
> {
  try {
    const response = await apiCall<
      BackResponse<StockItemGroupInInventorySummaryDto[]>
    >(
      ENDPOINT.replace(
        COMMON_QUERY_PARAMS.STOCK_ITEM_ID,
        payload.stockInInventoryId,
      ),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
