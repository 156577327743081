import { Column } from 'react-table';

import { ShortcutCell } from './Components/Cells';
import messages from './messages';
import { ShortcutKey } from './ShortcutsContent.types';

const SHORTCUTS_COLUMNS: Column<ShortcutKey>[] = [
  {
    Header: messages.tableHeaderLabel as any,
    accessor: 'description',
    getProps: () => ({
      style: {
        'font-weight': 'bold',
        width: '40%',
      },
    }),
  },
  {
    Header: messages.tableHeaderShortcut as any,
    accessor: 'fragments',
    Cell: ShortcutCell,
    getProps: () => ({
      style: {
        width: '25%',
      },
    }),
  },
  {
    Header: messages.tableHeaderCondition as any,
    accessor: 'condition',
    getProps: () => ({
      style: {
        width: '35%',
      },
    }),
  },
];

export default SHORTCUTS_COLUMNS;
