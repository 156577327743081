import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import uniqBy from 'lodash/uniqBy';

import {
  AdditionalInformationDto,
  ProductHistory,
} from '@savgroup-front-common/types';

export const groupProductsBy =
  <
    T extends {
      productHistory?: ProductHistory[];
      ownerProductId?: string;
      neededInformation: AdditionalInformationDto[];
    },
  >({
    fieldName,
  }: {
    fieldName: string;
  }) =>
  (products: T[]) => {
    const groupedProductsBySellerProductId = groupBy(products, fieldName);

    return Object.values(groupedProductsBySellerProductId).map(
      (groupedProducts = []) => {
        const productHistory = groupedProducts.map(
          (groupedProduct) => groupedProduct?.productHistory || [],
        );
        const flattenProductHistory = productHistory.flat();

        return {
          ...head(groupedProducts),
          ownerProductId: groupedProducts.map(
            ({ ownerProductId }) => ownerProductId,
          ),
          ownerProductIds: groupedProducts.map(
            ({ ownerProductId }) => ownerProductId,
          ),
          neededInformation: groupedProducts.reduce<AdditionalInformationDto[]>(
            (acc, groupedProduct) => {
              return [...acc, ...(groupedProduct.neededInformation || [])];
            },
            [],
          ),
          quantity: groupedProducts.length,
          productHistory: uniqBy(flattenProductHistory, 'fileId'),
        };
      },
    );
  };
