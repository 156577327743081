import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  HANDLING_MODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/carriers`;

export interface CarrierChoice {
  carrierId: string;
  carrierCustomerPriceId: string;
  carrierSellerPriceId: string;
  carrierProductId: string;
  handlingMode: 'Deposit' | 'Delivery' | 'Home';
  carrierName: string;
  price: number;
  priceWithTax: number;
  currencyCode: string;
  productType:
    | 'Pickup'
    | 'PickupPostOffice'
    | 'PickupCounter'
    | 'HomeDelivery'
    | 'DropAtStore'
    | 'SellerProvidedCarrier'
    | 'ImmediateDropAtStore'
    | 'PickUpStoreDelivery';
  transportReach:
    | 'Unknown'
    | 'Home'
    | 'PostOfficeCounter'
    | 'PrivateCarrierCounter';
}

async function getClaimGroupCarrier({
  claimGroupId,
  mode,
}: {
  claimGroupId: string;
  mode: HANDLING_MODES;
}): Promise<BackResponse<CarrierChoice[]> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<BackResponse<CarrierChoice[]>>(
      buildUrl(url, { mode }),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getClaimGroupCarrier;
