import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ProductOrientation,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}productOrientations`;

interface GetProductOrientationsQueryPayload {
  sellerId: string;
  adequacy?: string;
  orderLineReference?: string;
  fileId?: string;
}

const getProductOrientationsQuery = async ({
  sellerId,
  adequacy,
  orderLineReference,
  fileId,
}: GetProductOrientationsQueryPayload): Promise<
  BackResponse<ProductOrientation[]> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<ProductOrientation[]>>(
      buildUrl(ENDPOINT, { sellerId, adequacy, orderLineReference, fileId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getProductOrientationsQuery;
