import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

export const URL = `${APIConfiguration.owner}owners/forOrder/${COMMON_QUERY_PARAMS.ORDER_ID}`;

export async function createOwnerForOrderCommand({
  orderId,
}: {
  orderId: string;
}): Promise<BackResponse<string> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<string>>(
      URL.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId),
      {
        method: SUPPORTED_METHODS.POST,
        json: {},
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
