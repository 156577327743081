import { useMemo } from 'react';

import {
  CREATE_MODAL_TYPES,
  SHORTCUT_LIST_CONTROL,
} from '@savgroup-front-common/constants';
import { useAuthorizedCreateModalTypes } from '@savgroup-front-common/core/src/hooks';

import messages from '../messages';
import { ShortcutKey } from '../ShortcutsContent.types';

const bind = [
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_1,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_2,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_3,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_4,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_5,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_6,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_7,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_8,
  SHORTCUT_LIST_CONTROL.CREATE_MODAL_9,
];

const useModalShortcuts = () => {
  const authorizations = useAuthorizedCreateModalTypes();

  return useMemo<ShortcutKey[]>(() => {
    const initial: ShortcutKey[] = [
      {
        fragments: SHORTCUT_LIST_CONTROL.SHORTCUT_MODAL,
        description: messages.openShortcutModal,
      },
    ];

    return authorizations.reduce<ShortcutKey[]>((acc, authorization, index) => {
      const fragments = bind[index];

      const getShortcut = () => {
        switch (authorization) {
          case CREATE_MODAL_TYPES.FILE_ORDER_REFERENCE: {
            return {
              fragments,
              description: messages.createFileByOrderReference,
            };
          }
          case CREATE_MODAL_TYPES.FILE_PRODUCT_REFERENCE: {
            return {
              fragments,
              description: messages.createFileByProductReference,
            };
          }
          case CREATE_MODAL_TYPES.FILE_RECEIPT_INFORMATION: {
            return {
              fragments,
              description: messages.createFileByTicketReference,
            };
          }
          case CREATE_MODAL_TYPES.SPARE_PART_REQUEST: {
            return {
              fragments,
              description: messages.createSparePartRequest,
            };
          }
          case CREATE_MODAL_TYPES.FILE_BY_CUSTOMER_INFORMATION: {
            return {
              fragments,
              description: messages.createFileByCustomerReference,
            };
          }

          default: {
            return undefined;
          }
        }
      };

      const shortcut = getShortcut();

      if (shortcut) {
        return [...acc, shortcut];
      }

      return acc;
    }, initial);
  }, [authorizations]);
};

export default useModalShortcuts;
