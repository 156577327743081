import { STOCK_ITEM_STATUS } from '@savgroup-front-common/types';

import sparePartStockItemStatusMessages from '../helpers/i18n/messages/sparePartStockItemStatusMessages';

import { safeFormattedIntlString } from './safeFormattedIntlString';

const getSparePartStockItemStatus = (
  status: STOCK_ITEM_STATUS,
): ReturnType<typeof safeFormattedIntlString> => {
  let message;

  switch (status) {
    case STOCK_ITEM_STATUS.AVAILABLE: {
      message = sparePartStockItemStatusMessages.available;
      break;
    }
    case STOCK_ITEM_STATUS.BOOKED: {
      message = sparePartStockItemStatusMessages.booked;
      break;
    }
    case STOCK_ITEM_STATUS.CONSUMED: {
      message = sparePartStockItemStatusMessages.consumed;
      break;
    }
    case STOCK_ITEM_STATUS.BROKEN_UPON_RECEIPT: {
      message = sparePartStockItemStatusMessages.brokenUponReceipt;
      break;
    }
    case STOCK_ITEM_STATUS.MISMATCH: {
      message = sparePartStockItemStatusMessages.mismatch;
      break;
    }
    case STOCK_ITEM_STATUS.MISMATCH_WRONG_PART: {
      message = sparePartStockItemStatusMessages.mismatchWrongPart;
      break;
    }
    case STOCK_ITEM_STATUS.INSTALLED: {
      message = sparePartStockItemStatusMessages.installed;
      break;
    }
    case STOCK_ITEM_STATUS.BROKEN_DURING_INTERVENTION: {
      message = sparePartStockItemStatusMessages.brokenDuringIntervention;
      break;
    }
    case STOCK_ITEM_STATUS.DEFECTIVE: {
      message = sparePartStockItemStatusMessages.defective;
      break;
    }
    case STOCK_ITEM_STATUS.MISSING: {
      message = sparePartStockItemStatusMessages.missing;
      break;
    }
    case STOCK_ITEM_STATUS.OBSOLETE: {
      message = sparePartStockItemStatusMessages.obselete;
      break;
    }
    case STOCK_ITEM_STATUS.SUPPLIER_LITIGATION: {
      message = sparePartStockItemStatusMessages.supplierLitigation;
      break;
    }
    case STOCK_ITEM_STATUS.USELESS: {
      message = sparePartStockItemStatusMessages.useless;
      break;
    }
    case STOCK_ITEM_STATUS.IN_TRANSIT: {
      return safeFormattedIntlString(
        sparePartStockItemStatusMessages.inTransit,
      );
    }
    case STOCK_ITEM_STATUS.COMMERCIAL_SOLUTION: {
      return safeFormattedIntlString(
        sparePartStockItemStatusMessages.commercialSolution,
      );
    }

    default:
      return '';
  }

  return safeFormattedIntlString(message);
};

export default getSparePartStockItemStatus;
