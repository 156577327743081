import React, { FC } from 'react';

import { Switch } from '@savgroup-front-common/core/src/atoms/Form';
import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import {
  FLEX_DIRECTION,
  LOCAL_STORAGE_KEYS,
} from '@savgroup-front-common/types';

import messages from './messages';
import { $SwitchNewBo } from './SwitchNewBo.styles';

export const SwitchNewBo: FC = () => {
  const { isNewUiEnabled, onToggleNewBoEnabled } = useNewUiContext();

  return (
    <$SwitchNewBo $isNewUiEnabled={isNewUiEnabled}>
      <Switch
        label={messages.switchToNewBo}
        alignLabel={FLEX_DIRECTION.ROW}
        name={LOCAL_STORAGE_KEYS.NEW_BO_ENABLED}
        checked={isNewUiEnabled}
        onChange={() => onToggleNewBoEnabled()}
        isSmall
      />
    </$SwitchNewBo>
  );
};

SwitchNewBo.displayName = 'SwitchNewBo';
