import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  QuotationState,
  ReverseMoney,
} from '@savgroup-front-common/types';

import {
  QuotationRecipientDto,
  QuotationSparePartSelectionDto,
} from '../setQuotation/setQuotation';

const ENDPOINT = `${APIConfiguration.repairer}quotations/${COMMON_QUERY_PARAMS.QUOTE_ID}`;

interface UpdateQuotationArgs {
  quotationId: string;
  description: string;
  spareParts: {
    sparePartId: string;
    supplierId: string;
    description?: string;
    isRequiredForRepair?: boolean;
    isSelectedForRepair?: boolean;
    recipient?: QuotationRecipientDto;
    sparePart?: QuotationSparePartSelectionDto;
  }[];
  unknownSparePartsFromBuyingPrice: {
    description: string;
    reference: string;
    supplierId?: string;
    preTaxBuyingPrice: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  unknownSparePartsFromSellingPrice: {
    description: string;
    reference: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  services: {
    id: string;
  }[];
  discounts: {
    description: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  unknownServices: {
    description: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  administrativeFees: {
    description: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  state: QuotationState.DRAFT | QuotationState.PENDING_CUSTOMER_APPROVAL;
  isBonusRemoved?: boolean;
}

export enum QuotationTypes {
  Internal = 'Internal',
  Subcontractor = 'Subcontractor',
}

export interface UpdateQuotationInternalArgs extends UpdateQuotationArgs {
  type: QuotationTypes.Internal;
}
export interface UpdateQuotationWithSubcontractorArgs
  extends UpdateQuotationArgs {
  type: QuotationTypes.Subcontractor;
  subcontractorQuoteNumber: string;
  destructionFee: ReverseMoney;
  returnFee: ReverseMoney;
}

export default async function updateQuotation(
  payload: UpdateQuotationInternalArgs | UpdateQuotationWithSubcontractorArgs,
): Promise<BaseBackResponse> {
  try {
    const response = await apiCall(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.QUOTE_ID, payload.quotationId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          description: payload.description,
          type: payload.type,
          state: payload.state,
          subcontractorQuoteNumber:
            payload.type === QuotationTypes.Subcontractor
              ? payload.subcontractorQuoteNumber
              : undefined,
          spareParts: payload.spareParts,
          unknownSparePartsFromBuyingPrice:
            payload.unknownSparePartsFromBuyingPrice,
          unknownSparePartsFromSellingPrice:
            payload.unknownSparePartsFromSellingPrice,
          services: payload.services,
          discounts: payload.discounts,
          unknownServices: payload.unknownServices,
          administrativeFees: payload.administrativeFees,
          destructionFee:
            payload.type === QuotationTypes.Subcontractor
              ? payload.destructionFee
              : undefined,
          returnFee:
            payload.type === QuotationTypes.Subcontractor
              ? payload.returnFee
              : undefined,
          isBonusRemoved: payload.isBonusRemoved,
        },
      },
    );

    return response;
  } catch (exception) {
    return { errors: (exception as any).errors, failure: true };
  }
}
