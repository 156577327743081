import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requestLine/inStock`;

interface UpdateSparePartRequestLineArgs {
  requestLineId: string;
  stockItemId: string;
  fileId?: string;
  fileReference?: string;
}

export default async function setStockToSparePartRequestLine(
  payload: UpdateSparePartRequestLineArgs,
): Promise<void> {
  const response = await apiCall<BaseBackResponse>(ENDPOINT, {
    method: SUPPORTED_METHODS.PUT,
    json: payload,
  });

  if (response.failure) {
    throw new Error(
      `Got an exception trying to setStockToSparePartRequestLine`,
    );
  }
}
