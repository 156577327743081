import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { StockItemGroupInInventorySummaryDto } from './type/StockItemGroupInInventorySummaryDto';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.sparePart}stockInInventory/UpdateStockItemGroupInInventory/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}`;

export interface UpdateStockItemGroupInInventoryCommandArgs {
  stockItemGroupInInventoryId: string;
  sparePartId: string;
  manufacturerReference: string;
  manufacturerId: string;
  countQuantity: number;
  comment?: string;
  isCounted?: boolean;
  isChecked?: boolean;
}

export default async function updateStockItemGroupInInventoryCommand({
  stockItemGroupInInventoryId,
  sparePartId,
  manufacturerReference,
  manufacturerId,
  countQuantity,
  comment,
  isCounted,
  isChecked,
}: UpdateStockItemGroupInInventoryCommandArgs): Promise<
  BackResponse<StockItemGroupInInventorySummaryDto> | BackResponseFailure
> {
  try {
    const response = await apiCall<
      BackResponse<StockItemGroupInInventorySummaryDto>
    >(
      ENDPOINT.replace(
        COMMON_QUERY_PARAMS.STOCK_ITEM_ID,
        stockItemGroupInInventoryId,
      ),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          sparePartId,
          manufacturerReference,
          manufacturerId,
          countQuantity,
          comment,
          isCounted,
          isChecked,
        },
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
