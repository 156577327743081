import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.config}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/configuration/stripePublicKey`;

export interface SetSetSellerConfigurationStripeAccountPublicKeyArgs {
  sellerId: string;
  publicKey?: string;
}

export default async function setSetSellerConfigurationStripeAccountPublicKey(
  payload: SetSetSellerConfigurationStripeAccountPublicKeyArgs,
): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      payload.sellerId,
    );

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: { publicKey: payload.publicKey },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
