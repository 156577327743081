import { useEffect } from 'react';

import { EVENT_CODE } from '@savgroup-front-common/constants';

const targetIsDatePicker = (target: HTMLElement) => {
  return (
    target.classList.contains('react-datepicker__month-select') ||
    target.classList.contains('react-datepicker__year-select') ||
    target.classList.contains('react-datepicker__day') ||
    target.classList.contains('react-datepicker__navigation')
  );
};

export interface Shortcut {
  callback?: () => void;
  codes: EVENT_CODE[];
}

export const handleShortcutCallback = ({
  event,
  shortcut: { callback, codes },
}: {
  event: KeyboardEvent;
  shortcut: Shortcut;
}): void => {
  const hasShift = codes.includes(EVENT_CODE.SHIFT_LEFT);
  const hasCtrl = codes.includes(EVENT_CODE.CONTROL_LEFT);
  const hasAlt = codes.includes(EVENT_CODE.ALT_LEFT);

  const ctrlReady = (hasCtrl && event.ctrlKey) || (!hasCtrl && !event.ctrlKey);
  const altReady = (hasAlt && event.altKey) || (!hasAlt && !event.altKey);
  const shiftReady =
    (hasShift && event.shiftKey) || (!hasShift && !event.shiftKey);

  const code = codes.filter(
    (code) =>
      ![
        EVENT_CODE.SHIFT_LEFT,
        EVENT_CODE.CONTROL_LEFT,
        EVENT_CODE.ALT_LEFT,
      ].includes(code),
  )?.[0];

  const isShortCut = ctrlReady && altReady && shiftReady && event.code === code;

  if (isShortCut && callback) {
    callback();
  }
};
export const checkTargetIsInput = ({ event }: { event: KeyboardEvent }) => {
  const target = event.target as HTMLInputElement;

  const isInput =
    ['INPUT', 'TEXTAREA'].includes(target.tagName) ||
    ['autocomplete', 'datepicker'].includes(String(target?.dataset?.type)) ||
    targetIsDatePicker(target);

  return isInput;
};

const useShortCut = ({ callback, codes }: Shortcut): void => {
  useEffect(() => {
    function handleShortcut(event: KeyboardEvent) {
      if (checkTargetIsInput({ event })) {
        return;
      }

      handleShortcutCallback({
        event,
        shortcut: { callback, codes },
      });
    }
    document.addEventListener('keydown', handleShortcut);

    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [callback, codes]);
};

export default useShortCut;
