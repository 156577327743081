import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OwnerSummary,
} from '@savgroup-front-common/types';

const PHONE_NUMBER_PARAM = ':phoneNumber';

const ENDPOINT = `${APIConfiguration.owner}tenants/${COMMON_QUERY_PARAMS.TENANT_ID}/owners/phone/${PHONE_NUMBER_PARAM}`;

interface GetOwnersByPhoneNumberPayload {
  phoneNumber: string;
  tenantId: string;
}

async function getOwnersByPhoneNumber({
  tenantId,
  phoneNumber,
}: GetOwnersByPhoneNumberPayload): Promise<
  BackResponse<OwnerSummary[]> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.TENANT_ID,
      tenantId,
    ).replace(PHONE_NUMBER_PARAM, phoneNumber);

    const response = await apiCall<BackResponse<OwnerSummary[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getOwnersByPhoneNumber;
