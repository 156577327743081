import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const SELLER_ID = 'SELLER_ID';

const ENDPOINT = `${APIConfiguration.catalog}modeltypes/generic?SellerId=${SELLER_ID}`;

export interface UniverseModelType {
  id: string;
  name: string;
  uniqueName: string;
  defaultWeight: number;
  isLowValue: boolean;
  additionalReturnInfo: {
    isRmaPossible: boolean;
    isInspectionRequired: boolean;
    hasUnlockCode: boolean;
    isWarrantyRepurchase: boolean;
    isReturnable: boolean;
    isRepairable: boolean;
    isTransportable: boolean;
    isSerializable: boolean;
    isOnSiteInterventionPossible: boolean;
    isCumbersome: boolean;
  };
  parent: string[];
  isGeneric: boolean;
}

interface GetModelTypeGenericArgs {
  sellerId: string;
}

const getModelTypeGeneric = async ({
  sellerId,
}: GetModelTypeGenericArgs): Promise<
  BackResponse<UniverseModelType[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(SELLER_ID, sellerId);

    const response = await apiCall<BackResponse<UniverseModelType[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getModelTypeGeneric;
