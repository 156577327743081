import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotations/canCreate`;

export interface CanCreateQuotationOnFileIdQueryArgs {
  fileId: string;
}

export async function canCreateQuotationOnFileIdQuery({
  fileId,
}: CanCreateQuotationOnFileIdQueryArgs): Promise<
  BackResponse<boolean> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<boolean>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
