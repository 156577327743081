import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';
import { AuthConfiguration } from '@savgroup-front-common/types';

import AuthConfigDEMO from './Auth.config.DEMO';
import AuthConfigDEV from './Auth.config.DEV';
import AuthConfigLOCAL from './Auth.config.LOCAL';
import AuthConfigPREPROD from './Auth.config.PREPROD';
import AuthConfigPROD from './Auth.config.PROD';

const getCurrentAuthConfigs = (): AuthConfiguration => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return AuthConfigDEMO;
    case APP_ENVS.DEV:
      return AuthConfigDEV;
    case APP_ENVS.LOCAL:
      return AuthConfigLOCAL;
    case APP_ENVS.PROD:
      return AuthConfigPROD;
    case APP_ENVS.PREPROD:
      return AuthConfigPREPROD;
    default:
      throw new Error(`${currentAppEnvironment} is not supported as APP_ENVS.`);
  }
};

export default getCurrentAuthConfigs();
