import { defineMessages } from 'react-intl';

export default defineMessages({
  ToValidate: {
    id: 'RequestLineStatus.ToValidate',
    defaultMessage: 'To Validate',
  },
  Cancelled: {
    id: 'RequestLineStatus.Cancelled',
    defaultMessage: 'Cancelled',
  },
  Valid: {
    id: 'RequestLineStatus.Valid',
    defaultMessage: 'Valid',
  },

  ToProcess: {
    id: 'RequestLineStatus.ToProcess',
    defaultMessage: 'To Process',
  },
  OrderSubmitted: {
    id: 'RequestLineStatus.OrderSubmitted',
    defaultMessage: 'Order Submitted',
  },
  OrderDelivered: {
    id: 'RequestLineStatus.OrderDelivered',
    defaultMessage: 'Order Delivered',
  },
  OrderCollected: {
    id: 'RequestLineStatus.OrderCollected',
    defaultMessage: 'Order Collected',
  },
  AvailableInStock: {
    id: 'RequestLineStatus.AvailableInStock',
    defaultMessage: 'Available In Stock',
  },
  Rejected: { id: 'RequestLineStatus.Rejected', defaultMessage: 'Rejected' },
  Missing: { id: 'RequestLineStatus.Missing', defaultMessage: 'Missing' },
  Closed: { id: 'RequestLineStatus.Closed', defaultMessage: 'Closed' },
  OrderShipped: {
    id: 'RequestLineStatus.OrderShipped',
    defaultMessage: 'Order Shipped',
  },

  CancelledAbandoned: {
    id: 'RequestLineStatus.CancelledAbandoned',
    defaultMessage: 'Cancelled Abandoned',
  },
  SubmittedToSupplier: {
    id: 'RequestLineStatus.SubmittedToSupplier',
    defaultMessage: 'Submitted To Supplier',
  },
  Remainder: { id: 'RequestLineStatus.Remainder', defaultMessage: 'Remainder' },
  SoldOut: { id: 'RequestLineStatus.SoldOut', defaultMessage: 'SoldOut' },
  CancelledCommercialSolutionRemainder: {
    id: 'RequestLineStatus.CancelledCommercialSolutionRemainder',
    defaultMessage: 'Cancelled Commercial Solution Remainder',
  },
  CancelledCommercialSolutionExpired: {
    id: 'RequestLineStatus.CancelledCommercialSolutionExpired',
    defaultMessage: 'Cancelled Commercial Solution Expired',
  },
  CancelledCommercialSolutionSoldOut: {
    id: 'RequestLineStatus.CancelledCommercialSolutionSoldOut',
    defaultMessage: 'Cancelled Commercial Solution SoldOut',
  },
  CancelledCommercialSolutionNotRepairable: {
    id: 'RequestLineStatus.CancelledCommercialSolutionNotRepairable',
    defaultMessage: 'Cancelled Commercial Solution Not Repairable',
  },
});
