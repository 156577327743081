import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { RESULT_TYPE } from '@savgroup-front-common/types';

import { RESULT_DISPLAY_TYPE, SearchResultError } from '../Search.types';

const generateResultError = ({
  type,
  animationIndex = 0,
  isNew = true,
  errors,
}: {
  type: RESULT_TYPE;
  animationIndex?: number;
  errors: ErrorFromBack[];
  isNew?: boolean;
}): SearchResultError => ({
  displayType: RESULT_DISPLAY_TYPE.ERROR,
  animationIndex,
  key: `error-${type}`,
  resultType: type,
  isNew,
  value: {
    errors,
  },
});

export default generateResultError;
