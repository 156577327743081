import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { AppScheduledMaintenance } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters';

import {
  $ToastBody,
  $ToastLink,
  $ToastTime,
} from './InProgressScheduledMaintenance.styles';
import messages from './messages';

interface InProgressScheduledMaintenanceProps {
  scheduledMaintenance: AppScheduledMaintenance;
}

const InProgressScheduledMaintenance: FunctionComponent<
  InProgressScheduledMaintenanceProps
> = ({ scheduledMaintenance }) => {
  const { formatDate } = useIntl();

  return (
    <>
      <$ToastTime>
        <SafeFormattedMessageWithoutSpread
          message={messages.scheduledUntil}
          values={{
            time: formatDate(scheduledMaintenance.scheduledUntil, {
              day: 'numeric',
              month: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
          }}
        />
      </$ToastTime>
      <$ToastBody>{scheduledMaintenance.incidentUpdates[0].body}</$ToastBody>
      <$ToastLink
        href={scheduledMaintenance.shortlink}
        isExternal
        target="_blank"
      >
        <SafeFormattedMessageWithoutSpread message={messages.seemore} />
      </$ToastLink>
    </>
  );
};

InProgressScheduledMaintenance.displayName = 'InProgressSchuduledMaintenance';

export default InProgressScheduledMaintenance;
