import { useCallback, useEffect, useState } from 'react';

export default function useMouseHasMoved(): [boolean, () => void] {
  const [isSteady, setIsSteady] = useState(false);

  const mouseMove = useCallback(() => {
    if (isSteady) {
      setIsSteady(false);
    }
  }, [isSteady]);

  const clear = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
  }, [mouseMove]);

  const watchMoves = useCallback(() => {
    setIsSteady(true);
    window.addEventListener('mousemove', mouseMove);
  }, [mouseMove]);

  useEffect(() => {
    if (isSteady) {
      watchMoves();
    }

    return clear;
  }, [clear, isSteady, watchMoves]);

  return [isSteady, watchMoves];
}
