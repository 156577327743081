import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/unexpectedProduct`;

interface RemoveUnexpectedProductCommandPayload {
  fileId: string;
  unexpectedProductId: string;
}

const removeUnexpectedProductCommand = async ({
  fileId,
  unexpectedProductId,
}: RemoveUnexpectedProductCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.DELETE,
      json: {
        unexpectedProductId,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default removeUnexpectedProductCommand;
