import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponseFailure } from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/download/${COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION}`;

export interface ConsoleEntityFieldFilter {
  key: string;
  value: string[];
}

interface GetFileConfigurationDownloadQueryArgs {
  sellerId: string;
  importType: string;
}

async function getFileConfigurationDownloadQuery({
  sellerId,
  importType,
}: GetFileConfigurationDownloadQueryArgs): Promise<Blob | BackResponseFailure> {
  try {
    const file = await apiCall<Blob>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
        COMMON_QUERY_PARAMS.CONSOLE_ENTITY_DEFINITION,
        importType,
      ),
      {
        method: SUPPORTED_METHODS.GET,
        responseType: 'blob',
      },
    );

    return file;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFileConfigurationDownloadQuery;
