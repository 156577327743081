import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  CUSTOMER_TYPE,
  ModelDimension,
  ProductStates,
  ReverseMoney,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}debugging/claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/context`;

export interface ClaimDebuggingContext {
  sellerId: string;
  claimId: string;
  orderLineReference: string;
  orderReference: string;
  orderId: string;
  fileReference: string;
  fileId: string;
  ownerProductId: string;
  salesChannel: string;
  purchaseStoreId: string;
  purchaseStoreName: string;
  country: COUNTRY_CODES;
  orderPaymentMethod?: string;
  orderDeliveryDate: Date;
  orderDeliveryMethod: string;
  customerType?: CUSTOMER_TYPE;
  soldWarranty: {
    warrantyId: string;
    warrantyTypeId: string;
    soldWarrantyName: string;
    purchaseDate?: Date;
    insurerUniqueName: string;
    insurerId: string;
  };
  warrantyTypeId: string;
  orderPurchaseDate?: Date;
  warrantyTypeName: string;
  issueId: string;
  issueName: string;
  reasonId: string;
  reasonName: string;
  solutionTypeId: string;
  solutionTypeName: string;
  actorId: string;
  actorName: string;
  depositTransportMethodId: string;
  depositTransportMethodName: string;
  carrierDepositProductId: string;
  carrierDepositProductName: string;
  deliveryTransportMethodId: string;
  deliveryTransportMethodName: string;
  carrierDeliveryProductId: string;
  productModelTypeName: string;
  productModelTypeId: string;
  productModelId: string;
  productModelName: string;
  productBrandName: string;
  productEan: string;
  productSku: string;
  productSupplierId: string;
  productSupplierName: string;
  productState: ProductStates;
  productPrice: ReverseMoney;
  productIsLowValue: boolean;
  productWeight: number;
  productDimensions: ModelDimension;
  productIsRepairable: boolean;
  isAllReturned: boolean;
  storeActorZipCode: string;
  ownerZipCode: string;
  constraintDisplayNameMapping: Record<string, string[]>;
}

async function getClaimDebuggingContextQuery({
  claimId,
}: {
  claimId: string;
}): Promise<BackResponse<ClaimDebuggingContext> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<ClaimDebuggingContext>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getClaimDebuggingContextQuery;
