import React, { FunctionComponent } from 'react';

import {
  $CircularBackground,
  $CircularProgress,
} from './CurcularProgress.styles';

interface CircularProgressProps {
  percentage: number;
  sqSize?: number;
  strokeWidth?: number;
  color?: string;
  withBackground?: boolean;
}

export const CircularProgress: FunctionComponent<CircularProgressProps> = ({
  strokeWidth = 3,
  sqSize = 20,
  percentage,
  color,
  withBackground = true,
}) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      {withBackground && (
        <$CircularBackground
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
      )}
      <$CircularProgress
        $color={color}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
    </svg>
  );
};

CircularProgress.displayName = 'CircularProgress';
