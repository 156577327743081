import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  StockItem,
} from '@savgroup-front-common/types';
import { StockItemListRubric } from 'control/domains/stocks';

const ENDPOINT = `${APIConfiguration.sparePart}search/stockItem`;

export interface GetStockItemsArgs {
  page: number;
  pageSize: number;
  isDescending?: boolean;
  sort?: SORT_TYPES;
  query: string;
}

interface GetStockItemsResponse {
  hitCount: number;
  hits: StockItem[];
  rubrics: StockItemListRubric[];
}

export default async function getStockItems({
  page,
  pageSize,
  isDescending,
  sort = SORT_TYPES.STOCK_INPUT_TIME_UTC,
  query,
}: GetStockItemsArgs): Promise<
  BackResponse<GetStockItemsResponse> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<GetStockItemsResponse>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        page,
        pageSize,
        isDescending,
        sort,
        query,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
