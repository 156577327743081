import React, { FunctionComponent } from 'react';
import { CellProps } from 'react-table';

import { EVENT_CODE } from '@savgroup-front-common/constants';
import { ShortcutKeyboard } from '@savgroup-front-common/core/src/molecules/ShortcutKeyboard';

import { ShortcutKey } from '../../../ShortcutsContent.types';
import { $ShortcutKeyGroup } from '../../../ShortcutsModal.styles';

const ShortcutCell: FunctionComponent<
  React.PropsWithChildren<CellProps<ShortcutKey, EVENT_CODE[]>>
> = ({ value }) => {
  return (
    <$ShortcutKeyGroup>
      {value.map((code, index) => {
        return (
          <React.Fragment key={code}>
            {index > 0 && '+'}
            <ShortcutKeyboard code={code} />
          </React.Fragment>
        );
      })}
    </$ShortcutKeyGroup>
  );
};

ShortcutCell.displayName = 'ShortcutCell';

export default ShortcutCell;
