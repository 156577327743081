import { createSelector } from 'reselect';

const filesState = (state) => state.files;

export const ownerProductsMap = createSelector(
  [filesState],
  (files) => files.ownerProductsMap,
);

export const sellerInfo = createSelector(
  [filesState],
  (files) => files.sellerInfo,
);
