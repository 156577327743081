import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

import { ADDRESS_SUGGESTION_ORIGIN } from './AddressSuggestion.types';

const AddressSuggestionSchema = ({
  haveOwnerAddressSuggestion,
  haveOwnerAddressOnFileSuggestion,
}: {
  haveOwnerAddressSuggestion: boolean;
  haveOwnerAddressOnFileSuggestion: boolean;
}) => {
  let schema = Yup.object();

  if (haveOwnerAddressSuggestion) {
    schema = schema.shape({
      ownerAddressToKeep: Yup.string()
        .oneOf(Object.values(ADDRESS_SUGGESTION_ORIGIN))
        .required(globalMessages.form.required),
    });
  }
  if (haveOwnerAddressOnFileSuggestion) {
    schema = schema.shape({
      ownerAddressOnFileToKeep: Yup.string()
        .oneOf(Object.values(ADDRESS_SUGGESTION_ORIGIN))
        .required(globalMessages.form.required),
    });
  }

  return schema;
};

export default AddressSuggestionSchema;
