import {
  logException as commonLogException,
  Context,
  Exception,
  LogException,
} from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SEVERITY_LEVEL } from '@savgroup-front-common/constants/src/shared';

import { store } from '../view/app/App.config';

export function logException(
  { error, severityLevel = SEVERITY_LEVEL.ERROR }: LogException,
  context: Context = {},
) {
  const state = store.getState();

  const extendedContext = {
    ...context,
    userInfos: {
      userId: state?.login?.userId,
      mail: state?.login?.mail,
    },
  };

  commonLogException({ error, severityLevel }, extendedContext);
}

export function logWarning(error: Exception, context: Context = {}) {
  logException({ error, severityLevel: SEVERITY_LEVEL.WARNING }, context);
}

export function logError(error: Exception, context: Context = {}) {
  logException({ error, severityLevel: SEVERITY_LEVEL.ERROR }, context);
}

export function logCritical(error: Exception, context: Context = {}) {
  logException({ error, severityLevel: SEVERITY_LEVEL.CRITICAL }, context);
}

export function logVerbose(error: Exception, context: Context = {}) {
  logException({ error, severityLevel: SEVERITY_LEVEL.VERBOSE }, context);
}
