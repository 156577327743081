import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.warranty}warrantytypes/${COMMON_QUERY_PARAMS.WARRANTY_ID}`;

export interface GetWarrantyTypeQueryResponseValue {
  id: string;
  name: string;
  uniqueName: string;
  key: string;
  warrantyTypeGroup: string;
  requiresSwornStatement: string;
}

interface GetWarrantyTypeQueryQuery {
  warrantyId: string;
}

async function getWarrantyTypeQuery({
  warrantyId,
}: GetWarrantyTypeQueryQuery): Promise<
  BackResponse<GetWarrantyTypeQueryResponseValue> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.WARRANTY_ID, warrantyId);

    const response = await apiCall<
      BackResponse<GetWarrantyTypeQueryResponseValue>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getWarrantyTypeQuery;
