 
export enum CREATE_MODAL_TYPES {
  FILE_ORDER_REFERENCE = 'CREATE_FILE_BY_ORDER_REFERENCE',
  FILE_PRODUCT_REFERENCE = 'CREATE_FILE_BY_PRODUCT_REFERENCE',
  FILE_OWNER_STOCK = 'CREATE_FILE_BY_OWNER_STOCK',
  FILE_RECEIPT_INFORMATION = 'CREATE_FILE_BY_RECEIPT_INFORMATION',
  FILE_BY_CUSTOMER_INFORMATION = 'CREATE_FILE_BY_CUSTOMER_INFORMATION',
  SPARE_PART_REQUEST = 'CREATE_SPARE_PART_REQUEST',
  SELLER = 'CREATE_SELLER',
  TENANT = 'CREATE_TENANT',
}
