import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  DraftHistory,
  DraftHistoryStatus,
  SellerId,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/drafts/byStatuses`;

interface GetDraftByStatusesQueryPayload {
  sellerId: SellerId;
  statuses: DraftHistoryStatus[];
}

type GetDraftByStatusesQuery = (
  payload: GetDraftByStatusesQueryPayload,
) => Promise<BackResponse<DraftHistory[]> | BackResponseFailure>;

const getDraftByStatusesQuery: GetDraftByStatusesQuery = async ({
  sellerId,
  statuses,
}) => {
  try {
    return await apiCall<BackResponse<DraftHistory[]>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId), {
        statuses,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getDraftByStatusesQuery;
