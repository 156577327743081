import { useLayoutEffect } from 'react';

import { GoogleApiConfiguration } from '@savgroup-front-common/configuration';

const useInitGoogleMap = (): void => {
  useLayoutEffect(() => {
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleApiConfiguration.apiKey}&libraries=places`;
    script.type = 'text/javascript';

    document.body.appendChild(script);
  }, []);
};

export default useInitGoogleMap;
