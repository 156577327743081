import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';

import AddressInformationsSchema from '../AddressInformations.schema';
import { AddressInformationsFormValues } from '../AddressInformations.types';

import useAddressInformationStepContext from './useAddressInformationStepContext';
import { useHabitationOptions } from './useHabitationOptions';
import { AddressInfoDto } from '@savgroup-front-common/types';
import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import useHandleSuggestion from './useHandleSuggestion';
import { useState } from 'react';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { prepareAddressInfo } from '../AddressInformationForm/adapters/prepareAddressInfo';

const getUnmatchingFields = (
  address1: AddressInfoDto,
  address2: AddressInfoDto,
): string[] => {
  const returnValue = [];

  if (address1.address.toUpperCase() !== address2.address.toUpperCase()) {
    returnValue.push('address');
  }
  if (
    (address1.additionalAddress?.toUpperCase() || '') !==
    (address2.additionalAddress?.toUpperCase() || '')
  ) {
    returnValue.push('additionalAddress');
  }
  if (address1.city.toUpperCase() !== address2.city.toUpperCase()) {
    returnValue.push('city');
  }
  if (
    address1.countryCode.toUpperCase() !== address2.countryCode.toUpperCase()
  ) {
    returnValue.push('countryCode');
  }

  if (address1.postalCode.toUpperCase() !== address2.postalCode.toUpperCase()) {
    returnValue.push('postalCode');
  }

  return returnValue;
};

const useAddressInformationForm = () => {
  const {
    values,
    context: { couldEditAddressOnFile },
  } = useAddressInformationStepContext();

  const { habitationOptions } = useHabitationOptions();

  const defaultOwnerAddressOnFile =
    values?.ownerAddressOnFile || values?.ownerAddress;

  const formContext = useForm<AddressInformationsFormValues>({
    resolver: yupResolver(AddressInformationsSchema),
    defaultValues: {
      ownerAddress: {
        additionalAddress:
          values?.ownerAddress?.address?.additionalAddress || '',
        address: values?.ownerAddress?.address?.address || '',
        city: values?.ownerAddress?.address?.city || '',
        companyName: values?.ownerAddress?.address?.companyName || '',
        country: values?.ownerAddress?.address?.countryCode
          ? { value: values?.ownerAddress?.address?.countryCode }
          : { value: COUNTRY_CODES.FR },
        firstName: values?.ownerAddress?.address?.firstname || '',
        lastName: values?.ownerAddress?.address?.lastname || '',
        phoneNumber: values?.ownerAddress?.address?.phone || '',
        postalCode: values?.ownerAddress?.address?.postalCode || '',
        hasElevator: values?.ownerAddress?.address.hasElevator,
        hasParkingSpace: values?.ownerAddress?.address.hasParkingSpace,
        housingType: habitationOptions.find(
          (habitation) =>
            habitation.value === values?.ownerAddress?.address.housingType,
        ),
        floor: values?.ownerAddress?.address.floor,
        doorCode: values?.ownerAddress?.address.doorCode,
        additionalInformation:
          values?.ownerAddress?.address.additionalInformation,
      },
      ownerAddressOnFile: {
        additionalAddress:
          defaultOwnerAddressOnFile?.address?.additionalAddress || '',
        address: defaultOwnerAddressOnFile?.address?.address || '',
        city: defaultOwnerAddressOnFile?.address?.city || '',
        companyName: defaultOwnerAddressOnFile?.address?.companyName || '',
        country: defaultOwnerAddressOnFile?.address?.countryCode
          ? { value: defaultOwnerAddressOnFile?.address?.countryCode }
          : { value: COUNTRY_CODES.FR },
        firstName: defaultOwnerAddressOnFile?.address?.firstname || '',
        lastName: defaultOwnerAddressOnFile?.address?.lastname || '',
        phoneNumber: defaultOwnerAddressOnFile?.address?.phone || '',
        postalCode: defaultOwnerAddressOnFile?.address?.postalCode || '',
        hasElevator: defaultOwnerAddressOnFile?.address.hasElevator,
        hasParkingSpace: defaultOwnerAddressOnFile?.address.hasParkingSpace,
        housingType: habitationOptions.find(
          (habitation) =>
            habitation.value === defaultOwnerAddressOnFile?.address.housingType,
        ),
        floor: defaultOwnerAddressOnFile?.address.floor,
        doorCode: defaultOwnerAddressOnFile?.address.doorCode,
        additionalInformation:
          defaultOwnerAddressOnFile?.address.additionalInformation,
      },
      isOwnerAddressOnFileSame: values.isOwnerAddressOnFileSame ?? true,
    },
    shouldUnregister: true,
  });
  const { handleSubmit } = formContext;

  const { nextStep, onSubmit } = useAddressInformationStepContext();

  const { handleSuggestion, isVerifyStrict } = useHandleSuggestion();
  const [isLoading, setIsLoading] = useState(false);

  const { pushErrors } = useBanners();

  const handleAddressInformationsSubmit = handleSubmit(
    async ({ ownerAddress, ownerAddressOnFile, isOwnerAddressOnFileSame }) => {
      setIsLoading(true);

      const addressValues = prepareAddressInfo(ownerAddress);
      const suggestedAddress = await handleSuggestion(addressValues);

      if (!suggestedAddress) {
        setIsLoading(false);

        return undefined;
      }

      const unmatchingFields = getUnmatchingFields(
        addressValues,
        suggestedAddress,
      );

      if (couldEditAddressOnFile) {
        const addressOnFileValues = prepareAddressInfo(
          isOwnerAddressOnFileSame ? ownerAddress : ownerAddressOnFile,
        );
        const suggestedAddressOnFile = await handleSuggestion(
          addressOnFileValues,
        );

        if (!suggestedAddressOnFile) {
          setIsLoading(false);

          return undefined;
        }
        const unmatchingFieldsOnFileAddress = getUnmatchingFields(
          addressOnFileValues,
          suggestedAddressOnFile,
        );

        if (
          unmatchingFields.length === 0 &&
          unmatchingFieldsOnFileAddress.length === 0
        ) {
          try {
            onSubmit(
              {
                ownerAddress: { address: addressValues },
                ownerAddressOnFile: { address: addressOnFileValues },
                isOwnerAddressOnFileSame,
              },
              { hasSuggestedAddress: false, unmatchingFields },
            );
          } catch (errors) {
            pushErrors(errors as ErrorFromBack[]);
            setIsLoading(false);
          }

          return undefined;
        }

        nextStep(
          {
            ownerAddress: { address: addressValues, suggestedAddress },
            ownerAddressOnFile: {
              address: addressOnFileValues,
              suggestedAddress: suggestedAddressOnFile,
            },
          },
          { hasSuggestedAddress: true, unmatchingFields },
        );

        return undefined;
      }

      if (unmatchingFields.length === 0) {
        try {
          onSubmit(
            {
              ownerAddress: { address: addressValues },
            },
            { hasSuggestedAddress: false, unmatchingFields },
          );
        } catch (errors) {
          pushErrors(errors as ErrorFromBack[]);
          setIsLoading(false);
        }

        return undefined;
      }

      nextStep(
        {
          ownerAddress: { address: addressValues, suggestedAddress },
        },
        { hasSuggestedAddress: true, unmatchingFields },
      );

      return undefined;
    },
  );

  return {
    formContext,
    handleAddressInformationsSubmit,
    isLoading,
    isVerifyStrict,
  };
};

export default useAddressInformationForm;
