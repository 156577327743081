import { defineMessages } from 'react-intl';

export default defineMessages({
  pleaseWait: {
    id: 'view.ExplodeFileModal.pleaseWait',
    defaultMessage: 'Please wait and do not close the page',
  },
  seemore: {
    id: 'view.appStatus.seeMore',
    defaultMessage: 'See more',
  },
});
