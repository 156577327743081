import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.import}generate/diagnostictree`;

export interface ConsoleEntityKey {
  id?: string;
  uniqueName?: string;
  displayName?: string;
  displayBothDisplayAndUniqueNames: boolean;
  tooltip?: string;
  linkedValue?: string;
}

export interface GeneratedEntityFromConsoleCommandArg {
  sellerId: string;
  label?: string;
  modelType: ConsoleEntityKey;
  brand: ConsoleEntityKey;
  reason: ConsoleEntityKey;
  descriptionPrompt?: string;
}

async function generateEntityFromConsoleCommand({
  sellerId,
  ...payload
}: GeneratedEntityFromConsoleCommandArg): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    const response = await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: { ...payload, sellerId },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default generateEntityFromConsoleCommand;
