import React, { FunctionComponent } from 'react';

import { Z_INDEX_MENU } from '@savgroup-front-common/constants/src/shared';
import { BackgroundDimmer } from '@savgroup-front-common/core/src/atoms/BackgroundDimmer';
import { sellerSelectors } from '@savgroup-front-common/core/src/domains/sellers';
import { useControlTypedSelector } from 'control/hooks';

import useFaqUrl from './hooks/useFaqUrl';
import useGetMenuItems from './hooks/useGetMenuItems';
import { MenuExternalLink } from './MenuExternalLink';
import MenuLink from './MenuLink';
import SubMenusLink from './MenuLink/SubMenusLink';
import { SellerFaqModal } from './SellerFaqModal';
import { $MenuContainer, $MenuItem, $MenuList } from './SidBarMenu.styles';

interface MenuProps {
  onToggleMenu: () => void;
  isOpen: boolean;
}

const SidBarMenu: FunctionComponent<React.PropsWithChildren<MenuProps>> = ({
  isOpen,
  onToggleMenu,
}) => {
  const sellers = useControlTypedSelector(sellerSelectors.selectSellers);
  const { handleOpenUrl, handleFaqButton, isOpenModal, setIsOpenModal } =
    useFaqUrl();

  const { menus, index } = useGetMenuItems();

  return (
    <>
      <BackgroundDimmer
        isOpen={isOpen}
        onClick={onToggleMenu}
        zIndex={Z_INDEX_MENU}
      />
      <$MenuContainer $isOpen={isOpen}>
        <$MenuList $index={index}>
          {menus.map((menu) => {
            if (menu.isExternal) {
              return (
                <$MenuItem
                  key={menu.to}
                  onClick={handleFaqButton}
                  onKeyPress={handleFaqButton}
                  $hideCountOnHover={menu.count === 0 && menu.subCount > 0}
                  role="presentation"
                  data-testid={`sidebar_menucontainer_${menu.dataTestId}`}
                >
                  <MenuExternalLink icon={menu.icon} message={menu.message} />

                  {sellers.length > 1 && (
                    <SellerFaqModal
                      isOpen={isOpenModal}
                      onClose={() => setIsOpenModal(false)}
                      onSelect={handleOpenUrl}
                    />
                  )}
                </$MenuItem>
              );
            }

            return (
              <$MenuItem
                key={menu.to}
                onClick={onToggleMenu}
                onKeyPress={onToggleMenu}
                $hideCountOnHover={menu.count === 0 && menu.subCount > 0}
                role="presentation"
                data-testid={`sidebar_menucontainer_${menu.dataTestId}`}
              >
                <MenuLink
                  to={menu.to}
                  dataTestId={`sidebar_menu_${menu.dataTestId}`}
                  isActive={
                    menu.isActive ||
                    menu.submenus.some(({ isActive }) => isActive)
                  }
                  message={menu.message}
                  icon={menu.icon}
                  count={menu.count || menu.subCount}
                />
                {menu.submenus.length > 0 && (
                  <SubMenusLink menus={menu.submenus} />
                )}
              </$MenuItem>
            );
          })}
        </$MenuList>
      </$MenuContainer>
    </>
  );
};

SidBarMenu.displayName = 'SidBarMenu';

export default React.memo(SidBarMenu);
