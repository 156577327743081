import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';

export const $QrcodeUploadFileInputContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${media.maxWidth.xs} {
    margin-left: 10px;
  }
`;

export const $QrcodeUploadFileInputButton = styled(Button)`
  && {
    padding-left: 35px;
    padding-right: 35px;
  }
`;

export const $HiddenInput = styled('input')`
  display: none;
`;

export const $CardPosition = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 100vh;
`;

export const $LogoPosition = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const $ImportMessage = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.large};
  text-align: center;
  margin-bottom: 1rem;
`;

export const $CardContent = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

interface $UploadMessageProps {
  $error?: boolean;
}

export const $UploadMessage = styled.div<$UploadMessageProps>`
  margin-top: 10px;
  text-align: center;
  color: ${({ $error, theme }) =>
    $error ? theme.colors.danger : theme.colors.success};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;
