/* eslint-disable no-console */
import { fromJS } from 'immutable';
import assign from 'lodash/assign';
import get from 'lodash/get';
import omit from 'lodash/omit';

import * as ActionTypes from './actionTypes';

const initialState = fromJS({
  quotesMap: {},
  quotesMapOldEntries: {},
  draftIds: {},
  currentQuoteIds: {},
});

const onQuoteLoaded = (state, payload) => {
  const quote = get(payload, 'value');
  const quoteId = get(quote, 'id');

  if (quoteId) {
    return state.setIn(['quotesMap', quoteId], {
      ...quote,
      savePending: false,
      editable: quote.state === 'Draft',
      isLoaded: true,
      nextPosition: quote.quoteItemSummaries.length,
      quoteItemSummaries: assign(
        {},
        quote.quoteItemSummaries.map((task) => {
          const { partsPrice, laborPrice, bundlePrice } = task;
          const prices =
            partsPrice || laborPrice
              ? {
                  partsPrice,
                  laborPrice,
                }
              : { bundlePrice };

          return {
            name: task.name,
            quoteItemId: task.id,
            ...prices,
          };
        }),
      ),
    });
  }
  console.warn('Quote returned by the api call was malformed', quote);

  return state;
};

const onQuoteSubmitted = (state, payload, meta) => {
  const quoteId = get(meta, 'quoteId');

  if (quoteId) {
    return state.updateIn(['quotesMap'], (quotes) => quotes.delete(quoteId));
  }
  console.warn('quoteId cannot be undefined');

  return state;
};

const onQuoteTaskUpdated = (state, payload, meta) => {
  const newTaskId = get(payload, 'value');
  const quoteId = get(meta, 'quoteId');
  const position = get(meta, 'position');
  const newTask = get(meta, 'task');

  if (quoteId && newTask) {
    return state.updateIn(['quotesMap', quoteId], (quote) => ({
      ...quote,
      quoteItemSummaries: {
        ...quote.quoteItemSummaries,
        [position]: {
          id: newTaskId,
          ...newTask,
        },
      },
    }));
  }
  console.warn('QuoteId is missing from the payload', meta);

  return state;
};

const onEmptyQuoteTaskAdded = (state, payload, meta) => {
  const newTaskId = get(payload, 'value');
  const quoteId = get(meta, 'quoteId');

  if (quoteId) {
    return state.updateIn(['quotesMap', quoteId], (quote) => ({
      ...quote,
      // nextPosition: quote.nextPosition + 1,
      quoteItemSummaries: {
        ...quote.quoteItemSummaries,
        [quote.nextPosition - 1]: {
          ...quote.quoteItemSummaries[quote.nextPosition - 1],
          id: newTaskId,
        },
      },
    }));
  }
  console.warn('QuoteId is missing from the payload', meta);

  return state;
};

const onQuoteTaskDeleted = (state, payload, meta) => {
  const quoteId = get(meta, 'quoteId');
  const position = get(meta, 'position');

  if (quoteId) {
    return state.updateIn(['quotesMap', quoteId], (quote) => ({
      ...quote,
      quoteItemSummaries: omit(quote.quoteItemSummaries, position),
    }));
  }
  console.warn('QuoteId is missing from the payload', meta);

  return state;
};

const onDraftIdLoaded = (state, payload, meta) => {
  const fileId = get(meta, 'fileId');
  const quoteId = get(meta, 'quoteId');

  if (fileId) {
    return state.setIn(['draftIds', fileId], quoteId);
  }

  return state;
};

const onCurrentIdLoaded = (state, payload, meta) => {
  const fileId = get(meta, 'fileId');
  const quoteId = get(meta, 'id');

  if (fileId) {
    return state.setIn(['currentQuoteIds', fileId], quoteId);
  }

  return state;
};

// update requested
const onQuoteTaskDeleteRequested = (state, action) => {
  const { payload } = action;
  const quoteId = get(payload, 'quoteId');
  const position = get(payload, 'position');

  if (quoteId) {
    const oldQuoteItem = get(state.getIn(['quotesMap', quoteId]), [
      'quoteItemSummaries',
      position,
    ]);

    return state
      .setIn(['quotesMapOldEntries', quoteId, String(position)], oldQuoteItem)
      .updateIn(['quotesMap', quoteId], (quote) => ({
        ...quote,
        savePending: true,
        quoteItemSummaries: omit(quote.quoteItemSummaries, position),
      }));
  }
  console.warn('QuoteId is missing from the payload', payload);

  return state;
};

const onQuoteTaskUpdateRequested = (state, action) => {
  const { payload } = action;
  const quoteId = get(payload, 'quoteId');
  const position = get(payload, 'position');
  const newTask = get(payload, 'task');

  if (quoteId && newTask) {
    return state.updateIn(['quotesMap', quoteId], (quote) => ({
      ...quote,
      savePending: true,
      quoteItemSummaries: {
        ...quote.quoteItemSummaries,
        [position]: {
          ...quote.quoteItemSummaries[position],
          ...newTask,
        },
      },
    }));
  }
  console.warn('QuoteId is missing from the payload', payload);

  return state;
};

const onEmptyQuoteTaskAddRequested = (state, action) => {
  const { payload } = action;
  const quoteId = get(payload, 'quoteId');

  if (quoteId) {
    return state.updateIn(['quotesMap', quoteId], (quote) => ({
      ...quote,
      savePending: true,
      nextPosition: quote.nextPosition + 1,
      quoteItemSummaries: {
        ...quote.quoteItemSummaries,
        [quote.nextPosition]: {},
      },
    }));
  }
  console.warn('QuoteId is missing from the payload', payload);

  return state;
};

function onAutoSaveEnded(state, action) {
  const quoteId = action.meta;

  return state.updateIn(['quotesMap', quoteId], (quote) => ({
    ...quote,
    savePending: false,
  }));
}

const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_QUOTE_BY_ID.SUCCEEDED:
      return onQuoteLoaded(state, action.payload);

    case ActionTypes.SUBMIT_QUOTE.SUCCEEDED:
      return onQuoteSubmitted(state, action.payload, action.meta);

    case ActionTypes.EDIT_QUOTE_TASK.SUCCEEDED:
      return onQuoteTaskUpdated(state, action.payload, action.meta);

    case ActionTypes.ADD_EMPTY_QUOTE_TASK.SUCCEEDED:
      return onEmptyQuoteTaskAdded(state, action.payload, action.meta);

    case ActionTypes.DELETE_QUOTE_TASK.SUCCEEDED:
      return onQuoteTaskDeleted(state, action.payload, action.meta);

    case ActionTypes.GET_CURRENT_DRAFTED_QUOTE.END:
      return onDraftIdLoaded(state, action.payload, action.meta);

    case ActionTypes.GET_FILE_QUOTES.END:
      return onCurrentIdLoaded(state, action.payload, action.meta);

    case ActionTypes.ADD_EMPTY_QUOTE_TASK.BASE:
      return onEmptyQuoteTaskAddRequested(state, action);
    case ActionTypes.EDIT_QUOTE_TASK.BASE:
      return onQuoteTaskUpdateRequested(state, action);
    case ActionTypes.DELETE_QUOTE_TASK.BASE:
      return onQuoteTaskDeleteRequested(state, action);
    case ActionTypes.QUOTE_TASK_AUTO_SAVE.END:
      return onAutoSaveEnded(state, action);

    default:
      return state;
  }
};

export default quoteReducer;
