import { Cart } from 'iconoir-react';
import React from 'react';

interface CartIconProps {
  color?: string;
  size?: string;
}

const CartIcon: React.FC<CartIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return <Cart width={size} height={size} color={color} />;
};

CartIcon.displayName = 'CartIcon';

export default CartIcon;
