import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  ModelDimension,
  SparePartReturnProcess,
  SparePartStatus,
  SparePartType,
  SpareProductSize,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}SparePart`;

interface UpsertSparePartCommandPayload {
  sellerId: string;
  uniqueName: string;
  manufacturerReference: string;
  manufacturerUniqueName: string;
  ean: string;
  manufacturerLabel: string;
  isOEM: boolean;
  sparePartType: SparePartType;
  status: SparePartStatus;
  category: string;
  subCategory: string;
  size: SpareProductSize;
  dimensions: ModelDimension;
  weight: number;
  isCustomerSaleAllowed: boolean;
  outOfWarrantyReturnProcess: SparePartReturnProcess;
  warrantyReturnProcess: SparePartReturnProcess;
  manufacturerWarranty: boolean;
}

export async function upsertSparePart(
  payload: UpsertSparePartCommandPayload,
): Promise<BaseBackResponse> {
  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
