import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
  DeliveryLocationType,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.sparePart}requests/handling/deliveryLocationAddresses/sellers/${COMMON_QUERY_PARAMS.SELLER_ID}`;

export interface GetDeliveryLocationAddressesQueryArgs {
  sellerId: string;
  deliveryLocationType: DeliveryLocationType;
}

export default async function getDeliveryLocationAddressesQuery({
  sellerId,
  deliveryLocationType,
}: GetDeliveryLocationAddressesQueryArgs): Promise<
  | BackResponse<
      {
        deliveryLocationType: DeliveryLocationType;
        name: string;
        address: AddressInfoDto;
      }[]
    >
  | BackResponseFailure
> {
  try {
    return await apiCall<
      BackResponse<
        {
          deliveryLocationType: DeliveryLocationType;
          name: string;
          address: AddressInfoDto;
        }[]
      >
    >(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId), {
        deliveryLocationType,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
