import {
  RESULTS_COUNT_DISPLAYED,
  SEARCH_HEIGHT,
  SEARCH_RESULT_ORDER,
} from '../Search.contants';
import {
  EmptyResponse,
  isEmptyResponse,
  RESULT_DISPLAY_TYPE,
  SEARCH_ACTION_TYPES,
  SearchAction,
  SearchResult,
  SearchResultGroup,
  SearchResultItem,
  SearchState,
} from '../Search.types';

import distributeResult from './distributeResult';
import generateResultError from './generateResultError';
import generateResultTitle from './generateResultTitle';
import generateShowMoreResult from './generateShowMoreResult';
import getResultListSize from './getResultListSize';
import searchSuperiorIndexToFocus from './searchSuperiorIndexToFocus';

const adaptApiResultToSearchResult = (
  response: SearchResultGroup | EmptyResponse,
  oldResult: SearchResult[],
) => {
  const searchResults = SEARCH_RESULT_ORDER.reduce<SearchResult[]>(
    (resultAccumulator, type) => {
      if (isEmptyResponse(response)) {
        return [...resultAccumulator];
      }

      const {
        totalCount,
        value: incomingResutls,
        isFailure,
        errors,
      } = (response as SearchResultGroup)[type];

      const resultCount = incomingResutls.length;
      const hasMoreResult = resultCount < totalCount;

      const results = [...resultAccumulator];

      if (incomingResutls.length || isFailure) {
        results.push(
          generateResultTitle({
            type,
            animationIndex: results.length,
            count: totalCount,
            isNew: true,
          }),
        );
      }

      if (isFailure) {
        results.push(generateResultError({ type, errors }));

        return results;
      }

      const adaptedResult = incomingResutls.map(
        (result, index): SearchResultItem => ({
          value: {
            name: result.value.name,
            reference: result.value.reference,
            other: result.value.other,
            url: result.value.url,
            status: result.value.status,
          },
          isNew:
            oldResult.length > 0 &&
            oldResult.every(({ key }) => key !== result.key),
          resultType: type,
          key: result.key,
          displayType: RESULT_DISPLAY_TYPE.RESULT,
          animationIndex: index + results.length,
        }),
      );

      results.push(...adaptedResult);

      if (hasMoreResult) {
        results.push(
          generateShowMoreResult({
            animationIndex: results.length,
            type,
            count: totalCount - incomingResutls.length,
            isNew: true,
          }),
        );
      }

      return results;
    },
    [
      {
        displayType: RESULT_DISPLAY_TYPE.CONTROLS,
        animationIndex: 0,
        isNew: true,
        key: 'control',
      },
    ],
  );

  if (
    !searchResults.some(
      ({ displayType }) => displayType === RESULT_DISPLAY_TYPE.RESULT,
    )
  ) {
    return [];
  }

  return searchResults;
};

const shouldShowNoFilters = (state: SearchState) => {
  return state.enabledResultTypes.length === 0;
};
const shouldShowNoResult = (state: SearchState, results: SearchResult[]) => {
  const resultCount = results.filter(
    ({ displayType }) => displayType === RESULT_DISPLAY_TYPE.RESULT,
  ).length;

  const showNoResult = !shouldShowNoFilters(state) && resultCount === 0;

  return showNoResult;
};

const chooseHeight = ({
  state,
  results,
  listSize,
}: {
  state: SearchState;
  results: SearchResult[];
  listSize: number;
}) => {
  if (state.query.length === 0) {
    return 0;
  }

  return shouldShowNoResult(state, results) || shouldShowNoFilters(state)
    ? SEARCH_HEIGHT.NO_RESULT
    : listSize;
};

const buildSetResultsState = (
  state: SearchState,
  action: SearchAction,
): SearchState => {
  if (action.type !== SEARCH_ACTION_TYPES.SET_RESULTS) {
    return state;
  }
  const storedResults = adaptApiResultToSearchResult(
    action.payload.response,
    state.storedResults,
  );

  const results = distributeResult({
    response: action.payload.response,
    numberOfResultToSpread: 8,
  });

  const smartResult = adaptApiResultToSearchResult(results, state.results);
  const showNoFilter = state.enabledResultTypes.length === 0;

  const resultCount = storedResults.filter(
    ({ displayType }) => displayType === RESULT_DISPLAY_TYPE.RESULT,
  ).length;

  const showNoResult = !showNoFilter && resultCount === 0;

  const { indexFocus } = searchSuperiorIndexToFocus({
    results: smartResult,
    indexFocus: 0,
  });

  return {
    ...state,
    isLoading: false,
    results: smartResult,
    storedResults,
    showNoResult,
    showNoFilter,
    indexFocus,
    listHeight: chooseHeight({
      state,
      results: smartResult,
      listSize: getResultListSize(smartResult),
    }),
    containerHeight: chooseHeight({
      state,
      results: smartResult,
      listSize: getResultListSize(smartResult, RESULTS_COUNT_DISPLAYED),
    }),
    resultsAreOpened: state.query.length > 0 ? true : state.resultsAreOpened,
  };
};

export default buildSetResultsState;
