import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Store,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.actor}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/stores`;

export interface GetSellerBySellerIdArgs {
  sellerId: string;
}

const getStoresBySellerId = async (
  payload: GetSellerBySellerIdArgs,
): Promise<BackResponse<Store[]> | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      payload.sellerId,
    );

    const response = await apiCall<BackResponse<Store[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getStoresBySellerId;
