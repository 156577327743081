import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

import { isActionLoadingSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { ControlRootState } from '../ControlRootState';
import { currentOrderModelIdSelector } from '../orders/selectors';

import { GET_MODELS_BY_SEARCH_MODEL_QUERY } from './actionTypes';

const modelsState = (state: ControlRootState) => state.models;

export const selectModels = createSelector(
  [modelsState],
  (state) => state.model,
);

export const selectModelFromCurrentOrder = createSelector(
  [selectModels, currentOrderModelIdSelector],
  (models, modelIds: string[]) => modelIds.map((id) => ({ ...models[id], id })),
);

const selectBySellerState = createSelector(
  [modelsState],
  (state) => state?.bySeller,
);

const selectItemsState = createSelector(
  [modelsState],
  (state) => state?.items || [],
);

export const selectModelIdsBySellerId = createSelector(
  [selectBySellerState, (_: ControlRootState, sellerId: string) => sellerId],
  (bySellerState, sellerId) =>
    bySellerState ? bySellerState[sellerId] || [] : [],
);

export const selectModelsBySellerId = createSelector(
  [selectItemsState, selectModelIdsBySellerId],
  (models, sellerModelIds) => {
    return uniqBy(
      models.filter(({ id }) => sellerModelIds.includes(id)),
      'id',
    );
  },
);

export const selectModelsByQuerySearchIsLoading = createSelector(
  [(state) => state, (_: ControlRootState, index: string) => index],
  (state, index) =>
    isActionLoadingSelector(state, GET_MODELS_BY_SEARCH_MODEL_QUERY, index),
);

export const selectModelInfo = createSelector(
  [modelsState],
  (state) => state.modelInfo,
);
export const selectModelReparability = createSelector(
  [
    selectModels,
    (_state: ControlRootState, { modelId }: { modelId?: string }) => modelId,
  ],
  (models, modelId) => {
    return {
      value: modelId
        ? models[modelId]?.value?.isRepairable || false
        : undefined,
    };
  },
);
