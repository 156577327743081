import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}adapterServiceBusConfigurations/${COMMON_QUERY_PARAMS.SERVICE_BUS_ID}`;

export interface DeleteServiceBusConfigurationArgs {
  serviceBusId: string;
}

export default async function deleteServiceBusConfiguration(
  payload: DeleteServiceBusConfigurationArgs,
): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    const { serviceBusId } = payload;
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SERVICE_BUS_ID,
      serviceBusId,
    );

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.DELETE,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
