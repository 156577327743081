import { CREATE_MODAL_TYPES } from '@savgroup-front-common/constants';

import messages from './messages';

export const createModalAnimationDuration = 300;

export const TITLE_MESSAGES_BY_CREATE_MODAL_TYPE = {
  [CREATE_MODAL_TYPES.FILE_ORDER_REFERENCE]: messages.createFileMenuTitle,
  [CREATE_MODAL_TYPES.FILE_PRODUCT_REFERENCE]: messages.createFileMenuTitle,
  [CREATE_MODAL_TYPES.FILE_RECEIPT_INFORMATION]: messages.createFileMenuTitle,
  [CREATE_MODAL_TYPES.FILE_OWNER_STOCK]: messages.createFileMenuTitle,
  [CREATE_MODAL_TYPES.FILE_BY_CUSTOMER_INFORMATION]:
    messages.createFileMenuTitle,
  [CREATE_MODAL_TYPES.SPARE_PART_REQUEST]: messages.createSparePartMenuTitle,
  [CREATE_MODAL_TYPES.SELLER]: messages.settings,
  [CREATE_MODAL_TYPES.TENANT]: messages.settings,
};
