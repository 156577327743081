import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';
import {
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { ControlRootState } from '../ControlRootState';

import * as types from './actionTypes';
import { INVOICE_DOMAIN } from './constants';
import { FileId, InvoiceId } from './types';

const selectInvoiceDomain = (state: ControlRootState) => state.invoice;

const selectInvoiceList = createSelector(
  [selectInvoiceDomain],
  (state) => state[INVOICE_DOMAIN.LIST],
);

const selectByFileId = createSelector(
  [selectInvoiceDomain],
  (state) => state[INVOICE_DOMAIN.BY_FILE_ID],
);

export const selectInvoiceById = createSelector(
  [
    selectInvoiceList,
    (_: unknown, { invoiceId }: { invoiceId: InvoiceId }) => invoiceId,
  ],
  (invoices, invoiceId: InvoiceId) => invoices[invoiceId] || null,
);
export const selectInvoiceByIds = createSelector(
  [
    selectInvoiceList,
    (_: unknown, { invoiceIds }: { invoiceIds: InvoiceId[] }) => invoiceIds,
  ],
  (invoices, invoiceIds) =>
    invoiceIds
      .map((invoiceId) => invoices[invoiceId] || null)
      .filter((invoice) => invoice !== null),
);

export const selectInvoiceIdsByFileId = createSelector(
  [selectByFileId, (_: unknown, { fileId }: { fileId: FileId }) => fileId],
  (invoiceIdsByFileId, fileId: FileId) => invoiceIdsByFileId[fileId] || [],
);
export const selectInvoicesByFileId = createSelector(
  [selectInvoiceList, selectInvoiceIdsByFileId],
  (invoices, invoiceIds) =>
    invoiceIds
      .map((invoiceId) => invoices[invoiceId] || null)
      .filter((invoice) => invoice !== null),
);

export const selectGetInvoiceByFileIdIsLoading = (
  state: CommonAppState,
  { fileId }: { fileId: FileId },
): boolean =>
  isActionLoadingSelector(state, types.GET_INVOICES_BY_FILE_ID, fileId);

export const selectGetInvoiceByFileIdWasLoaded = (
  state: CommonAppState,
  { fileId }: { fileId: FileId },
): boolean =>
  wasActionLoadedSelector(state, types.GET_INVOICES_BY_FILE_ID, fileId);

export const selectGetInvoiceByFileIdIsReadyToCall = createSelector(
  [selectGetInvoiceByFileIdIsLoading, selectGetInvoiceByFileIdWasLoaded],
  (isLoading, wasLoaded): boolean => {
    return !(isLoading || wasLoaded);
  },
);
