import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OwnerSummary,
} from '@savgroup-front-common/types';

const EMAIL_PARAM = ':email';

const ENDPOINT = `${APIConfiguration.owner}tenants/${COMMON_QUERY_PARAMS.TENANT_ID}/owners/email/${EMAIL_PARAM}`;

interface GetOwnerByEmailPayload {
  email: string;
  tenantId: string;
  sellerId: string;
}

async function getOwnerByEmail({
  tenantId,
  email,
  sellerId,
}: GetOwnerByEmailPayload): Promise<
  BackResponse<OwnerSummary> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.TENANT_ID,
      tenantId,
    ).replace(EMAIL_PARAM, email);

    const response = await apiCall<BackResponse<OwnerSummary>>(
      buildUrl(url, {
        sellerId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getOwnerByEmail;
