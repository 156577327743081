import { createRequest } from '@savgroup-front-common/core/src/services/request';

export enum SPARE_PART_CATALOG_ACTION_TYPE {
  GET_SPARE_PART_CATALOG = 'SPARE_PART_CATALOG/GET_SPARE_PART_CATALOG',
  GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID = 'SPARE_PART_CATALOG/GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID',
}

export const GET_SPARE_PART_CATALOG = createRequest(
  SPARE_PART_CATALOG_ACTION_TYPE.GET_SPARE_PART_CATALOG,
);
export const GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID = createRequest(
  SPARE_PART_CATALOG_ACTION_TYPE.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID,
);
