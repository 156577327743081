import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponseFailure,
  BaseBackResponse,
  DeliveryLocationType,
  ModelDimension,
  RequestLineStatus,
  ReverseMoneyNumberAmount,
  SparePartCondition,
  SpareProductSize,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requests/line/update`;

export interface UpdateRequestLineNewModelCommandPayload {
  requestLineId: string;
  serialNumber: string;
  factoryCode: string;
  deliveryLocationType?: DeliveryLocationType;
  deliveryLocationAddressInfo?: AddressInfoDto;
  intermediateDeliveryLocationType?: DeliveryLocationType;
  intermediateDeliveryLocationAddressInfo?: AddressInfoDto;
  sparePart?: {
    manufacturerReference?: string;
    manufacturer?: string;
    manufacturerUniqueName: string;
    uniqueName?: string;
    manufacturerName?: string;
    ean?: string;
    manufacturerLabel?: string;
    isOEM?: boolean;
    sparePartType?: string;
    status?: string;
    category?: string;
    subCategory?: string;
    size?: SpareProductSize;
    dimensions?: ModelDimension;
    weight?: number;
    isCustomerSaleAllowed?: boolean;
    outOfWarrantyReturnProcess?: string;
    warrantyReturnProcess?: string;
    manufacturerWarranty?: boolean;
    comment?: string;
  };
  sparePartSupplierAssociation?: {
    sparePartId: string;
    supplierId: string;
    supplierPartReference: string;
    supplierPartName: string;
    condition: SparePartCondition;
    lastPretaxPurchasePrice?: ReverseMoneyNumberAmount;
    negotiatedPretaxPurchasePrice?: ReverseMoneyNumberAmount;
    recommendedSalePriceIncludingTaxes?: ReverseMoneyNumberAmount;
    comment?: string;
  };
  requestLineStatus?: RequestLineStatus;
}

export async function updateRequestLineNewModelCommand(
  payload: UpdateRequestLineNewModelCommandPayload,
): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.PUT,
      json: payload,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
