import { createSelector } from 'reselect';

import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';
import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { ControlRootState } from '../ControlRootState';

import * as types from './actionTypes';
import {
  SPARE_PART_SUPPLIER_DOMAIN,
  SPARE_PART_SUPPLIER_DOMAIN_KEY,
} from './constants';
import { SellerId, SparePartSupplierId } from './types';

const selectSparePartSupplierDomain = (state: ControlRootState) =>
  state[SPARE_PART_SUPPLIER_DOMAIN_KEY];

const selectSparePartSupplierList = createSelector(
  [selectSparePartSupplierDomain],
  (state) => state[SPARE_PART_SUPPLIER_DOMAIN.LIST],
);

const selectBySellerId = createSelector(
  [selectSparePartSupplierDomain],
  (state) => state[SPARE_PART_SUPPLIER_DOMAIN.BY_SELLER_ID],
);

export const selectSparePartSupplierById = createSelector(
  [
    selectSparePartSupplierList,
    (
      _: CommonAppState,
      { sparePartSupplierId }: { sparePartSupplierId: SparePartSupplierId },
    ) => sparePartSupplierId,
  ],
  (sparePartSuppliers, sparePartSupplierId: SparePartSupplierId) =>
    sparePartSuppliers[sparePartSupplierId] || null,
);
export const selectSparePartSupplierByIds = createSelector(
  [
    selectSparePartSupplierList,
    (
      _: CommonAppState,
      { sparePartSupplierIds }: { sparePartSupplierIds: SparePartSupplierId[] },
    ) => sparePartSupplierIds,
  ],
  (sparePartSuppliers, sparePartSupplierIds) =>
    sparePartSupplierIds
      .map(
        (sparePartSupplierId) =>
          sparePartSuppliers[sparePartSupplierId] || null,
      )
      .filter((sparePartSupplier) => sparePartSupplier !== null),
);

const selectSparePartSupplierIdsBySellerIdAndTenantId = createSelector(
  [
    selectBySellerId,
    (_: unknown, { sellerId }: { sellerId?: SellerId; tenantId?: string }) =>
      sellerId,
    (_: unknown, { tenantId }: { sellerId?: SellerId; tenantId?: string }) =>
      tenantId,
  ],
  (sparePartSupplierIdsBySellerId, sellerId?: SellerId, tenantId?: string) => [
    ...(sellerId ? sparePartSupplierIdsBySellerId[sellerId] || [] : []),
    ...(tenantId ? sparePartSupplierIdsBySellerId[tenantId] || [] : []),
  ],
);

export const selectSparePartSuppliersBySellerIdAndTenantId = createSelector(
  [
    selectSparePartSupplierList,
    selectSparePartSupplierIdsBySellerIdAndTenantId,
  ],
  (sparePartSuppliers, sparePartSupplierIds) =>
    sparePartSupplierIds
      .map(
        (sparePartSupplierId) =>
          sparePartSuppliers[sparePartSupplierId] || null,
      )
      .filter((sparePartSupplier) => sparePartSupplier !== null),
);

export const selectGetSparePartSupplierBySellerIdIsLoading = (
  state: CommonAppState,
  { sellerId }: { sellerId: SellerId },
): boolean =>
  isActionLoadingSelector(
    state,
    types.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID,
    sellerId,
  );

export const selectGetSparePartSupplierBySellerIdWasLoaded = (
  state: CommonAppState,
  { sellerId }: { sellerId?: SellerId },
): boolean =>
  sellerId
    ? wasActionLoadedSelector(
        state,
        types.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID,
        sellerId,
      )
    : false;
export const selectGetSparePartSupplierBySellerIdErrors = (
  state: CommonAppState,
  { sellerId }: { sellerId: SellerId },
): ErrorFromBack[] =>
  getActionErrorsSelector(
    state,
    types.GET_SPARE_PART_SUPPLIER_BY_SELLER_ID,
    sellerId,
  );
