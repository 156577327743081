import {
  APP_COMPONENT_STATUSES,
  AppComponent,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';

import adaptRawAppComponentsToAppComponents from './adapters/adaptRawAppComponentsToAppComponents';
import STATUS_PAGE_URL from './config';

export interface RawAppComponentsResponse {
  page: {
    id: string;
    name: string;
    url: string;
    updated_at: string;
  };
  components: {
    created_at: string;
    description: string | null;
    group: boolean;
    group_id: string | null;
    id: string;
    name: string;
    only_show_if_degraded: boolean;
    page_id: string;
    position: number;
    showcase: boolean;
    start_date: string;
    status: APP_COMPONENT_STATUSES;
    updated_at: string;
  }[];
}

const getAppComponents = async (): Promise<
  BackResponse<AppComponent[]> | BackResponseFailure
> => {
  try {
    const ENDPOINT = `${STATUS_PAGE_URL}/api/v2/components.json`;

    const response = await fetch(ENDPOINT);

    const rawUnresolvedIncidentsResponse =
      (await response.json()) as RawAppComponentsResponse;

    const returnValue: BackResponse<AppComponent[]> = {
      failure: false,
      isHandledError: false,
      hasWarnings: false,
      isNotFound: false,
      errors: [],
      value: adaptRawAppComponentsToAppComponents(
        rawUnresolvedIncidentsResponse.components,
      ),
    };

    return returnValue;
  } catch (e) {
    return prepareResponseFailure(e);
  }
};

export default getAppComponents;
