import { PERMISSIONS } from '@savgroup-front-common/constants';
import { useHasPermission } from '@savgroup-front-common/core/src/hooks';
import useHasSellerSettingsPagePermission from 'control/view/components/Settings/SellerSettings/SellerSettings.hooks';

const useHasSettingsPagePermission = () => {
  const hasSellerSettingsPagePermission = useHasSellerSettingsPagePermission();
  const hasUserSettingsPermission = useHasPermission(
    PERMISSIONS.ROLE_MANAGEMENT,
  );
  const hasSanityTestPermission = useHasPermission(PERMISSIONS.SANITY_TESTS);
  const hasImportsPermission = useHasPermission(PERMISSIONS.IMPORT_MANAGEMENT);
  const hasManageSparePartCatalog =
    useHasPermission(PERMISSIONS.MANAGE_SPARE_PART_CATALOG);

  return {
    hasSellerSettingsPagePermission,
    hasUserSettingsPermission,
    hasSanityTestPermission,
    hasImportsPermission,
    hasManageSparePartCatalog,
  };
};

export default useHasSettingsPagePermission;
