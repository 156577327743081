import { SearchResult } from '../Search.types';

import getNextSearchFocus from './getNextSearchFocus';

interface SearchInferiorIndexToFocusArgs {
  results: SearchResult[];
  indexFocus?: number;
}

const searchInferiorIndexToFocus = ({
  results,
  indexFocus = 0,
}: SearchInferiorIndexToFocusArgs): { indexFocus: number } => {
  for (let index = results.length - 1; index >= 0; index -= 1) {
    const result = getNextSearchFocus({ results, index, indexFocus });

    if (result !== undefined) {return { indexFocus: result };}
  }

  return { indexFocus };
};

export default searchInferiorIndexToFocus;
