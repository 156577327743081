import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { BaseBackResponse } from '@savgroup-front-common/types';

const URL = `${APIConfiguration.claim}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/claimGroups`;

type ClaimGroupId = string;

interface CreateClaimGroupFromOrder {
  orderId: string;
  orderLineReferences: string[];
  externalCaseId?: string;
}

interface Response extends BaseBackResponse {
  value: ClaimGroupId;
}

async function createClaimGroupFromOrder({
  orderId,
  orderLineReferences,
  externalCaseId,
}: CreateClaimGroupFromOrder): Promise<ClaimGroupId> {
  if (!orderId && !orderLineReferences) {
    throw new RequiredPayloadFieldIsNotProvided(
      JSON.stringify({ orderId, orderLineReferences }),
      `createClaimGroupFromOrder`,
    );
  }

  const response = await apiCall<Response>(
    URL.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId),
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        orderLineReferences,
        externalCaseId,
      },
    },
  );

  if (response.failure) {
    throw new Error(`Got an exception trying to createClaimGroupFromOrder`);
  }

  return response.value;
}

export default createClaimGroupFromOrder;
