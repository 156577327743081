import { createSelector } from 'reselect';

import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { ControlRootState } from '../ControlRootState';

import { GET_DRAFT_HISTORY } from './actionTypes';
import { IMPORT_DOMAIN, IMPORT_DOMAIN_KEY } from './constants';

const importState = (state: ControlRootState) => state[IMPORT_DOMAIN_KEY];

export const selectDraftHistoryBySellerId = createSelector(
  [
    importState,
    (_: ControlRootState, { sellerId }: { sellerId: string }) => sellerId,
  ],
  (state, sellerId) => {
    return state[IMPORT_DOMAIN.DRAFT_HISTORY][sellerId] || [];
  },
);

export const selectGetDraftHistoryBySellerIdIsLoading = (
  state: ControlRootState,
  { sellerId }: { sellerId: string },
) => isActionLoadingSelector(state, GET_DRAFT_HISTORY, sellerId);

export const selectGetDraftHistoryBySellerIdWasLoaded = (
  state: ControlRootState,
  { sellerId }: { sellerId: string },
) => wasActionLoadedSelector(state, GET_DRAFT_HISTORY, sellerId);

export const selectGetDraftHistoryBySellerIdErrors = (
  state: ControlRootState,
  { sellerId }: { sellerId: string },
): any[] => getActionErrorsSelector(state, GET_DRAFT_HISTORY, sellerId);
