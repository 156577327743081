import PropTypes from 'prop-types';
import React from 'react';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';

import messages from './messages';

const ModalHeader = ({ data }) => (
  <Heading level={2}>
    <SafeFormattedMessage {...messages[data]} />
  </Heading>
);

ModalHeader.propTypes = {
  data: PropTypes.string.isRequired,
};

export default ModalHeader;
