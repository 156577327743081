import { FILTER_FILES_ORDER } from '@savgroup-front-common/constants';

interface PartialRubric {
  name: string;
}

const sortFileRubric = (a: PartialRubric, b: PartialRubric) => {
  return (
    FILTER_FILES_ORDER.indexOf(a.name) - FILTER_FILES_ORDER.indexOf(b.name)
  );
};

export default sortFileRubric;
