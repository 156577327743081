import { defineMessages } from 'react-intl';

export default defineMessages({
  seemore: {
    id: 'view.appStatus.seeMore',
    defaultMessage: 'See more',
  },
  scheduledUntil: {
    id: 'view.appStatus.sheduledMaintenance.scheduledUtil',
    defaultMessage: 'End: {time}',
  },
});
