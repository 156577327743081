import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

interface AddFileToDraftArgs {
  draftId: string;
  file?: File;
}

interface AddFileToDraftValue {
  draftId: string;
}

interface AddFileToDraftResponse extends BaseBackResponse {
  value: AddFileToDraftValue;
}

const ENDPOINT = `${APIConfiguration.import}drafts/${COMMON_QUERY_PARAMS.DRAFT_ID}/files/${COMMON_QUERY_PARAMS.FILE_NAME}`;

export default async function addFileToDraft(
  payload: AddFileToDraftArgs,
): Promise<AddFileToDraftValue> {
  const { file, draftId } = payload;

  if (!file) {
    throw new Error(`No file sent`);
  }
  const fileName = file?.name;
  const data = new FormData();

  data.append(file.name, file);
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.DRAFT_ID, draftId).replace(
    COMMON_QUERY_PARAMS.FILE_NAME,
    fileName,
  );
  const response = await apiCall<AddFileToDraftResponse>(url, {
    method: SUPPORTED_METHODS.POST,
    body: data,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to add file to draft`);
  }

  return response.value;
}
