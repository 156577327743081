import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/control-attachments/fromUploadLink`;

interface CreateFileAttachmentsFromControlCommandArgs {
  fileId: string;
  token: string;
  fileAttachmentIds: string[];
}

const createFileAttachmentsFromControlCommand = async ({
  fileId,
  token,
  fileAttachmentIds,
}: CreateFileAttachmentsFromControlCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.POST,
        json: { token, fileAttachmentIds },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default createFileAttachmentsFromControlCommand;
