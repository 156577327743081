import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import {
  ActionCreators as AttachmentsActionCreators,
  ActionTypes as AttachmentsActionTypes,
  Constants as AttachmentsConstants,
} from '@savgroup-front-common/core/src/domains/attachments';
import { loadTranslation } from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';
import { selectAttachmentsByFileId } from 'control/domains/attachments/selectors';

import * as ActionCreators from './actionCreators';
import * as ActionTypes from './actionTypes';
import * as Selectors from './selectors/selectors';

export function* loadOwnerProductFilesWorker({ payload: ownerProductId }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_OWNER_PRODUCT_FILES,
    `${APIConfiguration.workflow}ownerproducts/${ownerProductId}/files`,
    { method: SUPPORTED_METHODS.GET },
    { productId: ownerProductId },
  );

  const ownerProductsMap = yield select(Selectors.ownerProductsMap);
  const files = map(
    get(ownerProductsMap, ownerProductId),
    (file) => find(get(file, ['fileProducts']), { ownerProductId }) || {},
  );

  for (const { issueId, reasonId, warrantyTypeId } of files) {
    if (issueId || reasonId || warrantyTypeId) {
      yield put(
        loadTranslation({
          issueId,
          reasonId,
          warrantyTypeId,
          ownerProductId,
        }),
      );
    }
  }

  yield put(ActionCreators.loadOwnerProductFilesEnd());
}
export function* loadOwnerProductFilesWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_OWNER_PRODUCT_FILES.BASE,
    loadOwnerProductFilesWorker,
  );
}

export function* loadSellerInfoWorker({ payload: sellerId }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_SELLER_INFO,
    `${APIConfiguration.config}sellers/byid/${sellerId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
  yield put(ActionTypes.LOAD_SELLER_INFO.end());
}
export function* loadSellerInfoWatcher() {
  yield takeEvery(ActionTypes.LOAD_SELLER_INFO.BASE, loadSellerInfoWorker);
}

function* loadCustomerFilesSummaryWorker({ payload: fileIds }) {
  const filteredFileIds = fileIds.filter((id) => !!id);

  if (filteredFileIds.length) {
    yield call(
      callAndGetResponse,
      ActionTypes.LOAD_CUSTOMER_FILES_SUMMARY,
      buildUrl(
        `${APIConfiguration.workflow}files/customerFile/summaries`,
        { fileIds: filteredFileIds },
        { arrayFormat: 'bracket' },
      ),
      { method: SUPPORTED_METHODS.GET },
    );
  }
  yield put(ActionTypes.LOAD_CUSTOMER_FILES_SUMMARY.end());
}
export function* loadCustomerFilesSummaryWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_CUSTOMER_FILES_SUMMARY.BASE,
    loadCustomerFilesSummaryWorker,
  );
}

function* editFileAddressWorker({ payload: { fileId, address, method } }) {
  yield call(
    callAndGetResponse,
    ActionTypes.EDIT_FILE_ADDRESS,
    `${APIConfiguration.workflow}files/customerfile/${fileId}/addresses/${method}`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        addressInfo: address,
      },
    },
  );
  yield put(ActionTypes.EDIT_FILE_ADDRESS.end());
}
function* editFileAddressWatcher() {
  yield takeEvery(ActionTypes.EDIT_FILE_ADDRESS.BASE, editFileAddressWorker);
}

function* uploadFileAttachmentWorker({
  payload: { file, fileId, fileButtonOrigin },
}) {
  yield put(
    AttachmentsActionCreators.uploadFileAttachment({
      file,
      fileId,
      service: AttachmentsConstants.attachmentsListOrigins.CONTROL,
      fileButtonOrigin,
    }),
  );
  const {
    meta: { id, extension, fileName },
  } = yield take(AttachmentsActionTypes.UPLOAD_FILE_ATTACHMENT_DATA.END);
  const attachments = yield select(selectAttachmentsByFileId, { fileId });

  yield put(
    AttachmentsActionCreators.setFileAttachmentInfo({
      fileId,
      extension,
      name: fileName,
      fileAttachmentId: get(
        attachments.getIn([id, 'value']),
        'fileAttachmentId',
      ),
      service: AttachmentsConstants.attachmentsListOrigins.CONTROL,
      transitionInfo: null,
    }),
  );

  yield take(AttachmentsActionTypes.SET_FILE_ATTACHMENT_INFO.SUCCEEDED);

  yield put(
    AttachmentsActionCreators.loadFilesAttachmentList({
      fileId,
      type: AttachmentsConstants.attachmentsListOrigins.CONTROL,
    }),
  );
}
function* uploadFileAttachmentWatcher() {
  yield takeEvery(
    ActionTypes.UPLOAD_FILE_ATTACHMENT,
    uploadFileAttachmentWorker,
  );
}

export default function* fileSaga() {
  try {
    yield all([
      loadOwnerProductFilesWatcher(),
      loadSellerInfoWatcher(),
      loadCustomerFilesSummaryWatcher(),
      editFileAddressWatcher(),
      uploadFileAttachmentWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
