import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  STOCK_ITEM_STATUS,
  StockItemId,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}stockitem/update`;

export interface BaseUpdateStockItemArgs {
  stockItemId: StockItemId;
  stockItemStatus?: STOCK_ITEM_STATUS;
  stockItemLocation?: string;
}

export default async function updateStockItem(
  payload: BaseUpdateStockItemArgs,
): Promise<void> {
  const response = await apiCall<BaseBackResponse>(ENDPOINT, {
    method: SUPPORTED_METHODS.PUT,
    json: payload,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to updateSparePartRequestLine`);
  }
}
