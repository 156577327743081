import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

interface GetDraftDocumentArgs {
  draftId: string;
  token: string;
}

interface GetDraftDocumentResponse extends BaseBackResponse {
  value: string;
}

const TOKEN = ':token';

const ENDPOINT = `${APIConfiguration.import}drafts/${COMMON_QUERY_PARAMS.DRAFT_ID}/files/token/${TOKEN}`;

export default async function getDraftDocument(
  payload: GetDraftDocumentArgs,
): Promise<string> {
  const { draftId, token } = payload;

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.DRAFT_ID, draftId).replace(
    TOKEN,
    token,
  );

  const response = await apiCall<GetDraftDocumentResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to get draft document`);
  }

  return response.value;
}
