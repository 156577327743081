import { createAction } from '@reduxjs/toolkit';

import {
  OnFacetChangeArgs,
  OnFacetLockArgs,
  OnMultipleFacetChangeArgs,
} from '@savgroup-front-common/types';

import {
  ALERTS_LIST_CHANGE_FILTER,
  ALERTS_LIST_CHANGE_MULTPLE_FILTER,
  ALERTS_LIST_CHANGE_SEARCH_QUERY,
  ALERTS_LIST_LOCK_FILTER,
  ALERTS_LIST_RESET_FILTERS,
  ALERTS_LIST_RESET_FILTERS_AND_LOCK,
  ALERTS_LIST_SELECT_PAGE,
  ALERTS_SEARCH,
  REHYDRATE_ALERTS_SEARCH,
  REORDER_ALERTS_LIST_FILTERS,
  TOGGLE_ALERTS_LIST_RESULTS_ORDER,
} from './actionTypes';

export const searchAlertsList = createAction(ALERTS_SEARCH.BASE);

export const changeAlertsListFilter = createAction<OnFacetChangeArgs>(
  ALERTS_LIST_CHANGE_FILTER.BASE,
);

export const changeMultipleAlertsListFilter =
  createAction<OnMultipleFacetChangeArgs>(
    ALERTS_LIST_CHANGE_MULTPLE_FILTER.BASE,
  );

export const lockAlertsListFilter = createAction<OnFacetLockArgs>(
  ALERTS_LIST_LOCK_FILTER.BASE,
);

export interface ChangeAlertsListSearchQueryPayload {
  query: string;
}
export const changeAlertsListSearchQuery =
  createAction<ChangeAlertsListSearchQueryPayload>(
    ALERTS_LIST_CHANGE_SEARCH_QUERY.BASE,
  );
export const toggleAlertsListSearchOrder = createAction<void>(
  TOGGLE_ALERTS_LIST_RESULTS_ORDER.BASE,
);

export interface ChangeAlertsListPagePayload {
  page: number;
}
export const changeAlertsListPage = createAction<ChangeAlertsListPagePayload>(
  ALERTS_LIST_SELECT_PAGE.BASE,
);
export const reorderAlertsListFilters = createAction<void>(
  REORDER_ALERTS_LIST_FILTERS.BASE,
);
export const resetAlertsListFilters = createAction<void>(
  ALERTS_LIST_RESET_FILTERS.BASE,
);
export const resetAlertsListLocksAndFilters = createAction<void>(
  ALERTS_LIST_RESET_FILTERS_AND_LOCK.BASE,
);
export const rehydrateAlertsListSearch = createAction<void>(
  REHYDRATE_ALERTS_SEARCH.BASE,
);
