import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

import { SEARCH_HEIGHT } from '../../../Search.contants';
import { SimpleAnimationProps } from '@savgroup-front-common/core/src/animations/simpleAnimation';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';

export const $ResultTitle = styled.h3<SimpleAnimationProps>`
  font-size: 1rem;
  margin: 0;
  top: 0;
  position: absolute;
  width: 100%;
  padding: 0 1rem;
  height: ${rem(SEARCH_HEIGHT.TITLE)};
  line-height: calc(${rem(SEARCH_HEIGHT.TITLE)} - 0.5rem);
  ${decelerateTimingFunctionTransition};
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  padding-top: 0.5rem;
`;
