import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const LOAD_MARKETPLACE_ORDERS = createRequest(
  'ORDERS/LOAD_MARKETPLACE_ORDERS',
);
export const LOAD_MARKETPLACE_DETAILED_ORDER = createRequest(
  'ORDERS/LOAD_MARKETPLACE_DETAILED_ORDER',
);
export const LOAD_FAILED_ORDERS = createRequest('ORDERS/LOAD_FAILED_ORDERS');

export const UPDATE_ORDERS_SEARCH_QUERY = createRequest(
  'UPDATE_ORDERS_SEARCH_QUERY',
);
