import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  ENTITY_TYPE,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.payment}payment/validate/${COMMON_QUERY_PARAMS.ENTITY_TYPE}/${COMMON_QUERY_PARAMS.ENTITY_ID}`;

export interface ValidatePaymentCommandArgs {
  entityId: string;
  entityType: ENTITY_TYPE;
  paymentMethod: string;
  amountIncludingVat: number;
  paymentReference?: string;
}

const validatePaymentCommand = async (
  payload: ValidatePaymentCommandArgs,
): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.ENTITY_TYPE,
      payload.entityType,
    ).replace(COMMON_QUERY_PARAMS.ENTITY_ID, payload.entityId);

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        paymentMethod: payload.paymentMethod,
        amountIncludingVat: payload.amountIncludingVat,
        paymentReference: payload?.paymentReference,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default validatePaymentCommand;
