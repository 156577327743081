import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  File,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.search}files`;

interface GetFilesByQueryPayload {
  hits: File[];
  hitCount: number;
}
interface GetFilesByQueryArgs {
  query: string;
  page?: number;
  pageSize?: number;
  isDescending: boolean;
  sort?: SORT_TYPES;
}

async function getFilesByQuery({
  query = '',
  page = 1,
  pageSize = 10,
  ...rest
}: GetFilesByQueryArgs): Promise<
  BackResponse<GetFilesByQueryPayload> | BackResponseFailure
> {
  try {
    const url = buildUrl(ENDPOINT, {
      page,
      query,
      pageSize,
      ...rest,
    });
    const response = await apiCall<BackResponse<GetFilesByQueryPayload>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFilesByQuery;
