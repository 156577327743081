import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const PdfIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"
      fill="currentColor"
    />
  </Icon>
);

PdfIcon.displayName = 'PdfIcon';
export default PdfIcon;
