import { PERMISSIONS } from '@savgroup-front-common/constants';
import { AssignedSites } from '@savgroup-front-common/types';

export enum USER_MANAGEMENT_DOMAIN {
  CONFIGURATION_BY_USER = 'configurationByUser',
  ASSIGNABLE_USER_PROFILES = 'assignableUserProfiles',
  ASSIGNABLE_USER_ROLES = 'assignableUserRoles',
  ASSIGNABLE_USER_PERMISSIONS = 'assignableUserPermissions',
}

export interface AssignableUserProfile {
  userProfileId: string;
  name: string;
  tenantId: string;
  roleIds: string[];
  cumulDashboardIds: string[];
  permissions: PERMISSIONS[];
}
export interface AssignableUserPermission {
  shortName: string;
  description: string;
}
export interface AssignableUserRole {
  sellerId: string;
  sellerName: string;
  roles: {
    id: string;
    name: string;
    description: string;
  }[];
}

export interface UserRoleOrUserPermission {
  roleId: string;
  builtInRole: string;
  entityType?: string;
  entityId: string;
}

export interface UserSitesPermission {
  id: string;
  type: string;
}

export interface UserConfiguration {
  userId: string;
  lastname: string;
  firstname: string;
  credentials: UserRoleOrUserPermission[];
  assignedSites?: AssignedSites;
  mail: string;
}

export interface UserManagmentDomainState {
  [USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER]: UserConfiguration[];
  [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_ROLES]: AssignableUserRole[];
  [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES]: AssignableUserProfile[];
  [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PERMISSIONS]: AssignableUserPermission[];
}
