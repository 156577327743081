import {
  FilterCategory,
  OnFacetChangeArgs,
} from '@savgroup-front-common/types';

import createNewFacet from './createNewFacet';

const injectNewFacetInNewRubric = (
  oldRubrics: FilterCategory[],
  payload: OnFacetChangeArgs,
): FilterCategory[] => {
  return [
    ...oldRubrics,
    {
      rubricName: payload.rubricName,
      rubricLabel: payload.rubricName,
      values: [createNewFacet(payload)],
    },
  ];
};

export default injectNewFacetInNewRubric;
