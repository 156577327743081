import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { getCountryTranslationSelector } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { AddressInfoDto } from '@savgroup-front-common/types';

import { ADDRESS_SUGGESTION_ORIGIN } from '../../AddressSuggestion.types';
import { useAddressSuggestionStepContext } from '../../hooks';

import { $AddressError } from './EditAddressChoiceFormatter.styles';

const GessHighlight: FunctionComponent<
  React.PropsWithChildren<{
    value: string;
    highlight: string;
    highlights: string[];
  }>
> = ({ value, highlight, highlights }) => {
  const adaptedValue = value.trim();

  if (!adaptedValue) {
    return null;
  }

  if (highlights.includes(highlight)) {
    return <$AddressError>{adaptedValue}</$AddressError>;
  }

  return <>{adaptedValue}</>;
};

GessHighlight.displayName = 'GessHighlight';

const EditAddressChoiceFormatter: FunctionComponent<
  React.PropsWithChildren<{
    datas: AddressInfoDto;
    value: ADDRESS_SUGGESTION_ORIGIN;
  }>
> = ({ datas, value }) => {
  const getCountry = useSelector(getCountryTranslationSelector);

  const {
    context: { unmatchingFields },
  } = useAddressSuggestionStepContext();

  const highlights =
    value === ADDRESS_SUGGESTION_ORIGIN.SUGGESTION ? unmatchingFields : [];

  const {
    address = '',
    additionalAddress = '',
    postalCode = '',
    city = '',
    countryCode = '',
    companyName = '',
    firstname = '',
    lastname = '',
  } = datas;

  const fullname = [firstname.trim(), lastname.trim()]
    .filter((v) => v)
    .join(' ')
    .trim();

  return (
    <>
      <div>{fullname}</div>
      <div>{companyName}</div>
      <div>
        <GessHighlight
          value={address}
          highlight="address"
          highlights={highlights}
        />{' '}
        <GessHighlight
          highlights={highlights}
          value={additionalAddress}
          highlight="additionalAddress"
        />
      </div>
      <div>
        <GessHighlight
          value={postalCode}
          highlight="postalCode"
          highlights={highlights}
        />{' '}
        <GessHighlight value={city} highlight="city" highlights={highlights} />{' '}
        <GessHighlight
          value={getCountry(countryCode)}
          highlight="countryCode"
          highlights={highlights}
        />
      </div>
    </>
  );
};

EditAddressChoiceFormatter.displayName = 'EditAddressChoiceFormatter';

export default EditAddressChoiceFormatter;
