import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

interface BookPlusProps extends IconPropsInterface {
  strokeColor: string;
}

const BookPlus: FunctionComponent<BookPlusProps> = ({
  size = '24px',
  strokeColor,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={strokeColor}
  >
    <path
      d="M19.9999 35V11.6667M19.9999 11.6667C19.9999 10.7826 20.3511 9.93476 20.9762 9.30964C21.6013 8.68452 22.4492 8.33333 23.3333 8.33333H24M19.9999 11.6667C19.9999 10.7826 19.6487 9.93476 19.0236 9.30964C18.3985 8.68452 17.5506 8.33333 16.6666 8.33333H4.33325C4.06804 8.33333 3.81368 8.43869 3.62615 8.62622C3.43861 8.81376 3.33325 9.06811 3.33325 9.33333V31.19M36.6666 31.19V21.5M23.3333 31.6667H36.6666M16.6666 31.6667H3.33325"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M19.9998 35C19.9998 34.1159 20.351 33.2681 20.9761 32.643C21.6013 32.0178 22.4491 31.6667 23.3332 31.6667M19.9998 35C19.9998 34.1159 19.6486 33.2681 19.0235 32.643C18.3984 32.0178 17.5506 31.6667 16.6665 31.6667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 13H33M33 13H38M33 13V8M33 13V18"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

BookPlus.displayName = 'BookPlus';

export default BookPlus;
