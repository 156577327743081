import { createRequest } from '@savgroup-front-common/core/src/services/request';

export enum STOCK_ITEM_LIST_ACTION_TYPE {
  GET_STOCK_ITEM_LIST = 'STOCKS/STOCK_ITEM_LIST/GET_STOCK_ITEM_LIST',
  SET_STOCK_ITEM_LIST_QUERY = 'STOCKS/STOCK_ITEM_LIST/SET_STOCK_ITEM_LIST_QUERY',
  SET_STOCK_ITEM_LIST_IS_DESCENDING = 'STOCKS/STOCK_ITEM_LIST/SET_STOCK_ITEM_LIST_IS_DESCENDING',
  SET_STOCK_ITEM_LIST_FILTERS = 'STOCKS/STOCK_ITEM_LIST/SET_STOCK_ITEM_LIST_FILTERS',
  UPDATE_STOCK_ITEM_LIST_ITEM_STATUS = 'STOCKS/STOCK_ITEM_LIST/UPDATE_STOCK_ITEM_LIST_ITEM_STATUS',
  UPDATE_STOCK_ITEM_LIST_ITEM = 'STOCKS/STOCK_ITEM_LIST/UPDATE_STOCK_ITEM_LIST_ITEM',
  RESET_STOCK_ITEM_LIST_FILTERS = 'STOCKS/STOCK_ITEM_LIST/RESET_STOCK_ITEM_LIST_FILTERS',
}

export const GET_STOCK_ITEM_LIST = createRequest(
  STOCK_ITEM_LIST_ACTION_TYPE.GET_STOCK_ITEM_LIST,
);

export const SET_STOCK_ITEM_LIST_QUERY = createRequest(
  STOCK_ITEM_LIST_ACTION_TYPE.SET_STOCK_ITEM_LIST_QUERY,
);

export const SET_STOCK_ITEM_LIST_IS_DESCENDING = createRequest(
  STOCK_ITEM_LIST_ACTION_TYPE.SET_STOCK_ITEM_LIST_IS_DESCENDING,
);

export const SET_STOCK_ITEM_LIST_FILTERS = createRequest(
  STOCK_ITEM_LIST_ACTION_TYPE.SET_STOCK_ITEM_LIST_FILTERS,
);

export const UPDATE_STOCK_ITEM_LIST_ITEM_STATUS = createRequest(
  STOCK_ITEM_LIST_ACTION_TYPE.UPDATE_STOCK_ITEM_LIST_ITEM_STATUS,
);

export const RESET_STOCK_ITEM_LIST_FILTERS = createRequest(
  STOCK_ITEM_LIST_ACTION_TYPE.RESET_STOCK_ITEM_LIST_FILTERS,
);

export const UPDATE_STOCK_ITEM_LIST_ITEM = createRequest(
  STOCK_ITEM_LIST_ACTION_TYPE.UPDATE_STOCK_ITEM_LIST_ITEM,
);
