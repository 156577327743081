import { SEARCH_RESULT_ORDER } from '../Search.contants';
import {
  EmptyResponse,
  isEmptyResponse,
  SearchApiResult,
  SearchResultGroup,
} from '../Search.types';

interface DistributeResultArgs {
  response: SearchResultGroup | EmptyResponse;
  numberOfResultToSpread?: number;
}

const distributeResult = ({
  response,
  numberOfResultToSpread = 8,
}: DistributeResultArgs): {
  order: SearchApiResult;
  file: SearchApiResult;
  sparePartRequest: SearchApiResult;
  stockItem: SearchApiResult;
} => {
  const { results } = Array(numberOfResultToSpread)
    .fill(null)
    .reduce(
      (acc) => {
        if (acc.noMoreResult) {
          return acc;
        }
        let result;
        let type = SEARCH_RESULT_ORDER[acc.catIndex];
        let { catIndex } = acc;
        let { resultIndex } = acc;

        let countLoop = 0;

        while (!result && countLoop < SEARCH_RESULT_ORDER.length) {
          if (isEmptyResponse(response)) {
            return { ...acc, noMoreResult: true };
          }

          const { isFailure } = response[type];

          const nextWillLoop = catIndex + 1 === SEARCH_RESULT_ORDER.length;

          const { value } = response[type];

          result = isFailure ? undefined : value?.[resultIndex];

          countLoop += 1;

          if (!result) {
            type = SEARCH_RESULT_ORDER[nextWillLoop ? 0 : catIndex + 1];
          }
          catIndex = nextWillLoop ? 0 : catIndex + 1;
          resultIndex = nextWillLoop ? resultIndex + 1 : resultIndex;
        }

        if (!result) {
          return { ...acc, noMoreResult: true };
        }

        return {
          catIndex,
          resultIndex,
          results: {
            ...acc.results,
            [type]: {
              ...acc.results[type],
              value: [...acc.results[type].value, result],
            },
          },
        };
      },
      {
        results: {
          order: {
            ...response.order,
            value: [],
          },
          file: {
            ...response.file,
            value: [],
          },
          sparePartRequest: {
            ...response.sparePartRequest,
            value: [],
          },
          stockItem: {
            ...response.stockItem,
            value: [],
          },
        },
        noMoreResult: false,
        catIndex: 0,
        resultIndex: 0,
      },
    );

  SEARCH_RESULT_ORDER.reduce((acc, type) => {
    if (
      response[type].totalCount === response[type].value.length &&
      response[type].value.length - acc[type].value.length === 1
    ) {
      return {
        ...acc,
        [type]: {
          ...acc[type],
          value: response[type].value,
        },
      };
    }

    return acc;
  }, results);

  if (
    response.order.totalCount === response.order.value.length &&
    response.order.value.length - results.order.value.length === 1
  ) {
    results.order.value = response.order.value;
  }

  return results;
};

export default distributeResult;
