import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  API_COMMON_ERROR,
  COMMON_QUERY_PARAMS,
} from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/sales/files`;

export interface ImportReportSummaryDto {
  importId: string;
  createdDate: string;
  imported: number;
  notImported: number;
  errors: number;
}

interface ImportSalesCommandPayload {
  sellerId: string;
  file: File;
}

type ImportSalesCommand = (
  payload: ImportSalesCommandPayload,
) => Promise<BackResponse<ImportReportSummaryDto> | BackResponseFailure>;

const importSalesCommand: ImportSalesCommand = async ({ sellerId, file }) => {
  const body = new FormData();

  if (file) {
    body.append(file.name, file);
  }

  if (file && (file.name === 'undefined' || file.name === undefined)) {
    return prepareResponseFailure({
      errors: [{ code: API_COMMON_ERROR.UNHANDLED }],
    });
  }

  try {
    const response = await apiCall<BackResponse<ImportReportSummaryDto>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
      {
        method: SUPPORTED_METHODS.POST,
        body,
      },
    );

    if (response.statusCode === 401) {
      return prepareResponseFailure({
        errors: [{ code: API_COMMON_ERROR.UNHANDLED }],
      });
    }

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default importSalesCommand;
