import get from 'lodash/get';
import { createSelector } from 'reselect';

import { OwnerSummary } from '@savgroup-front-common/types';

import { ControlRootState } from '../ControlRootState';

const selectOwnerState = (state: ControlRootState) => state.owner;

export const selectOwnerById = createSelector(
  [
    selectOwnerState,
    (_state: ControlRootState, { ownerId }: { ownerId?: string }) => ownerId,
  ],
  (state, ownerId): OwnerSummary | undefined => {
    return ownerId ? get(state.owners, [ownerId, 'value']) : undefined;
  },
);
