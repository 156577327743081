import { useEffect } from 'react';

import { APP_ENVS } from '@savgroup-front-common/constants/src/shared';

import useThemedEnv from '../../../components/common/hooks/useThemedEnv';

const useEnvFavicon = (): void => {
  const env = useThemedEnv();

  useEffect(() => {
    const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");

    if (link) {
      if (window.location.href.includes('localhost')) {
        link.href = `/favicon-localhost.ico`;

        return;
      }
      switch (env) {
        case APP_ENVS.DEMO:
          link.href = `/favicon-demo.ico`;
          break;
        case APP_ENVS.DEV:
          link.href = `/favicon-dev.ico`;
          break;

        case APP_ENVS.LOCAL:
          link.href = `/favicon-localhost.ico`;
          break;

        case APP_ENVS.PROD:
        default:
          link.href = `/favicon.ico`;
          break;
      }
    }
  }, [env]);
};

export default useEnvFavicon;
