import { User } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const UserIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <User width={size} height={size} color={color} />;

UserIcon.displayName = 'UserIcon';

export default UserIcon;
