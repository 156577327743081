import React, { FC } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const DonutLargeIcon: FC<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 24 24">
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <g>
          <path
            d="M11,5.08V2C6,2.5,2,6.81,2,12s4,9.5,9,10v-3.08c-3-0.48-6-3.4-6-6.92S8,5.56,11,5.08z M18.97,11H22c-0.47-5-4-8.53-9-9 v3.08C16,5.51,18.54,8,18.97,11z M13,18.92V22c5-0.47,8.53-4,9-9h-3.03C18.54,16,16,18.49,13,18.92z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </Icon>
);

DonutLargeIcon.displayName = 'DonutLargeIcon';

export default DonutLargeIcon;
