import React, { FunctionComponent } from 'react';

import {
  Autocomplete,
  DescriptionOptionFormatter,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import {
  BaseAutocompleteOption,
  KEYBOARD_LAYOUT,
} from '@savgroup-front-common/types';

import { useChooseKeyboardLayout } from './hooks';
import messages from './messages';

const ChooseKeyboardLayout: FunctionComponent = () => {
  const { options, value, onChange } = useChooseKeyboardLayout();

  return (
    <FormGroup>
      <Autocomplete
        options={options}
        label={messages.keyboardLayout}
        value={value}
        formatOptionLabel={DescriptionOptionFormatter}
        onChange={(option?: BaseAutocompleteOption | null) => {
          onChange(option?.value as KEYBOARD_LAYOUT);
        }}
      />
    </FormGroup>
  );
};

ChooseKeyboardLayout.displayName = 'ChooseKeyboardLayout';

export default ChooseKeyboardLayout;
