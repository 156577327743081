import React, { FunctionComponent, ReactNode } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Tooltip } from '@savgroup-front-common/core/src/atoms/Tooltip';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';
import { RadioCardHookForm } from '@savgroup-front-common/core/src/molecules/Form';
import { EditIcon } from '@savgroup-front-common/core/src/protons/icons';
import { AddressInfoDto, MessageType } from '@savgroup-front-common/types';

import { ADDRESS_SUGGESTION_ORIGIN } from '../../AddressSuggestion.types';
import {
  useAddressSuggestionFormContext,
  useAddressSuggestionStepContext,
} from '../../hooks';

import EditAddressChoiceFormatter from './EditAddressChoiceFormatter';
import messages from './messages';

interface SelectAddressRadioProps {
  address: AddressInfoDto;
  value: ADDRESS_SUGGESTION_ORIGIN;
  icon: ReactNode;
  label: MessageType;
  name: string;
}

const SelectAddressRadio: FunctionComponent<SelectAddressRadioProps> = ({
  address,
  value,
  icon,
  label,
  name,
}) => {
  const { previousStep } = useAddressSuggestionStepContext();
  const {
    register,
    formState: { errors },
  } = useAddressSuggestionFormContext();

  return (
    <RadioCardHookForm
      name={name}
      dataTestId="select-customer-option"
      image={icon}
      register={register}
      value={value}
      errors={errors}
      title={label}
    >
      <Row bottom="xs">
        <Col style={{ flex: 1 }}>
          <Field>
            <EditAddressChoiceFormatter datas={address} value={value} />
          </Field>
        </Col>
        <Col>
          <Tooltip
            title={safeFormattedIntlString(messages.modifyTitle)}
            style={{ display: 'block' }}
          >
            <Button
              type={BUTTON_TYPES.BUTTON}
              naked
              icon={<EditIcon />}
              onClick={(event) => {
                event.preventDefault();
                previousStep(
                  {},
                  {
                    hasSuggestedAddress: false,
                    unmatchingFields: [],
                  },
                );
              }}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.modifyButton}
              />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </RadioCardHookForm>
  );
};

SelectAddressRadio.displayName = 'SearchCustomerRadio';

export default SelectAddressRadio;
