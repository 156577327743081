import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/sales/import/errors/${COMMON_QUERY_PARAMS.IMPORT_ID}`;

export interface OperationResultErrorDto {
  subject: string;
  code: string;
  message: string;
  developerMessage: string;
  moreInfo: string;
  field: string;
  index: string;
  isWarning: boolean;
  extraData: string;
}

interface GetSalesImportErrorsQueryPayload {
  sellerId: string;
  importId: string;
}

type GetSalesImportErrorsQuery = (
  payload: GetSalesImportErrorsQueryPayload,
) => Promise<BackResponse<OperationResultErrorDto[]> | BackResponseFailure>;

const getSalesImportErrorsQuery: GetSalesImportErrorsQuery = async ({
  sellerId,
  importId,
}) => {
  try {
    return await apiCall<BackResponse<OperationResultErrorDto[]>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(COMMON_QUERY_PARAMS.IMPORT_ID, importId)),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getSalesImportErrorsQuery;
