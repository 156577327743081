import { createSelector } from 'reselect';

import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { CommonAppState } from '@savgroup-front-common/core/src/domains';
import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { SPARE_PART_REQUEST_DOMAIN_KEY } from '../constants';

import * as types from './actionTypes';
import {
  SPARE_PART_REQUEST_LINE_DOMAIN,
  SPARE_PART_REQUEST_LINE_LIST_DOMAIN_KEY,
} from './constants';
import {
  RubricItemWithActive,
  SelectSparePartRequestLinesAppliedFiltersToSend,
  SparePartRequestLineListDomainState,
} from './types';

interface State extends CommonAppState {
  [SPARE_PART_REQUEST_DOMAIN_KEY]: {
    [SPARE_PART_REQUEST_LINE_LIST_DOMAIN_KEY]: SparePartRequestLineListDomainState;
  };
}

const selectSparePartRequestLinesDomain = (state: State) => {
  return state[SPARE_PART_REQUEST_DOMAIN_KEY][
    SPARE_PART_REQUEST_LINE_LIST_DOMAIN_KEY
  ];
};

export const selectSparePartRequestLinesList = createSelector(
  [selectSparePartRequestLinesDomain],
  (state) => {
    return state[SPARE_PART_REQUEST_LINE_DOMAIN.LIST];
  },
);
export const selectSparePartRequestLineIdsByPage = createSelector(
  [selectSparePartRequestLinesDomain],
  (state) => state[SPARE_PART_REQUEST_LINE_DOMAIN.BY_PAGE],
);
export const selectSparePartRequestLinesByPage = createSelector(
  [selectSparePartRequestLinesList, selectSparePartRequestLineIdsByPage],
  (list, pagesOfIds) => {
    return pagesOfIds.flat().map((id) => list[id]);
  },
);

export const selectSparePartRequestLinesCurrentPage = createSelector(
  [selectSparePartRequestLinesDomain],
  (state) => state[SPARE_PART_REQUEST_LINE_DOMAIN.CURRENT_PAGE_NUMBER],
);
export const selectSparePartRequestLinesRubrics = createSelector(
  [selectSparePartRequestLinesDomain],
  (state) => state[SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS],
);

const countFilters = (filters: RubricItemWithActive[]): number =>
  filters.reduce((acc, { isActive }) => acc + Number(isActive), 0);

const mapActiveFilter = (
  filters: RubricItemWithActive[],
): Record<string, boolean> =>
  filters.reduce(
    (acc, { name, isActive }) => ({
      ...acc,
      [name]: isActive,
    }),
    {},
  );

export const selectSparePartRequestLinesAppliedFilters = createSelector(
  [selectSparePartRequestLinesRubrics],
  (rubrics) =>
    rubrics.reduce(
      (acc, { name, facets }) => ({
        ...acc,
        [name]: mapActiveFilter(facets),
      }),
      {},
    ),
);
export const selectSparePartRequestLinesAppliedFiltersToSend = createSelector(
  [selectSparePartRequestLinesRubrics],
  (rubrics) => {
    const filters = rubrics.map(({ name, facets }) => {
      const filters = facets
        .filter(({ isActive }) => isActive)
        .map(({ name }) => name);

      if (filters.length === 0) {
        return undefined;
      }

      return {
        name,
        facets: filters.map((filter) => {
          return {
            included: true,
            name: filter,
          };
        }),
      };
    }) as SelectSparePartRequestLinesAppliedFiltersToSend[];

    return filters.filter((filter) => filter);
  },
);

export const selectSparePartRequestLinesFilters = createSelector(
  [selectSparePartRequestLinesRubrics],
  (rubrics) => {
    return rubrics.map(({ name, facets }) => ({
      name,
      parameterName: name,
      values: Object.values(facets).map(({ label, count, name }) => ({
        value: name,
        name: label,
        resultsCount: count,
      })),
    }));
  },
);

export const selectSparePartRequestLinesFiltersCount = createSelector(
  [selectSparePartRequestLinesRubrics],
  (rubrics) => {
    return rubrics.reduce((acc, { facets }) => acc + countFilters(facets), 0);
  },
);

export const selectSparePartRequestLinesTotalCount = createSelector(
  [selectSparePartRequestLinesDomain],
  (state) => state[SPARE_PART_REQUEST_LINE_DOMAIN.TOTAL_COUNT],
);
export const selectSparePartRequestLinesQuery = createSelector(
  [selectSparePartRequestLinesDomain],
  (state) => state[SPARE_PART_REQUEST_LINE_DOMAIN.QUERY],
);
export const selectSparePartRequestLinesIsDescending = createSelector(
  [selectSparePartRequestLinesDomain],
  (state) => state[SPARE_PART_REQUEST_LINE_DOMAIN.IS_DESCENDING],
);

export const selectGetSparePartRequestLinesWasLoaded = (
  state: State,
  { page }: { page: number },
) =>
  wasActionLoadedSelector(state, types.GET_SPARE_PART_REQUEST_LINE_LIST, page);
export const selectGetSparePartRequestLinesErrors = (
  state: State,
  { page }: { page: number },
): ErrorFromBack[] =>
  getActionErrorsSelector(state, types.GET_SPARE_PART_REQUEST_LINE_LIST, page);
export const selectGetSparePartRequestLinesIsLoading = (
  state: State,
  { page }: { page: number },
) =>
  isActionLoadingSelector(state, types.GET_SPARE_PART_REQUEST_LINE_LIST, page);
