import qs from 'qs';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponse } from '@savgroup-front-common/types';

import { CarrierChoice } from './getClaimGroupCarrier';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/carriers`;

/**
 * @deprecated use getClaimGroupCarrier instead
 */
export const getClaimGroupCarrierLegacy = async ({
  claimGroupId,
  mode,
}: {
  claimGroupId: string;
  mode: unknown;
}): Promise<CarrierChoice[] | undefined> => {
  if (!claimGroupId || !mode) {
    return undefined;
  }

  const url = ENDPOINT.replace(
    COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
    claimGroupId,
  );

  const builtUrl = `${url}?${qs.stringify({ mode })}`;

  const response = await apiCall<BackResponse<CarrierChoice[]>>(builtUrl, {
    method: SUPPORTED_METHODS.GET,
  });

  return response.value || undefined;
};
