import get from 'lodash/get';
import { useEffect, useState } from 'react';

export default function useAttachmentPreview(attachmentValue) {
  const [blobDocumentUrl, setBlobDocumentUrl] = useState(undefined);
  const attachementId = get(attachmentValue, 'attachmentId');
  const data = get(attachmentValue, 'data', {});
  const { size = null, type = null } = data;

  useEffect(() => {
    if (size && type) {
      setBlobDocumentUrl(URL.createObjectURL(data));
    }

    return () => {
      URL.revokeObjectURL(blobDocumentUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachementId, size, type]);

  return blobDocumentUrl;
}
