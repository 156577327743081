import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';
import {
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { REPAIRER_TYPE } from '@savgroup-front-common/types';

import { ControlRootState } from '../ControlRootState';

import * as types from './actionTypes';
import { REPAIRER_DOMAIN } from './constants';
import { formatRepairerTypeAndSellerIdIndexer } from './helpers';
import { RepairerId, SellerId } from './types';

const selectRepairerDomain = (state: ControlRootState) => state.repairer;

const selectStoreList = createSelector(
  [selectRepairerDomain],
  (state) => state[REPAIRER_DOMAIN.LIST],
);

const selectBySellerId = createSelector(
  [selectRepairerDomain],
  (state) => state[REPAIRER_DOMAIN.BY_SELLER_ID],
);
const selectByRepairerType = createSelector(
  [selectRepairerDomain],
  (state) => state[REPAIRER_DOMAIN.BY_TYPE],
);

export const selectRepairerById = createSelector(
  [
    selectStoreList,
    (_: CommonAppState, { repairerId }: { repairerId: RepairerId }) =>
      repairerId,
  ],
  (repairers, repairerId: RepairerId) => repairers[repairerId] || null,
);
export const selectRepairerByIds = createSelector(
  [
    selectStoreList,
    (_: CommonAppState, { repairerIds }: { repairerIds: RepairerId[] }) =>
      repairerIds,
  ],
  (repairers, repairerIds) =>
    repairerIds
      .map((repairerId) => repairers[repairerId] || null)
      .filter((repairer) => repairer !== null),
);

const selectRepairerTypeArgs = (
  _: CommonAppState,
  { repairerType }: { repairerType: REPAIRER_TYPE },
): REPAIRER_TYPE => repairerType;

const selectSellerIdArgs = (
  _: CommonAppState,
  { sellerId }: { sellerId: SellerId; repairerType: REPAIRER_TYPE },
) => sellerId;

const selectRepairerIdsBySellerId = createSelector(
  [selectBySellerId, selectSellerIdArgs],
  (repairerIdsBySellerId, sellerId) => repairerIdsBySellerId[sellerId] || [],
);
const selectRepairerIdsByRepairerType = createSelector(
  [selectByRepairerType, selectRepairerTypeArgs],
  (repairerIdsByRepairerType, repairerType) =>
    repairerIdsByRepairerType[repairerType] || [],
);

export const selectRepairersByTypeAndSellerId = createSelector(
  [
    selectStoreList,
    selectRepairerIdsBySellerId,
    selectRepairerIdsByRepairerType,
  ],
  (repairers, repairerIdsBySellerId, repairerIdsByRepairerType) =>
    repairerIdsBySellerId
      .filter((repairerId) => repairerIdsByRepairerType.includes(repairerId))
      .map((repairerId) => repairers[repairerId] || null)
      .filter((repairer) => repairer !== null),
);

export const selectGetRepairerByTypeAndSellerIdIsLoading = (
  state: CommonAppState,
  {
    sellerId,
    repairerType,
  }: { sellerId: SellerId; repairerType: REPAIRER_TYPE },
): boolean =>
  isActionLoadingSelector(
    state,
    types.GET_REPAIRERS_BY_TYPE_AND_SELLER_ID,
    formatRepairerTypeAndSellerIdIndexer({ sellerId, repairerType }),
  );

export const selectGetRepairerTypeAndBySellerIdWasLoaded = (
  state: CommonAppState,
  {
    sellerId,
    repairerType,
  }: { sellerId: SellerId; repairerType: REPAIRER_TYPE },
): boolean =>
  wasActionLoadedSelector(
    state,
    types.GET_REPAIRERS_BY_TYPE_AND_SELLER_ID,
    formatRepairerTypeAndSellerIdIndexer({ sellerId, repairerType }),
  );
