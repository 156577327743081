import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';
import { transitionRightOrLeft } from '@savgroup-front-common/core/src/animations/complexeStateAnimation';

export const $ActionContainer = styled.div`
  padding: 0 ${rem(15)};
  min-width: ${rem(100)};
  position: absolute;
  top: 50%;
  &:first-child {
    left: 0;
  }
  &:last-child {
    right: 0;
  }
  z-index: 3;
`;

export const $AttachmentsContainer = styled.div`
  overflow: hidden;
  width: 100%;
  flex-flow: row;
  display: flex;
  justify-content: center;

  align-items: center;
  flex: 1;
  position: relative;
`;

interface $AttachmentsAnimationProps {
  $isZoomed?: boolean;
  animationDuration: number;
  animationState: TransitionStatus;
}

export const $AttachmentsAnimation = styled.div<$AttachmentsAnimationProps>`
  ${transitionRightOrLeft()};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem 4rem;
  width: 100%;
  object,
  video {
    object-fit: ${({ $isZoomed }) => ($isZoomed ? 'contain' : 'scale-down')};
    width: 100%;
    max-height: 100%;
  }

  object {
    height: 100%;
    transform: ${({ $isZoomed }) => ($isZoomed ? 'scale(1.5)' : 'scale(1)')};
    transition: transform 0.2s;
  }

  img {
    height: 100%;
    transform: ${({ $isZoomed }) => ($isZoomed ? 'scale(1.5)' : 'scale(1)')};
    transition: transform 0.2s;
  }
`;
