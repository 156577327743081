import { createSelector } from 'reselect';

import { selectLogin } from '@savgroup-front-common/core/src/domains/login/selectors';
import { getFileSummaryValue } from 'control/domains/workflow/fileSummary/selectors';

import { ControlRootState } from '../ControlRootState';
import { selectUserById } from '../users/selectors';

const workflowState = (state: ControlRootState) => state.workflow;

export const fileStates = createSelector(
  [workflowState],
  (workflow) => workflow.fileStates,
);

export const selectLatestFileStateByFileId = createSelector(
  [fileStates, (_: ControlRootState, fileId: string | undefined) => fileId],
  (state, fileId) =>
    fileId
      ? state[fileId]?.value?.at(0) || {
          state: { name: undefined },
          module: {},
          stateDate: undefined,
        }
      : { state: { name: undefined }, module: {}, stateDate: undefined },
);

export const selectFileComments = createSelector(
  [getFileSummaryValue, (state) => state, selectLogin],
  ({ value: fileSummaryValue }, state, login) => {
    return fileSummaryValue && fileSummaryValue.comments
      ? fileSummaryValue.comments.map((comment) => {
          if (login.userId === comment.userId) {
            return {
              ...comment,
              user: {
                userId: login.userId,
                firstName: login.firstname,
                lastName: login.lastname,
              },
            };
          }

          const user = selectUserById(state as any, { userId: comment.userId });

          if (user) {
            return {
              ...comment,
              user: {
                userId: user.userId,
                firstName: user.firstname,
                lastName: user.lastname,
              },
            };
          }

          return comment;
        })
      : [];
  },
);
