import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

export const ENDPOINT = `${APIConfiguration.owner}import/orders`;

interface ImportOrderCommandArgs {
  orderReference: string;
  sellerId: string;
}

async function importOrderCommand({
  orderReference,
  sellerId,
}: ImportOrderCommandArgs): Promise<
  BackResponse<string> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        orderReference,
        sellerId,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default importOrderCommand;
