import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.owner}video/session/publishertoken`;

interface TokenSummaryDto {
  token: string;
  url: string;
  apiKey: string;
}

interface GetVideoSessionPublisherTokenQueryPayload {
  sessionId: string;
}

export const getVideoSessionPublisherTokenQuery = async ({
  sessionId,
}: GetVideoSessionPublisherTokenQueryPayload): Promise<
  BackResponse<TokenSummaryDto> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<TokenSummaryDto>>(
      buildUrl(ENDPOINT, { sessionId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
