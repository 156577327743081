import { createReducer } from '@savgroup-front-common/core/src/helpers';

import * as BusinessConst from '../businessConst';

export const initialState = Object.freeze({
  isLoaded: false,
  id: null,
  civility: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  countryCode: null,
  preference: null,
  addresses: null,
});

function onGetClient(state, payload) {
  return {
    ...initialState,
    id: payload,
  };
}

function onGetClientSucceeded(state, payload) {
  if (state.id !== payload.value.id) {return state;}

  return {
    ...state,
    isLoaded: true,
    ...payload.value,
  };
}

const clientReducer = createReducer(initialState, {
  [BusinessConst.GET_OWNER.BASE]: onGetClient,
  [BusinessConst.GET_OWNER.SUCCEEDED]: onGetClientSucceeded,
});

export default clientReducer;
