import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useTheme } from 'styled-components';

import SearchDisplayError from '../../common/SearchDisplayError';

import messages from './messages';
import { SearchOffIcon } from '@savgroup-front-common/core/src/protons/icons';

interface NoResultsProps {
  animationState: TransitionStatus;
}

const NoResults: FunctionComponent<React.PropsWithChildren<NoResultsProps>> = ({
  animationState,
}) => {
  const theme = useTheme();

  return (
    <SearchDisplayError
      dataTestId="search-error-no-results"
      animationState={animationState}
      title={messages.noResultsTitle}
      body={messages.noResultsText}
      icon={<SearchOffIcon color={theme.colors.primary} size="64px" />}
    />
  );
};

NoResults.displayName = 'NoResults';

export default NoResults;
