import {
  adaptToBoldHighlight,
  adaptToRegularHighlight,
} from 'control/adapters';

const smartFindItemHighlight = (query: string, item: string) => {
  const regex = new RegExp(query, 'i');

  const start = item.search(regex);
  const end = start + query.length;

  const hasResult = start >= 0;
  const caraterCount = end - start;
  const minimumCaratereCount = 3;

  if (hasResult && caraterCount >= minimumCaratereCount) {
    const returnValue = [];

    if (start > 0) {
      returnValue.push(adaptToRegularHighlight(item.slice(0, start)));
    }
    returnValue.push(adaptToBoldHighlight(item.slice(start, end)));
    if (end !== item.length) {
      returnValue.push(adaptToRegularHighlight(item.slice(end)));
    }

    return returnValue;
  }

  return [adaptToRegularHighlight(item)];
};

export default smartFindItemHighlight;
