import { createSelector } from 'reselect';

import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';
import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { ControlRootState } from '../ControlRootState';

import * as types from './actionTypes';
import { PRODUCT_SUPPLIER_DOMAIN } from './constants';
import { ProductSupplierId, SellerId } from './types';

const selectProductSupplierDomain = (state: ControlRootState) =>
  state.productSupplier;

const selectProductSupplierList = createSelector(
  [selectProductSupplierDomain],
  (state) => state[PRODUCT_SUPPLIER_DOMAIN.VALUE],
);

export const selectProductSupplierById = createSelector(
  [
    selectProductSupplierList,
    (
      _: CommonAppState,
      { productSupplierId }: { productSupplierId?: ProductSupplierId },
    ) => productSupplierId,
  ],
  (productSuppliers, productSupplierId) =>
    productSupplierId ? productSuppliers[productSupplierId] : undefined,
);

export const selectGetProductSupplierIsLoading = (
  state: CommonAppState,
  { productSupplierId }: { productSupplierId?: SellerId },
): boolean =>
  productSupplierId
    ? isActionLoadingSelector(
        state,
        types.GET_PRODUCT_SUPPLIER,
        productSupplierId,
      )
    : false;

export const selectGetProductSupplierWasLoaded = (
  state: CommonAppState,
  { productSupplierId }: { productSupplierId: SellerId },
): boolean =>
  wasActionLoadedSelector(state, types.GET_PRODUCT_SUPPLIER, productSupplierId);

export const selectGetProductSupplierErrors = (
  state: CommonAppState,
  { productSupplierId }: { productSupplierId: SellerId },
): ErrorFromBack[] =>
  getActionErrorsSelector(state, types.GET_PRODUCT_SUPPLIER, productSupplierId);
