import { useCallback, useEffect, useState } from 'react';

import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import { StatusPageService } from '../../../api';
import { getFromLocalStorage, setToLocalStorage } from '../../../helpers';
import { useInterval } from '../../../hooks';
import processIncident from '../helpers/processIncident';

const SECONDES = 1000;
const MINUTES = 60 * SECONDES;
const INCIDENTS_INTERVAL = 5 * MINUTES;

interface UseAppIncidentsArgs {
  componentName: string;
}

const useAppIncidents = ({ componentName }: UseAppIncidentsArgs) => {
  const [appIncidents, setAppIncidents] = useState(() =>
    getFromLocalStorage({
      key: LOCAL_STORAGE_KEYS.APP_INCIDENTS,
      defaultValue: [],
    }),
  );

  const getAppIncidents = useCallback(async () => {
    const response = await StatusPageService.getAppIncidents();

    if (response.failure) {
      return;
    }

    setToLocalStorage({
      key: LOCAL_STORAGE_KEYS.APP_INCIDENTS,
      value: response.value,
    });

    setAppIncidents((oldIncidents) => {
      response.value.forEach((incident) => {
        processIncident(oldIncidents, incident, componentName);
      });

      return response.value;
    });
  }, [componentName]);

  useInterval(getAppIncidents, INCIDENTS_INTERVAL);

  useEffect(() => {
    getAppIncidents();
  }, [getAppIncidents]);

  return { appIncidents };
};

export default useAppIncidents;
