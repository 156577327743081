import React from 'react';

interface IconPropsInterface {
  color?: string;
}

 
export const AddIcon: React.FC<IconPropsInterface> = ({ color = 'black' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="currentColor" />
    </svg>
  );
};

AddIcon.displayName = 'AddIcon';
