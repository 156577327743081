import React, { FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import { $MenuItemTitle } from '../Menu.styles';

import { $MainExternalLink } from './MenuExternalLink.styles';

interface MenuExternalLinkProps {
  message: MessageType;
}

const MenuExternalLink: FunctionComponent<
  React.PropsWithChildren<MenuExternalLinkProps>
> = ({ message }) => {
  return (
    <$MainExternalLink
      href="https://demo-help.revers.io/fr"
      target="_blank"
      isExternal
    >
      <$MenuItemTitle>
        <SafeFormattedMessageWithoutSpread message={message} />
      </$MenuItemTitle>
    </$MainExternalLink>
  );
};

MenuExternalLink.displayName = 'MenuExternalLink';
export default MenuExternalLink;
