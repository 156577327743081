import * as ActionTypes from './actionTypes';

export const initialState = Object.freeze({
  modals: [],
});

function onModalOpen(state, modal) {
  return {
    modals: state.modals.concat(modal),
  };
}

function onModalClose(state) {
  const { modals } = state;

  modals.pop();

  return {
    modals: [...modals],
  };
}

export default function modalsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL: {
      return onModalOpen(state, action.payload);
    }

    case ActionTypes.CLOSE_MODAL: {
      return onModalClose(state);
    }

    default: {
      return state;
    }
  }
}
