import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { ICONS_TYPE } from '@savgroup-front-common/core/src/protons/icons/ConfigurationConsole/Icon.types';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}console/configuration`;

export enum ConsoleConfigurationItemType {
  RULE = 'Rule',
  ENTITY = 'Entity',
  TABS = 'Tabs',
  MERGED_TAB = 'MergedTab',
}

export type ConsoleConfigurationEntityPage =
  | {
      localizationKey: string;
      type: ConsoleConfigurationItemType.TABS;
      routingKey: string;
      tabs: {
        localizationKey: string;
        type: ConsoleConfigurationItemType.ENTITY;
        importType: string;
        iconKey?: ICONS_TYPE;
      }[];
    }
  | {
      localizationKey: string;
      type: ConsoleConfigurationItemType.ENTITY;
      importType: string;
      iconKey?: ICONS_TYPE;
    };
export type ConsoleConfigurationRulePage = {
  localizationKey: string;
  type: ConsoleConfigurationItemType.TABS;
  routingKey: string;
  tabs: {
    localizationKey: string;
    type: ConsoleConfigurationItemType.RULE;
    importType: string;
    iconKey?: string;
  }[];
};
export type ConsoleConfigurationTabsPage = {
  localizationKey: string;
  type: ConsoleConfigurationItemType.MERGED_TAB;
  routingKey?: string;
  iconKey?: ICONS_TYPE;
  entities?: {
    localizationKey: string;
    type: ConsoleConfigurationItemType.ENTITY;
    importType: string;
    iconKey?: ICONS_TYPE;
  }[];
  rules?: {
    localizationKey: string;
    type: ConsoleConfigurationItemType.RULE;
    importType: string;
    iconKey?: ICONS_TYPE;
  }[];
};

export interface ConsoleConfigurationDto {
  entities: ConsoleConfigurationEntityPage[];
  rules: [ConsoleConfigurationRulePage];
  tabs: ConsoleConfigurationTabsPage[];
}

export async function getConsoleConfigurationQuery({
  sellerId,
}: {
  sellerId: string;
}): Promise<BackResponse<ConsoleConfigurationDto> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<ConsoleConfigurationDto>>(
      buildUrl(ENDPOINT, { sellerId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
