import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  CREATE_MODAL_TYPES,
  PERMISSIONS,
} from '@savgroup-front-common/constants/src/control';

import { selectSellers } from '../domains/sellers/selectors';

import useGetSellerConfiguration from './useGetSellerConfiguration';
import { useHasPermission } from './useHasPermission';
import { useIsSuperAdmin } from './useHasRole';

const useAuthorizedCreateModalTypes = (): CREATE_MODAL_TYPES[] => {
  const sellers = useSelector(selectSellers);
  const initSellerSelected = sellers[0];

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId: initSellerSelected?.id,
  });

  const hasCreateFileByOrderReferencePermission = useHasPermission(
    PERMISSIONS.CREATE_FILE_BY_ORDER_REFERENCE,
  );
  const hasCreateFileByProductReferencePermission = useHasPermission(
    PERMISSIONS.CREATE_FILE_BY_PRODUCT_REFERENCE,
  );
  const hasCreateFileByReceiptInformationPermission = useHasPermission(
    PERMISSIONS.CREATE_FILE_BY_RECEIPT_INFORMATION,
  );
  const hasCreateFileByCustomerInformationPermission = useHasPermission(
    PERMISSIONS.CREATE_FILE_BY_CUSTOMER_INFORMATION,
  );
  const hasCreateSparePartRequestPermission = useHasPermission(
    PERMISSIONS.CREATE_SPARE_PART_REQUEST,
  );
  const hasCreateFileOwnerStockPermission = useHasPermission(
    PERMISSIONS.CREATE_FILE_BY_OWNER_STOCK,
  );
  const isSuperAdmin = useIsSuperAdmin();

  return useMemo(() => {
    const authorizations: CREATE_MODAL_TYPES[] = [];

    if (hasCreateFileByOrderReferencePermission) {
      authorizations.push(CREATE_MODAL_TYPES.FILE_ORDER_REFERENCE);
    }

    if (hasCreateFileByProductReferencePermission) {
      authorizations.push(CREATE_MODAL_TYPES.FILE_PRODUCT_REFERENCE);
    }

    if (hasCreateFileByCustomerInformationPermission) {
      authorizations.push(CREATE_MODAL_TYPES.FILE_BY_CUSTOMER_INFORMATION);
    }

    if (hasCreateFileOwnerStockPermission) {
      authorizations.push(CREATE_MODAL_TYPES.FILE_OWNER_STOCK);
    }

    if (hasCreateFileByReceiptInformationPermission) {
      authorizations.push(CREATE_MODAL_TYPES.FILE_RECEIPT_INFORMATION);
    }

    if (
      hasCreateSparePartRequestPermission &&
      !sellerConfiguration?.isSparePartNewModel &&
      !isSuperAdmin
    ) {
      authorizations.push(CREATE_MODAL_TYPES.SPARE_PART_REQUEST);
    }

    if (isSuperAdmin) {
      authorizations.push(CREATE_MODAL_TYPES.SELLER);
      authorizations.push(CREATE_MODAL_TYPES.TENANT);
    }

    return authorizations;
  }, [
    hasCreateFileByCustomerInformationPermission,
    hasCreateFileByOrderReferencePermission,
    hasCreateFileByProductReferencePermission,
    hasCreateFileOwnerStockPermission,
    hasCreateFileByReceiptInformationPermission,
    hasCreateSparePartRequestPermission,
    sellerConfiguration,
    isSuperAdmin,
  ]);
};

export default useAuthorizedCreateModalTypes;
