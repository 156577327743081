import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  LANGUAGES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

export const URL = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/language`;

interface SetOwnerNotificationLanguageArgs {
  ownerId: string;
  language?: LANGUAGES;
}

export async function setOwnerNotificationLanguage({
  ownerId,
  language = LANGUAGES.FR,
}: SetOwnerNotificationLanguageArgs): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    return await apiCall(URL.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId), {
      method: SUPPORTED_METHODS.PUT,
      json: { language },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
