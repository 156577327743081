import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/unexpectedProducts`;

interface AddUnexpectedProductsCommandPayload {
  fileId: string;
  modelIds: string[];
}

async function addUnexpectedProductsCommand({
  fileId,
  modelIds,
}: AddUnexpectedProductsCommandPayload): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        modelIds,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default addUnexpectedProductsCommand;
