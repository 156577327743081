import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  PAYMENT_BEHAVIOUR,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/confirmation`;

interface ClaimComment {
  type: 'ReasonComment' | 'DiagnosticComment' | 'InsuranceClaimComment';
  content: string;
}
interface DocumentInfo {
  name: string;
  originalFileName: string;
  extension: string;
  url: string;
  id: string;
}
interface ProductAdditionalInformation {
  additionalInformationId: string;
  additionalInformationEnumValue: string;
  additionalInformationStringValue: string;
  additionalInformationFileValue: ProductFileAdditionalInformation;
}
interface ProductFileAdditionalInformation {
  fileName: string;
  url: string;
}

interface ClaimGroupConfirmationElementSummary {
  claimId: string;
  claimdId: string;
  issueName: string;
  reasonName: string;
  comments: ClaimComment[];
  documents: DocumentInfo[];
  solutionTypeName: string;
  solutionTypeKey: string;
  solutionPrice: number;
  solutionPriceCurrency: string;
  solutionGroupId: string;
  additionalClaimInformation: ProductAdditionalInformation[];
}

interface ClaimGroupConfirmationSummary {
  claimGroupId: string;
  solutionTypeName: string;
  solutionPrice: number;
  solutionPriceCurrencyCode: string;
  solutionPriceWithTax: number;
  solutionPriceWithTaxCurrencyCode: string;
  solutionGroupId: string;
  depositStoreId: string;
  purchaseStoreId: string;
  storeActorId: string;
  actorId: string;
  actorType: ACTOR_TYPES;
  depositAddress: AddressInfoDto;
  deliveryAddress: AddressInfoDto;
  carrierDepositName: string;
  carrierDeliveryName: string;
  carrierDeliveryPrice: number;
  carrierDeliveryPriceCurrencyCode: string;
  carrierDepositPrice: number;
  carrierDepositPriceCurrencyCode: string;
  carrierDeliveryPriceWithTax: number;
  carrierDeliveryPriceWithTaxCurrencyCode: string;
  carrierDepositPriceWithTax: number;
  carrierDepositPriceWithTaxCurrencyCode: string;
  claims: ClaimGroupConfirmationElementSummary[];
  shouldGenerateAndPayInvoice: boolean;
  paymentBehavior: PAYMENT_BEHAVIOUR;
  carrierDepositReach:
    | 'Unknown'
    | 'Home'
    | 'PostOfficeCounter'
    | 'PrivateCarrierCounter';
  carrierDeliveryReach:
    | 'Unknown'
    | 'Home'
    | 'PostOfficeCounter'
    | 'PrivateCarrierCounter';
  carrierDepositProductType:
    | 'Pickup'
    | 'PickupPostOffice'
    | 'PickupCounter'
    | 'HomeDelivery'
    | 'DropAtStore'
    | 'SellerProvidedCarrier'
    | 'ImmediateDropAtStore'
    | 'PickUpStoreDelivery';
  carrierDeliveryProductType:
    | 'Pickup'
    | 'PickupPostOffice'
    | 'PickupCounter'
    | 'HomeDelivery'
    | 'DropAtStore'
    | 'SellerProvidedCarrier'
    | 'ImmediateDropAtStore'
    | 'PickUpStoreDelivery';
  additionalElements: string[];
}

async function getClaimGroupConfirmation({
  claimGroupId,
}: {
  claimGroupId: string;
}): Promise<BackResponse<ClaimGroupConfirmationSummary> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<ClaimGroupConfirmationSummary>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getClaimGroupConfirmation;
