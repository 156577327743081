import { combineReducers } from 'redux';

import {
  FILE_SPARE_PART_REQUEST_LINES_DOMAIN_KEY,
  SparePartFileRequestLineDetailsDomainState,
  sparePartFileRequestLinesReducer,
} from './fileRequestLines';
import {
  SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN_KEY,
  sparePartRequestDetailsReducer,
  SparePartRequestLineDetailsDomainState,
} from './requestLineDetails';
import {
  SPARE_PART_REQUEST_LINE_LIST_DOMAIN_KEY,
  SparePartRequestLineListDomainState,
  sparePartRequestLinesReducer,
} from './requestLineList';

export default combineReducers<{
  [SPARE_PART_REQUEST_LINE_LIST_DOMAIN_KEY]: SparePartRequestLineListDomainState;
  [SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN_KEY]: SparePartRequestLineDetailsDomainState;
  [FILE_SPARE_PART_REQUEST_LINES_DOMAIN_KEY]: SparePartFileRequestLineDetailsDomainState;
}>({
  [SPARE_PART_REQUEST_LINE_LIST_DOMAIN_KEY]: sparePartRequestLinesReducer,
  [SPARE_PART_REQUEST_LINE_DETAILS_DOMAIN_KEY]: sparePartRequestDetailsReducer,
  [FILE_SPARE_PART_REQUEST_LINES_DOMAIN_KEY]: sparePartFileRequestLinesReducer,
});
