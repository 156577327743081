import { defineMessages } from 'react-intl';

export default defineMessages({
  be: {
    id: 'components.atom.countryAutocomplete.Belgium',
    defaultMessage: 'Belgium',
  },
  bg: {
    id: 'components.atom.countryAutocomplete.Bulgaria',
    defaultMessage: 'Bulgaria',
  },
  cz: {
    id: 'components.atom.countryAutocomplete.Czechia',
    defaultMessage: 'Czechia',
  },
  dk: {
    id: 'components.atom.countryAutocomplete.Denmark',
    defaultMessage: 'Denmark',
  },
  de: {
    id: 'components.atom.countryAutocomplete.Germany',
    defaultMessage: 'Germany',
  },
  ee: {
    id: 'components.atom.countryAutocomplete.Estonia',
    defaultMessage: 'Estonia',
  },
  ie: {
    id: 'components.atom.countryAutocomplete.Ireland',
    defaultMessage: 'Ireland',
  },
  el: {
    id: 'components.atom.countryAutocomplete.Greece',
    defaultMessage: 'Greece',
  },
  es: {
    id: 'components.atom.countryAutocomplete.Spain',
    defaultMessage: 'Spain',
  },
  fr: {
    id: 'components.atom.countryAutocomplete.France',
    defaultMessage: 'France',
  },
  hr: {
    id: 'components.atom.countryAutocomplete.Croatia',
    defaultMessage: 'Croatia',
  },
  it: {
    id: 'components.atom.countryAutocomplete.Italy',
    defaultMessage: 'Italy',
  },
  cy: {
    id: 'components.atom.countryAutocomplete.Cyprus',
    defaultMessage: 'Cyprus',
  },
  lv: {
    id: 'components.atom.countryAutocomplete.Latvia',
    defaultMessage: 'Latvia',
  },
  lt: {
    id: 'components.atom.countryAutocomplete.Lithuania',
    defaultMessage: 'Lithuania',
  },
  lu: {
    id: 'components.atom.countryAutocomplete.Luxembourg',
    defaultMessage: 'Luxembourg',
  },
  hu: {
    id: 'components.atom.countryAutocomplete.Hungary',
    defaultMessage: 'Hungary',
  },
  mt: {
    id: 'components.atom.countryAutocomplete.Malta',
    defaultMessage: 'Malta',
  },
  nl: {
    id: 'components.atom.countryAutocomplete.Netherlands',
    defaultMessage: 'Netherlands',
  },
  at: {
    id: 'components.atom.countryAutocomplete.Austria',
    defaultMessage: 'Austria',
  },
  pl: {
    id: 'components.atom.countryAutocomplete.Poland',
    defaultMessage: 'Poland',
  },
  pt: {
    id: 'components.atom.countryAutocomplete.Portugal',
    defaultMessage: 'Portugal',
  },
  ro: {
    id: 'components.atom.countryAutocomplete.Romania',
    defaultMessage: 'Romania',
  },
  si: {
    id: 'components.atom.countryAutocomplete.Slovenia',
    defaultMessage: 'Slovenia',
  },
  sk: {
    id: 'components.atom.countryAutocomplete.Slovakia',
    defaultMessage: 'Slovakia',
  },
  fi: {
    id: 'components.atom.countryAutocomplete.Finland',
    defaultMessage: 'Finland',
  },
  se: {
    id: 'components.atom.countryAutocomplete.Sweden',
    defaultMessage: 'Sweden',
  },
  uk: {
    id: 'components.atom.countryAutocomplete.UnitedKingdom',
    defaultMessage: 'UnitedKingdom',
  },
  ch: {
    id: 'components.atom.countryAutocomplete.Switzerland',
    defaultMessage: 'Switzerland',
  },
  no: {
    id: 'components.atom.countryAutocomplete.Norway',
    defaultMessage: 'Norway',
  },
  is: {
    id: 'components.atom.countryAutocomplete.Iceland',
    defaultMessage: 'Iceland',
  },
  li: {
    id: 'components.atom.countryAutocomplete.Liechtenstein',
    defaultMessage: 'Liechtenstein',
  },
});
