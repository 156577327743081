import styled from 'styled-components';

import { Z_INDEX_HEADER_SEARCH_OPEN } from '@savgroup-front-common/constants/src/control';
import { media } from '@savgroup-front-common/constants/src/shared';
import { getAnimationDuration } from '@savgroup-front-common/core/src/animations/helper';
import { SimpleAnimationProps } from '@savgroup-front-common/core/src/animations/simpleAnimation';
import { StateAnimationProps } from '@savgroup-front-common/core/src/animations/stateAnimation';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';
import {
  isAnimationMounted,
  rem,
} from '@savgroup-front-common/core/src/helpers';

interface ResultContainerProps extends SimpleAnimationProps {
  $size: number;
}

export const $ResultContainer = styled.div<ResultContainerProps>`
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  & {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: ${rem(50)};
  left: 0;
  width: 100%;
  height: ${({ $size = 0 }) => rem($size)};
  transition-duration: ${getAnimationDuration()};
  ${decelerateTimingFunctionTransition};
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - ${rem(50)});
  border-bottom-right-radius: ${rem(8)};
  border-bottom-left-radius: ${rem(8)};
  scrollbar-width: none;
  @media ${media.minWidth.lg} {
    top: ${rem(70)};
    max-height: calc(100vh - ${rem(70)});
  }
`;

export const $SearchContainer = styled.div<{ $isNewUiEnabled: boolean }>`
  height: 100%;
  width: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? 'unset' : '100%')};
  flex: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? 'unset' : 1)};
  background-color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? 'unset' : theme.colors.white};
  justify-content: center;
  align-items: center;
  display: flex;
`;

interface SearchCenter extends StateAnimationProps {
  $isNewUiEnabled: boolean;
}

export const $SearchCenter = styled.div<SearchCenter>`
  height: 100%;
  width: 100%;
  max-width: ${rem(700)};
  min-width: ${rem(200)};
  flex: 1;
  background-color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? 'unset' : theme.colors.white};
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  z-index: ${({ animationState }) =>
    isAnimationMounted(animationState) ? Z_INDEX_HEADER_SEARCH_OPEN + 1 : null};
`;
