import {
  deleteApiApplication,
  getApiApplication,
  setApiApplication,
  updateApiApplication,
} from './apiCredentials';
import getFeatureFlagsByIdQuery from './getFeatureFlagsByIdQuery';
import getFeatureFlagsQuery from './getFeatureFlagsQuery';
import getUserInformationByIdQuery from './getUserInformationByIdQuery';
import getUserInformationByIdsQuery from './getUserInformationByIdsQuery';
import getUsersInformationBySellerIdQuery from './getUsersInformationBySellerIdQuery';
import inviteUserCommand from './inviteUserCommand';
import listManagementUsersQuery from './listManagementUsersQuery';
import listUserSiteQuery from './listUserSiteQuery';
import login from './login';
import { getConfigurableRolesQuery } from './roles/getConfigurableRolesQuery';
import upsertFeatureFlagsCommand from './upsertFeatureFlagsCommand';
import { listUserProfileQuery } from './user/listUserProfileQuery';
import { pagedListManagementUsersQuery } from './user/pagedListManagementUsersQuery';
import setUserProfilesToUser from './user/setUserProfilesToUser';
import { unlinkUserFromSellerCommand } from './user/unlinkUserFromSellerCommand';
import upsertUserManagementCommand from './user/upsertUserManagementCommand';

const AuthorizationService = {
  listManagementUsersQuery,
  getUserInformationByIdQuery,
  getUserInformationByIdsQuery,
  listUserSiteQuery,
  getUsersInformationBySellerIdQuery,
  upsertFeatureFlagsCommand,
  setApiApplication,
  deleteApiApplication,
  updateApiApplication,
  getApiApplication,
  setUser: upsertUserManagementCommand,
  upsertUserManagementCommand,
  setUserProfilesToUser,
  login,
  getFeatureFlagsQuery,
  getFeatureFlagsByIdQuery,
  inviteUserCommand,
  getConfigurableRolesQuery,

  pagedListManagementUsersQuery,
  listUserProfileQuery,
  unlinkUserFromSellerCommand,
};

export default AuthorizationService;
