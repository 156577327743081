import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
  LABEL_ADDRESS_TYPE,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}carrier/files/${COMMON_QUERY_PARAMS.FILE_ID}/labels/current/address`;

interface GetFileLabelAddressArgs {
  fileId: string;
  addressType: LABEL_ADDRESS_TYPE;
}

async function getFileLabelAddress({
  fileId,
  addressType,
}: GetFileLabelAddressArgs): Promise<
  BackResponse<AddressInfoDto> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const urlWithParams = buildUrl(url, { addressType });

    const response = await apiCall<BackResponse<AddressInfoDto>>(urlWithParams, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFileLabelAddress;
