import take from 'lodash/take';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { LinkButton } from '@savgroup-front-common/core/src/atoms/link';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';

import messagesFiltersMessages from './messages';
import { $LinkActionContainer, ShowMoreContainer } from './ShowMoreList.styles';

interface ShowMoreListProps {
  children: ReactNode[];
  isForcedToOpen?: boolean;
}

const ShowMoreList: FunctionComponent<
  React.PropsWithChildren<ShowMoreListProps>
> = ({ children, isForcedToOpen = false }) => {
  const [opened, setOpened] = useState(false);

  if (children.length > 5 && !isForcedToOpen) {
    return (
      <>
        <ShowMoreContainer>
          {opened ? children : take(children, 3)}
        </ShowMoreContainer>
        <$LinkActionContainer>
          <LinkButton
            onClick={() => setOpened(!opened)}
            type={BUTTON_TYPES.BUTTON}
          >
            {opened ? (
              <SafeFormattedMessage {...messagesFiltersMessages.showLess} />
            ) : (
              <SafeFormattedMessage {...messagesFiltersMessages.showMore} />
            )}
          </LinkButton>
        </$LinkActionContainer>
      </>
    );
  }

  return <ShowMoreContainer>{children}</ShowMoreContainer>;
};

ShowMoreList.displayName = 'ShowMoreList';

export default ShowMoreList;
