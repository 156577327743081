import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { SolutionSummary } from './getClaimSolutions';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/solutions`;

interface SolutionStepInfo {
  selectedWarrantyId: string;
  selectedWarrantyName: string;
  solutions: SolutionSummary[];
}

export async function getClaimGroupSolutionsQuery({
  claimGroupId,
}: {
  claimGroupId: string;
}): Promise<BackResponse<SolutionStepInfo> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<SolutionStepInfo>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
