export const FILTER_FILES_ORDER = [
  'StatusId',
  'SolutionGroupType',
  'Store',
  'SiteActorId',
  'Brands',
  'PartnerScenarioType',
  'WarrantyType',
  'ActorType',
  'ActorId',
  'Seller',
  'FileLanguage',
  'SiteActorType',
  'AssignedTo',
  'AssignedType',
  'Priorities',
  'BuyerType',
  'WorkflowVersion',
];

export const FILTER_FILES_FACETS_TO_EXCLUDE = [
  {
    rubricName: 'PartnerScenarioType',
    values: ['None'],
  },
];

export const FILTER_FILES_TO_EXCLUDE = [
  'AssignedTo',
  'AssignedType',
  'SiteActorType',
  'AlertType',
];
