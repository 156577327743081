import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}stocks/seller/${COMMON_QUERY_PARAMS.SELLER_ID}`;

export interface GetAuthorizedStocksByUserIdQueryArgs {
  sellerId: string;
}

interface StockLabel {
  stockId: string;
  stockName: string;
}

export default async function getSparePartStocksBySellerQuery(
  payload: GetAuthorizedStocksByUserIdQueryArgs,
): Promise<BackResponse<StockLabel[]> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<StockLabel[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, payload.sellerId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
