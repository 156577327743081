import { HelpCircle } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

export const HelpCircleIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <HelpCircle width={size} height={size} color={color} />;

HelpCircleIcon.displayName = 'HelpCircleIcon';
