import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  MARKETING_OFFER_TYPE,
} from '@savgroup-front-common/types';

import { RefundPolicyPayloadValue } from './setDetailedCommercialSolutionCommand';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/detailedcommercialgesture`;

interface SetDetailedCommercialGestureCommandPayload {
  fileId: string;
  marketingOfferType: MARKETING_OFFER_TYPE;
  products: RefundPolicyPayloadValue[];
  services: RefundPolicyPayloadValue[];
  other: RefundPolicyPayloadValue[];
  shippingPrice?: number;
}

const setDetailedCommercialGestureCommand = async ({
  fileId,
  marketingOfferType,
  products,
  services,
  other,
  shippingPrice,
}: SetDetailedCommercialGestureCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        marketingOfferType,
        products,
        services,
        other,
        shippingPrice,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default setDetailedCommercialGestureCommand;
