import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const AddLineIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <rect fill="none" height="24" width="24" />
    <path
      d="M14,10H3v2h11V10z M14,6H3v2h11V6z M18,14v-4h-2v4h-4v2h4v4h2v-4h4v-2H18z M3,16h7v-2H3V16z"
      fill="currentColor"
    />
  </Icon>
);

AddLineIcon.displayName = 'AddLineIcon';

export default AddLineIcon;
