import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { WorkflowState } from '../../domains/workflow/types';

const ENDPOINT = `${APIConfiguration.claim}claims/genericproduct`;

interface ClaimContextClaimWorkflowSummaryDto {
  id: string;
  claimGroupId: string;
  claimCreationDateUtc: string;
  ownerProductId: string;
  fileId: string;
  state: WorkflowState;
  productLocationCountryCode: string;
  isActive: boolean;
  orderReference: string;
  orderLineReference: string;
  orderId: string;
}

interface CreateClaimContextForGenericProductCommandArgs {
  modelTypeId: string;
  brandId: string;
  purchaseDate: Date;
  ean?: string;
  sku?: string;
  serialNumber?: string;
  estimatedPriceAmount: number;
  currencyCode: string;
  ownerId: string;
  sellerId: string;
}
async function createClaimContextForGenericProductCommand({
  ownerId,
  ean,
  currencyCode,
  estimatedPriceAmount,
  sku,
  sellerId,
  brandId,
  serialNumber,
  purchaseDate = new Date(2000, 1),
  modelTypeId,
}: CreateClaimContextForGenericProductCommandArgs): Promise<
  BackResponse<ClaimContextClaimWorkflowSummaryDto> | BackResponseFailure
> {
  try {
    const response = await apiCall<
      BackResponse<ClaimContextClaimWorkflowSummaryDto>
    >(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        ownerId,
        ean,
        currencyCode,
        estimatedPriceAmount,
        sku,
        sellerId,
        brandId,
        serialNumber,
        purchaseDate,
        modelTypeId,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createClaimContextForGenericProductCommand;
