import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SparePartSupplier,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/suppliers`;

export interface GetValidSparePartSuppliersQueryArgs {
  sellerId: string;
  includeMaster?: boolean;
}

export async function getSparePartSuppliersQuery({
  sellerId,
  includeMaster = false,
}: GetValidSparePartSuppliersQueryArgs): Promise<
  BackResponse<SparePartSupplier[]> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SparePartSupplier[]>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId), {
        includeMaster,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
