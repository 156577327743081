import { CARRIERS } from '@savgroup-front-common/types';
import { useFileInfoContext } from 'control/view/components/FileInfo/FileInfo.context';

const useCarrierName = (): string => {
  const { file } = useFileInfoContext();
  const trackingInfos = file?.trackingNumbers;
  const trackingInfo = trackingInfos.find(
    ({ handlingDirection }) => handlingDirection === 'ToOwner',
  );

  return trackingInfo?.carrier || CARRIERS.EXTERNAL_CARRIER;
};

export default useCarrierName;
