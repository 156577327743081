import { uniq } from 'lodash';
import get from 'lodash/get';
import { ActionMeta } from 'redux-actions';

import { Invoice } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { INVOICE_DOMAIN } from './constants';
import { FileId, InvoicesDomainState } from './types';

const initialState: InvoicesDomainState = {
  [INVOICE_DOMAIN.LIST]: {},
  [INVOICE_DOMAIN.BY_FILE_ID]: {},
};

export interface OnGetInvoiceSuccessPayload {
  value: Invoice[];
}
export interface OnGetInvoiceSuccessMeta {
  fileId: FileId;
}

function onGetInvoiceSuccess(
  state: InvoicesDomainState,
  {
    payload,
    meta,
  }: ActionMeta<OnGetInvoiceSuccessPayload, OnGetInvoiceSuccessMeta>,
): InvoicesDomainState {
  const { value } = payload;
  const invoices = value;

  return {
    ...state,
    [INVOICE_DOMAIN.LIST]: invoices.reduce((acc, invoice) => {
      return {
        ...acc,
        [invoice.id]: invoice,
      };
    }, state[INVOICE_DOMAIN.LIST]),
    [INVOICE_DOMAIN.BY_FILE_ID]: invoices.reduce((acc, invoice) => {
      return {
        ...acc,
        [meta.fileId]: uniq([...get(acc, meta.fileId, []), invoice.id]),
      };
    }, state[INVOICE_DOMAIN.BY_FILE_ID]),
  };
}

export default function mainReducer(
  state: InvoicesDomainState = initialState,
  action: ActionMeta<OnGetInvoiceSuccessPayload, OnGetInvoiceSuccessMeta>,
): InvoicesDomainState {
  switch (action.type) {
    case types.GET_INVOICES_BY_FILE_ID.SUCCEEDED:
      return onGetInvoiceSuccess(state, action);

    default:
      return state;
  }
}
