export interface IconPropsInterface {
  color?: string;
  size?: string;
}

export enum ICONS_TYPE {
  VEGAN_CIRCLE_ICON = 'VeganCircleIcon',
  UMBRELLA_ICON = 'UmbrellaIcon',
  STARS_ICON = 'StarsIcon',
  SMALL_SHOP_ICON = 'SmallShopIcon',
  SHOPPING_CART_ICON = 'ShoppingCartIcon',
  SLEEPER_CHAIR_ICON = 'SleeperChairIcon',
  SEND_MAIL_ICON = 'SendMailIcon',
  SCAN_BARCODE_ICON = 'ScanBarcodeIcon',
  SAD_FACE_ICON = 'SadFaceIcon',
  REFRESH_DOUBLE_ICON = 'RefreshDoubleIcon',
  RECEIVE_EUROS_ICON = 'ReceiveEurosIcon',
  QUESTION_MARK_ICON = 'QuestionMarkIcon',
  PHONE_ICON = 'PhoneIcon',
  PEOPLE_GROUP_ICON = 'PeopleGroupIcon',
  PACKAGES_ICON = 'PackagesIcon',
  OPEN_BOOK_ICON = 'OpenBookIcon',
  NEWSPAPER_ICON = 'NewspaperIcon',
  MULTIWINDOW_ICON = 'MultiwindowIcon',
  MEDIA_IMAGE_ICON = 'MediaImageIcon',
  LIGHT_BULB_ON_ICON = 'LightBulbOnIcon',
  LAPTOP_ICON = 'LaptopIcon',
  HOME_ICON = 'HomeIcon',
  HIGH_PRIORITY_ICON = 'HighPriorityIcon',
  HEADSET_HELP_ICON = 'HeadsetHelpIcon',
  GRID_REMOVE_ICON = 'GridRemoveIcon',
  DELIVERY_TRUCK_ICON = 'DeliveryTruckIcon',
  DELIVERY_ICON = 'DeliveryIcon',
  DELIVERY_BOX_ICON = 'DeliveryBoxIcon',
  CROSS_ICON = 'CrossIcon',
  CREDIT_CARD_ICON = 'CreditCardIcon',
  CRACKED_EGG_ICON = 'CrackedEggIcon',
  CONSUMABLE_ICON = 'ConsumableIcon',
  CHECK_CIRCLE_OUTLINE_ICON = 'CheckCircleOutlineIcon',
  CHAT_LINES_ICON = 'ChatLinesIcon',
  CART_ICON = 'CartIcon',
  CARD_WALLET_ICON = 'CardWalletIcon',
  CALENDAR_ICON = 'CalendarIcon',
  BREAKDOWN_ICON = 'BreakdownIcon',
  BOX_ICON = 'BoxIcon',
  TOOLS_ICON = 'ToolsIcon',
  ADD_DATABASE_SCRIPT_ICON = 'AddDatabaseScriptIcon',
  ADD_CIRCLE_OUTLINE_ICON = 'AddCircleOutlineIcon',
  BASELINE_ECO = 'BaselineEco',
  DEPOSIT_ICON = 'DepositIcon',
  SHIELD_ICON = 'ShieldIcon',
  INSERT_DRIVE_FILE_ICON = 'InsertDriveFileIcon',
  FORMAT_LIST_NUMBERED_IDON = 'FormatListNumberedIcon',
  POST_ADD_ICON = 'PostAddIcon',
  CONSTRUCTION_ICON = 'ConstructionIcon',
  GROUP_ICON = 'GroupIcon',
  EXCHANGE_ICON = 'ExchangeIcon',
}
