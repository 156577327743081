import styled from 'styled-components';

import { media, Z_INDEX_HEADER_SEARCH } from '@savgroup-front-common/constants';
import { rem } from '@savgroup-front-common/core/src/helpers';

import { SEARCH_HEIGHT } from '../../../Search.contants';
import { SimpleAnimationProps } from '@savgroup-front-common/core/src/animations/simpleAnimation';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';

export const $ResultError = styled.div<SimpleAnimationProps>`
  font-size: 1rem;

  ${decelerateTimingFunctionTransition};
  color: ${({ theme }) => theme.colors.danger};
  position: relative;
  z-index: ${Z_INDEX_HEADER_SEARCH};
  font-weight: bold;
  height: ${rem(SEARCH_HEIGHT.ERROR)};
  display: flex;
  align-items: center;
  padding: ${rem(14)};
  svg {
    margin-right: ${rem(16)};
    @media ${media.maxWidth.md} {
      display: none;
    }
  }
`;
