import styled from 'styled-components';

import { Z_INDEX_DOCUMENT_PREVIEW } from '@savgroup-front-common/constants/src/shared';
import { scaleDownToScaleIn } from '@savgroup-front-common/core/src/animations/stateAnimation';

export const $DocumentPreviewAnimation = styled.div`
  ${scaleDownToScaleIn};
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX_DOCUMENT_PREVIEW};
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`;
