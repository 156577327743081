import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ROLES } from '@savgroup-front-common/constants';
import { PERMISSIONS } from '@savgroup-front-common/constants/src/control';
import {
  useHasPermission,
  useHasSomePermissions,
  useHasSomeRoles,
} from '@savgroup-front-common/core/src/hooks';
import useHasSellerSettingsPagePermission from 'control/view/components/Settings/SellerSettings/SellerSettings.hooks';

import useHasBusinessIntelligencePermission from '../../../../pages/BusinessIntelligencePage/hooks/useHasBusinessIntelligencePermission';
import { transformToMenu } from '../Menu.helpers';
import { MENU_NAMES, MenuConf, MenuItem } from '../Menu.types';

const useGetMenuItems = (): { menus: MenuItem[]; index: number } => {
  const location: any = useLocation();

  const pathname = location.pathname || location?.location?.pathname;

  const hasGetOrdersPermission = useHasPermission(PERMISSIONS.GET_ORDER);
  const hasImportsPermission = useHasPermission(PERMISSIONS.IMPORT_MANAGEMENT);
  const isAdminDemo = useHasSomeRoles([
    ROLES.DEMO_ADMINISTRATOR,
    ROLES.ADMINISTRATOR,
  ]);
  const hasConsoleAccessRightsPermission = useHasPermission(
    PERMISSIONS.CONSOLE_ACCESS_RIGHTS,
  );

  const hasSellerSettingsPermissions = useHasSellerSettingsPagePermission();

  const hasUserManagementPermission = useHasPermission(
    PERMISSIONS.ROLE_MANAGEMENT,
  );
  const haseSanityTestsSettingsPermission = useHasPermission(
    PERMISSIONS.SANITY_TESTS,
  );

  const hasSparePartPermission = useHasSomePermissions([
    PERMISSIONS.ACCESS_SPARE_PART_REQUEST,
    PERMISSIONS.ACCESS_SPARE_PART_STOCK_ITEMS,
    PERMISSIONS.MANAGE_SPARE_PART_CATALOG,
  ]);

  const hasSomeFilePermissions = useHasSomePermissions([
    PERMISSIONS.SEARCH_FILE,
    PERMISSIONS.GET_WORKFLOW,
    PERMISSIONS.WORKFLOW_MANAGEMENT,
  ]);

  const hasBillingPermissions = useHasSomePermissions([
    PERMISSIONS.BILLING_ACCESS_RIGHTS,
  ]);

  const hasBusinessIntelligencePermission =
    useHasBusinessIntelligencePermission();

  const menus = useMemo(() => {
    const items: MenuConf[] = [
      {
        nav: MENU_NAMES.FILES,
        expandIfAlone: true,
        permission: hasSomeFilePermissions || isAdminDemo,
      },
      {
        nav: MENU_NAMES.ORDERS,
        permission: hasGetOrdersPermission || isAdminDemo,
      },
      {
        nav: MENU_NAMES.BUSINESS_INTELLIGENCE,
        permission: hasBusinessIntelligencePermission || isAdminDemo,
      },
      {
        nav: MENU_NAMES.SPARE_PARTS,
        expandIfAlone: true,
        forceIconIfAlone: true,
        permission: !isAdminDemo && hasSparePartPermission,
      },
      {
        nav: MENU_NAMES.SETTINGS,
        permission:
          isAdminDemo ||
          hasSellerSettingsPermissions ||
          hasUserManagementPermission ||
          haseSanityTestsSettingsPermission ||
          hasImportsPermission ||
          hasConsoleAccessRightsPermission,
      },
      {
        nav: MENU_NAMES.BILLINGS,
        permission: hasBillingPermissions,
      },
    ];

    return transformToMenu(items, pathname);
  }, [
    hasSomeFilePermissions,
    hasBusinessIntelligencePermission,
    hasGetOrdersPermission,
    hasSparePartPermission,
    isAdminDemo,
    hasSellerSettingsPermissions,
    hasUserManagementPermission,
    haseSanityTestsSettingsPermission,
    hasImportsPermission,
    hasConsoleAccessRightsPermission,
    hasBillingPermissions,
    pathname,
  ]);

  return {
    index: menus.findIndex((menu) => menu.isActive),
    menus,
  };
};

export default useGetMenuItems;
