import { createReducer } from '@savgroup-front-common/core/src/helpers';

import * as BusinessConst from '../businessConst';

export const initialState = {
  isLoaded: false,
  id: null,
  createdDate: null,
  issueId: null,
  issue: null,
  reason: null,
  solution: null,
  price: null,
  comment: null,
  isWarrantyApplied: false,
  warrantyUsed: null,
};

function onGetClaim(state, payload) {
  return {
    ...initialState,
    id: payload,
  };
}

function onGetClaimSucceeded(state, payload) {
  return {
    ...state,
    isLoaded: true,
    ...payload.value,
  };
}

const claimReducer = createReducer(initialState, {
  [BusinessConst.GET_CLAIM.BASE]: onGetClaim,
  [BusinessConst.GET_CLAIM.SUCCEEDED]: onGetClaimSucceeded,
});

export default claimReducer;
