const BUTTON = {
  SHARE_BY_LINK: 'ShareByLink',
  FORCE_CLOSE: 'ForceClose',
  SPLIT_FILE: 'SplitFile',
  REFRESH_WORKFLOW: 'RefreshWorkflow',
  SHOW_ACTIONS: 'ShowActions',
  GO_TO_ORDER_DETAILS_PAGE: 'GoToOrderDetailsPage',
  GO_TO_MESSAGES: 'GoToMessage',
  EDIT_ANY_ACTOR: 'EditAnyActor',
  WORKFLOW_VERSION: 'WorkflowVersion',
  WORKFLOW_VERSION_SUMMARY: 'WorkflowVersionSummary',
  LOAN: 'Loan',
  REOPEN_FILE: 'ReopenFile',
  CREATE_SPARE_PARTS_REQUEST: 'CreateSparePartRequest',
  DECLARE_SAFETY_RISK: 'DeclareSafetyRisk',
  MAKE_PAYMENT_REFUND: 'MakePaymentRefund',
  MAKE_COMMERCIAL_GESTURE: 'MakeCommercialGesture',
  DEBUG_INSPECTOR: 'DebugInspector',
};

export default BUTTON;
