import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}`;

const deleteClaimGroupCommand = async ({
  claimGroupId,
}: {
  claimGroupId: string;
}): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.DELETE,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default deleteClaimGroupCommand;
