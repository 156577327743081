import {
  FACET_INPUT_TYPE,
  FACET_TRIPLE_STATE_SWITCH_VALUES,
  PossibleFilter,
} from '@savgroup-front-common/types';

export const isTripleStateSwitchFacetActive = (
  filter: PossibleFilter,
): boolean => {
  return (
    filter.inputType === FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH &&
    filter.value !== FACET_TRIPLE_STATE_SWITCH_VALUES.IDDLE
  );
};

export const isSwitchFacetActive = (filter: PossibleFilter): boolean => {
  return filter.inputType === FACET_INPUT_TYPE.SWITCH && filter.value === true;
};

const isFacetActive = (filter: PossibleFilter): boolean => {
  return isSwitchFacetActive(filter) || isTripleStateSwitchFacetActive(filter);
};

export default isFacetActive;
