import { defineMessages } from 'react-intl';

export default defineMessages({
  enterKey: {
    id: 'view.search.control.key.enter',
    defaultMessage: 'Enter',
  },
  escapKey: {
    id: 'view.search.control.key.escap',
    defaultMessage: 'Escap',
  },
  ctrlKey: {
    id: 'view.search.control.key.ctrl',
    defaultMessage: 'Ctrl',
  },
  spaceKey: {
    id: 'view.search.control.key.space',
    defaultMessage: 'Space',
  },
  navigationLabel: {
    id: 'view.search.control.label.navigation',
    defaultMessage: 'to navigate',
  },
  escapLabel: {
    id: 'view.search.control.label.escape',
    defaultMessage: 'to close',
  },
  shortcutLabel: {
    id: 'view.search.control.label.shortcut',
    defaultMessage: 'quick access',
  },
});
