import { createAction } from '@reduxjs/toolkit';

import * as productSupplierActionTypes from './actionTypes';

export interface GetProductSupplierPayload {
  productSupplierId: string;
}

export const getProductSupplier = createAction<GetProductSupplierPayload>(
  productSupplierActionTypes.GET_PRODUCT_SUPPLIER.BASE,
);
