import uniqBy from 'lodash/uniqBy';

import { FILTER_FILES_TO_EXCLUDE } from '@savgroup-front-common/constants';
import {
  FACET_INPUT_TYPE,
  FACET_TRIPLE_STATE_SWITCH_VALUES,
  FilterCategory,
  PossibleFilter,
} from '@savgroup-front-common/types';
import { RawFilesListRubric } from 'control/domains/filesList/types';

import filterHiddenFileFacets from './filterHiddenFileFacets';
import sortFileRubric from './sortFileRubric';

const adaptRawRubricsToRubrics = (
  previousRubrics: FilterCategory[],
  rubrics: RawFilesListRubric[],
): FilterCategory[] => {
  return rubrics
    .filter(({ name }) => !FILTER_FILES_TO_EXCLUDE.includes(name))
    .map(filterHiddenFileFacets)
    .sort(sortFileRubric)
    .map<FilterCategory>(({ name, facets }) => {
      const previousCategory = previousRubrics.find(
        (oldRubric) => oldRubric.rubricName === name,
      );
      const previousValues = previousCategory?.values || [];

      const newFacets = facets.map<PossibleFilter>(({ key, label, count }) => {
        const shouldGoTop =
          previousCategory?.values?.find(
            (oldFacet) => oldFacet.facetName === key,
          )?.shouldGoTop || false;
        const isLocked =
          previousCategory?.values?.find(
            (oldFacet) => oldFacet.facetName === key,
          )?.isLocked || false;

        const previousValue = (previousValues?.find(
          (oldFacet) => oldFacet.facetName === key,
        )?.value ||
          FACET_TRIPLE_STATE_SWITCH_VALUES.IDDLE) as FACET_TRIPLE_STATE_SWITCH_VALUES;
        const isValid = [
          FACET_TRIPLE_STATE_SWITCH_VALUES.IDDLE,
          FACET_TRIPLE_STATE_SWITCH_VALUES.EXCLUDE,
          FACET_TRIPLE_STATE_SWITCH_VALUES.INCLUDE,
        ].includes(previousValue);

        const value = isValid
          ? previousValue
          : FACET_TRIPLE_STATE_SWITCH_VALUES.IDDLE;

        return {
          resultsCount: count,
          value,
          inputType: FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH,
          shouldGoTop,
          isLocked,
          isHidden: false,
          facetLabel: label,
          facetName: key,
        };
      });

      const merged = uniqBy([...newFacets], 'facetName').map((facet) => {
        const isHidden =
          facet.resultsCount === 0 &&
          facet.value === FACET_TRIPLE_STATE_SWITCH_VALUES.IDDLE;

        return { ...facet, isHidden };
      });

      return {
        rubricLabel: name,
        rubricName: name,
        values: merged.sort((a, b) => {
          if (a.shouldGoTop === true && b.shouldGoTop === false) {
            return -1;
          }
          if (a.shouldGoTop === false && b.shouldGoTop === true) {
            return 1;
          }
          if (a.shouldGoTop === false && b.shouldGoTop === false) {
            return 0;
          }

          return b.resultsCount - a.resultsCount < 0 ? -1 : 1;
        }),
      };
    });
};

export default adaptRawRubricsToRubrics;
