import { PERMISSIONS } from '@savgroup-front-common/constants';
import {
  useHasPermission,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';

const useHasTenantProfilePermission = (): boolean => {
  const isSuperAdmin = useIsSuperAdmin();

  return useHasPermission(PERMISSIONS.UPSERT_USER_PROFILE) && isSuperAdmin;
};

export default useHasTenantProfilePermission;
