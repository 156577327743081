import React, { FunctionComponent } from 'react';

import { ChevronDownIcon } from '../../protons/icons';

import { $TableOrderContainer } from './TableContent.styles';
import { TABLE_ORDER } from './TableContent.types';

interface TableOrderProps {
  order: TABLE_ORDER;
}

const TableOrder: FunctionComponent<TableOrderProps> = ({ order }) => (
  <$TableOrderContainer $order={order}>
    <ChevronDownIcon size="15px" />
  </$TableOrderContainer>
);

TableOrder.displayName = 'TableOrder';

export default TableOrder;
