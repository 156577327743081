import { DashboardSpeed } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const SpeedIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <DashboardSpeed width={size} height={size} color={color} />;

SpeedIcon.displayName = 'SpeedIcon';

export default SpeedIcon;
