import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

import { InputResult } from './types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/debugging/warranties`;

interface GtRulesDebuggingWarranties {
  claimId?: string;
  onlyMatchingRules?: boolean;
  roleNames?: string[];
}

interface GetRulesDebuggingWarrantiesResponseValue {
  includedWarranties?: {
    inputResults: InputResult[];
    depth: number;
    score: number;
    priority: number;
    name: string;
    overridable: boolean;
    additionalValues: Record<string, unknown>;
  }[];
  soldWarranties?: {
    inputResults: InputResult[];
    depth: number;
    score: number;
    priority: number;
    name: string;
    overridable: boolean;
    additionalValues: Record<string, unknown>;
  }[];
}
interface GetRulesDebuggingWarrantiesResponse extends BaseBackResponse {
  value: GetRulesDebuggingWarrantiesResponseValue;
}

async function getRulesDebuggingWarranties({
  claimId,
  onlyMatchingRules,
  roleNames = [],
}: GtRulesDebuggingWarranties): Promise<GetRulesDebuggingWarrantiesResponseValue> {
  const functionName = 'getRulesDebuggingWarranties';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  const response = await apiCall<GetRulesDebuggingWarrantiesResponse>(
    buildUrl(url, {
      onlyMatchingRules,
      roleNames,
    }),
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  return response.value;
}

export default getRulesDebuggingWarranties;
