import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ROUTES } from 'control/constants/routing';

const useMinimalLayout = () => {
  const location = useLocation();

  return useMemo(
    () =>
      matchPath(location.pathname || '', {
        path: [ROUTES.IFRAME],
      }),
    [location.pathname],
  );
};

export default useMinimalLayout;
