import {
  SEARCH_HIGHLIGHT_TYPE,
  SearchHighlight,
} from '@savgroup-front-common/types';

const adaptToBoldHighlight = (value: string): SearchHighlight => {
  return {
    type: SEARCH_HIGHLIGHT_TYPE.BOLD,
    value,
  };
};

export default adaptToBoldHighlight;
