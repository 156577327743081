import { defineMessages } from 'react-intl';

export default defineMessages({
  noPreviewAvailable: {
    id: 'view.attachmentPreview.noPreviewAvailable',
    defaultMessage: 'No preview available',
  },
  download: {
    id: 'view.attachmentPreview.download',
    defaultMessage: 'Download',
  },
});
