import { createSelector } from 'reselect';

import { CommonAppState } from '@savgroup-front-common/core/src/domains/CommonAppState';
import { getActionErrorsSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { RequestLineId, StockItemId } from '@savgroup-front-common/types';

import { STOCKS_DOMAIN_KEY } from '../constants';

import {
  GET_STOCK_ITEM_DETAILS,
  GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE,
} from './actionTypes';
import {
  STOCK_ITEM_DETAILS_DOMAIN,
  STOCK_ITEM_DETAILS_DOMAIN_KEY,
} from './constants';
import { StockItemDetailsDomainState } from './types';

interface State extends CommonAppState {
  [STOCKS_DOMAIN_KEY]: {
    [STOCK_ITEM_DETAILS_DOMAIN_KEY]: StockItemDetailsDomainState;
  };
}

const selectStockItemDetailsDomain = (
  state: State,
): StockItemDetailsDomainState =>
  state[STOCKS_DOMAIN_KEY][STOCK_ITEM_DETAILS_DOMAIN_KEY];

export const selectStockItemDetails = createSelector(
  [selectStockItemDetailsDomain],
  (state) => state[STOCK_ITEM_DETAILS_DOMAIN.STOCK_ITEM],
);
export const selectStockItemBySparePartRequestLineDomain = createSelector(
  [selectStockItemDetailsDomain],
  (state) => state[STOCK_ITEM_DETAILS_DOMAIN.BY_SPARE_PART_REQUEST_LINE_ID],
);

export const selectStockItemDetailsErrors = (
  state: State,
  { stockItemId }: { stockItemId: StockItemId },
) => getActionErrorsSelector(state, GET_STOCK_ITEM_DETAILS, stockItemId);

export const selectStockItemsDetailsBySparePartRequestLineErrors = (
  state: State,
  { sparePartRequestLineId }: { sparePartRequestLineId: string },
) =>
  getActionErrorsSelector(
    state,
    GET_STOCK_ITEMS_DETAILS_BY_SPARE_PART_REQUEST_LINE,
    sparePartRequestLineId,
  );

export const selectStockItemDetailsById = createSelector(
  [
    selectStockItemDetails,
    (_: State, { stockItemId }: { stockItemId: StockItemId }) => stockItemId,
  ],
  (state, stockItemId) => state[stockItemId],
);

export const selectStockItemIdBySparePartRequestLineId = createSelector(
  [
    selectStockItemBySparePartRequestLineDomain,
    (
      _: State,
      { sparePartRequestLineId }: { sparePartRequestLineId: RequestLineId },
    ) => sparePartRequestLineId,
  ],
  (stockItemIdsBySparePartRequestLineId, sparePartRequestLineId) =>
    stockItemIdsBySparePartRequestLineId?.[sparePartRequestLineId]?.map(
      (stockItemId) => stockItemId,
    ) || [],
);
