import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}stocks/authorized`;

export interface GetAuthorizedStocksByUserIdQueryArgs {
  userId: string;
}

interface StockLabel {
  stockId: string;
  stockName: string;
  sellerId: string;
}

export default async function getAuthorizedStocksByUserIdQuery(
  payload: GetAuthorizedStocksByUserIdQueryArgs,
): Promise<BackResponse<StockLabel[]> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<StockLabel[]>>(
      buildUrl(ENDPOINT, {
        userId: payload.userId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
