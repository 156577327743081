import { SearchResult } from '../Search.types';

import isResultFocusable from './isResultFocusabled';

const getNextSearchFocus = ({
  results,
  index,
  indexFocus,
}: {
  results: SearchResult[];
  index: number;
  indexFocus: number;
}): number | undefined => {
  const offsetIndex = index + indexFocus;
  const indexLessOffset = offsetIndex - results.length;
  const shoudResetOffset = indexLessOffset >= 0;
  const nextFocus = shoudResetOffset ? indexLessOffset : offsetIndex;

  return isResultFocusable(results[nextFocus]) ? nextFocus : undefined;
};

export default getNextSearchFocus;
