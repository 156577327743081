import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponse, BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/control-attachments`;

interface CreateFileAttachmentFromControlCommandArgs {
  fileId: string;
  transitionInfo: {
    moduleId: string;
    moduleDefinitionId: string;
    wave: number;
    from: string;
    to: string;
  };
  fileAttachmentId: string;
  name: string;
  extension: string;
}

export const createFileAttachmentFromControlCommand = async ({
  fileId,
  fileAttachmentId,
  transitionInfo,
  name,
  extension,
}: CreateFileAttachmentFromControlCommandArgs): Promise<BaseBackResponse> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BackResponse<BaseBackResponse>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        name,
        extension,
        transitionInfo,
        fileAttachmentId,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
