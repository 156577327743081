import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { FC, PropsWithChildren } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import useCommonTypedSelector from '@savgroup-front-common/core/src/domains/useCommonTypedSelector';
import { getAuthToken } from '@savgroup-front-common/core/src/helpers';
import { UserManagerConfig } from '@savgroup-front-common/core/src/services';

const httpLink = createHttpLink({
  uri: `${APIConfiguration.graphQlGateway}`,
});

const GraphqlProvider: FC<PropsWithChildren> = ({ children }) => {
  const { cache: userManagerConfigCache } = UserManagerConfig;
  const userManagerConfig = userManagerConfigCache?.userManagerConfig;
  const locale = useCommonTypedSelector(selectLocale);

  const authLink = setContext(() => {
    const token = userManagerConfig
      ? getAuthToken(window.localStorage, userManagerConfig())
      : undefined;

    return {
      headers: {
        'Accept-Language': locale,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

GraphqlProvider.displayName = 'GraphqlProvider';

export default GraphqlProvider;
