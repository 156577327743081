import React, { FunctionComponent } from 'react';

import { SwipeableDrawer } from '@savgroup-front-common/core/src/atoms/SwipeableDrawer';

import ShortcutsContent from './ShortcutsContent';

interface ShortcutsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ShortcutsModal: FunctionComponent<
  React.PropsWithChildren<ShortcutsModalProps>
> = ({ isOpen, onClose }) => {
  return (
    <SwipeableDrawer isOpen={isOpen} onClose={onClose}>
      <ShortcutsContent />
    </SwipeableDrawer>
  );
};

ShortcutsModal.displayName = 'ShortcutsModal';

export default ShortcutsModal;
