import { createAction } from '@reduxjs/toolkit';

import * as actionTypes from './actionTypes';

export interface GetUserPayload {
  userId: string;
}

export const getUser = createAction<GetUserPayload>(
  actionTypes.GET_USER_BY_ID.BASE,
);
