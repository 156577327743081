import { MessageDescriptor } from 'react-intl';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  DeliveryLocationType,
  FileProductSummary,
  RequestLineStatus,
  RequestReliability,
  ReverseMoney,
  SortOption,
  WarrantyTypeGroup,
} from '@savgroup-front-common/types';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';

const ENDPOINT = `${APIConfiguration.sparePart}requests/handling/tovalidate`;

export interface RequestValidationDto {
  fileId: string;
  oldestRequestDate: string;
  fileReference: string;
  fileProduct: FileProductSummary;
  sellerName: string;
  interventionsCount: number;
  warranty: string;
  warrantyTypeGroup: WarrantyTypeGroup;
  partsQuantity: number;
  partsPriceSum: ReverseMoney;
  productPrice: ReverseMoney;
  dpaCost: number;
  reliability: RequestReliability;
  status: RequestLineStatus;
  requestLines: RequestLineValidationDto[];
  requester?: string;
  vinCode: string;
  reparabilityCostRate: number;
  exceedSellerReparabilityThreshold: boolean;
  possibleNextStatuses: RequestLineStatus[];
  isFileClosed: boolean;
}

export interface RequestLineValidationDto {
  requestLineId: string;
  creationDate: string;
  label: string;
  manufacturerName: string;
  manufacturerReference: string;
  supplierReference?: string;
  supplierName: string;
  deliveryLocationType: DeliveryLocationType;
  deliveryLocationName: string;
  purchasePrice?: ReverseMoney;
  reliability: RequestReliability;
  status: RequestLineStatus;
  requester: string;
  possibleNextStatuses: RequestLineStatus[];
  stockItemId?: string;
  orderReference?: string;
}

export enum RUBRIC_TYPES {
  DEFAULT = 'default',
  DATE = 'date',
  RANGE_NUMERIC = 'rangeNumeric',
  NUMERIC = 'numeric',
  PERCENTAGE = 'percentage',
}

export interface FacetDto {
  name?: string;
  label?: string;
  included?: boolean;
  count?: number;
  key?: string;
}

export type RubricDefaultDto = {
  name: string;
  facets: FacetDto[];
  label: MessageDescriptor | string;
  type: RUBRIC_TYPES.DEFAULT | RUBRIC_TYPES.NUMERIC;
  filterName?: string;
};
export type FiltersDefaultDto = RubricDefaultDto[];

export type RubricRangeDto = {
  name: string;
  minValue?: number;
  maxValue?: number;
  label: MessageDescriptor | string;
  type: RUBRIC_TYPES.RANGE_NUMERIC | RUBRIC_TYPES.PERCENTAGE;
  filterName?: string;
  facets?: FacetDto[];
};
export type FiltersRangeDto = RubricRangeDto[];

export type RubricDateDto = {
  name: string;
  minValue?: Date;
  maxValue?: Date;
  label: MessageDescriptor | string;
  type: RUBRIC_TYPES.DATE;
  filterName?: string;
  facets?: FacetDto[];
};
export type FiltersDateDto = RubricDateDto[];

interface GetRequestsToValidateCommandPayload {
  query: string;

  page: number;
  pageSize: number;
  sortOptions?: SortOption[];

  isDescending?: boolean;
  sort?: string;
  filters?: FiltersDefaultDto;
  numericFilters?: FiltersRangeDto;
  dateTimeFilters?: FiltersDateDto;
}

export async function searchRequestsToValidateCommand({
  page,
  pageSize,
  sortOptions = [],
  sort,
  isDescending,
  query,
  filters = [],
  numericFilters = [],
  dateTimeFilters = [],
}: GetRequestsToValidateCommandPayload): Promise<
  BackResponse<SearchResultList<RequestValidationDto>> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SearchResultList<RequestValidationDto>>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          page,
          pageSize,
          sortOptions,
          sort,
          isDescending,
          query,
          filters,
          numericFilters,
          dateTimeFilters,
        },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
