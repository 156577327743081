import { PERMISSIONS, ROLES } from '@savgroup-front-common/constants';
import {
  useHasSomePermissions,
  useHasSomeRoles,
} from '@savgroup-front-common/core/src/hooks';

const useHasSellerAccountPermission = (): boolean => {
  const isAdminDemo = useHasSomeRoles([
    ROLES.DEMO_ADMINISTRATOR,
    ROLES.ADMINISTRATOR,
  ]);
  const hasPermission = useHasSomePermissions([
    PERMISSIONS.SELLER_CONFIGURATION,
    PERMISSIONS.SELLER_MANAGEMENT,
  ]);

  return hasPermission || isAdminDemo;
};

export default useHasSellerAccountPermission;
