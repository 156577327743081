import { Group } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const GroupIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Group width={size} height={size} color={color} />;

GroupIcon.displayName = 'GroupIcon';

export default GroupIcon;
