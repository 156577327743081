import React, { FC } from 'react';

import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import { $SearchResultHeader } from './SearchResultHeader.styles';

interface SearchResultHeaderProps {
  iconColor?: {
    color: string;
    bgColor: string;
  };
  title?: MessageType;
  count: number;
}

export const SearchResultHeader: FC<SearchResultHeaderProps> = ({
  iconColor,
  title,
  count,
}) => {
  return (
    <$SearchResultHeader
      $color={iconColor?.color}
      $bgColor={iconColor?.bgColor}
    >
      {title && <SafeFormattedMessageWithoutSpread message={title} />}

      <Tag isSmall withoutMargin>
        {count}
      </Tag>
    </$SearchResultHeader>
  );
};
