import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { ICONS_TYPE } from '@savgroup-front-common/core/src/protons/icons/ConfigurationConsole/Icon.types';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.SELLER_ID}/ruledefinition/${COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE}`;

export enum ConsoleRuleColumnType {
  LOOKUP = 'Lookup',
  ENUM = 'Enum',
  DURATION = 'Duration',
  TEXT = 'Text',
  BOOLEAN = 'Boolean',
  DOUBLE = 'Double',
  INTEGER = 'Integer',
  BUTTON = 'Button',
  ICON = 'Icon',
}

export enum FlexSize {
  XS = 1,
  SM = 2,
  MD = 4,
  LG = 6,
  XL = 8,
}

export interface RuleDefinitionDto {
  canAddMulti?: boolean;
  canAddOne?: boolean;
  columnName: string;
  columnIcon?: ICONS_TYPE;
  columnType: ConsoleRuleColumnType;
  isInput: boolean;
  isMandatory: boolean;
  isExpandable: boolean;
  /** @deprecated: use isColumnMultiValue * */
  isMultiValue: boolean;
  IsFilterActiveByDefault: boolean;
  localizationKey?: string;
  filterValues?: {
    value?: string;
    displayName?: string;
  }[];
  enumDefinition?: {
    value?: string;
    localizationKey?: string;
  }[];
  dataSource?: string;
  dataSourceName?: string;
  flexSize?: FlexSize;
  /** @deprecated: use rowDisplayOrder,upsertDisplayOrder  * */
  order: number;
  isEan?: boolean;
  hiddenWhenEanVisible?: boolean;
  couldEnabled?: boolean;
  enumValues?: string[];
  allValues?: {
    id: string;
    name: string;
    sellerId?: string;
  }[];
  upsertDisplayOrder?: number;
  isColumnMultiValue?: boolean;
  isFilterActiveByDefault?: boolean;
  isFilterMultiValue?: boolean;
  rowDisplayOrder?: number;
  isTag?: boolean;
  isTitle?: boolean;
  isFilterable?: boolean;
  isColumnVisible?: boolean;
  isCreatable: boolean;
  isEditable: boolean;
  isHierarchical?: boolean;
  isHiddenCard?: boolean;
  isBold?: boolean;
  defaultValue?: string;
}

export type RuleColumnDefinitionDto = Record<string, RuleDefinitionDto>;

async function getRuleDefinitionQuery({
  sellerId,
  ruleType,
  IsDebug,
}: {
  sellerId: string;
  ruleType: string;
  IsDebug?: boolean;
}): Promise<BackResponse<RuleColumnDefinitionDto> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace(COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE, ruleType);

    return await apiCall<BackResponse<RuleColumnDefinitionDto>>(
      buildUrl(url, { IsDebug }),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getRuleDefinitionQuery;
