import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { StockItemGroupInInventorySummaryDto } from './type/StockItemGroupInInventorySummaryDto';

 
const ENDPOINT = `${APIConfiguration.sparePart}stockInInventory/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}/Add`;

export interface AddStockItemGroupInInventoryHandlerCommandArgs {
  stockInInventoryId: string;
  sparePartId: string;
  countQuantity: number;
  comment: string;
}

export default async function addStockItemGroupInInventoryHandlerCommand({
  stockInInventoryId,
  sparePartId,
  countQuantity,
  comment,
}: AddStockItemGroupInInventoryHandlerCommandArgs): Promise<
  BackResponse<StockItemGroupInInventorySummaryDto> | BackResponseFailure
> {
  try {
    const response = await apiCall<
      BackResponse<StockItemGroupInInventorySummaryDto>
    >(ENDPOINT.replace(COMMON_QUERY_PARAMS.STOCK_ITEM_ID, stockInInventoryId), {
      method: SUPPORTED_METHODS.POST,
      json: {
        sparePartId,
        countQuantity,
        comment,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
