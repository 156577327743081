import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const FILES_SEARCH = createRequest('FILES_SEARCH');
export const FILES_LIST_CHANGE_FILTER = createRequest(
  'FILES_LIST_CHANGE_FILTER',
);
export const FILES_LIST_CHANGE_MULTPLE_FILTER = createRequest(
  'FILES_LIST_CHANGE_MULTPLE_FILTER',
);
export const FILES_LIST_LOCK_FILTER = createRequest('FILES_LIST_LOCK_FILTER');
export const FILES_LIST_CHANGE_SEARCH_QUERY = createRequest(
  'FILES_LIST_CHANGE_SEARCH_QUERY',
);
export const TOGGLE_FILES_LIST_RESULTS_ORDER = createRequest(
  'TOGGLE_FILES_LIST_SEARCH_ORDER',
);
export const FILES_LIST_SELECT_PAGE = createRequest('FILES_LIST_SELECT_PAGE');
export const FILES_LIST_RESET_FILTERS = createRequest(
  'FILES_LIST_RESET_FILTERS',
);
export const FILES_LIST_RESET_FILTERS_AND_LOCK = createRequest(
  'FILES_LIST_RESET_FILTERS_AND_LOCK',
);
export const REORDER_FILES_LIST_FILTERS = createRequest(
  'REORDER_FILES_LIST_FILTERS',
);

export const FILES_FILTER_REFRESH = createRequest('FILES_FILTER_REFRESH');
