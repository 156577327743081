import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, SellerExport } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.marketplace}returnShippingExports/Amazon/seller/${COMMON_QUERY_PARAMS.SELLER_ID}`;

export interface GetSellerExportBySellerIdArgs {
  sellerId: string;
}

interface GetSellerExportBySellerIdResponse extends BaseBackResponse {
  failure: false;
  value: SellerExport[];
}
interface GetSellerExportBySellerIdResponseError extends BaseBackResponse {
  failure: true;
}

export default async function getSellerExportBySellerId(
  payload: GetSellerExportBySellerIdArgs,
): Promise<
  GetSellerExportBySellerIdResponse | GetSellerExportBySellerIdResponseError
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      payload.sellerId,
    );

    const response = await apiCall<GetSellerExportBySellerIdResponse>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return { errors: (exception as any).errors, failure: true };
  }
}
