import { FolderSettings } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const SettingsSuggest: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <FolderSettings width={size} height={size} color={color} />;

SettingsSuggest.displayName = 'SettingsSuggest';

export default SettingsSuggest;
