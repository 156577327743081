import styled from 'styled-components';

import { rem } from '../../helpers';

export const $KeyShortcut = styled.div<{ $height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.mini};
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  color: ${({ theme }) => theme.colors.mainTextColor};

  margin-right: ${rem(2)};
  &:not(:first-child) {
    margin-left: ${rem(2)};
  }
  height: ${({ $height }) => rem($height)};
  min-width: ${({ $height }) => rem($height)};
  line-height: ${({ $height }) => rem($height)};
  span:not(:last-child) {
    padding-right: ${rem(4)};
  }
  span:not(:first-child) {
    padding-left: ${rem(4)};
  }
  padding-right: ${rem(4)};
  padding-left: ${rem(4)};
  svg {
    max-width: 20px;
    color: ${({ theme }) => theme.colors.mainTextColor};
  }
`;
