import { AnyAction } from 'redux';
import { Action, ActionMeta } from 'redux-actions';

import {
  BaseBackResponse,
  SparePartRequestLine,
} from '@savgroup-front-common/types';

import {
  SetSparePartRequestLineListFiltersPayload,
  SetSparePartRequestLineListIsDescendingPayload,
  SetSparePartRequestLineListQueryPayload,
  UpdateSparePartRequestLineListItemStatusPayload,
} from './actionCreators';
import * as actionTypes from './actionTypes';
import { SPARE_PART_REQUEST_LINE_DOMAIN } from './constants';
import {
  SparePartRequestLineListDomainState,
  SparePartRequestLineRubric,
} from './types';

export const initialSparePartRequestLineState: SparePartRequestLineListDomainState =
  {
    [SPARE_PART_REQUEST_LINE_DOMAIN.LIST]: {},
    [SPARE_PART_REQUEST_LINE_DOMAIN.CURRENT_PAGE_NUMBER]: undefined,
    [SPARE_PART_REQUEST_LINE_DOMAIN.TOTAL_COUNT]: 0,
    [SPARE_PART_REQUEST_LINE_DOMAIN.BY_PAGE]: [],
    [SPARE_PART_REQUEST_LINE_DOMAIN.QUERY]: '',
    [SPARE_PART_REQUEST_LINE_DOMAIN.IS_DESCENDING]: true,
    [SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS]: [],
  };

interface OnGetSparePartRequestLineSuccessPayload extends BaseBackResponse {
  value: {
    rubrics: SparePartRequestLineRubric[];
    hits: SparePartRequestLine[];
    hitCount: number;
  };
}

function onSetSparePartRequestLineFilters(
  state: SparePartRequestLineListDomainState,
  {
    payload: { filterCategoryName, filterName, active: isActive },
  }: Action<SetSparePartRequestLineListFiltersPayload>,
) {
  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS]: state[
      SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS
    ].map((rubric) => ({
      ...rubric,
      facets: rubric.facets.map((item) => {
        if (rubric.name === filterCategoryName && item.name === filterName) {
          return { ...item, isActive };
        }

        return item;
      }),
    })),
  };
}
function onResetSparePartRequestLineFilters(
  state: SparePartRequestLineListDomainState,
) {
  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS]: state[
      SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS
    ].map((rubric) => ({
      ...rubric,
      facets: rubric.facets.map((item) => ({
        ...item,
        isActive: false,
      })),
    })),
  };
}

function onGetSparePartRequestLineSuccess(
  state: SparePartRequestLineListDomainState,
  {
    payload,
    meta,
  }: ActionMeta<OnGetSparePartRequestLineSuccessPayload, { page: number }>,
): SparePartRequestLineListDomainState {
  const sparePartRequestLines = payload?.value?.hits;

  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DOMAIN.LIST]: sparePartRequestLines.reduce(
      (acc, sparePartRequestLines) => {
        return {
          ...acc,
          [sparePartRequestLines.requestLineId]: sparePartRequestLines,
        };
      },
      state[SPARE_PART_REQUEST_LINE_DOMAIN.LIST],
    ),
    [SPARE_PART_REQUEST_LINE_DOMAIN.CURRENT_PAGE_NUMBER]: meta.page,
    [SPARE_PART_REQUEST_LINE_DOMAIN.TOTAL_COUNT]: payload.value.hitCount,
    [SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS]: payload.value.rubrics.map(
      (rubric) => ({
        ...rubric,
        facets: rubric.facets.map((item) => ({
          isActive:
            state[SPARE_PART_REQUEST_LINE_DOMAIN.RUBRICS]
              .find((c) => c.name === rubric.name)
              ?.facets.find((c) => c.name === item.name)?.isActive || false,
          ...item,
        })),
      }),
    ),
    [SPARE_PART_REQUEST_LINE_DOMAIN.BY_PAGE]: [
      ...state[SPARE_PART_REQUEST_LINE_DOMAIN.BY_PAGE].slice(0, meta.page - 1),
      sparePartRequestLines.map(({ requestLineId }) => requestLineId),
    ],
  };
}

function onSetSparePartRequestLinesQueryAction(
  state: SparePartRequestLineListDomainState,
  { payload }: Action<SetSparePartRequestLineListQueryPayload>,
): SparePartRequestLineListDomainState {
  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DOMAIN.QUERY]: payload.query,
  };
}

function onSetSparePartRequestLinesIsDescendingAction(
  state: SparePartRequestLineListDomainState,
  { payload }: Action<SetSparePartRequestLineListIsDescendingPayload>,
): SparePartRequestLineListDomainState {
  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DOMAIN.IS_DESCENDING]: payload.isDescending,
  };
}

function onUpdateSparePartRequestLineListStatusAction(
  state: SparePartRequestLineListDomainState,
  { payload }: Action<UpdateSparePartRequestLineListItemStatusPayload>,
): SparePartRequestLineListDomainState {
  const requestLine =
    state[SPARE_PART_REQUEST_LINE_DOMAIN.LIST]?.[
      payload.sparePartRequestLineId
    ];

  if (!requestLine) {
    return state;
  }

  return {
    ...state,
    [SPARE_PART_REQUEST_LINE_DOMAIN.LIST]: {
      ...state[SPARE_PART_REQUEST_LINE_DOMAIN.LIST],
      [payload.sparePartRequestLineId]: {
        ...requestLine,
        requestLineStatus: payload.requestLineStatus,
      },
    },
  };
}

export default function requestLinesReducer(
  state: SparePartRequestLineListDomainState = initialSparePartRequestLineState,
  action: AnyAction,
): SparePartRequestLineListDomainState {
  switch (action.type) {
    case actionTypes.GET_SPARE_PART_REQUEST_LINE_LIST.SUCCEEDED:
      return onGetSparePartRequestLineSuccess(
        state,
        action as ActionMeta<
          OnGetSparePartRequestLineSuccessPayload,
          { page: number }
        >,
      );
    case actionTypes.SET_SPARE_PART_REQUEST_LINE_LIST_QUERY.BASE:
      return onSetSparePartRequestLinesQueryAction(
        state,
        action as Action<SetSparePartRequestLineListQueryPayload>,
      );
    case actionTypes.SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING.BASE:
      return onSetSparePartRequestLinesIsDescendingAction(
        state,
        action as Action<SetSparePartRequestLineListIsDescendingPayload>,
      );
    case actionTypes.SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.BASE:
      return onSetSparePartRequestLineFilters(
        state,
        action as Action<SetSparePartRequestLineListFiltersPayload>,
      );
    case actionTypes.RESET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.BASE:
      return onResetSparePartRequestLineFilters(state);

    case actionTypes.UPDATE_SPARE_PART_REQUEST_LINE_LIST_ITEM_STATUS.BASE:
      return onUpdateSparePartRequestLineListStatusAction(
        state,
        action as Action<UpdateSparePartRequestLineListItemStatusPayload>,
      );

    default:
      return state;
  }
}
