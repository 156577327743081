 
import React, { FC } from 'react';
import {
  $SearchResultItemContent,
  $SearchResultName,
  $SearchResultRef,
} from '../SearchResult.styles';
import { ResultSearchType } from '../../SearchWithDimmer.types';
import { getOtherHighlights } from '../helpers/getHighlights';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

const ResultHighlight: FC<{
  searchValue?: string;
  hit: ResultSearchType;
  color?: string;
}> = ({ searchValue, hit, color }) => {
  const {
    searchReference,
    searchLocalizedInfo,
    searchFirstName,
    searchLastName,
    highlights,
  } = hit;

  const injectClassHightlight = ({
    text,
    searchValue,
  }: {
    text?: string;
    searchValue?: string;
  }) => {
    if (
      !text ||
      !searchValue ||
      !text.toLowerCase().includes(searchValue.toLowerCase())
    ) {
      return text;
    }
    const index = text.toLowerCase().indexOf(searchValue.toLowerCase());
    const searchLen = searchValue.length;
    const word = text.substring(index, index + searchLen);

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: text.replaceAll(
            word,
            `<span class='hightlighted'>${word}</span>`,
          ),
        }}
      />
    );
  };

  const reference = (
    <b>{injectClassHightlight({ text: searchReference, searchValue })}</b>
  );
  const solution = injectClassHightlight({
    text: searchLocalizedInfo,
    searchValue,
  });
  const firstName = injectClassHightlight({
    text: searchFirstName,
    searchValue,
  });
  const lastName = injectClassHightlight({
    text: searchLastName,
    searchValue,
  });
  const otherHighlights = getOtherHighlights(highlights);

  return (
    <$SearchResultItemContent $color={color}>
      <$SearchResultRef>
        {reference} - {solution}
      </$SearchResultRef>
      <$SearchResultName>
        {firstName} {lastName}{' '}
      </$SearchResultName>
      {otherHighlights && (
        <$SearchResultName>
          <span>
            <SafeFormattedMessageWithoutSpread
              message={otherHighlights?.label}
            />
          </span>
          {': '}
          <span
            dangerouslySetInnerHTML={{
              __html: otherHighlights?.value,
            }}
          />
        </$SearchResultName>
      )}
    </$SearchResultItemContent>
  );
};

ResultHighlight.displayName = 'ResultHighlight';
export default ResultHighlight;
