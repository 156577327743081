import React, { FC, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { FileIcon } from '@savgroup-front-common/core/src/protons/icons';

import DocumentPreview from '../DocumentPreview';

import { $AttachmentListTable } from './AttachmentsList.styles';

interface AttachmentsListProps {
  attachments: {
    filename: string;
    attachmentId: string;
  }[];
  printIcon?: boolean;
}

const AttachmentsList: FC<AttachmentsListProps> = ({
  attachments,
  printIcon,
}) => {
  const theme = useTheme();
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false);
  const [currentActivePreview, setCurrentActivePreview] = useState(0);
  const documents = attachments.map((attachment) => ({
    documentName: attachment.filename,
    url: attachment.attachmentId,
  }));

  const handleClickOpen = useCallback((index: number) => {
    setOpenDocumentPreview(true);
    setCurrentActivePreview(index);
  }, []);

  if (!attachments.length) {
    return <></>;
  }

  return (
    <>
      <DocumentPreview
        attachments={documents}
        isOpen={openDocumentPreview}
        onClose={() => setOpenDocumentPreview(false)}
        currentActivePreview={currentActivePreview}
      />

      {attachments.map((attachment, index) => (
        <$AttachmentListTable
          onClick={() => handleClickOpen(index)}
          key={attachment.attachmentId}
        >
          {printIcon ? (
            <FileIcon size="14px" color={theme.colors.primary} />
          ) : null}
          {attachment.filename}
        </$AttachmentListTable>
      ))}
    </>
  );
};

AttachmentsList.displayName = 'AttachmentsList';

export default AttachmentsList;
