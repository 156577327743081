import React from 'react';

import Icon from './Icon';

interface LaptopIconProps {
  color?: string;
  size?: string;
}

const LaptopIcon: React.FC<LaptopIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M5.48865 23.5552V7.2C5.48865 6.35131 5.82579 5.53737 6.42591 4.93726C7.02602 4.33714 7.83996 4 8.68865 4H30.4486C31.2973 4 32.1113 4.33714 32.7114 4.93726C33.3115 5.53737 33.6486 6.35131 33.6486 7.2V23.5552M5.48865 23.5552H33.6486M5.48865 23.5552L3.12065 31.9296C2.98616 32.4054 2.96376 32.9059 3.05521 33.3918C3.14666 33.8778 3.34947 34.3359 3.64773 34.7303C3.94598 35.1246 4.33157 35.4445 4.77424 35.6648C5.2169 35.8851 5.70459 35.9999 6.19905 36H32.9383C33.4327 35.9999 33.9204 35.8851 34.3631 35.6648C34.8057 35.4445 35.1913 35.1246 35.4896 34.7303C35.7878 34.3359 35.9906 33.8778 36.0821 33.3918C36.1735 32.9059 36.1511 32.4054 36.0167 31.9296L33.6486 23.5552"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M17.9685 31.2H21.1685"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

LaptopIcon.displayName = 'LaptopIcon';

export default LaptopIcon;
