import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

import { APIConfiguration } from '@savgroup-front-common/configuration';

import { $DisplayValue, $FlagImageValue } from '../CountryAutocomplete.styles';

const MultiValue: FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  const { data, innerProps, children } = props;
  const countryCode = data?.cultureCode || data?.value || undefined;

  return (
    <components.MultiValue
      {...props}
      innerProps={{ ...innerProps, 'data-value': countryCode }}
    >
      <$DisplayValue>
        <$FlagImageValue
          src={`${APIConfiguration.catalogCDN}miscellaneous/flags/${countryCode}.svg`}
          alt={countryCode}
        />
        {children}
      </$DisplayValue>
    </components.MultiValue>
  );
};

MultiValue.displayName = 'MultiValue';

export default MultiValue;
