import uniqBy from 'lodash/uniqBy';
import { Action, ActionMeta } from 'redux-actions';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { FORM_MODE } from '@savgroup-front-common/constants/src/control';

import { SetUserPayload } from './actionCreators';
import * as ActionTypes from './actionTypes';
import {
  AssignableUserPermission,
  AssignableUserProfile,
  AssignableUserRole,
  USER_MANAGEMENT_DOMAIN,
  UserConfiguration,
  UserManagmentDomainState,
} from './types';

const initialState: UserManagmentDomainState = {
  [USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER]: [],
  [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_ROLES]: [],
  [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES]: [],
  [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PERMISSIONS]: [],
};

function onCreateUserManagementSuccess(
  state: UserManagmentDomainState,
  { payload }: { payload: SetUserPayload },
): UserManagmentDomainState {
  const { userId, lastname, firstname, email, credentials, type } = payload;

  const oldValue = state[USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER];

  if (type === FORM_MODE.DELETE) {
    return {
      ...state,
      [USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER]: oldValue.filter(
        (user) => userId !== user.userId,
      ),
    };
  }

  const previousUserIndex = oldValue.findIndex(
    (user) => userId === user.userId,
  );

  const nextUser = {
    lastname,
    firstname,
    mail: email,
    credentials,
    userId,
  };

  if (previousUserIndex >= 0) {
    return {
      ...state,
      [USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER]: [
        ...oldValue.slice(0, previousUserIndex),
        nextUser,
        ...oldValue.slice(previousUserIndex + 1, oldValue.length),
      ],
    };
  }

  return {
    ...state,
    [USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER]: [...oldValue, nextUser],
  };
}

function onManagementUsersLoaded(
  state: UserManagmentDomainState,
  action: Action<{ value: UserConfiguration[] }>,
): UserManagmentDomainState {
  return {
    ...state,
    [USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER]: action.payload.value,
  };
}

function onRolesLoaded(
  state: UserManagmentDomainState,
  action: Action<{ value: AssignableUserRole[] }>,
): UserManagmentDomainState {
  return {
    ...state,
    [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_ROLES]: action.payload.value,
  };
}

export function onSetUserProfil(
  state: UserManagmentDomainState,
  action: Action<{ value: AssignableUserProfile }>,
): UserManagmentDomainState {
  const oldValue = state[USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES];

  const profile = action.payload.value;

  const index = oldValue.findIndex(
    ({ userProfileId }) => userProfileId === profile.userProfileId,
  );

  if (index >= 0) {
    return {
      ...state,
      [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES]: [
        ...oldValue.slice(0, index),
        profile,
        ...oldValue.slice(index + 1, oldValue.length),
      ],
    };
  }

  return {
    ...state,
    [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES]: [...oldValue, profile],
  };
}

export function ondeleteUserProfil(
  state: UserManagmentDomainState,
  action: ActionMeta<any, { userProfileId: string }>,
): UserManagmentDomainState {
  const oldValue = state[USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES];

  const deletedUserProfileId = action.meta.userProfileId;

  const index = oldValue.findIndex(
    ({ userProfileId }) => userProfileId === deletedUserProfileId,
  );

  if (index >= 0) {
    return {
      ...state,
      [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES]: [
        ...oldValue.slice(0, index),
        ...oldValue.slice(index + 1, oldValue.length),
      ],
    };
  }

  return state;
}

export function onGetUserProfil(
  state: UserManagmentDomainState,
  action: Action<{ value: AssignableUserProfile[] }>,
): UserManagmentDomainState {
  const oldValue = state[USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES];

  return {
    ...state,
    [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES]: uniqBy(
      [...action.payload.value, ...oldValue],
      'userProfileId',
    ),
  };
}
export function onGetAssignablePermissions(
  state: UserManagmentDomainState,
  action: Action<{ value: AssignableUserPermission[] }>,
): UserManagmentDomainState {
  const oldValue = state[USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PERMISSIONS];

  return {
    ...state,
    [USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PERMISSIONS]: uniqBy(
      [...action.payload.value, ...oldValue],
      'shortName',
    ),
  };
}

export default function mainReducer(state = initialState, action: any) {
  try {
    switch (action.type) {
      case ActionTypes.CREATE_USER_MANAGEMENT.SUCCEEDED:
        return onCreateUserManagementSuccess(state, action);
      case ActionTypes.GET_MANAGEMENT_USERS.SUCCEEDED:
        return onManagementUsersLoaded(state, action);
      case ActionTypes.GET_CONFIGURABLE_ROLES.SUCCEEDED:
        return onRolesLoaded(state, action);
      case ActionTypes.SET_USER_PROFIL.SUCCEEDED:
        return onSetUserProfil(state, action);
      case ActionTypes.GET_USER_PROFILES.SUCCEEDED:
        return onGetUserProfil(state, action);
      case ActionTypes.DELETE_USER_PROFIL.SUCCEEDED:
        return ondeleteUserProfil(state, action);
      case ActionTypes.GET_ASSIGNABLE_PERMISSIONS.SUCCEEDED:
        return onGetAssignablePermissions(state, action);

      default:
        return state;
    }
  } catch (e) {
    logCritical(e as any);

    return state;
  }
}
