import React, { FunctionComponent, ReactElement } from 'react';

import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import PageContainer from '../PageContainer';

import getSplittedText from './helpers/getSplittedText';
import messages from './messages';
import {
  $NoItemSelectedContainer,
  $NoItemSelectedText,
} from './NoItemSelected.styles';

const splitText = ([text]: [string]) => {
  const [start, end] = getSplittedText(text);

  return (
    <>
      <$NoItemSelectedText>{start}</$NoItemSelectedText>
      {end ? <$NoItemSelectedText>{end}</$NoItemSelectedText> : null}
    </>
  );
};

interface NoItemSelectedProps {
  icon: ReactElement;
  message?: MessageType | null;
}

const NoItemSelected: FunctionComponent<
  React.PropsWithChildren<NoItemSelectedProps>
> = ({ icon, message = messages.selectItem }) => {
  return (
    <PageContainer>
      <$NoItemSelectedContainer data-id="data-no-item-selected">
        {icon}
        {message && (
          <SafeFormattedMessage {...message}>{splitText}</SafeFormattedMessage>
        )}
      </$NoItemSelectedContainer>
    </PageContainer>
  );
};

NoItemSelected.displayName = 'NoItemSelected';

export default NoItemSelected;
