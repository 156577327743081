import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Quotation,
  QuotationNewModelDto,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotation/currentDraft`;

interface GetCurrentQuotationArgs {
  fileId: string;
}

export default async function getCurrentQuotation(
  payload: GetCurrentQuotationArgs,
): Promise<
  BackResponse<Quotation | QuotationNewModelDto> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<Quotation>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
