import map from 'lodash/map';
import values from 'lodash/values';
import React, { FunctionComponent } from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { getFromDataByKey } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { WaitUntilLoaded } from '../../atoms';

import { $ExplodeFileModalBody } from './ExplodeFileModal.styles';
import FileItem from './FileItem';
import messages from './messages';

interface ExplodeFileModalProps {
  closeModal: () => void;
  explodeFileState: any;
}

const ExplodeFileModal: FunctionComponent<ExplodeFileModalProps> = ({
  explodeFileState,
  closeModal,
}) => {
  const isSuccess =
    explodeFileState &&
    getFromDataByKey(explodeFileState, 'value') &&
    getFromDataByKey(explodeFileState, 'value').every((file: any) =>
      getFromDataByKey(file, 'processed'),
    );

  return (
    <>
      <Heading level={2}>
        <SafeFormattedMessageWithoutSpread
          message={messages.explodeFileTitle}
        />
      </Heading>
      {!isSuccess && <BaseLoader messageList={[messages.pleaseWait]} />}
      <Banner
        isOpen={isSuccess}
        notificationType={NOTIFICATION_TYPES.SUCCESS}
        isFluid
      >
        <SafeFormattedMessageWithoutSpread
          message={messages.explodedSuccessfully}
        />
      </Banner>
      <WaitUntilLoaded
        data={explodeFileState}
        renderComponent={(explodeState: any) => (
          <$ExplodeFileModalBody>
            {map(values(explodeState.toJS()), (file, index) => (
              <FileItem {...file} order={index} closeModal={closeModal} />
            ))}
          </$ExplodeFileModalBody>
        )}
      />
    </>
  );
};

ExplodeFileModal.displayName = 'ExplodeFileModal';

export default ExplodeFileModal;
