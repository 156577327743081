import { AppIncident, INCIDENT_STATUSES } from '@savgroup-front-common/types';

import {
  displayResolvedIncident,
  displayUnresolvedIncident,
} from './toastList';

const UNRESOLVED_INCIDENT_STATUS = [
  INCIDENT_STATUSES.IDENTIFIED,
  INCIDENT_STATUSES.INVESTIGATING,
  INCIDENT_STATUSES.MONITORING,
];
const RESOLVED_INCIDENT_STATUS = [
  INCIDENT_STATUSES.RESOLVED,
  INCIDENT_STATUSES.POSTMORTEM,
];

const processIncident = (
  previousIncidents: AppIncident[],
  newIncident: AppIncident,
  componentName: string,
): void => {
  const isCurrentComponent = newIncident.components.some(
    (component) => component.name === componentName,
  );

  if (!isCurrentComponent) {
    return;
  }

  const previousAppStatus = previousIncidents.find(
    (appStatus) => newIncident.id === appStatus.id,
  );

  const lastestNewUpdate = newIncident.incidentUpdates[0];

  if (previousAppStatus) {
    const lastestPreviousUpdate = previousAppStatus.incidentUpdates[0];

    if (lastestPreviousUpdate.id === lastestNewUpdate.id) {
      return;
    }

    if (UNRESOLVED_INCIDENT_STATUS.includes(newIncident.status)) {
      displayUnresolvedIncident(newIncident);

      return;
    }

    if (
      UNRESOLVED_INCIDENT_STATUS.includes(previousAppStatus.status) &&
      RESOLVED_INCIDENT_STATUS.includes(newIncident.status)
    ) {
      displayResolvedIncident(newIncident);

      return;
    }

    return;
  }

  if (UNRESOLVED_INCIDENT_STATUS.includes(newIncident.status)) {
    displayUnresolvedIncident(newIncident);
  }
};

export default processIncident;
