import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}debugging/claims/${COMMON_QUERY_PARAMS.CLAIM_ID}`;

interface GetDebuggingClaimInfo {
  claimId?: string;
}
async function getDebuggingClaimInfo({
  claimId,
}: GetDebuggingClaimInfo): Promise<BackResponse<string> | BackResponseFailure> {
  const functionName = 'getDebuggingClaimInfo';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = buildUrl(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId),
    {},
  );

  try {
    return await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getDebuggingClaimInfo;
