import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  RequestStatus,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/sparepartstatus`;

export interface UpdateRequestStatusCommandPayload {
  fileId: string;
  status: RequestStatus;
}

export async function updateRequestStatusCommand({
  fileId,
  status,
}: UpdateRequestStatusCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    return await apiCall(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          status,
        },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
