import { defineMessages } from 'react-intl';

export default defineMessages({
  next: {
    id: 'view.attachmentPreview.next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'view.attachmentPreview.previous',
    defaultMessage: 'Previous',
  },
});
