import React, { FunctionComponent } from 'react';

import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import { $MainLink, $MenuItemTitle } from '../Menu.styles';

interface MenuLinkProps {
  to: string;
  message: MessageType;

  dataTestId: string;
}
const MenuLink: FunctionComponent<React.PropsWithChildren<MenuLinkProps>> = ({
  to,
  message,
  dataTestId,
}) => {
  return (
    <$MainLink
      to={to}
      title={safeFormattedIntlString(message)}
      data-testid={dataTestId}
    >
      <$MenuItemTitle>
        <SafeFormattedMessageWithoutSpread message={message} />
      </$MenuItemTitle>
    </$MainLink>
  );
};

MenuLink.displayName = 'MenuLink';

export default React.memo(MenuLink);
