import { createAction } from '@reduxjs/toolkit';

import { FileId } from '@savgroup-front-common/types';

import * as actionType from './actionTypes';

export interface GetSparePartRequestLinesByFileId {
  fileId: FileId;
}

export const getSparePartRequestLinesByFileId =
  createAction<GetSparePartRequestLinesByFileId>(
    actionType.GET_SPARE_PART_REQUEST_LINES_BY_FILE_ID.BASE,
  );
