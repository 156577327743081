import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const BookmarkIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color}>
    <path
      d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"
      fill="currentColor"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </Icon>
);

BookmarkIcon.displayName = 'BookmarkIcon';

export default BookmarkIcon;
