import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { ConsoleDataCell } from './getEntityBySellerQuery';

 
const ENDPOINT = `${APIConfiguration.import}debugging/${COMMON_QUERY_PARAMS.IMPORT_TYPE}/${COMMON_QUERY_PARAMS.CLAIM_ID}`;

const getDebugInfoForConsoleCommand = async ({
  claimId,
  importType,
}: {
  claimId: string;
  importType: string;
}): Promise<BackResponse<ConsoleDataCell[]> | BackResponseFailure> => {
  try {
    const response = await apiCall<BackResponse<ConsoleDataCell[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId).replace(
        COMMON_QUERY_PARAMS.IMPORT_TYPE,
        importType,
      ),
      {
        method: SUPPORTED_METHODS.POST,
        json: { importType },
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getDebugInfoForConsoleCommand;
