import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/adequacy`;

export interface Adequacy {
  orderLineReference: string;
  adequacy: string;
  orientationId: string;
}

interface GetLastWorkflowVersionPayload {
  fileId: string;
  adequacies: Adequacy[];
}

async function setFileProductAdequacyCommand({
  fileId,
  adequacies,
}: GetLastWorkflowVersionPayload): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        values: adequacies,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default setFileProductAdequacyCommand;
