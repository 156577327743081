import { AnyAction } from 'redux';

import { SellerInfo } from '@savgroup-front-common/types';
import { SELLERS_INFO_DOMAIN } from 'control/domains/sellersInfo/constants';

import { LOAD_SELLERS_INFO } from './actionTypes';
import { SellersInfoState } from './sellersInfo.types';

const initialState: SellersInfoState = {
  [SELLERS_INFO_DOMAIN.ALL_SELLERS]: {},
};

const onSellersInfoLoading = (state: SellersInfoState): SellersInfoState => {
  return {
    ...state,
    [SELLERS_INFO_DOMAIN.ALL_SELLERS]: {
      isLoaded: false,
      isDirty: false,
      value: undefined,
    },
  };
};
const onSellersInfoLoadingErrored = (
  state: SellersInfoState,
): SellersInfoState => {
  return {
    ...state,
    [SELLERS_INFO_DOMAIN.ALL_SELLERS]: {
      ...state[SELLERS_INFO_DOMAIN.ALL_SELLERS],
      isDirty: true,
      hasErrors: true,
    },
  };
};

interface OnSellersInfoLoadedAction extends AnyAction {
  payload: {
    value: SellerInfo[];
  };
}
const onSellersInfoLoaded = (
  state: SellersInfoState,
  { payload }: OnSellersInfoLoadedAction,
): SellersInfoState => {
  return {
    ...state,
    [SELLERS_INFO_DOMAIN.ALL_SELLERS]: {
      ...state[SELLERS_INFO_DOMAIN.ALL_SELLERS],
      value: payload.value,
      isDirty: false,
      hasErrors: false,
      isLoaded: true,
    },
  };
};

const sellersInfoReducer = (
  state: SellersInfoState = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case LOAD_SELLERS_INFO.STARTED:
      return onSellersInfoLoading(state);
    case LOAD_SELLERS_INFO.SUCCEEDED:
      return onSellersInfoLoaded(state, action as OnSellersInfoLoadedAction);
    case LOAD_SELLERS_INFO.ERRORED:
      return onSellersInfoLoadingErrored(state);

    default:
      return state;
  }
};

export default sellersInfoReducer;
