import { useMemo } from 'react';

import { SHORTCUT_LIST_CONTROL } from '@savgroup-front-common/constants';

import messages from '../messages';
import { ShortcutKey } from '../ShortcutsContent.types';

const useSearchShortcuts = () => {
  return useMemo<ShortcutKey[]>(() => {
    return [
      {
        fragments: SHORTCUT_LIST_CONTROL.SEARCH_OPEN,
        description: messages.searchOpenLabel,
        condition: messages.conditionsearchIsClosed,
      },
      {
        fragments: SHORTCUT_LIST_CONTROL.SEARCH_NAVIGATE_UP,
        description: messages.searchUpLabel,
        condition: messages.conditionSearchIsOpened,
      },
      {
        fragments: SHORTCUT_LIST_CONTROL.SEARCH_NAVIGATE_DOWN,
        description: messages.searchDowmLabel,
        condition: messages.conditionSearchIsOpened,
      },
      {
        fragments: SHORTCUT_LIST_CONTROL.SEARCH_SELECT_ITEM,
        description: messages.searchSelectLabel,
        condition: messages.conditionSearchIsOpened,
      },
      {
        fragments: SHORTCUT_LIST_CONTROL.SEARCH_CLOSE,
        description: messages.searchCloseLabel,
        condition: messages.conditionSearchIsOpened,
      },
    ];
  }, []);
};

export default useSearchShortcuts;
