import styled from 'styled-components';

export const $CircularBackground = styled.circle`
  fill: none;
  stroke: #ddd;
`;
export const $CircularProgress = styled.circle<{ $color?: string }>`
  fill: none;
  stroke: ${({ theme, $color }) => $color || theme.colors.primary};
  stroke-linecap: round;
  stroke-linejoin: round;
`;
