import { uniq } from 'lodash';
import get from 'lodash/get';
import { AnyAction } from 'redux';
import { Action } from 'redux-actions';

import { SparePartCatalogItemWithPrice } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { SPARE_PART_CATALOG_DOMAIN } from './constants';
import { SparePartCatalogsDomainState } from './types';

const initialState: SparePartCatalogsDomainState = {
  [SPARE_PART_CATALOG_DOMAIN.LIST]: {},
  [SPARE_PART_CATALOG_DOMAIN.BY_SUPPLIER_ID]: {},
};

export interface OnGetSparePartCatalogSuccessPayload {
  value: SparePartCatalogItemWithPrice[];
}

function onGetSparePartCatalogSuccess(
  state: SparePartCatalogsDomainState,
  { payload }: { payload: OnGetSparePartCatalogSuccessPayload },
): SparePartCatalogsDomainState {
  const { value: sparePartWithSellingPriceSummaries } = payload;

  return {
    ...state,
    list: sparePartWithSellingPriceSummaries.reduce(
      (acc, sparePartWithSellingPriceSummary) => {
        return {
          ...acc,
          [sparePartWithSellingPriceSummary.sparePartId]:
            sparePartWithSellingPriceSummary,
        };
      },
      state.list,
    ),
    bySupplierId: sparePartWithSellingPriceSummaries.reduce(
      (acc, sparePartWithSellingPriceSummary) => {
        return {
          ...acc,
          [sparePartWithSellingPriceSummary.supplierId]: uniq([
            ...get(acc, sparePartWithSellingPriceSummary.supplierId, []),
            sparePartWithSellingPriceSummary.sparePartId,
          ]),
        };
      },
      state.bySupplierId,
    ),
  };
}

export default function mainReducer(
  state: SparePartCatalogsDomainState = initialState,
  action: AnyAction,
): SparePartCatalogsDomainState {
  switch (action.type) {
    case types.GET_SPARE_PART_CATALOGS_BY_SUPPLIER_ID.SUCCEEDED:
    case types.GET_SPARE_PART_CATALOG.SUCCEEDED:
      return onGetSparePartCatalogSuccess(
        state,
        action as Action<OnGetSparePartCatalogSuccessPayload>,
      );

    default:
      return state;
  }
}
