import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';
import { styledGetCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';

export const $PageContainerStyled = styled.div<{
  $componentThemeName?: string;
}>`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 0.5rem;
  position: relative;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  background: ${({ theme, $componentThemeName }) =>
    $componentThemeName
      ? styledGetCustomComponentProperty({
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
        })
      : theme.colors.secondaryColor2};

  @media ${media.minWidth.sm} {
    padding: 1.2rem 1.2rem;
  }
`;
