import { defineMessages } from 'react-intl';

export default defineMessages({
  logoTitle: {
    id: 'view.header.LogoTitle',
    defaultMessage: 'Go back to home page',
  },
  searchPlaceholder: {
    id: 'view.header.searchPlaceholder',
    defaultMessage: 'Search',
  },
  files: {
    id: 'view.header.searchbar.files',
    defaultMessage: 'Files',
  },
  order: {
    id: 'view.header.searchbar.order',
    defaultMessage: 'Orders',
  },
  sparePartRequest: {
    id: 'view.header.searchbar.sparePartRequest',
    defaultMessage: 'Sparepart request',
  },
  stockItem: {
    id: 'view.header.searchbar.stockItem',
    defaultMessage: 'Stock item',
  },
});
