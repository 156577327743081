import React, { FC, useState } from 'react';

import { FlexibleModal } from '@savgroup-front-common/core/src/atoms/modal/FlexibleModal/FlexibleModal';
import { VideoCall } from '@savgroup-front-common/core/src/atoms/VideoCall/VideoCall';
import { MainScreen } from '@savgroup-front-common/core/src/atoms/VideoCall/VideoCall.types';
import {
  selectFirstName,
  selectLastName,
} from '@savgroup-front-common/core/src/domains/login/selectors';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { selectOwnerById } from '../../../../../../domains/owner/selectOwnerById';
import { useControlTypedSelector } from '../../../../../../hooks';
import { useImportAttachmentOnFile } from '../../../../../../hooks/Attachments/useImportAttachmentOnFile';
import { useVideoCallLauncherContext } from '../../../VideoCallLauncher/VideoCallLauncher.context';

export const FloatingWindowVideoCall: FC = React.memo(() => {
  const {
    applicationId,
    sessionId,
    token,
    shouldDisplayVideoCall,
    onExitCall,
    handleSessionEnd,
    isSessionEndLoading,
    fileId,
    ownerId,
  } = useVideoCallLauncherContext();

  const [isMinimised, setIsMinimised] = useState(false);

  const firstName = useControlTypedSelector(selectFirstName);
  const lastName = useControlTypedSelector(selectLastName);

  const ownerInfo = useControlTypedSelector((state) =>
    selectOwnerById(state, { ownerId }),
  );

  const { handleImportImageOnFile } = useImportAttachmentOnFile({
    fileId,
  });

  const isVideoCallDisplay = !!(
    shouldDisplayVideoCall &&
    applicationId &&
    token &&
    sessionId
  );

  if (!applicationId || !sessionId || !token) {
    return <></>;
  }

  if (!ownerInfo) {
    return <BaseLoader />;
  }

  return (
    <FlexibleModal
      isOpen={isVideoCallDisplay}
      onRequestClose={() => {
        return undefined;
      }}
      isMinimised={isMinimised}
      initWidth={350}
      shouldKeepRatio
      minWidth={350}
    >
      <VideoCall
        applicationId={applicationId}
        sessionId={sessionId}
        token={token}
        publisherPreference={{
          name: `${firstName} ${lastName}`,
          publishVideo: false,
        }}
        subscriberPreference={{
          name: `${ownerInfo.firstName} ${ownerInfo.lastName}`,
        }}
        main={MainScreen.SUBSCRIBER}
        onClose={onExitCall}
        isMinimised={isMinimised}
        onMinimiseClick={() => setIsMinimised(true)}
        onRestoreClick={() => setIsMinimised(false)}
        onEndCallClick={handleSessionEnd}
        isLoading={isSessionEndLoading}
        onTakeSubscriberPhoto={handleImportImageOnFile}
      />
    </FlexibleModal>
  );
});

FloatingWindowVideoCall.displayName = 'FloatingWindowVideoCall';
