import React, { FunctionComponent, useRef } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { UserIcon } from '@savgroup-front-common/core/src/protons/icons';

import {
  $MenuVerticalPosition,
  $ProfilButton,
  $ProfilButtonContainer,
} from './HeaderProfile.styles';
import { useDefaultLanguage, useOpenProfile, useUserTracking } from './hooks';
import HeaderProfileMenu from './Menu';

const HeaderProfile: FunctionComponent = () => {
  const theme = useTheme();

  useDefaultLanguage();
  useUserTracking();
  const { isOpen, handleToggleIsOpen, handleClose } = useOpenProfile();

  const wrapperRef = useRef(null);
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$ProfilButtonContainer ref={wrapperRef}>
      <$ProfilButton
        onClick={handleToggleIsOpen}
        type={BUTTON_TYPES.BUTTON}
        data-testid="profilButton"
        $isNewUiEnabled={isNewUiEnabled}
      >
        <UserIcon color={theme.colors.white} size="32px" />
      </$ProfilButton>
      <$MenuVerticalPosition $isNewUiEnabled={isNewUiEnabled}>
        <HeaderProfileMenu
          onClose={handleClose}
          wrapperRef={wrapperRef}
          isOpen={isOpen}
        />
      </$MenuVerticalPosition>
    </$ProfilButtonContainer>
  );
};

HeaderProfile.displayName = 'HeaderProfile';

export default React.memo(HeaderProfile);
