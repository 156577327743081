import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.sparePart}stockInInventory/StockItemGroupInInventory/Delete/${COMMON_QUERY_PARAMS.STOCK_ITEM_ID}`;

export interface DeleteStockItemGroupInInventoryCommandArgs {
  stockItemGroupInInventoryId: string;
}

export default async function deleteStockItemGroupInInventoryCommand(
  payload: DeleteStockItemGroupInInventoryCommandArgs,
): Promise<BackResponse<boolean> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<boolean>>(
      ENDPOINT.replace(
        COMMON_QUERY_PARAMS.STOCK_ITEM_ID,
        payload.stockItemGroupInInventoryId,
      ),
      {
        method: SUPPORTED_METHODS.DELETE,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
