import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ClaimContextSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/claimsSummary`;

async function getClaimGroupClaimSummaries({
  claimGroupId,
}: {
  claimGroupId: string;
}): Promise<BackResponse<ClaimContextSummary[]> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<ClaimContextSummary[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getClaimGroupClaimSummaries;
