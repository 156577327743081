import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { RESULT_TYPE } from '@savgroup-front-common/types';

import { SEARCH_HEIGHT } from '../../../Search.contants';
import SearchItemContainer from '../../common/SearchItemContainer';

import { getSearchTitleMessage } from './SearchItemTitle.helpers';
import { $ResultTitle } from './SearchItemTitle.styles';

interface SearchItemTitleProps {
  type: RESULT_TYPE;
  count: number;
  animationIndex: number;
  animationState: TransitionStatus;
}

const SearchItemTitle: FunctionComponent<
  React.PropsWithChildren<SearchItemTitleProps>
> = ({ animationIndex, animationState, count, type }) => {
  return (
    <SearchItemContainer
      animationState={animationState}
      animationIndex={animationIndex}
      height={SEARCH_HEIGHT.TITLE}
    >
      <$ResultTitle data-testid={`search-result-title-${type}`}>
        <SafeFormattedMessageWithoutSpread
          message={getSearchTitleMessage(type)}
          values={{ count }}
        />
      </$ResultTitle>
    </SearchItemContainer>
  );
};

SearchItemTitle.displayName = 'SearchItemTitle';

export default SearchItemTitle;
