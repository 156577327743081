import React, { FC, useCallback } from 'react';
import { $Resizer } from './Resizer.styles';

interface ResizerProps {
  onUpdateStateResizing: (v: boolean) => void;
}

export const Resizer: FC<ResizerProps> = ({ onUpdateStateResizing }) => {
  const handleMouseDown = useCallback(() => {
    onUpdateStateResizing(true);
  }, [onUpdateStateResizing]);

  const style = {
    width: 30,
    height: 30,
  };

  return <$Resizer style={style} onMouseDown={handleMouseDown} />;
};

Resizer.displayName = 'Resizer';
