import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';

import { Z_INDEX_HEADER_SEARCH } from '@savgroup-front-common/constants/src/control';
import { media } from '@savgroup-front-common/constants/src/shared';
import { Link } from '@savgroup-front-common/core/src/atoms/link';
import { getAnimationDuration } from '@savgroup-front-common/core/src/animations/helper';
import {
  isAnimationEntering,
  isAnimationTransitioning,
  rem,
} from '@savgroup-front-common/core/src/helpers';

import { SEARCH_HEIGHT } from '../../../Search.contants';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';

const infoMixin = css`
  white-space: nowrap;
  display: inline-block;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;

export const $ResultOtherList = styled.div`
  ${infoMixin};
  padding-left: 0;
  @media ${media.maxWidth.md} {
    display: none;
  }
`;

export const $ResultInfo = styled.div`
  ${infoMixin};
  display: flex;
  & > ${$ResultOtherList} {
    margin-left: 1rem;
  }
`;

export const $ResultOtherItem = styled.div`
  ${infoMixin}
  & ~ & {
    margin-left: 0.75rem;
  }
  padding-right: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 0.25rem;
  background: #eee;
`;
export const $ResultOtherTitle = styled.div`
  ${infoMixin};
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 0.25rem;
  background: #aaa;
  color: ${({ theme }) => theme.colors.white};
`;

export const $ResultReference = styled.div<{ $withSeparation: boolean }>`
  ${infoMixin};
  color: ${({ theme }) => theme.colors.mainTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};

  &::after {
    content: '${({ $withSeparation }) => {
      return $withSeparation ? '\xa0-\xa0' : null;
    }}';
  }
`;
export const $ResultShortener = styled.div`
  ${infoMixin};
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const $ResultSelectIcon = styled.div<{ $isFocus: boolean }>`
  display: flex;
  opacity: ${({ $isFocus }) => ($isFocus ? 1 : 0)};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > span {
    font-size: ${rem(10)};
    line-height: ${rem(10)};
  }
  @media ${media.maxWidth.sm} {
    display: none;
  }
`;

const backgroundHover = css<{ $disableHover: boolean; $isFocus: boolean }>`
  &:hover {
    background-color: ${({ theme, $disableHover }) => {
      return $disableHover ? null : theme.colors.secondaryColor1;
    }};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.secondaryColor1};
  }

  background-color: ${({ theme, $isFocus }) => {
    return $isFocus ? theme.colors.secondaryColor1 : null;
  }};
`;

interface $ResultLinkProps {
  animationDuration?: number;
  $animationState: TransitionStatus;
  $isActive: boolean;
  $isFocus: boolean;
  $disableHover: boolean;
}

export const $ResultLink = styled(Link)<$ResultLinkProps>`
  align-items: center;
  display: flex;
  height: ${rem(SEARCH_HEIGHT.RESULT)};
  padding: 0 1rem;
  ${decelerateTimingFunctionTransition};
  svg {
    position: relative;
    z-index: 3;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: 2;
    top: 0;
    pointer-events: none;
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  }

  &,
  &:focus,
  &:hover {
    outline: none;
    color: ${({ theme }) => theme.colors.mainTextColor};
    text-decoration: unset;
  }
  &:focus ${$ResultSelectIcon} {
    display: flex;
  }

  @media ${media.maxWidth.md} {
    svg {
      display: none;
    }
  }

  transition-duration: ${(props) =>
    isAnimationTransitioning(props.$animationState)
      ? getAnimationDuration(props)
      : null};
  ${({ $animationState }) =>
    isAnimationEntering($animationState) ? backgroundHover : null};
`;

export const $ResultInfoContainer = styled.div`
  flex: 1;
  padding-left: ${rem(3)};
  position: relative;
  z-index: ${Z_INDEX_HEADER_SEARCH};
  max-width: 100%;

  @media ${media.minWidth.lg} {
    padding-left: 1.25rem;
    max-width: calc(100% - ${rem(45)});
  }
`;
