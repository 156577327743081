import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}adapterEndpointConfigurations/${COMMON_QUERY_PARAMS.ADAPTER_ID}`;

export interface DeleteAdapterConfigurationArgs {
  adapterId: string;
}

export default async function deleteAdapterConfiguration(
  payload: DeleteAdapterConfigurationArgs,
): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    const { adapterId } = payload;
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ADAPTER_ID, adapterId);

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.DELETE,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
