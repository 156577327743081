import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import {
  File,
  RESULT_TYPE,
  SearchHighlight,
  SearchOtherHighlight,
} from '@savgroup-front-common/types';

export enum RESULT_DISPLAY_TYPE {
  ERROR = 'error',
  SHOW_MORE = 'showMore',
  TITLE = 'title',
  RESULT = 'result',
  CONTROLS = 'controls',
}

export interface BaseSearchResultItem {
  key: string;
  resultType: RESULT_TYPE;
  value: {
    name: SearchHighlight[];
    other: SearchOtherHighlight[];
    reference: SearchHighlight[];
    status: SearchHighlight[];
    url: string;
  };
}

export interface SearchApiResult {
  value: BaseSearchResultItem[];
  totalCount: number;
  hit?: any[];
  hitCount?: number;
  isFailure: boolean;
  errors: ErrorFromBack[];
}

export interface SearchResultItem extends BaseSearchResultItem {
  animationIndex: number;
  isNew: boolean;
  displayType: RESULT_DISPLAY_TYPE.RESULT;
}
export interface SearchResultTitle {
  animationIndex: number;
  displayType: RESULT_DISPLAY_TYPE.TITLE;
  key: string;
  resultType: RESULT_TYPE;
  isNew: boolean;
  value: {
    count: number;
  };
}
export interface SearchResultControls {
  animationIndex: number;
  displayType: RESULT_DISPLAY_TYPE.CONTROLS;
  key: string;
  isNew: boolean;
}
export interface SearchResultError {
  animationIndex: number;
  displayType: RESULT_DISPLAY_TYPE.ERROR;
  key: string;
  resultType: RESULT_TYPE;
  isNew: boolean;
  value: {
    errors: ErrorFromBack[];
  };
}
export interface SearchResultShowmore {
  animationIndex: number;
  displayType: RESULT_DISPLAY_TYPE.SHOW_MORE;
  key: string;
  isNew: boolean;

  resultType: RESULT_TYPE;
  value: {
    count: number;
  };
}

export enum SEARCH_ACTION_TYPES {
  TOGGLE_SEARCH_TYPE = 'TOGGLE_SEARCH_TYPE',
  UPDATE_QUERY = 'UPDATE_QUERY',
  CLOSE_RESULTS = 'CLOSE_RESULTS',
  SET_RESULTS = 'SET_RESULTS',
  SET_LOADING = 'SET_LOADING',
  GO_UP = 'GO_UP',
  GO_DOWN = 'GO_DOWN',
  ON_FOCUS = 'ON_FOCUS',
  ON_BLUR = 'ON_BLUR',
  REQUEST_MORE_RESULTS = 'REQUEST_MORE_RESULTS',
  SET_MORE_RESULTS = 'SET_MORE_RESULTS',
}

export type SearchResult =
  | SearchResultItem
  | SearchResultError
  | SearchResultControls
  | SearchResultTitle
  | SearchResultShowmore;

export interface SearchState {
  query: string;
  indexFocus?: number;
  containerHeight: number;
  listHeight: number;
  resultsAreOpened: boolean;
  isLoading: boolean;
  results: SearchResult[];
  storedResults: SearchResult[];
  showNoResult: boolean;
  showNoFilter: boolean;
  page: number;
  focusedResultType?: RESULT_TYPE;
  enabledResultTypes: RESULT_TYPE[];
}

export type EmptyResponse = {
  order: {
    value: BaseSearchResultItem[];
    totalCount: number;
    hit?: File[];
    hitCount?: number;
  };
  file: {
    value: BaseSearchResultItem[];
    totalCount: number;
    hit?: File[];
    hitCount?: number;
  };
  sparePartRequest: {
    value: BaseSearchResultItem[];
    totalCount: number;
    hit?: File[];
    hitCount?: number;
  };
  stockItem: {
    value: BaseSearchResultItem[];
    totalCount: number;
    hit?: File[];
    hitCount?: number;
  };
};

export interface SearchResultGroup {
  [RESULT_TYPE.ORDER]: SearchApiResult;
  [RESULT_TYPE.FILE]: SearchApiResult;
  [RESULT_TYPE.SPARE_PART_REQUEST]: SearchApiResult;
  [RESULT_TYPE.STOCK_ITEM]: SearchApiResult;
}

export const isEmptyResponse = (
  objectToBeDetermined: EmptyResponse | SearchResultGroup,
): objectToBeDetermined is EmptyResponse => {
  return (
    objectToBeDetermined.order.totalCount === 0 &&
    objectToBeDetermined.file.totalCount === 0 &&
    objectToBeDetermined.sparePartRequest.totalCount === 0 &&
    objectToBeDetermined.stockItem.totalCount === 0
  );
};

export type SearchAction =
  | {
      type: SEARCH_ACTION_TYPES.REQUEST_MORE_RESULTS;
      payload: { type: RESULT_TYPE };
    }
  | {
      type: SEARCH_ACTION_TYPES.CLOSE_RESULTS;
    }
  | {
      type: SEARCH_ACTION_TYPES.TOGGLE_SEARCH_TYPE;
      payload: { type: RESULT_TYPE };
    }
  | {
      type: SEARCH_ACTION_TYPES.ON_FOCUS;
    }
  | {
      type: SEARCH_ACTION_TYPES.ON_BLUR;
    }
  | {
      type: SEARCH_ACTION_TYPES.GO_UP;
    }
  | {
      type: SEARCH_ACTION_TYPES.GO_DOWN;
    }
  | {
      type: SEARCH_ACTION_TYPES.SET_LOADING;
      payload: {
        isLoading: boolean;
      };
    }
  | {
      type:
        | SEARCH_ACTION_TYPES.SET_RESULTS
        | SEARCH_ACTION_TYPES.SET_MORE_RESULTS;
      payload: {
        response: SearchResultGroup | EmptyResponse;
      };
    }
  | {
      type: SEARCH_ACTION_TYPES.UPDATE_QUERY;
      payload: {
        query: string;
      };
    };
