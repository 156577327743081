import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/additionalInformation`;

/**
 * @param fileId
 * @param additionalFileInformation
 * @returns {Promise<*|*[]>}
 */
export async function setFileAdditionalInformation({
  fileId,
  additionalFileInformation,
}) {
  if (!fileId || !additionalFileInformation) {
    throw new Error(
      `cannot setFileAdditionalInformation without a fileId or additionalFileInformation !`,
    );
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

  try {
    return await apiCall(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        additionalFileInformation,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
