import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';

import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';

import { ResultSearchType } from './SearchWithDimmer.types';

export const useSearchWithDimmer = ({
  query,
  results = [],
  onSearch,
  lastItemsSearched,
}: {
  query?: string;
  results?: SearchResultList<ResultSearchType>[];
  onClear?: () => void;
  onSearch?: (value: string) => void;
  lastItemsSearched: string[];
}) => {
  const [isResultVisible, setIsResultVisible] = useState(false);

  const [value, setValue] = useState(query ?? '');

  useDebounce(
    () => {
      if (onSearch) {
        onSearch(value);
      }
    },
    500,
    [value],
  );

  const searchHistoryHeight = isResultVisible
    ? lastItemsSearched.length * (28 + 4) + 16
    : 0;

  const gap = 16;
  const headerHeight = 20 + gap + gap;

  const resultHeight =
    results
      .filter((x) => !!x.hitCount)
      .reduce((acc, result) => {
        if (result.hitCount > 3) {
          return acc + headerHeight + 3 * (36 + gap) + 16 + gap;
        } else if (result.hitCount <= 3) {
          return acc + headerHeight + result.hitCount * (36 + gap);
        }

        return acc;
      }, 0) ?? 0;

  const sizeResult = searchHistoryHeight + resultHeight;

  const handleSearchInputChange = useCallback((value: string) => {
    setValue(value);
  }, []);

  const handleClear = useCallback(() => {
    setValue('');
  }, []);

  return {
    setIsResultVisible,
    isResultVisible,
    value,
    sizeResult: isResultVisible && results.length > 0 ? sizeResult : 0,
    handleSearchInputChange,
    handleClear,
  };
};
