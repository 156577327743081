import React, { FC } from 'react';

import { Z_INDEX_MENU } from '@savgroup-front-common/constants/src/shared';
import { BackgroundDimmer } from '@savgroup-front-common/core/src/atoms/BackgroundDimmer';

import useGetMenuItems from './hooks/useGetMenuItems';
import { $MenuContainer, $MenuItem, $MenuList } from './Menu.styles';
import { MenuExternalLink } from './MenuExternalLink';
import MenuLink from './MenuLink';

const Menu: FC = () => {
  const { menus, index } = useGetMenuItems();

  return (
    <>
      <BackgroundDimmer zIndex={Z_INDEX_MENU} />
      <$MenuContainer>
        <$MenuList $index={index}>
          {menus.map((menu) => {
            if (menu.isExternal) {
              return (
                <$MenuItem
                  key={menu.to}
                  $hideCountOnHover={menu.count === 0 && menu.subCount > 0}
                  role="presentation"
                  data-testid={`sidebar_menucontainer_${menu.dataTestId}`}
                >
                  <MenuExternalLink message={menu.message} />
                </$MenuItem>
              );
            }

            return (
              <$MenuItem
                key={menu.to}
                $hideCountOnHover={menu.count === 0 && menu.subCount > 0}
                role="presentation"
                data-testid={`sidebar_menucontainer_${menu.dataTestId}`}
                $isActive={
                  menu.isActive ||
                  menu.submenus.some(({ isActive }) => isActive)
                }
              >
                <MenuLink
                  to={menu.to}
                  dataTestId={`sidebar_menu_${menu.dataTestId}`}
                  message={menu.message}
                />
              </$MenuItem>
            );
          })}
        </$MenuList>
      </$MenuContainer>
    </>
  );
};

Menu.displayName = 'SidBarMenu';

export default React.memo(Menu);
