import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

interface ApplyDraftCommandArgs {
  draftId: string;
}

const ENDPOINT = `${APIConfiguration.import}drafts/applied`;

export default async function applyDraftCommand(
  payload: ApplyDraftCommandArgs,
): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
