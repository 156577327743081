import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  FileId,
  InternalService,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/services`;

export interface GetAvailableServicesForInterventionReportByFileIdArgs {
  fileId: FileId;
}

interface GetAvailableServicesForInterventionReportByFileIdResponse
  extends BaseBackResponse {
  value: InternalService[];
}

export default async function getAvailableServicesForInterventionReportByFileId(
  payload: GetAvailableServicesForInterventionReportByFileIdArgs,
): Promise<GetAvailableServicesForInterventionReportByFileIdResponse> {
  return apiCall<GetAvailableServicesForInterventionReportByFileIdResponse>(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId),
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
}
