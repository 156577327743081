import styled from 'styled-components';

export const $SwitchNewBo = styled.div<{ $isNewUiEnabled: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  & label {
    color: ${({ theme, $isNewUiEnabled }) =>
      $isNewUiEnabled ? theme.colors.white : undefined};
  }
`;
