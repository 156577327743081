import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SparePartSearchSummaryDto } from '@savgroup-front-common/core/src/api/SpareParts/searchSparePartsByNames';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}parts/search`;

export interface SearchSparePartsQueryArgs {
  sellerId: string;
  manufacturerReference?: string;
  supplierReference?: string;
  sparePartSupplierIds?: string[];
  factoryCode?: string;
  fileSku?: string;
  fileId?: string;
}

export async function searchSparePartsQuery({
  sellerId,
  manufacturerReference,
  supplierReference,
  sparePartSupplierIds,
  factoryCode,
  fileSku,
  fileId,
}: SearchSparePartsQueryArgs): Promise<
  BackResponse<SparePartSearchSummaryDto[]> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SparePartSearchSummaryDto[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
        manufacturerReference,
        supplierReference,
        sparePartSupplierIds,
        factoryCode,
        fileSku,
        fileId,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
