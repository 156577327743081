import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, ImportReport } from '@savgroup-front-common/types';

interface ImportReportValue {
  categories: ImportReport[];
  hasErrors: boolean;
}

interface ImportReportArgs {
  draftId: string;
}

interface ImportReportResponse extends BaseBackResponse {
  value: ImportReportValue;
}

const ENDPOINT = `${APIConfiguration.import}drafts/${COMMON_QUERY_PARAMS.DRAFT_ID}/importreport`;

export default async function importReport(
  payload: ImportReportArgs,
): Promise<ImportReportValue> {
  const { draftId } = payload;

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.DRAFT_ID, draftId);
  const response = await apiCall<ImportReportResponse>(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new Error(`Got an exception trying to get import report`);
  }

  return response.value;
}
