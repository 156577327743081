import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.warranty}warrantytypes/${COMMON_QUERY_PARAMS.WARRANTY_TYPE_ID}/warrantytypegroup`;

interface GetWarrantyTypeQueryQueryArgs {
  warrantyTypeId: string;
}

async function getWarrantyTypeGroupQuery({
  warrantyTypeId,
}: GetWarrantyTypeQueryQueryArgs): Promise<
  BackResponse<string> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.WARRANTY_TYPE_ID,
      warrantyTypeId,
    );

    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getWarrantyTypeGroupQuery;
