import {
  SEARCH_HIGHLIGHT_TYPE,
  SearchHighlight,
} from '@savgroup-front-common/types';

const adaptToRegularHighlight = (value: string): SearchHighlight => {
  return {
    type: SEARCH_HIGHLIGHT_TYPE.REGULAR,
    value,
  };
};

export default adaptToRegularHighlight;
