import { createAction } from '@reduxjs/toolkit';

import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { RequestLineStatus } from '@savgroup-front-common/types';

import * as actionType from './actionTypes';

export interface GetSparePartRequestLineListPayload {
  page: number;
  pageSize?: number;
  isDescending?: number;
  sort?: SORT_TYPES;
  query?: string;
}

export const getSparePartRequestLineList =
  createAction<GetSparePartRequestLineListPayload>(
    actionType.GET_SPARE_PART_REQUEST_LINE_LIST.BASE,
  );

export interface SetSparePartRequestLineListQueryPayload {
  query: string;
}

export const setSparePartRequestLineListQuery =
  createAction<SetSparePartRequestLineListQueryPayload>(
    actionType.SET_SPARE_PART_REQUEST_LINE_LIST_QUERY.BASE,
  );

export interface SetSparePartRequestLineListIsDescendingPayload {
  isDescending: boolean;
}

export const setSparePartRequestLineListIsDescending =
  createAction<SetSparePartRequestLineListIsDescendingPayload>(
    actionType.SET_SPARE_PART_REQUEST_LINE_LIST_IS_DESCENDING.BASE,
  );
export interface SetSparePartRequestLineListFiltersPayload {
  filterCategoryName: string;
  filterName: string;
  active: boolean;
}
export const setSparePartRequestLineListFilters =
  createAction<SetSparePartRequestLineListFiltersPayload>(
    actionType.SET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.BASE,
  );
export interface UpdateSparePartRequestLineListItemStatusPayload {
  sparePartRequestLineId: string;
  requestLineStatus: RequestLineStatus;
}
export const updateSparePartRequestLineListItemStatus =
  createAction<UpdateSparePartRequestLineListItemStatusPayload>(
    actionType.UPDATE_SPARE_PART_REQUEST_LINE_LIST_ITEM_STATUS.BASE,
  );

export const resetSparePartRequestLineListFilters = createAction(
  actionType.RESET_SPARE_PART_REQUEST_LINE_LIST_FILTERS.BASE,
);
