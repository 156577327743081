import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}modules/locales`;

interface UpsertModuleDefinitionLocalesCommandPayload {
  fileContent: Record<string, string>;
}

export async function upsertModuleDefinitionLocalesCommand({
  fileContent,
}: UpsertModuleDefinitionLocalesCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: fileContent,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
