import styled from 'styled-components';

export const $SearchHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  [class*='MountBlockContainer'] {
    width: 100%;
  }
`;

export const $SearchHistoryItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fonts.size.small};
  padding: 6px 12px 6px 8px;
  line-height: 14px;
  gap: 8px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.newUI?.iconColors.primary.bgColor};
    border-radius: 8px;
  }
`;
