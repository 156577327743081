import { createContext, useContext } from 'react';

import {
  AppComponent,
  AppIncident,
  AppScheduledMaintenance,
} from '@savgroup-front-common/types';

interface AppStatusesContextReturnValue {
  updateAppComponents: () => Promise<void>;
  removeMaintenanceScreen: () => void;
  nextScheduledMaintenance: AppScheduledMaintenance | undefined;
  appComponents: AppComponent[];
  appIncidents: AppIncident[];
  appScheduledMaintences: AppScheduledMaintenance[];
  isUnderMaintenance: boolean;
  isComponentStatusLoaded: boolean;
}

export const AppStatusesContext = createContext<AppStatusesContextReturnValue>({
  appIncidents: [],
  updateAppComponents: () => Promise.resolve(),
  removeMaintenanceScreen: () => undefined,
  appComponents: [],
  appScheduledMaintences: [],
  nextScheduledMaintenance: undefined,
  isUnderMaintenance: false,
  isComponentStatusLoaded: false,
});

export const useAppStatuses = (): AppStatusesContextReturnValue => {
  return useContext(AppStatusesContext);
};
