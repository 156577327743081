import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import {
  isAnimationEntering,
  rem,
} from '@savgroup-front-common/core/src/helpers';

import { SEARCH_HEIGHT } from '../../../Search.contants';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';

export const $ShowMoreTextContainer = styled.div`
  display: flex;
  padding: ${rem(4)} ${rem(6)};
  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius};
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.colors.secondaryColor1};
`;
export const $ShowMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
interface $ShowMoreProps {
  $disableHover: boolean;
  $isFocus: boolean;
  animationState: TransitionStatus;
}

const backgroundHover = css<{ $disableHover: boolean; $isFocus: boolean }>`
  &:hover ${$ShowMoreTextContainer} {
    background-color: ${({ theme, $disableHover }) => {
      return $disableHover ? null : theme.colors.primary;
    }};
    color: ${({ theme, $disableHover }) => {
      return $disableHover ? null : theme.colors.white;
    }};
    svg {
      fill: ${({ theme, $disableHover }) => {
        return $disableHover ? null : theme.colors.white;
      }};
    }
  }

  &:focus ${$ShowMoreTextContainer} {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${$ShowMoreTextContainer} {
    background-color: ${({ theme, $isFocus }) => {
      return $isFocus ? theme.colors.primary : null;
    }};
    color: ${({ theme, $isFocus }) => {
      return $isFocus ? theme.colors.white : null;
    }};
  }
`;

export const $ResultSelectIcon = styled.div<{ $isFocus: boolean }>`
  display: flex;
  opacity: ${({ $isFocus }) => ($isFocus ? 1 : 0)};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > span {
    font-size: ${rem(10)};
    line-height: ${rem(10)};
  }

  @media ${media.maxWidth.sm} {
    display: none;
  }
  background-color: ${({ theme }) => theme.colors.secondaryColor1};
  border-radius: ${({ theme }) => theme.borders.radius};
  height: ${rem(34)};
  padding: ${rem(3)};
`;

export const $ShowMore = styled.button<$ShowMoreProps>`
  display: flex;
  align-items: center;
  background-color: transparent;

  > svg {
    @media ${media.maxWidth.md} {
      display: none;
    }
    max-width: ${rem(20)};
  }
  justify-content: space-between;

  ${decelerateTimingFunctionTransition};
  font-size: 1rem;
  margin: 0;
  top: 0;
  position: absolute;
  padding: 0 ${rem(15)} ${rem(6)} ${rem(16)};
  width: 100%;
  color: ${({ theme }) => theme.colors.mainTextColor};
  outline: 0;
  border: 0;
  cursor: pointer;
  height: ${rem(SEARCH_HEIGHT.SHOW_MORE_RESULT)};
  &,
  &:focus,
  &:hover {
    outline: none;
    color: ${({ theme }) => theme.colors.mainTextColor};
    text-decoration: unset;
  }

  &:focus ${$ResultSelectIcon} {
    display: flex;
  }

  ${({ animationState }) =>
    isAnimationEntering(animationState) ? backgroundHover : null};
`;
