import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SalesChannelInfo,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.actor}saleschannels`;

export const getSalesChannelsQuery = async (): Promise<
  BackResponseFailure | BackResponse<SalesChannelInfo[]>
> => {
  try {
    return await apiCall<BackResponse<SalesChannelInfo[]>>(buildUrl(ENDPOINT), {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
