import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SellerBillingConfiguration,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/configuration/billing`;

interface GetSellerBillingConfigurationQueryPayload {
  sellerId: string;
}

const getSellerBillingConfigurationQuery = async ({
  sellerId,
}: GetSellerBillingConfigurationQueryPayload): Promise<
  BackResponse<SellerBillingConfiguration> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<BackResponse<SellerBillingConfiguration>>(
      url,
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSellerBillingConfigurationQuery;
