// DON'T RENAME THIS FILE INTO "messages.js" IT MAY CONFLICT WITH "react-intl"
import get from 'lodash/get';
import { matchPath } from 'react-router-dom';
import { buffers, eventChannel } from 'redux-saga';
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';

import { PERMISSIONS } from '@savgroup-front-common/constants';
import {
  selectPermissions,
  selectUserId,
} from '@savgroup-front-common/core/src/domains/login/selectors';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';

import { LOGIN_SIGNED_IN } from '../../login/actions';
import { orderDetailsAndClaimCreationModule } from '../../orders/ordersSaga';
import { currentOrderIdSelector } from '../../orders/selectors';
import * as ActionCreators from '../actionCreators';
import * as ActionTypes from '../actionTypes';
import * as Selectors from '../selectors';

import {
  currentDetailedTicketsTracking,
  currentMarketplaceCapabilitiesSelector,
  currentTicketSelector,
} from './selectors';
import * as TicketsActionCreators from './ticketsActions';

function activityOnAppChannel() {
  return eventChannel((emitter) => {
    const eventHandler = () => emitter(performance.now());

    window.addEventListener('focus', eventHandler);
    window.document.addEventListener('scroll', eventHandler);
    window.document.addEventListener('mousemove', eventHandler);
    window.document.addEventListener('keypress', eventHandler);
    window.document.addEventListener('click', eventHandler);

    // The subscriber must return an unsubscribe function
    return () => {
      window.removeEventListener('focus', eventHandler);
      window.document.removeEventListener('scroll', eventHandler);
      window.document.removeEventListener('mousemove', eventHandler);
      window.document.removeEventListener('keypress', eventHandler);
      window.document.removeEventListener('click', eventHandler);
    };
  }, buffers.sliding(1));
}

function* awaitScreenVisibility() {
  let animationId = null;

  yield new Promise((resolve) => {
    animationId = window.requestAnimationFrame(resolve);
  });
  window.cancelAnimationFrame(animationId);
}

function* trackUserActivity(ticketId) {
  const activityOnApp = yield call(activityOnAppChannel);

  try {
    const initialWaitTimeInSeconds = 2 * 1000;
    const pollTimeInSeconds = 10 * 1000;
    const maxIdleTime = 3 * 60 * 1000;

    let lastestActivityAt = performance.now();

    while (true) {
       
      yield delay(initialWaitTimeInSeconds);

      yield race([
        call(function* watchActivity() {
          while (true) {
            lastestActivityAt = yield take(activityOnApp);
            yield delay(1000);
          }
        }),
        call(function* trackActivityOnBackend() {
          while (true) {
            const userId = yield select(selectUserId);

            if (userId) {
              // make sure that the window is visible in the first place
              yield awaitScreenVisibility();
              const now = performance.now();

              if (lastestActivityAt + maxIdleTime <= now) {
                return;
              }

              // It's imperative to refresh the detailed ticket before tracking activity (Concurrency is very weird beast)
              yield all([
                put(ActionCreators.loadMarketplaceDetailedTicket(ticketId)),
                take(ActionTypes.LOAD_MARKETPLACE_DETAILED_TICKET.END),
              ]);
              const trackingActivity = yield select(
                currentDetailedTicketsTracking,
              );

              if (
                !trackingActivity ||
                get(trackingActivity, ['elapsedTimeInSeconds'], 21) > 20 ||
                !get(trackingActivity, ['userId']) ||
                get(trackingActivity, ['userId']) === userId
              ) {
                yield all([
                  put(
                    ActionCreators.trackUserActivityOnTicket({
                      ticketId,
                      userId,
                    }),
                  ),
                  take(ActionTypes.TRACK_USER_ACTIVITY_ON_TICKET.END),
                ]);
              }
              yield all([
                put(ActionCreators.loadMarketplaceDetailedTicket(ticketId)),
                take(ActionTypes.LOAD_MARKETPLACE_DETAILED_TICKET.END),
                put(TicketsActionCreators.loadTickets()),
                take(ActionTypes.LOAD_MARKETPLACE_TICKETS.END),
              ]);
            }
            yield delay(pollTimeInSeconds);
          }
        }),
      ]);

      [lastestActivityAt] = yield race([
        take(activityOnApp),
        call(function* keepUpdatingTicketDetails() {
          while (true) {
            // make sure that the window is visible in the first place
            yield awaitScreenVisibility();
            yield all([
              put(ActionCreators.loadMarketplaceDetailedTicket(ticketId)),
              take(ActionTypes.LOAD_MARKETPLACE_DETAILED_TICKET.END),
              put(TicketsActionCreators.loadTickets()),
              take(ActionTypes.LOAD_MARKETPLACE_TICKETS.END),
            ]);
            yield delay(pollTimeInSeconds);
          }
        }),
      ]);
    }
  } finally {
    activityOnApp.close();
  }
}

export default function* mainSaga(route) {
  const path = yield select(pathname);
  const options = matchPath(path, {
    path: route,
    exact: false,
    strict: false,
  });

  const dataIsUpToDate = (data) => {
    return data && data.get('isLoaded') && !data.get('isDirty');
  };

  const filters = yield select(Selectors.filters);

  if (!dataIsUpToDate(filters)) {
    yield put(ActionCreators.loadFilters());
  }

  const ticketId = get(options, 'params.ticketId');

  const tickets = yield select(Selectors.tickets);

  if (!ticketId || !dataIsUpToDate(tickets)) {
    yield put(TicketsActionCreators.loadTickets());
  }

  if (ticketId) {
    yield all([
      put(ActionCreators.loadMarketplaceDetailedTicket(ticketId)),
      take(
        (action) =>
          action.type === ActionTypes.LOAD_MARKETPLACE_DETAILED_TICKET.END &&
          get(action, ['meta', 'ticketId']) === ticketId,
      ),
    ]);

    const ticket = (yield select(currentTicketSelector)).get('value');
    const capabilities = yield select(currentMarketplaceCapabilitiesSelector);

    if (ticket && (!capabilities || capabilities.get('hasErrors'))) {
      yield put(
        ActionCreators.loadMarketplaceCapabilitiesBySeller({
          marketplace: get(ticket, 'apiConnector'),
          sellerId: get(ticket, 'sellerId'),
        }),
      );
    }

    const orderId = yield select(currentOrderIdSelector);

    let permissions = yield select(selectPermissions);

    if (permissions.length === 0) {
      yield take(LOGIN_SIGNED_IN);
      permissions = yield select(selectPermissions);
    }
    if (orderId && permissions.includes(PERMISSIONS.GET_ORDER)) {
      yield fork(orderDetailsAndClaimCreationModule, orderId);
    }
    if (permissions.includes(PERMISSIONS.TICKET_TRACKING_ACTIVITY)) {
      yield fork(trackUserActivity, ticketId);
    }
  }
}
