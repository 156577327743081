import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { RESULT_TYPE, SearchOrders } from '@savgroup-front-common/types';
import {
  adaptToBoldHighlight,
  adaptToRegularHighlight,
} from 'control/adapters';
import { OwnerService } from 'control/api';

import { buildOrdersUrl } from '../../../../../helpers';
import { SearchApiResult } from '../Search.types';

const formatOwnerName = ({ firstName, lastName }: SearchOrders) => {
  if (!firstName && !lastName) {
    return [];
  }

  return [adaptToRegularHighlight(`${firstName} ${lastName}`)];
};

const adaptOrderToSearchResult = (order: SearchOrders) => {
  const { orderReference, orderId, sellerName } = order;

  return {
    key: orderId,
    resultType: RESULT_TYPE.ORDER,
    value: {
      name: formatOwnerName(order),
      reference: [adaptToBoldHighlight(orderReference)],
      other: [],
      status: [adaptToRegularHighlight(sellerName)],
      url: buildOrdersUrl({ orderId }),
    },
  };
};

const MINIMUM_ORDER_REF_COUNT = 4;

const searchOrders = async ({
  query,
  sort,
  isDescending,
  shouldLoad,
  pageSize,
}: {
  page: number;
  pageSize?: number;
  query: string;
  sort?: SORT_TYPES;
  shouldLoad: boolean;
  isDescending?: boolean;
}): Promise<SearchApiResult> => {
  if (!shouldLoad || query.length < MINIMUM_ORDER_REF_COUNT) {
    return {
      isFailure: false,
      value: [],
      totalCount: 0,
      errors: [],
    };
  }

  const response = await OwnerService.getOrdersByQuery({
    query,
    pageSize: pageSize || 7,
    sort,
    isDescending,
  });

  if (response.failure) {
    return {
      isFailure: true,
      value: [],
      totalCount: 0,
      errors: response.errors,
    };
  }

  return {
    isFailure: false,
    value: response.value.map(adaptOrderToSearchResult),
    totalCount: response.value.length,
    errors: [],
    hit: response.value,
    hitCount: response?.value?.length,
  };
};

export default searchOrders;
