import { defineMessages } from 'react-intl';

export default defineMessages({
  switchToNewBo: {
    id: 'view.header.switchToNewBo',
    defaultMessage: 'Switch to new BO',
  },
  switchToOldBo: {
    id: 'view.header.switchToOldBo',
    defaultMessage: 'Switch to old BO',
  },
});
