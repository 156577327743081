import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BaseBackResponse,
  FileId,
  STOCK_ITEM_STATUS,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}files/${COMMON_QUERY_PARAMS.FILE_ID}/stockItems`;

export interface GetStockItemsByFileIdArgs {
  fileId: FileId;
  stockItemStatus?: STOCK_ITEM_STATUS;
}

interface GetStockItemsByFileIdResponse extends BaseBackResponse {
  value: {
    stockItemId: string;
    sparePartReference: string;
  }[];
}

export default async function getStockItemsByFileId(
  payload: GetStockItemsByFileIdArgs,
): Promise<GetStockItemsByFileIdResponse> {
  return apiCall<GetStockItemsByFileIdResponse>(
    buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId), {
      stockItemStatus: payload.stockItemStatus,
    }),
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
}
