import { createSelector } from 'reselect';

import {
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { ControlRootState } from '../ControlRootState';

import { LOAD_REFUNDS } from './actionTypes';

export const refundsStateSelector = (state: ControlRootState) => state.refunds;

export const refundByFileIdIsLoadedSelector = (
  state: ControlRootState,
  { fileId }: { fileId: string },
) => wasActionLoadedSelector(state, LOAD_REFUNDS, fileId);

export const refundByFileIdIsLoadingSelector = (
  state: ControlRootState,
  { fileId }: { fileId: string },
) => isActionLoadingSelector(state, LOAD_REFUNDS, fileId);

export const selectRefundByFileId = createSelector(
  [
    refundsStateSelector,
    (_: ControlRootState, { fileId }: { fileId: string }) => fileId,
  ],
  (state, fileId) => {
    return state.refund[fileId];
  },
);
export const selectRefundCurrencyCodeByFileId = createSelector(
  [selectRefundByFileId, (_, { fileId }: { fileId: string }) => fileId],
  (state) => {
    return state?.currencyCode;
  },
);

export const selectRefundAmountToDeductByFileId = createSelector(
  [selectRefundByFileId, (_, { fileId }: { fileId: string }) => fileId],
  (state) => {
    return state?.amountToDeduct;
  },
);

export const selectRefundAmountToRefundByFileId = createSelector(
  [selectRefundByFileId, (_, { fileId }: { fileId: string }) => fileId],
  (state) => {
    return state?.amountToRefund;
  },
);
