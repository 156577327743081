import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  TechnicalInterventionHistoryItem,
  TechnicalInterventionHistoryItemEntityType,
} from '@savgroup-front-common/types';

export interface TechnicalInterventionHistoryItemDto
  extends Omit<TechnicalInterventionHistoryItem, 'date'> {
  date: string;
}

const ENDPOINT = `${APIConfiguration.workflow}history`;

export const getHistoryItemsQuery = async ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: TechnicalInterventionHistoryItemEntityType;
}) => {
  try {
    return await apiCall<BackResponse<TechnicalInterventionHistoryItemDto[]>>(
      buildUrl(ENDPOINT, {
        entityType,
        entityId,
      }),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
