import React from 'react';

import Icon from './Icon';

interface HeadsetHelpIconProps {
  color?: string;
  size?: string;
}

const HeadsetHelpIcon: React.FC<HeadsetHelpIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M33.3334 18.3333C33.3334 14.7971 31.9287 11.4057 29.4282 8.90524C26.9277 6.40476 23.5363 5 20.0001 5C16.4639 5 13.0725 6.40476 10.572 8.90524C8.07151 11.4057 6.66675 14.7971 6.66675 18.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3334 30V30.8333C33.3334 31.7174 32.9822 32.5652 32.3571 33.1904C31.7319 33.8155 30.8841 34.1667 30 34.1667H24.1667M3.33337 25.73V22.6033C3.33349 21.86 3.58205 21.1381 4.03955 20.5522C4.49705 19.9664 5.13724 19.5503 5.85837 19.37L8.75837 18.6433C8.90573 18.6066 9.05952 18.604 9.20806 18.6356C9.3566 18.6672 9.49599 18.7322 9.61566 18.8257C9.73534 18.9191 9.83215 19.0387 9.89875 19.1751C9.96534 19.3116 9.99999 19.4615 10 19.6133V28.7183C10.0003 28.8705 9.96583 29.0208 9.89924 29.1576C9.83266 29.2944 9.73572 29.4143 9.61581 29.508C9.4959 29.6017 9.35618 29.6668 9.20731 29.6983C9.05843 29.7299 8.90432 29.727 8.75671 29.69L5.85671 28.965C5.13589 28.7844 4.49607 28.3682 4.03889 27.7824C3.58171 27.1965 3.33339 26.4748 3.33337 25.7317V25.73ZM36.6667 25.73V22.6033C36.6666 21.86 36.418 21.1381 35.9605 20.5522C35.503 19.9664 34.8628 19.5503 34.1417 19.37L31.2417 18.6433C31.0943 18.6066 30.9406 18.604 30.792 18.6356C30.6435 18.6672 30.5041 18.7322 30.3844 18.8257C30.2647 18.9191 30.1679 19.0387 30.1013 19.1751C30.0347 19.3116 30.0001 19.4615 30 19.6133V28.7183C29.9998 28.8703 30.0343 29.0204 30.1008 29.1571C30.1673 29.2938 30.2641 29.4135 30.3838 29.5072C30.5035 29.6008 30.643 29.666 30.7916 29.6977C30.9403 29.7294 31.0942 29.7267 31.2417 29.69L34.1417 28.965C34.8628 28.7847 35.503 28.3686 35.9605 27.7828C36.418 27.1969 36.6666 26.475 36.6667 25.7317V25.73Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M22.5 36.6667H17.5C16.837 36.6667 16.2011 36.4033 15.7322 35.9344C15.2634 35.4656 15 34.8297 15 34.1667C15 33.5036 15.2634 32.8677 15.7322 32.3989C16.2011 31.9301 16.837 31.6667 17.5 31.6667H22.5C23.163 31.6667 23.7989 31.9301 24.2678 32.3989C24.7366 32.8677 25 33.5036 25 34.1667C25 34.8297 24.7366 35.4656 24.2678 35.9344C23.7989 36.4033 23.163 36.6667 22.5 36.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </Icon>
  );
};

HeadsetHelpIcon.displayName = 'HeadsetHelpIcon';

export default HeadsetHelpIcon;
