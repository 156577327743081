import {
  FACET_INPUT_TYPE,
  FACET_TRIPLE_STATE_SWITCH_VALUES,
  PossibleFilter,
} from '@savgroup-front-common/types';

const resetFacet = (filter: PossibleFilter): PossibleFilter => {
  if (filter.inputType === FACET_INPUT_TYPE.TRIPLE_STATE_SWITCH) {
    return {
      ...filter,
      value: FACET_TRIPLE_STATE_SWITCH_VALUES.IDDLE,
      shouldGoTop: false,
    };
  }
  if (filter.inputType === FACET_INPUT_TYPE.SWITCH) {
    return {
      ...filter,
      value: false,
      shouldGoTop: false,
    };
  }

  return filter;
};

export default resetFacet;
