import { NavArrowRight } from 'iconoir-react';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import STATUS_PAGE_URL from '../../api/StatusPage/config';
import { Button, ButtonLink } from '../../atoms/button';
import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { useIsSuperAdmin } from '../../hooks';
import { QuestionIcon, SettingsIcon } from '../../protons/icons';
import { BaseErrorBoundary } from '../ErrorBoundary';

import { useAppStatuses } from './AppStatuses.context';
import { $GearContainer } from './MaintenanceScreen.styles';
import messages from './messages';

const MaintenanceScreen: FunctionComponent = () => {
  const isSuperAdmin = useIsSuperAdmin();
  const { removeMaintenanceScreen } = useAppStatuses();

  return (
    <BaseErrorBoundary
      isFullScreen
      titleMessage={messages.scheduledMaintenanceTitle}
      bodyMessage={messages.scheduledMaintenanceBody}
      icon={
        <$GearContainer animationDuration={2000}>
          <SettingsIcon />
          <SettingsIcon />
        </$GearContainer>
      }
    >
      <Row>
        <Col>
          <ButtonLink
            href={STATUS_PAGE_URL}
            isExternal
            target="_blank"
            icon={<QuestionIcon />}
          >
            <SafeFormattedMessageWithoutSpread message={messages.seemore} />
          </ButtonLink>
        </Col>
        {isSuperAdmin && (
          <Col>
            <Button
              icon={<NavArrowRight />}
              type={BUTTON_TYPES.BUTTON}
              onClick={removeMaintenanceScreen}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.scheduledMaintenanceByPass}
              />
            </Button>
          </Col>
        )}
      </Row>
    </BaseErrorBoundary>
  );
};

MaintenanceScreen.displayName = 'MaintenanceScreen';

export default MaintenanceScreen;
