import moment from 'moment';
import React, { FC } from 'react';
import {
  defineMessages,
  IntlShape,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

const defaultMaxThresholds = {
  seconds: 50,
  minutes: 50,
  hours: 23,
};

const defaultMaxRatioThresholds = {
  hours_on_minutes: (10 * 60) / 5,
  days_on_hours: (5 * 24) / 4,
};

const messages = defineMessages({
  durationDays: {
    id: 'savgroup-front-common.durationDays',
    defaultMessage: '{days} and {hours}',
  },
  durationHours: {
    id: 'savgroup-front-common.durationHours',
    defaultMessage: '{hours} and {minutes}',
  },
  days: {
    id: 'savgroup-front-common.days',
    defaultMessage: '{value, plural, one {# day} other {# days}}',
  },
  hours: {
    id: 'savgroup-front-common.hours',
    defaultMessage: '{value, plural, one {# hour} other {# hours}}',
  },
  minutes: {
    id: 'savgroup-front-common.minutes',
    defaultMessage: '{value, plural, one {# minute} other {# minutes}}',
  },
  seconds: {
    id: 'savgroup-front-common.seconds',
    defaultMessage: 'a few seconds',
  },
});

function formatDurationParts(
  offset: number,
  unit: 'seconds' | 'minutes' | 'hours' | 'days',
  intl?: IntlShape,
  maxThresholds = defaultMaxThresholds,
  maxRatioThresholds = defaultMaxRatioThresholds,
) {
  const humanize = (
    value: number,
    message: MessageDescriptor,
    keep = true,
  ) => ({
    keep: value > 0 && keep,
    value: intl?.formatMessage(message, { value }),
  });

  const now = moment();
  const duration = moment.duration(
    now.diff(moment(now).subtract(Math.abs(offset), unit)),
  );

  let days = Math.floor(duration.asDays());

  let hours = duration.hours();

  let minutes = duration.minutes();

  let seconds = duration.seconds();

  if (seconds >= maxThresholds.seconds) {
    minutes += 1;
    seconds = 0;
  }
  if (
    minutes >= 60 ||
    (Math.floor(duration.asMinutes()) > minutes &&
      minutes >= maxThresholds.minutes)
  ) {
    hours += 1;
    minutes = 0;
  }
  if (
    hours >= 24 ||
    (Math.floor(duration.asHours()) > hours && hours >= maxThresholds.hours)
  ) {
    days += 1;
    hours = 0;
  }

  return {
    days: humanize(days, messages.days),
    hours: humanize(
      hours,
      messages.hours,
      !(days > 0 && hours > 0) ||
        (days * 24) / hours <= maxRatioThresholds.days_on_hours,
    ),
    minutes: humanize(
      minutes,
      messages.minutes,
      !(hours > 0 && minutes > 0) ||
        (hours * 60) / minutes <= maxRatioThresholds.hours_on_minutes,
    ),
    seconds: humanize(seconds, messages.seconds),
  };
}

export function formatDuration(
  value: number,
  unit: 'seconds' | 'minutes' | 'hours' | 'days',
  intl?: IntlShape,
) {
  const { days, hours, minutes, seconds } = formatDurationParts(
    value,
    unit,
    intl,
  );

  if (days.keep) {
    if (hours.keep) {
      return intl?.formatMessage(messages.durationDays, {
        days: days.value,
        hours: hours.value,
      });
    }

    return days.value;
  }
  if (hours.keep) {
    if (minutes.keep) {
      return intl?.formatMessage(messages.durationHours, {
        hours: hours.value,
        minutes: minutes.value,
      });
    }

    return hours.value;
  }
  if (minutes.keep) {
    return minutes.value;
  }

  return seconds.value;
}

export const FormattedDuration: FC<{
  value?: number;
  unit?: 'seconds' | 'minutes' | 'hours' | 'days';
}> = ({ value = 0, unit = 'seconds' }) => {
  const intl = useIntl();
  const duration = formatDuration(value, unit, intl);

  if (!duration) {
    return <span />;
  }

  return <span>{duration.charAt(0).toUpperCase() + duration.slice(1)}</span>;
};
FormattedDuration.displayName = 'FormattedDuration';
