import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { intl } from '@savgroup-front-common/core/src/helpers';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as actionTypes from './actionTypes';

function* getAdditionalInformationEntitiesSummariesWorker({ payload }) {
  const { indexer, ids } = payload;
  const meta = { indexer };

  if (ids.length === 0) {
    return;
  }

  yield call(
    callAndGetResponse,
    actionTypes.GET_ADDITIONAL_INFORMATION_ENTITIES_SUMMARIES,
    `${APIConfiguration.claim}additionalInformation/entities`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        ids,
        countryCode: intl.locale,
      },
    },
    meta,
  );
  yield put(
    actionTypes.GET_ADDITIONAL_INFORMATION_ENTITIES_SUMMARIES.end(meta),
  );
}

function* getAdditionalInformationEntitiesSummariesWatcher() {
  yield takeEvery(
    actionTypes.GET_ADDITIONAL_INFORMATION_ENTITIES_SUMMARIES.BASE,
    getAdditionalInformationEntitiesSummariesWorker,
  );
}

export default function* mainSaga() {
  try {
    yield all([getAdditionalInformationEntitiesSummariesWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
