import { uniq } from 'lodash';
import get from 'lodash/get';
import { ActionMeta } from 'redux-actions';

import { Repairer } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { REPAIRER_DOMAIN } from './constants';
import { RepairerDomainState } from './types';

export const initialRepairerState: RepairerDomainState = {
  [REPAIRER_DOMAIN.LIST]: {},
  [REPAIRER_DOMAIN.BY_SELLER_ID]: {},
  [REPAIRER_DOMAIN.BY_TYPE]: {},
};

export interface OnGetRepairerSuccessPayload {
  value: Repairer[];
}
export interface OnGetRepairerSuccessMeta {
  sellerId: string;
}

function onGetRepairerSuccess(
  state: RepairerDomainState,
  {
    payload,
    meta,
  }: ActionMeta<OnGetRepairerSuccessPayload, OnGetRepairerSuccessMeta>,
): RepairerDomainState {
  const { value } = payload;
  const { sellerId } = meta;
  const repairers = value;

  return {
    ...state,
    [REPAIRER_DOMAIN.LIST]: repairers.reduce((acc, repairer) => {
      return {
        ...acc,
        [repairer.id]: repairer,
      };
    }, state[REPAIRER_DOMAIN.LIST]),
    [REPAIRER_DOMAIN.BY_SELLER_ID]: repairers.reduce((acc, repairer) => {
      return {
        ...acc,
        [sellerId]: uniq([...get(acc, sellerId, []), repairer.id]),
      };
    }, state[REPAIRER_DOMAIN.BY_SELLER_ID]),
    [REPAIRER_DOMAIN.BY_TYPE]: repairers.reduce((acc, repairer) => {
      return {
        ...acc,
        [repairer.repairerType]: uniq([
          ...get(acc, repairer.repairerType, []),
          repairer.id,
        ]),
      };
    }, state[REPAIRER_DOMAIN.BY_TYPE]),
  };
}

export default function mainReducer(
  state: RepairerDomainState = initialRepairerState,
  action: ActionMeta<OnGetRepairerSuccessPayload, OnGetRepairerSuccessMeta>,
): RepairerDomainState {
  switch (action.type) {
    case types.GET_REPAIRERS_BY_TYPE_AND_SELLER_ID.SUCCEEDED:
      return onGetRepairerSuccess(state, action);

    default:
      return state;
  }
}
