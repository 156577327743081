import styled from 'styled-components';

export const SpinnerStyled = styled('div')`
  height: 100%;
  display: flex;
  margin: auto;
  filter: ${({ $inverted }) => ($inverted ? 'invert(100%)' : null)};
  > svg {
    margin: auto;
  }
`;
