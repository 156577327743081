import { File, RESULT_TYPE } from '@savgroup-front-common/types';

import { TABS_FILE } from '../constants/routing';
import { buildFilesUrl } from '../helpers';

import { adaptFileToFirstOtherHighlights } from './adaptFileToOtherHighlights';
import adaptFileToOwnerNameHighLights from './adaptFileToOwnerNameHighLights';
import adaptFileToReferenceHighLights from './adaptFileToReferenceHighLights';
import adaptToRegularHighlight from './adaptToRegularHighlight';

const FILTER_OUT_KEYWORDS = [
  'fileReference',
  'ownerFirstName',
  'ownerLastName',
  'shortFileReference',
];

export const adaptFileToSearchResult = (file: File) => {
  const { fileId, statusId } = file;

  return {
    resultType: RESULT_TYPE.FILE,
    key: fileId,
    value: {
      name: adaptFileToOwnerNameHighLights(file),
      reference: adaptFileToReferenceHighLights(file),
      other: adaptFileToFirstOtherHighlights(file, FILTER_OUT_KEYWORDS),
      status: statusId?.[0] ? [adaptToRegularHighlight(statusId?.[0])] : [],
      url: buildFilesUrl({ fileId, fileTabId: TABS_FILE.SUMMARY }),
    },
  };
};
