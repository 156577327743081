import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/closed`;

interface ForceCloseFilesCommandArgs {
  fileIds: string[];
  comment?: string;
  toCustomerComment?: string;
}

const forceCloseFilesCommand = async ({
  fileIds,
  comment,
  toCustomerComment,
}: ForceCloseFilesCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    return await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.PUT,
      json: { fileIds, comment, toCustomerComment },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default forceCloseFilesCommand;
