import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

import { APIConfiguration } from '@savgroup-front-common/configuration';

import { $FlagImageOption } from '../CountryAutocomplete.styles';

const Option: FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  const { data, innerProps, children } = props;
  const countryCode = data?.cultureCode || data?.value || undefined;

  if (data?.isHidden) {
    return null;
  }

  return (
    <components.Option
      {...props}
      innerProps={{
        ...innerProps,
        'data-option': true,
        'data-option-label': data?.label,
        'data-option-value': countryCode,
      }}
    >
      <$FlagImageOption
        src={`${APIConfiguration.catalogCDN}miscellaneous/flags/${countryCode}.svg`}
        alt={countryCode}
      />
      {children}
    </components.Option>
  );
};

Option.displayName = 'Option';

export default Option;
